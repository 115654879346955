import React from 'react'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import {
  DATE_RANGE_PRESETS,
  dateRangePresets,
} from './DateRangePicker/dateRangePresets'

const defaultPresets = [
  DATE_RANGE_PRESETS.CURRENT_DAY,
  DATE_RANGE_PRESETS.LAST_7_DAYS,
  DATE_RANGE_PRESETS.LAST_30_DAYS,
  DATE_RANGE_PRESETS.LAST_90_DAYS,
]

function DateRangeDropdown({ presets = defaultPresets, value, onChange }) {
  return (
    <Select value={value} onChange={onChange} size="small">
      {presets.map((presetId) => (
        <MenuItem key={presetId} value={presetId} selected={presetId === value}>
          {dateRangePresets[presetId].label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default DateRangeDropdown
