import React from 'react'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import CancelPresentationIcon from '@mui/icons-material/CancelPresentationRounded'

import ScrollableBox from 'components/styled/ScrollableBox'

function OrderList({
  cart,
  removeFromCart,
  setActiveTemplate,
  activeTemplate,
}) {
  function OrderListItem({ item, index }) {
    return (
      <ListItem
        sx={{
          bgcolor: activeTemplate?.id === item.template.id && 'hover.main',
          '&:hover': {
            bgcolor: 'hover.main',
            cursor: 'pointer',
          },
        }}
        key={item.key}
        onClick={() => setActiveTemplate(item.template)}
        secondaryAction={
          <>
            <IconButton
              color="primary"
              title="Remove from Order"
              onClick={(e) => {
                removeFromCart(index)
                e.stopPropagation()
              }}
            >
              <CancelPresentationIcon color="primary" />
            </IconButton>
          </>
        }
      >
        <Typography ml={2} color="textPrimary">
          {item.template.name}
        </Typography>
      </ListItem>
    )
  }

  return (
    <ScrollableBox
      sx={{ display: 'flex', height: 'calc(100% - 72px)', overflow: 'auto' }}
    >
      {cart.length === 0 ? (
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
          <Typography fontSize={14} color="darkgray.light" sx={{ m: 'auto' }}>
            Saved templates <br /> will appear here.
          </Typography>
        </Box>
      ) : (
        <List disablePadding sx={{ width: '100%' }}>
          {cart.map((item, index) => (
            <OrderListItem key={item.key} item={item} index={index} />
          ))}
        </List>
      )}
    </ScrollableBox>
  )
}

export default OrderList
