import React from 'react'

import CorrespondenceExpandedList from './Correspondence'

function ExpandedList({
  ExpandedListComponent = CorrespondenceExpandedList,
  ...props
}) {
  return <ExpandedListComponent {...props} />
}

export default ExpandedList
