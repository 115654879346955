import formatRelative from 'date-fns/formatRelative'

import useAuth from 'hooks/useAuth'

import formatTimeZone from 'helpers/datetime/formatTimeZone'
import convertTZ from 'helpers/datetime/convertTZ'

const useUserData = () => {
  const { user } = useAuth()

  const formatUserTimeZone = (date, format = 'M/dd/yyyy h:mm a zzz') => {
    try {
      return formatTimeZone(date, user.timezone || 'America/New_York', format)
    } catch (err) {
      return date?.toString()
    }
  }

  const formatUserTimeZoneRelative = (date) => {
    try {
      const timezone = user.timezone || 'America/New_York'
      return formatRelative(
        convertTZ(date, timezone),
        convertTZ(new Date(), timezone)
      )
    } catch (err) {
      return date?.toString()
    }
  }

  return {
    user,
    formatUserTimeZone,
    formatUserTimeZoneRelative,
  }
}

export default useUserData
