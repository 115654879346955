import React from 'react'

import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export default function MPXCopyright({ sx }) {
  const currentYear = new Date().getFullYear()

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      sx={{ position: 'absolute', width: '100vw', bottom: 0, ...sx }}
    >
      © {currentYear}{' '}
      <Link
        target="_blank"
        href="http://www.mpxlinq.com/"
        sx={{ color: 'primary.main' }}
      >
        MPX
      </Link>
    </Typography>
  )
}
