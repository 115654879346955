import { createContext, useReducer } from 'react'

import Snackbar from '@mui/material/Snackbar'

import ErrorNotification from 'components/common/Notification/Error'
import DownloadsTemplate from 'components/common/Notification/templates/DownloadsTemplate'

const SET_OPEN = 'SET_OPEN'
const SET_PROPS = 'SET_PROPS'
const SET_COMPONENT = 'SET_COMPONENT'

const initialState = {
  open: false,
  props: null,
  NotificationComponent: Snackbar,
}

const NotificationReducer = (state, action) => {
  switch (action.type) {
    case SET_OPEN:
      return {
        ...state,
        open: action.payload.open,
      }
    case SET_PROPS:
      return {
        ...state,
        props: action.payload.props,
      }
    case SET_COMPONENT:
      return {
        ...state,
        NotificationComponent: action.payload.NotificationComponent,
      }
    default:
      return state
  }
}

const NotificationContext = createContext(null)

function NotificationProvider({ children }) {
  const [state, dispatch] = useReducer(NotificationReducer, initialState)

  const setOpen = (open) => dispatch({ type: SET_OPEN, payload: { open } })
  const setProps = (props) => dispatch({ type: SET_PROPS, payload: { props } })
  const setComponent = (NotificationComponent) =>
    dispatch({ type: SET_COMPONENT, payload: { NotificationComponent } })

  const setError = (message, err) => {
    const GlobalErrorNotification = (props) => {
      let displayMessage = err?.response?.data?.display_message

      if (!displayMessage && process.env.NODE_ENV !== 'production' && err) {
        displayMessage = err?.message
      }

      if (!displayMessage) {
        displayMessage = message
      }

      return <ErrorNotification {...props}>{displayMessage}</ErrorNotification>
    }

    setComponent(GlobalErrorNotification)
    setOpen(true)
  }

  const setBasicNotification = (message) => {
    const GlobalNotification = (props) => (
      <DownloadsTemplate {...props}>{message}</DownloadsTemplate>
    )

    setComponent(GlobalNotification)
    setOpen(true)
  }

  const handleClose = () => {
    dispatch({ type: SET_OPEN, payload: { show: false } })
  }

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        setOpen,
        setProps,
        setComponent,
        setError,
        setBasicNotification,
        handleClose,
      }}
    >
      {children}
      {state.NotificationComponent && (
        <state.NotificationComponent
          {...state.props}
          open={state.open}
          setOpen={setOpen}
          onClose={handleClose}
        />
      )}
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationProvider }
