import { useState, useEffect } from 'react'

const useAdobeSDKClient = ({ url, metaData, fileName }) => {
  const [ready, setReady] = useState(undefined)
  const [adobeDCView, setAdobeDCView] = useState(undefined)

  useEffect(() => {
    const readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve()
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve()
        })
      }
    })

    setReady(readyPromise)
  }, [])

  const previewFile = (divId, viewerConfig) => {
    const config = {
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
    }

    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId
    }

    const newAdobeDCView = new window.AdobeDC.View(config)

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = newAdobeDCView.previewFile(
      {
        content: {
          location: {
            url,
            /*
                    If the file URL requires some additional headers, then it can be passed as follows:-
                    headers: [
                        {
                            key: "<HEADER_KEY>",
                            value: "<HEADER_VALUE>",
                        }
                    ]
                    */
          },
        },
        metaData: {
          // filename by default is extracted from url
          fileName: fileName ?? url.match(/[^/]*$/gm)?.[0]?.replace('.pdf', ''),
          ...metaData,
        },
      },
      viewerConfig
    )

    setAdobeDCView(newAdobeDCView)

    return previewFilePromise
  }

  const previewFileUsingFilePromise = (divId, filePromise, fileName) => {
    const newAdobeDCView = new window.AdobeDC.View({
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
      divId,
    })

    newAdobeDCView
      .previewFile(
        {
          content: {
            promise: filePromise,
          },
          metaData: {
            fileName,
            ...metaData,
          },
        },
        {}
      )
      .then(() => window.focus())

    setAdobeDCView(newAdobeDCView)
  }

  const registerSaveApiHandler = () => {
    const saveApiHandler = (metaData, content, options) => {
      return new Promise((resolve) => {
        /* Dummy implementation of Save API */
        setTimeout(() => {
          const response = {
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {
                updatedAt: new Date().getTime(),
              }),
            },
          }
          resolve(response)
        }, 2000)
      })
    }

    if (adobeDCView)
      adobeDCView.registerCallback(
        window.AdobeDC.View.Enum.CallbackType.SAVE_API,
        saveApiHandler,
        {}
      )
  }

  const registerEventsHandler = () => {
    /* Register the callback to receive the events */
    adobeDCView.registerCallback(
      /* Type of call back */
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      (event) => {},
      {
        enablePDFAnalytics: true,
      }
    )
  }

  return {
    ready,
    adobeDCView,
    previewFile,
    previewFileUsingFilePromise,
    registerSaveApiHandler,
    registerEventsHandler,
  }
}

export default useAdobeSDKClient
