import React, { useEffect, useState } from 'react'

function Async({ observable, fallback = 'Loading...' }) {
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState('')

  useEffect(() => {
    observable.then((val) => {
      setValue(val)
      setLoading(false)
    })
  }, [observable])

  return <>{loading ? fallback : value}</>
}

export default Async
