import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import FullBox from 'components/styled/FullBox'
import ScrollableBox from 'components/styled/ScrollableBox'
import DatePickerModal from './DatePickerModal'

import useReports from 'hooks/reporting/useReports'
import useConfiguration from 'hooks/useConfiguration'
import ReportTypeSelect from './ReportTypeSelect'
import ReportingTabs from './Tabs'

function ReportListItem({ report, setActiveReport, handleSave }) {
  const handleClick = () => {
    if (
      report.variables?.includes('datetime_start') ||
      report.variables?.includes('datetime_end')
    ) {
      setActiveReport(report)
    } else {
      handleSave(report)
    }
  }

  return (
    <Card
      sx={{
        boxShadow: 2,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: 'hover.main',
        },
        '&:active': {
          bgcolor: 'lightgray.main',
        },
      }}
      onClick={handleClick}
    >
      <FullBox sx={{ py: 12, px: 10 }}>
        <Typography
          variant="h2"
          fontWeight="bold"
          mb={2}
          sx={{ textTransform: 'uppercase', wordBreak: 'break-all' }}
        >
          {report.title}
        </Typography>
        <Typography>{report.description}</Typography>
      </FullBox>
    </Card>
  )
}

function ReportSelectionList() {
  const navigate = useNavigate()
  const { updatePath } = useConfiguration()
  const [tabIndex, setTabIndex] = useState(0)
  const [activeReport, setActiveReport] = useState(null)

  const { reports } = useReports({ tabIndex })

  const handleSave = (report = activeReport, dateRange) => {
    const breadcrumbs = [
      {
        display: 'Reporting',
        href: '/command-center/reporting',
      },
      report?.title && {
        display: report?.title,
        href: `/command-center/reporting/${report?.title}`,
      },
    ].filter((b) => b)

    navigate('/command-center/reporting', {
      state: {
        activeReport: report?.id,
        reportName: report?.title,
        tabIndex: tabIndex,
        dateRange,
        breadcrumbs: breadcrumbs,
        isDD: report?.isDD,
      },
    })

    updatePath(breadcrumbs)
  }

  return (
    <>
      <Box>
        <ReportingTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
        <Box sx={{ pt: 4, px: 10 }}>
          <ReportTypeSelect
            tabIndex={tabIndex}
            activeReport={activeReport}
            setActiveReport={(report) => {
              if (
                report.variables?.includes('datetime_start') ||
                report.variables?.includes('datetime_end')
              ) {
                setActiveReport(report)
              } else {
                handleSave(report)
              }
            }}
          />
        </Box>
        <ScrollableBox
          sx={{
            p: 10,
            overflow: 'auto',
            height: 'calc(100vh - 228px)',
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {reports.map((report) => (
              <Box key={report.id} sx={{ mr: 8, mb: 8 }}>
                <ReportListItem
                  report={report}
                  setActiveReport={setActiveReport}
                  handleSave={handleSave}
                />
              </Box>
            ))}
          </Box>
        </ScrollableBox>
      </Box>
      <DatePickerModal
        open={Boolean(activeReport)}
        setOpen={() => setActiveReport(null)}
        activeReport={activeReport}
        handleSave={handleSave}
      />
    </>
  )
}

export default ReportSelectionList
