import React, { useState, useEffect, useMemo } from 'react'
import format from 'date-fns/format'
import sub from 'date-fns/sub'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import DateRangeIcon from '@mui/icons-material/DateRange'

import DateRangePicker from 'components/common/DateRangePicker'
import DataListTable from 'components/common/DataList/Table'
import PostageInvoiceRow from 'components/common/DataList/Item/PostageInvoice'
import PostageInvoicePreviewRow from 'components/common/DataList/Item/PostageInvoicePreview'
import PureDataList from 'layouts/PureDataList'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import usePostageInvoiceHistoryInfinite from 'hooks/postage/usePostageInvoiceHistoryInfinite'

import getUTCDateRange from 'helpers/datetime/getUTCDateRange'
import formatDateRange from 'helpers/datetime/formatDateRange'
import isEmptyObject from 'helpers/node/isEmptyObject'
import SearchField from 'components/common/SearchField'

import { POSTAGE_INVOICE_PAGE_DAYS } from 'utils/constants'
import ClientSelect from 'components/postage/ClientSelect'
import { useSearchParams } from 'react-router-dom'

function InvoiceRowGroup({
  invoiceGroup,
  dataListProps,
  columns,
  isPreview,
  offset,
}) {
  return invoiceGroup._embedded.invoices.map((invoice, invoiceIndex) => {
    const row = invoice

    if (isPreview) {
      return (
        <PostageInvoicePreviewRow
          key={invoice.invoice_number}
          rowKey={'invoice_number'}
          row={row}
          getDataDisplay={dataListProps?.getDataDisplay}
          columns={columns}
          visibleColumns={dataListProps.visibleColumns}
          index={offset + invoiceIndex}
        />
      )
    }

    return (
      <PostageInvoiceRow
        key={invoice.invoice_number}
        rowKey={'invoice_number'}
        row={row}
        getDataDisplay={dataListProps?.getDataDisplay}
        columns={columns}
        visibleColumns={dataListProps.visibleColumns}
      />
    )
  })
}

function InvoiceNumberSearch({ onSearch }) {
  const [invoiceNumber, setInvoiceNumber] = useState('')

  return (
    <SearchField
      name="invoice_number"
      label="Invoice Number"
      size="small"
      value={invoiceNumber}
      onChange={(e) => setInvoiceNumber(e.target.value)}
      onSearch={(value) => onSearch(value ?? invoiceNumber)}
    />
  )
}

function Invoices() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [clientId, setClientId] = useState(searchParams.get('clientId'))
  const [invoiceDateRange, setInvoiceDateRange] = useState([
    undefined,
    undefined,
  ])
  const [lastUpdatedRange, setLastUpdatedRange] = useState([
    undefined,
    undefined,
  ])
  const [status, setStatus] = useState('')
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [sortList, setSortList] = useState([])
  const { setTotal, setTotalPages, pageSize, setPageSize } =
    useDataListContext()
  const [columns] = useState([
    {
      display: 'INVOICE DATE',
      id: 'date_inv',
      sortable: true,
      exactMatch: false,
    },
    {
      display: 'INVOICE NUMBER',
      id: 'invoice_number',
      sortable: true,
      exactMatch: false,
    },
    {
      display: 'AMOUNT DUE',
      id: 'amount_due',
      sortable: false,
      exactMatch: false,
    },
    {
      display: 'STATUS',
      id: 'status',
      sortable: true,
      exactMatch: false,
    },
    {
      display: 'LAST UPDATED',
      id: 'datetime_updated',
      sortable: true,
      exactMatch: false,
    },
  ])

  const hookParams = useMemo(() => {
    const updatedInvoiceDateRange = [
      invoiceDateRange[0],
      invoiceDateRange[1] || invoiceDateRange[0],
    ]
    const utcLastUpdatedRange = getUTCDateRange(
      lastUpdatedRange[0],
      lastUpdatedRange[1] || lastUpdatedRange[0]
    )

    if (!isEmptyObject(sortList) && !invoiceDateRange[0]) {
      setInvoiceDateRange([
        sub(new Date(), { days: POSTAGE_INVOICE_PAGE_DAYS * 2 }),
        new Date(),
      ])
      return
    }

    return {
      'date_inv[start]':
        updatedInvoiceDateRange[0] &&
        format(updatedInvoiceDateRange[0], 'yyyy-MM-dd'),
      'date_inv[end]':
        updatedInvoiceDateRange[1] &&
        format(updatedInvoiceDateRange[1], 'yyyy-MM-dd'),
      'datetime_updated[start]':
        lastUpdatedRange[0] && utcLastUpdatedRange[0].toISOString(),
      'datetime_updated[end]':
        lastUpdatedRange[0] && utcLastUpdatedRange[1].toISOString(),
      status: status || undefined,
      invoice_number: invoiceNumber || undefined,
      client_id: clientId || undefined,
      ...sortList,
    }
  }, [
    invoiceDateRange,
    lastUpdatedRange,
    sortList,
    status,
    invoiceNumber,
    clientId,
  ])

  const {
    total,
    invoices,
    allInvoices,
    loading,
    page,
    setPage,
    startDate,
    endDate,
  } = usePostageInvoiceHistoryInfinite({
    params: hookParams,
    manualEndDate: invoiceDateRange[1] || invoiceDateRange[0],
    manualStartDate: invoiceDateRange[0],
  })

  const isInfinite =
    !invoiceDateRange[0] && !lastUpdatedRange[0] && !invoiceNumber

  const { dataList, dataListProps } = useDataList({
    baseRowData: !isInfinite ? allInvoices : [],
    presetName: 'postageInvoices',
    setSortList,
  })

  const renderInvoices = useMemo(() => {
    return invoices.map((invoiceGroup, index) => (
      <InvoiceRowGroup
        key={index}
        invoiceGroup={invoiceGroup}
        columns={columns}
        dataListProps={dataListProps}
      />
    ))
  }, [invoices])

  const dateDisplay = useMemo(() => {
    let start = startDate
    let end = endDate

    if (invoiceDateRange[0]) {
      ;[start, end] = invoiceDateRange
    } else if (lastUpdatedRange[0]) {
      ;[start, end] = lastUpdatedRange
    }

    if (start) {
      const display = formatDateRange({
        dateRange: [new Date(start), new Date(end)],
      })
      return `(${display})`
    }

    return ''
  }, [startDate, endDate, invoiceDateRange, lastUpdatedRange])

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(0) // no fixed pagination
    }
  }, [total])

  useEffect(() => {
    if (Array.isArray(allInvoices) && allInvoices.length) {
      if (pageSize !== allInvoices.length) setPageSize(allInvoices.length)
    }
  }, [allInvoices])

  return (
    <Box
      sx={{ p: 8, position: 'relative', width: '100%', overflowX: 'hidden' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 1 }}>
        <Typography
          my="auto"
          color="primary.main"
          fontWeight={700}
          fontSize="24px"
        >
          Invoices {dateDisplay}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', mb: 4 }}>
        <ClientSelect
          value={clientId}
          setValue={(value) => {
            setClientId(value)

            if (!value) {
              setSearchParams({}, { replace: true })
            } else {
              setSearchParams({ clientId: value }, { replace: true })
            }
          }}
          sx={{ mb: undefined }}
        />
        <Box sx={{ mr: 4 }}>
          <DateRangePicker
            size="small"
            label="Invoice Date"
            value={invoiceDateRange}
            setValue={setInvoiceDateRange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mr: 4 }}>
          <DateRangePicker
            size="small"
            label="Last Updated"
            value={lastUpdatedRange}
            setValue={setLastUpdatedRange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mr: 4 }}>
          <InvoiceNumberSearch onSearch={(value) => setInvoiceNumber(value)} />
        </Box>
        <Box sx={{ mr: 4, flex: 1 }}>
          <TextField
            fullWidth
            select
            size="small"
            label="Payment Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="">Any</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="pending">Not Paid</MenuItem>
          </TextField>
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Card sx={{ boxShadow: 3, width: '100%' }}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <DataListTable
              {...dataListProps}
              infiniteScroll={isInfinite}
              infiniteScrollProps={{
                next: () => setPage(page + 1),
                hasMore: isInfinite,
                dataLength: total || 0,
              }}
              dataList={dataList}
              columns={columns}
              rowKey="invoice_number"
              RowComponent={PostageInvoiceRow}
              total={total}
              loading={loading}
              colProps={{
                sx: {
                  py: 4,
                },
              }}
              extraColumns={
                <>
                  <TableCell sx={{ backgroundColor: 'white' }} />
                </>
              }
              sortList={sortList}
              setSortList={setSortList}
            >
              {renderInvoices}
            </DataListTable>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

function InvoicesWrapper() {
  return (
    <PureDataList>
      <Invoices />
    </PureDataList>
  )
}

export default InvoicesWrapper
