import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Formik, useFormikContext } from 'formik'
import ActionButtons from 'components/common/ActionButtons'
import { useNavigate } from 'react-router-dom'
import get from 'lodash.get'

import Box from '@mui/material/Box'

import { generateAddressModalProps } from 'utils/smartyStreets'

import AddressForm from './AddressForm'
import { addressKeys, validationSchemas, optionalKeys } from './AddressForm'

import useModal from 'hooks/context/useModal'
import useConfiguration from 'hooks/useConfiguration'
import useAuth from 'hooks/useAuth'

import {
  COLLATERAL_ENABLE_ACCOUNT_NUMBER,
  COLLATERAL_OPTIONAL_ACCOUNT_NUMBER,
  DELIVERY_TYPES,
  PRIVILEGES,
  STANDALONE_DELIVERY_TYPE_NAMES,
} from 'utils/constants'

function DeliveryAddress({ activeIndex, headerActionProps }) {
  const { instanceConfigurations, currentModule } = useConfiguration()
  const excludeAccountNumber =
    instanceConfigurations?.[COLLATERAL_ENABLE_ACCOUNT_NUMBER] !== '1'
  const requireAccountNumber =
    instanceConfigurations?.[COLLATERAL_OPTIONAL_ACCOUNT_NUMBER] !== '1'
  const bypassAccountCheck = useMemo(() => {
    return get(
      currentModule || {},
      'configurations.0.settings.account_optional'
    )
  }, [currentModule])

  return (
    <>
      <Box sx={{ py: 6 }}>
        <ActionButtons actionProps={headerActionProps} />
      </Box>
      <AddressForm
        activeIndex={activeIndex}
        excludeAccountNumber={excludeAccountNumber}
        requireAccountNumber={requireAccountNumber}
        bypassAccountCheck={bypassAccountCheck}
      />
    </>
  )
}

const initialValues = [
  {
    account_number: '',
    name: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zip_code: '',
    country: 'USA',
    language: '',
  },
  {
    account_number: '',
    name: '',
    email: '',
  },
]

function FooterButtons({ activeIndex }) {
  const { values, dirty, submitForm, resetForm, errors } = useFormikContext()
  const nextDisabled = useMemo(() => {
    if (Boolean(STANDALONE_DELIVERY_TYPE_NAMES[activeIndex])) return false

    if (!dirty || (errors && Object.keys(errors).length > 0)) return true

    const valuesEntries = Object.entries(values)

    for (let i = 0; i < valuesEntries.length; i++) {
      const [key, value] = valuesEntries[i]

      if (!optionalKeys.includes(key) && !value) return true
    }

    return false
  }, [dirty, errors, values, activeIndex])

  const footerActionProps = [
    {
      children: 'Next',
      color: 'primary',
      variant: 'contained',
      onClick: () => submitForm(),
      disabled: nextDisabled,
    },
    !Boolean(STANDALONE_DELIVERY_TYPE_NAMES[activeIndex]) && {
      children: 'Clear',
      color: 'primary',
      variant: 'outlined',
      onClick: () => resetForm(),
      disabled: !dirty,
    },
  ].filter((b) => b)

  return (
    <Box sx={{ py: 8 }}>
      <ActionButtons footer actionProps={footerActionProps} />
    </Box>
  )
}

function DeliveryAddressWrapper() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [activeIndex, setActiveIndex] = useState(0)
  const { setOpen, setModalProps } = useModal()
  const { hasPrivilege, currentModule, instanceConfigurations } =
    useConfiguration()

  const hasDeliveryConfig = useCallback(
    (deliveryOption) => {
      const configType = 'module_configurations'
      const { configurations } = currentModule || {}

      if (configurations) {
        const config = configurations.find(
          (config) => config.configuration_type === configType
        )
        const deliveryOptions = config?.settings?.delivery_options || []

        return deliveryOptions.includes(deliveryOption)
      }

      return false
    },
    [currentModule]
  )

  const validate = useCallback(
    (values) => {
      const requireAccountNumber =
        instanceConfigurations?.[COLLATERAL_OPTIONAL_ACCOUNT_NUMBER] !== '1'

      if (requireAccountNumber && !values.account_number && activeIndex < 2) {
        return {
          account_number: 'Account number is required',
        }
      }

      return {}
    },
    [instanceConfigurations, activeIndex]
  )

  const hideMail = !hasDeliveryConfig('mail')
  const hideEmail =
    !hasPrivilege(PRIVILEGES.DELIVER_EMAIL) || !hasDeliveryConfig('email')
  const hideFax =
    !hasPrivilege(PRIVILEGES.DELIVER_FAX) || !hasDeliveryConfig('fax')
  const hideClaimsDenial = user?.instanceId !== 'Evolent'

  const headerActionProps = [
    !hideMail && {
      children: 'Mail',
      active: activeIndex === DELIVERY_TYPES.MAIL ? 'true' : undefined,
      onClick: () => setActiveIndex(DELIVERY_TYPES.MAIL),
    },
    !hideEmail && {
      children: 'Email',
      active: activeIndex === DELIVERY_TYPES.EMAIL ? 'true' : undefined,
      onClick: () => setActiveIndex(DELIVERY_TYPES.EMAIL),
    },
    !hideFax && {
      children: 'FAX',
      active: activeIndex === DELIVERY_TYPES.FAX ? 'true' : undefined,
      onClick: () => setActiveIndex(DELIVERY_TYPES.FAX),
    },
    !hideClaimsDenial && {
      children: 'Claim Denial',
      active: activeIndex === DELIVERY_TYPES.CLAIM_DENIAL ? 'true' : undefined,
      onClick: () => setActiveIndex(DELIVERY_TYPES.CLAIM_DENIAL),
    },
  ].filter((b) => b)

  const handleSubmit = async (values) => {
    const onContinue = (newValues) => {
      setOpen(false)

      navigate('/correspondence-hub/collateral/configure', {
        state: {
          values: {
            ...values,
            ...newValues,
          },
          activeIndex,
        },
      })
    }

    const onCancel = () => setOpen(false)

    if (activeIndex === 0) {
      const { modalProps } = await generateAddressModalProps({
        values,
        addressKeys,
        onContinue,
        onCancel,
      })

      setModalProps(modalProps)
      setOpen(true)
    } else {
      onContinue(values)
    }
  }

  useEffect(() => {
    if (hideMail && !hideEmail && activeIndex === 0) {
      setActiveIndex(DELIVERY_TYPES.EMAIL)
    }
  }, [hideMail, hideEmail])

  return (
    <Formik
      initialValues={initialValues[activeIndex] || {}}
      initialTouched={{country: true}}
      onSubmit={handleSubmit}
      validationSchema={validationSchemas[activeIndex]}
      validate={validate}
      enableReinitialize
    >
      <Box>
        <DeliveryAddress
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          headerActionProps={headerActionProps}
        />
        <FooterButtons activeIndex={activeIndex} />
      </Box>
    </Formik>
  )
}

export default DeliveryAddressWrapper
