import React from 'react'

function CancelledWhite({ width = '24', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
    >
      <circle
        cx="11"
        cy="11"
        r="9"
        fill="#fff"
        stroke="#76BC21"
        stroke-width="2"
      />
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="#76BC21"
        d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      />
    </svg>
  )
}

export default CancelledWhite
