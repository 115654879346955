import { useMemo } from 'react'
import useSWR from 'swr'

import getMimeTypeDisplay from 'helpers/css/getMimeTypeDisplay'
import fetcher from 'utils/fetcher'
import urlSerialize from 'helpers/browser/urlSerialize'

const useDocumentMimeTypes = ({ disabled, params = {} }) => {
  const { data, isValidating, mutate } = useSWR(
    disabled ? null : `/document-mimetypes?${urlSerialize(params)}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const mimeTypes = useMemo(() => {
    const apiMimeTypes =
      data?._embedded?.mime_types?.filter((mimeType) =>
        mimeType.includes('/')
      ) || []

    return apiMimeTypes.map((mimeType) => {
      const key = getMimeTypeDisplay(mimeType)

      return {
        key: key.toUpperCase(),
        value: mimeType,
      }
    })
  }, [data])

  return {
    mimeTypes: mimeTypes,
    loading: (!data?._embedded?.mime_types && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default useDocumentMimeTypes
