import { PDFDocument } from 'pdf-lib'

import reorderPDF from './reorderPDF'

/**
 * Render a split pdf using pdf lib
 * @param {Blob} pdfBlob - blob of pdf
 * @param {Array<number>} pageNums - page numbers to split on
 */
async function renderSplitPDF(pdfBlob, pageNums) {
  const pdfBytes = await pdfBlob.arrayBuffer()
  const pdfDoc = await PDFDocument.load(pdfBytes)
  const pageCount = pdfDoc.getPageCount()

  let includedPages = pageNums.slice().map((p) => parseInt(p))
  let pageNumbers = Array.from({ length: pageCount }).map((_, i) => i + 1)

  // remove all outer-right page numbers
  const maxPageNum = pageNums.reduce(
    (a, b) => (parseInt(a) > parseInt(b) ? parseInt(a) : parseInt(b)),
    0
  )
  for (let i = 0; i < pageCount; i++) {
    if (i > maxPageNum) {
      pdfDoc.removePage(pdfDoc.getPageCount() - 1)
      pageNumbers.pop()
    }
  }

  // remove remaining page numbers, adjusting accordingly
  let i = 1
  while (pdfDoc.getPageCount() !== includedPages.length) {
    if (includedPages.includes(i)) {
      i += 1
      continue
    }

    // remove page from document
    pdfDoc.removePage(i - 1)

    const index = i

    // subtract 1 from future page numbers
    includedPages = includedPages.map((p) => {
      if (p > index) {
        return p - 1
      }

      return p
    })
  }

  // rearrange pages
  reorderPDF(pdfDoc, pageNums)

  const arrayBuffer = await pdfDoc.save()
  const base64 = await pdfDoc.saveAsBase64()

  return { arrayBuffer, base64 }
}

export default renderSplitPDF
