import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import DateRangePicker from 'components/common/DateRangePicker'
import Drawer from 'components/common/Drawer/Basic'

import DateRangeIcon from '@mui/icons-material/DateRange'

import useNotification from 'hooks/context/useNotification'
import useConfiguration from 'hooks/useConfiguration'

const initialDateRange = [undefined, undefined]

function Sidebar({
  open,
  setOpen,
  reports = [],
  activeReport,
  activeReportData,
  setReportingView,
  isDD,
}) {
  const { setError } = useNotification()
  const { updatePath } = useConfiguration()
  const location = useLocation()
  const navigate = useNavigate()
  const [localActiveReport, setLocalActiveReport] = useState(activeReport)
  const [localDateRange, setLocalDateRange] = useState(
    location.state?.dateRange || initialDateRange
  )

  const validateDateRange = (dateRange) => {
    if (!activeReportData?.showDateRange) return true

    if (dateRange?.[0] === undefined || dateRange?.[1] === undefined) {
      setError('Please select a valid date range')
      return false
    }

    return true
  }

  const foundReport = useMemo(() => {
    return reports?.find((report) => report.id === localActiveReport)
  }, [reports, localActiveReport])

  const showDateRange = useMemo(() => {
    return (
      foundReport &&
      foundReport?.variables &&
      (foundReport.variables.includes('datetime_start') ||
        foundReport.variables.includes('datetime_end'))
    )
  }, [foundReport])

  const handleClick = () => {
    let [startDate, endDate] = localDateRange || []

    if (startDate && !endDate) {
      endDate = startDate
    }

    const newDateRange = [startDate, endDate]

    if (!validateDateRange(newDateRange)) return

    setReportingView(localActiveReport, newDateRange)

    const reportName = foundReport?.title

    const breadcrumbs = [
      {
        display: 'Reporting',
        href: '/command-center/reporting',
      },
      reportName && {
        display: reportName,
        href: `/command-center/reporting/${reportName}`,
      },
    ].filter((b) => b)

    updatePath(breadcrumbs)

    navigate(`/command-center/reporting`, {
      shallow: true,
      state: {
        activeReport: localActiveReport,
        reportName: reportName,
        breadcrumbs,
        isDD,
      },
    })
  }

  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      sx={{ border: '1px solid', borderColor: 'lightgray.main' }}
    >
      <Box
        sx={{
          px: 4,
          height: '100vh',
        }}
      >
        <Box sx={{ py: 6, textAlign: 'center' }}>
          <Typography fontWeight={'bold'} variant="h2">
            Search / Filter
          </Typography>
        </Box>
        <Stack spacing={4}>
          <TextField
            fullWidth
            size="small"
            label="Report type"
            select
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: { maxHeight: '50vh !important', boxShadow: 2 },
                },
              },
            }}
            value={localActiveReport}
            onChange={(e) => setLocalActiveReport(e.target.value)}
          >
            {reports.map((report) => (
              <MenuItem key={report.id} value={report.id}>
                {report.title}
              </MenuItem>
            ))}
          </TextField>
          {showDateRange && (
            <DateRangePicker
              value={localDateRange}
              setValue={setLocalDateRange}
              fullWidth
              size="small"
              label="Date"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateRangeIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Execute search
            </Button>
          </Box>
        </Stack>
      </Box>
    </Drawer>
  )
}

export default Sidebar
