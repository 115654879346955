import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const useConsumerAccountIdTypes = () => {
  const { data, isValidating } = useSWR('/consumer-account-id-types', fetcher, {
    revalidateOnFocus: false,
  })
  return {
    defaultIdType: data?.default || null,
    options: data?.options || {},
    loading: isValidating,
  }
}

export default useConsumerAccountIdTypes
