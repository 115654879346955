import useSWR from 'swr'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

import saveData from 'helpers/browser/saveData'

const useLetterDocument = (letterId, documentId) => {
  const { data, isValidating, mutate } = useSWR(
    letterId && documentId
      ? `/letters/${letterId}/documents/${documentId}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const getDocument = async (id, docId) => {
    const res = await axios.get(`/letters/${id}/documents/${docId}`)
    return res.data
  }

  const downloadDocument = async (id, document) => {
    let file = null

    switch (document.content_type) {
      case 'application/pdf':
        file = await axios.get(`/letters/${id}/documents/${document.id}`, {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        })
        break
      default:
        break
    }

    if (file.data) saveData(file.data, document.filename, document.content_type)
    else throw new Error('Document could not be downloaded.')
  }

  return {
    document: data,
    loading: !data || isValidating,
    mutate,
    getDocument,
    downloadDocument,
  }
}

export default useLetterDocument
