import React, { useState, useMemo } from 'react'

import Modal from 'components/common/Modal'
import PasswordCheck from 'components/common/PasswordCheck'

import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import useNotification from 'hooks/context/useNotification'
import useUsers from 'hooks/users/useUsers'

import { PASSWORD_CHECKS } from 'utils/constants'
import checkPassword from 'helpers/node/checkPassword'

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

function PasswordResetModal({ open, setOpen, user, requireOld = true }) {
  const { updateUser } = useUsers()
  const { setError, setBasicNotification } = useNotification()

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target

    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleCancel = () => {
    setValues(initialValues)
    setOpen(false)
  }

  const handleSave = async () => {
    try {
      await updateUser({
        userId: user.userId,
        userData: {
          old_password: values.oldPassword,
          password: values.newPassword,
        },
      })

      setBasicNotification('Password updated successfully')
    } catch (err) {
      setError(err.response?.data?.display_message || 'Error setting password')
    } finally {
      setOpen(false)
    }
  }

  const saveDisabled = useMemo(() => {
    if (
      (!values.oldPassword && requireOld) ||
      !values.newPassword ||
      !values.confirmPassword
    )
      return true

    if (!checkPassword(values.newPassword)) return true

    if (values.newPassword !== values.confirmPassword) return true

    return false
  }, [values, errors, setErrors])

  const footerButtonProps = [
    {
      children: 'Save',
      variant: 'contained',
      color: 'primary',
      type: 'button',
      onClick: () => handleSave(),
      disabled: saveDisabled,
    },
    {
      children: 'Cancel',
      variant: 'outlined',
      color: 'primary',
      type: 'button',
      onClick: () => handleCancel(),
    },
  ]

  return (
    <Modal
      open={open}
      onClose={() => handleCancel()}
      size="lg"
      title="Reset Password"
      footerButtonProps={footerButtonProps}
    >
      <Stack spacing={6} sx={{ pt: 2 }}>
        {requireOld && (
          <TextField
            size="small"
            label="Old password *"
            name="oldPassword"
            value={values.oldPassword}
            onChange={handleChange}
            error={Boolean(errors.oldPassword)}
            helperText={errors.oldPassword}
            type="password"
          />
        )}
        <TextField
          size="small"
          label="New password *"
          name="newPassword"
          value={values.newPassword}
          onChange={handleChange}
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword}
          type="password"
        />
        <TextField
          size="small"
          label="Confirm new password *"
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword}
          type="password"
        />
        <PasswordCheck
          value={values.newPassword}
          checks={[
            ...PASSWORD_CHECKS,
            {
              display: 'Password and confirmation password must match',
              cond: (value) => value && value === values.confirmPassword,
            },
          ]}
        />
      </Stack>
    </Modal>
  )
}

export default PasswordResetModal
