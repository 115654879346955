import React, { useEffect, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

import ItemContainer from './containers/ItemContainer'
import DateRangeDropdown from 'components/common/DateRangeDropdown'
import PillChart from 'components/charts/PillChart'

import useDashboardContext from 'hooks/context/useDashboardContext'

import { COLOR_SHADES, WIDGET_KEYS } from 'utils/constants'
import ChartLegend from 'components/common/ChartLegend'
import {
  DATE_RANGE_PRESETS,
  dateRangePresets,
} from 'components/common/DateRangePicker/dateRangePresets'
import getUTCDateRange from 'helpers/datetime/getUTCDateRange'

import { useDocumentInboundAggregate } from 'hooks/digital_mail'

function DigitalMailboxCounts({ id }) {
  const { refreshCount, setWidgetLoaded } = useDashboardContext()
  const [tabIndex, setTabIndex] = useState(0)
  const colors = [
    COLOR_SHADES.GREEN[0],
    COLOR_SHADES.GREEN[1],
    COLOR_SHADES.CHART_BLUE[1],
  ]

  const [datePreset, setDatePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const [startDate, endDate] = useMemo(() => {
    // current day should match Digital Mail page results
    if (datePreset === DATE_RANGE_PRESETS.CURRENT_DAY) return []

    const [start, end] = dateRangePresets[datePreset]?.preset || []
    return getUTCDateRange(start, end)
  }, [datePreset])

  const {
    totalAssignedNew,
    totalAssignedOpen,
    totalUnassigned,
    totalNew,
    totalOpen,
    loading,
    mutate: mutateAll,
  } = useDocumentInboundAggregate({ startDate, endDate })

  const totals = useMemo(() => {
    return [
      (tabIndex === 0 ? totalAssignedNew : totalNew) || 0,
      (tabIndex === 0 ? totalAssignedOpen : totalOpen) || 0,
    ]
  }, [tabIndex, totalAssignedOpen, totalNew, totalOpen, totalAssignedNew])

  const total = useMemo(() => {
    return totals.reduce((a, b) => a + b, 0) || 0
  }, [totals])

  const labels = useMemo(() => {
    return ['New', 'Open']
  }, [])

  useEffect(() => {
    mutateAll()
  }, [refreshCount])

  useEffect(() => {
    if (!loading) {
      setWidgetLoaded(id)
    }
  }, [loading])

  return (
    <ItemContainer
      disablePadding
      href="/correspondence-hub/digital-mailbox"
      title="Digital Mailbox"
      titleAction={
        <Box>
          <DateRangeDropdown
            value={datePreset}
            onChange={(e) => setDatePreset(e.target.value)}
          />
        </Box>
      }
      widgetKey={WIDGET_KEYS.DIGITAL_MAILBOX_ASSIGNMENTS}
      loading={false}
    >
      <Box>
        <Stack spacing={4}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={(_e, value) => setTabIndex(parseInt(value))}
            >
              <Tab value={0} label="Assgned To Me" />
              <Tab
                value={1}
                label="Unassigned"
                sx={{ display: !totalUnassigned ? 'none' : undefined }}
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            {loading ? (
              <CircularProgress sx={{ m: 'auto' }} />
            ) : (
              <Stack spacing={4}>
                <Box>
                  <Typography variant="h1" color="primary.main" fontSize={32}>
                    {total}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight="medium"
                    color="primary.main"
                  >
                    all documents
                  </Typography>
                </Box>
                <Box>
                  <PillChart colors={colors} data={totals} />
                </Box>
                <Box>
                  <ChartLegend
                    labels={labels}
                    data={totals}
                    colors={colors}
                    sx={{
                      flexDirection: 'row',
                      '& > *': {
                        mr: 8,
                      },
                    }}
                  />
                </Box>
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>
    </ItemContainer>
  )
}

export default DigitalMailboxCounts
