import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

import ShowMoreContent from 'components/common/ExpandedList/components/ShowMoreContent'
import Modal from 'components/common/Modal'

import useMailTracking from 'hooks/mail_tracking/useMailTracking'
import useDataList from 'hooks/useDataList'
import { useUserData } from 'hooks/users'

import splitByPascalCase from 'helpers/node/splitByPascalCase'

function TrackingItem({ item, isLastItem }) {
  const { formatUserTimeZone } = useUserData()
  const { location } = item || {}
  const destination = [location?.city, location?.state]
    .filter((l) => l)
    .join(', ')

  return (
    <TimelineItem>
      <TimelineOppositeContent />
      <TimelineSeparator>
        <TimelineDot />
        {!isLastItem && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography sx={{ mr: 8, color: 'darkgray.main' }}>
              {formatUserTimeZone(
                new Date(item.datetime_event),
                'EEEE, MMMM dd, yyyy, h:mm a'
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography fontWeight="bold" color="darkgray.main">
                {splitByPascalCase(item.status || '')}
              </Typography>
              {destination && (
                <Typography
                  color="darkgray.main"
                  sx={{ textTransform: 'uppercase' }}
                >
                  {destination}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </TimelineContent>
    </TimelineItem>
  )
}

function MailTrackingModal({ docImb, ...props }) {
  const { formatUserTimeZone } = useUserData()
  const { trackingData, error, loading } = useMailTracking({ docImb })

  const rowData = useMemo(() => {
    const trackingItem = trackingData || {}

    return {
      Delivered: `Sent at ${formatUserTimeZone(
        new Date(trackingItem.datetime_delivery)
      )}`,
      'Tracking Number': trackingItem.tracking_number,
      'Postal Service': trackingItem.carrier,
    }
  }, [trackingData, formatUserTimeZone])

  const { dataListProps, columns } = useDataList({
    baseRowData: [rowData],
  })

  const errorMessage = useMemo(() => {
    return error?.response?.data?.display_message || ''
  }, [error])

  return (
    <Modal
      {...props}
      width="750px"
      title="Tracking Details"
      footerButtonProps={[
        {
          children: 'Close',
          color: 'primary',
          variant: 'contained',
          onClick: () => props.onClose(),
        },
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '70vh',
          width: '100%',
        }}
      >
        {errorMessage ? (
          <Box sx={{ width: '100%', textAlign: 'center' }}>{errorMessage}</Box>
        ) : loading ? (
          <CircularProgress sx={{ mx: 'auto' }} />
        ) : (
          <>
            <ShowMoreContent
              {...dataListProps}
              rowData={rowData}
              columns={columns}
              open={true}
            />
            <Timeline
              sx={{
                backgroundColor: 'lightgray.light',
                height: '100%',
                flex: 1,
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0,
                },
              }}
            >
              {trackingData?.scan_details?.map((trackingValue, index) => (
                <TrackingItem
                  key={index}
                  item={trackingValue}
                  isLastItem={index === trackingData?.scan_details?.length - 1}
                />
              ))}
            </Timeline>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default MailTrackingModal
