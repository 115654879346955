import useSWR from 'swr'

import fetcher from 'utils/fetcher'

import urlSerialize from 'helpers/browser/urlSerialize'

const useLetterDocumentDescriptions = (params, shouldFetch = true) => {
  const { data, isValidating, mutate } = useSWR(
    shouldFetch
      ? `/letter-document-descriptions?${urlSerialize({ ...params })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    descriptions: data?._embedded?.letter_document_descriptions || [],
    loading:
      (!data?._embedded?.letter_document_descriptions && !data?.error) ||
      isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default useLetterDocumentDescriptions
