import React from 'react'
import styled from 'styled-components/macro'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const FlexBox = styled(Box)`
  display: flex;
`

function CollapseFromText({
  children,
  text,
  expanded,
  setExpanded,
  typographyProps,
  hideButton = false,
  ...props
}) {
  return (
    <Box sx={{ py: 6 }} {...props}>
      <FlexBox>
        <Typography
          fontSize="16px"
          fontWeight={'bold'}
          sx={{ my: 'auto' }}
          {...typographyProps}
        >
          {text}
        </Typography>
        {!hideButton && (
          <IconButton color="primary" onClick={() => setExpanded(!expanded)}>
            {expanded ? (
              <ExpandLessIcon color="primary" />
            ) : (
              <ExpandMoreIcon color="primary" />
            )}
          </IconButton>
        )}
      </FlexBox>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  )
}

export default CollapseFromText
