import React from 'react'
import { Link } from 'react-router-dom'

import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'
import Button from '@mui/material/Button'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrowSharp'

import Copyright from 'components/layout/Copyright'
import Logo from 'components/common/Logo'

function Home() {
  return (
    <>
      <CssBaseline />
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={6} sm={4} p={1}>
                <Box
                  sx={{
                    width: '45%',
                    marginLeft: '20px',
                  }}
                >
                  <Logo variant="white" />
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} p={7}>
                <Box></Box>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ height: '5vh', backgroundColor: 'secondary.main' }}
        ></Grid>
      </Grid>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={4} p={7}>
            <Typography variant="h1">
              MPXLINQ: The next generation of the MPX Console!
            </Typography>
            <Box component="p">
              <strong>MPXLINQ</strong> is a web application written in React
              that uses the{' '}
              <MuiLink href="https://www.mpxpp.com/home/">MPXPP API</MuiLink>{' '}
              for data retrieval and updating.
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} p={7}>
            <Typography variant="h1">LOG IN</Typography>
            <Box component="p">
              You will need your MPXLINQ username, password, and instance # or
              string.
            </Box>
            <Button
              color="secondary"
              variant="contained"
              LinkComponent={Link}
              to="/auth/login"
            >
              Proceed to login
              <DoubleArrowIcon />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} p={7}>
            <Typography variant="h1">About MPX</Typography>
            <Box component="p">
              Learn more about the company behind MPXPP and MPXLINQ.
            </Box>
            <Button
              color="primary"
              variant="outlined"
              LinkComponent={MuiLink}
              href="https://www.mpxonline.com/"
            >
              View details
              <DoubleArrowIcon />
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Copyright sx={{ position: 'absolute', width: '100vw', bottom: 0 }} />
    </>
  )
}

export default Home
