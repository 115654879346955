import { useEffect } from 'react'
import useSWR from 'swr'
import deepEqual from 'deep-equal'

import fetcher from 'utils/fetcher'

const useStandaloneTemplate = ({
  templateId,
  activeTemplate,
  setActiveTemplate,
}) => {
  const { data } = useSWR(
    templateId ? '/collateral-template-categories?embed=templates|order_data_defaults' : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    try {
      if (templateId && data) {
        const allTemplates = data._embedded.categories.flatMap(
          (category) => category._embedded.templates
        )
        const foundTemplate = allTemplates.find(
          (template) => template.id === templateId
        )

        if (foundTemplate) {
          if (!deepEqual(activeTemplate, foundTemplate)) {
            setActiveTemplate(foundTemplate)
          }
        }
      }
    } catch (err) {}
  }, [data, templateId])
}

export default useStandaloneTemplate
