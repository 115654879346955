import React from 'react'

import LandingContent from 'components/landing/LandingContent'
import AuthGuard from 'components/guards/AuthGuard'
import LandingLayout from 'layouts/Landing'

function LandingPage() {
  return (
    <AuthGuard>
      <LandingLayout>
        <LandingContent />
      </LandingLayout>
    </AuthGuard>
  )
}

export default LandingPage
