import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedListHR from 'components/styled/ExpandedListHR'

// local components
import StatusHistoryDisplay from '../components/StatusHistoryDisplay'
import Notes from '../components/Notes'
import ShowMoreContent from '../components/ShowMoreContent'
import EditableExpandedListItem from './EditableListItem'

function DigitalMailboxDocumentPreviewExpandedList({
  children,
  expanded,
  rowData,
  columns,
  statusTitle = '',
  statusMessage = '',
  getDataDisplay,
  visibleColumns,
  collapseDetails,
}) {
  const { notes, statusHistory } = rowData?._embedded ?? {}
  const gridWidth = notes?.length > 0 ? 12 : 6

  const [open, setOpen] = useState(true)

  return (
    <Collapse
      in={expanded}
      sx={{
        textAlign: 'left',
        pt: 2,
        mt: 0,
        '& .MuiCollapse-entered': {
          mt: 4,
        },
        bgcolor: 'background.paper',
      }}
    >
      {statusTitle && (
        <Typography fontWeight="bold" fontSize={14}>
          {statusTitle}
        </Typography>
      )}
      {statusMessage && <Box component="p">{statusMessage}</Box>}
      {statusHistory && expanded && (
        <StatusHistoryDisplay statusHistory={statusHistory} />
      )}
      <Grid container columns={gridWidth} spacing={30}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight="medium" fontSize={14}>
              Details
            </Typography>
            {collapseDetails && (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </Box>
          <ExpandedListHR />
          <ShowMoreContent
            grid
            rowData={rowData}
            columns={columns}
            open={open}
            getDataDisplay={getDataDisplay}
            visibleColumns={visibleColumns}
            ExpandedListItemComponent={EditableExpandedListItem}
            initialShowing={columns.length}
          />
        </Grid>
        {notes?.length > 0 && (
          <Grid item xs={6}>
            <Notes notes={notes} />
          </Grid>
        )}
      </Grid>
      {open && children}
    </Collapse>
  )
}

export default DigitalMailboxDocumentPreviewExpandedList
