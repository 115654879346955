import React from 'react'
import styled from 'styled-components/macro'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

const Tab = styled(MuiTab)`
  min-width: 160px;
`

function PrintAssetsTabs({ tabIndex, setTabIndex }) {
  return (
    <Box sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
      <Box
        sx={{
          bgcolor: 'white',
          height: '72px',
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
        }}
      />
      <Box
        sx={{
          height: '72px',
          width: '100%',
          minWidth: '1024px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Tabs
            value={tabIndex}
            onChange={(_e, value) => setTabIndex(value)}
            aria-label="print assets tabs"
            sx={{ ml: 8, mt: 6, minWidth: '420px' }}
          >
            <Tab fullWidth label="PRINT ASSETS" />
            <Tab fullWidth label="MPX ASSETS " />
          </Tabs>
        </Box>
      </Box>
      <Box
        fullWidth
        sx={{
          height: '1px',
          bgcolor: 'darkblue.main',
          opacity: 0.12,
        }}
      />
    </Box>
  )
}

export default PrintAssetsTabs
