import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

import NavListGroup from './NavListGroup'
import InstanceDisplay from 'components/common/InstanceDisplay'
import MPXCopyright from 'components/layout/MPXCopyright'

import useSidebar from 'hooks/useSidebar'

import { SIDEBAR_WIDTH } from 'utils/constants'
import InstanceSelect from 'components/common/InstanceSelect'

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.darkgray.light,
      border: '3px solid transparent',
      borderRadius: '8px',
      backgroundClip: 'content-box',
    },
    height: '100vh',
    width: SIDEBAR_WIDTH.OPEN,
    [theme.breakpoints.down('sm')]: {
      width: SIDEBAR_WIDTH.MOBILE,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    boxSizing: 'border-box',
    ...(!open && {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: SIDEBAR_WIDTH.CLOSED,
    }),
  },
}))

export default function NavigationSideBar({ modules }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { open, setOpen } = useSidebar('navigation')

  useEffect(() => {
    if (isMobile) setOpen(false)
    else setOpen(true)
  }, [isMobile])

  return (
    <Drawer variant={isMobile ? undefined : 'permanent'} open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      />
      <List
        sx={{
          paddingTop: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', height: 'auto', px: 8, pt: 5 }}>
          {open && (
            <Box sx={{ mb: 'auto', width: '100%' }}>
              <InstanceSelect />
            </Box>
          )}
        </Box>
        <Box sx={{ flex: 1, pb: '32px' }}>
          {modules.map((module, moduleIndex) => (
            <>
              {module.modules.length > 0 && (
                <NavListGroup
                  key={`${module.name}-${module.id}`}
                  open={open}
                  display={module.display}
                  items={module.modules?.filter((m) => !m.hidden)}
                  moduleIndex={moduleIndex}
                />
              )}
            </>
          ))}
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            textTransform: 'uppercase',
            position: 'relative',
          }}
        >
          <InstanceDisplay extraChecks={open} />
          <MPXCopyright
            sx={{
              width: '100%',
              position: 'relative',
            }}
          />
        </Box>
      </List>
    </Drawer>
  )
}
