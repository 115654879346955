import React, { useState, useEffect, useMemo } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'

import SearchField from 'components/common/SearchField'
import DataListLayout from 'layouts/DataList'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'

import DataListTable from 'components/common/DataList/Table'
import AgencyPreferencesRow from 'components/common/DataList/Item/AgencyPreferences/Memic'
import useAgencyPreferences from 'hooks/agency_preferences/useAgencyPreferences'
import MemicEditPreferencesModal from 'components/agency_preferences/Memic/EditPreferencesModal'

const PAGE_SIZE = 20

function SearchTextField({ setFilters, lastSearch }) {
  const [localValue, setLocalValue] = useState(lastSearch)

  const handleChange = (val) => {
    const searchValue = val ?? localValue

    setFilters({ search: searchValue })
  }

  return (
    <SearchField
      fullWidth
      size="small"
      label="Search"
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleChange()
        }
      }}
      onSearch={handleChange}
    />
  )
}

function MemicAgencyPreferecesPage() {
  const { setTotal, setTotalPages, page, pageSize } = useDataListContext()

  const [filters, setFilters] = useState({})
  const [sortList, setSortList] = useState([])

  const hookParams = useMemo(() => {
    return {
      'filter[field]': filters.search ? 'agency_code|name' : undefined,
      'filter[term]': filters.search || undefined,
      'paging[page]': page,
      'paging[page_size]': pageSize,
    }
  }, [filters, page, pageSize])

  const {
    agencyPreferences,
    total,
    totalPages,
    loading,
    updateAgencyPreferences,
    downloadCsv,
  } = useAgencyPreferences({ params: hookParams })

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages)
    }
  }, [total])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: agencyPreferences,
    presetName: 'agencyMailing',
    setSortList,
  })

  const [activeAgency, setActiveAgency] = useState(null)

  return (
    <>
      <MemicEditPreferencesModal
        open={Boolean(activeAgency)}
        setOpen={setActiveAgency}
        agency={activeAgency}
        updateAgencyPreferences={updateAgencyPreferences}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 6, display: 'flex', height: '40px' }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 4, whiteSpace: 'nowrap', height: '40px' }}
              onClick={() => downloadCsv()}
            >
              Download Extract
            </Button>
          </Box>
          <SearchTextField
            setFilters={setFilters}
            lastSearch={filters.search || ''}
          />
        </Box>

        <Card sx={{ boxShadow: 2, width: '100%' }}>
          <DataListTable
            {...dataListProps}
            rowKey="id"
            dataList={dataList}
            columns={columns}
            loading={loading}
            filters={filters}
            sortList={sortList}
            setSortList={setSortList}
            RowComponent={AgencyPreferencesRow}
            rowProps={{
              setActiveAgency: setActiveAgency,
            }}
            visibleColumns={[
              'agency_code',
              'name',
              'city',
              'state',
              'updated_by',
              'datetime_modified',
            ]}
            pageSize={PAGE_SIZE}
            extraColumns={
              <>
                <TableCell sx={{ backgroundColor: 'white' }}></TableCell>
              </>
            }
            total={total}
          />
        </Card>
      </Box>
    </>
  )
}

function MemicAgencyPreferecesPageWrapper() {
  return (
    <DataListLayout>
      <MemicAgencyPreferecesPage />
    </DataListLayout>
  )
}

export default MemicAgencyPreferecesPageWrapper
