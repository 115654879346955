import React from 'react'
import { useFormikContext } from 'formik'

import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import usePrintAssets from 'hooks/collateral/usePrintAssets'

function PrintAssetDropdown({ fieldData, disabled, handleUpdateChange }) {
  const { required } = fieldData
  const { values, errors } = useFormikContext()
  const { assets } = usePrintAssets({
    collateral_available: 1,
    'paging[page_size]': 0,
  })

  const { field, display, inputProps, placeholder } = fieldData

  return (
    <TextField
      {...inputProps}
      id={field}
      name={field}
      label={display}
      placeholder={placeholder || display}
      value={values[field] || ''}
      onChange={(e) =>
        handleUpdateChange({ name: field, value: e.target.value })
      }
      select
      fullWidth
      InputLabelProps={{ shrink: values[field] ? true : undefined }}
      SelectProps={{
        MenuProps: { PaperProps: { sx: { maxHeight: '30% !important' } } },
      }}
      error={Boolean(errors[field])}
      helperText={errors[field]}
      disabled={disabled}
      required={required}
    >
      {assets &&
        assets.map((asset) => (
          <MenuItem key={asset.id} value={asset.key_id}>
            {asset.name || asset.key_id}
          </MenuItem>
        ))}
    </TextField>
  )
}

export default PrintAssetDropdown
