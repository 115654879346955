import React, { useRef, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import ScrollableBox from 'components/styled/ScrollableBox'
import NotesItem from 'components/notes/NotesItem'
import ResponsiveDrawer from 'components/common/Drawer/Responsive'

function AddNoteSection({ sx }) {
  const [row, setRows] = useState(1)
  const { values, handleChange, handleSubmit, isSubmitting, dirty } =
    useFormikContext()

  return (
    <Box sx={{ ...sx, height: 'auto', pb: 12 }}>
      <Typography variant="h2" fontWeight="medium" pb={4}>
        Add note
      </Typography>
      <TextField
        multiline
        fullWidth
        rows={row}
        onBlur={() => setRows(1)}
        onFocus={() => setRows(8)}
        variant="filled"
        value={values.note}
        onChange={(e) =>
          handleChange({ target: { name: 'note', value: e.target.value } })
        }
      />
      <Button
        color="primary"
        variant="contained"
        size="action-header"
        sx={{ mt: 4, mb: 6, float: 'right' }}
        onClick={() => handleSubmit()}
        disabled={isSubmitting || !dirty}
      >
        Submit
      </Button>
    </Box>
  )
}

function Content({
  notes = [],
  label = 'Order ID',
  id,
  orderId,
  open,
  loading,
  height,
}) {
  const scrollRef = useRef(null)

  const sharedStyles = {
    px: 6,
    py: 6,
  }

  useEffect(() => {
    if (scrollRef.current && open) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [scrollRef.current, open])

  return (
    <Box
      sx={{
        height,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '72px 1fr auto',
        width: '350px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'lightgray.main',
          px: sharedStyles.px,
          pb: 4,
        }}
      >
        <Typography variant="h1" pb={1}>
          Notes
        </Typography>
        <Typography fontSize={14} color="darkgray.main">
          {label} {orderId || id || 'N/A'}
        </Typography>
      </Box>
      <ScrollableBox
        ref={scrollRef}
        sx={{
          ...sharedStyles,
          height: 'auto',
          overflowY: 'auto',
          display: 'flex',
          wordBreak: 'break-word',
        }}
      >
        {notes.length === 0 && loading ? (
          <CircularProgress thickness={2} sx={{ mx: 'auto' }} />
        ) : (
          <Stack spacing={8}>
            {notes.map((note, noteIndex) => (
              <NotesItem key={`note-${noteIndex}`} note={note} />
            ))}
          </Stack>
        )}
      </ScrollableBox>
      <AddNoteSection sx={{ ...sharedStyles }} />
    </Box>
  )
}

function OrderDetailDrawer({
  sx,
  open,
  setOpen,
  height = 'calc(100vh - 72px - 64px)',
  ...props
}) {
  return (
    <ResponsiveDrawer open={open} setOpen={setOpen} width="350px">
      <Content height={height} open={open} {...props} />
    </ResponsiveDrawer>
  )
}

export default OrderDetailDrawer
