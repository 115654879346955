import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import axios from 'utils/axios'
import { APP_KEY } from 'utils/constants'

import useSavedSearchModal from 'hooks/useSavedSearchModal'

function Form({
  children,
  type,
  setType,
  initialValues,
  initialStatus,
  fetchSearchConfig,
  setActiveId,
  moduleId,
}) {
  const { sfcEtag, submitAction, activeSfcId } = useSavedSearchModal()

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(50).required('Title is required'),
  })

  const handleClose = () => {
    // update local search configs
    fetchSearchConfig()

    // close the modal
    setType('closed')
  }

  const handleDelete = async () => {
    const res = await axios.delete(
      `/modules/${moduleId}/search-field-configurations/${activeSfcId}`,
      {
        headers: {
          'If-Match': `${sfcEtag}`,
        },
        params: {
          app_key: APP_KEY,
        },
      }
    )

    if (res.status !== 204) {
      throw new Error('Error deleting saved search')
    } else {
      if (setActiveId) setActiveId(-1)
      handleClose()
    }
  }

  const handleSave = async ({ values }) => {
    const { title, searchInputs, resultTableColumns } = values

    // axios variables
    const successCode = 200
    const method = type === 'add' ? 'post' : 'put'
    const url =
      type === 'add'
        ? `/modules/${moduleId}/search-field-configurations`
        : `/modules/${moduleId}/search-field-configurations/${activeSfcId}`
    const data = {
      name: title,
      is_default: false,
      fields: {
        search_fields: (searchInputs || []).map((input) => ({
          path: input.path,
          display: input.display,
        })),
        result_columns: (resultTableColumns || []).map((input) => ({
          path: input.path,
          display: input.display,
        })),
      },
    }

    const res = await axios({
      method,
      url,
      data,
      params: {
        app_key: APP_KEY,
      },
      headers: {
        'If-Match': type === 'edit' ? `${sfcEtag}` : undefined,
      },
    })

    if (res.status !== successCode) {
      throw new Error('Error saving saved search')
    } else {
      if (setActiveId) setActiveId(res.data.id)
    }

    handleClose()
  }

  const handleSubmit = async (values, { setErrors }) => {
    try {
      if (submitAction === 'delete') {
        await handleDelete()
      } else {
        await handleSave({ values })
      }
    } catch (error) {
      setErrors({
        submit: error.message,
      })
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialStatus={initialStatus}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}

export default Form
