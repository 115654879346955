import React, { useState, useEffect, useMemo, useCallback } from 'react'
import sub from 'date-fns/sub'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'

import DateRangePicker from 'components/common/DateRangePicker'
import ExportToCSV from 'components/common/ExportToCSV'
import SearchField from 'components/common/SearchField'
import OrderHistoryRow from 'components/common/DataList/Item/OrderHistory'
import EditNotifications from 'components/order_history/EditNotifications'
import DataListLayout from 'layouts/PureDataList'

import useAuth from 'hooks/useAuth'
import useDataList from 'hooks/useDataList'
import { useAllOrders } from 'hooks/useOrders'
import useDataListContext from 'hooks/context/useDataListContext'

import DateRangeIcon from '@mui/icons-material/DateRange'

import trimString from 'helpers/node/trimString'
import isEmptyObject from 'helpers/node/isEmptyObject'
import formatDate from 'helpers/datetime/formatDateToUTC'
import DataListTable from 'components/common/DataList/Table'
import useOrdersInfinite from 'hooks/orders/useOrdersInfinite'
import formatDateRange from 'helpers/datetime/formatDateRange'
import { DATALIST_DIMENSIONS, WIP_PAGE_DAYS } from 'utils/constants'

const debugOptions = [
  {
    display: 'Any',
    value: 'all',
  },
  {
    display: 'Yes',
    value: '1',
  },
  {
    display: 'No',
    value: '0',
  },
]

const programOptions = [
  {
    display: 'Any',
    value: 'all',
  },
  {
    display: 'Programs Only',
    value: '0',
  },
  {
    display: 'One-offs Only',
    value: '1',
  },
]

function OrderRowGroup({
  orderGroup,
  prepareRowData,
  dataListProps,
  columns,
  visibleColumns,
}) {
  return orderGroup._embedded.orders.map((order) => {
    const row = prepareRowData(order)

    return (
      <OrderHistoryRow
        key={order.id}
        rowKey={'id'}
        row={row}
        getDataDisplay={dataListProps?.getDataDisplay}
        columns={columns}
        visibleColumns={visibleColumns}
      />
    )
  })
}

function OrderHistory() {
  const { setTotal, setTotalPages } = useDataListContext()
  const { user } = useAuth()

  const [filters, setFilters] = useState({
    debug: '0',
    one_off: 'all',
  })
  const [dateCreated, setDateCreated] = useState([undefined, undefined])
  const [dateApproved, setDateApproved] = useState([undefined, undefined])
  const [dateMailed, setDateMailed] = useState([undefined, undefined])
  const [sortList, setSortList] = useState([])

  const columns = useMemo(
    () =>
      [
        {
          display: 'ORDER ID',
          id: 'order_id',
          sortable: true,
          exactMatch: false,
        },
        {
          display: 'PROGRAM NAME',
          id: 'program_name',
          sortable: true,
          exactMatch: false,
        },
        {
          display: 'DATE CREATED',
          id: 'datetime_created',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'DATE APPROVED',
          id: 'datetime_approved',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'DATE MAILED',
          id: 'date_mailed',
          sortable: true,
          exactMatch: false,
        },
        {
          display: 'PIECES',
          id: 'pieces',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'STATUS',
          id: 'status',
          sortable: true,
          exactMatch: false,
          filter: 'status',
        },
        {
          display: 'PROGRAM ID',
          id: 'program_id',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'FILE NAME',
          id: 'file_name',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'ENVELOPES',
          id: 'envelopes',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'IMAGES',
          id: 'images',
          sortable: false,
          exactMatch: false,
        },
        user?.isMPXAdmin && {
          display: 'DEBUG',
          id: 'is_debug',
          sortable: false,
          exactMatch: false,
        },
        user?.isMPXAdmin && {
          display: 'REQUIRES MPX APPROVAL',
          id: 'mpx_approval_required',
          sortable: false,
          exactMatch: false,
        },
      ].filter((c) => c),
    [user]
  )

  const dateCreatedStart = dateCreated[0] ? dateCreated[0] : undefined
  const dateCreatedEnd = dateCreated[1] ? dateCreated[1] : dateCreatedStart
  const dateApprovedStart = dateApproved[0] ? dateApproved[0] : undefined
  const dateApprovedEnd = dateApproved[1] ? dateApproved[1] : dateApprovedStart
  const dateMailedStart = dateMailed[0] ? dateMailed[0] : undefined
  const dateMailedEnd = dateMailed[1] ? dateMailed[1] : dateMailedStart

  const ordersHookParams = useMemo(() => {
    const searchValue = trimString(filters.search)

    return {
      status: filters.status === 'Any' ? undefined : filters.status,
      debug: filters.debug,
      one_off: filters.one_off,
      state: 'WIP',
      'date_created[start]': formatDate(dateCreatedStart),
      'date_created[end]': formatDate(dateCreatedEnd),
      'date_approved[start]': formatDate(dateApprovedStart),
      'date_approved[end]': formatDate(dateApprovedEnd),
      'date_mailed[start]': formatDate(dateMailedStart),
      'date_mailed[end]': formatDate(dateMailedEnd),
      'filter[field]':
        searchValue && searchValue !== '' && filters.searchfields
          ? filters.searchfields
          : undefined,
      'filter[term]':
        searchValue && searchValue !== '' && filters.searchfields
          ? searchValue
          : undefined,
      embed: 'reports|workflow_statuses|processing_statistics',
      ...sortList,
    }
  }, [
    filters,
    dateCreatedStart,
    dateCreatedEnd,
    dateApprovedStart,
    dateApprovedEnd,
    dateMailedStart,
    dateMailedEnd,
    sortList,
  ])

  const getEarliestStartDate = () => {
    const startDates = [dateCreatedStart, dateApprovedStart, dateMailedStart]

    return startDates.reduce((earliestDate, date) => {
      if (!earliestDate) {
        return date
      }

      if (!date) {
        return earliestDate
      }

      if (date < earliestDate) {
        return date
      }

      return earliestDate
    }, undefined)
  }

  const getLatestEndDate = () => {
    const endDates = [dateCreatedEnd, dateApprovedEnd, dateMailedEnd]

    return endDates.reduce((latestDate, date) => {
      if (!latestDate) {
        return date
      }

      if (!date) {
        return latestDate
      }

      if (date > latestDate) {
        return date
      }

      return latestDate
    }, undefined)
  }

  const earliestStartDate = useMemo(getEarliestStartDate, [
    dateCreatedStart,
    dateApprovedStart,
    dateMailedStart,
  ])
  const latestEndDate = useMemo(getLatestEndDate, [
    dateCreatedEnd,
    dateApprovedEnd,
    dateMailedEnd,
  ])

  const {
    orders,
    allOrders,
    total,
    loading,
    page,
    setPage,
    additionalLoading,
  } = useOrdersInfinite({
    params: ordersHookParams,
    manualEndDate: latestEndDate,
    manualStartDate: earliestStartDate,
  })

  const displayStartDate =
    earliestStartDate || sub(new Date(), { days: (page + 1) * WIP_PAGE_DAYS })
  const displayEndDate = latestEndDate ? latestEndDate : new Date()
  const isInfinite = !Boolean(earliestStartDate)

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(0) // set to 0 to remove pagination
    }
  }, [total])

  const prepareRowData = useCallback((row) => {
    const updatedLots = {}

    if (row.lots && !isEmptyObject(row.lots)) {
      Object.keys(row.lots).forEach((key) => {
        updatedLots[key] = {
          ...row.lots[key],
        }

        delete updatedLots[key].lot_date_expected_contractsla

        if (row.id && row.lots[key].lot_report) {
          updatedLots[
            key
          ].lot_report = `${row.id},${row.lots[key].lot_report},${row.lots[key].lot_file_name}`
        }
      })
    }

    const rowData = {
      order_id: row.id,
      program_name: row.program_name,
      datetime_created: row.datetime_created,
      datetime_approved: row.datetime_approved,
      date_mailed: row.date_mailed,
      pieces: row.counts ? row.counts.pieces : null,
      status: !row.is_on_hold
        ? { status: row.status, display: row.status }
        : { status: row.status, display: 'On Hold' },
      program_id: row.program_id,
      file_name: row.file_name,
      envelopes: row.counts.envelopes,
      images: row.counts.images_clicks,
      lots: updatedLots,
      is_debug: row.is_debug,
      mpx_approval_required: row.mpx_approval_required,
      _embedded: row._embedded,
    }

    if (!user?.isMPXAdmin) {
      delete rowData.is_debug
      delete rowData.mpx_approval_required
    }

    return rowData
  }, [])

  const visibleColumns = useMemo(
    () =>
      [
        'order_id',
        'program_name',
        'datetime_created',
        'datetime_approved',
        'date_mailed',
        'pieces',
        'status',
        user?.isMPXAdmin && 'is_debug',
        user?.isMPXAdmin && 'mpx_approval_required',
      ].filter((c) => c),
    [user]
  )

  const { dataList, dataListProps } = useDataList({
    baseRowData: !isInfinite ? allOrders : [],
    presetName: 'orderHistory',
    setSortList,
    prepareRowData,
    getColumnDisplay: (col) => {
      let displayCol = col

      if (displayCol.includes('datetime')) {
        displayCol = col.replace('datetime', 'date')
      }

      return displayCol.replace('_', ' ').toUpperCase()
    },
  })

  const { getCsvExport } = useAllOrders(ordersHookParams, total, prepareRowData)

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    const newFilters = { ...filters }

    if (name === 'search' && value) {
      newFilters['searchfields'] = 'program_name|order_id|file_name'
    }
    newFilters[name] = value

    setFilters(newFilters)
  }

  const renderOrders = useMemo(() => {
    return orders.map((orderGroup, index) => (
      <OrderRowGroup
        key={index}
        orderGroup={orderGroup}
        columns={columns}
        dataListProps={dataListProps}
        prepareRowData={prepareRowData}
        visibleColumns={visibleColumns}
      />
    ))
  }, [orders, visibleColumns])

  return (
    <>
      <Box
        sx={{
          height: '72px',
          background: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          px: 6,
          borderBottom: '1px solid',
          borderColor: 'lightgray.main',
        }}
      >
        <Typography variant="h1" sx={{ mt: 7 }}>
          Order History
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <EditNotifications />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 8, pt: 4 }}>
        <Grid
          container
          columns={12}
          spacing={4}
          sx={{
            '& > *': { display: 'flex' },
            '& > * > *': { my: 'auto' },
            mb: 6,
          }}
        >
          <Grid item xs={2}>
            <DateRangePicker
              size="small"
              id="dateCreated"
              name="dateCreated"
              label="Date Created"
              value={dateCreated}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateRangeIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={(newValue) => {
                setDateCreated(newValue)
              }}
              setValue={setDateCreated}
            />
          </Grid>

          <Grid item xs={2}>
            <DateRangePicker
              size="small"
              id="dateApproved"
              name="dateApproved"
              label="Date Approved"
              value={dateApproved}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateRangeIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={(newValue) => {
                setDateApproved(newValue)
              }}
              setValue={setDateApproved}
            />
          </Grid>

          <Grid item xs={2}>
            <DateRangePicker
              size="small"
              id="dateMailed"
              name="dateMailed"
              label="Date Mailed"
              value={dateMailed}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateRangeIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={(newValue) => {
                setDateMailed(newValue)
              }}
              setValue={setDateMailed}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              fullWidth
              select
              size="small"
              label="Status"
              name="status"
              onChange={handleFilterChange}
              defaultValue="Any"
            >
              {[
                'Any',
                'Mailed',
                'Created',
                'Production',
                'Processing',
                'Pending Approval',
                'Partially Mailed',
              ].map((status, index) => (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <SearchField
              fullWidth
              size="small"
              label="Search"
              name="search"
              value={filters.search}
              onChange={handleFilterChange}
            />
          </Grid>
        </Grid>

        <Grid
          container
          columns={12}
          spacing={4}
          sx={{
            '& > *': { display: 'flex' },
            '& > * > *': { my: 'auto' },
            mb: 6,
          }}
        >
          {user.isMPXAdmin && (
            <Grid item xs={2}>
              <TextField
                fullWidth
                select
                size="small"
                label="Debug"
                name="debug"
                onChange={handleFilterChange}
                value={filters.debug}
              >
                {debugOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.display}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item xs={2}>
            <TextField
              fullWidth
              select
              size="small"
              label="Programs"
              name="one_off"
              onChange={handleFilterChange}
              value={filters.one_off}
            >
              {programOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', py: 2 }}>
          <Typography variant="h2">
            {formatDateRange({ dateRange: [displayStartDate, displayEndDate] })}
          </Typography>
        </Box>

        <Card sx={{ boxShadow: 2, width: '100%' }}>
          <DataListTable
            {...dataListProps}
            rowKey="order_id"
            dataList={dataList}
            columns={columns}
            loading={loading}
            additionalLoading={additionalLoading}
            filters={filters}
            sortList={sortList}
            setSortList={setSortList}
            visibleColumns={visibleColumns}
            getCsvExport={getCsvExport}
            infiniteScroll={isInfinite}
            total={total}
            height={DATALIST_DIMENSIONS.TABBED_HEIGHT}
            infiniteScrollProps={{
              next: () => setPage(page + 1),
              hasMore: isInfinite,
              dataLength: total || 0,
            }}
            extraColumns={
              <>
                <TableCell sx={{ backgroundColor: 'white' }}></TableCell>
              </>
            }
          >
            {renderOrders}
          </DataListTable>
        </Card>
        <Box>
          <ExportToCSV
            filename={`Order.Approval.${Date.now()}.csv`}
            getCsvExport={getCsvExport}
            hide={loading}
          />
        </Box>
      </Box>
    </>
  )
}

function OrderHistoryWrapper() {
  return (
    <DataListLayout>
      <Box sx={{ width: '100%' }}>
        <OrderHistory />
      </Box>
    </DataListLayout>
  )
}

export default OrderHistoryWrapper
