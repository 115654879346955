import getTextWidth from './getTextWidth'

/* from an array of strings, get the longest string width */
const getLongestTextWidth = (strings) => {
  const longestString = strings.reduce((longest, current) => {
    return getTextWidth(current) > getTextWidth(longest) ? current : longest
  }, '')

  return getTextWidth(longestString)
}

export default getLongestTextWidth
