import React from 'react'
import { useLocation } from 'react-router-dom'

import ReportSelection from './ReportSelection'
import ReportView from './ReportView'
import DDReportView from './DDReportView'

function Reporting() {
  const location = useLocation()
  const { activeReport, isDD } = location.state || {}

  if (activeReport) {
    if (isDD) {
      return <DDReportView />
    }

    return <ReportView />
  }

  return <ReportSelection />
}

export default Reporting
