import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Formik, useFormikContext } from 'formik'
import RemovableItems from 'components/common/RemovableItems'
import MenuItem from '@mui/material/MenuItem'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import LoadingModal from 'components/common/LoadingModal'
import useModal from 'hooks/context/useModal'
import AddressAutocomplete from 'components/common/AddressAutocomplete'

export const addressKeys = {
  address: 'address_line_1',
  addressalt: 'address_line_2',
  city: 'city',
  state: 'state',
  country: 'country',
  zip: 'zip_code',
}

export const optionalKeys = ['address_line_2']

export const expediteAddressValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address_line_1: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip_code: Yup.string().required('ZIP Code is required'),
})

const addDisplay = (items) => {
  return items.map((item) => ({ ...item, display: 'LETTER' }))
}

const SEALED_OPTIONS = {
  1: 'Yes',
  0: 'No',
}

function ExpediteAddressFormModal({
  items,
  open,
  setOpen,
  selectedItems,
  setSelectedItems,
}) {
  const plural = selectedItems.length !== 1 ? 1 : 0
  const itemsDisplay = ['document', 'documents']
  const { values, errors, handleChange, resetForm, submitForm } =
    useFormikContext()

  const handleRemove = (index) => {
    const newSelectedItems = [...selectedItems]

    newSelectedItems.splice(index, 1)

    setSelectedItems(newSelectedItems)
  }

  const footerButtonProps = [
    {
      children: 'Confirm',
      color: 'primary',
      variant: 'contained',
      onClick: () => submitForm(),
    },
    {
      children: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      onClick: () => setOpen(false),
    },
  ]

  useEffect(() => {
    setSelectedItems(addDisplay(items))
  }, [items])

  useEffect(() => {
    if (selectedItems.length === 0 && open) {
      setOpen(false)
    }
  }, [selectedItems])

  // clear form on close
  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open])

  const content = (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      footerButtonProps={footerButtonProps}
      title="Expedite Documents"
      height="604px"
      width="732px"
      scrollable
    >
      <Box sx={{ pr: 8, pb: 8 }}>
        <RemovableItems
          items={selectedItems}
          handleRemove={handleRemove}
          idKey="id"
          max={5}
        />

        <Box sx={{ mt: 2 }}>
          <Typography color="darkgray.main" mb={6}>
            You are about to expedite{' '}
            {`${selectedItems.length} ${itemsDisplay[plural]}`}.
          </Typography>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                required
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <AddressAutocomplete addressKeys={addressKeys} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address_line_2"
                label="Address Line 2"
                value={values.address_line_2}
                onChange={handleChange}
                error={Boolean(errors.address_line_2)}
                helperText={errors.address_line_2}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="city"
                label="City"
                value={values.city}
                onChange={handleChange}
                error={Boolean(errors.city)}
                helperText={errors.city}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="state"
                label="State"
                value={values.state}
                onChange={handleChange}
                error={Boolean(errors.state)}
                helperText={errors.state}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="zip_code"
                label="ZIP Code"
                value={values.zip_code}
                onChange={handleChange}
                error={Boolean(errors.zip_code)}
                helperText={errors.zip_code}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="country"
                label="Country"
                value={values.country}
                onChange={handleChange}
                error={Boolean(errors.country)}
                helperText={errors.country}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Sealed Envelope?"
                name="is_sealed"
                onChange={handleChange}
                value={values.is_sealed}
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { maxHeight: '50vh !important', boxShadow: 2 },
                    },
                  },
                }}
              >
                {Object.keys(SEALED_OPTIONS).map((x) => (
                  <MenuItem key={x} value={x}>
                    {SEALED_OPTIONS[x]}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Typography color="darkgray.main">
            Please confirm this action and click Confirm below, or click Cancel
            to go back to the previous screen.
          </Typography>
          <Typography color="darkgray.main">
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Note:
            </span>{' '}
            by expediting documents, you are also approving them for mailing.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
  return content
}
function ExpediteAddressModal({ open, setOpen, items, handleConfirm }) {
  const {
    setOpen: setModalOpen,
    setModalProps,
    setComponent: setModalComponent,
  } = useModal()
  const [selectedItems, setSelectedItems] = useState(addDisplay(items))

  const handleCompleteConfirm = async (values) => {
    const executeConfirmAction = async () => {
      await handleConfirm(selectedItems, {
        is_sealed: values.is_sealed,
        status: 'approved',
        addresses: {
          expedite: {
            name: values.name,
            line1: values.address_line_1,
            line2: values.address_line_2,
            city: values.city,
            state: values.state,
            zip_code: values.zip_code,
            country: values.country,
          },
        },
      })
      return true
    }
    setModalProps({
      size: 'lg',
      hideButtonsBorder: true,
      title: 'Expedite Documents',
    })
    setModalComponent(LoadingModal)
    setModalOpen(true)
    if (await executeConfirmAction()) {
      setOpen(false)
    }
    setModalOpen(false)
  }

  return (
    <Formik
      initialValues={{
        is_sealed: 1,
        name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip_code: '',
        country: 'USA',
      }}
      validationSchema={expediteAddressValidationSchema}
      validateOnChange={false}
      onSubmit={handleCompleteConfirm}
    >
      <ExpediteAddressFormModal
        items={items}
        open={open}
        setOpen={setOpen}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </Formik>
  )
}

export default ExpediteAddressModal
