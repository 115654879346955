import React from 'react'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/CancelPresentationRounded'

function ListItem({ display, sx, handleRemove }) {
  return (
    <Box sx={{ display: 'flex', width: '100%', marginY: '12px' }}>
      <Box sx={sx}>{display}</Box>
      <IconButton
        color="primary"
        sx={{ marginY: 'auto' }}
        onClick={handleRemove}
      >
        <CancelIcon />
      </IconButton>
    </Box>
  )
}

export default ListItem
