import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import MuiListItem from '@mui/material/ListItem'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

const ListItem = styled(MuiListItem)`
  padding: 0;
  padding-left: 34px;
  padding-right: 34px;
  height: 36px;
`

const ListItemIcon = styled(MuiListItemIcon)`
  margin-right: 11.48px;
  min-width: 18px;
`

const Icon = styled.img`
  height: 18px;
  width: 18px;
`

function NavListItem({
  icon,
  display = '',
  href = '#',
  open,
  selected,
  moduleIndex,
  index,
  bookmark,
}) {
  return (
    <ListItem
      component={bookmark ? 'a' : Link}
      title={display}
      target={bookmark && '_blank'}
      to={!bookmark && href}
      href={bookmark && href}
      state={{ moduleIndex, index }}
      sx={{
        bgcolor: selected ? 'hover.main' : undefined,
      }}
    >
      {icon && (
        <ListItemIcon sx={{ width: '18px' }}>
          <Icon src={icon} alt="" />
        </ListItemIcon>
      )}
      {open && (
        <ListItemText
          primary={display}
          primaryTypographyProps={{
            color: 'textPrimary',
            sx: {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        />
      )}
    </ListItem>
  )
}

NavListItem.propTypes = {
  icon: PropTypes.string,
  display: PropTypes.string,
  href: PropTypes.string,
  open: PropTypes.bool,
  selected: PropTypes.bool,
  moduleIndex: PropTypes.number,
  index: PropTypes.number,
}

export default NavListItem
