import React, { useState, useEffect } from 'react'

import MuiLink from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import RemovableItems from 'components/common/RemovableItems'
import DownloadsTemplate from 'components/common/Notification/templates/DownloadsTemplate'
import LoadingModal from 'components/common/LoadingModal'

import useModal from 'hooks/context/useModal'
import useNotification from 'hooks/context/useNotification'

function ConfirmationModal({
  open,
  setOpen,
  items,
  handleConfirm,
  variant,
  setTabIndex,
  customTitle,
  customNotification = () => {},
  customContent = () => {},
  checkedCount,
}) {
  const addDisplay = () => items.map((item) => ({ ...item, display: 'LETTER' }))

  const {
    setOpen: setModalOpen,
    setModalProps,
    setComponent: setModalComponent,
  } = useModal()
  const { setComponent, setOpen: setNotifOpen } = useNotification()
  const [selectedItems, setSelectedItems] = useState(addDisplay(items))
  const itemCount = selectedItems.length || checkedCount
  const plural = itemCount !== 1 ? 1 : 0
  const itemsDisplay = ['document', 'documents']
  const verbDisplay = ['has', 'have']

  const handleRemove = (index) => {
    const newSelectedItems = [...selectedItems]

    newSelectedItems.splice(index, 1)

    setSelectedItems(newSelectedItems)
  }

  const titles = {
    approved: 'Approve Documents',
    hold: 'Hold Documents',
    rejected: 'Reject Documents',
  }

  const content = {
    approved: (
      <>
        <Typography color="darkgray.main" mb={6}>
          You are about to approve {`${itemCount} ${itemsDisplay[plural]}`}.
        </Typography>
        <Typography color="darkgray.main">
          Please confirm this action and click Confirm below, or click Cancel to
          go back to the previous screen.
        </Typography>
      </>
    ),
    hold: (
      <>
        <Typography color="darkgray.main" mb={6}>
          You are about to hold {`${itemCount} ${itemsDisplay[plural]}`}.
        </Typography>
        <Typography color="darkgray.main">
          Please confirm this action and click Confirm below, or click Cancel to
          go back to the previous screen.
        </Typography>
      </>
    ),
    rejected: (
      <>
        <Typography color="darkgray.main" mb={6}>
          You are about to reject {`${itemCount} ${itemsDisplay[plural]}`}.
        </Typography>
        <Typography color="darkgray.main">
          Please confirm this action and click Confirm below, or click Cancel to
          go back to the previous screen.
        </Typography>
      </>
    ),
  }

  const notificationContent = {
    approved: (
      <>
        {`${itemCount} ${itemsDisplay[plural]} ${verbDisplay[plural]}`} been
        approved. You can find them in the{' '}
        <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setTabIndex(3)}>
          Approved
        </MuiLink>{' '}
        tab
      </>
    ),
    hold: (
      <>
        {`${itemCount} ${itemsDisplay[plural]} ${verbDisplay[plural]}`} been put
        on hold. You can find them in the{' '}
        <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setTabIndex(1)}>
          On Hold
        </MuiLink>{' '}
        tab
      </>
    ),
    rejected: (
      <>
        {`${itemCount} ${itemsDisplay[plural]} ${verbDisplay[plural]}`} been
        rejected. You can find them in the{' '}
        <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setTabIndex(2)}>
          Rejected
        </MuiLink>{' '}
        tab
      </>
    ),
  }

  const handleCompleteConfirm = async () => {
    const executeConfirmAction = async () => {
      const success = await handleConfirm(selectedItems)
      const notifVariant =
        variant === 'download' && itemCount > 1 ? 'downloads' : variant

      const NotificationComponent = (props) => (
        <DownloadsTemplate {...props}>
          {notificationContent[notifVariant] ??
            customNotification(selectedItems)}
        </DownloadsTemplate>
      )

      if (success) {
        setComponent(NotificationComponent)
        setNotifOpen(true)
      }
    }

    setModalProps({
      size: 'md',
      hideButtonsBorder: true,
      title: titles[variant] ?? customTitle,
    })
    setModalComponent(LoadingModal)
    setModalOpen(true)
    await executeConfirmAction()
    setOpen(false)
    setModalOpen(false)
  }

  const handleModalConfirm = () => {
    const footerButtonProps = [
      {
        children: 'Confirm',
        color: 'primary',
        variant: 'contained',
        onClick: () => handleCompleteConfirm(),
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: () => setModalOpen(false),
      },
    ]

    setModalProps({
      children: content[variant] ?? customContent(selectedItems),
      title: titles[variant] ?? customTitle,
      size: 'md',
      footerButtonProps,
    })

    setModalOpen(true)
  }

  const footerButtonProps = [
    {
      children: 'Confirm',
      color: 'primary',
      variant: 'contained',
      onClick: () => handleCompleteConfirm(),
    },
    {
      children: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      onClick: () => setOpen(false),
    },
  ]

  useEffect(() => {
    setSelectedItems(addDisplay(items))
  }, [items])

  useEffect(() => {
    if (items.length === 1 && open) {
      handleModalConfirm()
      setOpen(false)
    }
  }, [items, open])

  useEffect(() => {
    if (selectedItems.length === 0 && open) {
      setOpen(false)
      setSelectedItems(addDisplay(items))
    }
  }, [selectedItems])

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      size="md"
      footerButtonProps={footerButtonProps}
    >
      <RemovableItems
        items={selectedItems}
        handleRemove={handleRemove}
        title={titles[variant] ?? customTitle ?? 'LETTER'}
        idKey="id"
        max={5}
      />
      <Box sx={{ mt: 2 }}>
        {content[variant] ?? customContent(selectedItems)}
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
