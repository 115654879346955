import React, { useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import CardHeader from '@mui/material/CardHeader'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/styles/useTheme'

import ScrollableBox from 'components/styled/ScrollableBox'
import ActionButtons from './ActionButtons'

function Modal({
  children,
  title,
  subTitle,
  scrollable = true,
  size,
  height,
  width,
  footerButtonProps = [],
  hideButtonsBorder = false,
  ...props
}) {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('sm'))
  const smDown = useMediaQuery(theme.breakpoints.down('xs'))

  const getSize = useCallback(() => {
    let modalSize = size
    let h = undefined
    let w = undefined

    if (smDown && size) {
      modalSize = 'sm'
    } else if (mdDown && size) {
      modalSize = 'md'
    }

    switch (modalSize) {
      case 'lg':
        h = '520px'
        w = '1144px'
        break
      case 'md':
        h = '368px'
        w = '732px'
        break
      case 'sm':
        h = '368px'
        w = '414px'
        break
      default:
        h = height ?? '100%'
        w = width ?? '100%'
        break
    }

    return {
      height: h,
      width: w,
      maxWidth: w,
    }
  }, [size, mdDown, smDown])

  const modalSize = getSize()

  return (
    <Dialog
      {...props}
      PaperProps={{
        sx: {
          ...modalSize,
          height: { sm: modalSize.height },
        },
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pt: 10,
              pb: 22,
              height: '100%',
            }}
          >
            <CardHeader
              title={title}
              subheader={subTitle}
              sx={{ padding: 0, marginX: '48px' }}
              titleTypographyProps={{
                variant: 'h1',
                sx: {
                  mb: 6,
                },
              }}
              subheaderTypographyProps={{
                variant: 'h2',
                color: 'textPrimary',
              }}
            ></CardHeader>
            <ScrollableBox
              isPerfectScrollbar={false}
              sx={{
                px: { xs: 4, sm: 12 },
                pb: 12,
                overflowY: scrollable ? 'scroll' : 'hidden',
              }}
            >
              {children}
            </ScrollableBox>
          </Box>
          <Box
            sx={{
              py: 6,
              px: '48px',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              boxShadow:
                !hideButtonsBorder && '0px 4px 8px rgba(0, 40, 86, 0.5)',
              backgroundColor: 'white',
              zIndex: 1,
            }}
          >
            <ActionButtons footer actionProps={footerButtonProps} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default Modal
