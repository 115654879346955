const KB = 1024
const MB = 1024 * KB
const GB = 1024 * MB

const getFilesizeDisplay = (bytes) => {
  if (!bytes) return 'N/A'

  if (bytes < KB) {
    return `${bytes} bytes`
  } else if (bytes < MB) {
    return `${(bytes / KB).toFixed(2)} KB`
  } else if (bytes < GB) {
    return `${(bytes / MB).toFixed(2)} MB`
  } else {
    return `${(bytes / GB).toFixed(2)} GB`
  }
}

export default getFilesizeDisplay
