import React, { useState, useEffect, useMemo, useCallback } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import DataList from 'components/common/DataList'
import Tabs from './Tabs'

import useNotification from 'hooks/context/useNotification'
import useModal from 'hooks/context/useModal'
import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import useDocumentExtracts from 'hooks/documents/useDocumentExtracts'
import { useUserData } from 'hooks/users'

import { DATALIST_DIMENSIONS } from 'utils/constants'

import DownloadIcon from '@mui/icons-material/Download'

function DownloadsDataList() {
  const { setModalProps, setOpen: setModalOpen } = useModal()
  const { formatUserTimeZone } = useUserData()

  // datalist
  const [sortList, setSortList] = useState({})

  // tabs
  const [tabIndex, setTabIndex] = useState(0)

  // notification
  const { setBasicNotification, setError } = useNotification()

  const {
    page,
    pageSize,
    setTotal,
    setTotalPages,
    setPageSize,
    initialized,
    layoutType,
    setLayoutType,
  } = useDataListContext()

  const documentsHookParams = {
    'paging[page]': page,
    'paging[page_size]': pageSize,
    embed: 'metadata',
    ...sortList,
  }

  const {
    downloads,
    total,
    loading,
    totalPages,
    pageSize: docPageSize,
    downloadZip,
  } = useDocumentExtracts(documentsHookParams)

  const handleDownload = async (docExtract) => {
    try {
      await downloadZip(docExtract)

      setBasicNotification(
        `${docExtract.filename} has been automatically downloaded to your computer.`
      )
    } catch (err) {
      setError(err.response?.data?.display_message || err.message)
    }
  }

  const visibleColumns = useMemo(() => {
    return [
      'status',
      'datetime_requested',
      'count_documents',
      'parameters',
      'requesting_user_display',
    ]
  }, [])

  const [actionButtonProps] = useState([
    {
      children: (
        <>
          <DownloadIcon />
          Download
        </>
      ),
      color: 'primary',
      variant: 'outlined',
      size: 'action-header',
      sx: {
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: 'hover.main',
        },
      },
      onClick: (props) => handleDownload(props.rowData),
      disabled: (props) => props.rowData?.status === 'Pending',
    },
  ])

  const prepareRowData = useCallback((row) => {
    const displayParameters = {
      ...row.request_parameters,
    }

    if (row.request_parameters && Object.keys(displayParameters)) {
      Object.keys(displayParameters).forEach((key) => {
        const value = displayParameters[key]

        if (typeof value === 'string' && value.split(',').length > 1) {
          displayParameters[key] = {
            data: value.split(','),
            setModalProps,
            setOpen: setModalOpen,
          }
        }
      })
    }

    return {
      ...row,
      parameters: displayParameters,
      datetime_requested: {
        'datetime[start]': formatUserTimeZone(new Date(row.datetime_requested)),
        'datetime[end]': row.datetime_processed
          ? formatUserTimeZone(new Date(row.datetime_processed))
          : null,
      },
    }
  }, [])

  const getColumnDisplay = useCallback((col) => {
    const editDisplay = (display) =>
      display
        .replace(/_/gm, ' ')
        .replace(/.metadata./gm, '')
        .toUpperCase()

    const colMappings = {
      count_documents: 'Quantity',
      requesting_user_display: 'Requested By',
      datetime_requested: 'Requested',
      datetime_created: 'Created Date',
    }

    return editDisplay(colMappings[col] || col)
  }, [])

  const getRowDisplay = useCallback((rowData, columnName) => {
    return rowData[columnName]?.toString() ?? 'N/A'
  }, [])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: downloads ?? [],
    presetName: 'downloads',
    setSortList,
    prepareRowData,
    getColumnDisplay,
    getRowDisplay,
  })

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages) // assume total pages updates alongside total
      setPageSize(docPageSize, false)
    }
  }, [total, totalPages])

  const dataListDisplay = useMemo(() => {
    const showDataList = loading || dataList?.length > 0

    return (
      showDataList && (
        <Card sx={{ boxShadow: 2, mb: 2 }}>
          <DataList
            {...dataListProps}
            dataList={dataList}
            columns={columns}
            loading={loading}
            validating={initialized}
            visibleColumns={visibleColumns}
            height={DATALIST_DIMENSIONS.HEIGHT}
            layoutType={'downloads'}
            fixedButtonProps={actionButtonProps}
            minWidth={null}
            multiSelect={false}
            expandable={true}
          />
        </Card>
      )
    )
  }, [
    dataListProps,
    dataList,
    columns,
    loading,
    initialized,
    layoutType,
    visibleColumns,
  ])

  return (
    <>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Tabs
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
          layoutType={layoutType}
          setLayoutType={setLayoutType}
          items={downloads}
        />
        <Box sx={{ px: 8, pt: 6, width: '100%' }}>{dataListDisplay}</Box>
      </Box>
    </>
  )
}

export default DownloadsDataList
