import React, { useMemo } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ExpandedListHR from 'components/styled/ExpandedListHR'
import StatusTimeline from 'components/common/StatusTimeline'

function StatusHistoryDisplay({
  statusHistory = [],
  title = 'Status History',
  currentStatus,
}) {
  const updatedStatusHistory = useMemo(() => {
    const statusHistoryDisplay = [...statusHistory]

    if (
      typeof currentStatus.status === 'string' &&
      currentStatus.status.toLowerCase() === 'partially mailed'
    ) {
      // ASSUMPTION: mailed state exists in statusHistory of Partially Mailed orders
      const foundIndex = statusHistoryDisplay.findIndex(
        (status) => status.status.toLowerCase() === 'mailed'
      )

      if (foundIndex > -1) {
        statusHistoryDisplay[foundIndex].status = 'Partially Mailed'
      }
    }

    return statusHistoryDisplay
  }, [statusHistory, currentStatus])

  return (
    <Grid container sx={{ mb: 24 }}>
      <Grid item xs={12}>
        <Typography fontWeight="medium" fontSize={14}>
          {title}
        </Typography>
        <ExpandedListHR />
        <Box sx={{ ml: 6 }}>
          <StatusTimeline statusHistory={updatedStatusHistory} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default StatusHistoryDisplay
