import React, { useState, forwardRef } from 'react'

import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const PopoverButton = forwardRef(
  (
    {
      popoverMessage = 'Are you sure you want to perform this action?',
      popoverProps,
      popover,
      ...props
    },
    ref
  ) => {
    const [anchor, setAnchor] = useState(null)

    const handleClose = () => {
      setAnchor(null)
    }

    const handleAccept = (e) => {
      const { onClick } = props

      if (onClick) props.onClick(e)

      handleClose()
    }

    const handleClick = (e) => {
      const { onClick } = props

      if (popover) {
        setAnchor(e.currentTarget)
      } else if (onClick) {
        onClick(e)
      }
    }

    return (
      <>
        <LoadingButton {...props} ref={ref} onClick={handleClick} />
        {popover && (
          <Popover
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            BackdropProps={{ invisible: false }}
            {...popoverProps}
          >
            <Box sx={{ p: 4 }}>
              <Typography>{popoverMessage}</Typography>
              <Box
                sx={{ float: 'right', my: 1, '& > .MuiButton-root': { mr: 2 } }}
              >
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleAccept}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Proceed
                </Button>
              </Box>
            </Box>
          </Popover>
        )}
      </>
    )
  }
)

export default PopoverButton
