import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import NotesItem from 'components/notes/NotesItem'
import ExpandedListHR from 'components/styled/ExpandedListHR'

function Notes({ notes }) {
  return (
    <>
      <Typography fontWeight="medium" fontSize={14}>
        Last Notes
      </Typography>
      <ExpandedListHR />
      <Grid container spacing={14}>
        {notes.slice(0, 2).map((note, index) => (
          <Grid key={`note-${index}`} item xs={6}>
            <NotesItem note={note} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Notes
