import React, { useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { create } from 'jss'
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro'
import jwtDecode from 'jwt-decode'

import axios from 'utils/axios'

import { StyledEngineProvider } from '@mui/styled-engine-sc'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StylesProvider from '@mui/styles/StylesProvider'
import jssPreset from '@mui/styles/jssPreset'

import createTheme from './theme'
import routes, { GROUPED_ROUTES } from './routes'

import { AuthProvider } from 'contexts/JWTContext'
import { ConfigurationProvider } from 'contexts/ConfigurationContext'
import { ModalProvider } from 'contexts/component/ModalContext'
import { SidebarProvider } from 'contexts/SidebarContext'
import { NotificationProvider } from 'contexts/component/NotificationContext'
import { ThemeProvider } from 'contexts/ThemeContext'

import { isValidToken } from 'utils/jwt'
import { APP_KEY } from 'utils/constants'

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
})

const canAccessRoute = (route, userRoutes) => {
  // if user has access to module, return true
  if (userRoutes.includes(route.path)) {
    return true
  } else if (!route?.children) {
    return false
  }

  // check if any of the module's children are accessible
  const children = route.children

  if (children?.length > 0) {
    return children.some((child) => canAccessRoute(child, userRoutes))
  } else {
    return false
  }
}

const getAccessibleRoute = (route, userRoutes) => {
  if (route.optional && !canAccessRoute(route, userRoutes)) return null

  let children = route.children

  if (route.children && route.optional) {
    children =
      route.children?.length > 0
        ? route.children
            .map((child) => getAccessibleRoute(child, userRoutes))
            .filter((r) => r)
        : []
  }

  return {
    ...route,
    children,
  }
}

function App() {
  const [availableRoutes, setAvailableRoutes] = useState(routes)
  const content = useRoutes(availableRoutes)

  useEffect(() => {
    const asyncHandler = async () => {
      // get user access token from localStorage
      const accessToken = localStorage.getItem('accessToken')

      // if no access token, allow all routes
      if (!isValidToken({ accessToken })) {
        setAvailableRoutes(routes)
        return
      }

      // else get user id from access token
      const { UserID } = jwtDecode(accessToken)

      // use user id to get user's accessible routes
      const res = await axios.get(`/users/${UserID}`, {
        params: {
          embed: '*',
          app_key: APP_KEY,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      // allow user to access either the provided route or a list of routes
      // defined in the GROUPED_ROUTES constant
      const userModules = res.data._embedded.accessible_modules.flatMap((m) =>
        GROUPED_ROUTES[m.name]
          ? GROUPED_ROUTES[m.name]
          : [m.name.replace(/_/g, '-')]
      )

      // filter out routes the user does not have access to
      const newRoutes = routes
        .map((route) => getAccessibleRoute(route, userModules))
        .filter((r) => r)

      setAvailableRoutes(newRoutes)
    }

    asyncHandler()
  }, [])

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | MPXLinq"
        defaultTitle="MPXLinq - MPX Online"
      />
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme()}>
              <StyledThemeProvider theme={createTheme()}>
                <AuthProvider>
                  <ThemeProvider>
                    <ConfigurationProvider>
                      <NotificationProvider>
                        <ModalProvider>
                          <SidebarProvider>{content}</SidebarProvider>
                        </ModalProvider>
                      </NotificationProvider>
                    </ConfigurationProvider>
                  </ThemeProvider>
                </AuthProvider>
              </StyledThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  )
}

export default App
