import React, { useState, useMemo, useEffect } from 'react'
import format from 'date-fns/format'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import DateRangePicker from 'components/common/DateRangePicker'
import SearchField from 'components/common/SearchField'
import MultiSelectDropdown from 'components/common/MultiSelectDropdown'
import ConfirmationModal from './ConfirmationModal'
import ExpediteAddressModal from './ExpediteAddressModal'
import AttachModal from './AttachModal'

import useDataListLayout from 'hooks/context/useDataListContext'
import useNotification from 'hooks/context/useNotification'
import useLetterDocumentDescriptions from 'hooks/document_approval/useLetterDocumentDescriptions'
import useAuth from 'hooks/useAuth'

import DateRangeIcon from '@mui/icons-material/DateRange'

import getDateRangePreset from 'helpers/datetime/getDateRangePreset'
import trimString from 'helpers/node/trimString'

import useConfiguration from 'hooks/useConfiguration'
import useDataListContext from 'hooks/context/useDataListContext'

import { INSTANCE_CONFIGIRATIONS, PRIVILEGES } from 'utils/constants'

const TAB_NAMES = {
  TO_REVIEW: 0,
  ON_HOLD: 1,
  REJECTED: 2,
  ACCEPTED: 3,
}

const availableStatuses = ['To Review', 'On Hold', 'Rejected', 'Approved']

function SearchTextField({ currentModule, setSearch, searchValue: searchVal }) {
  const metaSearchKeys = useMemo(() => {
    const config = currentModule?.configurations.find(
      (c) => c.configuration_type === 'module_configurations'
    )

    if (config)
      return config.settings.available_metadata_fields
        .filter((field) => field !== 'Description')
        .map((field) => `_.metadata.${field}`)
    return []
  }, [currentModule])
  const filterFields = ['id', 'addresses.to.name']
    .concat(metaSearchKeys)
    .join('|')

  const [lastSearch, setLastSearch] = useState('')
  const [localValue, setLocalValue] = useState(lastSearch)

  const handleChange = (val) => {
    const searchValue = trimString(val ?? localValue)

    setSearch({
      'filter[field]': Boolean(searchValue) ? filterFields : undefined,
      'filter[term]': searchValue || undefined,
    })
    setLastSearch(searchValue)
  }

  useEffect(() => {
    if (localValue !== searchVal) {
      setLocalValue(searchVal)
    }
  }, [searchVal])

  return (
    <SearchField
      fullWidth
      size="small"
      label="Search"
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleChange()
        }
      }}
      onSearch={handleChange}
    />
  )
}

function DocumentApprovalHeader({
  tabIndex,
  items,
  getAllItems,
  setTabIndex,
  updateLetters,
  attachLetters,
  updateSearchedLetters,
  documentsLoading,
  filterList,
  setFilterList,
  layoutType,
  activeIndex,
  total,
}) {
  const { user } = useAuth()
  const {
    hasPrivilege,
    currentModule,
    getMetadataDisplay,
    instanceConfigurations,
  } = useConfiguration()
  const { checkAllPreset } = useDataListContext()

  const { ON_HOLD, REJECTED, ACCEPTED } = TAB_NAMES
  const hideApprove =
    tabIndex === REJECTED ||
    !hasPrivilege(PRIVILEGES.APPROVE_DOCUMENT) ||
    tabIndex === ACCEPTED
  const hideHold =
    tabIndex === ON_HOLD ||
    tabIndex === REJECTED ||
    !hasPrivilege(PRIVILEGES.HOLD_DOCUMENT)
  const hideReject =
    tabIndex === REJECTED || !hasPrivilege(PRIVILEGES.REJECT_DOCUMENT)
  const hideExpedite =
    !hasPrivilege(PRIVILEGES.EXPEDITE_DOCUMENT) ||
    instanceConfigurations?.[INSTANCE_CONFIGIRATIONS.ENABLE_LETTER_EXPEDITE] !==
      '1'
  const hideAttach =
    !hasPrivilege(PRIVILEGES.ATTACH_DOCUMENT) ||
    instanceConfigurations?.[INSTANCE_CONFIGIRATIONS.ENABLE_LETTER_ATTACH] !==
      '1'

  const [confirmModalProps, setConfirmModalProps] = useState({})
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [expediteModalOpen, setExpediteModalOpen] = useState(false)
  const [attachModalOpen, setAttachModalOpen] = useState(false)

  // filter inputs
  const [search, setSearch] = useState({})
  const [orderId, setOrderId] = useState('')
  const [descriptions, setDescriptions] = useState([])
  const [dateRange, setDateRange] = useState([undefined, undefined])

  const { checkedCount, checked, allChecked, clearChecked, pageSize } =
    useDataListLayout()
  const { setError, setBasicNotification } = useNotification()
  const { descriptions: descNames } = useLetterDocumentDescriptions({
    type: 'document-approval',
    status_filter: availableStatuses[tabIndex],
  })

  const [checkedItems, setCheckedItems] = useState([])

  useEffect(() => {
    if (checkedCount <= 0) {
      if (layoutType === 'documentapprovalpreview') {
        setCheckedItems([items?.[activeIndex]] ?? [])
        return
      } else {
        setCheckedItems([])
        return
      }
    }

    if (checkedCount === total && total > pageSize) {
      setCheckedItems([])
      return
    } else if (allChecked) {
      setCheckedItems(items)
    } else {
      setCheckedItems(
        items
          .map((item, index) => ({ ...item, checkedIndex: index }))
          .filter((_, index) => checked[index])
      )
    }
  }, [checkedCount, layoutType, items, allChecked, checked])

  const itemName = {
    singular: 'letter',
    plural: 'letters',
  }

  const handleDateChange = (dateRangeValue) => {
    setDateRange(dateRangeValue)
  }

  const handlePatch = async (selected, data) => {
    let success = true
    const updateData = {
      ...data,
      user_detail: user.display,
    }

    try {
      if (checkAllPreset === 'all-matching-query') {
        await updateSearchedLetters({ updateData, checkAllPreset })
      } else {
        const updateDataArray = selected.map((letter) => ({
          ...updateData,
          id: letter.id,
        }))

        await updateLetters(updateDataArray)
      }
    } catch (err) {
      setError(err.message)
      success = false
    }

    if (success) clearChecked()

    return success
  }

  const handleAttachAction = async (selected, data) => {
    let success = true
    const updateData = {
      user_detail: user.display,
    }

    try {
      const updateDataArray = selected.map((letter) => ({
        ...updateData,
        id: letter.id,
      }))

      await attachLetters(updateDataArray, data.file)
    } catch (err) {
      setError(err.message)
      success = false
    }

    if (success) {
      clearChecked()
      setBasicNotification(
        'All selected documents have had a new file attached'
      )
    }

    return success
  }

  const handleExpediteAction = async (selected, data) => {
    let success = true
    const updateData = {
      ...data,
      user_detail: user.display,
    }

    try {
      const updateDataArray = selected.map((letter) => ({
        ...updateData,
        id: letter.id,
      }))

      await updateLetters(updateDataArray)
    } catch (err) {
      setError(err.message)
      success = false
    }

    if (success) {
      clearChecked()
      setBasicNotification('All selected documents have been expedited')
    }

    return success
  }

  const handleAction = (status) => {
    const approveSelected = async (selected) => {
      return handlePatch(selected, {
        status,
      })
    }

    // open confirmation modal
    setConfirmModalProps({
      ...confirmModalProps,
      variant: status,
      handleConfirm: approveSelected,
    })
    setConfirmModalOpen(true)
  }

  const buttonsDisabled =
    !items?.length ||
    (checkedCount <= 0 && layoutType !== 'documentapprovalpreview')

  useEffect(() => {
    const getDateValues = () => {
      const [start, end] = dateRange
      let newEndDate = end || start

      if (!start && !end) {
        return [undefined, undefined]
      }

      // include end date
      const newDateRange = getDateRangePreset({
        baseDate: newEndDate,
        daysAfter: 1,
      })
      newEndDate = newDateRange[1]

      return [
        (start && format(start, `yyyy-MM-dd'T'hh:mm:ss+0000`)) || undefined,
        (newEndDate && format(newEndDate, `yyyy-MM-dd'T'hh:mm:ss+0000`)) ||
          undefined,
      ]
    }

    const utcDateRange = getDateValues()

    let searchVal = descriptions.length
      ? `_.metadata.Description|${descriptions.join(';;')}`
      : ''

    if (orderId) {
      searchVal = [searchVal, `import_order_id|${trimString(orderId)}`]
        .filter((s) => s)
        .join('|')
    }

    setFilterList({
      'datetime_created[start]': utcDateRange[0] || undefined,
      'datetime_created[end]': utcDateRange[1] || undefined,
      ...search,
      search: searchVal || undefined,
    })
  }, [search, descriptions, dateRange, orderId])

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', mb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            height: '100%',
            minWidth: '444px',
            '& > .MuiButton-root': {
              my: 'auto',
            },
          }}
        >
          <Button
            color="primary"
            variant="contained"
            size="action-footer"
            disabled={buttonsDisabled}
            onClick={() => handleAction('approved')}
            sx={{ display: hideApprove && 'none', mr: 4 }}
          >
            Approve
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size="action-footer"
            disabled={buttonsDisabled}
            onClick={() => handleAction('hold')}
            sx={{ display: hideHold && 'none', mr: 4 }}
          >
            Hold
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="action-footer"
            disabled={buttonsDisabled}
            onClick={() => handleAction('rejected')}
            sx={{ display: hideReject && 'none', mr: 4 }}
          >
            Reject
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="action-footer"
            disabled={buttonsDisabled}
            onClick={() => setExpediteModalOpen(true)}
            sx={{ display: hideExpedite || hideApprove ? 'none' : '', mr: 4 }}
          >
            Expedite
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="action-footer"
            disabled={buttonsDisabled}
            onClick={() => setAttachModalOpen(true)}
            sx={{ display: hideAttach && 'none', mr: 4 }}
          >
            Attach
          </Button>
        </Box>
        <Box sx={{ minWidth: '24px' }}>
          {checkedCount > 0 && (
            <Box sx={{ px: 4, pt: 2 }}>
              <Typography>
                {`${checkedCount} ${
                  checkedCount === 1 ? itemName.singular : itemName.plural
                } selected`}
              </Typography>
            </Box>
          )}
          {items.length === 0 && !documentsLoading && (
            <Box sx={{ px: 4, pt: 2 }}>
              <Typography>No {itemName.plural} found</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            height: '100%',
            minWidth: '440px',
            '& > .MuiButton-root': {
              my: 'auto',
            },
          }}
        >
          <Grid container spacing={6}>
            <Grid item xs={3}>
              <DateRangePicker
                value={dateRange}
                setValue={handleDateChange}
                fullWidth
                size="small"
                label="Date"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateRangeIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MultiSelectDropdown
                value={descriptions}
                onChange={(e) => setDescriptions(e.target.value)}
                name={getMetadataDisplay('_.metadata.Description')}
                showChips={false}
                options={descNames}
                textFieldProps={{
                  size: 'small',
                  label: getMetadataDisplay('_.metadata.Description'),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                name="orderId"
                label="Order ID"
                placeholder="Order ID"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <SearchTextField
                currentModule={currentModule}
                setSearch={setSearch}
                searchValue={filterList?.['filter[term]'] || ''}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ConfirmationModal
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        items={checkedItems}
        checkedCount={checkedCount}
        {...confirmModalProps}
      />
      <ExpediteAddressModal
        open={expediteModalOpen}
        setOpen={setExpediteModalOpen}
        items={checkedItems}
        handleConfirm={handleExpediteAction}
      />
      <AttachModal
        open={attachModalOpen}
        setOpen={setAttachModalOpen}
        items={checkedItems}
        handleConfirm={handleAttachAction}
      />
    </>
  )
}

export default DocumentApprovalHeader
