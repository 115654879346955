import React from 'react'

import AlertsTemplate from './templates/AlertsTemplate'

function ErrorNotification({ children, ...props }) {
  return (
    <AlertsTemplate {...props} alertProps={{ severity: 'error' }}>
      {children}
    </AlertsTemplate>
  )
}

export default ErrorNotification
