import baseAxios from 'axios'
import axios from 'utils/axios'

const fetcher = async (url) => {
  try {
    const res = await axios.get(url, { headers: { Accept: '*/*' } })

    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Fetching ${url} failed with status ${res.status}`)
    }
  } catch (error) {
    return { error }
  }
}

export const postFetcher = async (url, params) => {
  try {
    const res = await axios.post(url, params, { headers: { Accept: '*/*' } })

    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Fetching ${url} failed with status ${res.status}`)
    }
  } catch (error) {
    return { error }
  }
}

export const etagFetcher = async (url) => {
  try {
    const res = await axios.get(url, { headers: { Accept: '*/*' } })

    if (res.status === 200) {
      res.data.etag = res.headers.etag
      return res.data
    } else {
      throw new Error(`Fetching ${url} failed with status ${res.status}`)
    }
  } catch (error) {
    return { error }
  }
}

export const outgoingFetcher = async (url) => {
  try {
    const res = await baseAxios.get(url, { headers: { Accept: '*/*' } })

    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Fetching ${url} failed with status ${res.status}`)
    }
  } catch (error) {
    return { error }
  }
}

export default fetcher
