import React from 'react'

import Box from '@mui/material/Box'

import withDataList from 'hocs/withDataList'

function PureDataListLayout({ children }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {children}
      </Box>
    </>
  )
}

export default withDataList(PureDataListLayout)
