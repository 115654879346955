import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Modal from 'components/common/Modal'
import StatesAutocomplete from 'components/common/StatesAutocomplete'

import useNotification from 'hooks/context/useNotification'

import stateJson from 'json/states.json'

const editableFields = [
  {
    field: 'agency_code',
    display: 'Agency Code',
  },
  {
    field: 'name',
    display: 'Name',
  },
  {
    field: 'address1',
    display: 'Address 1',
  },
  {
    field: 'address2',
    display: 'Address 2',
  },
  {
    field: 'city',
    display: 'City',
  },
  {
    field: 'state',
    display: 'State',
  },
  {
    field: 'zip',
    display: 'Zip-Code',
  },
]

const validationSchema = Yup.object().shape({
  agency_code: Yup.string()
    .typeError('Invalid agency code')
    .max(10, 'Agency code must be 10 characters or less')
    .required('Agency code is required'),
  name: Yup.string().typeError('Invalid name').required('Name is required'),
  address1: Yup.string()
    .typeError('Invalid address')
    .required('Address is required'),
  address2: Yup.string().typeError('Invalid address2'),
  city: Yup.string().typeError('Invalid city').required('City is required'),
  zip: Yup.string()
    .matches(/^(\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d)$/, 'Invalid zip-code')
    .typeError('Invalid zip-code')
    .required('Zip-Code is required'),
  notes: Yup.string().typeError('Invalid notes'),
})

function MMGEditPreferencesModal({
  open,
  setOpen,
  agency,
  addAgency,
  updateAgencyPreferences,
}) {
  const isAddModal = !Boolean(agency)
  const { setBasicNotification, setError } = useNotification()
  const formikRef = useRef()
  const [selectedState, setSelectedState] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  const initialValues = useMemo(() => {
    const values = {
      notes: agency?.notes || '',
    }

    editableFields.forEach((field) => {
      values[field.field] = agency?.[field.field] || ''
    })

    return values
  }, [agency])

  const handleSubmit = useCallback(
    async (values, { setErrors }) => {
      try {
        let errorMessage = ''
        let statesValue = values.state || ''

        if (!statesValue && !searchValue) {
          errorMessage = 'State is required'
        } else if (!statesValue) {
          statesValue = searchValue?.toUpperCase()?.trim() || ''
        }

        const foundState = stateJson.find(
          (state) =>
            state.abbreviation === statesValue ||
            state.name.toUpperCase() === statesValue
        )

        if (!foundState) {
          errorMessage = 'Invalid state'
        } else {
          values.state = foundState.abbreviation
        }

        if (errorMessage) {
          setErrors({
            state: errorMessage,
          })
          return
        }

        let message = `${agency?.name} has been successfully updated!`

        if (isAddModal) {
          await addAgency({ values })
          message = `${values.name} has been successfully added!`
        } else {
          const body = { values: {} }

          Object.entries(values).forEach(([k, v]) => {
            if (agency[k] !== v) {
              body.values[k] = v
            }
          })

          await updateAgencyPreferences({
            agencyId: agency?.agency_code,
            ...body,
          })
          message = `${agency?.name} has been successfully updated!`
        }

        setBasicNotification(message)
        setOpen(false)
      } catch (err) {
        setError(
          err.response?.data?.display_message ||
            err?.message ||
            'Unable to update agency.'
        )
      }
    },
    [
      agency,
      updateAgencyPreferences,
      addAgency,
      setOpen,
      isAddModal,
      setBasicNotification,
      searchValue,
    ]
  )

  const footerButtonProps = useMemo(() => {
    return [
      {
        children: <Box>Save Agency Info</Box>,
        variant: 'contained',
        color: 'primary',
        onClick: () => {
          formikRef.current.submitForm()
        },
      },
      {
        children: 'Cancel',
        variant: 'outlined',
        color: 'primary',
        onClick: () => setOpen(false),
      },
    ]
  }, [formikRef, setOpen])

  useEffect(() => {
    if (isAddModal) setSelectedState(null)
  }, [isAddModal])

  return (
    <Modal
      open={open}
      onClose={() => setOpen(null)}
      height="90vh"
      width="732px"
      title={
        isAddModal ? 'New Agency' : `${agency?.name} (${agency?.agency_code})`
      }
      scrollable
      footerButtonProps={footerButtonProps}
    >
      <Formik
        enableReinitialize
        innerRef={formikRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ handleChange, values, errors }) => (
          <Box sx={{ pb: 12, pt: 2 }}>
            <Stack spacing={4}>
              {editableFields.map((field) => (
                <Grid container columns={7}>
                  <Grid item xs={1}>
                    <Box>
                      <Typography>{field.display}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      {field.field === 'state' ? (
                        <StatesAutocomplete
                          label="State"
                          placeholder="State"
                          textFieldProps={{
                            size: 'small',
                            error: Boolean(errors.state),
                            helperText: errors.state,
                          }}
                          selectedOption={selectedState}
                          setSelectedOption={(state) => {
                            setSelectedState(state)
                            handleChange({
                              target: {
                                name: field.field,
                                value: state?.abbreviation || searchValue || '',
                              },
                            })
                          }}
                          value={values?.[field.field] || ''}
                          searchValue={searchValue}
                          setSearchValue={setSearchValue}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          size="small"
                          placeholder={field.display}
                          value={values?.[field.field]}
                          error={Boolean(errors?.[field.field])}
                          helperText={errors?.[field.field]}
                          onChange={handleChange}
                          name={field.field}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              ))}
              <Grid container columns={7}>
                <Grid item xs={1}>
                  <Box>
                    <Typography>Notes</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      fullWidth
                      multiline
                      name="notes"
                      rows={8}
                      value={values?.notes}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        )}
      </Formik>
    </Modal>
  )
}

export default MMGEditPreferencesModal
