import { PDFDocument } from 'pdf-lib'
import dataToArrayBuffer from 'helpers/browser/dataToArrayBuffer'

const getPdfPageCount = async (pdfBlob) => {
  if (pdfBlob) {
    const pdfData = await dataToArrayBuffer(pdfBlob, 'application/pdf')
    const pdf = await PDFDocument.load(pdfData)

    return pdf.getPages().length
  }

  return 0
}

export default getPdfPageCount
