import axios from 'utils/axios'

const fetcherPDF = async (url) => {
  try {
    const res = await axios.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        'If-None-Match': '',
      },
    })

    if (res.status !== 200) {
      throw new Error('Report not found')
    }

    return res.data
  } catch (err) {
    return null
  }
}

export default fetcherPDF
