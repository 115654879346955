import React, { useCallback } from 'react'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { useFormikContext } from 'formik'
import isEmptyObject from 'helpers/node/isEmptyObject'

import PlusIcon from '@mui/icons-material/Add'

function TagsField({
  field,
  selectedOption,
  setSelectedOption,
  display,
  inputProps,
}) {
  const { values, handleChange } = useFormikContext()
  const value = Object.values(
    !isEmptyObject(values[field] || {})
      ? values[field]
      : [{ name: '', value: '' }]
  )

  const handleAdd = useCallback(() => {
    handleChange({
      target: { name: field, value: [...value, { name: '', value: '' }] },
    })
  }, [values, handleChange])

  return (
    <Box>
      <Grid container columns={2} spacing={4}>
        {value.map((tag, tagIndex) => (
          <>
            <Grid item xs={1}>
              <TextField
                fullWidth
                name={`${field}.${tagIndex}.name`}
                label="Tag name"
                placeholder="Tag name"
                value={tag.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                fullWidth
                name={`${field}.${tagIndex}.value`}
                label="Tag value"
                placeholder="Tag value"
                value={tag.value}
                onChange={handleChange}
              />
            </Grid>
          </>
        ))}
      </Grid>
      <Button startIcon={<PlusIcon />} sx={{ mt: 4 }} onClick={handleAdd}>
        Add new tag
      </Button>
    </Box>
  )
}

export default TagsField
