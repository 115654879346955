import { useCallback } from 'react'
import useSWR from 'swr'
import debounce from 'lodash.debounce'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

const useCollateralTemplateCategories = () => {
  const { data, isValidating, mutate } = useSWR(
    '/collateral-template-categories?embed=templates|order_data_defaults',
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const updateTemplate = useCallback(
    debounce(async (templateId, templateData, shouldMutate = false) => {
      const {
        headers: { etag },
      } = await axios.get(`/collateral-templates/${templateId}`)
      const res = await axios.patch(
        `/collateral-templates/${templateId}`,
        templateData,
        {
          headers: {
            'If-Match': etag,
          },
        }
      )

      if (res.status === 200) {
        if (shouldMutate) mutate()
        return true
      } else {
        throw new Error('Failed to update template')
      }
    }, 500),
    [mutate]
  )

  return {
    categories: data?._embedded?.categories ?? [],
    loading: (!data?._embedded?.categories && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    updateTemplate,
  }
}

export default useCollateralTemplateCategories
