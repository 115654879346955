import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

import useAuth from 'hooks/useAuth'

const useUsers = (params) => {
  const { user, updateUser: updateUserData } = useAuth()

  const { data, isValidating, mutate } = useSWR(
    params
      ? `/users?${urlSerialize({
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const getUserByDisplayName = async (displayName) => {
    const res = await axios.get(
      `/users?${urlSerialize({
        'filter[field]': 'display',
        'filter[term]': displayName,
      })}`
    )

    return res.data?._embedded?.users?.[0] ?? null
  }

  const updateUser = async ({ userId, userData }) => {
    const userEndpoint = `/users/${userId}`

    const {
      headers: { etag },
    } = await axios.get(userEndpoint)

    const res = await axios.patch(userEndpoint, userData, {
      headers: {
        'If-Match': etag,
      },
    })

    mutate()

    // if updating logged in user, update cached settings
    if (`${user.userId}` === `${userId}`) {
      await updateUserData({
        responseData: {
          token: user.accessToken,
          refresh_token: user.refreshToken,
          datetime_token_expires: user.accessTokenExpires,
          datetime_refresh_token_expires: user.refreshTokenExpires,
        },
      })
    }

    return res.data
  }

  const resetUserPassword = async ({ userId }) => {
    const endpoint = `/users/${userId}/password-reset-codes`

    const res = await axios.post(endpoint)

    return res.data
  }

  const addUser = async ({ userData }) => {
    const userEndpoint = `/users`
    const res = await axios.post(userEndpoint, {
      ...userData,
      is_active: true, // default to active on creation
    })

    mutate()

    return res.data
  }

  const deleteUser = async ({ userId }) => {
    const userEndpoint = `/users/${userId}`

    const {
      headers: { etag },
    } = await axios.get(userEndpoint)

    const res = await axios.delete(userEndpoint, {
      headers: {
        'If-Match': etag,
      },
    })

    mutate()

    return res.data
  }

  return {
    users: data?._embedded?.users ?? [],
    loading: (!data?._embedded?.users && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    getUserByDisplayName,
    mutate,
    updateUser,
    deleteUser,
    addUser,
    resetUserPassword,
  }
}

export default useUsers
