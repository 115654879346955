import { useEffect, useState } from 'react'
import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

import useInstance from 'hooks/instance/useInstance'

const useDocumentInboundItems = (params) => {
  const [data, setData] = useState({})
  const { alterDocumentInboundItems } = useInstance()

  const {
    data: apiData,
    isValidating,
    mutate,
  } = useSWR(
    params
      ? `/document-inbound-items?${urlSerialize(
          {
            ...params,
          },
          true
        )}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    const asyncHandler = async () => {
      const documentInboundItems = apiData?._embedded?.document_inbound_items
      if (documentInboundItems) {
        apiData._embedded.document_inbound_items =
          await alterDocumentInboundItems(documentInboundItems)
      }

      setData(apiData)
    }

    asyncHandler()
  }, [apiData])

  const updateInboundItem = async (id, data, shouldMutate = true) => {
    const {
      headers: { etag },
    } = await axios.get(`/document-inbound-items/${id}`)

    try {
      const res = await axios.patch(`/document-inbound-items/${id}`, data, {
        headers: {
          'If-Match': etag,
        },
      })

      if (res.status !== 200) {
        throw new Error('Error updating inbound item')
      }

      if (shouldMutate) mutate()

      return res.data
    } catch (err) {
      mutate()
      throw new Error(err.response?.data?.display_message || err.message)
    }
  }

  return {
    documentInboundItems: data?._embedded?.document_inbound_items ?? [],
    embedded: data?._embedded ?? {},
    loading:
      params &&
      ((!data?._embedded?.document_inbound_items && !data?.error) ||
        isValidating),
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    updateInboundItem,
  }
}

export default useDocumentInboundItems
