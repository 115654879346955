import React from 'react'
import { Link } from 'react-router-dom'

import DownloadsTemplate from './templates/DownloadsTemplate'

import useConfiguration from 'hooks/useConfiguration'

function DownloadsNotification(props) {
  const { canAccessModule } = useConfiguration()
  const canAccessDownloads = canAccessModule('/main/downloads')

  return (
    <DownloadsTemplate {...props}>
      We started downloading your files. This may take some time.{' '}
      {canAccessDownloads && (
        <>
          Please check <Link to="/main/downloads">Downloads</Link>.
        </>
      )}
    </DownloadsTemplate>
  )
}

export default DownloadsNotification
