import React from 'react'

import { Navigate } from 'react-router-dom'

// Layouts
import AuthLayout from 'layouts/Auth'
import LandingLayout from 'layouts/Landing'
import CornerLinq from 'layouts/CornerLinq'

// Guards
import GuestGuard from 'components/guards/GuestGuard'
import AuthGuard from 'components/guards/AuthGuard'

// Auth components
import LogIn from 'pages/auth/LogIn'
import PasswordReset from 'pages/auth/PasswordReset'
import OneTimePasswordReset from 'pages/auth/OneTimePasswordReset'
import Page404 from 'pages/auth/Page404'
import Page500 from 'pages/auth/Page500'

// Landing components
import Landing from 'pages/landing/Landing'
import Home from 'pages/home/Home'

// Correspondence
import CorrespondenceSearchPage from 'pages/correspondence/Search'
import CorrespondenceResultsPage from 'pages/correspondence/Results'

// Digital Mailbox
import DocumentPreview from 'pages/digital_mailbox/DocumentPreview'
import DigitaiMailboxDocumentListPage from 'pages/digital_mailbox/DocumentList'

// Collateral
import DeliveryAddressPage from 'pages/collateral/DeliveryAddress'
import ItemsOrderListPage from 'pages/collateral/ItemsOrderList'

// Print Assets
import PrintAssets from 'pages/print_assets/PrintAssets'
import PrintAssetPreview from 'pages/print_assets/PrintAssetPreview'

// Document Approval
import DocumentApproval from 'pages/document_approval/DocumentList'

// User Maintenance
import UserMaintenance from 'pages/user_maintenance/UserMaintenance'

// User Settings
import UserSettings from 'pages/user_settings/UserSettings'

// MPX Contact
import MPXContact from 'pages/mpx_contact/MPXContact'

// Downloads
import Downloads from 'pages/downloads/Downloads'

// Reporting
import Reporting from 'pages/reporting/Reporting'
import ReportingCreate from 'pages/reporting/ReportingCreate'

// Dashboard
import Dashboard from 'pages/dashboard/Dashboard'

// Bulk Mail
import BulkMailConfigurationsList from 'pages/bulk_mail/ConfigurationsList'

// Postage
import PostagePage from 'pages/postage/Postage'
import Invoices from 'pages/postage/Invoices'

// Library
import Library from 'pages/library/Library'
import LibraryPreview from 'pages/library/LibraryPreview'

import OrderHistory from 'pages/order_history/OrderHistory'
import OrderApproval from 'pages/order_approval/OrderApproval'
import OrderProcessingReport from 'pages/order_approval/OrderProcessingReport'
import OrderDetail from 'pages/order_detail/OrderDetail'
import SFTPHistory from 'pages/sftp_history/SFTPHistory'
import TwoFactor from 'pages/auth/TwoFactor'

// Agency Preferences
import AgencyPreferencesPage from 'pages/agency_preferences/AgencyPreferences'

// Manual Bill
import ManualBill from 'pages/manual_bill/ManualBill'

// Misc
import DocumentHTML from 'pages/html/DocumentHTML'
import HelpPage from 'pages/help/Help'
import InvoicePage from 'pages/postage/Invoice'

// key-value pairs of route name as key and array of accessible paths as value
export const GROUPED_ROUTES = {
  wip: ['wip', 'order-details'],
  order_approval: ['order-approval', 'order-details'],
  correspondence: ['correspondence', 'order-details'],
}

const routes = [
  // BASE ROUTE - MUST BE FIRST
  {
    path: '/',
    element: <Navigate to="/main/dashboard" replace />,
  },
  {
    path: 'onetimelogin',
    element: (
      <GuestGuard>
        <OneTimePasswordReset />
      </GuestGuard>
    ),
  },
  {
    path: 'home',
    element: <Home />,
  },
  {
    path: 'main',
    element: (
      <AuthGuard>
        <LandingLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'downloads',
        optional: true,
        element: <Downloads />,
      },
    ],
  },
  {
    path: 'correspondence-hub',
    optional: true,
    element: (
      <AuthGuard>
        <LandingLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'correspondence',
        optional: true,
        children: [
          {
            path: 'results',
            element: <CorrespondenceResultsPage />,
          },
          {
            path: '',
            element: <CorrespondenceSearchPage />,
          },
        ],
      },
      {
        path: 'digital-mailbox',
        optional: true,
        children: [
          {
            path: ':documentId',
            element: <DocumentPreview />,
          },
          {
            path: '',
            element: <DigitaiMailboxDocumentListPage />,
          },
        ],
      },
      {
        path: 'collateral',
        optional: true,
        children: [
          {
            path: 'configure',
            element: <ItemsOrderListPage />,
          },
          {
            path: '',
            element: <DeliveryAddressPage />,
          },
        ],
      },
      {
        path: 'print-asset',
        optional: true,
        children: [
          {
            path: '',
            element: <PrintAssets />,
          },
          {
            path: ':assetId',
            element: <PrintAssetPreview />,
          },
        ],
      },
      {
        path: 'library',
        optional: true,
        children: [
          {
            path: '',
            element: <Library />,
          },
          {
            path: ':libId',
            element: <LibraryPreview />,
          },
        ],
      },
    ],
  },
  {
    path: 'command-center',
    optional: true,
    element: (
      <AuthGuard>
        <LandingLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'wip',
        optional: true,
        element: <OrderHistory />,
      },
      {
        path: 'order-approval',
        optional: true,
        element: <OrderApproval />,
      },
      {
        path: 'document-approval',
        optional: true,
        element: <DocumentApproval />,
      },
      {
        path: 'order-details',
        optional: true,
        children: [
          {
            path: ':orderId/order-processing-report',
            element: <OrderProcessingReport />,
          },
          {
            path: ':orderId',
            element: <OrderDetail />,
          },
        ],
      },
      {
        path: 'user-maintenance',
        optional: true,
        element: <UserMaintenance />,
      },
      {
        path: 'reporting',
        optional: true,
        children: [
          {
            path: '',
            element: <Reporting />,
          },
          {
            path: 'create',
            element: <ReportingCreate />,
          },
          {
            path: 'dd',
            children: [
              {
                path: ':ddReportId',
                element: <ReportingCreate />,
              },
            ],
          },
        ],
      },
      {
        path: 'sftp-stats',
        optional: false,
        element: <SFTPHistory />,
      },
      {
        path: 'bulk-mail',
        optional: true,
        children: [
          {
            path: '',
            element: <BulkMailConfigurationsList />,
          },
        ],
      },
      {
        path: 'postage',
        optional: true,
        children: [
          {
            path: '',
            element: <PostagePage />,
          },
          {
            path: 'invoices',
            children: [
              {
                path: '',
                element: <Invoices />,
              },
              {
                path: ':invoiceNumber',
                element: <InvoicePage />,
              },
            ],
          },
        ],
      },
      {
        path: 'agency-mailing',
        optional: true,
        element: <AgencyPreferencesPage />,
      },
      {
        path: 'manual-bill',
        optional: true,
        element: <ManualBill />,
      },
    ],
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <AuthLayout>
            <GuestGuard>
              <LogIn />
            </GuestGuard>
          </AuthLayout>
        ),
        children: [
          {
            path: ':shortName',
          },
        ],
      },
      {
        path: 'password-reset',
        element: (
          <CornerLinq>
            <PasswordReset />
          </CornerLinq>
        ),
        children: [
          {
            path: ':shortName',
          },
        ],
      },
      {
        path: 'two-factor',
        element: (
          <AuthLayout>
            <GuestGuard>
              <CornerLinq>
                <TwoFactor />
              </CornerLinq>
            </GuestGuard>
          </AuthLayout>
        ),
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '500',
        element: <Page500 />,
      },
    ],
  },
  {
    path: 'user-settings',
    element: (
      <AuthGuard>
        <LandingLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <UserSettings />,
      },
    ],
  },
  {
    path: 'contact-mpx',
    element: (
      <AuthGuard>
        <LandingLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <MPXContact />,
      },
    ],
  },
  {
    path: 'html',
    children: [
      {
        path: 'document/:documentId',
        element: <DocumentHTML />,
      },
    ],
  },
  {
    path: 'help',
    element: <HelpPage />,
  },
  {
    path: '*',
    element: <Landing />,
  },
]

export default routes
