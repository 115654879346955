import { useState } from 'react'
import format from 'date-fns/format'

import axios from 'utils/axios'
import { BASE_PAGE_SIZE } from 'utils/constants'

import urlSerialize from 'helpers/browser/urlSerialize'
import saveData from 'helpers/browser/saveData'

const useReport = (params) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const runReport = async (reportId, dateRange) => {
    setLoading(true)

    try {
      const res = await axios.post(
        `/reports/${reportId}/runs?${urlSerialize(params)}`,
        dateRange
          ? {
              variables: {
                datetime_start: format(dateRange[0], 'yyyy-MM-dd'),
                datetime_end: format(dateRange[1], 'yyyy-MM-dd'),
              },
            }
          : {}
      )

      setData(res.data)
    } finally {
      setLoading(false)
    }
  }

  const downloadReport = async (
    reportId,
    dateRange,
    reportTitle,
    total = BASE_PAGE_SIZE,
    type = 'application/pdf'
  ) => {
    const res = await axios.post(
      `/reports/${reportId}/runs?${urlSerialize({
        ...params,
        'paging[page]': 1,
        'paging[page_size]': total,
      })}`,
      dateRange
        ? {
            variables: {
              datetime_start: format(dateRange[0], 'yyyy-MM-dd'),
              datetime_end: format(dateRange[1], 'yyyy-MM-dd'),
            },
          }
        : {},
      {
        responseType: 'blob',
        headers: {
          Accept: type,
        },
      }
    )

    const ext = type === 'application/pdf' ? 'pdf' : 'csv'

    saveData(res.data, `${reportTitle || `Report.${Date.now()}.`}.${ext}`, type)
  }

  return {
    report: data || {},
    items: data?._embedded?.report_items || [],
    columns: data?._embedded?.report_columns || [],
    loading,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    runReport,
    downloadReport,
  }
}

export default useReport
