const stringAvatar = ({ display, color }) => {
  if (!display) {
    return {
      sx: {
        bgcolor: color,
        color: 'white',
      },
      children: '',
    }
  }

  let initials = display[0]
  const nameSplit = display.split(/\s/gm)

  if (nameSplit.length >= 2) {
    initials = `${nameSplit[0][0]}${nameSplit.slice(-1)[0][0]}`
  }

  return {
    sx: {
      bgcolor: color,
      color: 'white',
    },
    children: initials,
  }
}

export default stringAvatar
