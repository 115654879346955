import React, { forwardRef } from 'react'
import styled from 'styled-components/macro'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'

import Box from '@mui/material/Box'

const ScrollableBox = styled(Box)`
  display: block;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.darkgray.light};
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }

  .ps__rail-x,
  .ps__rail-y {
    opacity: 0.6;
    z-index: 10;
  }
`

const ScrollableBoxWrapper = forwardRef(
  (
    {
      children,
      perfectScrollbarProps,
      psRef,
      isPerfectScrollbar = true,
      ...props
    },
    ref
  ) => {
    const sx = { ...props?.sx }

    if (isPerfectScrollbar) {
      if (sx.px) {
        sx.pl = sx.px
        sx['& > *'] = {
          pr: sx.px,
        }
        delete sx.px
      }

      return (
        <ScrollableBox {...props} sx={sx} ref={ref}>
          <PerfectScrollbar
            containerRef={psRef ? (el) => (psRef.current = el) : undefined}
            {...perfectScrollbarProps}
          >
            {children}
          </PerfectScrollbar>
        </ScrollableBox>
      )
    }

    return (
      <ScrollableBox {...props} sx={sx} ref={ref}>
        {children}
      </ScrollableBox>
    )
  }
)

export default ScrollableBoxWrapper
