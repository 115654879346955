import React from 'react'

import DataListLayout from 'layouts/DataList'
import ManualBillResultsList from 'components/manual_bill/ResultsList'

function ManualBill() {
  return (
    <DataListLayout>
      <ManualBillResultsList />
    </DataListLayout>
  )
}

export default ManualBill
