const getDateRangePreset = ({ daysBefore, daysAfter, baseDate }) => {
  const today = new Date()
  const centralDate =
    baseDate || new Date(today.getFullYear(), today.getMonth(), today.getDate())

  let pastDate = new Date(centralDate)
  if (daysBefore !== undefined) {
    pastDate.setDate(centralDate.getDate() - daysBefore)
  }

  let futureDate = new Date(centralDate)
  if (daysAfter === undefined) {
    futureDate = undefined
  } else {
    futureDate.setDate(centralDate.getDate() + daysAfter)
  }

  return [pastDate, futureDate]
}

export default getDateRangePreset
