import React, { useCallback } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import useDataListContext from 'hooks/context/useDataListContext'

function PostageInvoicePreviewRowCell({ row, getDataDisplay, index, isPaid }) {
  const { activeIndex, setActiveIndex } = useDataListContext()

  const isActive = activeIndex === index
  const statusColor = isPaid ? 'green.main' : 'yellow.main'

  const handleClick = useCallback(() => {
    setActiveIndex(index)
  }, [index, setActiveIndex])

  return (
    <TableCell
      onClick={handleClick}
      sx={{
        color: 'darkgray.main',
        borderLeft: '4px solid',
        borderLeftColor: isActive ? statusColor : 'transparent',
        borderRight: '1px solid',
        borderRightColor: 'lightgray.main',
        '&:hover': {
          backgroundColor: 'hover.main',
          cursor: 'pointer',
        },
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Stack sx={{ width: '200px', overflow: 'hidden' }}>
          <Typography
            sx={{
              textDecoration: 'underline',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            INV {getDataDisplay(row, 'invoice_number')}
          </Typography>
          <Typography
            sx={{
              maxWidth: '100px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {getDataDisplay(row, 'status') ?? 'N/A'}
          </Typography>
        </Stack>
      </Box>
    </TableCell>
  )
}

function PostageInvoicePreviewRow({ row, rowKey, getDataDisplay, index }) {
  const isPaid = `${row.status}`.toLowerCase() === 'paid'

  return (
    <>
      <TableRow key={row[rowKey]}>
        <PostageInvoicePreviewRowCell
          isPaid={isPaid}
          row={row}
          getDataDisplay={getDataDisplay}
          index={index}
        />
      </TableRow>
    </>
  )
}

export default PostageInvoicePreviewRow
