import React from 'react'
import styled from 'styled-components/macro'

import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'

const StyledListItem = styled(ListItem)`
  .MuiListItemSecondaryAction-root {
    right: 0;
  }
`

function DashboardListItem({ children, divider, buttonProps }) {
  return (
    <StyledListItem
      disablePadding
      divider={divider}
      secondaryAction={
        buttonProps && (
          <>
            <Button
              size="dashboard-list-action"
              variant="contained"
              color="green"
              sx={{ fontSize: '12px', borderRadius: '4px' }}
              {...buttonProps}
            />
          </>
        )
      }
    >
      {children}
    </StyledListItem>
  )
}

export default DashboardListItem
