import getTextWidth from './getTextWidth'

const clampTextWidth = (text, maxWidth) => {
  const output = {
    text,
    clamped: text,
  }
  let textWidth = getTextWidth(output.clamped)

  if (maxWidth <= 0) return output

  while (textWidth > maxWidth) {
    output.clamped = output.clamped.slice(0, -1)
    textWidth = getTextWidth(output.clamped)
  }

  return output
}

export default clampTextWidth
