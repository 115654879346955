import React from 'react'

import ReportDataList from 'components/reporting/ReportDataList'

import PureDataListLayout from 'layouts/PureDataList'

function ReportView() {
  return (
    <PureDataListLayout>
      <ReportDataList />
    </PureDataListLayout>
  )
}

export default ReportView
