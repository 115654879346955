import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function FileTypeSquare({ children }) {
  return (
    <Box
      sx={{
        height: '76px',
        width: '76px',
        backgroundColor: 'lightgray.main',
        borderRadius: 2,
        display: 'flex',
        mr: 4,
      }}
    >
      <Typography
        sx={{ m: 'auto' }}
        fontWeight={600}
        fontSize="12px"
        color="darkgray.main"
      >
        {children}
      </Typography>
    </Box>
  )
}

export default FileTypeSquare
