import { useMemo } from 'react'
import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import axios from 'utils/axios'
import { APP_KEY } from 'utils/constants'
import fetcher from 'utils/fetcher'

const useUserRoles = (userId, params) => {
  const { data, isValidating, mutate } = useSWR(
    userId
      ? `/users/${userId}/roles?${urlSerialize({
          embed: 'privilege_sets|modules',
          app_key: APP_KEY,
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const addRole = async ({ id = userId, roleId }) => {
    await axios.post(`/users/${id}/roles`, {
      role_id: roleId,
    })

    mutate()
  }

  const removeRole = async ({ id = userId, roleId }) => {
    const {
      headers: { etag: userEtag },
    } = await axios.get(`/users/${id}`)
    const {
      headers: { etag: roleEtag },
    } = await axios.get(`/roles/${roleId}`)

    await axios.delete(`/users/${id}/roles/${roleId}`, {
      headers: {
        'If-Match': `"${roleEtag}", "${userEtag}"`,
      },
    })

    mutate()
  }

  const userModules = useMemo(() => {
    const roles = data?._embedded?.roles ?? []

    return roles.flatMap((role) => role._embedded?.modules ?? [])
  }, [data])

  return {
    roles: data?._embedded?.roles ?? [],
    userModules,
    loading: (!data?._embedded?.roles && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
    addRole,
    removeRole,
  }
}

export default useUserRoles
