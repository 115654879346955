import React, { useMemo } from 'react'

import NormalItem from './Normal'
import PreviewItem from './Preview'
import ColumnsItem from './Columns'
import OrderHistoryItem from './OrderHistory'
import OrderApprovalItem from './OrderApproval'
import DigitalMailboxItem from './DigitalMailbox'
import DocumentApprovalItem from './DocumentApproval'
import DocumentApprovalPreview from './DocumentApprovalPreview'
import UserMaintenancePreviewItem from './UserMaintenance'
import ReportingItem from './Reporting'
import DownloadsItem from './Downloads'
import SFTPHistory from './SFTPHistory'
import PrintAssetsItem from './PrintAssets'

function Item({ variant, ...props }) {
  let ItemComponent = NormalItem

  if (variant === 'columns') ItemComponent = ColumnsItem
  if (variant === 'preview') ItemComponent = PreviewItem
  if (variant === 'orderhistory') ItemComponent = OrderHistoryItem
  if (variant === 'orderapproval') ItemComponent = OrderApprovalItem
  if (variant === 'digitalmailbox') ItemComponent = DigitalMailboxItem
  if (variant === 'documentapproval') ItemComponent = DocumentApprovalItem
  if (variant === 'documentapprovalpreview')
    ItemComponent = DocumentApprovalPreview
  if (variant === 'usermaintenance') ItemComponent = UserMaintenancePreviewItem
  if (variant === 'reporting') ItemComponent = ReportingItem
  if (variant === 'downloads') ItemComponent = DownloadsItem
  if (variant === 'sftphistory') ItemComponent = SFTPHistory
  if (variant === 'printassets') ItemComponent = PrintAssetsItem

  const item = useMemo(
    () => <ItemComponent {...props} />,
    [
      props.rowData,
      props.active,
      props.checked,
      props.columns,
      props.handleCheck,
      props.visibleColumns,
      props.sortedCols,
      props.sharedSx,
      props.innerRef,
      variant,
    ]
  )

  return item
}

export default Item
