import React from 'react'

import MultiSelectDropdown from 'components/common/MultiSelectDropdown'

import { MANUAL_BILL_STATUSES } from 'utils/constants'

function StatusMultiselect(props) {
  const options = Object.values(MANUAL_BILL_STATUSES)
  return (
    <MultiSelectDropdown
      {...props}
      options={options}
      renderTags={(option) => `${props.value.length} selected`}
    />
  )
}

export default StatusMultiselect
