import React from 'react'

function MailedWhite({ width = '24', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
    >
      <circle
        cx="24"
        cy="24"
        r="24"
        fill="#fff"
        stroke="#76BC21"
        stroke-width="2"
      />
      <path
        d="M37.091 16.8656V15.7747C37.091 15.491 36.8619 15.2728 36.5892 15.2728H11.411C11.1383 15.2619 10.9092 15.491 10.9092 15.7747V16.8328C10.9092 16.9965 10.9855 17.1383 11.1055 17.2365L23.6946 26.771C23.8801 26.9128 24.1201 26.9128 24.3055 26.771L36.8837 17.2692C37.0146 17.171 37.091 17.0183 37.091 16.8656Z"
        fill="#76BC21"
      />
      <path
        d="M23.6946 28.5927L11.6074 19.429C11.3237 19.2109 10.9092 19.4181 10.9092 19.7781V33.0109C10.9092 33.2945 11.1383 33.5127 11.411 33.5127H36.5783C36.8619 33.5127 37.0801 33.2836 37.0801 33.0109V19.7999C37.0801 19.4399 36.6655 19.2327 36.3819 19.4509L24.2946 28.5818C24.1201 28.7345 23.8801 28.7345 23.6946 28.5927Z"
        fill="#76BC21"
      />
    </svg>
  )
}

export default MailedWhite
