import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const useDocumentGroups = () => {
  const { data, isValidating, mutate } = useSWR('/document-groups', fetcher, {
    revalidateOnFocus: false,
  })

  return {
    documentGroups: data?._embedded?.document_groups || [],
    loading:
      (!data?._embedded?.document_groups && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default useDocumentGroups
