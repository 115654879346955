import React from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'

import useDocumentInboundNotes from 'hooks/digital_mail/useDocumentInboundNotes'
import { useUserData } from 'hooks/users'

function DocumentHistoryModal({ open, onClose, inboundId }) {
  const { formatUserTimeZone } = useUserData()
  const { notes, loading } = useDocumentInboundNotes(inboundId, {
    type: 'System',
  })

  const modalProps = {
    title: `Detailed Document History`,
    subTitle: undefined,
    scrollable: true,
    size: undefined,
    height: '335px',
    width: '732px',
    hideButtonsBorder: false,
    footerButtonProps: [
      {
        children: 'Close',
        color: 'primary',
        variant: 'contained',
        onClick: () => onClose(),
        size: 'action-header',
      },
    ],
  }

  return (
    <Modal {...modalProps} open={open} onClose={onClose}>
      <Box sx={{ pt: 2 }}>
        {loading ? (
          <CircularProgress />
        ) : notes.length > 0 ? (
          notes.map((item, index) => (
            <Grid
              container
              sx={{
                minHeight: '32px',
                py: 1,
                borderBottom: index < notes.length - 1 && '1px solid',
                borderColor: 'lightgray.main',
              }}
              columnSpacing={1}
            >
              <Grid item xs={6}>
                <Typography>
                  {formatUserTimeZone(new Date(item.datetime_created))}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{item.note}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{item.user_display}</Typography>
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography>No history found.</Typography>
        )}
      </Box>
    </Modal>
  )
}

export default DocumentHistoryModal
