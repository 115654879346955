import { useState, useEffect } from 'react'

import useConfiguration from 'hooks/useConfiguration'

const useUserHomepage = () => {
  const {
    navigation: { modules },
  } = useConfiguration()
  const [userModules, setUserModules] = useState([])

  useEffect(() => {
    if (modules?.length > 0) {
      const allModules = modules.flatMap((m) => m.modules)
      setUserModules(allModules)
    } else {
      setUserModules([])
    }
  }, [modules])

  return {
    userModules,
  }
}

export default useUserHomepage
