import jwtDecode from 'jwt-decode'

import useDocumentInboundItems from './useDocumentInboundItems'
import useAuth from 'hooks/useAuth'
import { MODULE_NAMES, PRIVILEGES } from 'utils/constants'

import useConfiguration from 'hooks/useConfiguration'
import { useMemo } from 'react'

const useDocumentInboundAggregate = ({ startDate, endDate }) => {
  const startDateString =
    startDate instanceof Date ? startDate.toISOString() : undefined
  const endDateString =
    endDate instanceof Date && startDateString
      ? endDate.toISOString()
      : undefined
  const { hasPrivilege } = useConfiguration()
  const hasAllAccess = hasPrivilege(
    PRIVILEGES.VIEW_ALL_ITEMS,
    MODULE_NAMES.DIGITAL_MAILBOX
  )

  const sharedParams = useMemo(() => {
    const params = {
      assigned_to_user_id: null,
      'paging[page]': 1,
      'paging[page_size]': 1,
      'date_doc[start]': startDateString,
      'date_doc[end]': endDateString,
    }

    if (!startDateString) {
      delete params['date_doc[start]']
    }

    if (!startDateString || !endDateString) {
      delete params['date_doc[end]']
    }

    return params
  }, [startDateString, endDateString])

  const { user } = useAuth()
  const { UserID } = jwtDecode(user.accessToken)
  const {
    total: totalUnassigned,
    loading: totalUnassignedLoading,
    mutate: mutateUnassigned,
  } = useDocumentInboundItems(
    hasAllAccess && {
      ...sharedParams,
    }
  )
  const {
    total: totalNew,
    loading: totalNewLoading,
    mutate: mutateNew,
  } = useDocumentInboundItems(
    hasAllAccess && {
      ...sharedParams,
      status: 'NEW',
    }
  )
  const {
    total: totalClosed,
    loading: totalClosedLoading,
    mutate: mutateClosed,
  } = useDocumentInboundItems(
    hasAllAccess && {
      ...sharedParams,
      status: 'CLOSED',
    }
  )
  const {
    total: totalAssignedToUser,
    loading: totalAssignedToUserLoading,
    mutate: mutateAssigned,
  } = useDocumentInboundItems({
    ...sharedParams,
    assigned_to_user_id: UserID,
  })
  const {
    total: totalAssignedNew,
    loading: totalAssignedNewLoading,
    mutate: mutateAssignedNew,
  } = useDocumentInboundItems({
    ...sharedParams,
    assigned_to_user_id: UserID,
    status: 'NEW',
  })
  const {
    total: totalAssignedClosed,
    loading: totalAssignedClosedLoading,
    mutate: mutateAssignedClosed,
  } = useDocumentInboundItems({
    ...sharedParams,
    assigned_to_user_id: UserID,
    status: 'CLOSED',
  })

  const loading =
    totalNewLoading ||
    totalAssignedToUserLoading ||
    totalAssignedNewLoading ||
    totalUnassignedLoading ||
    totalAssignedClosedLoading ||
    totalClosedLoading

  const mutate = () => {
    mutateUnassigned()
    mutateNew()
    mutateClosed()
    mutateAssigned()
    mutateAssignedNew()
    mutateAssignedClosed()
  }

  return {
    totalAssignedNew,
    totalAssignedToUser,
    totalAssignedClosed,
    totalUnassigned,
    totalAssignedOpen: Math.max(
      (totalAssignedToUser || 0) -
        (totalAssignedClosed || 0) -
        (totalAssignedNew || 0),
      0
    ),
    totalOpen: Math.max(
      (totalUnassigned || 0) - (totalClosed || 0) - (totalNew || 0),
      0
    ),
    totalNew,
    totalClosed,
    loading,
    mutate,
  }
}

export default useDocumentInboundAggregate
