import { useState, useEffect, useMemo } from 'react'
import sub from 'date-fns/sub'
import deepEqual from 'deep-equal'

import axios from 'utils/axios'
import { POSTAGE_INVOICE_PAGE_DAYS } from 'utils/constants'

import urlSerialize from 'helpers/browser/urlSerialize'
import formatDateToUTC from 'helpers/datetime/formatDateToUTC'

const usePostageInvoiceHistoryInfinite = ({
  params,
  manualStartDate,
  manualEndDate,
}) => {
  const [cachedParams, setCachedParams] = useState(params)
  const [infiniteLoading, setInfiniteLoading] = useState(false)
  const [data, setData] = useState(null)
  const [infiniteData, setInfiniteData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  const startDate = useMemo(
    () =>
      formatDateToUTC(manualStartDate) ||
      formatDateToUTC(
        sub(new Date(), { days: POSTAGE_INVOICE_PAGE_DAYS * (page + 1) })
      ),
    [page, manualStartDate]
  )

  const endDate = useMemo(() => {
    const initial = page === 1
    const notInfinite = Boolean(manualStartDate)
    if (initial && !notInfinite) {
      return formatDateToUTC(new Date())
    }

    return !manualStartDate
      ? formatDateToUTC(
          sub(new Date(), { days: 1 + POSTAGE_INVOICE_PAGE_DAYS * page })
        )
      : formatDateToUTC(manualEndDate)
  }, [manualStartDate, manualEndDate, page])

  const handleGet = async () => {
    const notInfinite = Boolean(manualStartDate)
    const sorted = Boolean(cachedParams['sort[0]'])

    const urlParams = {
      ...cachedParams,
      'paging[page_size]': 1000,
      'paging[page]': 1,
    }

    if (!manualStartDate) {
      urlParams['date_inv[start]'] = startDate
      urlParams['date_inv[end]'] = endDate
    }

    if (!sorted) {
      urlParams['sort[0]'] = 'date_inv:desc'
    }

    const endpoint =
      Boolean(cachedParams) && `/postage-invoices?${urlSerialize(urlParams)}`

    if (endpoint) {
      if (page === 1 || notInfinite) {
        setLoading(true)
      } else {
        setInfiniteLoading(true)
      }
      try {
        const res = await axios.get(endpoint)
        const invoices = res.data._embedded?.invoices || []

        if (!sorted && invoices.length > 0) {
          invoices.sort(
            (a, b) =>
              new Date(b.datetime_created) - new Date(a.datetime_created)
          )
        }

        if (notInfinite) {
          setData([res.data])
        } else {
          setInfiniteData(infiniteData.concat([res.data]))
          setData(null)
        }
      } catch (err) {
        setData(null)
      } finally {
        // without a delay, window freezes shortly instead of showing a loader
        setTimeout(() => {
          setLoading(false)
          setInfiniteLoading(false)
        }, 100)
      }
    }
  }

  useEffect(() => {
    handleGet()
  }, [page, cachedParams])

  useEffect(() => {
    if (!deepEqual(cachedParams, params)) {
      setInfiniteData([])
      setData(null)
      setCachedParams(params)
      setPage(1)
    }
  }, [params, manualStartDate])

  const invoices = data || infiniteData || []

  return {
    invoices,
    allInvoices: invoices?.flatMap((data) => data?._embedded?.invoices) ?? [],
    total: invoices?.length
      ? invoices.reduce((sum, invoices) => sum + invoices.total, 0)
      : 1,
    loading: loading || !invoices?.length,
    additionalLoading: infiniteLoading,
    page,
    setPage: (page) => {
      setInfiniteLoading(true)
      setPage(page)
    },
    mutate: handleGet,
    startDate,
    endDate,
  }
}

export default usePostageInvoiceHistoryInfinite
