import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// local components
import ItemCheckBox from './components/ItemCheckbox'
import HoverButtons from './components/HoverButtons'

import { COLORS } from 'utils/constants'

import useDataListContext from 'hooks/context/useDataListContext'
import DataListItemWrapper from './components/DataListItemWrapper'

const StyledDataListItem = styled(Box)`
  .hover-buttons {
    display: none;
  }

  &:hover {
    .hover-buttons {
      ${({ showButtons }) => showButtons && 'display: block;'}
    }
  }
`

function DigitalMailboxItem(props) {
  const {
    index,
    rowData,
    columns,
    getDataDisplay,
    multiSelect,
    statusColName,
    visibleColumns,
    sharedSx,
  } = props
  const { checked } = useDataListContext()
  const [status, setStatus] = useState({ color: COLORS.GREEN })
  const [checkedState, setCheckedState] = useState(checked[index])

  const isConfidential = rowData?.is_confidential

  const shownColumns = useMemo(
    () =>
      visibleColumns
        .map((col) => columns.find((c) => c.id === col))
        .filter((c) => c),
    [columns, visibleColumns]
  )

  const { color } = useMemo(() => status ?? { color: 'green.main' }, [status])

  const backgroundColor = useMemo(() => {
    const isChecked = checked[index]

    if (isConfidential) {
      return isChecked ? 'lightyellow.hover' : 'lightyellow.light'
    }

    if (isChecked) {
      return 'hover.main'
    }

    return 'background.paper'
  }, [isConfidential, checked, index])

  const borderColor = useMemo(
    () => (checked[index] ? color : backgroundColor),
    [checked, index, color, backgroundColor]
  )

  useEffect(() => {
    if (rowData?.[statusColName]) {
      setStatus(rowData[statusColName])
    }
  }, [rowData, statusColName])

  return (
    <StyledDataListItem
      showButtons
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderLeft: '4px solid',
        borderLeftColor: borderColor,
        borderBottomColor: 'lightgray.main',
        bgcolor: backgroundColor,
        color: 'darkgray.main',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ListItem
        secondaryAction={<IconButton edge="end" aria-label="expand" disabled />}
        sx={{
          display: 'flex',
          overflow: 'hidden',
          my: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {multiSelect && (
            <DataListItemWrapper index={0}>
              <Box>
                <ItemCheckBox
                  index={index}
                  color="success"
                  checkedState={checkedState}
                  handleItemCheck={setCheckedState}
                />
              </Box>
            </DataListItemWrapper>
          )}
          {shownColumns.map((col, colIndex) => (
            <DataListItemWrapper key={col.id} index={colIndex + 1}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    whiteSpace: 'nowrap',
                    color: col.id === statusColName && color,
                  }}
                >
                  <Box
                    sx={{
                      my: 'auto',
                      fontSize: 14,
                    }}
                  >
                    {getDataDisplay(rowData, col.id, statusColName)}
                  </Box>
                </Box>
              </Box>
            </DataListItemWrapper>
          ))}
        </Box>
        <HoverButtons {...props} />
      </ListItem>
    </StyledDataListItem>
  )
}

export default DigitalMailboxItem
