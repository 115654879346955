import React from 'react'

import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'

import CloseIcon from '@mui/icons-material/Close'

import withNotification from 'hocs/withNotification'

function AlertsTemplate({ children, open, setOpen, alertProps, timeout }) {
  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={timeout}
      onClose={handleClose}
    >
      <Alert {...alertProps} sx={{ '& > *': { m: 'auto', p: 0 } }}>
        {children}
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Alert>
    </Snackbar>
  )
}

export default withNotification(AlertsTemplate)
