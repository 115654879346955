import React, { useMemo, useState, useEffect } from 'react'

import useAuth from 'hooks/useAuth'
import useConfiguration from 'hooks/useConfiguration'
import useUser from 'hooks/users/useUser'

import { ADMIN_USERNAME } from 'utils/constants'

function InstanceDisplay({ extraChecks = true }) {
  const {
    user: { userId, instanceId },
  } = useAuth()
  const { user } = useUser(userId)
  const { getEnvInfo } = useConfiguration()
  const [envName, setEnvName] = useState('')
  const showInstanceInfo = useMemo(() => {
    return (
      extraChecks &&
      (user?.username === ADMIN_USERNAME || envName?.includes('test'))
    )
  }, [extraChecks, user, envName])

  useEffect(() => {
    const { env } = getEnvInfo()
    setEnvName(env)
  }, [getEnvInfo])

  return showInstanceInfo ? (
    <>
      {envName || 'Prod'} / {instanceId}
    </>
  ) : (
    <></>
  )
}

export default InstanceDisplay
