import { useContext } from 'react'
import { SavedSearchModalContext } from 'contexts/SavedSearchModalContext'

const useSavedSearchModal = () => {
  const context = useContext(SavedSearchModalContext)

  if (!context)
    throw new Error(
      'SavedSearModalContext must be placed within SavedSearModalProvider'
    )

  return context
}

export default useSavedSearchModal
