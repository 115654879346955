import { useState, useEffect } from 'react'

import useSWR from 'swr'

import fetcher from 'utils/fetcher'

import urlSerialize from 'helpers/browser/urlSerialize'

const useOrderApprovalTotal = (params) => {
  const [orderData, setOrderData] = useState({})

  const { data, isValidating, mutate } = useSWR(
    params
      ? `/orders?${urlSerialize({
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    if (data?.error) {
      setOrderData({ ...orderData, error: data.error })
    } else {
      setOrderData(data)
    }
  }, [data])

  return {
    loading: !orderData?._embedded?.orders || isValidating,
    count: orderData?.count,
    total: orderData?.total,
    error: orderData?.error,
    mutate,
  }
}

export default useOrderApprovalTotal
