import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import ToggleButton from '@mui/material/ToggleButton'
import MuiTab from '@mui/material/Tab'

import ViewListIcon from '@mui/icons-material/ViewList'
import ViewModuleIcon from '@mui/icons-material/ViewModule'

import { useLetters } from 'hooks/document_approval'

const Tab = styled(MuiTab)`
  min-width: 160px;
`

function DocumentApprovalTabs({
  tabIndex,
  setTabIndex,
  layoutType,
  setLayoutType,
  items,
  loading,
}) {
  const { total: toReviewTotal, mutate: mutateReviewTotal } = useLetters({
    status_filter: 'To Review',
    'paging[page_size]': 1,
    fields: 'id',
  })
  const { total: onHoldTotal, mutate: mutateHoldTotal } = useLetters({
    status_filter: 'On Hold',
    'paging[page_size]': 1,
    fields: 'id',
  })
  const { total: rejectedTotal, mutate: mutateRejectedTotal } = useLetters({
    status_filter: 'Rejected',
    'paging[page_size]': 1,
    fields: 'id',
  })
  const { total: acceptedTotal, mutate: mutateAcceptedTotal } = useLetters({
    status_filter: 'Approved',
    'paging[page_size]': 1,
    fields: 'id',
  })

  useEffect(() => {
    mutateReviewTotal()
    mutateHoldTotal()
    mutateRejectedTotal()
    mutateAcceptedTotal()
  }, [items])

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
      <Box
        sx={{
          bgcolor: 'white',
          height: '72px',
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
        }}
      />
      <Grid
        container
        sx={{
          height: '72px',
          width: '100%',
          minWidth: '1024px',
        }}
      >
        <Grid item md={6} lg={5}>
          <Tabs
            value={tabIndex}
            onChange={(_e, value) => setTabIndex(value)}
            aria-label="document approval tabs"
            sx={{ ml: 8, mt: 6, minWidth: 160 * 4 }}
          >
            <Tab
              fullWidth
              label={`TO REVIEW (${toReviewTotal ?? 0})`}
              disabled={loading}
            />
            <Tab
              fullWidth
              label={`ON HOLD (${onHoldTotal ?? 0})`}
              disabled={loading}
            />
            <Tab
              fullWidth
              label={`REJECTED (${rejectedTotal ?? 0})`}
              disabled={loading}
            />
            <Tab
              fullWidth
              label={`APPROVED (${acceptedTotal ?? 0})`}
              disabled={loading}
            />
          </Tabs>
        </Grid>
        <Grid item md={2} lg={3} />
        <Grid item md={3} lg={4} sx={{ height: '100%', display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              height: '50%',
              width: '100%',
              my: 'auto',
              '& > *': { my: 'auto' },
              justifyContent: 'flex-end',
              justifySelf: 'flex-end',
            }}
          >
            <ToggleButton
              color="primary"
              value="list"
              sx={{ mr: 4 }}
              selected={layoutType !== 'documentapproval'}
              disabled={layoutType === 'documentapproval'}
              onClick={() => setLayoutType('documentapproval')}
            >
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton
              color="primary"
              value="list"
              sx={{ mr: 8 }}
              selected={layoutType !== 'documentapprovalpreview'}
              disabled={layoutType === 'documentapprovalpreview'}
              onClick={() => setLayoutType('documentapprovalpreview')}
            >
              <ViewModuleIcon />
            </ToggleButton>
          </Box>
        </Grid>
      </Grid>
      <Box
        fullWidth
        sx={{
          height: '1px',
          bgcolor: 'darkblue.main',
          opacity: 0.12,
        }}
      />
    </Box>
  )
}

export default DocumentApprovalTabs
