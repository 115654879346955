import React from 'react'

function Production({ width = '24', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="currentColor" />
      <path
        d="M35.5847 17.5491L25.1197 22.6681C24.934 22.7554 24.8138 22.9519 24.8138 23.1702V36.8027C24.8138 37.021 25.0323 37.1519 25.2289 37.0646L35.6939 31.9456C35.8796 31.8583 35.9998 31.6618 35.9998 31.4435V17.8219C35.9998 17.6036 35.7813 17.4618 35.5847 17.5491Z"
        fill="white"
      />
      <path
        d="M12.4046 17.5476L22.8695 22.6666C23.0552 22.7539 23.1754 22.9504 23.1754 23.1687V36.8012C23.1754 37.0195 22.9569 37.1505 22.7603 37.0632L12.3062 31.9551C12.1205 31.8677 12.0004 31.6713 12.0004 31.453V17.8205C11.9895 17.6022 12.2189 17.4603 12.4046 17.5476Z"
        fill="white"
      />
      <path
        d="M35.4852 15.8345L24.343 21.1936C24.1027 21.3137 23.8296 21.3137 23.5893 21.1936L12.447 15.8563C12.2176 15.7471 12.2395 15.4197 12.4689 15.3324L23.6548 10.9665C23.8624 10.8901 24.0918 10.8901 24.2884 10.9665L35.4634 15.2996C35.6928 15.4088 35.7037 15.7253 35.4852 15.8345Z"
        fill="white"
      />
    </svg>
  )
}

export default Production
