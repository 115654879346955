import React from 'react'

import Box from '@mui/material/Box'

import Modal from 'components/common/Modal'
import PDFViewer from 'components/common/PDFViewer'

function RedactPreviewModal(props) {
  const redactedPdf = props.redactedPdf
  return (
    <Modal {...props}>
      {redactedPdf && (
        <Box
          sx={{
            pt: 2,
            height: '69vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ flex: 1 }}>
            {redactedPdf && (
              <PDFViewer
                id="redacted-pdf"
                url={redactedPdf}
                fileName={`${Date.now()}.pdf`}
              />
            )}
          </Box>
        </Box>
      )}
    </Modal>
  )
}

export default RedactPreviewModal
