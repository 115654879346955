import React from 'react'

import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { useUserData } from 'hooks/users'

const getValueDisplay = (value) => {
  if (value === null || value === undefined) return ''

  if (typeof value === 'object') {
    return (
      <Stack spacing={2}>
        {Object.values(value).map((v) => {
          return (
            <Typography fontSize={12} lineHeight={1}>
              {v}
            </Typography>
          )
        })}
      </Stack>
    )
  }

  return `${value}`
}

function ChangeDisplay({ field, oldVal, newVal }) {
  return (
    <Grid container sx={{ width: '100%', height: '100%' }} columns={5}>
      <Grid item xs={1}>
        <Alert
          severity="info"
          icon={false}
          sx={{
            height: '100%',
            padding: 0,
            borderRadius: 0,
            fontWeight: 'bold',
            textTransform: 'capitalize',
            '& > div': { padding: 1 },
          }}
        >
          {field.split('_').join(' ')}
        </Alert>
      </Grid>
      <Grid xs={2}>
        <Alert
          severity="error"
          icon={<Remove />}
          sx={{
            height: '100%',
            padding: 0,
            borderRadius: 0,
            '& > div': { padding: 1 },
          }}
        >
          {getValueDisplay(oldVal)}
        </Alert>
      </Grid>
      <Grid xs={2}>
        <Alert
          severity="success"
          icon={<Add />}
          sx={{
            height: '100%',
            padding: 0,
            borderRadius: 0,
            '& > div': { padding: 1 },
          }}
        >
          {getValueDisplay(newVal)}
        </Alert>
      </Grid>
    </Grid>
  )
}

function ActivityTable({ audits }) {
  const { formatUserTimeZone } = useUserData()

  return (
    <TableContainer sx={{ padding: 0 }}>
      <Table padding={0}>
        <TableHead>
          <TableRow>
            <TableCell align="left">User</TableCell>
            <TableCell align="left">Date/Time</TableCell>
            <TableCell>Activity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audits.map((audit) => (
            <TableRow
              key={audit.datetime_created}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell align="left" sx={{ py: 0 }}>
                {audit.user_display}
              </TableCell>
              <TableCell align="left" sx={{ py: 0 }}>
                {formatUserTimeZone(new Date(audit.datetime_created))}
              </TableCell>
              <TableCell sx={{ px: 0, py: 1 }}>
                {Object.entries(audit.description).map(([k, v]) => (
                  <ChangeDisplay
                    key={k}
                    field={k}
                    oldVal={v.before}
                    newVal={v.after}
                  />
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ActivityTable
