import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function LegendItem({ color, children }) {
  return (
    <Box
      sx={{ pl: 2, borderLeft: '4px solid', borderColor: color, height: 32 }}
    >
      {children}
    </Box>
  )
}
function ChartLegend({ labels, colors, data, sx, labelProps }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        ...sx,
      }}
    >
      {labels.map((label, index) => (
        <LegendItem key={label} color={colors[index]}>
          <Typography color="primary" fontWeight="bold" lineHeight="16px">
            {data[index]}
          </Typography>
          <Typography
            color="primary"
            fontSize="10px"
            lineHeight="16px"
            {...labelProps}
          >
            {label}
          </Typography>
        </LegendItem>
      ))}
    </Box>
  )
}
export default ChartLegend
