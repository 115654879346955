import React from 'react'

import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import Modal from 'components/common/Modal'

function CCListModal({ sftpUser, open, setOpen }) {
  const modalProps = {
    open,
    setOpen,
    title: `Email Recipients for ${sftpUser?.username}`,
    size: 'sm',
    footerButtonProps: [
      {
        children: 'Close',
        onClick: () => setOpen(false),
        color: 'primary',
        variant: 'contained',
      },
    ],
  }

  const isEmpty = !sftpUser?.email_cc?.length

  return (
    <Modal {...modalProps}>
      <Stack>
        {isEmpty ? (
          <Typography textAlign="center">No CC'd users</Typography>
        ) : (
          sftpUser?.email_cc?.map((email) => (
            <>
              <Typography>{email}</Typography>
            </>
          ))
        )}
      </Stack>
    </Modal>
  )
}

export default CCListModal
