import React from 'react'

import CorrespondencePreviewContent from './Correspondence'

function PreviewContent({
  PreviewComponent = CorrespondencePreviewContent,
  ...props
}) {
  return <PreviewComponent {...props} />
}

export default PreviewContent
