import { useState, useEffect } from 'react'

import axios from 'utils/axios'

const useManualBillPDF = ({ id }) => {
  const [pdf, setPdf] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!id) {
      setPdf(null)
      return
    }

    setLoading(true)
    axios
      .get(`/manual-bill/${id?.toString().trim()}/render`, {
        responseType: 'blob',
        headers: { accept: 'application/pdf' },
      })
      .then((res) => {
        setPdf(res.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id])

  return {
    pdf: pdf,
    loading: loading,
  }
}

export default useManualBillPDF
