import { createContext, useReducer, useCallback } from 'react'
import { BASE_PAGE_SIZE } from 'utils/constants'

const initialState = {
  page: 1,
  pageSize: BASE_PAGE_SIZE,
  total: 0,
  totalPages: 1,
  activeIndex: 0,
  checked: [],
  allChecked: false,
  checkAllPreset: '', // options: '', 'all-on-page', 'all-matching-query'
  checkedCount: 0,
}

const SET_PAGE = 'SET_PAGE'
const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
const SET_TOTAL = 'SET_TOTAL'
const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES'
const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX'
const INC_ACTIVE_INDEX = 'INC_ACTIVE_INDEX'
const DEC_ACTIVE_INDEX = 'DEC_ACTIVE_INDEX'
const SET_CHECKED = 'SET_CHECKED'
const TOGGLE_CHECK_ALL = 'TOGGLE_CHECK_ALL'
const CLEAR_CHECKED = 'CLEAR_CHECKED'
const SET_CHECK_ALL_PRESET = 'SET_CHECK_ALL_PRESET'

const PaginationReducer = (state, action) => {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
        activeIndex: 0,
      }
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload.pageSize,
        page: action.payload.resetPage ? 1 : state.page,
      }
    case SET_TOTAL:
      return {
        ...state,
        total: action.payload.total,
      }
    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload.totalPages,
      }
    case SET_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.payload.force
          ? action.payload.activeIndex
          : Math.min(
              Math.max(0, action.payload.activeIndex),
              state.pageSize - 1
            ),
      }
    case INC_ACTIVE_INDEX: {
      const max = Math.min(state.pageSize, state.total)
      const force = action.payload?.force

      return {
        ...state,
        activeIndex: force
          ? state.activeIndex + 1
          : Math.min(Math.max(0, state.activeIndex + 1), max - 1),
      }
    }
    case DEC_ACTIVE_INDEX: {
      const min = Math.min(state.pageSize, state.total)

      return {
        ...state,
        activeIndex: Math.min(Math.max(0, state.activeIndex - 1), min - 1),
      }
    }
    case SET_CHECKED: {
      const { index, value } = action.payload

      const checked = [...state.checked].slice(0, state.total)

      checked[index] = value

      const checkedCount = checked.filter((c) => c).length

      // handle case where user manually checks everything
      const allChecked =
        checkedCount === state.total - (state.page - 1) * state.pageSize

      return {
        ...state,
        checked: checkedCount <= 0 ? [] : checked,
        allChecked,
        checkedCount,
      }
    }
    case TOGGLE_CHECK_ALL: {
      const allChecked = !state.allChecked
      const totalOnPage = Math.min(
        state.total - (state.page - 1) * state.pageSize,
        state.pageSize
      )

      return {
        ...state,
        allChecked,
        checked: allChecked
          ? Array.from({ length: totalOnPage }).map(() => true)
          : [],
        checkedCount: allChecked ? totalOnPage : 0,
      }
    }
    case CLEAR_CHECKED: {
      if (action.payload?.indexes) {
        const checked = state.checked.map((checkedValue, index) => {
          return action.payload.indexes.includes(index)
            ? false
            : checkedValue && index < action.payload.indexes.length
        })

        return {
          ...state,
          allChecked: false,
          checked,
          checkedCount: checked.filter((c) => c.value)?.length ?? 0,
          checkAllPreset: '',
        }
      } else {
        return {
          ...state,
          allChecked: false,
          checked: [],
          checkedCount: 0,
          checkAllPreset: '',
        }
      }
    }
    case SET_CHECK_ALL_PRESET: {
      const { preset } = action.payload
      const totalOnPage = Math.min(
        state.total - (state.page - 1) * state.pageSize,
        state.pageSize
      )

      return {
        ...state,
        checkAllPreset: preset,
        allChecked: Boolean(preset),
        checked: Array.from({ length: state.pageSize }).map(() => true),
        checkedCount:
          preset === 'all-matching-query' ? state.total : totalOnPage,
      }
    }
    default:
      return state
  }
}

const PaginationContext = createContext(null)

function PaginationProvider({ children }) {
  const [state, dispatch] = useReducer(PaginationReducer, initialState)

  const setPage = useCallback((page) => {
    dispatch({
      type: SET_PAGE,
      payload: {
        page,
      },
    })
  }, [])

  const setPageSize = useCallback((pageSize, resetPage = true) => {
    dispatch({
      type: SET_PAGE_SIZE,
      payload: {
        pageSize,
        resetPage,
      },
    })
  }, [])

  const setTotal = useCallback((total) => {
    dispatch({
      type: SET_TOTAL,
      payload: {
        total,
      },
    })
  }, [])

  const setTotalPages = useCallback((totalPages) => {
    dispatch({
      type: SET_TOTAL_PAGES,
      payload: {
        totalPages,
      },
    })
  }, [])

  const setActiveIndex = useCallback(
    (activeIndex, force = false) => {
      if (state.activeIndex !== activeIndex) {
        dispatch({
          type: SET_ACTIVE_INDEX,
          payload: {
            activeIndex,
            force,
          },
        })
      }
    },
    [state.activeIndex]
  )

  const incActiveIndex = useCallback(
    (neg = false, force = false) => {
      const activeIndexChanges = () => {
        const diff = neg ? -1 : 1

        if (force) return true

        return !(
          state.activeIndex + diff < 0 ||
          state.activeIndex + diff > state.pageSize - 1
        )
      }

      if (activeIndexChanges()) {
        dispatch({
          type: neg ? DEC_ACTIVE_INDEX : INC_ACTIVE_INDEX,
          payload: {
            force: force,
          },
        })
      }
    },
    [state.activeIndex, state.pageSize]
  )

  const setChecked = useCallback(({ index, value }) => {
    dispatch({
      type: SET_CHECKED,
      payload: {
        index,
        value,
      },
    })
  }, [])

  const toggleCheckAll = useCallback(() => {
    dispatch({
      type: TOGGLE_CHECK_ALL,
    })
  }, [])

  const setCheckAllPreset = useCallback((preset) => {
    dispatch({
      type: SET_CHECK_ALL_PRESET,
      payload: {
        preset,
      },
    })
  }, [])

  const clearChecked = useCallback((indexes) => {
    dispatch({
      type: CLEAR_CHECKED,
      payload: { indexes },
    })
  }, [])

  return (
    <PaginationContext.Provider
      value={{
        ...state,
        setPage,
        setPageSize,
        setTotal,
        setTotalPages,
        setActiveIndex,
        incActiveIndex,
        toggleCheckAll,
        setChecked,
        clearChecked,
        setCheckAllPreset,
      }}
    >
      {children}
    </PaginationContext.Provider>
  )
}

export { PaginationContext, PaginationProvider }
