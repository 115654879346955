import useSWR from 'swr'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

const useLetterStatusHistory = (letterId) => {
  const { data, isValidating, mutate } = useSWR(
    letterId ? `/letters/${letterId}/statuses` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const getLastUpdatedUser = async (letterId, status) => {
    const res = await axios.get(`/letters/${letterId}/statuses`)
    const statuses = res.data._embedded?.statuses

    if (!statuses || statuses.length <= 0) return 'System'

    const lastStatus = statuses.filter((s) => s.status === status)?.[0]

    return lastStatus?.user_detail || 'System'
  }

  return {
    statuses: data?._embedded?.statuses ?? [],
    loading: (!data?._embedded?.statuses && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    getLastUpdatedUser,
  }
}

export default useLetterStatusHistory
