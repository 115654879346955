import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import Button from '@mui/material/Button'

import ReportDataListUI from './ReportDataListUI'

import useDataListContext from 'hooks/context/useDataListContext'
import useReports from 'hooks/reporting/useReports'
import useDDReport from 'hooks/reporting/useDDReport'
import useAuth from 'hooks/useAuth'

import DDSidebar from './DDSidebar'

function DDReportDataList() {
  const location = useLocation()
  const { user } = useAuth()

  const [sortList, setSortList] = useState({})
  const [userInput, setUserInput] = useState({})

  // sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { page, pageSize, setTotal, setTotalPages } = useDataListContext()

  const reportHookParams = useMemo(
    () => ({
      paging: { offset: (page - 1) * pageSize, limit: pageSize },
      user_input: userInput,
      ...sortList,
    }),
    [page, pageSize, userInput]
  )

  const { reports } = useReports({
    ddOnly: true,
  })
  const [activeReport, setActiveReport] = useState(location.state?.activeReport)
  const activeReportData = useMemo(() => {
    const foundReport = reports.find((report) => report.id === activeReport)
    const showDateRange = false
    return {
      ...foundReport,
      showDateRange,
    }
  }, [reports, activeReport])
  const [activeDateRange, setActiveDateRange] = useState(
    location.state?.dateRange
  )
  const {
    items,
    loading,
    columns: visibleColumns,
    total,
    runReport,
    downloadReport,
  } = useDDReport({ ddReportId: activeReport, ...reportHookParams })

  const setReportingView = useCallback(
    (reportId, dateRange, filters) => {
      setActiveReport(reportId)
      setActiveDateRange(dateRange)
      setUserInput(filters)

      runReport(reportId, { user_input: filters })
    },
    [setActiveReport, setActiveReport, runReport]
  )

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
    }

    setTotalPages(Math.ceil(total / pageSize))
  }, [total, pageSize])

  useEffect(() => {
    runReport(activeReport, activeDateRange)
  }, [page, pageSize])

  useEffect(() => {
    if (location.state) {
      setReportingView(location.state?.activeReport, location.state?.dateRange)
    }
  }, [])

  const canEdit =
    user.isMPXAdmin || activeReportData?.user?.id?.toString() === user?.userId

  return (
    <ReportDataListUI
      reportName={location?.state?.reportName}
      activeReport={activeReport}
      activeDateRange={activeDateRange}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
      sortList={sortList}
      setSortList={setSortList}
      downloadReport={downloadReport}
      total={total}
      items={items}
      loading={loading}
      visibleColumns={visibleColumns}
      headerProps={{
        buttons: [
          <Button
            color="secondary"
            variant="contained"
            size="action-footer"
            onClick={() =>
              downloadReport(
                activeReport,
                null,
                `${activeReportData?.name}`,
                total,
                'text/csv'
              )
            }
            sx={{ mr: 4 }}
          >
            Export to CSV
          </Button>,
          canEdit && (
            <Button
              color="primary"
              variant="outlined"
              size="action-footer"
              onClick={() =>
                (window.location.href = `/command-center/reporting/dd/${activeReport}`)
              }
              sx={{ mr: 4 }}
            >
              Edit
            </Button>
          ),
        ].filter((b) => b),
      }}
      sidebar={
        <DDSidebar
          isDD
          reports={reports}
          activeReport={activeReport}
          activeReportData={activeReportData}
          setActiveReport={setActiveReport}
          open={sidebarOpen}
          setOpen={setSidebarOpen}
          setReportingView={setReportingView}
        />
      }
    />
  )
}

export default DDReportDataList
