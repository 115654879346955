import useSWR from 'swr/immutable'

import { outgoingFetcher } from 'utils/fetcher'
import { SMARTY } from 'utils/constants'

import urlSerialize from 'helpers/browser/urlSerialize'

const useSmartyStreetsAutocomplete = (search) => {
  const { data, isValidating } = useSWR(
    search
      ? `${SMARTY.AUTOCOMPLETE_URL}?${urlSerialize({
          search,
          key: process.env.REACT_APP_SMARTY_WEBSITE_KEY_AUTOCOMPLETE,
        })}`
      : null,
    outgoingFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    suggestions: data?.suggestions ?? [],
    loading: isValidating,
  }
}

export default useSmartyStreetsAutocomplete
