import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const useDocumentInboundCategories = () => {
  const { data, isValidating, mutate } = useSWR(
    '/document-inbound-categories',
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const sortedCategories = data?._embedded?.document_inbound_categories?.sort(
    (a, b) =>
      ('' + a.name?.toUpperCase?.()).localeCompare(b.name?.toUpperCase?.())
  )

  return {
    categories: sortedCategories ?? [],
    loading: (!sortedCategories && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default useDocumentInboundCategories
