const splitByPascalCase = (str) => {
  // just return string if there are not any lowercase letters
  // handles all capital cases not splitting correctly
  if (!str?.match(/[a-z]/gm)) {
    return str
  }

  return (
    str?.match(/([A-Z](?=[^a-z])){2,}|([A-Z][a-z]+)|([A-Z]+$)/g)?.join(' ') ??
    str
  )
}

export default splitByPascalCase
