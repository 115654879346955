import React from 'react'

import TabbedDataList from 'layouts/TabbedDataList'

import DownloadsDataList from 'components/downloads/DownloadsDataList'

function DownloadsPage() {
  return (
    <TabbedDataList>
      <DownloadsDataList />
    </TabbedDataList>
  )
}

export default DownloadsPage
