import React, { useEffect, useState, useRef, useMemo } from 'react'
import format from 'date-fns/format'
import { Formik, useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import useTheme from '@mui/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import XIcon from '@mui/icons-material/Close'
import DateRangeIcon from '@mui/icons-material/DateRange'

import ActionButtons from 'components/common/ActionButtons'
import MultiSelectDropdown from 'components/common/MultiSelectDropdown'
import Autocomplete from 'components/common/Autocomplete'
import ScrollableBox from 'components/styled/ScrollableBox'
import DateRangePicker from 'components/common/DateRangePicker'

import useSidebar from 'hooks/useSidebar'
import {
  useDocumentInboundCategories,
  useDocumentInboundStatuses,
} from 'hooks/digital_mail'
import useAllUsers from 'hooks/users/useAllUsers'
import useNotification from 'hooks/context/useNotification'
import useInstance from 'hooks/instance/useInstance'

import trimString from 'helpers/node/trimString'

function SidebarContent() {
  const { values, handleChange, handleSubmit, handleReset } = useFormikContext()
  const { categories } = useDocumentInboundCategories()
  const { statuses } = useDocumentInboundStatuses()
  const { getDigitalMailFilters } = useInstance()
  let { users } = useAllUsers({}, true)
  users = [
    ...users,
    {
      display: 'Unassigned',
      id: 'null',
    },
  ]

  const footerActionProps = [
    {
      children: 'Search',
      color: 'primary',
      variant: 'contained',
      onClick: () => handleSubmit(),
    },
    {
      children: 'Clear',
      color: 'primary',
      variant: 'outlined',
      onClick: () => {
        handleReset()
        handleSubmit()
      },
    },
  ]

  const displayCategories = categories.map((category) => category.name)
  const displayStatuses = statuses.map((status) => status.status)

  const getUserById = (id) => {
    return users.find((user) => user.id.toString() === id?.toString())
  }

  function UsersAutocomplete() {
    const [searchValue, setSearchValue] = useState('')
    const handleAutocompleteFilter = (options) => {
      const filtered = options.filter((option) =>
        option?.display?.toLowerCase().includes(searchValue.toLowerCase())
      )
      return filtered
    }

    return (
      <Autocomplete
        selectedOption={
          typeof values['assigned_to_user_id'] === 'object'
            ? values['assigned_to_user_id']
            : getUserById(values['assigned_to_user_id'])
        }
        setSelectedOption={(user) =>
          handleChange({ target: { name: 'assigned_to_user_id', value: user } })
        }
        placeholder="Assigned To"
        maxHeight="15vh"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        options={users}
        getOptionLabel={(user) => user?.display ?? ''}
        handleFilter={handleAutocompleteFilter}
      />
    )
  }

  const filterFields = {
    document_id: (
      <TextField
        fullWidth
        label="Document ID"
        name="document_id"
        value={values['document_id']}
        onChange={handleChange}
      />
    ),
    account_id: (
      <TextField
        label="Account ID"
        name="account_id"
        value={values['account_id']}
        onChange={handleChange}
      />
    ),
    member_id: (
      <TextField
        label="Member ID"
        name="member_id"
        value={values['member_id']}
        onChange={handleChange}
      />
    ),
    tracking_number: (
      <TextField
        label="Tracking Number"
        name="tracking_number"
        value={values['tracking_number']}
        onChange={handleChange}
      />
    ),
    category: (
      <MultiSelectDropdown
        label="Category"
        name="category"
        placeholder="Category"
        options={displayCategories}
        onChange={handleChange}
        value={values.category}
        maxHeight="20vh"
      />
    ),
    status: (
      <MultiSelectDropdown
        label="Status"
        name="status"
        placeholder="Status"
        options={displayStatuses}
        onChange={handleChange}
        value={values.status}
        maxHeight="20vh"
      />
    ),
    date_doc: (
      <DateRangePicker
        label="Date"
        value={values['date_doc']}
        setValue={(value) =>
          handleChange({ target: { name: 'date_doc', value } })
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <DateRangeIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    ),
    assigned_to_user_id: <UsersAutocomplete />,
  }

  return (
    <ScrollableBox
      sx={{
        height: '90%',
        width: '100%',
        display: 'flex !important',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: 'auto',
      }}
    >
      <Stack spacing={4} sx={{ width: '100%', mt: 2 }}>
        {getDigitalMailFilters().map((filter) => filterFields[filter])}
      </Stack>
      <Box sx={{ mr: 4, my: 4 }}>
        <ActionButtons footer actionProps={footerActionProps} />
      </Box>
    </ScrollableBox>
  )
}

function DigitalMailboxSidebar({ open, setOpen, filterList, setFilterList }) {
  const formikRef = useRef(null)
  const location = useLocation()
  const { open: navOpen, setOpen: setNavOpen } = useSidebar('navigation')
  const { setError } = useNotification()

  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('xl')) || undefined
  const showSidebar = !isMobileView || (isMobileView && open)

  const [initialValues] = useState({
    document_id: '',
    account_id: '',
    date_doc: [undefined, undefined],
    category: [],
    status: [],
    assigned_to_user_id: '',
    tracking_number: '',
  })

  useEffect(() => {
    const arrValues = ['category', 'status']
    const newInitialValues = { ...initialValues }

    const search = filterList?.search

    if (search) {
      const vals = search.split(/\|/gm)

      for (let i = 0; i < vals.length; i += 2) {
        const key = vals[i]
        const value = vals[i + 1]

        if (key && value) {
          if (arrValues.includes(key)) {
            newInitialValues[key] = value.split(',')
          } else {
            newInitialValues[key] = value
          }
        }
      }
    }

    if (filterList['date_doc[start]']) {
      // date is in format year-month-day
      const [startYear, startMonth, startDay] = filterList['date_doc[start]']
        .split('-')
        .map(Number)
      const [endYear, endMonth, endDay] = filterList['date_doc[end]']
        .split('-')
        .map(Number)

      const startDate = new Date(startYear, startMonth - 1, startDay)
      const endDate =
        filterList['date_doc[start]'] !== filterList['date_doc[end]'] // check for single date filter
          ? new Date(endYear, endMonth - 1, endDay)
          : undefined

      newInitialValues['date_doc'] = [startDate, endDate]
    }

    Object.keys(filterList).forEach((key) => {
      if (
        key === 'search' ||
        !filterList[key] ||
        key === 'date_doc[start]' ||
        key === 'date_doc[end]'
      )
        return

      if (arrValues.includes(key)) {
        newInitialValues[key] = filterList[key].split(/\|/gm)
      } else {
        newInitialValues[key] = filterList[key]
      }
    })

    formikRef.current.setValues(newInitialValues)
  }, [filterList])

  const handleSubmit = (values) => {
    const newValues = { search: [] }

    try {
      Object.keys(values).forEach((key) => {
        const value = trimString(values[key])

        if (Array.isArray(value) && value.length === 0) return
        if (!value) return

        if (key === 'category' || key === 'status')
          newValues[key] = value.join('|')
        else if (key === 'date_doc') {
          const dateRange = values['date_doc']

          if (!dateRange[0]) {
            return
          } else if (!dateRange[1]) {
            dateRange[1] = dateRange[0] // no end date defaults to same as start
          }

          newValues['date_doc[start]'] = dateRange[0]
            ? format(dateRange[0], 'yyyy-MM-dd')
            : undefined
          newValues['date_doc[end]'] = dateRange[1]
            ? format(dateRange[1], 'yyyy-MM-dd')
            : undefined
        } else if (key === 'assigned_to_user_id') {
          newValues[key] = typeof value === 'object' ? value.id : value
        } else if (key === 'member_id') {
          newValues.search.push(`_.metadata.MemberID|${value}`)
        } else if (!key.includes('metadata')) {
          newValues.search.push(`${key}|${value}`)
        }
      })

      if (newValues.search.length === 0) delete newValues.search
      else newValues.search = newValues.search.join('|')

      setFilterList(newValues)
    } catch (err) {
      setError(err.message)
    }
  }

  // close sidebar on search on mobile
  useEffect(() => {
    if (location.state && isMobileView && open) {
      setOpen(false)
    }
  }, [location.state])

  // close nav sidebar when this sidebar is open
  useEffect(() => {
    if (open && navOpen) {
      setNavOpen(false)
    }
  }, [open])

  const renderSidebarContent = useMemo(() => {
    return <SidebarContent />
  }, [])

  return (
    <Box>
      <Collapse in={open} orientation="horizontal">
        <Box sx={{ width: showSidebar ? '424px' : '0px' }}>
          <Card
            tabindex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                formikRef.current.submitForm()
              }
            }}
            sx={{
              px: 8,
              py: 7,
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              position: 'fixed',
              height: '100vh',
              width: '424px',
              outline: 'none !important',
            }}
          >
            <IconButton onClick={() => setOpen(false)} sx={{ float: 'right' }}>
              <XIcon />
            </IconButton>
            <Typography variant="h1">Search/Filters</Typography>
            <Fade in={open} timeout={{ appear: 2000, enter: 800 }}>
              <Box sx={{ height: '100%' }}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize
                  innerRef={formikRef}
                >
                  {({ handleSubmit }) => (
                    <form style={{ height: '100%' }} onSubmit={handleSubmit}>
                      {renderSidebarContent}
                    </form>
                  )}
                </Formik>
              </Box>
            </Fade>
          </Card>
        </Box>
      </Collapse>
    </Box>
  )
}

export default DigitalMailboxSidebar
