import { useState } from 'react'

import axios from 'utils/axios'

const useLetterDocumentPdf = () => {
  const [missingPdfs, setMissingPdfs] = useState({})
  const [foundPdfs, setFoundPdfs] = useState({})
  const [pdf, setPdf] = useState(null)

  const getPdfByDocumentId = async (letterId, docId) => {
    try {
      const response = await fetch(
        `${axios.defaults.baseURL}/letters/${letterId}/documents/${docId}`,
        {
          headers: {
            Accept: 'application/pdf',
            Authorization: axios.defaults.headers.Authorization,
          },
        }
      )

      if (response.status !== 200) {
        throw new Error('Document not found')
      }
      return response.blob()
    } catch (err) {
      return null
    }
  }

  const fetchPdf = async ({ letterId, documentId }) => {
    if (!letterId || !documentId) return false

    if (missingPdfs[documentId]) return false

    if (foundPdfs[documentId]) {
      setPdf(foundPdfs[documentId])
      return true
    }

    try {
      const pdfData = await getPdfByDocumentId(letterId, documentId)

      if (pdfData) {
        setFoundPdfs({ ...foundPdfs, [documentId]: pdfData })
        setPdf(pdfData)

        return true
      } else {
        throw new Error('A PDF could not be found for this document.')
      }
    } catch (err) {
      setPdf(null)

      missingPdfs[documentId] = true
      setMissingPdfs(missingPdfs)

      return false
    }
  }

  return {
    fetchPdf,
    getPdfByDocumentId,
    pdf,
  }
}

export default useLetterDocumentPdf
