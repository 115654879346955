import deepEqual from 'deep-equal'

/**
 * Get the difference between two objects
 * @param obj - initial value of the object
 * @param updatedObj - same interface as obj, with small differences, edited inplace
 */
function getObjDiff(obj, updatedObj) {
  if (obj === updatedObj) {
    return undefined
  }

  if (obj && typeof obj === 'object') {
    if (deepEqual(obj, updatedObj)) {
      return undefined
    }

    Object.keys(obj).forEach((key) => {
      if (getObjDiff(obj[key], updatedObj[key]) === undefined) {
        delete updatedObj[key]
      }
    })

    return updatedObj
  } else {
    if (obj === updatedObj) {
      return undefined
    } else {
      return updatedObj
    }
  }
}

export default getObjDiff
