import React from 'react'

import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import Typography from '@mui/material/Typography'

import ViewListIcon from '@mui/icons-material/ViewList'
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'

import useDataListLayout from 'hooks/context/useDataListContext'

function ResultsListHeader({ loading }) {
  const { layoutType, setLayoutType, total } = useDataListLayout()

  const itemName = {
    singular: 'document',
    plural: 'documents',
  }

  return (
    <>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          {total === 0 && !loading && (
            <Box sx={{ px: 4, pt: 2 }}>
              <Typography>No {itemName.plural} found</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '50%',
            my: 'auto',
            '& > *': { my: 'auto' },
            justifyContent: 'flex-end',
            justifySelf: 'flex-end',
          }}
        >
          <Box sx={{ width: '32px' }} />
          <ToggleButton
            color="primary"
            value="list"
            sx={{ mr: 4 }}
            selected={layoutType !== 'normal'}
            disabled={layoutType === 'normal'}
            onClick={() => setLayoutType('normal')}
          >
            <ViewListIcon />
          </ToggleButton>
          <ToggleButton
            color="primary"
            value="preview"
            selected={layoutType !== 'preview'}
            disabled={layoutType === 'preview'}
            onClick={() => setLayoutType('preview')}
          >
            <ViewSidebarIcon sx={{ transform: 'rotate(180deg)' }} />
          </ToggleButton>
        </Box>
      </Box>
    </>
  )
}

export default ResultsListHeader
