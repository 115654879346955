import useLetters from './useLetters'

const useLettersAggregate = ({ baseParams, disabled = false }) => {
  const {
    total: toReviewTotal,
    mutate: toReviewMutate,
    loading: toReviewLoading,
  } = useLetters(
    !disabled && {
      ...baseParams,
      status_filter: 'To Review',
      'paging[page_size]': 1,
    }
  )
  const {
    total: onHoldTotal,
    mutate: onHoldMutate,
    loading: onHoldLoading,
  } = useLetters(
    !disabled && {
      ...baseParams,
      status_filter: 'On Hold',
      'paging[page_size]': 1,
    }
  )
  const {
    total: approvedTotal,
    mutate: approvedMutate,
    loading: approvedLoading,
  } = useLetters(
    !disabled && {
      ...baseParams,
      status_filter: 'Approved',
      'paging[page_size]': 1,
    }
  )
  const {
    total: mailedTotal,
    mutate: mutateMailedTotal,
    loading: mailedLoading,
  } = useLetters(
    !disabled && {
      ...baseParams,
      status_filter: 'Mailed',
      'paging[page_size]': 1,
    }
  )
  const {
    total: productionTotal,
    mutate: mutateProductionTotal,
    loading: productionLoading,
  } = useLetters(
    !disabled && {
      ...baseParams,
      status_filter: 'Production',
      'paging[page_size]': 1,
    }
  )
  const {
    total: rejectedTotal,
    mutate: mutateRejectedTotal,
    loading: rejectedLoading,
  } = useLetters(
    !disabled && {
      ...baseParams,
      status_filter: 'Rejected',
      'paging[page_size]': 1,
    }
  )

  const mutate = () => {
    mutateMailedTotal()
    mutateProductionTotal()
    mutateRejectedTotal()
    toReviewMutate()
    onHoldMutate()
    approvedMutate()
  }

  const loading =
    mailedLoading ||
    productionLoading ||
    rejectedLoading ||
    toReviewLoading ||
    onHoldLoading ||
    approvedLoading

  return {
    mutate,
    loading,
    aggregate: {
      counts: {
        Mailed: mailedTotal,
        Production: productionTotal,
        Rejected: rejectedTotal,
        Other: approvedTotal + onHoldTotal + toReviewTotal,
        Approved: approvedTotal,
        'On Hold': onHoldTotal,
        'To Review': toReviewTotal,
      },
    },
  }
}

export default useLettersAggregate
