import React from 'react'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import ScrollableBox from 'components/styled/ScrollableBox'
import NotesItem from 'components/notes/NotesItem'
import BasicDrawer from 'components/common/Drawer/Basic'

function AddNoteSection({ sx }) {
  const { values, handleChange, handleSubmit, isSubmitting } =
    useFormikContext()

  return (
    <Box sx={{ ...sx, height: '30vh', minHeight: '368px' }}>
      <Typography variant="h2" fontWeight="medium" pb={4}>
        Add note
      </Typography>
      <TextField
        multiline
        fullWidth
        rows={8}
        variant="filled"
        value={values.note}
        onChange={(e) =>
          handleChange({ target: { name: 'note', value: e.target.value } })
        }
      />
      <Button
        color="primary"
        variant="contained"
        size="action-header"
        sx={{ mt: 4, mb: 6, float: 'right' }}
        onClick={() => handleSubmit()}
        disabled={isSubmitting}
      >
        Submit
      </Button>
    </Box>
  )
}

function OrderNotesDrawerContent({ notes = [], orderId, loading }) {
  const sharedStyles = {
    px: 6,
    py: 6,
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '72px 1fr 388px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'lightgray.main',
          px: sharedStyles.px,
          pb: 4,
        }}
      >
        <Typography variant="h1" pb={1}>
          Notes
        </Typography>
        <Typography fontSize={14} color="darkgray.main">
          Order ID {orderId}
        </Typography>
      </Box>
      <ScrollableBox
        sx={{
          ...sharedStyles,
          height: 'auto',
          overflowY: 'auto',
          display: 'flex',
        }}
      >
        {notes.length === 0 && loading ? (
          <CircularProgress sx={{ mx: 'auto' }} />
        ) : (
          <Stack spacing={8}>
            {notes.map((note, noteIndex) => (
              <NotesItem key={`note-${noteIndex}`} note={note} />
            ))}
          </Stack>
        )}
      </ScrollableBox>
      <AddNoteSection sx={{ ...sharedStyles }} />
    </Box>
  )
}

function OrderNotesDrawer({ sx, open, setOpen, ...props }) {
  return (
    <BasicDrawer open={open} setOpen={setOpen} width="428px">
      <OrderNotesDrawerContent {...props} />
    </BasicDrawer>
  )
}

export default OrderNotesDrawer
