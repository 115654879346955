import React, { useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import uniqBy from 'lodash/uniqBy'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'

import MultiSelectDropdown from 'components/common/MultiSelectDropdown'
import ScrollableBoxWrapper from 'components/styled/ScrollableBox'
import DraggableCards from 'components/common/DraggableCards'
import ValueDisplay from './ValueDisplay'

import reorder from 'helpers/node/reorder'
import isEmptyObject from 'helpers/node/isEmptyObject'
import useNotification from 'hooks/context/useNotification'
import { validateJoins } from 'utils/report'

import Section from './Section'

function SidebarCreateForm({
  open,
  setOpen,
  dataDomains,
  domainOptions,
  setDomainOptions,
  columns,
  setColumns,
  ddReportId,
}) {
  const { values, setFieldValue, submitForm, status, setStatus } =
    useFormikContext()
  const { setError } = useNotification()

  const sorts = useMemo(() => {
    return Object.keys(values.report_sorts)
  }, [values])

  const filters = values.report_filters

  const handleDataDomainsChange = (newDomains) => {
    if (!newDomains?.length) {
      setDomainOptions(dataDomains)
      setStatus({
        ...status,
        columns: [],
      })
      setColumns([])
      setFieldValue('report_columns', {})
      setFieldValue('report_sorts', {})
      setFieldValue('report_filters', [])
      setFieldValue('data_domains', [])
      return
    }

    const invalidJoins = validateJoins(newDomains)
    if (invalidJoins.length > 0) {
      const names = invalidJoins.map((dd) => dd.name)
      setError(
        `Unable to update datadomains. The following datadomains cannot be joined: ${names.join(
          ','
        )}`
      )
      setFieldValue('data_domains', values.data_domains.slice())
      return
    }

    // filter domain options by join
    let validDomainIds = []
    let newColumns = []

    newDomains.forEach((domain) => {
      validDomainIds.push(domain.id.toString())

      const joins = domain._embedded?.joins || {}
      validDomainIds = validDomainIds.concat(Object.keys(joins))

      const columns = domain._embedded?.columns || {}
      newColumns = newColumns.concat(
        Object.entries(columns).flatMap(([view, dd]) =>
          Object.entries(dd).map(([k, v]) => ({
            dataType: v,
            col: k,
            view: view,
          }))
        )
      )
    })

    const domainOptions = dataDomains.filter((dd) =>
      validDomainIds.includes(dd.id.toString())
    )

    let invalidColumns = []

    Object.values(values.report_columns).forEach((col) => {
      if (!newDomains.find((dd) => dd.view === col.view)) {
        invalidColumns.push(col.col)
      }
    })

    Object.entries(values.report_sorts).forEach(([col, sort]) => {
      if (!newDomains.find((dd) => dd.view === sort.view)) {
        invalidColumns.push(col)
      }
    })

    values.report_filters.forEach((filter) => {
      if (!newDomains.find((dd) => dd.view === filter.view)) {
        invalidColumns.push(filter.col)
      }
    })

    invalidColumns = [...new Set(invalidColumns)]

    if (invalidColumns.length > 0) {
      setError(
        `Unable to update report. The following inputs need to be removed: ${invalidColumns.join(
          ','
        )}`
      )
      setFieldValue('data_domains', values.data_domains.slice())
      return
    }

    if (!ddReportId) {
      setFieldValue('name', newDomains?.[0]?.name || 'Report')
    }

    setColumns(columns.filter((col) => values.report_columns[col]))
    setDomainOptions(domainOptions)
    setStatus({
      ...status,
      columns: [...uniqBy(newColumns, (col) => col.col)],
    })
    setFieldValue('data_domains', newDomains)
  }

  useEffect(() => {
    if (domainOptions.length === 0) {
      setDomainOptions(dataDomains || [])
    }
  }, [dataDomains])

  return (
    <Collapse in={open} orientation="horizontal">
      <Box
        component={Paper}
        open={open}
        setOpen={setOpen}
        sx={{
          border: '1px solid',
          borderColor: 'lightgray.main',
          height: 'calc(100vh - 72px)',
          width: '630px',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              pt: 6,
              pb: 3,
              mb: 3,
              textAlign: 'center',
              borderBottom: '1px solid',
              borderColor: 'lightgray.main',
            }}
          >
            <Typography fontWeight={'bold'} variant="h2">
              Add Inputs / Outputs
            </Typography>
          </Box>
          <ScrollableBoxWrapper sx={{ height: '100%', pb: 36 }}>
            <Stack spacing={4} sx={{ px: 4 }}>
              <MultiSelectDropdown
                options={domainOptions}
                name="Data"
                value={values.data_domains}
                onChange={(e) => handleDataDomainsChange(e.target.value)}
                getOptionLabel={(option) => option.name}
              />
              <Section
                title={`Report Outputs (${columns.length})`}
                selected={columns}
                onAdd={(col) => {
                  setFieldValue(`report_columns.${col.value}`, {
                    col: col.value,
                    position: columns.length,
                    view: col.view,
                  })
                  setColumns(columns.concat(col.value))
                }}
              >
                <DraggableCards
                  handleDragEnd={(result) => {
                    const cols = reorder(
                      columns,
                      result.source.index,
                      result.destination.index
                    )

                    cols.forEach((col, index) => {
                      setFieldValue(`report_columns.${col}.position`, index)
                    })

                    setColumns(cols)
                  }}
                >
                  {columns.map((col) => (
                    <ValueDisplay
                      key={col}
                      value={col}
                      type="column"
                      onClose={() => {
                        const report_columns = { ...values.report_columns }
                        delete report_columns[col]

                        setFieldValue('report_columns', report_columns)
                        setColumns(columns.filter((c) => c !== col))
                      }}
                    />
                  ))}
                </DraggableCards>
              </Section>
              <Section
                title={`Report Inputs (${filters.length})`}
                selected={[]}
                onAdd={(col) => {
                  const _filters = filters.slice()

                  _filters.push({
                    col: col.value,
                    action: 'eq',
                    target: '',
                    index: filters.length,
                    view: col.view,
                  })

                  setFieldValue(`report_filters`, _filters)
                }}
              >
                {filters.map((filter, index) => (
                  <ValueDisplay
                    key={filter.col}
                    value={filter}
                    type="filter"
                    onClose={() => {
                      const _filters = filters.slice()

                      _filters.splice(index, 1)

                      setFieldValue(
                        'report_filters',
                        _filters.map((f, i) => ({ ...f, index: i }))
                      )
                    }}
                  />
                ))}
              </Section>
              <Section
                title={`Sort (${sorts.length})`}
                selected={sorts}
                onAdd={(col) => {
                  setFieldValue(`report_sorts.${col.value}`, {
                    dir: 'asc',
                    view: col.view,
                  })
                }}
              >
                {sorts.map((sort) => (
                  <ValueDisplay
                    key={sort}
                    value={sort}
                    type="sort"
                    onClose={() => {
                      const reportSorts = { ...values.report_sorts } || {}
                      delete reportSorts[sort]

                      setFieldValue(`report_sorts`, reportSorts)
                    }}
                  />
                ))}
              </Section>
            </Stack>
          </ScrollableBoxWrapper>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              py: 4,
              px: 4,
              borderTop: '1px solid',
              borderColor: 'lightgray.main',
              position: 'absolute',
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: 'white',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submitForm}
              disabled={isEmptyObject(values.report_columns)}
            >
              Run report
            </Button>
          </Box>
        </Box>
      </Box>
    </Collapse>
  )
}

function SidebarCreate({ ...props }) {
  return <SidebarCreateForm {...props} />
}

export default SidebarCreate
