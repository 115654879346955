import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import deepEqual from 'deep-equal'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import MenuIcon from '@mui/icons-material/Menu'

import PureDataListLayout from 'layouts/PureDataList'
import ReportDataListUI from 'components/reporting/ReportDataListUI'
import SidebarCreate from 'components/reporting/SidebarCreate'

import useDataDomains from 'hooks/reporting/useDataDomains'
import ConfirmCreateReportModal from 'components/reporting/ConfirmCreateReportModal'
import useDDReport from 'hooks/reporting/useDDReport'
import useReports from 'hooks/reporting/useReports'
import useModal from 'hooks/context/useModal'
import useAuth from 'hooks/useAuth'
import useConfiguration from 'hooks/useConfiguration'
import { convertApiToForm } from 'utils/report'
import useNotification from 'hooks/context/useNotification'

function ReportingCreate() {
  const formikRef = useRef()
  const [paging, setPaging] = useState({ offset: undefined, limit: undefined })
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)
  const [domainOptions, setDomainOptions] = useState([])
  const [columns, setColumns] = useState([])
  const { dataDomains } = useDataDomains({ embed: 'joins|columns' })
  const { ddReportId } = useParams()
  const {
    loading,
    runReport,
    report,
    reportData,
    previewReport,
    updateReport,
    deleteReport,
  } = useDDReport({ ddReportId })
  const { saveReport } = useReports({})
  const { setOpen, setModalProps } = useModal()
  const { user } = useAuth()
  const {
    updatePath,
    navigation: { currentPath },
  } = useConfiguration()
  const { setBasicNotification } = useNotification()

  const handleReportDelete = () => {
    const modalProps = {
      title: 'Delete report?',
      children: 'Are you sure you want to delete this report?',
      size: 'sm',
      footerButtonProps: [
        {
          children: 'Confirm',
          variant: 'contained',
          color: 'primary',
          onClick: () => {
            deleteReport().then(() => {
              navigate('/command-center/reporting')
              setBasicNotification('Report has been successfully deleted!')
              setOpen(false)
            })
          },
        },
        {
          children: 'Cancel',
          variant: 'outlined',
          color: 'primary',
          onClick: () => setOpen(false),
        },
      ],
    }

    setModalProps(modalProps)
    setOpen(true)
  }

  const initialData = useMemo(() => {
    let _initialValues = {
      data_domains: [],
      report_columns: {},
      report_sorts: {},
      report_filters: [],
      name: reportData?.name,
      public: reportData?.public,
    }

    const _initialStatus = {
      columns: [],
    }

    let newColumns = []

    if (!ddReportId) {
      dataDomains.forEach((domain) => {
        const columns = domain._embedded?.columns || {}
        newColumns = newColumns.concat(
          Object.entries(columns).flatMap(([view, dd]) =>
            Object.entries(dd).map(([k, v]) => ({
              dataType: v,
              col: k,
              view: view,
            }))
          )
        )
      })

      _initialStatus.columns = newColumns

      return {
        initialValues: _initialValues,
        initialStatus: _initialStatus,
      }
    }

    try {
      _initialValues = convertApiToForm({ reportData, dataDomains })
      _initialValues.data_domains.forEach((domain) => {
        const columns = domain._embedded?.columns || {}
        newColumns = newColumns.concat(
          Object.entries(columns).flatMap(([view, dd]) =>
            Object.entries(dd).map(([k, v]) => ({
              dataType: v,
              col: k,
              view: view,
            }))
          )
        )
      })

      _initialStatus.columns = newColumns
    } catch (err) {}

    return { initialValues: _initialValues, initialStatus: _initialStatus }
  }, [reportData, dataDomains])

  useEffect(() => {
    const newColumns = Object.keys(
      initialData.initialValues.report_columns || {}
    )
    if (!deepEqual(columns, newColumns) && ddReportId) {
      setColumns(newColumns)
    }
  }, [initialData])

  const reportItems = useMemo(() => {
    const resultCount = report?.results?.length

    if (resultCount === 0 && !ddReportId && formikRef.current) {
      const item = {}

      const values = formikRef.current?.values
      Object.keys(values.report_columns)
        .sort(
          (a, b) =>
            values.report_columns[a].position -
            values.report_columns[b].position
        )
        .forEach((key) => {
          item[key] = null
        })

      return [item]
    }

    return report?.results || []
  }, [report, formikRef.current])

  const handleSaveReportClick = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (ddReportId) {
      runReport(ddReportId)
    }
  }, [ddReportId])

  useEffect(() => {
    if (!reportData || !ddReportId) return

    const breadcrumbs = [
      {
        display: 'Reporting',
        href: '/command-center/reporting',
      },
      reportData && {
        display: reportData?.name,
        href: `/command-center/reporting/${reportData?.name}`,
      },
    ].filter((b) => b)

    if (deepEqual(breadcrumbs, currentPath)) return

    updatePath(breadcrumbs)
  }, [reportData, currentPath])

  useEffect(() => {
    previewReport(formikRef.current?.values || {}, {
      paging,
    })
  }, [paging])

  if (!initialData.initialStatus?.columns?.length) return <></>

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialData.initialValues}
      initialStatus={initialData.initialStatus}
      onSubmit={async (values) => {
        await previewReport(values, {
          paging,
        })
      }}
    >
      {({ touched }) => (
        <>
          <ConfirmCreateReportModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            saveReport={ddReportId ? updateReport : saveReport}
            ddReportId={ddReportId}
          />
          <Box
            sx={{
              display: 'flex',
              height: 'calc(100vh - 72px)',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Box sx={{ flex: 1, maxWidth: 'calc(100vw - 916px)', mx: 'auto' }}>
              <PureDataListLayout>
                <ReportDataListUI
                  activeReport={null}
                  activeDateRange={[undefined, undefined]}
                  filterList={{}}
                  items={reportItems}
                  visibleColumns={Object.keys(reportItems?.[0] || {})}
                  total={report?.total || 0}
                  headerProps={{
                    SidebarIcon: MenuIcon,
                    buttons: [
                      <Button
                        disabled={!touched}
                        variant="contained"
                        onClick={() => handleSaveReportClick()}
                      >
                        Save report
                      </Button>,
                      Boolean(ddReportId) && (
                        <Button
                          variant="outlined"
                          onClick={handleReportDelete}
                          sx={{ ml: 4 }}
                          disabled={
                            reportData?.user?.id?.toString() !== user.userId &&
                            !user.isMPXAdmin
                          }
                        >
                          Delete
                        </Button>
                      ),
                    ].filter((b) => b),
                  }}
                  loading={loading}
                  setPaging={setPaging}
                />
              </PureDataListLayout>
            </Box>
            <Box sx={{ ml: 'auto' }}>
              <SidebarCreate
                open={true}
                dataDomains={dataDomains}
                domainOptions={domainOptions}
                setDomainOptions={setDomainOptions}
                columns={columns}
                setColumns={setColumns}
                ddReportId={ddReportId}
              />
            </Box>
          </Box>
        </>
      )}
    </Formik>
  )
}

export default ReportingCreate
