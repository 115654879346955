import React from 'react'

import ListItem from '@mui/material/ListItem'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import useDataListContext from 'hooks/context/useDataListContext'

import stringAvatar from 'helpers/css/stringAvatar'

function UserMaintenancePreviewItem({
  index,
  rowData,
  getDataDisplay,
  sharedSx,
}) {
  const { activeIndex, setActiveIndex, pageSize } = useDataListContext()

  const active = activeIndex === index
  const darken = active

  const getBorderColor = () => {
    if (active) return 'green.main'

    return 'background.paper'
  }

  const setActive = () => {
    setActiveIndex(index, index === pageSize)
  }

  if (!rowData.id) return <></>

  return (
    <Box
      onClick={setActive}
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderBottomColor: 'lightgray.main',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        bgcolor: darken && 'hover.main',
        color: 'darkgray.main',
        cursor: 'pointer',
        display: 'flex',
        width: '246px',
        flexDirection: 'column',
        '&:hover': {
          borderLeftColor: !active && 'hover.main',
          bgcolor: !active && 'hover.main',
        },
      }}
    >
      <ListItem
        disablePadding
        sx={{
          display: 'flex',
          overflow: 'hidden',
          justifyContent: 'space-around',
          my: 'auto',
          mx: 0,
          width: '246px',
          py: 2,
        }}
      >
        <Grid container>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Box sx={{ m: 'auto' }}>
              <Avatar
                {...stringAvatar({
                  ...rowData,
                  display: rowData.display || 'NEW USER',
                })}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Stack>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {rowData.display || 'NEW USER'}
              </Typography>
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {rowData.id ? getDataDisplay(rowData, 'username') : '@username'}
              </Typography>
              <Typography>ID {rowData.id || '****'}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </ListItem>
    </Box>
  )
}

export default UserMaintenancePreviewItem
