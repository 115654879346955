import React, { useMemo } from 'react'
import format from 'date-fns/format'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedListHR from 'components/styled/ExpandedListHR'

// local components
import StatusHistoryDisplay from './components/StatusHistoryDisplay'
import Notes from './components/Notes'
import ShowMoreContent from './components/ShowMoreContent'

import ReprintHistoryModal from 'components/correspondence/ResultsList/ReprintHistoryModal'

import useModal from 'hooks/context/useModal'
import useDocumentReprints from 'hooks/documents/useDocumentReprints'
import LetterHistoryModal from 'components/document_approval/LetterHistoryModal'

function CorrespondenceExpandedList({
  children,
  expanded,
  setExpanded,
  rowData,
  columns,
  statusTitle = '',
  statusMessage = '',
  getColumnDisplay,
  getDataDisplay,
  visibleColumns,
  collapseDetails,
  hoverButtons,
}) {
  const { setComponent, setOpen, setModalProps } = useModal()
  const { reprints, loading } = useDocumentReprints(expanded && rowData.id)
  const { notes, statusHistory, notification } = rowData?._embedded ?? {}
  const gridWidth = notes?.length > 0 ? 12 : 6

  const onReprintClick = () => {
    setComponent(ReprintHistoryModal)
    setModalProps({
      title: 'Reprint History',
      size: 'md',
      footerButtonProps: [
        {
          children: 'Close',
          color: 'primary',
          variant: 'outlined',
          onClick: () => setOpen(false),
        },
      ],
      reprints: reprints?.reverse() ?? [],
      docId: rowData.id,
      loading,
    })
    setOpen(true)
  }

  const onHistoryClick = () => {
    setComponent(LetterHistoryModal)
    setModalProps({
      letterId: rowData.letter_id,
      title: `Document History`,
    })
    setOpen(true)
  }

  const updatedColumns = useMemo(() => {
    let newColumns = [...columns]

    if (notification) {
      const { to } = notification
      if (to?.address) {
        newColumns.push({
          display: getColumnDisplay?.('_.notification.to') || 'NOTIFIED TO',
          id: '_.notification.to',
          sortable: false,
          exactMatch: false,
          filter: undefined,
        })
      }
    }

    return newColumns
  }, [columns, notification, getColumnDisplay])

  const updatedData = useMemo(() => {
    const newData = { ...rowData }

    if (notification) {
      const { to } = notification
      if (to?.address) {
        newData[`_.notification.to`] = to?.name
          ? `${to.name}<${to.address}>`
          : to.address
      }
    }

    return newData
  }, [rowData])

  return (
    <Collapse
      in={expanded}
      sx={{
        textAlign: 'left',
        px: 11,
        pt: 2,
        mt: 0,
        '& .MuiCollapse-entered': {
          mt: 4,
        },
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {statusTitle && (
          <Typography fontWeight="bold" fontSize={14} mr={2}>
            {statusTitle}
          </Typography>
        )}
        {reprints?.length > 0 && (
          <Typography
            fontWeight="bold"
            fontSize={14}
            sx={{ textDecoration: 'underline', cursor: 'pointer', zIndex: 1 }}
            onClick={onReprintClick}
            title="Click to see entire reprint history"
          >
            Last Reprint: {format(new Date(), 'MM/dd/yyyy')}
          </Typography>
        )}
      </Box>
      {statusMessage && <Box component="p">{statusMessage}</Box>}
      {statusHistory && expanded && (
        <StatusHistoryDisplay statusHistory={statusHistory} />
      )}
      <Grid container columns={gridWidth} spacing={30}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography fontWeight="medium" fontSize={14}>
              Details
            </Typography>
            {collapseDetails && (
              <IconButton onClick={() => setExpanded(!expanded)}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
            {expanded && hoverButtons && (
              <Box
                sx={{
                  justifySelf: 'flex-end',
                  justifyContent: 'flex-end',
                  float: 'right',
                  width: '100%',
                  position: 'relative',
                  pb: 9,
                }}
              >
                {hoverButtons}
              </Box>
            )}
          </Box>
          <ExpandedListHR />
          <ShowMoreContent
            rowData={updatedData}
            columns={updatedColumns}
            open={true}
            getDataDisplay={getDataDisplay}
            visibleColumns={visibleColumns}
          />
          {rowData.letter_id && (
            <Grid item xs={3} sx={{ pb: 4 }}>
              <Box
                component="span"
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: 'primary.main',
                }}
                title="See entire document"
                onClick={onHistoryClick}
              >
                Document History
              </Box>
            </Grid>
          )}
        </Grid>
        {notes?.length > 0 && (
          <Grid item xs={6}>
            <Notes notes={notes} />
          </Grid>
        )}
      </Grid>
      {children}
    </Collapse>
  )
}

export default CorrespondenceExpandedList
