import rh from 'helpers/css/rh'

export const THEMES = {
  DEFAULT: 'DEFAULT',
}

export const SIDEBAR_WIDTH = {
  OPEN: '280px',
  CLOSED: '88px',
  MOBILE: '80vw',
}

export const NAVBAR_DIMENSIONS = {
  HEIGHT: rh(69),
}

export const DATALIST_DIMENSIONS = {
  HEIGHT: rh(745),
  TABBED_HEIGHT: rh(664),
}

export const COLORS = {
  DARK_BLUE: '#002856',
  NAVY_BLUE: '#002E6D',
  DARK_GRAY: '#54565A',
  MEDIUM_GRAY: '#C4C4C4',
  LIGHT_GRAY: '#D9D8D6',
  HOVER_GRAY: '#FAFAF9',
  WHITE: '#FFFFFF',
  GREEN: '#76BC21',
  YELLOW: '#FFBB00',
  RED: '#BD0C00',
  TEAL: '#8BD9CB',
  PURPLE: '#8B93D9',
  PINK: '#D98BC8',
  BLUE: '#64B2DE',
  CHART_BLUE: '#003783',
  LIGHT_YELLOW: '#F9F871',
  LIGHT_BLUE: '#3B82E2',
}

export const COLOR_SHADES = {
  DARK_BLUE: [COLORS.DARK_BLUE, `${COLORS.DARK_BLUE}66`],
  NAVY_BLUE: [
    COLORS.NAVY_BLUE,
    `${COLORS.NAVY_BLUE}E6`,
    `${COLORS.NAVY_BLUE}B3`,
    `${COLORS.NAVY_BLUE}66`,
    `${COLORS.NAVY_BLUE}26`,
  ],
  LIGHT_BLUE: [COLORS.LIGHT_BLUE, `${COLORS.LIGHT_BLUE}26`],
  DARK_GRAY: [COLORS.DARK_GRAY, `${COLORS.DARK_GRAY}B3`],
  MEDIUM_GRAY: [COLORS.MEDIUM_GRAY, `${COLORS.MEDIUM_GRAY}B3`],
  LIGHT_GRAY: [COLORS.LIGHT_GRAY, `${COLORS.LIGHT_GRAY}26`, COLORS.HOVER_GRAY],
  WHITE: [COLORS.WHITE],
  GREEN: [COLORS.GREEN, `${COLORS.GREEN}66`, `${COLORS.GREEN}33`],
  YELLOW: [COLORS.YELLOW, `${COLORS.YELLOW}66`, `${COLORS.YELLOW}33`],
  RED: [COLORS.RED],
  TEAL: [COLORS.TEAL, `${COLORS.TEAL}66`],
  PURPLE: [COLORS.PURPLE, `${COLORS.PURPLE}66`],
  PINK: [COLORS.PINK],
  BLUE: [COLORS.BLUE],
  CHART_BLUE: [COLORS.CHART_BLUE, `${COLORS.CHART_BLUE}B3`],
  LIGHT_YELLOW: [
    COLORS.LIGHT_YELLOW,
    `${COLORS.LIGHT_YELLOW}B3`,
    `${COLORS.LIGHT_YELLOW}66`,
  ],
}

export const USER_COLORS = [
  COLORS.TEAL,
  COLORS.PURPLE,
  COLORS.PINK,
  COLORS.BLUE,
]

export const NOTIFICATION_TIMEOUT = 10000 // 10 secs

export const PATH_DISPLAYS = [
  {
    path: '/correspondence-hub/digital-mailbox/:documentId',
    display: 'Document ID :documentId',
  },
  {
    path: '/correspondence-hub/print-asset',
    display: 'Print Assets',
  },
  {
    path: '/correspondence-hub/print-asset/:assetId',
    display: 'Asset :assetId',
  },
  {
    path: '/command-center/postage/invoices/:invoiceNumber',
    display: 'Invoice #:invoiceNumber',
  },
  {
    path: '/correspondence-hub/library/:libId',
    display: 'Library Document :libId',
  },
]
export const REFRESH_INTERVAL = 60 * 1000 // 1 min
export const TIMEOUT = 15 * 60 * 1000 // 15 mins
export const PROMPT_TIMEOUT = 30 * 1000 // 30 secs

export const SMARTY = {
  API_URL: 'https://us-street.api.smartystreets.com/street-address',
  AUTOCOMPLETE_URL: 'https://us-autocomplete-pro.api.smartystreets.com/lookup',
}

export const TIMEZONES = {
  'America/New_York': 'US Eastern',
  'America/Chicago': 'US Central',
  'America/Denver': 'US Mountain',
  'America/Phoenix': 'US Mountain (Phoenix)',
  'America/Los_Angeles': 'US Pacific',
  'America/Anchorage': 'US Alaska (Anchorage)',
  'Pacific/Honolulu': 'US Hawaii',
  'America/Adak': 'US Alaska (Adak)',
}

// for PasswordCheck component
export const PASSWORD_CHECKS = [
  {
    display: 'Password must be at least 8 characters',
    regex: /.{8,}/,
  },
  {
    display: 'Password must contain at least one number',
    regex: /\d/,
  },
  {
    display: 'Password must contain at least one letter',
    regex: /[a-zA-Z]/,
  },
  {
    display: 'Password must contain at least one special character',
    regex: /[^a-zA-Z0-9]/,
  },
]

// list of all possible user privileges
// maps to user privilege dictionary in ConfigurationContext
export const PRIVILEGES = {
  /* DOWNLOADS */
  INITIATE_DOWNLOAD: 'initiate.download',

  /* Collateral */
  DELIVER_MAIL: 'deliver.mail',
  DELIVER_EMAIL: 'deliver.email',
  DELIVER_FAX: 'deliver.fax',

  /* SFTP */
  LIST_SFTP_HISTORY: 'list.sftp-history',

  /* WIP */
  VIEW_ORDER: 'view.order',
  DOWNLOAD_PROCESSING_REPORT: 'download.processing-report',
  LIST_ORDERS: 'list.orders',

  /* ORDER APPROVAL */
  APPROVE_ORDER: 'approve.order',
  HOLD_ORDER: 'hold.order',

  /* DIGITAL MAILBOX */
  MAIL_ORIGINAL: 'mail.original',
  ESCALATE_ITEM: 'escalate.item',
  REDACT_DOCUMENT: 'redact.document',
  SPLIT_DOCUMENT: 'split.document',
  VIEW_ALL_ITEMS: 'view.all-items',
  VIEW_ARCHIVED_ITEMS: 'view.archived-items',
  EDIT_CATEGORY: 'edit.category',
  EDIT_STATUS: 'edit.status',
  DOWNLOAD_BULK_ITEMS: 'download.bulk-items',
  ATTACH_MAIL_ORIGINAL_DOCUMENT: 'attach.mail-original-document',

  /* PRINT ASSETS */
  CREATE_PRINT_ASSET: 'create.print_asset',
  DELETE_PRINT_ASSET: 'delete.print_asset',
  EDIT_PRINT_ASSET: 'edit.print_asset',
  ACCESS_INTERNAL_PRINT_ASSET: 'access_internal.print_asset',

  /* MAIL TRACKING */
  VIEW_MAIL_TRACKING: 'view.mail_tracking',

  /* LIBRARY */
  VIEW_LIBRARY: 'view.library',
  MODIFY_LIBRARY: 'modify.library',

  /* REPORTING */
  EXPORT_REPORT: 'export.report',
  VIEW_REPORT: 'view.report',
  LIST_REPORTS: 'list.reports',
  USER_REPORTS: 'view.user',

  /* USER MAINTENANCE */
  RESET_PASSWORD: 'reset.password',
  VIEW_USER_HISTORY: 'view.user-history',
  MANAGE_USER_ROLES: 'manage.user_roles',
  DELETE_USER: 'delete.user',
  ACTIVATE_USER: 'activate.user',
  DEACTIVATE_USER: 'deactivate.user',
  EDIT_USER: 'edit.user',
  ADD_USER: 'add.user',
  VIEW_USER: 'view.user',
  VIEW_USERS: 'view.users',
  LIST_USERS: 'list.users',
  VIEW_USER_NOTES: 'view.user_note',

  /* CORRESPONDENCE */
  DOWNLOAD_ITEM: 'download.item',
  DOWNLOAD_BULK_DOCUMENTS: 'download.bulk-documents',
  PREVIEW_ITEM: 'view.item',
  DEACTIVATE_ITEM: 'deactivate.item',
  RESEND_ITEM: 'resend.item',
  VIEW_CONFIDENTIAL: 'view|confidential-items',
  VIEW_MY_ITEMS: 'view|my-items',
  ARCHIVE_ITEM: 'archive.item',
  SEARCH_DEFAULT: 'search.default',
  CUSTOM_SEARCH: 'search.custom',

  /* DOCUMENT APPROVAL */
  REJECT_DOCUMENT: 'reject.document',
  LIST_DOCUMENTS: 'list.documents',
  APPROVE_DOCUMENT: 'approve.document',
  EXPEDITE_DOCUMENT: 'expedite.document',
  ATTACH_DOCUMENT: 'attach.document',
  HOLD_DOCUMENT: 'hold.document',
  VIEW_DOCUMENT: 'view.document',
  DOWNLOAD_DOCUMENT: 'download.document',
  VIEW_NOTES: 'view.notes',
  ADD_NOTE: 'add.note',
}

export const MODULE_NAMES = {
  DASHBOARD: 'dashboard',
  DOWNLOADS: 'downloads',
  CORRESPONDENCE: 'correspondence',
  DIGITAL_MAILBOX: 'digital_mailbox',
  COLLATERAL: 'collateral',
  ORDER_APPROVAL: 'order_approval',
  DOCUMENT_APPROVAL: 'document_approval',
  ORDER_HISTORY: 'wip',
  SFTP_HISTORY: 'sftp_stats',
  USER_MAINTENANCE: 'user_maintenance',
  REPORTING: 'reporting',
}

export const ADMIN_USERNAME = 'MPXAdmin'

export const SSO_KEY = 'security_type'
export const CORRESPONDENCE_DESCRIPTION_SELECT =
  'correspondence_enable_description_select'
export const COLLATERAL_ENABLE_ACCOUNT_NUMBER =
  'collateral_enable_accountnumber_entry'
export const COLLATERAL_OPTIONAL_ACCOUNT_NUMBER = 'collateral_account_optional'
export const APP_KEY = 'mpxpp_healthlinq'
export const PRINT_ASSETS_CUSTOM_FIELDS = 'asset_manager_custom_fields'
export const ORDER_HISTORY_USER_ALERT_PROGRAMIDS = 'user_alert_programids'
export const BASE_PAGE_SIZE = 20

export const DASHBOARD_QUICK_SEARCH_FIELD =
  'dashboard_correspondence_quick_search'
export const LETTERS_ENABLED = 'letters_enable'
export const DASHBOARD_ACTIVE_WIDGETS = 'v3_dashboard_active_widgets'
export const ENABLE_LETTER_EXPEDITE = 'enable_letter_expedite'
export const ENABLE_LETTER_ATTACH = 'enable_letter_attach'
export const METADATA_COLUMN_NAMES = 'metadata_column_names'
export const INSTANCE_CONFIGIRATIONS = {
  CORRESPONDENCE_DESCRIPTION_SELECT,
  DASHBOARD_QUICK_SEARCH_FIELD,
  LETTERS_ENABLED,
  DASHBOARD_ACTIVE_WIDGETS,
  ENABLE_LETTER_ATTACH,
  ENABLE_LETTER_EXPEDITE,
  METADATA_COLUMN_NAMES,
}

export const DASHBOARD_WIDGETS = {
  DOCUMENT_APPROVAL: 'document-approval',
  ORDER_APPROVAL: 'order-approval',
  DIGITAL_MAILBOX: 'digital-mail',
  COLLATERAL: 'collateral',
  CORRESPONDENCE: 'correspondence',
  ORDER_HISTORY: 'order-history',
  SFTP_HISTORY: 'sftp-stats',
}

export const WIDGET_KEYS = {
  DOCUMENT_APPROVAL_DONUT: 'document-approval-donut',
  ORDER_APPROVAL_DONUT: 'order-approval-donut',
  ORDER_APPROVAL_LIST: 'order-approval-list',
  ORDER_HISTORY_CHART: 'order-history-chart',
  ORDER_HISTORY_QUICKSEARCH: 'order-history-quick-search',
  COLLATERAL_BARS: 'collateral-bars',
  COLLATERAL_DONUT: 'collateral-donut',
  DIGITAL_MAILBOX_DONUT: 'digital-mailbox-donut',
  CORRESPONDENCE_DONUT: 'correspondence-donut',
  CORRESPONDENCE_DUAL_DONUT: 'correspondence-dual-donut',
  CORRESPONDENCE_QUICKSEARCH: 'correspondence-quick-search',
  CORRESPONDENCE_SAVED_SEARCH: 'correspondence-saved-search',
  DIGITAL_MAILBOX_ASSIGNMENTS: 'digital-mailbox-assignments',
  SFTP_STATS_CHART: 'sftp-stats-chart',
}

export const WIDGET_KEY_DISPLAYS = {
  [WIDGET_KEYS.DOCUMENT_APPROVAL_DONUT]: 'Document Approval Statuses',
  [WIDGET_KEYS.ORDER_APPROVAL_DONUT]: 'Order Approval Statuses',
  [WIDGET_KEYS.ORDER_APPROVAL_LIST]: 'Order Approval List',
  [WIDGET_KEYS.ORDER_HISTORY_CHART]: 'Order History Completed Orders',
  [WIDGET_KEYS.ORDER_HISTORY_QUICKSEARCH]: 'Order History Quick Search',
  [WIDGET_KEYS.COLLATERAL_BARS]: 'Collateral Created Documents By Mail Type',
  [WIDGET_KEYS.COLLATERAL_DONUT]: 'Collateral Created Documents By Category',
  [WIDGET_KEYS.DIGITAL_MAILBOX_DONUT]: 'Digital Mailbox Open By Status',
  [WIDGET_KEYS.CORRESPONDENCE_DONUT]: 'Correspondence Documents',
  [WIDGET_KEYS.CORRESPONDENCE_DUAL_DONUT]: 'Correspondence New + Failed',
  [WIDGET_KEYS.CORRESPONDENCE_QUICKSEARCH]: 'Correspondence Quick Search',
  [WIDGET_KEYS.CORRESPONDENCE_SAVED_SEARCH]: 'Correspondence Saved Search',
  [WIDGET_KEYS.DIGITAL_MAILBOX_ASSIGNMENTS]: 'Digital Mailbox Assignments',
  [WIDGET_KEYS.SFTP_STATS_CHART]: 'SFTP History Files To + From MPX',
}

export const INSTANCES = {
  EVOLENT: 'evolent',
}

export const DELIVERY_TYPES = {
  MAIL: 0,
  EMAIL: 1,
  FAX: 2,
  CLAIM_DENIAL: 3,
}

export const COLLATERAL_MODES = {
  DEFAULT: 0,
  STANDALONE: 1,
}

export const STANDALONE_DELIVERY_TYPE_NAMES = {
  [DELIVERY_TYPES.CLAIM_DENIAL]: 'ClaimDenial',
}

/* # of days to load per page scroll */
export const POSTAGE_INVOICE_PAGE_DAYS = 90
export const WIP_PAGE_DAYS = 30 // # of days to load per page scroll in Order History

export const PRINT_ASSET_MIME_TYPES = {
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TIFF: 'image/tiff',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  PSD: 'image/vnd.adobe.photoshop',
  EPS: 'image/x-eps',
  AI: 'application/illustrator',
  TXT: 'text/*',
}

export const MIME_TYPES = {
  ...PRINT_ASSET_MIME_TYPES,
}

export const MANUAL_BILL_STATUSES = {
  COMPLETED: 'Completed',
  PENDING_REVIEW: 'Pending Review',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  ON_HOLD: 'On Hold',
}

export const MANUAL_BILL_DEFAULT_STATUSES = [
  MANUAL_BILL_STATUSES.PENDING_REVIEW,
  MANUAL_BILL_STATUSES.ON_HOLD,
]

export const MANUAL_BILL_READONLY_STATUSES = [MANUAL_BILL_STATUSES.COMPLETED]
export const POSTAGE_TRANSACTION_PAGE_RECORDS = 25

export const POSTAGE_TRANSCTION_TYPE = {
  PAYMENT: 'payment',
  ADJUSTMENT: 'adjustment',
  POSTAGE: 'postage',
}

export const FOLDER_NUM_TEXT_DESCRIPTIONS = {
  10000: 'Investigation and Correspondence',
  10050: 'Forms/Legal',
  30100: 'Explanation of Benefits',
  10020: 'Medical Bills & Payment Requests',
  10030: 'Medical Correspondence',
  '10209W': 'Wage Information',
  Liens: 'Liens Folder',
}

export const DOCUMENT_TYPE_DESCRIPTIONS = {
  10000: {
    FX: 'Outgoing Fax',
    CORR: 'Correspondence',
    DMGT: 'Disability Management',
    EML: 'Emails',
    INIT: 'Initial',
    INVS: 'Investigations',
    MDCR: 'Medicare Eligibility Reports',
    MDCS: 'Medical & Disability Coordination Services',
    MMGT: 'Medical Management',
    SPIR: 'Special Investigation Reports',
    VOCR: 'VOC Rehab',
  },
  10050: {
    FX: 'Outgoing Fax',
    CORR: 'Correspondence',
    DFCL: 'Defense Counsel',
    DSHL: 'DHS Liens',
    EMCL: 'Employee Counsel',
    EML: 'Emails',
    FRPT: 'First Report',
    MOP: 'Memorandum of Payment',
    NOC: 'Notice of Controversy',
    ORDC: 'Orders/Decrees',
    SOC: 'Statement',
    STFF: 'State Forms/Fillings',
    WCBR: 'WCB Requests',
  },
  30100: {
    FX: 'Outgoing Fax',
    EOBS: 'Explanation of Benefits',
  },
  10020: {
    FX: 'Outgoing Fax',
    DMGT: 'Disability Management',
    HSFC: 'Hospitals and Facilities',
    MMGT: 'Medical Management',
    NOCD: "NOC'd Medical Bills",
    PBDC: 'Partial Benefit Documentation',
    PROV: 'Providers',
    PRTR: 'Prescriptions, Travel Reimbursements, etc',
    RCVY: 'Recovery',
    VOCR: 'VOC Rehab',
  },
  10030: {
    FX: 'Outgoing Fax',
    PRRT: 'Provider Reports',
    RXCD: 'RX Card',
    RXUR: 'RX Usage Report',
    SOPX: 'Second Opinion Exams',
    UR: 'Utilization Review',
  },
  '10209W': {
    FX: 'Outgoing Fax',
    SOD: 'Schedule of Department',
    TPD: 'TPD Calc',
    WSFB: 'Wage Statements & Fringe Benefits',
  },
  Liens: {
    OFCC: 'Outgoing Fax',
    CS: 'Child Support',
    MED: 'Medicare',
    Other: 'Other',
  },
}

export const LETTER_TYPES = {
  myhealth: {
    'D - PHP': 'D - PHP',
    'H - Healthplan': 'H - Healthplan',
    'I - Rendering Image': 'I - Rendering Image',
    'M - Member': 'M - Member',
    'P - Provider': 'P - Provider',
    'X - UNDEFINED': 'X - UNDEFINED',
  },
}

export const MEDICARE_OPTIONS = {
  myhealth: { YES: 'YES', NO: 'NO' },
}

export const DOCUMENT_INBOUND_NOTE_ACTIVITY_TYPES = {
  VIEW: 'VIEW',
}

export const REPORT_USAGE = {
  ALL: 'All',
  INTERNAL: 'Internal',
}

export const COLLATERAL_FIELD_TYPES = {
  FILEUPLOAD: 'fileupload',
  CHECKBOX: 'checkbox',
  MATERIAL_SELECT_7102: 'material_select_7102',
  MULTISELECT: 'multiselect',
  DATE: 'date',
  TIME: 'time',
}

export const DIGITAL_MAIL_METADATA_INSTANCE_COLUMNS = {
  evolentmpc: [
    {
      id: 'member_id',
      apiId: 'MemberID',
      list: true,
      editable: true,
    },
  ],
  chubb: [
    {
      id: 'CheckAmount',
      apiId: 'CheckAmount',
      list: true,
      editable: true,
    },
    {
      id: 'CheckNumber',
      apiId: 'CheckNumber',
      list: true,
      editable: true,
    },
    {
      id: 'CheckSender',
      apiId: 'CheckSender',
      list: true,
      editable: true,
    },
    {
      id: 'CAName',
      apiId: 'CAName',
      editable: true,
    },
    {
      id: 'DateCAReceived',
      apiId: 'DateCAReceived',
      editable: true,
    },
    {
      id: 'System',
      apiId: 'System',
      list: true,
      editable: true,
    },
    {
      id: 'ClaimID',
      apiId: 'ClaimID',
      editable: true,
    },
    {
      id: 'DelayReason',
      apiId: 'DelayReason',
      editable: true,
    },
    {
      id: 'DateCheckPulled',
      apiId: 'DateCheckPulled',
    },
    {
      id: 'DateCheckShipped',
      apiId: 'DateCheckShipped',
    },
    {
      id: 'PulledOrShippedTracking',
      apiId: 'PulledOrShippedTracking',
    },
    {
      id: 'DepositDate',
      apiId: 'DepositDate',
    },
    {
      id: 'PullsOrderID',
      apiId: 'PullsOrderID',
    },
    {
      id: 'CheckIssueDate',
      apiId: 'CheckIssueDate',
      editable: true,
    },
    {
      id: 'LOB',
      apiId: 'LOB',
      editable: true,
    },
    {
      id: 'ReturnReason',
      apiId: 'ReturnReason',
      editable: true,
    },
    {
      id: 'Resolution',
      apiId: 'Resolution',
      editable: true,
    },
    {
      id: 'DateCompleted',
      apiId: 'DateCompleted',
      editable: true,
    },
    {
      id: 'Payee',
      apiId: 'Payee',
      editable: true,
    },
    {
      id: 'OriginalDocument',
      apiId: 'OriginalDocument',
      editable: true,
    },
    {
      id: 'PhysicalItemDescription',
      apiId: 'PhysicalItemDescription',
      editable: true,
    },
    {
      id: 'VinNumber',
      apiId: 'VinNumber',
      editable: true
    },
    {
      id: 'ClaimantName',
      apiId: 'ClaimantName',
      editable: true
    }
  ],
}
