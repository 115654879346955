import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import ActivityTable from './ActivityTable'
import Modal from 'components/common/Modal'

import useAgencyPreferencesActivity from 'hooks/agency_preferences/useAgencyPreferenceActivity'

function ActivityModal({ open, setOpen, agency }) {
  const { audits, loading, mutate } = useAgencyPreferencesActivity({
    agencyCode: agency?.agency_code,
  })

  useEffect(() => {
    if (open) {
      mutate()
    }
  }, [open])

  return (
    <Modal
      scrollable
      height="80vh"
      width="90vw"
      open={open}
      onClose={() => setOpen(false)}
      title={`${agency?.name} (${agency?.agency_code}) History`}
      footerButtonProps={[
        {
          children: 'Close',
          variant: 'outlined',
          color: 'primary',
          onClick: () => setOpen(false),
        },
      ]}
    >
      <Box sx={{ display: 'flex', width: '100%' }}>
        {loading ? (
          <CircularProgress sx={{ mx: 'auto' }} />
        ) : (
          <ActivityTable audits={audits} />
        )}
      </Box>
    </Modal>
  )
}

export default ActivityModal
