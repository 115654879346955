import React, { useCallback, useEffect, useState, useMemo } from 'react'
import format from 'date-fns/format'
import InfiniteScroll from 'react-infinite-scroll-component'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'

import DateRangeIcon from '@mui/icons-material/DateRange'

import PureDataList from 'layouts/PureDataList'

import ExportToCSV from 'components/common/ExportToCSV'
import DataListTable from 'components/common/DataList/Table'
import PostTransactionActivityRow from 'components/common/DataList/Item/PostageTransactionActivity'
import SearchField from 'components/common/SearchField'
import DateRangePicker from 'components/common/DateRangePicker'
import ProgramsAutocomplete from 'components/programs/ProgramsAutocomplete'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import usePostageTransactionActivityInfinite from 'hooks/postage/usePostageTransactionActivityInfinite'

import isEmptyObject from 'helpers/node/isEmptyObject'
import { POSTAGE_TRANSCTION_TYPE } from 'utils/constants'

function TransactionRowGroup({ transactionGroup, dataListProps, columns }) {
  return transactionGroup._embedded.transactions.map((transaction) => {
    return (
      <PostTransactionActivityRow
        key={JSON.stringify(transaction)}
        rowKey={'order_id'}
        row={transaction}
        getDataDisplay={dataListProps?.getDataDisplay}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
      />
    )
  })
}

function TransactionActivity({ clientId }) {
  const [transactionTypes, setTransactionTypes] = useState(
    [
      POSTAGE_TRANSCTION_TYPE.ADJUSTMENT,
      POSTAGE_TRANSCTION_TYPE.PAYMENT,
      POSTAGE_TRANSCTION_TYPE.POSTAGE,
    ].join(',')
  )
  const [dateRange, setDateRange] = useState([undefined, undefined])
  const [orderId, setOrderId] = useState('')
  const [selectedPrograms, setSelectedPrograms] = useState([])
  const [filter, setFilter] = useState({})
  const [sortList, setSortList] = useState({})

  const columns = useMemo(
    () =>
      [
        {
          display: 'METER DATE',
          id: 'date_transaction',
          sortable: true,
          exactMatch: false,
        },
        {
          display: 'PROGRAM NAME',
          id: 'program_name',
          sortable: true,
          exactMatch: false,
        },
        {
          display: 'ORDER ID',
          id: 'order_id',
          sortable: true,
          exactMatch: false,
        },
        {
          display: 'LOT #',
          id: 'lot_number',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'POSTAGE TYPE',
          id: 'postage_type',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'COUNTS',
          id: 'document_count',
          sortable: false,
          exactMatch: false,
        },
        {
          display: 'AMOUNT',
          id: 'amount',
          sortable: false,
          exactMatch: false,
        },
        filter.clientId && {
          display: 'RUNNING BALANCE',
          id: 'ending_balance',
          sortable: false,
          exactMatch: false,
        },
      ].filter((c) => c),
    [filter]
  )

  const { setTotalPages } = useDataListContext()

  const getHookParams = useCallback(
    (isBase = false) => {
      let dateTransactionStart = dateRange[0]
      let dateTransactionEnd = dateRange[1] || dateRange[0]

      const params = {}

      // textfield filters
      if (selectedPrograms?.length) {
        params['program_name'] = selectedPrograms
          .map((prog) => prog.name)
          .join(',')
      }

      if (filter?.clientId) {
        params['client_id'] = filter?.clientId
      }

      params['order_id'] = filter?.orderId
      params['transaction_type'] = transactionTypes

      // no need to append initial 30 if user input exists
      const userInputExists =
        Object.values(params).filter((p) => p).length > 0 ||
        dateTransactionStart instanceof Date ||
        !isEmptyObject(sortList)

      if (userInputExists && isBase) {
        return { exclude: true }
      }

      if (isEmptyObject(sortList)) {
        params['sort[0]'] = 'date_transaction:desc'
      }

      if (dateTransactionStart instanceof Date) {
        params['date_transaction[start]'] = format(
          dateTransactionStart,
          'yyyy-MM-dd'
        )
      }

      if (dateTransactionEnd instanceof Date) {
        params['date_transaction[end]'] = format(
          dateTransactionEnd,
          'yyyy-MM-dd'
        )
      }

      return {
        ...params,
        ...sortList,
      }
    },
    [dateRange, filter, sortList, selectedPrograms, transactionTypes]
  )

  const hookParams = useMemo(() => getHookParams(false), [getHookParams])

  const {
    transactions: postageTransactions,
    total,
    page,
    setPage,
    loading,
    additionalLoading,
    getCSV,
  } = usePostageTransactionActivityInfinite({
    params: hookParams,
  })

  const postageTransactionsCount = useMemo(() => {
    return postageTransactions.reduce(
      (a, b) => a + b._embedded.transactions.length,
      0
    )
  }, [postageTransactions])

  const { dataList, dataListProps } = useDataList({
    baseRowData: [],
    presetName: 'postage',
  })

  useEffect(() => {
    setTotalPages(0)
  }, [])

  useEffect(() => {
    setFilter({
      ...filter,
      clientId: clientId,
    })
  }, [clientId])

  const renderTransactions = useMemo(() => {
    return postageTransactions.map((transactionGroup, index) => (
      <TransactionRowGroup
        key={index}
        transactionGroup={transactionGroup}
        columns={columns}
        dataListProps={dataListProps}
      />
    ))
  }, [postageTransactions, columns, dataListProps])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography
            my="auto"
            color="primary.main"
            fontWeight={700}
            fontSize="24px"
            mr={18}
          >
            Transaction Activity
          </Typography>
          <Box>
            <ExportToCSV
              getCsvExport={getCSV}
              filename={`Postage.Transaction.History.${Date.now()}.csv`}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', width: '100%', mb: 4 }}>
        <Box sx={{ mr: 4, flex: 1 }}>
          <TextField
            select
            fullWidth
            size="small"
            label="Transaction Type"
            value={transactionTypes}
            onChange={(e) => setTransactionTypes(e.target.value)}
          >
            <MenuItem
              value={[
                POSTAGE_TRANSCTION_TYPE.ADJUSTMENT,
                POSTAGE_TRANSCTION_TYPE.PAYMENT,
                POSTAGE_TRANSCTION_TYPE.POSTAGE,
              ].join(',')}
              selected={transactionTypes === 'adjustment,payment,postage'}
            >
              Any
            </MenuItem>
            <MenuItem
              value={[
                POSTAGE_TRANSCTION_TYPE.PAYMENT,
                POSTAGE_TRANSCTION_TYPE.POSTAGE,
              ].join(',')}
              selected={transactionTypes === 'payment,postage'}
            >
              Payments and Postage
            </MenuItem>
            <MenuItem
              value={POSTAGE_TRANSCTION_TYPE.ADJUSTMENT}
              selected={transactionTypes === 'adjustment'}
            >
              Adjustments
            </MenuItem>
            <MenuItem
              value={POSTAGE_TRANSCTION_TYPE.PAYMENT}
              selected={transactionTypes === 'payment'}
            >
              Payments
            </MenuItem>
            <MenuItem
              value={POSTAGE_TRANSCTION_TYPE.POSTAGE}
              selected={transactionTypes === 'postage'}
            >
              Postage
            </MenuItem>
          </TextField>
        </Box>
        <Box sx={{ mr: 4, flex: 1 }}>
          <DateRangePicker
            size="small"
            label="Transaction Date"
            value={dateRange}
            setValue={setDateRange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mr: 4, flex: 1 }}>
          <SearchField
            fullWidth
            size="small"
            label="Order ID"
            placeholder="10001,10002,..."
            value={orderId || ''}
            onChange={(e) => {
              setOrderId(e.target.value)
            }}
            onSearch={(val) =>
              setFilter({
                ...filter,
                orderId: val ?? orderId,
              })
            }
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <ProgramsAutocomplete
            multiple
            size="small"
            maxHeight="25vh"
            placeholder="Program1,Program2,..."
            textFieldProps={{
              label: 'Program name',
            }}
            selectedPrograms={selectedPrograms}
            setSelectedPrograms={setSelectedPrograms}
            hookParams={{
              prod_only: '1',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Card sx={{ boxShadow: 3, width: '100%' }}>
          <DataListTable
            dataList={dataList}
            columns={columns}
            infiniteScroll
            infiniteScrollProps={{
              next: () => setPage(page + 1),
              hasMore: postageTransactionsCount < total,
              dataLength: postageTransactionsCount || 0,
              height: undefined,
              scrollableTarget: 'main',
              scrollThreshold: 0.975,
            }}
            rowKey="order_id"
            RowComponent={PostTransactionActivityRow}
            total={total || 1}
            getDataDisplay={dataListProps.getDataDisplay}
            colProps={{
              sx: {
                py: 4,
              },
            }}
            height={null}
            loading={loading}
            additionalLoading={additionalLoading}
            sortList={sortList}
            setSortList={setSortList}
            Component={InfiniteScroll}
            {...dataListProps}
            visibleColumns={[
              'date_transaction',
              'program_name',
              'order_id',
              'lot_number',
              'postage_type',
              'document_count',
              'amount',
              Boolean(filter?.clientId) && 'ending_balance',
            ].filter((c) => c)}
          >
            {renderTransactions}
          </DataListTable>
        </Card>
      </Box>
    </Box>
  )
}

function TransactionActivityWrapper(props) {
  return (
    <PureDataList>
      <TransactionActivity {...props} />
    </PureDataList>
  )
}

export default TransactionActivityWrapper
