import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ToggleButton from '@mui/material/ToggleButton'
import Typography from '@mui/material/Typography'

import ViewListIcon from '@mui/icons-material/ViewList'
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'

import DownloadsTemplate from 'components/common/Notification/templates/DownloadsTemplate'
import DownloadsNotification from 'components/common/Notification/Downloads'
import ResendModal from './ResendModal'
import DeactivateModal from './DeactivateModal'

import useDataListContext from 'hooks/context/useDataListContext'
import useDocuments, { useDocument } from 'hooks/useDocuments'
import useModal from 'hooks/context/useModal'
import useNotification from 'hooks/context/useNotification'
import useConfiguration from 'hooks/useConfiguration'

import urlSerialize from 'helpers/browser/urlSerialize'

import { PRIVILEGES } from 'utils/constants'
import FilterToggleButton from 'components/common/FilterToggleButton'

function ResultsListHeader({
  documents,
  searchParams,
  deactivateDocuments,
  open,
  getAllDocuments,
  setOpen,
  loading,
  filterCount,
}) {
  const {
    checkedCount,
    checked,
    layoutType,
    setLayoutType,
    total,
    allChecked,
    checkAllPreset,
  } = useDataListContext()
  const { hasPrivilege, canAccessModule } = useConfiguration()
  const { setModalProps, setOpen: setModalOpen } = useModal()
  const { setComponent, setOpen: setNotifOpen, setError } = useNotification()
  const { downloadDocuments, downloadDocumentsBySearch } = useDocuments(null)
  const { getDocument, downloadDocument } = useDocument()

  const [resendOpen, setResendOpen] = useState(true)
  const [deactivateOpen, setDeactivateOpen] = useState(false)
  const [bulkUseSearchParams, setBulkUseSearchParams] = useState(false)
  const [searchedParams, setSearchedParams] = useState([])

  const hideDeactivate = !hasPrivilege(PRIVILEGES.DEACTIVATE_ITEM)
  const hideResend = !hasPrivilege(PRIVILEGES.RESEND_ITEM)
  const canAccessDownloads = canAccessModule('/main/downloads')

  const itemName = {
    singular: 'document',
    plural: 'documents',
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const [checkedDocuments, setCheckedDocuments] = useState([])

  useEffect(() => {
    // handle deactivating every document
    if (
      allChecked &&
      typeof checkAllPreset === 'string' &&
      checkAllPreset === 'all-matching-query'
    ) {
      setBulkUseSearchParams(true)
      let parsedParams = []
      if (searchParams && typeof searchParams === 'object') {
        Object.keys(searchParams).forEach((key) => {
          if (
            typeof key !== 'string' ||
            key === 'embed' ||
            key.startsWith('paging') ||
            key.startsWith('sort')
          ) {
            return
          }

          parsedParams[key] = searchParams[key]
        })
      }

      setSearchedParams(parsedParams)
    } else {
      setBulkUseSearchParams(false)
    }

    if (checkedCount === total) {
      getAllDocuments().then((allDocuments) =>
        setCheckedDocuments(allDocuments)
      )
      return
    }
    const pageDocuments = documents.filter((_, index) => checked[index])
    setCheckedDocuments(pageDocuments)
  }, [
    total,
    checked,
    checkedCount,
    allChecked,
    checkAllPreset,
    getAllDocuments,
  ])

  const handleDeactivate = async (documentsToDeactivate) => {
    const checkedDocumentIds = documentsToDeactivate.map((doc) => doc.id)
    const itemDisplay =
      checkedDocumentIds.length === 1 ? itemName.singular : itemName.plural

    const handleDocumentDeactivate = async () => {
      try {
        await deactivateDocuments(checkedDocumentIds)

        const DeactivateNotification = (props) => (
          <DownloadsTemplate {...props}>
            {`${checkedDocumentIds.length} ${itemDisplay}`} have been
            successfully deactivated.
          </DownloadsTemplate>
        )

        setComponent(DeactivateNotification)
        setNotifOpen(true)
      } catch (err) {
        setError(err.message)
      }

      setModalOpen(false)
      setDeactivateOpen(false)
    }

    const deactivateModalProps = {
      title: 'Deactivate Documents',
      children: (
        <>
          <Typography color="darkgray.main" pb={1}>
            You are about to deactivate {checkedDocumentIds.length}{' '}
            {itemDisplay}. When you deactivate a document, users will no longer
            be able to find or view the document in a search.
          </Typography>
          <Typography color="darkgray.main">
            Please confirm this action and click Deactivate below, or click
            Cancel to go back to the previous screen.
          </Typography>
        </>
      ),
      width: '414px',
      height: '368px',
      footerButtonProps: [
        {
          children: 'Deactivate',
          color: 'primary',
          variant: 'contained',
          onClick: () => handleDocumentDeactivate(),
        },
        {
          children: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          onClick: () => setModalOpen(false),
        },
      ],
      hideButtonsBorder: true,
    }

    setModalProps(deactivateModalProps)
    setModalOpen(true)
  }

  const onDeactivatePress = () => {
    if (checkedDocuments.length === 1) {
      handleDeactivate(checkedDocuments)
    } else {
      setDeactivateOpen(true)
    }
  }

  const handleDownload = async () => {
    try {
      if (bulkUseSearchParams) {
        await downloadDocumentsBySearch(
          searchedParams,
          '/documents/extract' +
            (searchedParams ? `?${urlSerialize({ ...searchedParams })}` : '')
        )
        setComponent(DownloadsNotification)
        setNotifOpen(true)
      } else if (checkedDocuments.length <= 0) {
        return
      } else if (checkedDocuments.length === 1) {
        const checkedDocument = checkedDocuments[0]
        const documentId = checkedDocument.id
        const document = await getDocument(documentId)
        await downloadDocument(document)
      } else {
        const documentIds = checkedDocuments.map((doc) => doc.id)
        await downloadDocuments(documentIds)
        setComponent(DownloadsNotification)
        setNotifOpen(true)
      }
    } catch (err) {
      setError(err.response?.data?.display_message || err.message)
    }
  }

  return (
    <>
      <ResendModal
        open={resendOpen}
        onClose={() => setResendOpen(false)}
        selectedItems={checkedDocuments}
      />
      <DeactivateModal
        open={deactivateOpen}
        onClose={() => setDeactivateOpen(false)}
        selectedItems={checkedDocuments}
        itemName={itemName}
        deactivateDocuments={deactivateDocuments}
        handleDeactivate={handleDeactivate}
      />
      <Box sx={{ minWidth: '1024px', display: 'flex', mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            height: '100%',
            minWidth: '436px',
            my: 'auto',
          }}
        >
          {!hideResend && (
            <Button
              color="primary"
              variant="contained"
              size="action-footer"
              disabled={checkedCount <= 0}
              onClick={() => setResendOpen(true)}
            >
              Resend
            </Button>
          )}
          {!hideDeactivate && (
            <Button
              color="secondary"
              variant="contained"
              size="action-footer"
              disabled={checkedCount <= 0}
              onClick={() => onDeactivatePress()}
            >
              Deactivate
            </Button>
          )}
          <Button
            color="primary"
            variant="outlined"
            size="action-footer"
            disabled={
              checkedCount <= 0 || (!canAccessDownloads && checkedCount > 1)
            }
            onClick={() => handleDownload()}
          >
            Download
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {checkedCount > 0 && (
            <Box sx={{ px: 4, pt: 2 }}>
              <Typography>
                {`${checkedCount} ${
                  checkedCount === 1 ? itemName.singular : itemName.plural
                } selected`}
              </Typography>
            </Box>
          )}
          {total === 0 && !loading && (
            <Box sx={{ px: 4, pt: 2 }}>
              <Typography>No {itemName.plural} found</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '50%',
            my: 'auto',
            '& > *': { my: 'auto' },
            justifyContent: 'flex-end',
            justifySelf: 'flex-end',
          }}
        >
          <FilterToggleButton
            open={open}
            toggleOpen={toggleOpen}
            filterCount={filterCount}
          />
          <Box sx={{ width: '32px' }} />
          <ToggleButton
            color="primary"
            value="list"
            sx={{ mr: 4 }}
            selected={layoutType !== 'normal'}
            disabled={layoutType === 'normal'}
            onClick={() => setLayoutType('normal')}
          >
            <ViewListIcon />
          </ToggleButton>
          <ToggleButton
            color="primary"
            value="preview"
            selected={layoutType !== 'preview'}
            disabled={layoutType === 'preview'}
            onClick={() => setLayoutType('preview')}
          >
            <ViewSidebarIcon sx={{ transform: 'rotate(180deg)' }} />
          </ToggleButton>
        </Box>
      </Box>
    </>
  )
}

export default ResultsListHeader
