import { useContext } from 'react'
import { SidebarContext } from 'contexts/SidebarContext'

const useSidebar = (id) => {
  const context = useContext(SidebarContext)

  if (!context)
    throw new Error('SidebarContext must be placed within SidebarProvider')

  if (!id) throw new Error('Sidebar id is required')

  return {
    ...context,
    open: context.sidebars[id]?.open,
    mounted: context.sidebars[id]?.mounted,
    toggleOpen: () => context.toggleOpen(id),
    setOpen: (open) => context.setOpen(id, open),
    setMounted: (mounted) => context.setMounted(id, mounted),
  }
}

export default useSidebar
