import useSWR from 'swr'

import axios from 'utils/axios'
import { etagFetcher } from 'utils/fetcher'

const useLetterDocumentNotes = (
  letterId,
  documentId,
  params = { type: 'User' }
) => {
  const { data, isValidating, mutate } = useSWR(
    letterId && documentId
      ? `/letters/${letterId}/documents/${documentId}/notes`
      : null,
    etagFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const makeNote = async (note) => {
    const res = await axios.post(
      `/letters/${letterId}/documents/${documentId}/notes`,
      {
        note,
      }
    )

    mutate()

    return res
  }

  return {
    notes: data?._embedded?.notes ?? [],
    loading: (!data?._embedded?.notes && !data?.error) || isValidating,
    error: data?.error,
    mutate,
    makeNote,
  }
}

export default useLetterDocumentNotes
