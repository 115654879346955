import React from 'react'

import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import GlobalStyle from 'components/GlobalStyle'
import Logo from 'components/common/Logo'

function LoginLoadingScreen() {
  return (
    <Box sx={{ height: '100vh', width: '100vw', display: 'flex' }}>
      <CssBaseline />
      <GlobalStyle />
      <Box sx={{ width: '226px', m: 'auto', textAlign: 'center' }}>
        <Logo />
        <Typography color="primary.main" fontWeight="medium" mb={1}>
          Logging you in...
        </Typography>
        <LinearProgress />
      </Box>
    </Box>
  )
}

export default LoginLoadingScreen
