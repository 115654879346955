import React, { useEffect, useState } from 'react'

import CurrentBalance from 'components/postage/CurrentBalance'
import PostageInvoices from 'components/postage/PostageInvoices'
import TransactionActivity from 'components/postage/TransactionActivity'

import Box from '@mui/material/Box'
import ClientSelect from 'components/postage/ClientSelect'
import { useSearchParams } from 'react-router-dom'

function PostagePage() {
  const [mounted, setMounted] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [clientId, setClientId] = useState(searchParams.get('clientId'))
  const [showClientId, setShowClientId] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return <></>

  return (
    <Box sx={{ py: 12, px: 8, width: '100%', overflow: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          height: { xs: '100%' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box sx={{ mr: { md: 6 }, mb: { xs: 12, lg: 0 } }}>
          <ClientSelect
            value={clientId}
            setValue={(value) => {
              setClientId(value)

              if (showClientId) {
                if (!value) {
                  setSearchParams({}, { replace: true })
                } else {
                  setSearchParams({ clientId: value }, { replace: true })
                }
              }
            }}
            setHasMultiple={setShowClientId}
          />
          <CurrentBalance clientId={clientId} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <PostageInvoices clientId={clientId} showClientId={showClientId} />
        </Box>
      </Box>
      <Box sx={{ mt: 12 }}>
        <TransactionActivity clientId={clientId} />
      </Box>
    </Box>
  )
}

export default PostagePage
