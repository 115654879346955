import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import useDataListContext from 'hooks/context/useDataListContext'

function HoverButtons({ sx, expanded, ...props }) {
  const { dimensions } = useDataListContext()
  const { actionButtonProps } = props
  const left = expanded
    ? dimensions.width -
      (160 + 120 * actionButtonProps.length) +
      dimensions.scrollLeft
    : dimensions.width -
      130 * actionButtonProps.length -
      40 +
      dimensions.scrollLeft
  const hide = !(dimensions.width > 0)

  return hide ? (
    <></>
  ) : (
    <Box
      className={!expanded && 'hover-buttons'}
      sx={{
        bgcolor: 'inherit',
        display: 'flex',
        position: 'absolute',
        left: left,
        '& > .MuiButton-root': {
          mr: 2,
        },
        pr: 6,
        ...sx,
      }}
    >
      {actionButtonProps.map(({ onClick, ...rest }, buttonIndex) => (
        <Button
          key={`action-button-${buttonIndex}`}
          {...rest}
          onClick={(e) => {
            onClick(props)
            e.stopPropagation()
          }}
        />
      ))}
    </Box>
  )
}

export default HoverButtons
