import useSWR from 'swr'

import { postFetcher } from 'utils/fetcher'

const useSFTPUserSchedules = (params) => {
  const { data, isValidating, mutate } = useSWR(
    params ? '/sftp-user-schedules' : null,
    (url) => postFetcher(url, params),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    userSchedules: data?._embedded?.user_schedules || [],
    embedded: data?._embedded ?? {},
    loading: (!data?._embedded?.user_schedules && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default useSFTPUserSchedules
