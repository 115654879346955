import React, { useMemo, useEffect, useState, useRef } from 'react'
import { PRINT_ASSET_MIME_TYPES } from 'utils/constants'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import FileTypeSquare from './FileTypeSquare'
import ImagePreviewModal from 'components/common/ImagePreviewModal'

import { usePrintAssetPreview } from 'hooks/print_assets'
import useModal from 'hooks/context/useModal'
import getFilenameExt from 'helpers/node/getFilenameExt'

function PreviewSquare({ asset, fluid = false }) {
  const [cachedAsset, setCachedAsset] = useState({})
  const [src, setSrc] = useState()
  const { preview, refetch, loading } = usePrintAssetPreview({
    asset,
    version: asset.version,
  })
  const { setOpen, setModalProps, setComponent } = useModal()
  const hoverRef = useRef({ isHovered: false })

  const fileTypeDisplay = useMemo(() => {
    let display = 'N/A'

    if (!asset?.mime_type) {
      return display
    }

    let ext = getFilenameExt(asset.filename)?.toUpperCase()

    if (PRINT_ASSET_MIME_TYPES[ext]) {
      return ext
    }

    if (asset.mime_type.includes('text')) {
      return 'TXT'
    }

    Object.entries(PRINT_ASSET_MIME_TYPES).forEach(([key, value]) => {
      if (value === asset?.mime_type) {
        display = key
      }
    })

    return display
  }, [asset])

  const showPreviewModal = () => {
    setModalProps({
      imageSrc: src,
      width: '90vw',
      height: '90vh',
    })
    setComponent(ImagePreviewModal)
    setOpen(true)
  }

  useEffect(() => {
    if (
      cachedAsset.id !== asset?.id ||
      cachedAsset.version !== asset?.version
    ) {
      refetch()
      setCachedAsset(asset)
    }
  }, [asset])

  useEffect(() => {
    const lastSrc = src

    if (preview) {
      setSrc(window.URL.createObjectURL(preview))
    }

    return () => {
      if (lastSrc) {
        window.URL.revokeObjectURL(lastSrc)
      }
    }
  }, [preview])

  if (loading) {
    return (
      <Box sx={{ height: '76px', width: '76px', display: 'flex' }}>
        <CircularProgress sx={{ m: 'auto' }} />
      </Box>
    )
  }

  if (
    `${asset?.mime_type}`.includes('image') &&
    preview &&
    !`${asset?.mime_type}`.includes('tiff') &&
    !`${asset?.mime_type}`.includes('adobe')
  ) {
    return (
      <>
        <Box
          component="img"
          sx={{
            height: '76px',
            width: fluid ? '100%' : '76px',
          }}
          src={src}
          alt=""
          onMouseEnter={() => {
            if (hoverRef.current.isHovered) return

            hoverRef.current.isHovered = true

            // delay so that just passing over the element
            // doesn't open the modal
            setTimeout(() => {
              if (hoverRef.current.isHovered) {
                showPreviewModal()
                hoverRef.current.isHovered = false
              }
            }, 100)
          }}
          onMouseLeave={() => {
            if (hoverRef.current.isHovered) {
              hoverRef.current.isHovered = false
            }
          }}
        />
      </>
    )
  }

  return <FileTypeSquare>{fileTypeDisplay}</FileTypeSquare>
}

export default PreviewSquare
