import React from 'react'

import ReportDataList from 'components/reporting/DDReportDataList'

import PureDataListLayout from 'layouts/PureDataList'

function DDReportView() {
  return (
    <PureDataListLayout>
      <ReportDataList />
    </PureDataListLayout>
  )
}

export default DDReportView
