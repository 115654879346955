import { useState, useEffect } from 'react'
import useSWR from 'swr'

import fetcherPDF from 'utils/fetcherPDF'

const useOrderProcessingReport = (params) => {
  const [orderProcessingReportResponse, setOrderOrderProcessingReport] =
    useState({})

  const { data, isValidating } = useSWR(
    params ? `/orders/${params.orderId}/reports/${params.reportId}` : null,
    fetcherPDF,
    {
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    if (data?.error) {
      setOrderOrderProcessingReport({
        ...orderProcessingReportResponse,
        error: data.error,
      })
    } else {
      setOrderOrderProcessingReport(data)
    }
  }, [data])

  return {
    response: orderProcessingReportResponse ?? data ?? null,
    loading: isValidating,
    error: data?.error,
  }
}

export default useOrderProcessingReport
