import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import Sidebar from './Sidebar'
import ReportDataListUI from './ReportDataListUI'

import useDataListContext from 'hooks/context/useDataListContext'
import useReports from 'hooks/reporting/useReports'
import useReport from 'hooks/reporting/useReport'

function ReportDataList() {
  const location = useLocation()

  const [sortList, setSortList] = useState({})

  // sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { page, pageSize, setTotal, setTotalPages, setPageSize } =
    useDataListContext()

  const reportHookParams = useMemo(
    () => ({
      'paging[page]': page,
      'paging[page_size]': pageSize,
      ...sortList,
    }),
    [page, pageSize]
  )

  const { reports } = useReports({})
  const [activeReport, setActiveReport] = useState(location.state?.activeReport)
  const activeReportData = useMemo(() => {
    const foundReport = reports.find((report) => report.id === activeReport)
    const showDateRange =
      foundReport &&
      foundReport?.variables &&
      (foundReport.variables.includes('datetime_start') ||
        foundReport.variables.includes('datetime_end'))

    return {
      ...foundReport,
      showDateRange,
    }
  }, [reports, activeReport])
  const [activeDateRange, setActiveDateRange] = useState(
    location.state?.dateRange
  )
  const {
    items,
    loading,
    columns: visibleColumns,
    total,
    totalPages,
    pageSize: docPageSize,
    runReport,
    downloadReport,
  } = useReport(reportHookParams)

  const setReportingView = useCallback(
    (reportId, dateRange) => {
      setActiveReport(reportId)
      setActiveDateRange(dateRange)

      runReport(reportId, dateRange)
    },
    [setActiveReport, setActiveReport, runReport]
  )

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages) // assume total pages updates alongside total
      setPageSize(docPageSize, false)
    }
  }, [total, totalPages, docPageSize])

  useEffect(() => {
    runReport(activeReport, activeDateRange)
  }, [page, pageSize])

  useEffect(() => {
    if (location.state) {
      setReportingView(location.state?.activeReport, location.state?.dateRange)
    }
  }, [])

  return (
    <ReportDataListUI
      reportName={location?.state?.reportName}
      activeReport={activeReport}
      activeDateRange={activeDateRange}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
      sortList={sortList}
      setSortList={setSortList}
      downloadReport={downloadReport}
      total={total}
      items={items}
      loading={loading}
      visibleColumns={visibleColumns}
      sidebar={
        <Sidebar
          reports={reports}
          activeReport={activeReport}
          activeReportData={activeReportData}
          setActiveReport={setActiveReport}
          open={sidebarOpen}
          setOpen={setSidebarOpen}
          setReportingView={setReportingView}
        />
      }
    />
  )
}

export default ReportDataList
