import useSWR from 'swr'

import fetcher from 'utils/fetcher'
import axios from 'utils/axios'

import urlSerialize from 'helpers/browser/urlSerialize'

const usePullConfigurations = (params) => {
  const { data, isValidating, mutate } = useSWR(
    `/pull-configurations?${urlSerialize(params)}`,
    fetcher,
    { revalidateOnFocus: false }
  )

  const createPullConfiguration = async (values) => {
    const res = await axios.post('/pull-configurations', {
      ...values,
    })
    mutate()
    return res.data
  }

  const updatePullConfiguration = async ({ id, values }) => {
    const {
      headers: { etag },
    } = await axios.head(`/pull-configurations/${id}`)

    const res = await axios.patch(
      `/pull-configurations/${id}`,
      {
        ...values,
      },
      {
        headers: {
          'If-Match': etag,
        },
      }
    )
    mutate()
    return res.data
  }

  const getCsvExport = async () => {
    const res = await axios.get(
      `/pull-configurations?${urlSerialize({
        ...params,
        'paging[page]': 1,
        'paging[page_size]': data?.total || 1,
      })}`,
      {
        headers: {
          Accept: 'text/csv',
        },
      }
    )

    return res.data
  }

  return {
    pullConfigurations: data?._embedded?.pull_configurations ?? [],
    loading:
      (!data?._embedded?.pull_configurations && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    createPullConfiguration,
    updatePullConfiguration,
    getCsvExport,
    mutate,
  }
}

export default usePullConfigurations
