import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import DownloadsTemplate from 'components/common/Notification/templates/DownloadsTemplate'
import AddressAutocomplete from './AddressAutocomplete'
import RemovableItems from 'components/common/RemovableItems'

import useNotification from 'hooks/context/useNotification'
import useDocuments from 'hooks/useDocuments'
import useModal from 'hooks/context/useModal'

import { checkAddress } from 'utils/smartyStreets'

const ModalFormLabel = styled.span`
  font-weight: bold;
`

function DocumentAddressForm() {
  const {
    values: { name, addressalt, country, state, city, zip },
    handleChange,
    errors,
  } = useFormikContext()

  const sharedProps = {
    fullWidth: true,
    onChange: handleChange,
  }

  return (
    <Grid container spacing={4} sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <TextField
          {...sharedProps}
          id="name"
          name="name"
          label="Name"
          value={name}
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
      </Grid>
      <Grid item xs={6}>
        <AddressAutocomplete />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...sharedProps}
          id="addressalt"
          name="addressalt"
          label="Address Line 2"
          value={addressalt}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...sharedProps}
          id="city"
          name="city"
          label="City"
          value={city}
          error={Boolean(errors.city)}
          helperText={errors.city}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...sharedProps}
          id="state"
          name="state"
          label="State / Province"
          value={state}
          error={Boolean(errors.state)}
          helperText={errors.state}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...sharedProps}
          id="zip"
          name="zip"
          label="Zip / Postal Code"
          value={zip}
          error={Boolean(errors.zip)}
          helperText={errors.zip}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...sharedProps}
          id="country"
          name="country"
          label="Country"
          value={country}
          error={Boolean(errors.country)}
          helperText={errors.country}
        />
      </Grid>
    </Grid>
  )
}

function NotificationForm() {
  const {
    values: { sendTo, cc, bcc },
    handleChange,
    errors,
  } = useFormikContext()

  const sharedProps = {
    fullWidth: true,
    onChange: handleChange,
  }

  return (
    <Grid container spacing={4} sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <TextField
          {...sharedProps}
          id="sendTo"
          name="sendTo"
          label="Send to"
          value={sendTo}
          error={Boolean(errors.sendTo)}
          helperText={errors.sendTo}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...sharedProps}
          id="cc"
          name="cc"
          label="cc"
          value={cc}
          error={Boolean(errors.cc)}
          helperText={errors.cc}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...sharedProps}
          id="bcc"
          name="bcc"
          label="bcc"
          value={bcc}
          error={Boolean(errors.bcc)}
          helperText={errors.bcc}
        />
      </Grid>
    </Grid>
  )
}

function ResendDocumentForm({ items = [], title, Form, valuesKey }) {
  const { handleChange } = useFormikContext()

  const handleRemove = (index) => {
    const newItems = [...items]
    newItems.splice(index, 1)
    handleChange({ target: { name: valuesKey, value: newItems } })
  }

  if (items.length === 0) return <></>

  return (
    <Box sx={{ mb: 10 }}>
      <RemovableItems
        items={items}
        handleRemove={handleRemove}
        title={items.length > 5 ? `${items.length} ${title}` : title}
        max={5}
      />
      <Form />
    </Box>
  )
}

function ResendDocumentContent({ onClose }) {
  const {
    values: { documents, notifications },
    errors,
  } = useFormikContext()

  useEffect(() => {
    if (documents.length === 0 && notifications.length === 0) {
      onClose()
    }
  }, [documents, notifications])

  return (
    <>
      {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
      <ResendDocumentForm
        items={documents}
        title="Selected documents"
        Form={DocumentAddressForm}
        valuesKey="documents"
      />
      <ResendDocumentForm
        items={notifications}
        title="Selected notifications"
        Form={NotificationForm}
        valuesKey="notifications"
      />
    </>
  )
}

function ResendModal({ selectedItems = [], onClose, ...props }) {
  const formikRef = useRef(null)
  const { setComponent, setOpen, setError } = useNotification()
  const { reprintDocuments, resendNotifications } = useDocuments()
  const { setModalProps, setOpen: setModalOpen } = useModal()

  const addDisplay = (item) => {
    let display = item.record_type

    if (display === 'document') display = 'DOCUMENT'
    else if (display === 'notification') {
      display = item._embedded.notification?.type
    }

    return {
      ...item,
      display,
    }
  }

  const documents = selectedItems
    .filter((item) => item.record_type === 'document')
    .map(addDisplay)
  const notifications = selectedItems
    .filter((item) => item.record_type === 'notification')
    .map(addDisplay)

  const initialValues = {
    documents,
    notifications,
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    addressalt: '',
    country: 'USA',
    sendTo: '',
    cc: '',
    bcc: '',
  }

  const actionButtonProps = [
    {
      children: 'Resend',
      color: 'primary',
      variant: 'contained',
      type: 'submit',
      onClick: () => formikRef.current.submitForm(),
    },
    {
      children: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      onClick: onClose,
    },
  ]

  const handleSubmit = async (values, { setFieldError }) => {
    const generateMessage = () => {
      const { documents, notifications } = values
      let message = ''

      if (documents.length > 0) {
        message += `${documents.length} document${
          documents.length === 1 ? '' : 's'
        }`
      }

      if (documents.length > 0 && notifications.length > 0) {
        message += ' and '
      }

      if (notifications.length > 0) {
        message += `${notifications.length} notification${
          notifications.length === 1 ? '' : 's'
        } `
      }

      return `${message} have been successfully queued for resending.`
    }

    const getReprintData = () => {
      return {
        name: values.name,
        address: values.address,
        city: values.city,
        state: values.state,
        zip: values.zip,
        addressalt: values.addressalt,
        country: values.country,
      }
    }

    const getResendData = () => {
      return {
        address: values.sendTo,
        cc: values.cc,
        bcc: values.bcc,
      }
    }

    const generateModalContent = (addressValues) => {
      return (
        <Stack>
          <Typography>
            <ModalFormLabel>Street: </ModalFormLabel>
            {addressValues.address}
            {` ${addressValues.addressalt ?? ''}`}
          </Typography>
          <Typography>
            <ModalFormLabel>City: </ModalFormLabel>
            {addressValues.city}
          </Typography>
          <Typography>
            <ModalFormLabel>State / Province: </ModalFormLabel>
            {addressValues.state}
          </Typography>
          <Typography>
            <ModalFormLabel>Zip / Postal Code: </ModalFormLabel>
            {addressValues.zip}
          </Typography>
          <Typography>
            <ModalFormLabel>Country: </ModalFormLabel>
            {addressValues.country}
          </Typography>
        </Stack>
      )
    }

    const ResendNotification = (props) => (
      <DownloadsTemplate {...props}>{generateMessage()}</DownloadsTemplate>
    )

    try {
      const documentIds = values.documents.map((doc) => doc.id)
      const notificationIds = values.notifications.map(
        (notif) => notif._embedded.notification.id
      )

      const submitForm = async () => {
        try {
          await Promise.all([
            reprintDocuments(documentIds, getReprintData()),
            resendNotifications(notificationIds, getResendData()),
          ])
          setComponent(ResendNotification)
          setOpen(true)
        } catch (err) {
          setError(
            err.response?.data?.display_message ||
              err.message ||
              'An error has occurred'
          )
        }

        onClose()
      }

      // check address if reprinting documents
      if (documents.length > 0) {
        const res = await checkAddress({
          addressee: values.name,
          street: values.address,
          city: values.city,
          state: values.state,
          zipCode: values.zip,
          match: 'strict',
        })

        const smartyResult = res?.lookups?.[0]?.result?.[0]
        const { deliveryLine1, deliveryLine2 } = smartyResult ?? {}
        const { cityName, state, zipCode } = smartyResult?.components ?? {}

        const handleContinue = () => {
          if (smartyResult) {
            values.address = deliveryLine1
            values.addressalt = deliveryLine2
            values.city = cityName
            values.state = state
            values.zip = zipCode
          }

          setModalOpen(false)
          submitForm()
        }

        const modalProps = {
          title: 'The selected address could not be found.',
          size: 'sm',
          children: generateModalContent(values),
          footerButtonProps: [
            {
              children: <Box sx={{ fontSize: 12 }}>Continue anyways</Box>,
              color: 'primary',
              variant: 'contained',
              onClick: () => {
                handleContinue()
              },
            },
            {
              children: 'Cancel',
              color: 'primary',
              variant: 'outlined',
              onClick: () => setModalOpen(false),
            },
          ],
        }

        if (smartyResult) {
          modalProps.title = 'Is the following address correct?'
          modalProps.footerButtonProps[0].children = 'Continue'
          modalProps.children = generateModalContent({
            address: deliveryLine1,
            addressalt: deliveryLine2,
            city: cityName,
            state,
            zip: zipCode,
            country: 'USA',
          })
        }

        setModalProps(modalProps)
        setModalOpen(true)
      } else {
        await submitForm()
      }
    } catch (err) {
      setError(
        err.response?.data?.display_message ||
          err.message ||
          'An error has occurred'
      )
      onClose()
    }
  }

  const validate = async (values) => {
    const documentRequiredFields = [
      {
        value: 'name',
        display: 'Name',
      },
      {
        value: 'address',
        display: 'Address',
      },
      {
        value: 'city',
        display: 'City',
      },
      {
        value: 'state',
        display: 'State / Province',
      },
      {
        value: 'zip',
        display: 'Zip / Postal Code',
      },
      {
        value: 'country',
        display: 'Country',
      },
    ]
    const notificationRequiredFields = [{ value: 'sendTo', display: 'Send to' }]
    const errors = {}

    if (notifications.length > 0) {
      notificationRequiredFields.forEach((field) => {
        if (!values[field.value])
          errors[field.value] = `${field.display} is required`
      })
    }

    if (documents.length > 0) {
      documentRequiredFields.forEach((field) => {
        if (!values[field.value])
          errors[field.value] = `${field.display} is required`
      })
    }

    return errors
  }

  const validationObject = {
    name: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    addressalt: Yup.string(),
    country: Yup.string(),
    sendTo: Yup.string().email('Send to must be a valid email address'),
    cc: Yup.string(),
    bcc: Yup.string(),
  }

  const validationScema = Yup.object().shape(validationObject)

  return (
    <Modal
      {...props}
      footerButtonProps={actionButtonProps}
      onClose={onClose}
      width="1144px"
      height="888px"
      title="Resend"
      scrollable
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={validationScema}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ResendDocumentContent onClose={onClose} />
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default ResendModal
