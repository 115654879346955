import parse from 'date-fns/parse'
import format from 'date-fns/format'

const getGroupedLabels = ({ labels }) => {
  const MAX_GROUP_SIZE = 5
  const count = labels.length

  if (count <= MAX_GROUP_SIZE) {
    return labels
  }

  const groupSize = Math.ceil(count / MAX_GROUP_SIZE)
  const numOfGroups = Math.floor(count / groupSize)
  const displayLabels = []

  for (let i = 0; i < numOfGroups; i++) {
    const offset = i * groupSize
    const group = labels.slice(offset, offset + groupSize)

    const first = group[0]
    const last = group.slice(-1)[0]

    let dateString = first.split(' ')[0]
    const dateFirst = parse(dateString, 'yyyy-MM-dd', new Date())

    dateString = last.split(' ')[0]
    const dateLast = parse(dateString, 'yyyy-MM-dd', new Date())

    const months = [
      ...new Set([format(dateFirst, 'LLL'), format(dateLast, 'LLL')]),
    ]
    const days = [dateFirst.getDate(), dateLast.getDate()]

    const dayDisplay = days.join('-')
    const monthDisplay = months.join('/')

    displayLabels.push(`${dayDisplay} ${monthDisplay}`)
  }

  return displayLabels
}

export default getGroupedLabels
