import BackorderIcon from './Backorder'
import CreatedIcon from './Created'
import CompletedIcon from './Completed'
import CompletedIconWhite from './CompletedWhite'
import MailedIcon from './Mailed'
import MailedIconWhite from './MailedWhite'
import PendingApprovalIcon from './PendingApproval'
import PendingApprovalIconWhite from './PendingApprovalWhite'
import ProcessingIcon from './Processing'
import ProcessingIconWhite from './ProcessingWhite'
import ProductionIcon from './Production'
import ProductionIconWhite from './ProductionWhite'
import CancelledIcon from './Cancelled'
import CancelledIconWhite from './CancelledWhite'
import ErrorIcon from '@mui/icons-material/Error'

const components = {
  backorder: BackorderIcon,
  created: CreatedIcon,
  completed: CompletedIcon,
  completed_current: CompletedIconWhite,
  mailed: MailedIcon,
  mailed_current: MailedIconWhite,
  'partially mailed': MailedIcon,
  'partially mailed_current': MailedIconWhite,
  'pending approval': PendingApprovalIcon,
  'pending approval_current': PendingApprovalIconWhite,
  processing: ProcessingIcon,
  processing_current: ProcessingIconWhite,
  production: ProductionIcon,
  production_current: ProductionIconWhite,
  cancelled: CancelledIcon,
  cancelled_current: CancelledIconWhite,
  'on hold': ErrorIcon,
  'on hold_current': ErrorIcon,
}

export default components
