import React, { useState } from 'react'
import { Formik } from 'formik'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import CardForm from 'components/user_settings/CardForm'
import PasswordResetModal from 'components/user_settings/PasswordResetModal'

import useAuth from 'hooks/useAuth'
import useUser from 'hooks/users/useUser'
import useUsers from 'hooks/users/useUsers'
import useNotification from 'hooks/context/useNotification'

import removeMatchingValues from 'helpers/node/removeMatchingValues'

function UserSettings() {
  const { setError, setBasicNotification } = useNotification()
  const { user } = useAuth()
  const { user: userData, mutate, loading } = useUser(user.userId)
  const { updateUser } = useUsers()
  const [showModal, setShowModal] = useState(false)

  const initialValues = {
    display: userData.display || '',
    phone: userData.phone || '',
    username: userData.username || '',
    timezone: userData.timezone || '',
    email: userData.email || '',
    home_module: userData.home_module || '',
  }

  const handleSubmit = async (values) => {
    try {
      await updateUser({
        userId: user.userId,
        userData: removeMatchingValues(initialValues, values),
      })

      mutate()

      setBasicNotification('User settings updated successfully')
    } catch (err) {
      setError(
        err.response?.data?.display_message || 'Error updating user settings'
      )
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 8, display: 'flex' }}>
              {loading ? (
                <CircularProgress sx={{ m: 'auto' }} />
              ) : (
                <CardForm
                  initialValues={initialValues}
                  setShowModal={setShowModal}
                  user={user}
                />
              )}
            </Box>
          </form>
        )}
      </Formik>
      <PasswordResetModal open={showModal} setOpen={setShowModal} user={user} />
    </>
  )
}

export default UserSettings
