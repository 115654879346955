import React from 'react'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'

import ScrollableBox from 'components/styled/ScrollableBox'

function RemovableItem({ item, index, handleRemove }) {
  return (
    <Box sx={{ display: 'flex', height: '48px', width: '248px', mr: 7 }}>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'lightgray.main',
          borderRadius: '8px',
          display: 'flex',
          height: '100%',
          width: '200px',
          color: 'darkgray.main',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            my: 'auto',
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          title={item.template.name}
        >
          {item.template.name}
        </Box>
      </Box>
      <IconButton sx={{ my: 'auto' }} onClick={() => handleRemove(index)}>
        <CancelPresentationIcon color="primary" />
      </IconButton>
    </Box>
  )
}

function OrderedItems({ items, handleRemove, title, idKey = 'id' }) {
  return (
    <>
      {title && (
        <Typography variant="h2" fontWeight="medium" mb={6}>
          {title}
        </Typography>
      )}
      <ScrollableBox
        sx={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {items.map((item, index) => (
            <RemovableItem
              key={`resend-item-${item.id}`}
              item={item}
              index={index}
              handleRemove={handleRemove}
              idKey={idKey}
            />
          ))}
        </Box>
      </ScrollableBox>
    </>
  )
}

export default OrderedItems
