import { useState, useEffect } from 'react'
import useSWR from 'swr'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

const useLibrary = ({ libId }) => {
  const [dataLoading, setDataLoading] = useState(false)
  const [data, setData] = useState({})

  const {
    data: baseData,
    isValidating,
    mutate,
  } = useSWR(
    libId ? `/libraries/${libId}?embed=user|versions|document|note` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const updateLibraryItem = async ({
    id = libId,
    updateData,
    isForm = true,
  }) => {
    const errorMsg = 'Error updating library item'

    if (!updateData || !id) throw new Error()

    const formData = new FormData()

    if (isForm) {
      Object.entries(updateData).forEach(([key, value]) => {
        if (key === 'file' && !value) return

        // value can be falsey
        if (value !== undefined || value !== null) {
          // check for redundancies
          if (value !== data[key]) {
            formData.append(key, value)
          }
        }
      })
    }

    const res = await axios.patch(
      `/libraries/${id}`,
      isForm ? formData : updateData,
      {
        headers: {
          'Content-Type': isForm ? 'multipart/form-data' : undefined,
          'If-Match': data?.etag,
        },
      }
    )

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      await mutate()
    }
  }

  const addNewLibraryItemVersion = async ({
    id = libId,
    updateData,
    isForm = true,
  }) => {
    const errorMsg = 'Error updating library item'

    if (!updateData || !id) throw new Error()

    const formData = new FormData()

    if (isForm) {
      Object.entries(updateData).forEach(([key, value]) => {
        if (key === 'file' && !value) return

        // value can be falsey
        if (value !== undefined || value !== null) {
          // check for redundancies
          if (value !== data[key]) {
            formData.append(key, value)
          }
        }
      })
    }

    const res = await axios.post(
      `/libraries/${id}/versions`,
      isForm ? formData : updateData,
      {
        headers: {
          'Content-Type': isForm ? 'multipart/form-data' : undefined,
          'If-Match': data?.etag,
        },
      }
    )

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      await mutate()
    }
  }

  const updateLibraryItemVersion = async ({
    id = libId,
    version = 1,
    updateData,
  }) => {
    const errorMsg = 'Error updating library item'

    if (!updateData || !id || !version) throw new Error()

    const formData = new FormData()

    Object.entries(updateData).forEach(([key, value]) => {
      if (key === 'file' && !value) return

      // value can be falsey
      if (value !== undefined || value !== null) {
        // check for redundancies
        if (value !== data[key]) {
          formData.append(key, value)
        }
      }
    })

    const {
      headers: { etag },
    } = await axios.head(`/libraries/${id}/versions/${version}`)
    const res = await axios.patch(
      `/libraries/${id}/versions/${version}`,
      updateData,
      {
        headers: {
          'If-Match': etag,
        },
      }
    )

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      await mutate()
    }
  }

  const deleteLibraryItem = async ({ id = libId }) => {
    const errorMsg = 'Error deleting library item'

    if (!id) throw new Error(errorMsg)

    const res = await axios.delete(`/libraries/${id}`, {
      headers: {
        'If-Match': data?.etag,
      },
    })

    if (res.status !== 204) {
      throw new Error(errorMsg)
    }
  }

  const addLibraryItemNote = async ({ id = libId, note }) => {
    const errorMsg = 'Error adding note to library item'

    if (!note || !id) throw new Error()

    const res = await axios.post(`/libraries/${id}/notes`, { note })

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      await mutate()
    }
  }

  useEffect(() => {
    setDataLoading(true)
    axios
      .get(`/libraries/${libId}`)
      .then((res) => {
        const etag = res.headers.etag

        const versions = baseData?._embedded?.versions?.versions || []
        const currentVersion = versions.find(
          (version) =>
            version.version?.toString() ===
            baseData?._embedded?.versions?.default?.toString()
        )

        if (typeof currentVersion?.custom_data === 'string') {
          try {
            currentVersion.custom_data = JSON.parse(currentVersion.custom_data)
          } catch (err) {
            // pass
          }
        }

        const newData = {
          ...currentVersion,
          ...baseData,
          user: currentVersion?._embedded?.user,
          etag,
        }

        setData(newData)
      })
      .finally(() => {
        setDataLoading(false)
      })
  }, [baseData])

  // cache etag in data object, but do not render in UI
  const libraryItem = { ...data }
  delete libraryItem.etag

  return {
    libraryItem: libraryItem,
    loading: (!data && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
    updateLibraryItem,
    updateLibraryItemVersion,
    deleteLibraryItem,
    addNewLibraryItemVersion,
    addLibraryItemNote,
    dataLoading,
  }
}

export default useLibrary
