import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const useClientDataList = () => {
  const { data, isValidating } = useSWR('/flow/clients', fetcher, {
    revalidateOnFocus: false,
  })

  return {
    mpxContacts: data || [],
    contactsExist: Array.isArray(data) && Boolean(data?.length),
    loading: isValidating,
  }
}

export default useClientDataList
