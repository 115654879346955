import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const useDocumentInboundStatuses = () => {
  const { data, isValidating, mutate } = useSWR(
    '/document-inbound-statuses',
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    statuses: data?._embedded?.statuses ?? [],
    loading: (!data?._embedded?.statuses && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default useDocumentInboundStatuses
