import React, { useState, useEffect, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import DataListTable from 'components/common/DataList/Table'
import ExportToCSV from 'components/common/ExportToCSV'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import usePullConfigurations from 'hooks/bulk_mail/usePullConfigurations'
import useConfiguration from 'hooks/useConfiguration'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import trimString from 'helpers/node/trimString'
import PureDataList from 'layouts/PureDataList'
import AddIcon from '@mui/icons-material/Add'
import AddPullConfigModal from 'components/bulk_mail/AddPullConfigModal'
import BulkMailRow from 'components/common/DataList/Item/BulkMail'
import { DATALIST_DIMENSIONS } from 'utils/constants'
import isEmptyObject from 'helpers/node/isEmptyObject'

function ConfigurationsList() {
  const {
    instanceConfigurations: { bulk_mail_data_fields },
  } = useConfiguration()
  const { page, pageSize, setTotal, setTotalPages, setPage } =
    useDataListContext()

  const bulkMailDataFields = bulk_mail_data_fields || []

  const defaultDataField = useMemo(() => {
    return bulkMailDataFields?.[0] || ''
  }, [bulkMailDataFields])

  const [filters, setFilters] = useState({})
  const [sortList, setSortList] = useState([])
  const [activePullConfig, setActivePullConfig] = useState(null)
  const [showAddModal, setShowAddModal] = useState(false)

  const hookParams = useMemo(() => {
    let searchValue = trimString(filters.search) || ''

    if (searchValue.toLowerCase() === 'any') {
      searchValue = ''
    }

    return {
      'filter[field]':
        searchValue && searchValue !== '' && filters.searchFields
          ? filters.searchFields
          : undefined,
      'filter[term]':
        searchValue && searchValue !== '' && filters.searchFields
          ? searchValue
          : undefined,
      'paging[page]': !searchValue ? page : undefined,
      'paging[page_size]': pageSize,
      ...sortList,
    }
  }, [filters, page, pageSize, sortList])

  const {
    pullConfigurations,
    total,
    totalPages,
    loading,
    createPullConfiguration,
    updatePullConfiguration,
    getCsvExport,
  } = usePullConfigurations(hookParams)

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages)
    }
  }, [total, totalPages, pageSize])

  useEffect(() => {
    if (isEmptyObject(filters) && defaultDataField) {
      setFilters({
        searchFields: 'data_field',
        search: defaultDataField,
      })
    }
  }, [defaultDataField])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: pullConfigurations,
    presetName: 'pullConfigurations',
    setSortList,
  })

  const handleFilterChange = (e) => {
    setFilters({
      searchFields: 'data_field',
      search: e.target.value,
    })
    setPage(1)
  }

  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          sx={{
            height: '72px',
            background: 'white',
            display: 'flex',
            px: 8,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              my: 'auto',
            }}
          >
            <Typography variant="h1" sx={{ pt: 3 }}>
              Pull Configurations
            </Typography>
            <Button
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              onClick={() => setShowAddModal(true)}
            >
              Create New Pull Configuration
            </Button>
          </Box>
        </Box>
        <Box
          fullWidth
          sx={{
            height: '1px',
            bgcolor: 'darkblue.main',
            opacity: 0.12,
          }}
        />
        <Box sx={{ p: 6 }}>
          <AddPullConfigModal
            open={showAddModal}
            setOpen={setShowAddModal}
            createPullConfiguration={createPullConfiguration}
            updatePullConfiguration={updatePullConfiguration}
            activePullConfig={activePullConfig}
            setActivePullConfig={setActivePullConfig}
          />

          <Grid
            container
            columns={12}
            spacing={4}
            sx={{
              '& > *': { display: 'flex' },
              '& > * > *': { my: 'auto' },
              mb: 6,
            }}
          >
            <Grid item xs={2}>
              <TextField
                fullWidth
                select
                size="small"
                label="Data Field"
                name="data_field"
                onChange={handleFilterChange}
                value={filters.search || ''}
              >
                {bulkMailDataFields.map((field) => (
                  <MenuItem key={field} value={field}>
                    {field}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Card sx={{ boxShadow: 2 }}>
            <DataListTable
              dataList={dataList}
              columns={columns}
              getDataDisplay={dataListProps.getDataDisplay}
              RowComponent={BulkMailRow}
              rowKey="id"
              loading={loading}
              sortList={sortList}
              setSortList={setSortList}
              total={total}
              height={DATALIST_DIMENSIONS.TABBED_HEIGHT}
              visibleColumns={[
                'name',
                'data_field',
                'value_list',
                'datetime_created',
                'datetime_modified',
                'is_active',
              ]}
              extraColumns={
                <>
                  <TableCell sx={{ backgroundColor: 'white' }}></TableCell>
                </>
              }
              rowProps={{
                onDetailsClick: (rowData) => {
                  setActivePullConfig(rowData)
                  setShowAddModal(true)
                },
              }}
            />
          </Card>
          <ExportToCSV
            getCsvExport={getCsvExport}
            filename={`CurrentPulls.${Date.now()}.csv`}
            hide={true || loading || dataList.length <= 0}
          />
        </Box>
      </Box>
    </>
  )
}

function ConfigurationsListWrapper() {
  return (
    <PureDataList>
      <ConfigurationsList />
    </PureDataList>
  )
}

export default ConfigurationsListWrapper
