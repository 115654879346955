import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import Modal from 'components/common/Modal'

import useSFTPUserSchedules from 'hooks/sftp/useSFTPUserSchedules'

function ModalContent({ username }) {
  const { userSchedules, loading } = useSFTPUserSchedules({ username })

  const isEmpty = !userSchedules?.length

  return (
    <Stack>
      {loading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress sx={{ mx: 'auto' }} />
        </Box>
      ) : isEmpty ? (
        <Typography>No schedules exist for this user.</Typography>
      ) : (
        <Grid container rowSpacing={1}>
          <Grid item xs={9}>
            <Typography>Program</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Scheduling</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {userSchedules?.map((schedule) => (
            <>
              <Grid item xs={9}>
                <Typography>{schedule.program_name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{schedule.schedule}</Typography>
              </Grid>
            </>
          ))}
        </Grid>
      )}
    </Stack>
  )
}

function SchedulesModal({ sftpUser, open, setOpen }) {
  const modalProps = {
    open,
    setOpen,
    title: `Schedule List for ${sftpUser?.username}`,
    size: 'md',
    footerButtonProps: [
      {
        children: 'Close',
        onClick: () => setOpen(false),
        color: 'primary',
        variant: 'contained',
      },
    ],
  }

  return (
    <Modal {...modalProps}>
      <ModalContent username={sftpUser?.username} />
    </Modal>
  )
}

export default SchedulesModal
