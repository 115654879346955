import React, { useEffect, useState, useMemo } from 'react'
import get from 'lodash.get'
import set from 'lodash.set'

import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { useCallback } from 'react'

const getLeafNodes = (option) => {
  if (!option?.items?.length) return []

  return option.items
    .flatMap((item) => [item, ...getLeafNodes(item)])
    .filter((item) => item.value)
}

const getChildIds = (option) => {
  if (!option?.items?.length) return []

  return option.items.flatMap((item) => [item.id, ...getChildIds(item)])
}

function TreeNodeContent({ option, checked, handleCheck }) {
  return (
    <Box
      onClick={(e) => {
        handleCheck(!checked[option.id])
        e.stopPropagation()
      }}
    >
      <Checkbox
        color={checked[option.id] === true ? 'green' : undefined}
        checked={checked[option.id] === true || false}
        indeterminate={checked[option.id] === 'indeterminate'}
      />
      {option.label.toString()}
    </Box>
  )
}

const updateChecked = ({ option, checked }) => {
  if (!option) return

  const leafNodes = getLeafNodes(option)
  const checkedCount = leafNodes.filter((node) => checked[node.id]).length

  if (checkedCount === leafNodes.length) {
    checked[option.id] = true
  } else if (checkedCount > 0) {
    checked[option.id] = 'indeterminate'
  } else {
    checked[option.id] = false
  }

  if (option.parent) {
    updateChecked({ option: option.parent, checked })
  }
}

function TreeNode({ option, checked, setChecked }) {
  const childIds = useMemo(() => {
    return getChildIds(option)
  }, [option])

  const handleCheck = (val) => {
    const newChecked = { ...checked, [option.id]: val }

    childIds.forEach((id) => {
      newChecked[id] = val
    })

    setChecked(newChecked)
  }

  return (
    <TreeItem
      label={
        <TreeNodeContent
          option={option}
          checked={checked}
          handleCheck={handleCheck}
        />
      }
      nodeId={`${option.id}`}
    >
      {option.items.map((item) => (
        <TreeNode
          key={item.id}
          option={item}
          checked={checked}
          setChecked={setChecked}
        />
      ))}
    </TreeItem>
  )
}

function MultiselectTree({
  defaultExpanded,
  options,
  values,
  onChange,
  title,
  name,
  error,
}) {
  const [tree, setTree] = useState(null)
  const [defaultExpandedIds, setDefaultExpandedIds] = useState([])
  const [checked, setChecked] = useState({})
  const handleChecked = useCallback(
    (checkedOptions) => {
      setChecked(checkedOptions)

      const newValues = {}
      getLeafNodes(tree.root)
        .filter((node) => checkedOptions[node.id])
        .forEach((node) => {
          let valuesArr = get(newValues, node.parentKey)

          if (!valuesArr) {
            set(newValues, node.parentKey, [])
            valuesArr = get(newValues, node.parentKey)
          }

          valuesArr.push(node.value)
        })

      onChange(newValues)
    },
    [tree, values, onChange, getLeafNodes]
  )

  const renderTree = useMemo(() => {
    if (tree)
      return (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ flexGrow: 1, overflowY: 'auto' }}
          defaultExpanded={defaultExpandedIds || []}
        >
          <TreeNode
            nodeId="0"
            option={tree.root}
            checked={checked}
            setChecked={handleChecked}
          />
        </TreeView>
      )
  }, [tree, defaultExpandedIds, checked, handleChecked])

  useEffect(() => {
    let counter = 1
    let expandedIds = ['0']
    const root = { items: options, label: name, id: '0' }

    const abstractOptions = (items, parent) => {
      items.forEach((item) => {
        item.id = counter
        item.parent = parent
        counter++

        if (item.defaultExpanded) {
          expandedIds.push(item.id.toString())
        }

        if (!Array.isArray(item.items)) {
          item.items = Object.values(item.items || {})
        }

        abstractOptions(item.items, item)
      })
    }

    abstractOptions(root.items, root)

    setTree({ root })
    setDefaultExpandedIds(expandedIds)
  }, [options])

  useEffect(() => {
    const newChecked = {}

    if (tree?.root) {
      getLeafNodes(tree.root)
        .filter((node) => {
          return get(values, node.parentKey)?.includes(node.value)
        })
        .forEach((node) => {
          newChecked[node.id] = true
          updateChecked({ option: node.parent, checked: newChecked })
        })

      setChecked(newChecked)
    }
  }, [values, tree])

  return (
    <>
      <Typography
        fontWeight={600}
        color={error ? 'error.main' : 'primary.main'}
        variant="h2"
        sx={{ mb: 2 }}
      >
        {title}
      </Typography>{' '}
      <Box>
        {renderTree}
        {error && <Typography color="error.main">{error}</Typography>}
      </Box>
    </>
  )
}

export default MultiselectTree
