import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

function FixedButtons(props) {
  const { displayColumns, fixedButtonProps, hide: fullHide } = props

  return (
    <Box
      sx={{
        display: 'flex',
        visibility: displayColumns && 'hidden',
        '& > .MuiButton-root': {
          mr: 2,
        },
      }}
    >
      {fixedButtonProps.map(({ onClick, sx, hide, ...rest }, buttonIndex) => (
        <Button
          sx={{
            ...sx,
            visibility: hide || fullHide ? 'hidden' : undefined,
          }}
          key={`fixed-button-${buttonIndex}`}
          {...rest}
          disabled={rest?.disabled ? rest.disabled(props) : false}
          onClick={(e) => {
            onClick(props)
            e.stopPropagation()
          }}
        />
      ))}
    </Box>
  )
}

export default FixedButtons
