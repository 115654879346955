import React from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/styles/useTheme'

import FullBox from 'components/styled/FullBox'

function SubHeaderSubLayout({ children, title = 'Search' }) {
  const theme = useTheme()

  return (
    <FullBox>
      <Box
        sx={{
          height: '72px',
          background: 'white',
          display: 'flex',
        }}
      >
        <Typography variant="h1" sx={{ marginLeft: 8, marginY: '28px' }}>
          {title}
        </Typography>
      </Box>
      <Box
        fullWidth
        sx={{
          height: '1px',
          bgcolor: 'darkblue.main',
          marginBottom: theme.rh(112),
          opacity: 0.12,
        }}
      />
      <Container>{children}</Container>
    </FullBox>
  )
}

export default SubHeaderSubLayout
