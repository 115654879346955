import React from 'react'

import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'

function CheckboxWithDropdown({
  options,
  handleCheck,
  checked,
  handleItemSelect,
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Select
        onChange={handleItemSelect}
        value={''}
        MenuProps={{
          PaperProps: {
            sx: { boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 88,
          },
        }}
        sx={{
          position: 'absolute',
          '&.MuiSelect-root': {
            width: '56px',
            height: '44px',
            transform: 'translateX(-4px)',
          },
        }}
      >
        {options &&
          options.map((option) => (
            <MenuItem
              key={`checkbox-dropdown-${option.value}`}
              value={option.value}
            >
              {option.display}
            </MenuItem>
          ))}
      </Select>
      <Checkbox
        edge="start"
        tabIndex={-1}
        disableRipple
        checked={checked}
        onChange={handleCheck}
      />
    </Box>
  )
}

export default CheckboxWithDropdown
