import React, { useState, useCallback, useMemo } from 'react'

import Autocomplete from './Autocomplete'

import useAuth from 'hooks/useAuth'
import useNotification from 'hooks/context/useNotification'

function InstanceSelect() {
  const {
    logIn,
    user: { instanceId, username, userInstances },
  } = useAuth()
  const { setError } = useNotification()
  const [searchValue, setSearchValue] = useState('')

  const handleInstanceSelect = useCallback(
    async (selectedInstance) => {
      if (selectedInstance && selectedInstance.key !== instanceId) {
        try {
          await logIn({
            username,
            instanceId,
            impersonate: false,
            sso: false,
            newInstance: selectedInstance.key,
          })
          window.location.href = '/main/dashboard'
        } catch (err) {
          setError(
            err.response?.data?.display_message ||
              err.message ||
              'Unable to switch to instance'
          )
        }
      }
    },
    [instanceId]
  )

  const handleAutocompleteFilter = (options) => {
    const filtered = options.filter((option) => {
      return (
        option.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        option.key.toLowerCase().includes(searchValue.toLowerCase())
      )
    })
    return filtered
  }

  const currentInstance = useMemo(() => {
    return userInstances?.find((instance) => instance.key === instanceId) || {}
  }, [userInstances, instanceId])

  if (!Array.isArray(userInstances) || userInstances.length < 2) {
    return <></>
  }

  return (
    <Autocomplete
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedOption={currentInstance}
      setSelectedOption={handleInstanceSelect}
      options={userInstances}
      handleFilter={handleAutocompleteFilter}
      textFieldProps={{
        size: 'small',
        label: 'Instance',
        onFocus: () => setSearchValue(''),
      }}
      freeSolo={false}
      clearOnBlur
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.key === value.key}
    />
  )
}

export default InstanceSelect
