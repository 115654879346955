import React from 'react'
import PropTypes from 'prop-types'

import MuiButton from '@mui/material/Button'
import Box from '@mui/material/Box'

import dateRangePresets from './dateRangePresets'

function DateRangePickerButton({ presetIndex, dateRange, setDateRange }) {
  const [startDate, endDate] = dateRange
  const { preset, label } = dateRangePresets[presetIndex]
  const isActive =
    ((preset[0] === startDate || preset[0] - startDate === 0) &&
      (preset[1] === endDate || preset[1] - endDate === 0)) ||
    undefined

  const handleClick = () => {
    if (isActive) setDateRange([undefined, undefined])
    else setDateRange(preset)
  }

  return (
    <MuiButton
      sx={{
        height: '100%',
        width: '100%',
        borderBottom: '1px solid',
        borderColor: 'background.main',
        borderRadius: 0,
        backgroundColor: isActive && 'background.default',
        fontWeight: isActive && 'medium',
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          width: '100%',
          textAlign: 'left',
          marginLeft: '16px',
          fontSize: '12px',
          color: 'text.main',
        }}
      >
        {label}
      </Box>
    </MuiButton>
  )
}

DateRangePickerButton.propTypes = {
  presetIndex: PropTypes.number,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  setDateRange: PropTypes.func,
}

export default DateRangePickerButton
