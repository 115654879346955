import axios from 'utils/axios'

const useOrderUpdate = (orderId) => {
  const getEtag = async () => {
    const {
      headers: { etag },
    } = await axios.get(`/orders/${orderId}`)
    return etag
  }

  const updateOrderStatus = async (updatedData) => {
    const etag = await getEtag()
    const res = await axios.patch(`/orders/${orderId}`, updatedData, {
      headers: {
        'If-Match': etag,
      },
    })

    if (res.status !== 200) {
      throw new Error('Error updating inbound item')
    }

    return res.data
  }
  return { updateOrderStatus }
}

export default useOrderUpdate
