import React, { useEffect, useState } from 'react'

import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// local components
import ItemCheckBox from './components/ItemCheckbox'

import useDataListContext from 'hooks/context/useDataListContext'

function DocumentApprovalPreviewItem({
  index,
  rowData,
  columns,
  getDataDisplay,
  multiSelect,
  statusColName,
  sharedSx,
}) {
  const { activeIndex, setActiveIndex } = useDataListContext()
  const [checked, setChecked] = useState(false)

  const active = activeIndex === index
  const checkboxColor = 'success'
  const darken = active || checked

  const getBorderColor = () => {
    if (active || checked) return 'green.main'

    return 'background.paper'
  }

  const setActive = () => {
    setActiveIndex(index)
  }

  function CheckBox() {
    const { allChecked, checkedCount } = useDataListContext()

    useEffect(() => {
      if (allChecked === true) setChecked(true)
      if (checkedCount === 0) setChecked(false)
    }, [allChecked, checkedCount])

    return (
      <>
        {multiSelect && (
          <ItemCheckBox
            index={index}
            handleItemCheck={setChecked}
            color={checkboxColor}
          />
        )}
      </>
    )
  }

  return (
    <Box
      onClick={setActive}
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderBottomColor: 'lightgray.main',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        bgcolor: darken && 'hover.main',
        color: 'darkgray.main',
        cursor: 'pointer',
        display: 'flex',
        minWidth: '232px',
        flexDirection: 'column',
        '&:hover': {
          borderLeftColor: !active && !checked && 'hover.main',
          bgcolor: !active && !checked && 'hover.main',
        },
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          overflow: 'hidden',
          justifyContent: 'space-around',
          my: 'auto',
          mx: 0,
          width: '100%',
        }}
      >
        {multiSelect && (
          <CheckBox
            index={index}
            handleItemCheck={setChecked}
            color={checkboxColor}
          />
        )}
        <Box sx={{ display: 'flex', width: '150px' }}>
          <Stack sx={{ width: '100%' }}>
            <Typography sx={{ textDecoration: 'underline' }}>
              ID {rowData.id}
            </Typography>
            <Typography
              title={getDataDisplay(rowData, 'recipient')}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {getDataDisplay(rowData, 'recipient') ?? 'N/A'}
            </Typography>
          </Stack>
        </Box>
      </ListItem>
    </Box>
  )
}

export default DocumentApprovalPreviewItem
