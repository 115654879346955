import React from 'react'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

function ReportingCell({ row, colId, getDataDisplay }) {
  return <TableCell>{getDataDisplay(row, colId)}</TableCell>
}

function ReportingRow({ row, rowKey, visibleColumns = [], getDataDisplay }) {
  return (
    <>
      <TableRow key={row[rowKey]}>
        {visibleColumns.map((col) => (
          <ReportingCell
            key={`${row[rowKey]}-${col}`}
            row={row}
            getDataDisplay={getDataDisplay}
            colId={col}
          />
        ))}
      </TableRow>
    </>
  )
}

export default ReportingRow
