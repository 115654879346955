import React, { useEffect, useMemo } from 'react'

import { DATALIST_DIMENSIONS } from 'utils/constants'

import Box from '@mui/material/Box'

import ExpandedList from 'components/common/ExpandedList'
import ScrollableBox from 'components/styled/ScrollableBox'
import PDFViewer from 'components/common/PDFViewer'
import DocumentApprovalExpandedList from 'components/common/ExpandedList/DocumentApproval'

import useDataListContext from 'hooks/context/useDataListContext'
import {
  useLetterDocumentPdf,
  useLetterDocument,
} from 'hooks/document_approval'

function DocumentApprovalPreview({ dataList, dataListProps, columns }) {
  const { activeIndex } = useDataListContext()

  const activeLetter = useMemo(
    () => dataList?.[activeIndex],
    [dataList, activeIndex]
  )

  const { document: activeDocument } = useLetterDocument(
    activeLetter?.id,
    activeLetter?.document_id
  )

  const { pdf, fetchPdf } = useLetterDocumentPdf()

  useEffect(() => {
    fetchPdf({
      letterId: activeLetter?.id,
      documentId: activeLetter?.document_id,
    })
  }, [activeLetter])

  return (
    <ScrollableBox
      sx={{
        px: 10,
        py: 6,
        height: DATALIST_DIMENSIONS.HEIGHT,
        overflow: 'auto',
      }}
    >
      <ExpandedList
        expanded={true}
        columns={columns}
        getDataDisplay={dataListProps.getDataDisplay}
        rowData={activeLetter ?? {}}
        visibleColumns={[]}
        collapseDetails
        ExpandedListComponent={DocumentApprovalExpandedList}
      />
      {pdf && (
        <Box
          sx={{
            height: `calc(${DATALIST_DIMENSIONS.HEIGHT} - 108px)`,
          }}
        >
          <PDFViewer
            url={pdf}
            fileName={activeDocument?.filename ?? 'preview.pdf'}
            hideLoader
          />
        </Box>
      )}
    </ScrollableBox>
  )
}

export default DocumentApprovalPreview
