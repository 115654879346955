import { useState } from 'react'

import axios from 'utils/axios'

const useLibraryPreview = ({ libraryItem, version }) => {
  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(false)

  const getLibraryItemPreview = async ({
    libItem = libraryItem,
    versionNum = version,
  }) => {
    if (libItem && libItem.mime_type) {
      setLoading(true)
      try {
        const res = await axios.get(
          `/libraries/${libItem.library_id}/versions/${versionNum}`,
          {
            responseType: 'blob',
            headers: { Accept: libItem.mime_type },
          }
        )
        setLoading(false)
        return res.data
      } catch (err) {
        setLoading(false)
        return null
      }
    } else {
      setLoading(false)
      return null
    }
  }

  const refetch = async () => {
    setPreview(
      await getLibraryItemPreview({ libItem: libraryItem, versionNum: version })
    )
  }

  return {
    preview,
    refetch,
    getLibraryItemPreview,
    loading,
  }
}

export default useLibraryPreview
