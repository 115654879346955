import React from 'react'

import { NOTIFICATION_TIMEOUT } from 'utils/constants'

const withNotification = (NotificationComponent) => {
  return (props) => (
    <NotificationComponent timeout={NOTIFICATION_TIMEOUT} {...props} />
  )
}

export default withNotification
