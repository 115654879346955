import { ReactComponent as CompletedIcon } from 'svgs/statuses/completed.svg'
import { ReactComponent as BackorderIcon } from 'svgs/statuses/backorder.svg'
import { ReactComponent as CreatedIcon } from 'svgs/statuses/created.svg'
import { ReactComponent as MailedIcon } from 'svgs/statuses/mailed.svg'
import { ReactComponent as PendingApprovalIcon } from 'svgs/statuses/pending_approval.svg'
import { ReactComponent as ProcessingIcon } from 'svgs/statuses/processing.svg'
import { ReactComponent as ProductionIcon } from 'svgs/statuses/production.svg'
import ErrorIcon from '@mui/icons-material/Error'
import CancelledIcon from 'svgs/statuses/Cancelled'

function statusToIcon(status) {
  switch (status) {
    case 'completed':
      return CompletedIcon

    case 'backorder':
      return BackorderIcon

    case 'created':
      return CreatedIcon

    case 'processing':
      return ProcessingIcon

    case 'production':
      return ProductionIcon

    case 'pending approval':
      return PendingApprovalIcon

    case 'mailed':
    case 'partially mailed':
      return MailedIcon

    case 'on hold':
      return ErrorIcon

    case 'cancelled':
      return CancelledIcon

    default:
      return null
  }
}

export default statusToIcon
