import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import MuiTextField from '@mui/material/TextField'

import Modal from 'components/common/DateRangePicker/Modal'
import formatDateRange from 'helpers/datetime/formatDateRange'

const TextField = styled(MuiTextField)`
  & .MuiInputBase-input {
    cursor: pointer;
  }
`

function DateRangePicker({
  value,
  setValue,
  dateFormat,
  buttonConfig,
  modalProps,
  single = false,
  ...textFieldProps
}) {
  const [anchor, setAnchor] = useState(null)
  const [open, setOpen] = useState(false)
  const [displayValue, setDisplayValue] = useState('')

  const handleClick = (e) => {
    setAnchor(e.currentTarget)
    setOpen(true)
  }

  useEffect(() => {
    if (open && !displayValue) {
      setDisplayValue(' ')
    } else if (!open && displayValue === ' ') {
      setDisplayValue('')
    }
  }, [open])

  useEffect(() => {
    setDisplayValue(formatDateRange({ dateRange: value, ...dateFormat }))

    const [startDate, endDate] = value || []

    if (endDate && !startDate) {
      setValue([endDate, undefined])
    } else if ((startDate || endDate) && startDate === endDate) {
      setValue([startDate, undefined])
    }
  }, [value])

  return (
    <>
      <TextField
        fullWidth
        onClick={handleClick}
        value={displayValue}
        inputProps={{
          readOnly: true,
        }}
        {...textFieldProps}
      />
      <Modal
        open={open}
        setOpen={setOpen}
        value={value}
        setValue={setValue}
        buttonConfig={buttonConfig}
        anchor={anchor}
        single={single}
        {...modalProps}
      />
    </>
  )
}

DateRangePicker.propTypes = {
  setValue: PropTypes.func,
  modalProps: PropTypes.object,
  dateFormat: PropTypes.shape({
    dateFormat: PropTypes.string,
    delimiter: PropTypes.string,
  }),
  textFieldProps: PropTypes.object,
  buttonConfig: PropTypes.arrayOf(PropTypes.number),
}

export default DateRangePicker
