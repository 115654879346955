import React from 'react'
import { Formik } from 'formik'

import OrderNotesDrawer from './Order'

function NotesDrawer({
  NotesDrawerComponent = OrderNotesDrawer,
  handleSubmit,
  ...props
}) {
  return (
    <Formik
      initialValues={{
        note: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <NotesDrawerComponent {...props} />
        </form>
      )}
    </Formik>
  )
}

export default NotesDrawer
