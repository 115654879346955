import React, { useState, useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'

import Autocomplete from 'components/common/Autocomplete'

import useSmartyStreetsAutocomplete from 'hooks/address_validation/useSmartyStreetsAutocomplete'

function AddressAutocomplete() {
  const {
    values: { address },
    handleChange,
  } = useFormikContext()
  const [selectedOption, setSelectedOption] = useState(undefined)
  const [localTimeout, setLocalTimeout] = useState(null)
  const [fullAddress, setFullAddress] = useState(address)

  const search = useMemo(() => {
    if (selectedOption || localTimeout) return null
    return fullAddress
  }, [selectedOption, fullAddress, localTimeout])

  const { suggestions, loading } = useSmartyStreetsAutocomplete(search)

  const getSuggestionDisplay = ({ entries, ...suggestion }) => {
    return Object.values(suggestion)
      .filter((value) => value)
      .join(', ')
  }

  const handleOptionSelect = (selectedAddress) => {
    if (selectedAddress) {
      handleChange({
        target: { name: 'addressalt', value: selectedAddress.secondary },
      })
      handleChange({
        target: { name: 'city', value: selectedAddress.city },
      })
      handleChange({
        target: { name: 'state', value: selectedAddress.state },
      })
      handleChange({
        target: { name: 'zip', value: selectedAddress.zipcode },
      })
      handleChange({
        target: { name: 'country', value: 'USA' },
      })
    }

    setSelectedOption(selectedAddress)
  }

  useEffect(() => {
    const generateTimeout = () =>
      setTimeout(() => {
        setLocalTimeout(null)
        setFullAddress(address)
      }, 500)

    const timeout = generateTimeout()

    if (localTimeout) {
      clearTimeout(localTimeout)
    }

    setLocalTimeout(timeout)
    if (selectedOption?.street_line !== address) setSelectedOption(undefined)
  }, [address])

  return (
    <Autocomplete
      searchValue={address}
      setSearchValue={(value) =>
        handleChange({ target: { name: 'address', value } })
      }
      selectedOption={selectedOption}
      setSelectedOption={handleOptionSelect}
      options={suggestions}
      renderOption={(props, option) => (
        <li {...props} key={`${option.street_line} ${option.state}`}>
          {getSuggestionDisplay(option)}
        </li>
      )}
      getOptionLabel={(option) => option.street_line}
      filterOptions={undefined}
      placeholder="Address"
      textFieldProps={{
        delay: 200,
        color: 'primary',
      }}
      loading={Boolean(localTimeout) || loading}
    />
  )
}

export default AddressAutocomplete
