import React from 'react'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

function FieldSeperator({ display }) {
  return (
    <>
      <Typography variant="h1" mb={2}>
        {display}
      </Typography>
      <Divider />
    </>
  )
}

export default FieldSeperator
