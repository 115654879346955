import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'

import XIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

import useDashboardContext from 'hooks/context/useDashboardContext'
import useTimeout from 'hooks/useTimeout'

function HideWidgetPopover({ onConfirm }) {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <Tooltip title="Hide widget from dashboard?">
        <XIcon
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            position: 'absolute',
            top: 1,
            right: 1,
            fontSize: '12px',
            color: 'darkgray.light',
          }}
        />
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            boxShadow: 3,
          },
        }}
      >
        <Box sx={{ padding: 1, display: 'flex' }}>
          <Typography
            color="primary"
            textAlign="center"
            lineHeight="100%"
            fontWeight="bold"
            fontSize="12px"
            sx={{ my: 'auto' }}
          >
            Hide widget?
          </Typography>
          <IconButton
            size="small"
            color="success"
            title="Confirm"
            onClick={onConfirm}
          >
            <CheckIcon fontSize="12px" />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            title="Cancel"
            onClick={() => setAnchorEl(null)}
          >
            <XIcon fontSize="12px" />
          </IconButton>
        </Box>
      </Popover>
    </>
  )
}

function ItemContainerContent({
  loading,
  href,
  title,
  subTitle,
  titleAction,
  disablePadding,
  emptyState,
  children,
  widgetKey,
  hideWidget,
}) {
  let content

  if (loading) {
    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <Box sx={{ m: 'auto' }}>
          <CircularProgress />
        </Box>
      </Box>
    )
  } else {
    content = (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography
              component={href ? Link : undefined}
              to={href}
              variant="h2"
              fontWeight="600"
              color="primary.main"
              fontSize={16}
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: href ? 'underline' : undefined,
                },
              }}
            >
              {title}
            </Typography>
            {Boolean(subTitle) && (
              <Typography fontSize={10} color="darkgray.main">
                {subTitle}
              </Typography>
            )}
          </Box>
          {titleAction}
        </Box>
        <Box sx={{ p: disablePadding ? 0 : 4 }}>
          {emptyState ? (
            <Box sx={{ display: 'flex', height: '100%' }}>
              <Typography m="auto">{emptyState}</Typography>
            </Box>
          ) : (
            children
          )}
        </Box>
      </>
    )
  }

  return (
    <>
      {content}
      {widgetKey && (
        <HideWidgetPopover onConfirm={() => hideWidget(widgetKey)} />
      )}
    </>
  )
}

function ItemContainer({
  widgetKey,
  title,
  subTitle,
  titleAction,
  children,
  disablePadding,
  loading = false,
  href,
  emptyState,
}) {
  const [timedOut, setTimedOut] = useState(false)
  const { hideWidget } = useDashboardContext()

  const handleTimeout = useCallback(() => {
    if (loading && !timedOut) {
      setTimedOut(true)
    }
  }, [timedOut, loading])

  useTimeout(handleTimeout, 1000 * 30)

  useEffect(() => {
    if (!loading && timedOut) {
      setTimedOut(false)
    }
  }, [loading, timedOut])

  return (
    <>
      <Card
        sx={{
          px: 6,
          py: 4,
          boxShadow: 2,
          height: '304px',
          width: '470px',
          userSelect: 'none',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <ItemContainerContent
          loading={loading && !timedOut}
          href={href}
          title={title}
          subTitle={subTitle}
          titleAction={titleAction}
          disablePadding={disablePadding}
          emptyState={emptyState || (timedOut && 'Unable to load widget data.')}
          widgetKey={widgetKey}
          hideWidget={hideWidget}
        >
          {children}
        </ItemContainerContent>
      </Card>
    </>
  )
}

export default ItemContainer
