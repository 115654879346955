import React, { useMemo, useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import DoughnutItemContainer from './containers/DoughnutItem'

import useDashboardContext from 'hooks/context/useDashboardContext'
import useOrderApprovalAggregate from 'hooks/orders/useOrderApprovalAggregate'

import ErrorIcon from '@mui/icons-material/Error'
import PendingApproval from 'svgs/statuses/PendingApproval'
import { WIDGET_KEYS } from 'utils/constants'

function OrderApprovalItem({ id }) {
  const { refreshCount, setWidgetLoaded } = useDashboardContext()

  const { mutate, aggregate, loading } = useOrderApprovalAggregate()

  const labels = useMemo(() => {
    return Object.keys(aggregate?.counts?.status_group_filters || [])
  }, [aggregate])

  const totals = useMemo(() => {
    return Object.values(aggregate?.counts?.status_group_filters || []).concat(
      Object.values(aggregate?.counts?.status_groups || [])
    )
  }, [aggregate])

  useEffect(() => {
    mutate()
  }, [refreshCount])

  useEffect(() => {
    if (!loading) {
      setWidgetLoaded(id)
    }
  }, [loading])

  return (
    <DoughnutItemContainer
      title="Order Approval"
      centerText="All orders"
      totals={totals}
      labels={labels}
      loading={loading}
      href="/command-center/order-approval"
      widgetKey={WIDGET_KEYS.ORDER_APPROVAL_DONUT}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{ mr: 1, color: 'error.main' }}
          title="Currently Temporarily On Hold"
        >
          <ErrorIcon />
        </Box>
        <Typography sx={{ mr: 2 }}>
          {aggregate?.counts?.status_group_filters?.['On Hold'] || 0}
        </Typography>
        <Box
          sx={{ mr: 1, color: 'green.main' }}
          title="Currently Pending Approval"
        >
          <PendingApproval />
        </Box>
        <Typography>
          {aggregate?.counts?.status_group_filters?.['Pending Approval'] || 0}
        </Typography>
      </Box>
    </DoughnutItemContainer>
  )
}

export default OrderApprovalItem
