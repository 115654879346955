import React from 'react'
import { Navigate } from 'react-router-dom'

import useAuth from 'hooks/useAuth'

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth()

  if (isAuthenticated && isInitialized) {
    return <Navigate to={user.homepage || '/'} replace />
  }

  return <>{children}</>
}

export default GuestGuard
