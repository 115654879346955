import React, { useEffect, useMemo, useState } from 'react'

import DoughnutItemContainer from './containers/DoughnutItem'

import { DASHBOARD_WIDGETS, WIDGET_KEYS } from 'utils/constants'

import useWidget from 'hooks/dashboard/useWidget'
import useDashboardContext from 'hooks/context/useDashboardContext'

import { mergeAggregateDataArray, groupData } from 'utils/aggregate'
import capitalize from 'helpers/node/capitalize'
import { DATE_RANGE_PRESETS } from 'components/common/DateRangePicker/dateRangePresets'

function DigitalMailboxItem({ dataKey = 'status', id }) {
  const { refreshCount } = useDashboardContext()
  const [datePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const { data, loading: widgetLoading } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.DIGITAL_MAILBOX,
    widgetName: id,
    presetId: datePreset,
  })

  const aggregateTotal = useMemo(() => {
    const aggregateData =
      mergeAggregateDataArray({ data })?.aggregated_data || {}
    const counts = aggregateData?.counts?.[`open-by-${dataKey}`] || {}

    counts['NEW'] = aggregateData?.counts?.new || 0

    return groupData({ data: counts, count: 5 })
  }, [data])

  const totals = useMemo(() => {
    return Object.values(aggregateTotal || {})
  }, [aggregateTotal])

  const [tooltips, labels] = useMemo(() => {
    const t = Object.keys(aggregateTotal || {})
    const l = t

    return [t, l]
  }, [aggregateTotal])

  useEffect(() => {
    // mutate()
  }, [refreshCount])

  return (
    <DoughnutItemContainer
      title="Digital Mailbox"
      widgetKey={WIDGET_KEYS.DIGITAL_MAILBOX_DONUT}
      centerText={`Open by ${capitalize(dataKey)}`}
      href="/correspondence-hub/digital-mailbox"
      totals={totals}
      labels={labels}
      tooltips={tooltips}
      loading={widgetLoading}
      wrapperSx={{
        '& > *:first-child': {
          mr: 4,
        },
      }}
    />
  )
}

export default DigitalMailboxItem
