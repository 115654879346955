import { useEffect, useState } from 'react'

import axios from 'utils/axios'

const useSFTPHistory = (params, sftpuser, apiBody = {}) => {
  const [sftpHistory, setSftpHistory] = useState(null)
  const [loading, setLoading] = useState(false)

  const body = { username: `${sftpuser}`, ...apiBody }

  const getData = async () => {
    if (!sftpuser) return

    try {
      setLoading(true)
      const res = await axios.post(`/sftp-user-history-reports`, body, {
        params,
      })
      if (res.status !== 200) throw new Error('Error retrieving records')
      return res.data
    } finally {
      setLoading(false)
    }
  }

  const setData = async () => {
    try {
      const responseData = await getData()

      if (responseData) {
        setSftpHistory(responseData)

        return true
      } else {
        throw new Error('A PDF could not be found for this document.')
      }
    } catch (err) {
      setSftpHistory(null)
      return false
    }
  }

  useEffect(() => {
    setData()
  }, [params, sftpuser])

  return {
    records: sftpHistory?._embedded?.user_histories ?? [],
    loading:
      (!sftpHistory?._embedded?.user_histories && !sftpHistory?.error) ||
      loading,
    count: sftpHistory?.count,
    total: sftpHistory?.total,
    totalPages: sftpHistory?.total_pages,
    pageSize: sftpHistory?.page_size,
    paginates: sftpHistory?.paginates,
  }
}

export const useSFTPUsers = () => {
  const [sftpUserList, setSftpUserList] = useState(null)

  const getData = async () => {
    const res = await axios.get('/sftp-users')
    if (res.status !== 200) throw new Error('Error retrieving records')
    return res.data
  }

  const setData = async () => {
    try {
      const data = await getData()

      if (data) {
        const userArray = []

        const newArray = data?._embedded?.users ?? []

        if (newArray.length) {
          newArray.map((i) => i.is_active && userArray.push(i))
        }

        setSftpUserList(userArray)

        return true
      } else {
        setSftpUserList(null)
        throw new Error('Unable to retrieve users')
      }
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    setData()
  }, [])

  return {
    sftpUsers: sftpUserList ?? [],
    sftpUsersLoading: !sftpUserList,
    sftpUsersError: sftpUserList?.error,
  }
}

export default useSFTPHistory
