import React, { useState } from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Pagination from '@mui/material/Pagination'
import TablePagination from '@mui/material/TablePagination'

import ParanoidPopover from 'components/common/ParanoidPopover'

import { DataListProvider } from 'contexts/component/dataList/DataListContext'
import { PaginationProvider } from 'contexts/component/dataList/PaginationContext'

import useDataListContext from 'hooks/context/useDataListContext'

function DataListLayoutContent({
  children,
  csvFilename,
  pageOptions = [20, 50, 100],
  disablePagination,
}) {
  const {
    page,
    checkedCount,
    pageSize,
    totalPages,
    setPageSize,
    setPage,
    total,
  } = useDataListContext()
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverProps, setPopoverProps] = useState({})

  const handlePageChange = (e, pageNum, anyChecked) => {
    if (anyChecked) {
      setAnchorEl(e.currentTarget)

      setPopoverProps({
        acceptAction: () => {
          setPage(pageNum)
          setAnchorEl(null)
        },
        cancelAction: () => {
          setAnchorEl(null)
        },
      })
    } else {
      setPage(pageNum)
    }
  }

  return (
    <>
      {children}
      {totalPages > 0 && (
        <Grid
          container
          sx={{
            '& > *': { my: 'auto' },
            mt: 4,
            width: '100%',
          }}
        >
          <Grid item xs={3} />
          {!disablePagination && (
            <>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <Pagination
                  page={page}
                  count={totalPages}
                  shape="rounded"
                  color="primary"
                  sx={{ m: 'auto' }}
                  onChange={(e, pageNum) =>
                    handlePageChange(e, pageNum, checkedCount > 0)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TablePagination
                  backIconButtonProps={{ sx: { display: 'none' } }}
                  nextIconButtonProps={{ sx: { display: 'none' } }}
                  component="div"
                  count={total}
                  page={page - 1}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={pageOptions}
                  onRowsPerPageChange={(e) => setPageSize(e.target.value)}
                  onPageChange={() => {}}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
      <ParanoidPopover
        anchorOrigin={{
          vertical: -175,
          horizontal: -100,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        BackdropProps={{ invisible: false }}
        {...popoverProps}
      >
        <Typography>
          You have selected items. <br />
          Changing the page will clear your selection. <br />
          Are you sure you want to change pages?
        </Typography>
      </ParanoidPopover>
    </>
  )
}

function DataListLayout(props) {
  return (
    <PaginationProvider>
      <DataListProvider>
        <DataListLayoutContent {...props} />
      </DataListProvider>
    </PaginationProvider>
  )
}

const withDataList = (DataListContent) => {
  return (props) => (
    <DataListLayout {...props}>
      <DataListContent {...props} />
    </DataListLayout>
  )
}

export default withDataList
