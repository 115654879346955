import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

import Async from 'components/common/Async'

import { COLORS, PRINT_ASSET_MIME_TYPES } from 'utils/constants'
import { downloadProcessingReport } from 'utils/order'

import statusToIcon from 'helpers/css/statusToIcon'

import Processing from 'svgs/statuses/Processing'
import Completed from 'svgs/statuses/Completed'
import Cancelled from 'svgs/statuses/Cancelled'
import PendingApproval from 'svgs/statuses/PendingApproval'

import capitalize from 'helpers/node/capitalize'
import getDollarDisplay from 'helpers/css/getDollarDisplay'

import statesJSON from 'json/states.json'
import splitByPascalCase from 'helpers/node/splitByPascalCase'
import flipObject from 'helpers/node/flipObject'
import getFilenameExt from 'helpers/node/getFilenameExt'

const isItemList = (rowData) => {
  try {
    const { data, setModalProps, setOpen } = rowData

    return (
      Array.isArray(data) &&
      data.length > 1 &&
      Boolean(setModalProps) &&
      Boolean(setOpen)
    )
  } catch (err) {
    return false
  }
}

const getItemListDisplay = ({ rowData, modalProps }) => {
  try {
    const { data, setModalProps, setOpen } = rowData

    const handleClick = () => {
      const modProps = {
        title: 'Files name',
        subTitle: undefined,
        scrollable: true,
        size: 'sm',
        footerButtonProps: [
          {
            children: 'Close',
            color: 'primary',
            variant: 'contained',
            onClick: () => setOpen(false),
            size: 'action-header',
          },
        ],
        children: (
          <>
            {data.map((value, index) => (
              <Box key={`${value}-${index}`} sx={{ fontSize: '14px' }}>
                {value}
              </Box>
            ))}
          </>
        ),
        ...modalProps,
      }

      setModalProps(modProps)
      setOpen(true)
    }

    return (
      <>
        <Box
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={handleClick}
        >
          See All
        </Box>
      </>
    )
  } catch (err) {
    return <Box>{rowData?.toString()}</Box>
  }
}

export const defaultPreset = {
  getColumnDisplay: (col) => {
    return col
      .replace(/_/gm, ' ')
      .replace(/.metadata./gm, '')
      .toUpperCase()
  },
  displayOptions: {
    file_names: (rowData) => {
      return getItemListDisplay({ rowData })
    },
  },
  doSort: [
    'id',
    'alternate_id',
    'account_id',
    'order_id',
    'date_doc',
    'datetime_created',
  ],
  exactMatch: ['alternate_id'],
  excludedColumns: ['_links', '_embedded'],
  dataListProps: {
    columnCount: 7,
    multiSelect: false,
    statusColName: 'type',
    previewName: 'preview',
  },
}

const dataListPresets = {
  dashboardOrderApproval: {
    ...defaultPreset,
    excludedColumns: ['_links', '_embedded', 'lots'],
    doSort: ['order_id', 'program_name', 'date_mailed', 'status'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      order_id: (rowValue) => {
        return (
          <Box
            component={Link}
            to={`/command-center/order-details/${rowValue}`}
            state={{
              from: {
                display: 'Order History',
                href: '/command-center/wip',
              },
            }}
            sx={{ textDecoration: 'underline' }}
          >
            {rowValue}
          </Box>
        )
      },
      status: (rowValue) => {
        if (!rowValue.display) return 'N/A'

        const Icon = statusToIcon(rowValue.display?.toLowerCase())

        const isHold = rowValue.display.toLowerCase() === 'on hold'

        const getColor = () => {
          if (isHold) return COLORS.RED
          if (rowValue.display.toLowerCase() === 'partially mailed') {
            return COLORS.YELLOW
          }

          return COLORS.GREEN
        }

        return (
          <Box
            title={isHold ? 'Temporarily On Hold' : rowValue.display}
            sx={{ display: 'flex' }}
          >
            {Icon && (
              <Icon
                style={{
                  color: getColor(),
                  marginY: 'auto',
                  padding: 0,
                }}
              />
            )}
          </Box>
        )
      },
      counts: (rowValue) => {
        return (
          <Box>
            {Object.keys(rowValue).map((k) => (
              <li>
                {k.replace('_', ' ')}: {rowValue[k] ? rowValue[k] : 'N/A'}
              </li>
            ))}
          </Box>
        )
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      columnCount: 7,
      multiSelect: false,
      statusColName: 'status',
    },
  },
  downloads: {
    ...defaultPreset,
    doSort: ['status', 'datetime_requested', 'count_documents'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      datetime_requested: (rowValue) => {
        return (
          <Box>
            DATE_START: {rowValue['datetime[start]']}
            <br />
            DATE_END: {rowValue['datetime[end]'] || 'TBD'}
          </Box>
        )
      },
      status: (rowValue) => {
        return (
          <>
            <Box
              component="div"
              title={rowValue}
              sx={{ color: rowValue === 'Processed' && 'green.main' }}
            >
              {rowValue === 'Pending' && <Processing />}
              {rowValue === 'Processed' && <Completed />}
            </Box>
          </>
        )
      },
      count_documents: (rowValue) => {
        return `${rowValue} DOCUMENT${rowValue > 1 ? 'S' : ''}`
      },
      parameters: (rowValue) => {
        return (
          <Box>
            {Object.keys(rowValue || {}).map((key) => {
              if (isItemList(rowValue[key])) {
                return getItemListDisplay({
                  rowData: rowValue[key],
                  modalProps: { title: key },
                })
              }

              return (
                <Box sx={{ mb: 1 }}>
                  {key}: {rowValue[key]?.toString()}
                </Box>
              )
            })}
          </Box>
        )
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      multiSelect: false,
      itemName: {
        singular: 'document',
        plural: 'documents',
      },
    },
  },
  correspondence: {
    ...defaultPreset,
    displayOptions: {
      ...defaultPreset.displayOptions,
      type: (rowValue) => {
        const {
          icon: Icon,
          color,
          display,
        } = rowValue ?? { color: COLORS.GREEN }

        if (!Icon) return 'N/A'

        return (
          <Box sx={{ display: 'flex' }}>
            <Icon title={display} style={{ color, marginY: 'auto' }} />
          </Box>
        )
      },
      order_id: (rowValue) => {
        return (
          <Box
            component={Link}
            to={`/command-center/order-details/${rowValue}`}
            state={{
              from: {
                display: 'Correspondence',
                href: '/correspondence-hub/correspondence',
              },
            }}
            sx={{ textDecoration: 'underline' }}
            onClick={(e) => e.stopPropagation()}
          >
            {rowValue}
          </Box>
        )
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      multiSelect: true,
      itemName: {
        singular: 'document',
        plural: 'documents',
      },
    },
  },
  digitalMailbox: {
    ...defaultPreset,
    doSort: [
      'document_id',
      'status',
      'category',
      'date_doc',
      'assigned_to_display_name',
      'DateScanned',
      'CheckAmount',
      'CheckNumber',
      'CheckSender',
      'System',
    ],
    getColumnDisplay: (col) => {
      const editDisplay = (display) => {
        let newDisplay = display.replace(/_/gm, ' ').replace(/.metadata./gm, '')
        newDisplay = splitByPascalCase(newDisplay)
        return newDisplay.toUpperCase()
      }

      const colMappings = {
        document_id: 'Doc ID',
        date_doc: 'Date',
        tracking_number: 'Tracking Number',
        assigned_to_display_name: 'Assigned To',
      }

      return colMappings[col]?.toUpperCase() || editDisplay(col)
    },
    displayOptions: {
      ...defaultPreset.displayOptions,
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      multiSelect: true,
      itemName: {
        singular: 'document',
        plural: 'documents',
      },
      flipSort: ['document_id'],
    },
  },
  orderHistory: {
    ...defaultPreset,
    excludedColumns: ['_links', '_embedded', 'lots'],
    doSort: ['order_id', 'program_name', 'date_mailed', 'status'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      order_id: (rowValue) => {
        return (
          <Box
            component={Link}
            to={`/command-center/order-details/${rowValue}`}
            state={{
              from: {
                display: 'Order History',
                href: '/command-center/wip',
              },
            }}
            sx={{ textDecoration: 'underline' }}
          >
            {rowValue}
          </Box>
        )
      },
      is_debug: (rowValue) => {
        return rowValue ? 'Yes' : 'No'
      },
      mpx_approval_required: (rowValue) => {
        return rowValue ? 'Yes' : 'No'
      },
      status: (rowValue) => {
        if (!rowValue.display) return 'N/A'

        const Icon = statusToIcon(rowValue.display?.toLowerCase())

        const isHold = rowValue.display.toLowerCase() === 'on hold'

        const getColor = () => {
          if (isHold) return COLORS.RED
          if (rowValue.display.toLowerCase() === 'partially mailed') {
            return COLORS.YELLOW
          }

          return COLORS.GREEN
        }

        return (
          <Box
            title={isHold ? 'Temporarily On Hold' : rowValue.display}
            sx={{ display: 'flex' }}
          >
            {Icon && (
              <Icon
                style={{
                  color: getColor(),
                  marginY: 'auto',
                  padding: 0,
                }}
              />
            )}
            <Typography fontWeight="medium" fontSize={14} sx={{ ml: 2 }}>
              {rowValue.display}
            </Typography>
          </Box>
        )
      },
      counts: (rowValue) => {
        return (
          <Box>
            {Object.keys(rowValue).map((k) => (
              <li>
                {k.replace('_', ' ')}: {rowValue[k] ? rowValue[k] : 'N/A'}
              </li>
            ))}
          </Box>
        )
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      columnCount: 7,
      multiSelect: false,
      statusColName: 'status',
    },
  },
  orderHistoryLots: {
    ...defaultPreset,
    excludedColumns: ['_links', '_embedded', 'lots'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      lot_status: (rowValue) => {
        const Icon = statusToIcon(rowValue?.toLowerCase())

        const isHold = rowValue.toLowerCase() === 'on hold'

        const getColor = () => {
          if (isHold) return COLORS.RED
          if (rowValue.toLowerCase() === 'partially mailed') {
            return COLORS.YELLOW
          }

          return COLORS.GREEN
        }

        return (
          <Box
            title={isHold ? 'Temporarily On Hold' : rowValue}
            sx={{ display: 'flex' }}
          >
            {Icon && (
              <Icon
                style={{
                  color: getColor(),
                  marginY: 'auto',
                  padding: 0,
                }}
              />
            )}
            <Typography>{rowValue}</Typography>
          </Box>
        )
      },
      lot_file_name: (rowValue) => {
        return (
          <Typography>{rowValue?.split('/')?.slice(-1)[0] || 'N/A'}</Typography>
        )
      },
      lot_report: (rowValue) => {
        const [orderId, reportId, filename] = rowValue?.split(',') || []

        if (!orderId || !reportId) return 'N/A'

        return (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              downloadProcessingReport(
                orderId,
                reportId,
                filename || `Audit_Sheet_${Date.now()}.pdf`
              )
            }}
          >
            Download
          </Button>
        )
      },
    },
    getColumnDisplay: (col) => {
      let colDisplay = col.replace(/.metadata./gm, '')

      // only lot id should include lot
      if (col !== 'lot_id') {
        colDisplay = colDisplay.replace(/^lot_/, '').trim()
      }

      return colDisplay.replace(/_/gm, ' ').toUpperCase()
    },
  },
  orderApproval: {
    ...defaultPreset,
    doSort: ['order_id', 'program_name', 'date_created'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      order_id: (rowValue) => {
        return (
          <Box
            component={Link}
            to={`/command-center/order-details/${rowValue}`}
            state={{
              from: {
                display: 'Order Approval',
                href: '/command-center/order-approval',
              },
            }}
            sx={{ textDecoration: 'underline' }}
          >
            {rowValue}
          </Box>
        )
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      columnCount: 5,
    },
  },
  orderDetail: {
    ...defaultPreset,
    dataListProps: {
      ...defaultPreset.dataListProps,
      columnCount: 10,
      multiSelect: false,
    },
  },
  pullConfigurations: {
    ...defaultPreset,
    displayOptions: {
      ...defaultPreset.displayOptions,
      is_active: (rowValue) => (`${rowValue}` === '1' ? 'Yes' : 'No'),
    },
    doSort: [
      'name',
      'data_field',
      'datetime_created',
      'datetime_modified',
      'is_active',
    ],
    dataListProps: {
      ...defaultPreset.dataListProps,
      columnCount: 10,
      multiSelect: false,
    },
    getColumnDisplay: (col) => {
      const nameMapping = {
        name: 'Name',
        data_field: 'Data Field',
        value_list: 'Value List',
        datetime_created: 'Created',
        datetime_modified: 'Modified',
        is_active: 'Is Active?',
      }

      const display = nameMapping[col] || capitalize(col)
      return display.toUpperCase()
    },
  },
  documentApproval: {
    ...defaultPreset,
    doSort: ['document_id', 'id', 'datetime_created', 'description'],
    colSx: {
      held_by: {
        width: '188px',
      },
      approved_by: {
        width: '188px',
      },
      rejected_by: {
        width: '188px',
      },
    },
    displayOptions: {
      ...defaultPreset.displayOptions,
      held_by: (rowValue) => {
        return <Async observable={rowValue} />
      },
      approved_by: (rowValue) => {
        return <Async observable={rowValue} />
      },
      rejected_by: (rowValue) => {
        return <Async observable={rowValue} />
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      multiSelect: true,
      itemName: {
        singular: 'document',
        plural: 'documents',
      },
      flipSort: ['document_id', 'id', 'datetime_created', 'description'],
      previewName: 'documentapprovalpreview',
    },
  },
  userMaintenance: {
    ...defaultPreset,
    doSort: ['document_id', 'id', 'datetime_created', 'description'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      username: (rowValue) => {
        return `@${rowValue}`
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      multiSelect: true,
      itemName: {
        singular: 'user',
        plural: 'users',
      },
      flipSort: ['document_id', 'id', 'datetime_created', 'description'],
      previewName: 'usermaintenance',
    },
  },
  reporting: {
    ...defaultPreset,
    doSort: [],
    dataListProps: {
      ...defaultPreset.dataListProps,
      multiSelect: false,
      itemName: {
        singular: 'report',
        plural: 'reports',
      },
    },
  },
  postageInvoices: {
    ...defaultPreset,
    doSort: ['invoice_number', 'date_inv', 'status', 'datetime_updated'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      amount_due: (rowValue) => getDollarDisplay(rowValue),
      status: (status) => {
        if (typeof status !== 'string') return <></>

        if (status === 'client_voided') {
          return (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mr: 2, color: 'error.main', pt: 1.5 }}>
                <Cancelled height="24px" width="24px" />
              </Box>
              <Typography
                fontWeight={600}
                fontSize="14px"
                sx={{ textTransform: 'uppercase' }}
                my="auto"
              >
                {status}
              </Typography>
            </Box>
          )
        }

        if (status === 'pending') {
          return (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mr: 2, color: 'yellow.main', pt: 1.5 }}>
                <PendingApproval height="24px" width="24px" />
              </Box>
              <Typography
                fontWeight={600}
                fontSize="14px"
                sx={{ textTransform: 'uppercase' }}
                my="auto"
              >
                {status}
              </Typography>
            </Box>
          )
        }

        if (status === 'paid') {
          return (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mr: 2, color: 'green.main', pt: 1.5 }}>
                <Completed height="24px" width="24px" />
              </Box>
              <Typography
                fontWeight={600}
                fontSize="14px"
                sx={{ textTransform: 'uppercase' }}
                my="auto"
              >
                {status}
              </Typography>
            </Box>
          )
        }

        return <></>
      },
    },
    getColumnDisplay: (col) => {
      let colDisplay = col.replace(/.metadata./gm, '')

      const colMap = {
        date_inv: 'INVOICE DATE',
        datetime_updated: 'LAST UPDATED',
      }

      colDisplay = colMap[col] || col

      return colDisplay.replace(/_/gm, ' ').toUpperCase()
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      multiSelect: false,
      visibleColumns: [
        'date_inv',
        'invoice_number',
        'amount_due',
        'status',
        'datetime_updated',
      ],
    },
  },
  SFTPHistory: {
    ...defaultPreset,
    doSort: ['filename', 'datetime_uploaded'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      order_id: (rowValue) => {
        return (
          <Box
            component={Link}
            to={`/command-center/order-details/${rowValue}`}
            state={{
              from: {
                display: 'SFTP History',
                href: '/command-center/sftp-stats',
              },
            }}
            sx={{ textDecoration: 'underline' }}
          >
            {rowValue}
          </Box>
        )
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
    },
  },
  postage: {
    ...defaultPreset,
    doSort: ['date_transaction', 'program_name', 'order_id'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      amount: (rowValue) => getDollarDisplay(rowValue),
      ending_balance: (rowValue) => getDollarDisplay(rowValue),
      order_id: (rowValue) => {
        return (
          <Box
            component={Link}
            to={`/command-center/order-details/${rowValue}`}
            state={{
              from: {
                display: 'Postage',
                href: '/command-center/postage',
              },
            }}
            sx={{ textDecoration: 'underline' }}
          >
            {rowValue}
          </Box>
        )
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
      visibleColumns: [
        'date_transaction',
        'program_name',
        'order_id',
        'lot_number',
        'postage_type',
        'document_count',
        'amount',
      ],
    },
  },
  printAssets: {
    ...defaultPreset,
    doSort: ['datetime_created', 'name', 'key_id'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      mime_type: (rowValue) => {
        try {
          const flippedMimeTypeMap = flipObject(PRINT_ASSET_MIME_TYPES)

          let ext = rowValue.split('/')[1]

          if (flippedMimeTypeMap[rowValue]) {
            ext = flippedMimeTypeMap[rowValue]
          }

          return (
            <Box
              sx={{
                backgroundColor: 'lightgray.light',
                display: 'flex',
                width: '60px',
                height: '32px',
                borderRadius: '6px',
              }}
            >
              <Typography
                fontSize="12px"
                fontWeight={600}
                lineHeight="12px"
                m="auto"
                color="darkgray.main"
                sx={{ textTransform: 'uppercase' }}
              >
                {ext}
              </Typography>
            </Box>
          )
        } catch (err) {
          return 'N/A'
        }
      },
      collateral_available: (rowValue) => {
        return <Checkbox checked={Boolean(rowValue)} disabled />
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
    },
    getColumnDisplay: (col) => {
      const nameMapping = {
        key_id: 'Key',
        datetime_created: 'Created',
        date_created: 'Created',
        mime_type: 'File type',
        document_id: 'Document ID',
        datetime_modified: 'Modified',
        collateral_available: 'Available for Collateral',
        default_version: 'Active Version',
      }

      if (col.match(/^TAG_/)) {
        return col.slice(4)
      }

      return nameMapping[col] || capitalize(col)
    },
    prepareRowData: (row) => {
      const rowData = {
        ...row,
        ...row?.custom_data,
      }

      delete rowData.custom_data

      let ext = getFilenameExt(row.filename)?.toUpperCase()

      if (PRINT_ASSET_MIME_TYPES[ext]) {
        rowData.mime_type = `/${ext}`
      }

      return rowData
    },
  },
  library: {
    ...defaultPreset,
    doSort: ['datetime_created', 'name'],
    displayOptions: {
      ...defaultPreset.displayOptions,
      mime_type: (rowValue) => {
        try {
          let ext = rowValue.split('/')[1]

          if (ext === 'plain') ext = 'txt'

          return (
            <Box
              sx={{
                backgroundColor: 'lightgray.light',
                display: 'flex',
                width: '60px',
                height: '32px',
                borderRadius: '6px',
              }}
            >
              <Typography
                fontSize="12px"
                fontWeight={600}
                lineHeight="12px"
                m="auto"
                color="darkgray.main"
                sx={{ textTransform: 'uppercase' }}
              >
                {ext}
              </Typography>
            </Box>
          )
        } catch (err) {
          return 'N/A'
        }
      },
      category: (rowValue) => {
        return (
          <Typography sx={{ textTransform: 'uppercase' }}>
            {rowValue}
          </Typography>
        )
      },
    },
    dataListProps: {
      ...defaultPreset.dataListProps,
    },
    getColumnDisplay: (col) => {
      const nameMapping = {
        key_id: 'Key',
        datetime_created: 'Created',
        mime_type: 'File type',
        document_id: 'Document ID',
        datetime_modified: 'Modified',
        collateral_available: 'Available for collateral',
      }

      return nameMapping[col] || capitalize(col)
    },
    prepareRowData: (row) => {
      const rowData = {
        ...row,
        ...row?.custom_data,
      }

      delete rowData.custom_data

      return rowData
    },
  },
  agencyMailing: {
    ...defaultPreset,
    dataListProps: {
      ...defaultPreset.dataListProps,
    },
    displayOptions: {
      ...defaultPreset.displayOptions,
      state: (rowValue) => {
        const stateValue = statesJSON.find((st) => st.abbreviation === rowValue)
        return stateValue?.name
      },
    },
    getColumnDisplay: (col) => {
      let colDisplay = col.replace(/.metadata./gm, '')

      if (col === 'datetime_modified') {
        colDisplay = 'Modified Date/Time'
      }

      return colDisplay.replace(/_/gm, ' ').toUpperCase()
    },
  },
  manualBill: {
    ...defaultPreset,
    doSort: [],
    dataListProps: {
      ...defaultPreset.dataListProps,
      visibleColumns: ['id', 'status', 'datetime_created', 'datetime_modified'],
    },
    displayOptions: {
      ...defaultPreset.displayOptions,
    },
    getColumnDisplay: (col) => {
      let colDisplay = col.replace(/.metadata./gm, '')

      if (col === 'id') {
        colDisplay = 'Bill ID'
      } else if (col === 'datetime_created') {
        colDisplay = 'Created Date/Time'
      } else if (col === 'datetime_modified') {
        colDisplay = 'Modified Date/Time'
      }

      return colDisplay.replace(/_/gm, ' ').toUpperCase()
    },
  },
}

export default dataListPresets
