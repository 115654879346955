import React, { useState, useRef, useEffect } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import PreviewContent from './components/PreviewContent'

import { DATALIST_DIMENSIONS } from 'utils/constants'

import useDataListContext from 'hooks/context/useDataListContext'

function BulkPreview({
  dataListComponent,
  dataList,
  PreviewComponent = PreviewContent,
  dataListProps,
  columns,
  loading,
  sidebarOpen,
  excludeFromBlur = false,
  tabIndex,
  height,
  defaultText = 'Select an item to continue',
  forceIndexChange = () => false,
  forceShow = false,
  previewMinWidth = '260px',
  ...props
}) {
  const containerRef = useRef(null)
  const { activeIndex, incActiveIndex, layoutType, checked } =
    useDataListContext()

  const isPreview = forceShow || layoutType === dataListProps.previewName
  const Container = !loading && dataList.length <= 0 ? Box : Card

  const [blocked, setBlocked] = useState(false)

  const incIndex = (e) => {
    if (isPreview) {
      if (e.key === 'ArrowDown') {
        incActiveIndex(false, forceIndexChange(activeIndex, dataList))
      } else if (e.key === 'ArrowUp') {
        incActiveIndex(true)
      }
    }
  }

  const handleKeyPress = (e) => {
    if (!blocked && !e.repeat) {
      setBlocked(true)

      incIndex(e)

      // slow down the keypress for performance reasons
      setTimeout(() => {
        setBlocked(false)
      }, 100)
    }

    if (!excludeFromBlur) e.preventDefault()
  }

  // handle arrow keys losing focus
  const handleBlur = (e) => {
    if (isPreview && !sidebarOpen && !excludeFromBlur) e.target.focus()
  }

  useEffect(() => {
    if (isPreview && !excludeFromBlur) containerRef.current.focus()
  }, [checked, layoutType])

  return (
    <Container
      ref={containerRef}
      onKeyDown={handleKeyPress}
      onBlur={!excludeFromBlur ? handleBlur : undefined}
      tabIndex={1}
      sx={{
        boxShadow: dataList.length > 0 && 3,
        '&:focus': { outline: 'none' },
        height,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            minWidth: isPreview && previewMinWidth,
            width: !isPreview ? '100%' : 'auto',
            height: isPreview ? DATALIST_DIMENSIONS.HEIGHT : 'auto',
            boxShadow: dataList.length > 0 && 3,
          }}
        >
          {dataListComponent}
        </Box>
        {isPreview && (
          <Box sx={{ flex: 1 }}>
            {dataList[activeIndex] ? (
              <PreviewComponent
                dataList={dataList}
                dataListProps={dataListProps}
                columns={columns}
                tabIndex={tabIndex}
                setBlocked={setBlocked}
                {...props}
              />
            ) : (
              <>
                {loading ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress sx={{ mx: 'auto' }} />
                  </Box>
                ) : (
                  <Typography
                    variant="h2"
                    color="darkgray.main"
                    sx={{ textAlign: 'center', mt: 4 }}
                  >
                    {defaultText}
                  </Typography>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default BulkPreview
