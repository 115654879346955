import React, { useRef, useMemo, useEffect } from 'react'
import * as Yup from 'yup'

import Box from '@mui/material/Box'

import Modal from 'components/common/Modal'
import FormRenderer from 'components/common/FormRenderer'
import useConfiguration from 'hooks/useConfiguration'
import useNotification from 'hooks/context/useNotification'
import arrayToObject from 'helpers/node/arrayToObject'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  data_field: Yup.string().required('Data Field is required'),
  value_list: Yup.string().required('Add least one value is required'),
})

const getDefault = (value, arr) => {
  if (value) return value

  if (Array.isArray(arr) && arr.length === 1) {
    return arr[0]
  }

  return ''
}

function AddPullConfigModal({
  open,
  setOpen,
  setActivePullConfig,
  createPullConfiguration,
  updatePullConfiguration,
  activePullConfig,
}) {
  const {
    instanceConfigurations: {
      bulk_mail_ship_methods,
      bulk_mail_doc_descriptions,
      bulk_mail_data_fields,
    },
  } = useConfiguration()
  const formikRef = useRef()
  const { setBasicNotification, setError } = useNotification()

  const isAdd = !activePullConfig?.name

  const renderingData = useMemo(() => {
    const dataFieldOptions = arrayToObject(
      bulk_mail_data_fields?.filter((field) => field.toLowerCase() !== 'any') ||
        []
    )
    const appliesToOptions = arrayToObject(bulk_mail_doc_descriptions || [])
    const shipViaOptions = arrayToObject(
      bulk_mail_ship_methods || [
        'USPS Priority',
        'USPS First Class',
        'UPS Ground',
        'UPS Next Day',
      ]
    )
    const printMethodOptions = arrayToObject(['Print', 'No Print'])
    const mediaOptions = arrayToObject(['Insert & Seal', 'Un-Inserted'])
    const groupingOptions = arrayToObject([
      'Package Together',
      'Package Individually',
    ])

    return [
      {
        field: 'name',
        display: 'Name',
        data_type: 'text',
        default_value: activePullConfig?.name || '',
      },
      {
        field: 'data_field',
        display: 'Data Field',
        data_type: 'select',
        default_value: getDefault(
          activePullConfig?.data_field,
          bulk_mail_data_fields
        ),
        options: dataFieldOptions,
      },
      {
        field: 'value_list',
        display: 'Value List',
        data_type: 'text',
        default_value: activePullConfig?.value_list?.replace(/,/gm, '\n') || '',
        inputProps: {
          multiline: true,
          rows: 4,
          helperText: 'One value per line.',
        },
      },
      {
        field: 'is_active',
        display: 'Is Active?',
        data_type: 'checkbox',
        default_value: Boolean(activePullConfig?.is_active),
      },
      {
        display: 'Action',
        data_type: 'divider',
      },
      {
        field: 'applies_to',
        display: 'Applies To',
        data_type: 'select',
        options: appliesToOptions,
        default_value: getDefault(
          activePullConfig?.applies_to,
          bulk_mail_doc_descriptions
        ),
      },
      {
        field: 'print_method',
        display: 'Print Method',
        data_type: 'select',
        options: printMethodOptions,
        default_value: activePullConfig?.print_method || '',
      },
      {
        field: 'media',
        display: 'Media',
        data_type: 'select',
        options: mediaOptions,
        default_value: activePullConfig?.media || '',
      },
      {
        field: 'grouping',
        display: 'Grouping',
        data_type: 'select',
        options: groupingOptions,
        default_value: activePullConfig?.grouping || '',
      },
      {
        display: 'Shipping Info',
        data_type: 'divider',
      },
      {
        field: 'ship_via',
        display: 'Ship Via',
        data_type: 'select',
        options: shipViaOptions,
        default_value: getDefault(
          activePullConfig?.ship_via,
          bulk_mail_ship_methods
        ),
      },
      {
        field: 'company',
        display: 'Company',
        data_type: 'text',
        default_value: activePullConfig?.company || '',
      },
      {
        field: 'attention',
        display: 'Attention',
        data_type: 'text',
        default_value: activePullConfig?.attention || '',
      },
      {
        field: 'address1',
        display: 'Address 1',
        data_type: 'text',
        default_value: activePullConfig?.address1 || '',
      },
      {
        field: 'address2',
        display: 'Address 2',
        data_type: 'text',
        default_value: activePullConfig?.address2 || '',
      },
      {
        field: 'address3',
        display: 'Address 3',
        data_type: 'text',
        default_value: activePullConfig?.address3 || '',
      },
      {
        field: 'city',
        display: 'City',
        data_type: 'text',
        default_value: activePullConfig?.city || '',
      },
      {
        field: 'state',
        display: 'State/Prov',
        data_type: 'text',
        default_value: activePullConfig?.state || '',
      },
      {
        field: 'zip',
        display: 'Zip/Postal',
        data_type: 'text',
        default_value: activePullConfig?.zip || '',
      },
      {
        field: 'country',
        display: 'Country',
        data_type: 'text',
        default_value: activePullConfig?.country || '',
      },
    ]
  }, [
    activePullConfig,
    bulk_mail_data_fields,
    bulk_mail_doc_descriptions,
    bulk_mail_ship_methods,
  ])

  const footerButtonProps = [
    {
      children: 'Save',
      variant: 'contained',
      color: 'primary',
      onClick: () => {
        formikRef.current.submitForm()
      },
    },
    {
      children: 'Cancel',
      variant: 'outlined',
      color: 'primary',
      onClick: () => setOpen(false),
    },
  ]

  const handleSubmit = async (values) => {
    try {
      const updatedValues = { ...values }

      Object.entries(values).forEach(([key]) => {
        if (key === 'undefined') {
          delete updatedValues[key]
        }

        // remove unchanged
        if (activePullConfig?.[key] === values[key]) {
          delete updatedValues[key]
        }
      })

      if (isAdd) {
        await createPullConfiguration(updatedValues)
        setBasicNotification(`${values.name} has been successfully added!`)
      } else {
        await updatePullConfiguration({
          id: activePullConfig?.id,
          values: updatedValues,
        })
        setBasicNotification(`${values.name} has been successfully updated!`)
      }
      setOpen(false)
    } catch (err) {
      setError(
        err.response?.data?.display_message ||
          err?.message ||
          'An error occurred when attempting to add pull configuration.'
      )
    }
  }

  const title = !isAdd
    ? `Edit ${
        activePullConfig?.name
          ? `"${activePullConfig.name}"`
          : 'Pull Configuration'
      }`
    : 'Create Pull Configuration'

  useEffect(() => {
    if (!open) {
      setActivePullConfig(null)
    }
  }, [open])

  return (
    <Modal
      scrollable
      title={title}
      height="90vh"
      width="600px"
      open={open}
      onClose={() => setOpen(false)}
      footerButtonProps={footerButtonProps}
    >
      <Box sx={{ pb: 3 }}>
        <FormRenderer
          renderingData={renderingData}
          innerRef={formikRef}
          handleSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </Box>
    </Modal>
  )
}

export default AddPullConfigModal
