const getFollowingMonth = ({ baseDate, inc = 0 }) => {
  const centralDate = baseDate ?? new Date()
  let year = centralDate.getFullYear()
  let month = centralDate.getMonth() + inc

  if (month < 0 || month > 11) {
    month = Math.abs((month + 12) % 12)
    year += inc / Math.abs(inc)
  }

  return {
    month,
    year,
  }
}

export default getFollowingMonth
