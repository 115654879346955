import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/Button'

import Autocomplete from 'components/common/Autocomplete'

import useConfiguration from 'hooks/useConfiguration'
import useDataListLayout from 'hooks/context/useDataListContext'
import useAllUsers from 'hooks/users/useAllUsers'

import { PRIVILEGES } from 'utils/constants'

import debounce from 'helpers/node/debounce'
import trimString from 'helpers/node/trimString'

import SearchIcon from '@mui/icons-material/Search'

const userFilteredKeys = ['display', 'username', 'email']

function UsersAutocomplete({ selectedOption, setSelectedOption, onEnter }) {
  let { users } = useAllUsers({}, true)
  const [searchValue, setSearchValue] = useState('')
  const [backspaceDown, setBackspaceDown] = useState(false)

  // filter by name, email, or username
  const handleAutocompleteFilter = (options) => {
    const filtered = options.filter((option) => {
      // return true if any of the user filtered keys include search value
      return userFilteredKeys.reduce(
        (result, key) =>
          result ||
          option?.[key]?.toLowerCase().includes(searchValue.toLowerCase()),
        false
      )
    })
    return filtered
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ flex: 1 }}>
        <Autocomplete
          autoHighlight={false}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          placeholder="Search"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          options={users}
          getOptionLabel={(user) => user?.display ?? ''}
          handleFilter={handleAutocompleteFilter}
          sx={{
            '& .MuiOutlinedInput-root': {
              py: 0,
            },
          }}
          onEnter={() => onEnter(searchValue)}
          handleAutocompleteChange={(e, value) => {
            if (!value && backspaceDown) return
            if (typeof value === 'string') setSelectedOption({ display: value })
            else setSelectedOption(value)
          }}
          textFieldProps={{
            onKeyDown: (e) => {
              if (e.key === 'Backspace') {
                e.stopPropagation()

                if (!backspaceDown) {
                  setBackspaceDown(true)
                }

                debounce(() => setBackspaceDown(false))()
              }
            },
          }}
        />
      </Box>
      <IconButton onClick={() => onEnter(searchValue)}>
        <SearchIcon />
      </IconButton>
    </Box>
  )
}

function SearchTextField({ setSearch }) {
  const [selectedUser, setSelectedUser] = useState({ display: '' })

  const handleFilteredSearch = (val) => {
    setSearch({
      'filter[field]': val ? 'display|email' : undefined,
      'filter[term]': trimString(val) || undefined,
    })
  }

  const handleChange = (user) => {
    if (!user || typeof user === 'object') {
      setSelectedUser(user)
      handleFilteredSearch(user?.display)
    }
  }

  return (
    <UsersAutocomplete
      fullWidth
      size="small"
      label="Search"
      selectedOption={selectedUser}
      setSelectedOption={handleChange}
      onEnter={(searchValue) => {
        setSelectedUser({ display: searchValue })
        handleFilteredSearch(searchValue)
      }}
    />
  )
}

function UserMaintenanceHeader({
  tabIndex,
  setFilterList,
  setNewUser,
  newUser,
}) {
  const { hasPrivilege } = useConfiguration()

  // filter inputs
  const [search, setSearch] = useState({})

  const { setActiveIndex } = useDataListLayout()

  const hideAddNewUser = !hasPrivilege(PRIVILEGES.ADD_USER)

  const handleAddUser = () => {
    if (!newUser) {
      setNewUser({
        _embedded: {
          roles: [],
        },
      })
    }

    setActiveIndex(0)
  }

  useEffect(() => {
    setFilterList({
      ...search,
    })
  }, [search])

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', mb: 4 }}>
        {!hideAddNewUser && (
          <Box
            sx={{
              display: tabIndex === 0 ? 'flex' : 'none',
              flexWrap: 'wrap',
              height: '100%',
              minWidth: '184px',
              pr: 6,
              '& > .MuiButton-root': {
                my: 'auto',
              },
            }}
          >
            <Button
              color="primary"
              variant="contained"
              sx={{ width: '100%' }}
              onClick={handleAddUser}
            >
              + Add New User
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            height: '100%',
            minWidth: '440px',
            '& > .MuiButton-root': {
              my: 'auto',
            },
          }}
        >
          <SearchTextField setSearch={setSearch} />
        </Box>
      </Box>
    </>
  )
}

export default UserMaintenanceHeader
