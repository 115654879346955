import React, { useRef, useMemo, useState } from 'react'

import FormRenderer from 'components/common/FormRenderer'
import Modal from 'components/common/Modal'

import useNotification from 'hooks/context/useNotification'
import useConfiguration from 'hooks/useConfiguration'
import capitalize from 'helpers/node/capitalize'

const generateRenderingData = (options = []) => {
  const arrMap = options.reduce((a, b) => {
    a[b] = b
    return a
  }, {})

  const fieldDataMap = {
    name: {
      field: 'name',
      display: 'Name',
    },
    category: {
      field: 'category',
      display: 'category',
      data_type: 'select',
      options: arrMap,
    },
    libraryfile: {
      field: 'libraryfile',
      data_type: 'fileupload',
      placeholder: 'Drag and drop files here',
      includePreview: false,
      multiple: true,
      accept: '*',
    },
  }

  const renderingData = [...Object.values(fieldDataMap)]

  return renderingData
}

function LibraryAddModal({ open, onClose, addLibraryItem }) {
  const [loading, setLoading] = useState(false)
  const formikRef = useRef()
  const { setBasicNotification, setError } = useNotification()
  const { instanceConfigurations } = useConfiguration()

  const modalProps = {
    title: 'Create New Document',
    subTitle: undefined,
    scrollable: true,
    size: undefined,
    width: '732px',
    height: '805px',
    hideButtonsBorder: true,
  }

  const handleSubmit = async (values) => {
    setLoading(true)

    try {
      let hasError = false
      const formDataValues = {}

      Object.entries(values).forEach(([key, value]) => {
        if (!value) {
          hasError = true
          setError(`${capitalize(key)} is required`)
        }

        if (key === 'name' && !values.name?.trim?.() && values.libraryfile) {
          formDataValues[key] = values.libraryfile.name
        } else {
          formDataValues[key] = value
        }
      })

      if (hasError) return

      await addLibraryItem({ updateData: formDataValues })
      setBasicNotification('Library Item has been successfully created')
      onClose()
    } catch (err) {
      setError(err?.response?.data?.display_message || err?.message)
    } finally {
      setLoading(false)
    }
  }

  const renderingData = useMemo(
    () =>
      generateRenderingData(instanceConfigurations?.library_categories || []),
    [instanceConfigurations]
  )

  return (
    <Modal {...modalProps} open={open} onClose={onClose}>
      <FormRenderer
        validateOnChange
        validateOnSubmit
        renderingData={renderingData}
        innerRef={formikRef}
        handleSubmit={handleSubmit}
        footerActionProps={[
          {
            children: 'Upload',
            color: 'primary',
            variant: 'contained',
            size: 'action-header',
            onClick: () => {
              handleSubmit(formikRef.current?.values, formikRef.current)
            },
            loading: loading,
          },
          {
            children: 'Cancel',
            color: 'primary',
            variant: 'outlined',
            size: 'action-header',
            onClick: () => onClose(),
            disabled: loading,
          },
        ]}
      />
    </Modal>
  )
}

export default LibraryAddModal
