import React, { useEffect, useState, useRef } from 'react'
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import Modal from 'components/common/Modal'
import RedactPreviewModal from './Preview'

import useNotification from 'hooks/context/useNotification'
import getMousePos from 'helpers/canvas/getMousePos'
import useModal from 'hooks/context/useModal'
import renderRedactedPDF from 'helpers/pdf/renderRedactedPDF'

GlobalWorkerOptions.workerSrc = pdfjsWorker

function RedactModalContent({
  pdf,
  canvasRef,
  pdfCanvasRef,
  rects,
  setRects,
  currentPage,
}) {
  const [pdfPage, setPdfPage] = useState(null)
  const currentRects = rects[currentPage - 1]
  const drawRef = useRef({ origin: null, end: null })

  const renderRect = (context, rect) => {
    const x = rect.origin.x
    const y = rect.origin.y
    const width = rect.end.x - x
    const height = rect.end.y - y
    context.beginPath()
    context.rect(x, y, width, height)
    context.fillStyle = 'black'
    context.fill()
  }

  const renderPDF = async (force = false) => {
    let page = pdfPage

    if (!page || force) {
      const document = await getDocument(window.URL.createObjectURL(pdf))
        .promise
      page = await document.getPage(currentPage)
      setPdfPage(page)
    }

    const viewport = page.getViewport({ scale: 1 })
    const canvas = pdfCanvasRef.current
    const drawCanvas = canvasRef.current
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width
    drawCanvas.height = viewport.height
    drawCanvas.width = viewport.width

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    }
    await page.render(renderContext).promise
  }

  const renderCanvas = async () => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    context.clearRect(0, 0, canvas.width, canvas.height)

    currentRects.forEach((rect) => {
      renderRect(context, rect)
    })

    if (drawRef.current.origin !== null && drawRef.current.end !== null) {
      renderRect(context, drawRef.current)
    }
  }

  const handleMouseMove = (e) => {
    const canvas = canvasRef.current
    const mouseEnd = getMousePos(canvas, e)
    if (drawRef.current.origin !== null) {
      drawRef.current.end = mouseEnd
      renderCanvas()
    }
  }

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current
    const mouseOrigin = getMousePos(canvas, e)
    drawRef.current.origin = mouseOrigin
  }

  const handleMouseUp = (e) => {
    const canvas = canvasRef.current
    const mouseEnd = getMousePos(canvas, e)
    if (drawRef.current.origin !== null) {
      drawRef.current.end = mouseEnd
      const newRects = rects.slice()
      newRects[currentPage - 1] = currentRects.concat([drawRef.current])
      setRects(newRects)
      drawRef.current = {
        origin: null,
        end: null,
      }
      renderCanvas()
    }
  }

  useEffect(() => {
    renderPDF(true).then(() => {
      renderCanvas()
    })
  }, [pdf, currentPage])

  useEffect(() => {
    renderCanvas()
  }, [currentRects])

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        component="canvas"
        sx={{
          position: 'absolute',
          border: '1px solid',
          borderColor: 'lightgray.main',
        }}
        ref={canvasRef}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
      <canvas ref={pdfCanvasRef} />
    </Box>
  )
}

function RedactModalV2({
  document,
  open,
  onClose,
  redactItem,
  pageCount,
  fetchPdf,
  pdf,
}) {
  const canvasRef = useRef()
  const pdfCanvasRef = useRef()
  const [currentPage, setCurrentPage] = useState(1)
  const [rects, setRects] = useState([])
  const [cachedPdf, setCachedPdf] = useState(pdf)
  const { setError, setBasicNotification } = useNotification()
  const { setComponent, setModalProps, setOpen: setModalOpen } = useModal()

  const handleReset = () => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
    }

    setRects(Array.from({ length: pageCount }).map(() => []))
  }

  const handleResetClick = () => {
    if (rects.filter((r) => r.length).length === 0) {
      return setModalOpen(false)
    }

    setComponent(Modal)
    setModalProps({
      title: 'Confirm Reset',
      children:
        'You have unsaved changes that will be lost. Are you sure you want to continue?',
      size: 'sm',
      footerButtonProps: [
        {
          children: 'Confirm',
          color: 'primary',
          variant: 'contained',
          size: 'action-header',
          onClick: () => {
            handleReset()
            setModalOpen(false)
          },
        },
        {
          children: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          size: 'action-header',
          onClick: () => setModalOpen(false),
        },
      ],
    })
    setModalOpen(true)
  }

  const handleClose = () => {
    if (rects.filter((r) => r.length).length === 0) {
      return onClose()
    }

    setComponent(Modal)
    setModalProps({
      title: 'Confirm Close',
      children:
        'You have unsaved changes that will be lost. Are you sure you want to continue?',
      size: 'sm',
      footerButtonProps: [
        {
          children: 'Confirm',
          color: 'primary',
          variant: 'contained',
          size: 'action-header',
          onClick: () => {
            setModalOpen(false)
            onClose()
          },
        },
        {
          children: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          size: 'action-header',
          onClick: () => setModalOpen(false),
        },
      ],
    })
    setModalOpen(true)
  }

  const handleSubmit = async () => {
    try {
      const { arrayBuffer } = await renderRedactedPDF(cachedPdf, rects)

      const handleConfirm = async () => {
        try {
          const formData = new FormData()
          const binary = new Blob([arrayBuffer], {
            type: 'application/octet-stream',
          })
          formData.set('document', binary)
          await redactItem(formData)
          setCachedPdf(null)
          fetchPdf()
          setBasicNotification('Document has been successfully redacted.')
        } catch (err) {
          setError(
            err.response?.data.display_message ||
              'Document could not be redacted.'
          )
        } finally {
          onClose()
          setModalOpen(false)
        }
      }

      const modalProps = {
        title: 'Confirm Redacted Document',
        subTitle: 'The following is a preview of the redacted document.',
        scrollable: true,
        size: undefined,
        height: '90vh',
        width: '60vw',
        hideButtonsBorder: true,
        footerButtonProps: [
          {
            children: 'Confirm',
            color: 'primary',
            variant: 'contained',
            size: 'action-header',
            onClick: () => {
              handleConfirm()
            },
          },
          {
            children: 'Cancel',
            color: 'primary',
            variant: 'outlined',
            size: 'action-header',
            onClick: () => setModalOpen(false),
          },
        ],
        redactedPdf: arrayBuffer,
      }

      setModalProps(modalProps)
      setComponent(RedactPreviewModal)
      setModalOpen(true)
    } catch (err) {
      setError(
        err.response?.data.display_message || 'Document could not be redacted.'
      )
    }
  }

  const handlePageChange = (inc = 0) => {
    setCurrentPage(Math.max(Math.min(1, currentPage + inc), currentPage + inc))
  }

  const modalProps = {
    title: `Redact Document ID ${document?.document_id}`,
    subTitle: undefined,
    scrollable: true,
    size: undefined,
    height: '90vh',
    width: '60vw',
    hideButtonsBorder: true,
    footerButtonProps: [
      {
        children: 'Save',
        color: 'primary',
        variant: 'contained',
        size: 'action-header',
        onClick: () => handleSubmit(),
      },
      {
        children: 'Reset',
        color: 'secondary',
        variant: 'contained',
        size: 'action-header',
        onClick: () => handleResetClick(),
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        size: 'action-header',
        onClick: () => handleClose(),
      },
    ],
  }

  useEffect(() => {
    if (pdf && !cachedPdf) {
      setCachedPdf(pdf)
    }
  }, [pdf])

  useEffect(() => {
    if (rects.length !== pageCount) {
      setRects(Array.from({ length: pageCount }).map(() => []))
    }
  }, [pageCount])

  useEffect(() => {
    if (!open) {
      handleReset()
    }
  }, [open])

  return (
    <Modal {...modalProps} open={open} onClose={onClose}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            sx={{ my: 'auto' }}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(-1)}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography fontWeight="bold" sx={{ my: 'auto' }}>
            Page #{currentPage}
          </Typography>
          <IconButton
            sx={{ my: 'auto' }}
            disabled={currentPage === pageCount}
            onClick={() => handlePageChange(1)}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography>(Right-Click and Drag to Redact an Area)</Typography>
        </Box>
        <Box sx={{ mx: 'auto', pt: 4 }}>
          {cachedPdf && (
            <RedactModalContent
              pdf={cachedPdf}
              canvasRef={canvasRef}
              pdfCanvasRef={pdfCanvasRef}
              rects={rects}
              setRects={setRects}
              currentPage={currentPage}
            />
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default RedactModalV2
