import React, { useRef, useState, useMemo } from 'react'

import Modal from 'components/common/Modal'
import FormRenderer from 'components/common/FormRenderer'

import useNotification from 'hooks/context/useNotification'

function UploadNewVersionModalContent({
  libraryItem,
  setLoading,
  formikRef,
  addNewLibraryItemVersion,
  setOpen,
}) {
  const { setBasicNotification, setError } = useNotification()

  const handleSubmit = async (values) => {
    setLoading(true)

    try {
      const formDataValues = {}

      Object.entries(values).forEach(([key, value]) => {
        if (key === 'name' && !values.name?.trim?.() && values.libraryfile) {
          formDataValues[key] = values.libraryfile.name
        } else if (key === 'set_as_default') {
          formDataValues[key] = values.set_as_default ? 1 : 0
        } else {
          formDataValues[key] = value
        }
      })

      await addNewLibraryItemVersion({
        id: libraryItem?.id,
        updateData: { ...formDataValues },
      })

      setBasicNotification('New version has been successfully added!')
      setOpen(false)
    } catch (err) {
      setError(
        err?.response?.data?.display_message ||
          err?.message ||
          'Error updating document.'
      )
    } finally {
      setLoading(false)
    }
  }

  const renderingData = useMemo(() => {
    return [
      {
        field: 'libraryfile',
        data_type: 'fileupload',
        placeholder: 'Drag and drop files here',
        includePreview: false,
        multiple: true,
        accept: '*',
      },
      {
        field: 'set_as_default',
        data_type: 'checkbox',
        display: 'Set as active',
      },
    ]
  }, [])

  return (
    <FormRenderer
      renderingData={renderingData}
      innerRef={formikRef}
      handleSubmit={handleSubmit}
    />
  )
}

function UploadNewVersionModal({
  open,
  setOpen,
  libraryItem,
  addNewLibraryItemVersion,
}) {
  const formikRef = useRef()
  const [loading, setLoading] = useState(false)

  return (
    <Modal
      hideButtonsBorder
      title={`Upload New Version of ${libraryItem?.name || ''}`}
      width="732px"
      height="605px"
      open={open}
      footerButtonProps={[
        {
          children: 'Upload',
          variant: 'contained',
          color: 'primary',
          size: 'action-header',
          onClick: () => formikRef.current.submitForm(),
          loading: loading,
        },
        {
          children: 'Cancel',
          variant: 'outlined',
          color: 'primary',
          size: 'action-header',
          disabled: loading,
          onClick: () => setOpen(false),
        },
      ]}
    >
      <UploadNewVersionModalContent
        libraryItem={libraryItem}
        setLoading={setLoading}
        formikRef={formikRef}
        addNewLibraryItemVersion={addNewLibraryItemVersion}
        setOpen={setOpen}
      />
    </Modal>
  )
}

export default UploadNewVersionModal
