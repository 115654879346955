import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Autocomplete from 'components/common/Autocomplete'

import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import reorder from 'helpers/node/reorder'

const StyledListItem = styled(Box)`
  padding: 0 !important;
  color: ${({ theme }) => theme.palette.darkgray.main};
  font-size: 1rem;
`

function Chips({
  selectedOptions,
  setSelectedOptions,
  getOptionLabel,
  handleOptionDelete,
  rearrange,
}) {
  if (!rearrange) {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedOptions.map((option, optionIndex) => (
          <Chip
            label={getOptionLabel(option)}
            onDelete={() => handleOptionDelete(optionIndex)}
            sx={{
              m: 1,
              color: 'primary.main',
              fontWeight: 'bold',
              bgcolor: 'background.default',
            }}
          />
        ))}
      </Box>
    )
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      selectedOptions,
      result.source.index,
      result.destination.index
    )

    setSelectedOptions(items)
  }

  return (
    <Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="chips-droppable" direction="horizontal">
          {(provided) => (
            <Box
              sx={{ display: 'flex', width: '100%' }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {selectedOptions.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Chip
                        label={getOptionLabel(item)}
                        onDelete={() => handleOptionDelete(index)}
                        sx={{
                          m: 1,
                          color: 'primary.main',
                          fontWeight: 'bold',
                          bgcolor: 'background.default',
                          cursor: 'grab',
                        }}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}

function SelectRenderedOption({ option, getOptionLabel, ...renderProps }) {
  return (
    <StyledListItem component="li" {...renderProps} option={option}>
      <Checkbox
        checked={renderProps['aria-selected']}
        color="green"
        sx={{ color: 'lightgray.main' }}
      />
      {getOptionLabel(option)}
    </StyledListItem>
  )
}

function MultiSelectDropdown({
  options,
  name,
  value,
  onChange,
  showChips = true,
  sortOptions = false,
  rearrange = false,
  RenderedOption = SelectRenderedOption,
  ...props
}) {
  const [searchValue, setSearchValue] = useState('')
  const [selectedOptions, _setSelectedOptions] = useState(value || [])

  const setSelectedOptions = (newOptions) => {
    if (sortOptions) {
      let sortedOptions = newOptions.slice()

      if (sortOptions instanceof Function) {
        sortedOptions.sort(sortOptions)
      } else {
        sortedOptions.sort()
      }

      _setSelectedOptions(sortedOptions)
    } else {
      _setSelectedOptions(newOptions)
    }
  }

  const getOptionLabel = (option) => {
    if (props.getOptionLabel) {
      return props.getOptionLabel(option)
    } else {
      return option
    }
  }

  const handleUpdateChange = (name, value) => {
    setSelectedOptions(value)
    onChange({ target: { name, value } })
  }

  const handleOptionDelete = (optionIndex) => {
    const newOptions = [...selectedOptions]
    newOptions.splice(optionIndex, 1)
    setSelectedOptions(newOptions)
    onChange({ target: { name, value: newOptions } })
  }

  const renderOption = (renderProps, option) => {
    return (
      <RenderedOption
        {...renderProps}
        option={option}
        getOptionLabel={getOptionLabel}
      />
    )
  }

  useEffect(() => {
    setSelectedOptions(value || [])
  }, [value])

  return (
    <>
      <Autocomplete
        options={options}
        disableCloseOnSelect
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedOption={value}
        setSelectedOption={(option) => handleUpdateChange(name, option)}
        multiple
        placeholder={name}
        renderOption={renderOption}
        renderTags={() =>
          showChips ? `` : `${selectedOptions.length} selected`
        }
        {...props}
      />
      {showChips && selectedOptions?.length > 0 && (
        <Chips
          selectedOptions={selectedOptions}
          setSelectedOptions={(newOptions) => {
            _setSelectedOptions(newOptions)
            onChange({ target: { value: newOptions } })
          }}
          handleOptionDelete={handleOptionDelete}
          getOptionLabel={getOptionLabel}
          rearrange={rearrange}
        />
      )}
    </>
  )
}

export default MultiSelectDropdown
