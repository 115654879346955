import {
  createContext,
  useEffect,
  useReducer,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import jwtDecode from 'jwt-decode'
import { useIdleTimer } from 'react-idle-timer'

import axios from 'utils/axios'
import { isValidToken, shouldRefreshToken } from 'utils/jwt'
import {
  PROMPT_TIMEOUT,
  REFRESH_INTERVAL,
  TIMEOUT,
  ADMIN_USERNAME,
} from 'utils/constants'

import getUserColor from 'helpers/css/getUserColor'
import getModuleRoute from 'helpers/browser/getModuleRoute'
import TimeoutModal from 'components/modals/TimeoutModal'

const INITIALIZE = 'INITIALIZE'
const UNINITIALIZE = 'UNINITIALIZE'
const LOG_IN = 'LOG_IN'
const LOG_OUT = 'LOG_OUT'
const REGISTER = 'REGISTER'
const SET_AXIOS = 'SET_AXIOS'
const SET_INSTANCE = 'SET_INSTANCE'
const SHOW_TIMEOUT = 'SHOW_TIMEOUT'
const BLOCK_REFRESH = 'BLOCK_REFRESH'
const SET_REASON = 'SET_REASON'
const SET_ACCESS_TOKENS = 'SET_ACCESS_TOKENS'

const initialState = {
  isAuthenticated: false,
  hasLoggedIn: false,
  isInitialized: false,
  isLoggingIn: false,
  showTimeoutModal: false,
  blockRefresh: false,
  logoutReason: '',
  user: {
    userId: '',
    accessToken: '',
    accessTokenExpires: '',
    refreshToken: '',
    refreshTokenExpires: '',
    instanceId: '',
    display: '',
  },
}

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        hasLoggedIn: action.payload.isAuthenticated,
        user: action.payload.user,
        isLoggingIn: false,
      }
    case UNINITIALIZE: {
      axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
      delete axios.defaults.headers.Authorization

      return {
        ...state,
        isInitialized: false,
        isLoggingIn: true,
      }
    }
    case LOG_IN:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated ?? true,
        isInitialized: true,
        hasLoggedIn: action.payload.isAuthenticated ?? true,
        user: action.payload.user,
        isLoggingIn: false,
      }
    case LOG_OUT:
      Object.keys(initialState.user).forEach((key) => {
        if (key !== 'instanceId') delete localStorage[key]
      })
      delete axios.defaults.headers.Authorization
      axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

      return {
        ...state,
        isAuthenticated: false,
        isInitialized: true,
        user: { ...initialState.user, instanceId: state.user.instanceId },
        logoutReason: action.payload?.logoutReason,
      }
    case REGISTER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      }
    case SET_AXIOS:
      // set data that will persist for every API call
      if (action.payload.accessToken)
        axios.defaults.headers.Authorization = `Bearer ${action.payload.accessToken}`
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/${action.payload.instanceId}`

      return {
        ...state,
        user: {
          ...state.user,
          instanceId: action.payload.instanceId,
        },
      }
    case SET_INSTANCE:
      return {
        ...state,
        user: {
          ...state.user,
          instanceId: action.payload.instanceId,
        },
      }
    case SHOW_TIMEOUT:
      return {
        ...state,
        showTimeoutModal: action.payload.showTimeoutModal,
      }
    case BLOCK_REFRESH:
      return {
        ...state,
        blockRefresh: action.payload.blockRefresh,
      }
    case SET_REASON: {
      return {
        ...state,
        logoutReason: action.payload.logoutReason,
      }
    }
    case SET_ACCESS_TOKENS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    }
    default:
      return state
  }
}

const AuthContext = createContext(null)

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState)
  const storageKeys = [
    'accessToken',
    'accessTokenExpires',
    'refreshToken',
    'refreshTokenExpires',
    'display',
    'color',
    'homepage',
    'instanceId',
    'timezone',
  ]
  const idleRef = useRef()

  const envName = useMemo(() => {
    let env = (process.env.REACT_APP_BASE_URL ?? '').match(
      /^(?:http[s]?:\/\/)?(?:www\.)?(?:([^.]+)(?:\.))*(?:mpxpp\.com)/
    )
    if (env === null) {
      // assert env is "custom", and therefor unrecognized
      return 'custom'
    }

    return (env?.[1] ?? 'prod').toLowerCase()
  }, [])

  const isValidUser = (user) => {
    if (!user) return false

    let loggedIn = true

    Object.keys(initialState.user).forEach((key) => {
      loggedIn = loggedIn && user[key]
    })

    return loggedIn
  }

  const setLocalStorage = (user, clear = false) => {
    storageKeys.forEach((key) => {
      if (clear) delete localStorage[key]
      else localStorage[key] = user[key]
    })
  }

  const getUserData = async (userId) =>
    (await axios.get(`/users/${userId}?embed=*`)).data

  const updateUser = async ({ responseData }) => {
    if (!responseData) return

    const {
      token: accessToken,
      refresh_token: refreshToken,
      datetime_token_expires: accessTokenExpires,
      datetime_refresh_token_expires: refreshTokenExpires,
    } = responseData
    const jwtToken = jwtDecode(accessToken)
    const { UserID, UserInstance } = jwtToken

    const user = {
      display: 'Default User',
      color: state.user?.color || getUserColor(),
      userId: UserID,
      instanceId: UserInstance,
      accessToken,
      accessTokenExpires,
      refreshToken,
      refreshTokenExpires,
      userInstances: [],
    }

    if (isValidUser(user)) {
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/${user.instanceId}`
      axios.defaults.headers.Authorization = `Bearer ${accessToken}`
      localStorage.setItem('accessToken', accessToken)

      const {
        display,
        username,
        home_module: homepage,
        timezone,
        note,
        _embedded: { user_instances: userInstances },
      } = await getUserData(UserID)
      user.display = display
      user.timezone = timezone
      user.note = note
      user.username = username
      user.isMPXAdmin = username === ADMIN_USERNAME
      user.userInstances = userInstances
      user.isMPXAdmin = username === ADMIN_USERNAME
      user.homepage =
        (await getModuleRoute(homepage.replace(/_/gm, '-'))) ||
        '/main/dashboard' // default to dashboard

      dispatch({
        type: LOG_IN,
        payload: {
          user,
        },
      })

      setLocalStorage(user)

      return user
    } else {
      throw new Error('Invalid user.')
    }
  }

  const logIn = async ({
    username,
    password,
    instanceId,
    impersonate,
    sso,
    resData,
    newInstance,
  }) => {
    const isCode = impersonate || sso

    if (!newInstance)
      dispatch({
        type: UNINITIALIZE,
      })

    window.hj('identify', username, {
      lastInstance: instanceId,
      sitename: envName,
    })

    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/${instanceId}`

    const responseData =
      resData ||
      (
        await axios.post(`/user-sessions`, {
          username,
          password: isCode ? undefined : password,
          one_time_code: isCode ? password : undefined,
          is_impersonation: impersonate,
          is_sso: sso,
          instance_id: newInstance,
        })
      ).data

    await updateUser({ responseData })
    idleRef.current.reset()
    idleRef.current.start()
  }

  const setLogoutReason = useCallback((reason) => {
    dispatch({
      type: SET_REASON,
      payload: {
        logoutReason: reason,
      },
    })
  }, [])

  const logOut = async (
    includeServer = true,
    reason = 'You have successfully logged out.'
  ) => {
    if (includeServer) {
      // log out user regardless of result
      try {
        await axios.delete('/user-sessions/current')
      } catch (e) {}
    }

    // only emit message if this is the active tab
    if (idleRef.current.isLastActiveTab()) {
      idleRef.current.message({ type: 'logout' }, false)
    }

    dispatch({
      type: SHOW_TIMEOUT,
      payload: {
        showTimeoutModal: false,
      },
    })
    dispatch({ type: LOG_OUT, payload: { logoutReason: reason } })
  }

  const setInstance = useCallback((instanceId, accessToken) => {
    dispatch({
      type: SET_AXIOS,
      payload: {
        instanceId,
        accessToken,
      },
    })
  }, [])

  const refreshTokens = async () => {
    const { user, isAuthenticated } = state
    if (!isAuthenticated) {
      throw new Error('User must be logged in.')
    }
    const responseData = (
      await axios.patch('/user-sessions/current', {
        refresh_token:
          localStorage.getItem('refreshToken') || user.refreshToken,
      })
    ).data

    return await updateUser({ responseData })
  }

  const handleRefresh = () => {
    const asyncHandler = async () => {
      try {
        const user = await refreshTokens()
        idleRef.current.message({ ...user, type: 'refresh' }, false)
      } catch (err) {
        await logOut(true)
      } finally {
        setTimeout(() => {
          dispatch({
            type: BLOCK_REFRESH,
            payload: {
              blockRefresh: false,
            },
          })
        }, REFRESH_INTERVAL)
      }
    }

    if (
      !state.blockRefresh &&
      shouldRefreshToken({ accessToken: state.user.accessToken }) &&
      idleRef.current?.isLastActiveTab()
    ) {
      dispatch({
        type: BLOCK_REFRESH,
        payload: {
          blockRefresh: true,
        },
      })
      asyncHandler()
    }
  }

  const resendPin = useCallback(async () => {
    const baseURL = process.env.REACT_APP_BASE_URL
    const isNotProd = baseURL.includes('test')

    try {
      const res = await axios.post(`/user-pins`, null, {
        params: {
          skip_notification: isNotProd ? 1 : undefined,
        },
      })

      return { pin: res.data?.pin || '' }
    } catch (err) {
      return {
        error:
          err.response?.data?.display_message ||
          'An error occurred while resending PIN. Try again later.',
      }
    }
  }, [])

  const validate2FA = useCallback(async (pin) => {
    const res = await axios.post(`/user-pin-validations`, { pin })
    return res.data?.success
  }, [])

  const showTimeoutModal = useCallback((show) => {
    dispatch({
      type: SHOW_TIMEOUT,
      payload: {
        showTimeoutModal: show,
      },
    })
  }, [])

  const onPrompt = useCallback(() => {
    if (state.isAuthenticated) {
      showTimeoutModal(true)
    }
  }, [state.isAuthenticated, showTimeoutModal])

  const onIdle = useCallback(() => {
    // log out the user when they are idle
    if (state.showTimeoutModal && state.isAuthenticated) {
      logOut(true, 'Your session has expired. Please log back in to continue.')
      showTimeoutModal(false)
    }
  }, [state.showTimeoutModal, showTimeoutModal, logOut, state.isAuthenticated])

  const onAction = useCallback(() => {
    if (state.isAuthenticated && !state.blockRefresh) {
      handleRefresh()
    }
  }, [state.isAuthenticated, state.blockRefresh, idleRef])

  const onMessage = useCallback(
    (payload) => {
      if (payload.type === 'continue') {
        showTimeoutModal(false)
      } else if (payload.type === 'refresh') {
        axios.defaults.headers.Authorization = `Bearer ${payload.accessToken}`
        dispatch({
          type: SET_ACCESS_TOKENS,
          payload: {
            accessToken: payload.accessToken,
            accessTokenExpires: payload.accessTokenExpires,
            refreshToken: payload.refreshToken,
            refreshTokenExpires: payload.refreshTokenExpires,
          },
        })
      } else if (payload.type === 'logout') {
        logOut(false)
      }
    },
    [showTimeoutModal, logOut, dispatch]
  )

  const idleTimer = useIdleTimer({
    onIdle,
    onPrompt,
    onAction,
    onMessage,
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_TIMEOUT,
    crossTab: true,
    syncTimers: 200,
    stopOnIdle: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
  })

  useEffect(() => {
    idleRef.current = idleTimer
  }, [idleTimer])

  // assess auth on load
  useEffect(() => {
    const initialize = () => {
      try {
        const {
          accessToken,
          accessTokenExpires,
          refreshToken,
          refreshTokenExpires,
        } = localStorage

        if (isValidToken({ accessToken, accessTokenExpires })) {
          const { UserInstance: instanceId, UserID: userId } =
            jwtDecode(accessToken)
          const user = { ...initialState.user, instanceId, userId }

          storageKeys.forEach((key) => {
            user[key] = localStorage[key]
          })

          axios.defaults.headers.Authorization = `Bearer ${accessToken}`
          axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/${user.instanceId}`

          dispatch({
            type: SET_AXIOS,
            payload: {
              instanceId: user.instanceId,
              accessToken,
            },
          })

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          })

          updateUser({
            responseData: {
              token: accessToken,
              refresh_token: refreshToken,
              datetime_token_expires: accessTokenExpires,
              datetime_refresh_token_expires: refreshTokenExpires,
            },
          })
        } else {
          throw new Error('Invalid access token')
        }
      } catch (err) {
        dispatch({ type: LOG_OUT })
      }
    }

    initialize()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        logIn,
        logOut,
        refreshTokens,
        setInstance,
        resendPin,
        validate2FA,
        setLogoutReason,
        updateUser,
        idleTimer,
      }}
    >
      {children}
      {state.showTimeoutModal && (
        <TimeoutModal
          open={state.showTimeoutModal}
          setOpen={showTimeoutModal}
          idleTimer={idleTimer}
          refreshAction={handleRefresh()}
        />
      )}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
