import React, { useState, useEffect, useMemo } from 'react'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import SearchField from 'components/common/SearchField'
import AddLibraryItemModal from 'components/library/AddModal'
import LibraryTable from './LibraryTable'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import useConfiguration from 'hooks/useConfiguration'
import { useLibraries } from 'hooks/library'

import { PRIVILEGES } from 'utils/constants'

function LibraryResultsList() {
  const {
    hasPrivilege,
    instanceConfigurations,
    documentMimeTypes,
    mimeTypesLoading,
  } = useConfiguration()
  const { setTotal, setTotalPages, page, pageSize } = useDataListContext()
  const [showAddModal, setShowAddModal] = useState(false)
  const [name, setName] = useState('')
  const [documentId, setDocumentId] = useState('')
  const [filters, setFilters] = useState({})
  const [sortList, setSortList] = useState([])

  const categories = useMemo(() => {
    return instanceConfigurations?.library_categories || []
  }, [instanceConfigurations])

  const hookParams = useMemo(() => {
    const params = {
      ...sortList,
      embed: 'versions',
      'paging[page]': page,
      'paging[page_size]': pageSize,
    }

    if (filters.name) {
      params['name'] = filters.name
      params['name_like'] = 1
    }

    if (filters.versiondocid) {
      params['versiondocid'] = filters.versiondocid
    }

    if (filters.versiondocmimetype) {
      params['versiondocmimetype'] = filters.versiondocmimetype
    }

    if (filters.category) {
      params['category'] = filters.category
    }

    return params
  }, [sortList, filters, page, pageSize])

  const { libraries, total, totalPages, loading, addLibraryItem } =
    useLibraries(hookParams)

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages)
    }
  }, [total])

  useEffect(() => {
    if (totalPages !== undefined) {
      setTotalPages(totalPages)
    }
  }, [totalPages])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: libraries,
    presetName: 'library',
    setSortList,
  })

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    const newFilters = { ...filters }
    newFilters[name] = value

    setFilters(newFilters)
  }

  return (
    <>
      <AddLibraryItemModal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        addLibraryItem={addLibraryItem}
      />

      <Box
        sx={{
          height: '80px',
          display: 'flex',
          justifyContent: 'space-between',
          pb: 7.5,
          pt: 2.5,
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 600,
            lineHeight: '32px',
            my: 'auto',
          }}
        >
          Library
        </Typography>
        {hasPrivilege(PRIVILEGES.MODIFY_LIBRARY) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowAddModal(true)}
            size="large"
          >
            Create New Document
          </Button>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          '& > *': { display: 'flex' },
          '& > * > *': { my: 'auto' },
          mb: 2,
          pb: 2,
          borderBottom: '1px solid',
          borderColor: 'lightgray.main',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <TextField
            fullWidth
            select
            label={mimeTypesLoading ? 'Please wait...' : 'File type'}
            placeholder="File type"
            size="small"
            sx={{ mr: 8 }}
            name="versiondocmimetype"
            value={filters.versiondocmimetype || ''}
            onChange={handleFilterChange}
            disabled={mimeTypesLoading}
          >
            <MenuItem value="" selected={filters.versiondocmimetype === ''}>
              Any
            </MenuItem>
            {documentMimeTypes.map((mimeType) => (
              <MenuItem
                value={mimeType.value}
                selected={filters.versiondocmimetype === mimeType.value}
              >
                {mimeType.key}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Category"
            placeholder="Category"
            size="small"
            sx={{ mr: 8 }}
            name="category"
            value={filters.category || ''}
            onChange={handleFilterChange}
          >
            <MenuItem value="" selected={filters.category === ''}>
              Any
            </MenuItem>
            {categories.map((category) => (
              <MenuItem
                value={category}
                selected={filters.category === category}
              >
                {category}
              </MenuItem>
            ))}
          </TextField>
          <SearchField
            fullWidth
            size="small"
            placeholder="Name"
            name="name"
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            onSearch={(val) =>
              handleFilterChange({
                target: { name: 'name', value: val ?? name },
              })
            }
            sx={{ mr: 8 }}
          />
          <SearchField
            fullWidth
            size="small"
            placeholder="Document ID"
            name="versiondocid"
            value={documentId || ''}
            onChange={(e) => setDocumentId(e.target.value)}
            onSearch={(val) =>
              handleFilterChange({
                target: { name: 'versiondocid', value: val ?? documentId },
              })
            }
          />
        </Box>
      </Box>

      <Box sx={{ minHeight: '400px' }}>
        {!total && !loading ? (
          <Box sx={{ px: 4, pt: 2, textAlign: 'center' }}>
            <Typography>Filter results empty</Typography>
          </Box>
        ) : (
          <LibraryTable
            dataList={dataList}
            columns={columns}
            sortList={sortList}
            setSortList={setSortList}
            loading={loading}
            {...dataListProps}
          />
        )}
      </Box>
    </>
  )
}

export default LibraryResultsList
