import styled from '@mui/styles/styled'
import MuiAppBar from '@mui/material/AppBar'

import { SIDEBAR_WIDTH } from 'utils/constants'

const NavAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: theme.palette.primary.opaque,
  ...(open && {
    marginLeft: SIDEBAR_WIDTH.OPEN,
    width: `calc(100% - ${SIDEBAR_WIDTH.OPEN})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export default NavAppBar
