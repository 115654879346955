import React from 'react'

function Created({ width = '24', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="currentColor" />
      <path
        d="M23.3368 37.0899C16.302 36.73 10.7179 30.7969 10.9142 23.6312C11.1105 16.8037 16.8037 11.1105 23.6312 10.9142C30.7969 10.7179 36.73 16.302 37.0899 23.3259C37.1117 23.6967 36.7955 24.0021 36.4246 24.0021H34.1343C33.7852 24.0021 33.5126 23.7294 33.4908 23.3804C33.1636 18.3743 28.9536 14.4152 23.8603 14.4916C19.0069 14.5679 14.557 19.0069 14.4916 23.8603C14.4152 28.9645 18.3852 33.1745 23.3913 33.4908C23.7294 33.5126 24.0021 33.7743 24.0021 34.1124V36.1956C24.013 36.8064 23.7076 37.1117 23.3368 37.0899Z"
        fill="white"
      />
      <path
        d="M35.607 29.2364H32.9676C32.8477 29.2364 32.7495 29.1382 32.7495 29.0182V26.3788C32.7495 26.0189 32.4659 25.7354 32.106 25.7354H29.8156C29.4557 25.7354 29.1721 26.0189 29.1721 26.3788V29.0182C29.1721 29.1382 29.074 29.2364 28.954 29.2364H26.3146C25.9547 29.2364 25.6711 29.5308 25.6711 29.8798V32.1702C25.6711 32.5301 25.9656 32.8137 26.3146 32.8137H28.954C29.074 32.8137 29.1721 32.9119 29.1721 33.0318V35.6712C29.1721 36.0311 29.4666 36.3147 29.8156 36.3147H32.106C32.4659 36.3147 32.7495 36.0202 32.7495 35.6712V33.0318C32.7495 32.9119 32.8477 32.8137 32.9676 32.8137H35.607C35.9669 32.8137 36.2505 32.5192 36.2505 32.1702V29.8798C36.2505 29.5308 35.9669 29.2364 35.607 29.2364Z"
        fill="white"
      />
    </svg>
  )
}

export default Created
