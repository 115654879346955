import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@mui/material/IconButton'

function CalendarItem({
  date,
  isActive,
  setActive,
  display,
  isEnd,
  isBetween,
  outsideCurrentMonth,
}) {
  const isRangeStart = isActive && !isEnd
  const isRangeEnd = isActive && isEnd

  const generateBgColor = () => {
    if (isActive) return 'green.main'
    else if (isBetween) return 'green.lightest'

    return undefined
  }

  const bgColor = generateBgColor()

  return (
    <IconButton
      disabled={!date || outsideCurrentMonth}
      onClick={() => setActive(date)}
      sx={{
        backgroundColor: bgColor,
        borderRadius: isBetween ? 0 : 4,
        borderBottomRightRadius: isRangeStart ? 0 : undefined,
        borderTopRightRadius: isRangeStart ? 0 : undefined,
        borderBottomLeftRadius: isRangeEnd ? 0 : undefined,
        borderTopLeftRadius: isRangeEnd ? 0 : undefined,
        color: isActive ? 'white' : 'text.main',
        fontSize: 12,
        height: '90%',
        margin: 'auto',
        transition: !isBetween ? 'border-radius 0.1s ease-out' : undefined,
        width: '100%',
        '&:hover': {
          backgroundColor: 'green.light',
        },
        '&.Mui-disabled': {
          backgroundColor: bgColor,
          color: !date ? 'text.main' : undefined,
        },
      }}
    >
      {display}
    </IconButton>
  )
}

CalendarItem.propTypes = {
  date: PropTypes.instanceOf(Date),
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  display: PropTypes.string,
  isEnd: PropTypes.bool,
  isBetween: PropTypes.bool,
  outsideCurrentMonth: PropTypes.bool,
}

export default CalendarItem
