import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import PostageInvoicesExpandedList from 'components/common/ExpandedList/PostageInvoices'
import PDFViewer from 'components/common/PDFViewer'

import useDataList from 'hooks/useDataList'
import usePostageInvoice from 'hooks/postage/usePostageInvoice'
import useNotification from 'hooks/context/useNotification'

function HeaderLayoutButtons() {
  const { invoiceNumber } = useParams()
  const { setBasicNotification, setError } = useNotification()
  const { downloadInvoice } = usePostageInvoice({})
  const handleDownload = async () => {
    try {
      await downloadInvoice(invoiceNumber)
      setBasicNotification(
        'Invoice has been automatically downloaded to your computer.'
      )
    } catch (err) {
      setError(
        err?.response?.data?.display_message ||
          err?.message ||
          'Unable to download invoice.'
      )
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        height: '100%',
        '& > .MuiButton-root': { my: 'auto' },
        pr: 8,
      }}
    >
      <Button
        size="action-header"
        color="primary"
        variant="contained"
        onClick={() => handleDownload()}
      >
        Download
      </Button>
    </Box>
  )
}

function InvoicePage() {
  const [pdf, setPdf] = useState(null)
  const { invoiceNumber } = useParams()
  const { invoice, getInvoicePdf } = usePostageInvoice({ invoiceNumber })

  const { dataListProps, columns, dataList } = useDataList({
    baseRowData: [invoice],
    presetName: 'postageInvoices',
  })

  useEffect(() => {
    try {
      getInvoicePdf().then((invoicePdf) => {
        setPdf(invoicePdf)
      })
    } catch (err) {
      // pass
    }
  }, [])

  return (
    <Box>
      <Box
        sx={{
          height: '72px',
          background: 'white',
          display: 'flex',
          borderBottom: '1px solid',
          borderColor: 'darkblue.light',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1" sx={{ ml: 8, my: '28px' }}>
          {invoiceNumber && `Invoice #${invoiceNumber ?? ''}`}
        </Typography>
        <HeaderLayoutButtons />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ px: 8, pt: 4, width: '100%' }}>
          <Card sx={{ boxShadow: 2, mb: 3 }}>
            <PostageInvoicesExpandedList
              {...dataListProps}
              expanded
              collapseDetails
              rowData={dataList?.[0]}
              columns={columns}
              visibleColumns={[]}
            >
              <Box sx={{ height: '600px' }}>
                <PDFViewer
                  url={pdf || {}}
                  fileName={`Postage_Invoice_${invoiceNumber}-${Date.now()}.pdf`}
                />
              </Box>
            </PostageInvoicesExpandedList>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

export default InvoicePage
