import { USER_COLORS } from 'utils/constants'

import getRandomInt from 'helpers/node/getRandomInt'

const getUserColor = () => {
  const rand = getRandomInt(USER_COLORS.length)
  return USER_COLORS[rand]
}

export default getUserColor
