import React from 'react'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'

import AddressAutocomplete from 'components/common/AddressAutocomplete'

import useConsumerAccounts from 'hooks/collateral/useConsumerAccounts'
import { DELIVERY_TYPES } from 'utils/constants'
import isValidUSPhone from 'helpers/api/isValidUSPhone'

export const addressKeys = {
  address: 'address_line_1',
  addressalt: 'address_line_2',
  city: 'city',
  state: 'state',
  country: 'country',
  zip: 'zip_code',

  // instance 7102
  language: 'language',
  lob: 'lob',
}

export const optionalKeys = [
  'account_number',
  'address_line_2',

  // instance 7102
  'language',
  'lob',
]

export const validationSchemas = [
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address_line_1: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string().required('ZIP Code is required'),
  }),
  Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    name: Yup.string().required('Name is required'),
  }),
  Yup.object().shape({
    fax_number: Yup.string()
      .required('Fax number is required')
      .test({
        name: 'fax_number',
        skipAbsent: true,
        test(value, ctx) {
          if (!isValidUSPhone(value)) {
            return ctx.createError({
              message: 'Fax number must be a valid 10-digit number',
            })
          }

          return true
        },
      }),
    name: Yup.string().required('Name is required'),
  }),
]

const emailFieldMap = {
  email: 'email',
  contact_name: 'name',
}

function EmailFields({
  excludeAccountNumber,
  bypassAccountCheck,
  requireAccountNumber,
}) {
  const {
    values,
    handleChange,
    errors,
    setValues,
    setFieldError,
    touched,
    handleBlur,
  } = useFormikContext()
  const { fetchConsumerAccounts } = useConsumerAccounts({
    any_id: values?.account_number,
    embed: 'addresses',
  })

  const handleAccountNumberBlur = async (e) => {
    handleBlur(e)

    const responseData = await fetchConsumerAccounts()
    const addresses = responseData?._embedded?.addresses || []

    if (
      responseData?.error &&
      values?.account_number &&
      (responseData?.error?.response?.status !== 404 || !bypassAccountCheck)
    ) {
      setFieldError('account_number', 'Consumer Account Not Found')
      return
    } else {
      setFieldError('account_number', undefined)
    }

    if (addresses.length > 0) {
      const address = addresses[0]
      const entries = Object.entries(emailFieldMap || {})
      const newValues = { ...values }

      entries.forEach(([addrKey, formKey]) => {
        newValues[formKey] = address[addrKey]
      })

      setValues(newValues)
    }
  }

  return (
    <Grid container spacing={6}>
      {!excludeAccountNumber && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="account_number"
            label={`Account Number ${requireAccountNumber ? '' : '(Optional)'}`}
            value={values.account_number || ''}
            onChange={handleChange}
            error={touched.account_number && Boolean(errors.account_number)}
            helperText={touched.account_number && errors.account_number}
            onBlur={handleAccountNumberBlur}
            required={requireAccountNumber}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="name"
          label="Name"
          value={values.name || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="email"
          label="Email"
          value={values.email || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          required
        />
      </Grid>
    </Grid>
  )
}

const faxFieldMap = {
  contact_name: 'name',
}

function FaxFields({
  excludeAccountNumber,
  bypassAccountCheck,
  requireAccountNumber,
}) {
  const {
    values,
    handleChange,
    errors,
    setValues,
    setFieldError,
    touched,
    handleBlur,
  } = useFormikContext()
  const { fetchConsumerAccounts } = useConsumerAccounts({
    any_id: values?.account_number,
    embed: 'addresses',
  })

  const handleAccountNumberBlur = async (e) => {
    handleBlur(e)

    const responseData = await fetchConsumerAccounts()
    const addresses = responseData?._embedded?.addresses || []

    if (
      responseData?.error &&
      values?.account_number &&
      (responseData?.error?.response?.status !== 404 || !bypassAccountCheck)
    ) {
      setFieldError('account_number', 'Consumer Account Not Found')
      return
    } else {
      setFieldError('account_number', undefined)
    }

    if (addresses.length > 0) {
      const address = addresses[0]
      const entries = Object.entries(faxFieldMap || {})
      const newValues = { ...values }

      entries.forEach(([addrKey, formKey]) => {
        newValues[formKey] = address[addrKey]
      })

      setValues(newValues)
    }
  }

  return (
    <Grid container spacing={6}>
      {!excludeAccountNumber && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="account_number"
            label={`Account Number ${requireAccountNumber ? '' : '(Optional)'}`}
            value={values.account_number || ''}
            onChange={handleChange}
            error={touched.account_number && Boolean(errors.account_number)}
            helperText={touched.account_number && errors.account_number}
            onBlur={handleAccountNumberBlur}
            required={requireAccountNumber}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="name"
          label="Name"
          value={values.name || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="fax_number"
          label="Fax number"
          value={values.fax_number || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.fax_number && Boolean(errors.fax_number)}
          helperText={touched.fax_number && errors.fax_number}
          required
        />
      </Grid>
    </Grid>
  )
}

const addressFieldMap = {
  city: 'city',
  contact_name: 'name',
  country: 'country',
  line1: 'address_line_1',
  line2: 'address_line_2',
  state: 'state',
  zip: 'zip_code',

  // instance 7102
  language: 'language',
  lob: 'lob',
}

function AddressFields({
  excludeAccountNumber,
  requireAccountNumber,
  bypassAccountCheck,
}) {
  const {
    values,
    handleChange,
    errors,
    setFieldError,
    setValues,
    touched,
    handleBlur,
  } = useFormikContext()
  const { fetchConsumerAccounts } = useConsumerAccounts({
    any_id: values?.account_number,
    embed: 'addresses',
  })

  const handleAccountNumberBlur = async (e) => {
    handleBlur(e)

    const responseData = await fetchConsumerAccounts()
    const addresses = responseData?._embedded?.addresses || []

    if (
      responseData?.error &&
      values?.account_number &&
      (responseData?.error?.response?.status !== 404 || !bypassAccountCheck)
    ) {
      setFieldError('account_number', 'Consumer Account Not Found')
      return
    } else {
      setFieldError('account_number', undefined)
    }

    if (addresses.length > 0) {
      const address = addresses[0]
      const entries = Object.entries(addressFieldMap || {})
      const newValues = { ...values }

      entries.forEach(([addrKey, formKey]) => {
        newValues[formKey] = address[addrKey]
      })

      setValues(newValues)
    }
  }

  return (
    <Grid container spacing={6}>
      {!excludeAccountNumber && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="account_number"
            label={`Account Number ${requireAccountNumber ? '' : '(Optional)'}`}
            value={values?.account_number || ''}
            onChange={handleChange}
            error={touched.account_number && Boolean(errors.account_number)}
            helperText={touched.account_number && errors.account_number}
            onBlur={handleAccountNumberBlur}
            required={requireAccountNumber}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="name"
          label="Name"
          value={values?.name || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="country"
          label="Country"
          value={values?.country || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.country && Boolean(errors.country)}
          helperText={touched.country && errors.country}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AddressAutocomplete addressKeys={addressKeys} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="address_line_2"
          label="Address Line 2"
          value={values?.address_line_2 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.address_line_2 && Boolean(errors.address_line_2)}
          helperText={touched.address_line_2 && errors.address_line_2}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="city"
          label="City"
          value={values?.city || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.city && Boolean(errors.city)}
          helperText={touched.city && errors.city}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="state"
          label="State"
          value={values?.state || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.state && Boolean(errors.state)}
          helperText={touched.state && errors.state}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="zip_code"
          label="ZIP Code"
          value={values?.zip_code || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.zip_code && Boolean(errors.zip_code)}
          helperText={touched.zip_code && errors.zip_code}
          required
        />
      </Grid>
    </Grid>
  )
}

function AddressForm({
  activeIndex,
  excludeAccountNumber,
  requireAccountNumber,
  bypassAccountCheck,
}) {
  return (
    <>
      {activeIndex === DELIVERY_TYPES.MAIL && (
        <AddressFields
          excludeAccountNumber={excludeAccountNumber}
          requireAccountNumber={requireAccountNumber}
          bypassAccountCheck={bypassAccountCheck}
        />
      )}
      {activeIndex === DELIVERY_TYPES.EMAIL && (
        <EmailFields
          excludeAccountNumber={excludeAccountNumber}
          requireAccountNumber={requireAccountNumber}
          bypassAccountCheck={bypassAccountCheck}
        />
      )}
      {activeIndex === DELIVERY_TYPES.FAX && (
        <FaxFields
          excludeAccountNumber={excludeAccountNumber}
          requireAccountNumber={requireAccountNumber}
          bypassAccountCheck={bypassAccountCheck}
        />
      )}
      {activeIndex === DELIVERY_TYPES.CLAIM_DENIAL && (
        <Card sx={{ p: 14, textAlign: 'center' }}>
          The Claims Denial process does not require an address to be entered in
          this step.
        </Card>
      )}
    </>
  )
}

export default AddressForm
