// don't set a default value for str as we want
// an undefined param to return undefined
const trimString = (str) => {
  if (typeof str === 'string') {
    return str.trim()
  }

  return str
}

export default trimString
