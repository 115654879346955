import React, { useEffect, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import DateRangeDropdown from 'components/common/DateRangeDropdown'

import { DASHBOARD_WIDGETS, COLOR_SHADES, WIDGET_KEYS } from 'utils/constants'

import useWidget from 'hooks/dashboard/useWidget'
import useDashboardContext from 'hooks/context/useDashboardContext'

import { DATE_RANGE_PRESETS } from 'components/common/DateRangePicker/dateRangePresets'
import ItemContainer from './containers/ItemContainer'
import LineChart from 'components/charts/LineChart'
import getGroupedLabels from 'helpers/datetime/getGroupedLabels'

function OrderHistoryItem({ id }) {
  const [datePreset, setDatePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const { refreshCount } = useDashboardContext()
  const { data, loading, mutate } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.ORDER_HISTORY,
    widgetName: id,
    presetId: datePreset,
  })

  const [labels, totals] = useMemo(() => {
    const l = []
    const t = []

    try {
      data.forEach((agg) => {
        l.push(agg.datetime_ran.split(' ')[0])
        t.push(
          agg.aggregated_data.counts.statuses['Approved for Production'] || 0
        )
      })
    } catch (err) {
      // leave chart empty if error
    }

    return [l, t]
  }, [data])

  const displayLabels = useMemo(() => {
    return getGroupedLabels({ labels })
  }, [labels])

  useEffect(() => {
    mutate()
  }, [refreshCount])

  return (
    <ItemContainer
      disablePadding
      widgetKey={WIDGET_KEYS.ORDER_HISTORY_CHART}
      href="/command-center/wip"
      title="Order History"
      centerText=""
      loading={loading}
      titleAction={
        <Box>
          <DateRangeDropdown
            value={datePreset}
            onChange={(e) => setDatePreset(e.target.value)}
          />
        </Box>
      }
    >
      <Stack spacing={4}>
        <Box sx={{ py: 1 }}>
          <Typography fontWeight="bold" fontSize="48px" lineHeight="48px">
            {totals.reduce((a, b) => a + b, 0)}
          </Typography>
          <Typography fontWeight={600} fontSize="14px" lineHeight="14px">
            completed orders
          </Typography>
        </Box>
        <LineChart
          chartData={{
            labels,
            datasets: [
              {
                data: totals,
                fill: true,
                backgroundColor: COLOR_SHADES.GREEN[2],
                borderColor: COLOR_SHADES.GREEN[0],
                tension: 0.5,
              },
            ],
          }}
          options={{
            scales: {
              x: {
                grid: {
                  color: 'white',
                },
                ticks: {
                  color: COLOR_SHADES.LIGHT_GRAY[0],
                  font: {
                    size: 10,
                    family: 'Open Sans',
                  },
                  callback: function (value, index, ticks) {
                    if (displayLabels.length <= 5) {
                      return displayLabels[index]
                    }

                    const mod = displayLabels.length
                    const offset = mod === 5 ? 0 : -1
                    const offsetIndex = index + offset

                    if (offsetIndex % mod !== 0) {
                      return undefined
                    }

                    const i = offsetIndex / mod

                    if (displayLabels[i]) {
                      return displayLabels[i]
                    }

                    return undefined
                  },
                },
              },
              y: {
                grid: {
                  borderDash: [4, 4],
                  color: COLOR_SHADES.LIGHT_GRAY[0],
                },
                ticks: {
                  color: COLOR_SHADES.LIGHT_GRAY[0],
                },
              },
            },
          }}
        />
      </Stack>
    </ItemContainer>
  )
}

export default OrderHistoryItem
