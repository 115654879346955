import React, { useState, useEffect, useMemo, useCallback } from 'react'
import get from 'lodash.get'
import { useSearchParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import DataList from 'components/common/DataList'
import NotesDrawer from 'components/notes/NotesDrawer'
import DocumentApprovalVariant from 'components/notes/NotesDrawer/DocumentApproval'
import Header from './Header'
import Tabs from './Tabs'
import BulkPreview from 'components/common/BulkPreview'
import DocumentApprovalPreview from 'components/common/BulkPreview/components/PreviewContent/DocumentApprovalPreview'
import LetterHistoryModal from './LetterHistoryModal'

import useConfiguration from 'hooks/useConfiguration'
import useNotification from 'hooks/context/useNotification'
import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import {
  useLetters,
  useAllLetters,
  useLetterDocumentNotes,
  useLetterDocument,
} from 'hooks/document_approval'

import { DATALIST_DIMENSIONS } from 'utils/constants'

import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import ExportToCSV from 'components/common/ExportToCSV'
import encodeBase64 from 'helpers/node/encodeBase64'
import deepEqual from 'deep-equal'

const availableStatuses = ['To Review', 'On Hold', 'Rejected', 'Approved']

function DocumentDataList({ baseFilterList = {} }) {
  const [, setSearchParams] = useSearchParams()
  const { currentModule, getMetadataDisplay } = useConfiguration()

  const metaSearchKeys = useMemo(() => {
    const docApproval = currentModule

    const config = docApproval?.configurations.find(
      (c) => c.configuration_type === 'module_configurations'
    )

    if (config)
      return config.settings.available_metadata_fields.map(
        (field) => `_.metadata.${field}`
      )
    return []
  }, [currentModule])

  // datalist
  const [sortList, setSortList] = useState({
    'sort[0]': 'datetime_created:desc',
  })
  const [filterList, _setFilterList] = useState(baseFilterList)
  const setFilterList = useCallback((newFilterList) => {
    const q = encodeBase64(JSON.stringify(newFilterList || {}))
    setSearchParams({
      q: q,
    })
    _setFilterList(newFilterList)
  }, [])

  // sidebar
  const [notesDocument, setNotesDocument] = useState([0, 0])
  const [historyDocument, setHistoryDocument] = useState(0)
  const {
    notes,
    loading: notesLoading,
    makeNote,
  } = useLetterDocumentNotes(notesDocument[0], notesDocument[1])

  // tabs
  const [tabIndex, setTabIndex] = useState(0)

  // documents
  const { getDocument, downloadDocument } = useLetterDocument()

  // notification
  const { setBasicNotification, setError } = useNotification()

  const handleDownload = async (letterId, documentId, showNotif = true) => {
    try {
      const document = await getDocument(letterId, documentId)
      await downloadDocument(letterId, document)

      if (showNotif)
        setBasicNotification(
          `Document ID ${documentId} has been automatically downloaded to your computer.`
        )
    } catch (err) {
      setError(err.response?.data?.display_message || err.message)
    }
  }

  const {
    page,
    setPage,
    pageSize,
    setTotal,
    setTotalPages,
    clearChecked,
    checkedCount,
    initialized,
    setSortedCols,
    setActiveIndex,
    layoutType,
    setLayoutType,
    activeIndex,
  } = useDataListContext()

  const documentsHookParams = {
    'paging[page]': page,
    'paging[page_size]': pageSize,
    status_filter: availableStatuses[tabIndex],
    embed: 'metadata',
    fields: `addresses.to.name|document_id|datetime_created|id|import_order_id|${metaSearchKeys.join(
      '|'
    )}`,
    ...filterList,
    ...sortList,
  }

  const visibleColumns = useMemo(() => {
    return [
      'id',
      'datetime_created',
      'recipient',
      'approved_by',
      'held_by',
      'rejected_by',
      'import_order_id',
      ...metaSearchKeys,
    ]
  }, [metaSearchKeys])

  const [actionButtonProps] = useState([
    {
      children: (
        <>
          <SearchIcon />
          View
        </>
      ),
      color: 'primary',
      variant: 'outlined',
      size: 'action-header',
      sx: {
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: 'hover.main',
        },
      },
      onClick: ({ index }) => {
        setActiveIndex(index)
        setLayoutType('documentapprovalpreview')
      },
    },
    {
      children: (
        <>
          <DownloadIcon />
          Download
        </>
      ),
      color: 'primary',
      variant: 'outlined',
      size: 'action-header',
      sx: {
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: 'hover.main',
        },
      },
      onClick: (props) =>
        handleDownload(props.rowData.id, props.rowData?.document_id),
    },
    {
      children: (
        <>
          <ChatBubbleIcon />
          Notes
        </>
      ),
      color: 'primary',
      variant: 'outlined',
      size: 'action-header',
      sx: {
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: 'hover.main',
        },
      },
      onClick: (props) =>
        setNotesDocument([props?.rowData?.id, props?.rowData?.document_id]),
    },
  ])

  const handleSubmitNotes = async (
    values,
    { setSubmitting, setFieldValue }
  ) => {
    await makeNote(values.note)
    setFieldValue('note', '')
    setSubmitting(false)
  }

  const {
    letters,
    total,
    loading,
    totalPages,
    updateLetters,
    attachLetters,
    updateSearchedLetters,
  } = useLetters(documentsHookParams)

  const prepareRowData = (row) => {
    const rowData = {
      ...row,
      recipient: row.addresses.to.name,
    }

    metaSearchKeys.forEach((key) => {
      const rowKey = key.replace('_', '_embedded')
      rowData[key] = get(row, rowKey)
    })

    return rowData
  }

  const getColumnDisplay = useCallback(
    (col) => {
      const editDisplay = (display) =>
        display
          .replace(/_/gm, ' ')
          .replace(/.metadata./gm, '')
          .toUpperCase()

      const colMappings = {
        status_message: 'Description',
        id: 'Letter ID',
        datetime_created: 'Created Date',
        description: getMetadataDisplay(`_.metadata.Description`),
      }

      return editDisplay(colMappings[col] || col)
    },
    [getMetadataDisplay]
  )

  const newSetSortList = (val) => {
    const newVal = { ...val }

    setSortList(newVal)
  }

  const handleFilterChange = (newFilterList) => {
    setFilterList(newFilterList)

    if (page !== 1) {
      setPage(1)
    }
  }

  const getRowDisplay = useCallback(
    (rowData, columnName) => {
      if (columnName === 'id') {
        return (
          <Box sx={{ display: 'flex' }}>
            <Box
              component="span"
              sx={{
                textDecoration: 'underline',
                color: 'text.primary',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                setHistoryDocument(rowData[columnName])
                e.stopPropagation()
              }}
            >
              {rowData[columnName]}
            </Box>
          </Box>
        )
      } else {
        return rowData[columnName]?.toString() ?? 'N/A'
      }
    },
    [setHistoryDocument]
  )

  const {
    dataList,
    columns,
    dataListProps,
    loading: dataListLoading,
  } = useDataList({
    baseRowData: letters ?? [],
    presetName: 'documentApproval',
    setSortList: newSetSortList,
    prepareRowData,
    getColumnDisplay,
    getRowDisplay,
  })

  const documentsLoading = dataListLoading || loading

  const { getAllLetters, getCsvExport } = useAllLetters(
    {
      ...documentsHookParams,
    },
    !loading && total,
    dataListProps.getDataDisplay,
    getColumnDisplay
  )

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages) // assume total pages updates alongside total
    }
  }, [total, totalPages])

  // resets checked whenever documents changes
  useEffect(() => {
    if (checkedCount > 0) clearChecked()
  }, [page, total, letters])

  useEffect(() => {
    setActiveIndex(0)
    setLayoutType('documentapproval')
    setSortedCols({ datetime_created: { id: 'datetime_created', desc: true } })
  }, [])

  // reset page on tab change
  useEffect(() => {
    if (page !== 1) {
      setPage(1)
    }
  }, [tabIndex])

  useEffect(() => {
    if (!deepEqual(baseFilterList, filterList)) {
      setFilterList(baseFilterList)
    }
  }, [baseFilterList])

  const dataListDisplay = useMemo(() => {
    return (
      <Card sx={{ boxShadow: 2, mb: 2 }}>
        {!documentsLoading && !total && (
          <Box sx={{ px: 4, pt: 2 }}>
            <Typography>Filter results empty</Typography>
          </Box>
        )}
        <BulkPreview
          dataList={dataList}
          dataListProps={dataListProps}
          loading={documentsLoading}
          columns={columns}
          PreviewComponent={DocumentApprovalPreview}
          excludeFromBlur
          defaultText=""
          dataListComponent={
            <DataList
              {...dataListProps}
              dataList={dataList}
              columns={columns}
              loading={documentsLoading}
              validating={initialized}
              visibleColumns={visibleColumns}
              height={DATALIST_DIMENSIONS.TABBED_HEIGHT}
              layoutType={layoutType}
              actionButtonProps={actionButtonProps}
              itemProps={{
                notesDocument,
              }}
            />
          }
        />
      </Card>
    )
  }, [
    dataListProps,
    dataList,
    columns,
    documentsLoading,
    initialized,
    layoutType,
    visibleColumns,
  ])

  return (
    <>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Tabs
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
          layoutType={layoutType}
          setLayoutType={setLayoutType}
          items={letters}
          loading={documentsLoading}
        />
        <Box sx={{ px: 8, pt: 6, width: '100%' }}>
          <Header
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            items={letters}
            getAllItems={getAllLetters}
            updateLetters={updateLetters}
            attachLetters={attachLetters}
            updateSearchedLetters={updateSearchedLetters}
            handleSingleDownload={handleDownload}
            documentsLoading={documentsLoading}
            filterList={filterList}
            setFilterList={handleFilterChange}
            layoutType={layoutType}
            activeIndex={activeIndex}
            total={total}
          />
          {dataListDisplay}
        </Box>
        <ExportToCSV
          getCsvExport={getCsvExport}
          filename={`Document.Approval.${Date.now()}.csv`}
          sx={{ ml: 8 }}
          hide={loading || dataList.length <= 0}
        />
      </Box>
      <NotesDrawer
        open={notesDocument[0] && notesDocument[1]}
        setOpen={() => setNotesDocument([0, 0])}
        NotesDrawerComponent={DocumentApprovalVariant}
        label="Document ID"
        id={notesDocument[1]}
        notes={notes}
        handleSubmit={handleSubmitNotes}
        loading={notesLoading}
      />
      <LetterHistoryModal
        letterId={historyDocument}
        onClose={() => setHistoryDocument(0)}
        open={Boolean(historyDocument)}
      />
    </>
  )
}

export default DocumentDataList
