import React from 'react'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'

import ScrollableBox from 'components/styled/ScrollableBox'

function RemovableItem({ item, index, handleRemove, idKey = 'id' }) {
  return (
    <Box sx={{ display: 'flex', height: '72px', width: '248px', mr: 7 }}>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'darkgray.light',
          borderRadius: '8px',
          display: 'flex',
          height: '100%',
          width: '208px',
          color: 'darkgray.main',
          textAlign: 'center',
        }}
      >
        <Box sx={{ my: 'auto', width: '100%' }}>
          <Typography sx={{ textDecoration: 'capitalize' }}>
            {item.display}
          </Typography>
          <Typography>ID {item[idKey]}</Typography>
        </Box>
      </Box>
      <IconButton sx={{ my: 'auto' }} onClick={() => handleRemove(index)}>
        <CancelPresentationIcon color="primary" />
      </IconButton>
    </Box>
  )
}

function RemovableItems({ items, handleRemove, title, idKey = 'id', max = 0 }) {
  return (
    <>
      {title && (
        <Typography variant="h2" fontWeight="medium" mb={6}>
          {title}
        </Typography>
      )}
      <ScrollableBox
        sx={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {max === 0 || items.length <= max ? (
            items.map((item, index) => (
              <RemovableItem
                key={`resend-item-${item.id}`}
                item={item}
                index={index}
                handleRemove={handleRemove}
                idKey={idKey}
              />
            ))
          ) : (
            <></>
          )}
        </Box>
      </ScrollableBox>
    </>
  )
}

export default RemovableItems
