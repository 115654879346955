import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from './Modal'

function LoadingModal({ percentage = 0, ...props }) {
  return (
    <Modal
      title="Executing Action..."
      disableEscapeKeyDown
      onBackdropClick={() => {}}
      {...props}
      onClose={() => {}}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          justifyContent: 'space-around',
        }}
      >
        <Typography mb={8}>
          This may take a while. Do not close this page while this action is
          executing.
        </Typography>
        <CircularProgress sx={{ mx: 'auto' }} />
      </Box>
    </Modal>
  )
}

export default LoadingModal
