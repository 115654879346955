import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import axios from 'utils/axios'
import { APP_KEY } from 'utils/constants'
import fetcher from 'utils/fetcher'

const useUserNotes = (userId, params) => {
  const { data, isValidating, mutate } = useSWR(
    userId
      ? `/users/${userId}/notes?${urlSerialize({
          app_key: APP_KEY,
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const addNote = async (userId, content) => {
    let resp = await axios.post(`/users/${userId}/notes`, {
      note: content,
    })

    mutate()

    return resp.data
  }

  const deleteNote = async (userId, noteId) => {
    const {
      headers: { etag: noteEtag },
    } = await axios.head(`/users/${userId}/notes/${noteId}`)

    await axios.delete(`/users/${userId}/notes/${noteId}`, {
      headers: {
        'If-Match': `"${noteEtag}"`,
      },
    })

    mutate()
  }

  const listNotes = async ({ id = userId }) => {
    await axios.get(`/users/${id}/notes`)

    mutate()
  }

  const editNote = async (userId, noteId, content) => {
    const {
      headers: { etag: noteEtag },
    } = await axios.head(`/users/${userId}/notes/${noteId}`)

    await axios.patch(
      `/users/${userId}/notes/${noteId}`,
      {
        note: content,
      },
      {
        headers: {
          'If-Match': `"${noteEtag}"`,
        },
      }
    )

    mutate()
  }

  return {
    notes: data?._embedded?.notes ?? [],
    loading: (!data?._embedded?.notes && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
    listNotes,
    editNote,
    addNote,
    deleteNote,
  }
}

export default useUserNotes
