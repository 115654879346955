import React, { useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'
import axios from 'utils/axios'

import AlertTitle from '@mui/material/AlertTitle'
import MuiAlert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import MuiTextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import spacing from '@mui/system/spacing'

import useAuth from 'hooks/useAuth'
import useTheme from 'hooks/useTheme'

const Alert = styled(MuiAlert)(spacing)

const TextField = styled(MuiTextField)(spacing)

function SSO({ shortName }) {
  const [searchParams] = useSearchParams()
  const formikRef = useRef(null)
  const { logIn } = useAuth()
  const [disableButtons, setDisableButtons] = useState(false)
  const {
    texts: { login_help },
  } = useTheme()

  const [queryError] = useState(searchParams.get('errors'))
  const [requirePassword, setRequirePassword] = useState(false)

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    const { username, password } = values
    try {
      if (!requirePassword) {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/${shortName}/sso-email-validations`,
          { username }
        )

        if (res.data?.requires_sso_login) {
          window.location.href = `${process.env.REACT_APP_BASE_URL}/${shortName}/sso-sessions`
        } else {
          setRequirePassword(true)
        }
      } else {
        await logIn({ username, password, instanceId: shortName })
      }
    } catch (error) {
      const message =
        error.response?.data?.display_message ||
        'Please check your username and password and try again.'

      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
      setDisableButtons(false)
    }
  }

  useEffect(() => {
    if (formikRef?.current) {
      const { setValues, values } = formikRef.current
      setValues({
        ...values,
        instanceId: shortName,
      })
    }
  }, [shortName, formikRef])

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{
        username: process.env.REACT_APP_USERNAME || '',
        password: process.env.REACT_APP_PASSWORD || '',
        twoFactorCode: '',
        instanceId: shortName,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password:
          requirePassword && Yup.string().required('Password is required'),
        instanceId: Yup.string().required(
          'Instance shortname is required in URL'
        ),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography mb={2}>Please Log In to continue</Typography>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              <AlertTitle>Unable to log in</AlertTitle>
              {errors.submit}
            </Alert>
          )}
          {queryError && (
            <Alert mt={2} mb={3} severity="warning">
              <AlertTitle>{queryError}</AlertTitle>
            </Alert>
          )}
          <TextField
            type="username"
            name="username"
            label="Username"
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            disabled={disableButtons || !shortName || requirePassword}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            disabled={!shortName}
            sx={{ display: !requirePassword && 'none' }}
          />
          <Box
            sx={{
              textAlign: 'center',
              color: 'error.main',
            }}
          >
            {errors.instanceId}
          </Box>
          <Stack spacing={4} sx={{ flex: 1 }}>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                LinkComponent={Link}
                to={`/auth/password-reset/${shortName}`}
                disabled={!shortName}
              >
                Forgot your password?
              </Button>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row-reverse',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: '50%', mx: 'auto' }}
                disabled={disableButtons || !shortName || isSubmitting}
              >
                Log In
              </Button>
              {requirePassword && (
                <Button
                  type="buton"
                  variant="outlined"
                  color="primary"
                  sx={{ width: '40%', mx: 'auto' }}
                  disabled={disableButtons || !shortName || isSubmitting}
                  onClick={() => setRequirePassword(false)}
                >
                  Cancel
                </Button>
              )}
            </Box>
          </Stack>
          <Box sx={{ width: '100%', display: 'flex', textAlign: 'center' }}>
            <Typography
              fontSize={12}
              color="darkgray.main"
              sx={{ width: '50%', mx: 'auto' }}
            >
              {login_help}
            </Typography>
          </Box>
        </Box>
      )}
    </Formik>
  )
}

export default SSO
