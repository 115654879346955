import React, { useState, useMemo, useEffect } from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'

import ScrollableBox from 'components/styled/ScrollableBox'
import FullBox from 'components/styled/FullBox'

import useFixedTemplateCategories from 'hooks/collateral/useFixedTemplateCategories'
import useCollateralTemplateCategories from 'hooks/collateral/useCollateralTemplateCategories'
import useNotification from 'hooks/context/useNotification'
import useCollateralContext from 'hooks/collateral/useCollateralContext'
import isEmptyObject from 'helpers/node/isEmptyObject'

function TemplateListItem({
  template,
  updateTemplate,
  setError,
  cart,
  setActiveTemplate,
  activeTemplate,
}) {
  const { favorited, setFavorited } = useCollateralContext()

  const handleToggleFavorite = async () => {
    const isFavorited = favorited[template.id]

    setFavorited({
      ...favorited,
      [template.id]: !isFavorited,
    })

    try {
      await updateTemplate(
        template.id,
        {
          is_favorite: !isFavorited,
        },
        true
      )
    } catch (err) {
      setFavorited({
        ...favorited,
        [template.id]: isFavorited,
      })
      setError('Failed to favorite template')
    }
  }

  const handleClick = () => {
    const cartItem = cart.find((item) => item.id === template.id)
    const newTemplate = cartItem ? cartItem.template : template

    setActiveTemplate(newTemplate)
  }

  const starButton = useMemo(() => {
    return (
      <IconButton
        size="small"
        title={
          favorited[template.id] ? 'Remove from Favorites' : 'Add to Favorites'
        }
        onClick={(e) => {
          handleToggleFavorite()
          e.stopPropagation()
        }}
      >
        {favorited[template.id] ? (
          <StarIcon sx={{ color: 'yellow.main' }} />
        ) : (
          <StarBorderIcon />
        )}
      </IconButton>
    )
  }, [favorited])

  return (
    <ListItemButton
      sx={{
        p: 0,
        m: 0,
        pl: 4,
        bgcolor: activeTemplate?.id === template.id ? 'hover.main' : undefined,
      }}
      onClick={() => handleClick()}
    >
      {starButton}
      <Typography fontSize={16} color="darkgray.main">
        {template.name}
      </Typography>
    </ListItemButton>
  )
}

function CategoryListItem({
  category,
  index,
  expandedIndex,
  setExpandedIndex,
  updateTemplate,
  setError,
  cart,
  activeTemplate,
  setActiveTemplate,
}) {
  const expanded = expandedIndex === index

  return (
    <>
      <ListItemButton
        sx={{
          borderLeft: '4px solid',
          borderColor: expanded ? 'green.main' : 'white',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
        onClick={() => setExpandedIndex(expanded ? -1 : index)}
      >
        <Typography
          fontSize={16}
          color="textPrimary"
          sx={{ textTransform: 'uppercase' }}
        >
          {category.name}
        </Typography>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={expanded}>
        <Box>
          <List disablePadding>
            {category._embedded.templates.map((template, templateIndex) => (
              <TemplateListItem
                key={template.id}
                template={template}
                index={templateIndex}
                updateTemplate={updateTemplate}
                setError={setError}
                cart={cart}
                setActiveTemplate={setActiveTemplate}
                activeTemplate={activeTemplate}
              />
            ))}
          </List>
        </Box>
      </Collapse>
    </>
  )
}

function Categories({ activeTemplate, setActiveTemplate, cart }) {
  const [expandedIndex, setExpandedIndex] = useState(-1)
  const { setError } = useNotification()
  const { categories, loading } = useFixedTemplateCategories()
  const { updateTemplate } = useCollateralTemplateCategories()
  const { favorited, setFavorited } = useCollateralContext()

  const favoritedCategory = useMemo(() => {
    const favoriteCategory = { name: 'Favorite', _embedded: { templates: [] } }

    // aggregate all favorited templates into a single category
    favoriteCategory._embedded.templates = categories.flatMap((category) =>
      category._embedded.templates.filter((template) => favorited[template.id])
    )

    return favoriteCategory
  }, [categories, favorited])

  useEffect(() => {
    const newFavorited = {}

    if (isEmptyObject(favorited)) {
      categories.forEach((category) => {
        category._embedded.templates.forEach((template) => {
          newFavorited[template.id] = template.is_favorite
        })
      })

      setFavorited(newFavorited)
    }
  }, [categories])

  return (
    <ScrollableBox sx={{ height: '100%', overflow: 'scroll' }}>
      <List disablePadding sx={{ pt: 3 }}>
        {loading ? (
          <FullBox sx={{ display: 'flex' }}>
            <CircularProgress sx={{ mx: 'auto' }} />
          </FullBox>
        ) : (
          <>
            <CategoryListItem
              category={favoritedCategory}
              expandedIndex={expandedIndex}
              setExpandedIndex={setExpandedIndex}
              updateTemplate={updateTemplate}
              setError={setError}
              cart={cart}
              activeTemplate={activeTemplate}
              setActiveTemplate={setActiveTemplate}
            />
            {categories.map((category, index) => (
              <CategoryListItem
                key={category.name}
                category={category}
                index={index}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                updateTemplate={updateTemplate}
                setError={setError}
                cart={cart}
                activeTemplate={activeTemplate}
                setActiveTemplate={setActiveTemplate}
              />
            ))}
          </>
        )}
      </List>
    </ScrollableBox>
  )
}

export default Categories
