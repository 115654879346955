import React, { useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components/macro'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import OrderHistoryRow from './Item/OrderHistory'
import { DATALIST_DIMENSIONS } from 'utils/constants'

import Button from '@mui/material/Button'

const StyledInfiniteScroll = styled(InfiniteScroll)`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.darkgray.light};
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
`

const ScrollableBox = styled(Box)`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.darkgray.light};
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
`

function TableColumn({ col, sortList, setSortList, sx }) {
  const handleColumnClick = useCallback(
    (col) => {
      // alternate between not sorted, asc, and desc
      if (!sortList['sort[0]'] || !sortList['sort[0]'].includes(col)) {
        setSortList({ 'sort[0]': `${col}:asc` })
      } else if (sortList['sort[0]'] === `${col}:asc`) {
        setSortList({ 'sort[0]': `${col}:desc` })
      } else {
        setSortList({})
      }
    },
    [sortList, setSortList]
  )

  if (!col) return <></>

  const re = new RegExp(`^${col.id}:`)

  const sharedSx = {
    backgroundColor: 'white',
    color: 'primary.main',
    fontWeight: 'bold',
    fontSize: '16px',
    ...sx,
  }

  if (col.sortable) {
    const active = sortList && sortList['sort[0]']?.match(re)
    return (
      <TableCell sx={sharedSx}>
        <TableSortLabel
          active={active}
          direction={active ? sortList?.['sort[0]']?.split(':')?.[1] : 'asc'}
          onClick={() => handleColumnClick(col.id)}
        >
          {col.display}
        </TableSortLabel>
      </TableCell>
    )
  }

  return <TableCell sx={sharedSx}>{col.display}</TableCell>
}

function DataListTable({
  id = 'scrollable-table',
  children,
  dataList,
  rowKey = 'id', // unique identifier on the row
  columns,
  getDataDisplay,
  sortList,
  setSortList,
  visibleColumns = [], // arr of col ids
  loading,
  pageSize = 20,
  getCsvExport,
  extraColumns = null,
  infiniteScroll = false,
  infiniteScrollProps = {},
  wrapperProps = {},
  total = 0,
  additionalLoading = false,
  RowComponent = OrderHistoryRow,
  rowProps = {},
  height = DATALIST_DIMENSIONS.HEIGHT,
  colProps = {},
  minWidth = 650,
  maxWidth = undefined,
  isPreview = false,
  ...props
}) {
  const { next } = infiniteScrollProps

  const Component = infiniteScroll ? StyledInfiniteScroll : ScrollableBox
  const componentProps = infiniteScroll
    ? {
        height: height,
        hasMore: true,
        ...infiniteScrollProps,
      }
    : {
        ...wrapperProps,
        sx: {
          height: height,
          ...wrapperProps?.sx,
        },
      }

  return (
    <TableContainer
      id={id}
      component={Component}
      height={height}
      loader={
        additionalLoading && (
          <Box
            sx={{
              display: 'flex',
              height: '64px',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <CircularProgress sx={{ m: 'auto' }} />
          </Box>
        )
      }
      {...componentProps}
    >
      <Table
        stickyHeader
        size="small"
        sx={{
          minWidth,
          maxWidth,
          overflow: 'auto',
        }}
        aria-label={id}
      >
        {Boolean(columns?.length) && (
          <TableHead>
            <TableRow>
              {visibleColumns.map((col) => (
                <TableColumn
                  key={col}
                  col={columns.find((c) => c.id === col)}
                  sortList={sortList}
                  setSortList={setSortList}
                  {...colProps}
                />
              ))}
              {extraColumns}
            </TableRow>
          </TableHead>
        )}
        {!loading && (
          <TableBody>
            {infiniteScroll && children}
            {!infiniteScroll &&
              dataList.map((row, rowIndex) => (
                <RowComponent
                  key={row[rowKey]}
                  rowKey={rowKey}
                  row={row}
                  getDataDisplay={getDataDisplay}
                  columns={columns}
                  visibleColumns={visibleColumns}
                  index={rowIndex}
                  {...rowProps}
                />
              ))}
            {!loading && !total && (
              <TableRow>
                <TableCell
                  colSpan={12}
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  No results found
                </TableCell>
              </TableRow>
            )}
            {Boolean(
              infiniteScroll &&
                !loading &&
                !additionalLoading &&
                infiniteScrollProps?.hasMore
            ) && (
              <TableRow>
                <TableCell
                  colSpan={12}
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    borderRight: isPreview && '1px solid',
                    borderRightColor: isPreview && 'lightgray.main',
                  }}
                >
                  <Button onClick={() => next?.()}>Load More</Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {loading && (
        <Box sx={{ height: '600px', display: 'flex' }}>
          <CircularProgress sx={{ m: 'auto' }} />
        </Box>
      )}
    </TableContainer>
  )
}

export default DataListTable
