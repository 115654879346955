import { createContext, useReducer } from 'react'

import Modal from 'components/common/Modal'

const SET_OPEN = 'SET_OPEN'
const SET_MODAL_PROPS = 'SET_MODAL_PROPS'
const SET_COMPONENT = 'SET_COMPONENT'

const initialState = {
  open: false,
  modalProps: null,
  Component: Modal,
}

const ModalReducer = (state, action) => {
  switch (action.type) {
    case SET_OPEN:
      return {
        ...state,
        open: action.payload.open,
        Component: !action.payload.open ? Modal : state.Component,
      }
    case SET_MODAL_PROPS:
      return {
        ...state,
        modalProps: action.payload.modalProps,
      }
    case SET_COMPONENT:
      return {
        ...state,
        Component: action.payload.Component,
      }
    default:
      return state
  }
}

const ModalContext = createContext(null)

function ModalProvider({ children }) {
  const [state, dispatch] = useReducer(ModalReducer, initialState)

  const setOpen = (open) => dispatch({ type: SET_OPEN, payload: { open } })
  const setModalProps = (modalProps) =>
    dispatch({ type: SET_MODAL_PROPS, payload: { modalProps } })
  const setComponent = (Component) =>
    dispatch({ type: SET_COMPONENT, payload: { Component } })

  const handleClose = () => {
    dispatch({ type: SET_OPEN, payload: { open: false } })
  }

  return (
    <ModalContext.Provider
      value={{
        ...state,
        setOpen,
        setModalProps,
        setComponent,
      }}
    >
      {children}
      {state.modalProps && (
        <state.Component
          {...state.modalProps}
          open={state.open}
          onClose={handleClose}
        />
      )}
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider }
