import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

function OrderApprovalTabs({ setTabIndex, tabIndex, tabsObj }) {
  const handleChange = (_event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
      <Box
        sx={{
          bgcolor: 'white',
          height: '72px',
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
        }}
      />
      <Grid
        container
        sx={{
          height: '72px',
          width: '100%',
          minWidth: '1024px',
        }}
      >
        <Grid item md={6} lg={5}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="order approval tabs"
            sx={{ ml: 8, mt: 6, minWidth: '420px' }}
          >
            <Tab label={`TO REVIEW (${tabsObj[0].total})`} />
            <Tab label={`ON HOLD (${tabsObj[1].total})`} />
            <Tab label={`PROCESSING (${tabsObj[2].total})`} />
          </Tabs>
        </Grid>
      </Grid>
      <Box
        fullWidth
        sx={{
          height: '1px',
          bgcolor: 'darkblue.main',
          opacity: 0.12,
        }}
      />
    </Box>
  )
}

export default OrderApprovalTabs
