import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import Button from 'components/common/PopoverButton'
import Grid from '@mui/material/Grid'

import clampTextWidth from 'helpers/css/clampTextWidth'

const ActionButton = styled(Button)`
  border-radius: ${({ footer }) => (footer ? '8px' : '24px')};
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
`

const maxTextWidth = 78

function ActionButtons({ actionProps, footer }) {
  const adjustedActionProps = actionProps

  const getDefaultProps = (actionProp) => {
    const { active } = actionProp
    const props = {}
    props.size = footer ? 'action-footer' : 'action-header'

    if (!footer) {
      props.color = active ? 'primary' : 'disabled'
      props.variant = 'contained'
    }

    return props
  }

  const clampContent = (content) => {
    const { clamped } = clampTextWidth(content, maxTextWidth)
    let title = ''

    if (clamped !== content) title = content

    return {
      children: clamped,
      title,
    }
  }

  return (
    <Grid
      container
      columns={12}
      rowSpacing={2}
      columnSpacing={8}
      sx={{
        flexDirection: footer ? 'row-reverse' : 'row',
        float: footer ? 'right' : undefined,
        height: '100%',
      }}
    >
      {adjustedActionProps
        .filter((actionProp) => !actionProp.hidden)
        .map((actionProp, index) => (
          <Grid
            item
            sx={{ display: 'flex' }}
            key={`${actionProp.children}-${index}`}
          >
            <ActionButton
              footer={footer && 'true'}
              xs={2}
              {...getDefaultProps(actionProp)}
              {...actionProp}
              {...clampContent(actionProp.children)}
            />
          </Grid>
        ))}
    </Grid>
  )
}

ActionButtons.propTypes = {
  actionProps: PropTypes.arrayOf(PropTypes.object),
  footer: PropTypes.bool,
}

export default ActionButtons
