import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

import useConfiguration from 'hooks/useConfiguration'
import { PRIVILEGES } from 'utils/constants'

const Tab = styled(MuiTab)`
  min-width: 160px;
`

function ReportingTabs({ tabIndex, setTabIndex }) {
  const { hasPrivilege } = useConfiguration()

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
      <Box
        sx={{
          bgcolor: 'white',
          height: '72px',
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
        }}
      />
      <Box
        sx={{
          height: '72px',
          width: '100%',
          minWidth: '1024px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Tabs
            value={tabIndex}
            onChange={(_e, value) => setTabIndex(value)}
            aria-label="reporting tabs"
            sx={{ ml: 8, mt: 6, minWidth: '420px' }}
          >
            <Tab fullWidth label="SYSTEM REPORTS" />
            {hasPrivilege(PRIVILEGES.USER_REPORTS)
              ? [
                  <Tab fullWidth label="PRIVATE USER REPORTS" />,
                  <Tab fullWidth label="SHARED USER REPORTS" />,
                ]
              : ''}
          </Tabs>
        </Box>
        {hasPrivilege(PRIVILEGES.USER_REPORTS) ? (
          <Box sx={{ height: '100%', display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                height: '50%',
                width: '100%',
                my: 'auto',
                '& > *': { my: 'auto' },
                justifyContent: 'flex-end',
                justifySelf: 'flex-end',
                mr: 10,
              }}
            >
              <Button
                color="primary"
                variant="contained"
                sx={{ my: 'auto' }}
                LinkComponent={Link}
                to="/command-center/reporting/create"
              >
                + Add New Report
              </Button>
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box
        fullWidth
        sx={{
          height: '1px',
          bgcolor: 'darkblue.main',
          opacity: 0.12,
        }}
      />
    </Box>
  )
}

export default ReportingTabs
