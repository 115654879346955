import React from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'

import useLetterStatusHistory from 'hooks/document_approval/useLetterStatusHistory'

import { useUserData } from 'hooks/users'

function LetterHistoryModal({
  open,
  onClose,
  letterId,
  title = 'Detailed Status History',
}) {
  const { formatUserTimeZone } = useUserData()
  const { statuses, loading } = useLetterStatusHistory(letterId)

  const modalProps = {
    title,
    subTitle: undefined,
    scrollable: true,
    size: undefined,
    height: '335px',
    width: '732px',
    hideButtonsBorder: false,
    footerButtonProps: [
      {
        children: 'Close',
        color: 'primary',
        variant: 'contained',
        onClick: () => onClose(),
        size: 'action-header',
      },
    ],
  }

  function HistoryRow({ date, status, userDisplay, index }) {
    return (
      <Grid
        container
        sx={{
          minHeight: '32px',
          py: 1,
          borderBottom: index < statuses.length - 1 && '1px solid',
          borderColor: 'lightgray.main',
        }}
        columnSpacing={4}
      >
        <Grid item xs={5}>
          <Typography fontWeight={index === -1 && 'bold'}>{date}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={index === -1 && 'bold'}>{status}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={index === -1 && 'bold'}>
            {userDisplay}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Modal {...modalProps} open={open} onClose={onClose}>
      <Box sx={{ pt: 2 }}>
        {loading && letterId ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : statuses.length > 0 ? (
          <>
            <HistoryRow
              date={'DATE'}
              status={'STATUS'}
              userDisplay={'USER'}
              index={-1}
            />
            {statuses.map((item, index) => (
              <HistoryRow
                date={formatUserTimeZone(item.datetime_set)}
                status={item.status}
                userDisplay={item.user_detail || item.source}
                index={index}
              />
            ))}
          </>
        ) : (
          <Typography>No history found.</Typography>
        )}
      </Box>
    </Modal>
  )
}

export default LetterHistoryModal
