import { useContext } from 'react'
import { CollateralContext } from 'contexts/collateral/CollateralContext'

const useCollateralContext = () => {
  const context = useContext(CollateralContext)

  if (!context)
    throw new Error(
      'CollateralContext must be placed within CollateralProvider'
    )

  return context
}

export default useCollateralContext
