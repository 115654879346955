import React from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ItemForm from './ItemForm'
import PDFViewer from 'components/common/PDFViewer'
import FullBox from 'components/styled/FullBox'

import useCollateralContext from 'hooks/collateral/useCollateralContext'

import RefreshIcon from '@mui/icons-material/Refresh'

const PdfPreview = ({ previewPdf, setPdfLoading, pdfLoading }) => {
  const { activeTemplate } = useCollateralContext()

  return (
    <Box sx={{ flex: 1 }}>
      {pdfLoading ? (
        <FullBox sx={{ display: 'flex' }}>
          <CircularProgress sx={{ mx: 'auto' }} />
        </FullBox>
      ) : previewPdf ? (
        <PDFViewer
          url={previewPdf}
          fileName={`${activeTemplate?.name || 'sample'}.pdf`}
          hideLoader
          onLoadFinished={() => setPdfLoading(false)}
        />
      ) : (
        <FullBox></FullBox>
      )}
    </Box>
  )
}

function ActiveTemplatePreview({
  cart,
  addToCart,
  handleBlur,
  handleFocus,
  handleChange,
  previewFormData,
  previewFormTimeout,
  generateFormData,
  parseCart,
}) {
  const {
    activeTemplate,
    previewPdf,
    setPdfLoading,
    pdfLoading,
    fetchPreviewPdf,
  } = useCollateralContext()

  const disableRefresh =
    !previewFormData || !activeTemplate?.should_render_preview

  return (
    <>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Typography variant="h1">{activeTemplate?.name}</Typography>
        <IconButton
          color="primary"
          sx={{ my: 'auto' }}
          title="Refresh Preview"
          disabled={disableRefresh}
          onClick={() => {
            clearTimeout(previewFormTimeout)
            setPdfLoading(true)
            fetchPreviewPdf(previewFormData, activeTemplate)
          }}
        >
          <RefreshIcon color={!disableRefresh ? 'primary' : 'secondary'} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flex: 1,
          height: 'calc(100% - 32px)',
        }}
      >
        {activeTemplate?.should_render_preview && (
          <PdfPreview
            previewPdf={previewPdf}
            setPdfLoading={setPdfLoading}
            pdfLoading={pdfLoading}
          />
        )}
        <Box
          sx={{
            width: !activeTemplate?.should_render_preview ? '100%' : undefined,
          }}
        >
          <ItemForm
            cart={cart}
            activeTemplate={activeTemplate}
            addToCart={addToCart}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            handleChange={handleChange}
            loading={pdfLoading}
            generateFormData={generateFormData}
            parseCart={parseCart}
          />
        </Box>
      </Box>
    </>
  )
}

export default ActiveTemplatePreview
