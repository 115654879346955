import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedList from 'components/common/ExpandedList'
import DocumentApprovalExpandedList from 'components/common/ExpandedList/DocumentApprovalList'

// local components
import ItemCheckBox from './components/ItemCheckbox'
import HoverButtons from './components/HoverButtons'
import FixedButtons from './components/FixedButtons'

import useDataListContext from 'hooks/context/useDataListContext'

import { COLORS } from 'utils/constants'
import DataListItemWrapper from './components/DataListItemWrapper'

const StyledDataListItem = styled(Box)`
  .hover-buttons {
    display: none;
  }

  &:hover {
    .hover-buttons {
      ${({ showButtons }) => showButtons && 'display: block;'}
    }
  }
`

function DocumentApprovalItem(props) {
  const {
    index,
    rowData,
    columns,
    fixedButtonProps = [],
    getDataDisplay,
    multiSelect,
    statusColName,
    preview,
    expandedListProps,
    visibleColumns,
    notesDocument,
    sharedSx,
  } = props
  const [checked, setChecked] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [status, setStatus] = useState({ color: COLORS.GREEN })
  const { activeIndex, setActiveIndex } = useDataListContext()
  const darken = expanded || checked || notesDocument === rowData.document_id

  const shownColumns = visibleColumns
    .map((col) => columns.find((c) => c.id === col))
    .filter((c) => c)
  const adjustedShowCount = preview ? 2 : shownColumns.length
  const gridColumns = (multiSelect ? 1 : 0) + adjustedShowCount * 2

  const { color } = status ?? { color: 'green.main' }
  const checkboxColor = 'success'
  const showButtons = !expanded

  const getBorderColor = () => {
    if (darken) return color

    return 'background.paper'
  }

  const toggleExpanded = (expand) => {
    if (expanded === expand) return

    if (expand && activeIndex !== index) {
      setExpanded(true)
      setActiveIndex(index)
    } else if (!expand && activeIndex === index) {
      setExpanded(false)
    } else {
      setExpanded(true)
    }
  }

  useEffect(() => {
    if (rowData?.[statusColName]) {
      setStatus(rowData[statusColName])
    }
  }, [rowData])

  useEffect(() => {
    // collapse if not selected
    if (index !== activeIndex) {
      setExpanded(false)
    }
  }, [activeIndex])

  function CheckBox() {
    const { allChecked, checkedCount } = useDataListContext()

    useEffect(() => {
      if (allChecked === true) setChecked(true)
      if (checkedCount === 0) setChecked(false)
    }, [allChecked, checkedCount])

    return (
      <>
        {multiSelect && (
          <ItemCheckBox
            index={index}
            handleItemCheck={setChecked}
            color={checkboxColor}
            checkedState={checked}
          />
        )}
      </>
    )
  }

  return (
    <StyledDataListItem
      showButtons={showButtons}
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        borderBottomColor: 'lightgray.main',
        bgcolor: darken && 'hover.main',
        color: 'darkgray.main',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ListItem
        secondaryAction={
          <>
            <IconButton
              edge="end"
              aria-label="expand"
              onClick={(e) => {
                e.stopPropagation()
                toggleExpanded(!expanded)
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </>
        }
        sx={{
          display: 'flex',
          overflow: 'hidden',
          my: 'auto',
          width: '100%',
          '&:hover': {
            borderColor: expanded && 'hover.main',
            bgcolor: expanded && 'hover.main',
          },
        }}
      >
        <Box
          columns={gridColumns}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <DataListItemWrapper index={0}>
            <Box>
              <CheckBox />
            </Box>
          </DataListItemWrapper>
          {shownColumns.map((col, colIndex) => (
            <DataListItemWrapper key={col.id} index={colIndex + 1}>
              <Box
                sx={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  flexDirection: 'column',
                  color: col.id === statusColName && color,
                }}
              >
                <Box
                  sx={{
                    marginY: 'auto',
                    fontSize: 14,
                  }}
                >
                  {getDataDisplay(rowData, col.id, statusColName)}
                </Box>
              </Box>
            </DataListItemWrapper>
          ))}
        </Box>
        <HoverButtons {...props} />
        {fixedButtonProps.length > 0 && <FixedButtons {...props} />}
      </ListItem>
      <ExpandedList
        {...expandedListProps}
        expanded={expanded}
        setExpanded={toggleExpanded}
        columns={Object.keys(rowData?._embedded?.metadata ?? {}).map((key) => ({
          id: key,
          display: key,
        }))}
        rowData={rowData}
        visibleColumns={visibleColumns}
        getDataDisplay={getDataDisplay}
        ExpandedListComponent={DocumentApprovalExpandedList}
        hoverButtons={<HoverButtons expanded {...props} />}
      />
    </StyledDataListItem>
  )
}

export default DocumentApprovalItem
