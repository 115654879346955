import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import { useFormikContext } from 'formik'
import format from 'date-fns/format'
import parse from 'date-fns/parse'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import EditIcon from '@mui/icons-material/Edit'

import splitByPascalCase from 'helpers/node/splitByPascalCase'
import getLongestTextWidth from 'helpers/css/getLongestTextWidth'
import trimString from 'helpers/node/trimString'

import Autocomplete from 'components/common/Autocomplete'

import {
  useDocumentInboundCategories,
  useDocumentInboundStatuses,
} from 'hooks/digital_mail'
import useAllUsers from 'hooks/users/useAllUsers'
import {
  DOCUMENT_TYPE_DESCRIPTIONS,
  FOLDER_NUM_TEXT_DESCRIPTIONS,
  MODULE_NAMES,
} from 'utils/constants'
import DateRangePicker from 'components/common/DateRangePicker'
import useFieldDefinitions from 'hooks/instance/useFieldDefinitions'
import useInstance from 'hooks/instance/useInstance'

const doEdit = [
  'status',
  'category',
  'assigned_to',
  'account_number',
  'tracking_number',
  'member_id',
  'Drawer',
  'FileNumber',
  'FolderNumber',
  'DocumentType',
  'PageDescription',
]

const editableWhenEmpty = [
  'tracking_number',
  'OriginalDocument',
  'PhysicalItemDescription'
]

function CustomInput(props) {
  const [value, setValue] = useState(props.value)

  const handleChange = useCallback((e) => {
    setValue(e.target.value)
  }, [])

  const handleBlur = useCallback(() => {
    props.onChange({ target: { name: props.name, value } })
  }, [value, props])

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value)
    }
  }, [props])

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

function CustomSelect({ id, options = [], editing, toggleEditing }) {
  const { values, handleChange, setFieldValue } = useFormikContext()
  const isDisabled = !Boolean(options?.length)

  const getOptionDisplay = useCallback(
    (option) => {
      const folderNumberDesc = FOLDER_NUM_TEXT_DESCRIPTIONS[option]

      if (id === 'FolderNumber' && folderNumberDesc) {
        return `${option} - ${folderNumberDesc}`
      }

      const docTypeDesc =
        DOCUMENT_TYPE_DESCRIPTIONS[values.FolderNumber]?.[option]
      if (id === 'DocumentType' && docTypeDesc) {
        return `${option} - ${docTypeDesc}`
      }

      return option
    },
    [values]
  )

  const handleUpdateChange = useCallback(
    (e) => {
      if (id === 'FolderNumber') {
        setFieldValue('DocumentType', '')
      }

      handleChange(e)
    },
    [handleChange, setFieldValue]
  )

  return (
    <TextField
      id={id}
      name={id}
      select
      sx={{ minWidth: '100px' }}
      value={values[id] === 'N/A' ? '' : values[id]}
      onChange={handleUpdateChange}
      disabled={isDisabled}
      title={
        id === 'DocumentType' &&
        isDisabled &&
        'Choose a folder number before selecting a document type'
      }
      size="small"
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {getOptionDisplay(option)}
        </MenuItem>
      ))}
    </TextField>
  )
}

function UserAutocomplete({ id, autocompleteProps, editing, toggleEditing }) {
  const autocompleteRef = useRef(null)
  const { users: allUsers } = useAllUsers({ embed: 'permissions' }, true)

  const users = useMemo(() => {
    // only show users that have access to digital mail
    return allUsers.filter((user) => {
      return user._embedded?.permissions?.modules?.[
        MODULE_NAMES.DIGITAL_MAILBOX
      ]
    })
  }, [allUsers])
  const { values, handleChange } = useFormikContext()
  const [searchValue, setSearchValue] = useState(values[id] ?? '')

  const minWidth = useMemo(() => {
    const userNames = users.map((user) => user.display)
    const longest = getLongestTextWidth(userNames) / 4

    return `${longest}rem`
  }, [users])

  const handleAutocompleteFilter = (options) => {
    const filtered = options.filter((option) =>
      option?.display?.toLowerCase().includes(searchValue.toLowerCase())
    )
    return filtered
  }

  useEffect(() => {
    if (autocompleteRef.current && editing) {
      setSearchValue('')
      autocompleteRef.current.focus()
    }
  }, [autocompleteRef, editing])

  return (
    <Autocomplete
      selectedOption={values[id]}
      setSelectedOption={(user) => {
        if (user) handleChange({ target: { name: id, value: user?.display } })
      }}
      placeholder="Assigned To"
      maxHeight="50vh"
      minWidth={minWidth}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={[...users, { id: null, display: 'Unassigned' }]}
      getOptionLabel={(user) => user?.display ?? user?.toString() ?? ''}
      handleFilter={handleAutocompleteFilter}
      {...{
        ...autocompleteProps,
        textFieldProps: {
          ...autocompleteProps.textFieldProps,
          inputRef: autocompleteRef,
        },
      }}
    />
  )
}

function StatusAutocomplete({ id, autocompleteProps, editing, toggleEditing }) {
  const autocompleteRef = useRef(null)
  const { statuses } = useDocumentInboundStatuses()
  const { values, handleChange } = useFormikContext()
  const [searchValue, setSearchValue] = useState(values[id] ?? '')

  const minWidth = useMemo(() => {
    const statusNames = statuses.map((status) => status.status)
    const longest = getLongestTextWidth(statusNames) / 4

    return `${longest}rem`
  }, [statuses])

  const handleAutocompleteFilter = (options) => {
    const filtered = options.filter((option) =>
      option?.status?.toLowerCase().includes(searchValue.toLowerCase())
    )
    return filtered
  }

  useEffect(() => {
    if (autocompleteRef.current && editing) {
      setSearchValue('')
      autocompleteRef.current.focus()
    }
  }, [autocompleteRef, editing])

  return (
    <Autocomplete
      selectedOption={values[id]}
      setSelectedOption={(status) => {
        if (status)
          handleChange({ target: { name: id, value: status?.status } })
      }}
      placeholder="Status"
      maxHeight="50vh"
      minWidth={minWidth}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={statuses}
      getOptionLabel={(status) => status?.status ?? status ?? ''}
      handleFilter={handleAutocompleteFilter}
      {...{
        ...autocompleteProps,
        textFieldProps: {
          ...autocompleteProps.textFieldProps,
          inputRef: autocompleteRef,
        },
      }}
    />
  )
}

function CategoryAutocomplete({
  id,
  autocompleteProps,
  editing,
  toggleEditing,
}) {
  const autocompleteRef = useRef(null)
  const { categories } = useDocumentInboundCategories()
  const { values, handleChange } = useFormikContext()

  // states
  const [searchValue, setSearchValue] = useState(values[id] ?? '')

  const minWidth = useMemo(() => {
    const categoryNames = categories.map((category) => category.name)
    const longest = getLongestTextWidth(categoryNames) / 4

    return `${longest}rem`
  }, [categories])

  const handleAutocompleteFilter = (options) => {
    const filtered = options.filter((option) =>
      option?.name?.toLowerCase().includes(searchValue.toLowerCase())
    )
    return filtered
  }

  useEffect(() => {
    if (autocompleteRef.current && editing) {
      setSearchValue('')
      autocompleteRef.current.focus()
    }
  }, [autocompleteRef, editing])

  return (
    <Autocomplete
      selectedOption={values[id]}
      setSelectedOption={(category) => {
        setSearchValue('')
        if (category)
          handleChange({ target: { name: id, value: category?.name } })
      }}
      placeholder="Category"
      maxHeight="50vh"
      minWidth={minWidth}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={categories}
      getOptionLabel={(category) => category?.name ?? category ?? ''}
      handleFilter={handleAutocompleteFilter}
      {...{
        ...autocompleteProps,
        textFieldProps: {
          ...autocompleteProps.textFieldProps,
          inputRef: autocompleteRef,
        },
      }}
    />
  )
}

function EditableListItem({ id, display, data, getDataDisplay }) {
  const { values, handleChange, setStatus, status } = useFormikContext()
  const { fieldDefinitions } = useFieldDefinitions()
  const { showDigitalMailDetailsItem } = useInstance()
  const fieldDefinition = fieldDefinitions[`_.metadata.${id}`]
  let editable = doEdit.includes(id) || fieldDefinition

  if (editableWhenEmpty.includes(id) && (data?.[id] && data?.[id] !== 'N/A')) {
    editable = false
  }

  const autocompleteProps = {
    textFieldProps: {
      variant: 'standard',
      fullWidth: true,
      autoFocus: true,
    },
  }

  const handleUpdateChange = useCallback(
    (newValue) => {
      handleChange({ target: { name: id, value: trimString(newValue) } })
    },
    [handleChange]
  )

  const toggleEditing = useCallback(() => {
    if (status.editing === id) {
      setStatus({ ...status, editing: null })
    } else {
      setStatus({ ...status, editing: id })
    }
  }, [status, setStatus])

  const getValueDisplay = useCallback(() => {
    if (
      id === 'FolderNumber' &&
      values[id] &&
      values[id] !== 'N/A' &&
      FOLDER_NUM_TEXT_DESCRIPTIONS?.[values[id]]
    ) {
      return `${values[id]} - ${FOLDER_NUM_TEXT_DESCRIPTIONS[values[id]]}`
    }

    if (
      id === 'DocumentType' &&
      values[id] &&
      values.FolderNumber &&
      values[id] !== 'N/A' &&
      DOCUMENT_TYPE_DESCRIPTIONS[values.FolderNumber]?.[values[id]]
    ) {
      return `${values[id]} - ${
        DOCUMENT_TYPE_DESCRIPTIONS[values.FolderNumber]?.[values[id]]
      }`
    }

    return values[id]
  }, [values])

  const searchField = useMemo(() => {
    if (id === 'category') {
      return (
        <CategoryAutocomplete
          id={id}
          autocompleteProps={autocompleteProps}
          editing={status.editing === id}
          toggleEditing={toggleEditing}
        />
      )
    } else if (id === 'status') {
      return (
        <StatusAutocomplete
          id={id}
          autocompleteProps={autocompleteProps}
          editing={status.editing === id}
          toggleEditing={toggleEditing}
        />
      )
    } else if (id === 'assigned_to') {
      return (
        <UserAutocomplete
          id={id}
          autocompleteProps={autocompleteProps}
          editing={status.editing === id}
          toggleEditing={toggleEditing}
        />
      )
    }

    let options = fieldDefinition?.options

    if (typeof fieldDefinition?.depends_on === 'string') {
      let field = fieldDefinition.depends_on.replace('_.metadata.', '')
      options = options[values[field]] || []
    }

    if (fieldDefinition && typeof fieldDefinition === 'object') {
      if (Array.isArray(options)) {
        return (
          <CustomSelect
            id={id}
            autocompleteProps={autocompleteProps}
            options={options}
            editing={status.editing === id}
            toggleEditing={toggleEditing}
          />
        )
      } else if (fieldDefinition.data_type === 'date') {
        let value = undefined

        if (typeof value === 'string' && value !== 'N/A') {
          value = parse(value, 'yyyy-MM-dd', new Date())
        }

        return (
          <DateRangePicker
            single
            name={id}
            value={[value, undefined]}
            setValue={(val) => {
              const [date] = val

              if (date instanceof Date) {
                handleUpdateChange(format(date, 'yyyy-MM-dd'))
              }
            }}
            size="small"
            variant="outlined"
          />
        )
      }
    }

    return (
      <CustomInput
        name={id}
        value={values[id]}
        onChange={(e) => handleUpdateChange(e.target.value)}
        size="small"
        variant="outlined"
      />
    )
  }, [id, display, data, getDataDisplay, values, fieldDefinitions, status])

  useEffect(() => {
    if (status.editing) {
      setStatus({ ...status, editing: null, edited: true })
    }
  }, [values])

  if (!showDigitalMailDetailsItem({ values, id, data })) {
    return <></>
  }

  return (
    <Box
      key={`extra-row-${data.id}-${id}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        mt: 0,
        mb: 6,
        ml: 0,
        mr: 4,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography
          fontWeight="bold"
          fontSize={14}
          color="textSecondary"
          sx={{
            my: 'auto',
          }}
        >
          {splitByPascalCase(display)}
        </Typography>
        {editable && (
          <IconButton size="small" onClick={toggleEditing}>
            <EditIcon color="primary" sx={{ fontSize: 14 }} />
          </IconButton>
        )}
      </Box>
      <Box sx={{ display: status.editing !== id && 'none' }}>{searchField}</Box>
      <Typography
        color="textSecondary"
        sx={{
          textDecoration: editable ? 'underline' : undefined,
          visibility: status.editing === id && 'hidden',
        }}
      >
        {getValueDisplay(values[id]) ?? getDataDisplay(data, id)}
      </Typography>
    </Box>
  )
}

export default EditableListItem
