import { useState, useEffect } from 'react'
import deepEqual from 'deep-equal'

import axios from 'utils/axios'
import { POSTAGE_TRANSACTION_PAGE_RECORDS } from 'utils/constants'

import urlSerialize from 'helpers/browser/urlSerialize'

const usePostageTransactionActivityInfinite = ({ params }) => {
  const [cachedParams, setCachedParams] = useState(params)
  const [infiniteLoading, setInfiniteLoading] = useState(false)
  const [data, setData] = useState(null)
  const [infiniteData, setInfiniteData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  const handleGet = async () => {
    const urlParams = { ...cachedParams }

    urlParams['paging[page]'] = page
    urlParams['paging[page_size]'] = POSTAGE_TRANSACTION_PAGE_RECORDS

    const endpoint =
      Boolean(cachedParams) &&
      page > 0 &&
      `/postage-transactions?${urlSerialize(urlParams)}`

    if (endpoint) {
      setInfiniteLoading(true)
      try {
        const res = await axios.get(endpoint)
        setInfiniteData(infiniteData.concat([res.data]))
        setData(null)
      } catch (err) {
        setData(null)
      } finally {
        // without a delay, window freezes shortly instead of showing a loader
        setTimeout(() => {
          setLoading(false)
          setInfiniteLoading(false)
        }, 100)
      }
    }
  }

  useEffect(() => {
    handleGet()
  }, [page, cachedParams])

  useEffect(() => {
    if (!deepEqual(cachedParams, params)) {
      setInfiniteData([])
      setData(null)
      setCachedParams(params)
      setPage(1)
    }
  }, [params])

  const transactions = data || infiniteData || []

  const getCSV = async () => {
    const urlParams = { ...cachedParams }
    const endpoint = `/postage-transactions?${urlSerialize(urlParams)}`

    urlParams['paging[page]'] = 1

    const {
      data: { total },
    } = await axios.get(endpoint, {
      params: urlParams,
    })

    if (total) {
      urlParams['paging[page_size]'] = total

      const response = await axios.get(endpoint, {
        params: urlParams,
        headers: { Accept: 'text/csv' },
      })
      return response.data
    }

    return ''
  }

  return {
    transactions,
    allTransactions:
      transactions?.flatMap((data) => data?._embedded?.transactions) ?? [],
    total: transactions?.length
      ? transactions.reduce((sum, transactions) => sum + transactions.total, 0)
      : 1,
    loading: loading || !transactions?.length,
    additionalLoading: infiniteLoading,
    page,
    setPage: (page) => {
      setInfiniteLoading(true)
      setPage(page)
    },
    mutate: handleGet,
    getCSV,
  }
}

export default usePostageTransactionActivityInfinite
