import { useContext } from 'react'
import { DashboardContext } from 'contexts/dashboard/DashboardContext'

const useDashboardContext = () => {
  const context = useContext(DashboardContext)

  if (!context)
    throw new Error('DashboardContext must be placed within Dashboard provider')

  return context
}

export default useDashboardContext
