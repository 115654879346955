import React from 'react'
import format from 'date-fns/format'

import CssBaseline from '@mui/material/CssBaseline'

import ResultsList from 'components/correspondence/ResultsList'

import DataListLayout from 'layouts/PureDataList'

function CorrespondenceResultsPage() {
  const csvFilename = `documents-${format(new Date(), 'MM-dd-yyyy-hhmmss')}.csv`

  return (
    <DataListLayout csvFilename={csvFilename}>
      <CssBaseline />
      <ResultsList />
    </DataListLayout>
  )
}

export default CorrespondenceResultsPage
