import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import useSavedSearchModal from 'hooks/useSavedSearchModal'
import useConfiguration from 'hooks/useConfiguration'

import List from './List'

import sortByObjectKey from 'helpers/node/sortByObjectKey'

function Content({ readonly = false }) {
  const { disableSave } = useSavedSearchModal()
  const { fieldDefinitions } = useConfiguration()
  const {
    values: { title, searchInputs, resultTableColumns },
    handleChange,
    errors,
    touched,
  } = useFormikContext()

  const availableOptions = Object.keys(fieldDefinitions).map(
    (key) => fieldDefinitions[key]
  )

  const inputOptions = sortByObjectKey(availableOptions, 'display')

  useEffect(() => {
    disableSave(
      !(
        (searchInputs || []).length > 0 && (resultTableColumns || []).length > 0
      )
    )
  }, [searchInputs, resultTableColumns])

  return (
    <>
      <Box>
        {readonly ? (
          <Typography
            sx={{
              color: 'darkblue.main',
              fontSize: '24px',
              fontWeight: title ? 'bold' : undefined,
              lineHeight: '32px',
            }}
          >
            {title}
          </Typography>
        ) : (
          <TextField
            fullWidth
            id="title"
            name="title"
            value={title}
            onChange={handleChange}
            variant="standard"
            placeholder="Add title"
            inputProps={{ maxLength: 50 }}
            sx={{ marginBottom: 8 }}
            error={touched.title && !!errors.title}
            helperText={touched.title && errors.title}
            InputProps={{
              sx: {
                color: 'darkblue.main',
                fontSize: '24px',
                fontWeight: title ? 'bold' : undefined,
                lineHeight: '32px',
              },
            }}
          />
        )}
      </Box>
      {errors.submit && <Alert color="error">{errors.submit}</Alert>}
      <Grid container spacing={14} columns={10} sx={{ height: '100%' }}>
        <Grid item xs={10} md={6}>
          <List
            readonly={readonly}
            title="Search inputs"
            name="searchInputs"
            optionKey="display"
            availableOptions={inputOptions}
            selectedOptions={searchInputs}
            itemProps={{
              sx: {
                background: '#FFFFFF',
                border: '1px solid',
                borderColor: 'background.main',
                boxSizing: 'border-box',
                borderRadius: '4px',
                color: 'darkgray.main',
                paddingLeft: '16px',
                paddingY: '12px',
                height: '100%',
                width: '100%',
              },
            }}
          />
        </Grid>
        <Grid item xs={10} md={4}>
          <List
            readonly={readonly}
            title="Result table columns"
            name="resultTableColumns"
            optionKey="display"
            availableOptions={inputOptions}
            selectedOptions={resultTableColumns}
            itemProps={{
              sx: {
                background: '#FFFFFF',
                border: '1px solid',
                borderColor: 'background.main',
                boxSizing: 'border-box',
                borderRadius: '4px',
                color: 'darkgray.main',
                paddingLeft: '16px',
                paddingY: '12px',
                height: '100%',
                width: '100%',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Content
