import React, { useMemo, useRef } from 'react'

import FormRenderer from 'components/common/FormRenderer'
import Modal from 'components/common/Modal'

import useManualBill from 'hooks/manual_bill/useManualBill'
import useWebForm from 'hooks/manual_bill/useWebForm'
import useNotification from 'hooks/context/useNotification'

import { MANUAL_BILL_READONLY_STATUSES } from 'utils/constants'

function BillEditModal({ bill, setBill, open, setOpen, mutate }) {
  const formikRef = useRef()
  const { setBasicNotification, setError } = useNotification()
  const { fields, validationSchema } = useWebForm()
  const {
    manualBill,
    updateManualBill,
    mutate: mutateManual,
  } = useManualBill({
    id: open && bill?.id,
  })

  const isReadOnly = useMemo(() => {
    return MANUAL_BILL_READONLY_STATUSES.includes(manualBill?.status)
  }, [manualBill])

  const renderingData = useMemo(() => {
    if (!Array.isArray(fields)) return []

    const dataArray = []

    fields.forEach((field) => {
      const dataField = {
        field: field.field,
        display: field.label,
        default_value:
          manualBill?.json?.[field.field]?.trim() ??
          field.default?.trim() ??
          '',
        type: 'text',
        inputProps: {
          readOnly: isReadOnly,
        },
      }
      dataArray.push(dataField)
    })

    return dataArray
  }, [fields, manualBill, isReadOnly])

  const handleClose = () => {
    setBill(null)
    setOpen(false)
  }

  const handleSubmit = async (values) => {
    try {
      const updatedJson = {}

      Object.keys(values).forEach((key) => {
        if (values[key] !== manualBill.json[key]) {
          updatedJson[key] = values[key]
        }
      })

      await updateManualBill({
        billId: manualBill?.id,
        updateData: { json: updatedJson },
      })
      mutate()
      mutateManual()
      setBasicNotification(`Bill has been successfully updated!`)
      handleClose()
    } catch (err) {
      setError('Cannot update bill. Try again later.', err)
    }
  }

  return (
    <Modal
      title="Edit Bill"
      height="90vh"
      width="max(40vw, 400px)"
      open={open}
      onClose={handleClose}
      footerButtonProps={[
        !isReadOnly && {
          children: 'Save',
          variant: 'contained',
          color: 'primary',
          onClick: () => formikRef.current.submitForm(),
        },
        {
          children: 'Close',
          variant: 'outlined',
          color: 'primary',
          onClick: handleClose,
        },
      ].filter((b) => b)}
    >
      <FormRenderer
        renderingData={renderingData}
        handleSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={validationSchema}
      />
    </Modal>
  )
}

export default BillEditModal
