import React from 'react'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

function SortingArrow({ col, sortedCols }) {
  if (sortedCols[col.id]) {
    if (sortedCols[col.id].desc) {
      return <ArrowDownwardIcon sx={{ my: 'auto' }} />
    } else {
      return <ArrowUpwardIcon sx={{ my: 'auto' }} />
    }
  }

  return <></>
}

export default SortingArrow
