import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import CircularProgress from '@mui/material/CircularProgress'

import FullBox from 'components/styled/FullBox'
import DownloadsNotification from '../Notification/Downloads'

import useAdobeSDKClient from 'hooks/useAdobeSDKClient'
import useDocuments from 'hooks/useDocuments'

import dataToArrayBuffer from 'helpers/browser/dataToArrayBuffer'
import useNotification from 'hooks/context/useNotification'
import { PDFDocument } from 'pdf-lib'

function PDFViewer({
  sx,
  options,
  id = 'pdf-div',
  url,
  fileName = 'sample.pdf',
  hideLoader,
  onLoadFinished,
}) {
  const loadTime = 2000 // 2 seconds
  const [show, setShow] = useState(false)
  const [showTimeout, setShowTimeout] = useState(null)
  const { ready, previewFile, previewFileUsingFilePromise } = useAdobeSDKClient(
    { url, fileName }
  )
  const previewFileOptions = {
    enableFormFilling: true,
    ...options,
  }

  useEffect(() => {
    setShow(false)

    if (ready) {
      ready.then(() => {
        if (typeof url === 'string') {
          previewFile(id, previewFileOptions)
        } else if (url) {
          if (url instanceof Uint8Array) {
            const filePromise = Promise.resolve(url)
            previewFileUsingFilePromise(id, filePromise, fileName)
          } else {
            dataToArrayBuffer(url, 'application/pdf').then(
              async (arrayBuffer) => {
                const pdfDoc = await PDFDocument.load(arrayBuffer)
                const newArrayBuffer = await pdfDoc.save()
                const filePromise = Promise.resolve(newArrayBuffer)
                previewFileUsingFilePromise(id, filePromise, fileName)
              }
            )
          }
        }
      })

      if (showTimeout) {
        clearTimeout(showTimeout)
      }

      const newTimeout = setTimeout(() => setShow(ready), loadTime)
      setShowTimeout(newTimeout)

      if (onLoadFinished) {
        onLoadFinished()
      }
    } else {
      setShow(true)
    }
  }, [ready, url])

  return (
    <>
      <FullBox sx={{ display: !show && !hideLoader ? 'flex' : 'none' }}>
        <CircularProgress thickness={2} sx={{ mx: 'auto' }} />
      </FullBox>
      <FullBox
        id={id}
        component="div"
        sx={{
          ...sx,
          visibility: show || hideLoader ? 'visible' : 'hidden',
        }}
      />
    </>
  )
}

function PDFViewerWrapper({ url, ...props }) {
  const { downloadDocuments } = useDocuments()
  const { setError, setComponent, setOpen } = useNotification()

  const handleDownload = async () => {
    try {
      await downloadDocuments([url.downloadId])
      setComponent(DownloadsNotification)
      setOpen(true)
    } catch (err) {
      setError(err.message)
    }
  }

  if (url.downloadId) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          border: '1px solid',
          borderColor: 'lightgray.main',
          height: '100%',
          pt: 4,
        }}
      >
        This file is too large to preview. Would you like to{' '}
        <MuiLink
          sx={{ textDecoration: 'underline' }}
          href="#"
          onClick={() => handleDownload()}
        >
          download
        </MuiLink>
        ?
      </Box>
    )
  }

  return <PDFViewer url={url} {...props} />
}

export default PDFViewerWrapper
