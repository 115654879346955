import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

import Legend from './components/Legend'
import LineChart from 'components/charts/LineChart'
import ItemContainer from './containers/ItemContainer'
import DateRangeDropdown from 'components/common/DateRangeDropdown'

import { COLOR_SHADES, DASHBOARD_WIDGETS, WIDGET_KEYS } from 'utils/constants'
import { DATE_RANGE_PRESETS } from 'components/common/DateRangePicker/dateRangePresets'

import useWidget from 'hooks/dashboard/useWidget'
import useDashboardContext from 'hooks/context/useDashboardContext'

import getGroupedLabels from 'helpers/datetime/getGroupedLabels'
import getFilesizeDisplay from 'helpers/css/getFilesizeDisplay'

const StyledTabs = styled(Tabs)`
  .MuiButtonBase-root {
    padding: 0 !important;
    height: 19px;
    min-height: 19px;
    min-width: 40px;
    font-size: 10px;
    line-height: 13.62px;

    color: ${COLOR_SHADES.DARK_GRAY[1]};

    &.Mui-selected {
      color: ${COLOR_SHADES.NAVY_BLUE[1]};
    }
  }

  .MuiTab-root {
    border-bottom: 2px solid ${COLOR_SHADES.DARK_GRAY[1]};
  }

  .MuiTabs-flexContainer {
    height: 19px;
  }
`

function SftpHistoryItem({ id }) {
  const { refreshCount } = useDashboardContext()
  const [datePreset, setDatePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const [tabIndex, setTabIndex] = useState(0)
  const { data, loading, mutate } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.SFTP_HISTORY,
    widgetName: id,
    presetId: datePreset,
  })

  const [labels, totalsTo, totalsFrom, toMPX, fromMPX, total] = useMemo(() => {
    const l = []
    const to = []
    const from = []
    const sftpKey = tabIndex === 0 ? 'files' : 'size'

    try {
      data.forEach((agg) => {
        l.push(agg.datetime_ran.split(' ')[0])
        to.push(agg.aggregated_data.counts[sftpKey]['to-mpx'] || 0)
        from.push(agg.aggregated_data.counts[sftpKey]['from-mpx'] || 0)
      })
    } catch (err) {
      // leave chart empty if error
    }

    const toTotal = to.reduce((a, b) => a + b, 0)
    const fromTotal = from.reduce((a, b) => a + b, 0)

    if (l.length === 1) {
      l.push(l[0])
      to.push(to[0])
      from.push(from[0])
    }

    return [l, to, from, toTotal, fromTotal, toTotal + fromTotal]
  }, [data, tabIndex])

  const displayLabels = useMemo(() => {
    return getGroupedLabels({ labels })
  }, [labels])

  const chartData = useMemo(
    () => ({
      labels: displayLabels,
      datasets: [
        {
          label: 'To MPX',
          data: totalsTo,
          fill: true,
          backgroundColor: COLOR_SHADES.GREEN[2],
          borderColor: COLOR_SHADES.GREEN[0],
          tension: 0.5,
        },
        {
          label: 'From MPX',
          data: totalsFrom,
          fill: true,
          backgroundColor: COLOR_SHADES.NAVY_BLUE[4],
          borderColor: COLOR_SHADES.NAVY_BLUE[1],
          tension: 0.5,
        },
      ],
    }),
    [totalsTo, totalsFrom, displayLabels]
  )

  const getValueDisplay = useCallback(
    (value) => {
      if (tabIndex === 0) {
        return value
      } else {
        return getFilesizeDisplay(value)
      }
    },
    [tabIndex]
  )

  useEffect(() => {
    mutate()
  }, [refreshCount])

  return (
    <ItemContainer
      disablePadding
      title="SFTP History"
      href="/command-center/sftp-stats"
      loading={loading}
      titleAction={
        <Box>
          <DateRangeDropdown
            value={datePreset}
            onChange={(e) => setDatePreset(e.target.value)}
          />
        </Box>
      }
      widgetKey={WIDGET_KEYS.SFTP_STATS_CHART}
    >
      <Box>
        <StyledTabs
          value={tabIndex}
          onChange={(_e, value) => setTabIndex(value)}
          sx={{
            minHeight: '19px',
            height: '19px',
            mb: 4,
          }}
        >
          <Tab label="Files" />
          <Tab label="Size" />
        </StyledTabs>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography fontSize={40} lineHeight="40px" fontWeight="bold">
              {getValueDisplay(total)}
            </Typography>
            <Typography fontSize={12} lineHeight="16px" mb={5}>
              {tabIndex === 0 ? 'all files' : 'total size'}
            </Typography>
          </Box>
          <Box sx={{ width: '120px' }}>
            <Legend
              labels={['To MPX', 'From MPX']}
              colors={[
                chartData.datasets[0].borderColor,
                chartData.datasets[1].borderColor,
              ]}
              data={[getValueDisplay(toMPX), getValueDisplay(fromMPX)]}
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            />
          </Box>
        </Box>
        <LineChart
          chartData={chartData}
          options={{
            scales: {
              x: {
                grid: {
                  color: 'white',
                },
                ticks: {
                  color: COLOR_SHADES.LIGHT_GRAY[0],
                  font: {
                    size: 10,
                    family: 'Open Sans',
                  },
                  callback: function (value, index, ticks) {
                    if (displayLabels.length <= 5) {
                      return displayLabels[index]
                    }

                    const mod = displayLabels.length
                    const offset = mod === 5 ? 0 : -1
                    const offsetIndex = index + offset

                    if (offsetIndex % mod !== 0) {
                      return undefined
                    }

                    const i = offsetIndex / mod

                    if (displayLabels[i]) {
                      return displayLabels[i]
                    }

                    return undefined
                  },
                },
              },
              y: {
                grid: {
                  borderDash: [4, 4],
                  color: COLOR_SHADES.LIGHT_GRAY[0],
                },
                ticks: {
                  color: COLOR_SHADES.LIGHT_GRAY[0],
                },
              },
            },
          }}
        />
      </Box>
    </ItemContainer>
  )
}

export default SftpHistoryItem
