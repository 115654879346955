import React, { useEffect, useMemo, useState } from 'react'

import { DATALIST_DIMENSIONS } from 'utils/constants'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import ExpandedList from 'components/common/ExpandedList'
import ScrollableBox from 'components/styled/ScrollableBox'
import PDFViewer from 'components/common/PDFViewer'
import DocumentPreviewExpandedList from 'components/common/ExpandedList/digital_mailbox/DocumentPreview'

import getFilesizeDisplay from 'helpers/css/getFilesizeDisplay'

function PDFContent({ pdf, setBytes, activeDocument }) {
  useEffect(() => {
    if (pdf) {
      setBytes(pdf.size)
    }
  }, [pdf])

  return (
    <>
      <Box
        sx={{
          height: `calc(${DATALIST_DIMENSIONS.HEIGHT} - 108px)`,
          overflow: 'hidden',
          pr: 10,
        }}
      >
        {pdf && (
          <PDFViewer
            url={pdf}
            fileName={
              activeDocument?._embedded?.document?.filename ?? 'preview.pdf'
            }
          />
        )}
      </Box>
    </>
  )
}

function DigitalMailboxDocumentPreviewContent({
  activeDocument,
  getDataDisplay,
  columns,
  onClick,
  pdf,
}) {
  const [bytes, setBytes] = useState(0)

  const expandedListDocument = useMemo(() => {
    return {
      ...activeDocument,
      file_size: getFilesizeDisplay(bytes),
    }
  }, [activeDocument, bytes])

  return (
    <ScrollableBox
      sx={{
        pl: 10,
        py: 6,
        height: '75vh',
        overflowY: 'auto',
        width: '100%',
      }}
    >
      <ExpandedList
        expanded={true}
        columns={columns}
        getDataDisplay={getDataDisplay}
        rowData={expandedListDocument}
        collapseDetails
        ExpandedListComponent={DocumentPreviewExpandedList}
      >
        <Button
          sx={{ textDecoration: 'underline', mb: 4 }}
          onClick={() => onClick()}
        >
          Detailed Document History
        </Button>
      </ExpandedList>
      <PDFContent
        activeDocument={activeDocument}
        setBytes={setBytes}
        pdf={pdf}
      />
    </ScrollableBox>
  )
}

export default DigitalMailboxDocumentPreviewContent
