import jwtDecode from 'jwt-decode'

const isValidToken = ({ accessToken }) => {
  if (!accessToken) {
    return false
  }

  const { exp } = jwtDecode(accessToken)

  return exp * 1000 > Date.now()
}

/**
 * function to check if token should be refreshed
 */
const shouldRefreshToken = ({ accessToken }) => {
  if (!accessToken) {
    return false
  }

  const { exp } = jwtDecode(accessToken)

  const buffer = 60 * 15 * 1000 // 15 mins
  const timeToRefresh = exp * 1000 - Date.now() < buffer

  return timeToRefresh
}

export { isValidToken, shouldRefreshToken }
