import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'

import ClockIcon from '@mui/icons-material/AccessTime'

import useAuth from 'hooks/useAuth'

function TimeoutModal({ idleTimer, ...props }) {
  const { logOut } = useAuth()
  const [remainingTime, setRemaningTime] = useState(
    Math.ceil(parseInt(idleTimer.getRemainingTime()) / 1000)
  )

  const modalProps = {
    title: `Session Timeout`,
    size: 'sm',
    footerButtonProps: [
      {
        children: 'Continue',
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          props.setOpen(false)
          idleTimer.message({ type: 'continue' }, true)
        },
      },
      {
        children: 'Log Off',
        color: 'primary',
        variant: 'outlined',
        onClick: () => {
          props.setOpen(false)
          logOut(true)
        },
      },
    ],
    ...props,
  }

  const displayRemainingTime = () => {
    const _remainingTime = idleTimer.getRemainingTime()
    setRemaningTime(Math.ceil(parseInt(_remainingTime) / 1000))
  }

  useEffect(() => {
    const rtInterval = setInterval(() => {
      displayRemainingTime()
    }, 1000)

    return () => clearTimeout(rtInterval)
  }, [])

  return (
    <Modal {...modalProps}>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mr: 2 }}>
            <ClockIcon />
          </Box>
          <Typography>Your session will expire in:</Typography>
        </Box>
        <Typography
          sx={{ mx: 'auto', textAlign: 'center', fontSize: 48, my: 8 }}
          variant="h1"
        >
          {remainingTime} seconds
        </Typography>
        <Typography>Please click "Continue" to keep working</Typography>
        <Typography>or click "Log Off" to end your session now.</Typography>
      </Box>
    </Modal>
  )
}

export default TimeoutModal
