import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const useUser = (userId) => {
  const { data, isValidating, mutate } = useSWR(
    userId ? `/users/${userId}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    user: data ?? {},
    loading: (!data && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
  }
}

export default useUser
