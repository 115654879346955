import {
  createContext,
  useReducer,
  useCallback,
  useState,
  useEffect,
} from 'react'

import { DATE_RANGE_PRESETS } from 'components/common/DateRangePicker/dateRangePresets'
import SavedSearchModal from 'components/correspondence/SavedSearchModal'
import { SavedSearchModalProvider } from 'contexts/SavedSearchModalContext'
import SearchModal from 'components/correspondence/SearchModal'

import useAuth from 'hooks/useAuth'
import useNotification from 'hooks/context/useNotification'
import useUserWidgets from 'hooks/users/useUserWidgets'

const SET_DATE_PRESET = 'SET_DATE_PRESET'
const SET_SAVED_SEARCH = 'SET_SAVED_SEARCH'
const INC_REFRESH_COUNT = 'INC_REFRESH_COUNT'
const SET_SEARCH_MODAL_PROPS = 'SET_SEARCH_MODAL_PROPS'
const SET_WIDGET_MAP = 'SET_WIDGET_MAP'
const SET_PRIORITY_LEVEL = 'SET_PRIORITY_LEVEL'

const initialState = {
  datePreset: DATE_RANGE_PRESETS.CURRENT_DAY,
  refreshCount: 0,
  lastRefreshed: new Date(),
  savedSearchProps: {
    type: 'closed',
  },
  searchModalProps: {
    open: false,
  },
  priorityLevel: 1,
  widgetMap: {},
}

const DashboardReducer = (state, action) => {
  switch (action.type) {
    case SET_DATE_PRESET:
      return {
        ...state,
        datePreset: action.payload.datePreset,
      }
    case INC_REFRESH_COUNT:
      return {
        ...state,
        refreshCount: state.refreshCount + 1,
        lastRefreshed: new Date(),
      }
    case SET_SAVED_SEARCH:
      return {
        ...state,
        savedSearchProps: action.payload.savedSearchProps,
      }
    case SET_SEARCH_MODAL_PROPS:
      return {
        ...state,
        searchModalProps: action.payload.searchModalProps,
      }
    case SET_WIDGET_MAP:
      return {
        ...state,
        widgetMap: action.payload.widgetMap,
      }
    case SET_PRIORITY_LEVEL:
      return {
        ...state,
        priorityLevel: action.payload.priorityLevel,
      }
    default:
      return state
  }
}

const DashboardContext = createContext(initialState)
const REFRESH_PAUSE_TIMER = 60 * 1000

function DashboardProvider({ children }) {
  const { user } = useAuth()
  const { setBasicNotification, setError } = useNotification()
  const { updateWidget, widgets } = useUserWidgets(user?.userId)
  const [state, dispatch] = useReducer(DashboardReducer, initialState)
  const [refreshPaused, setRefreshPaused] = useState('')
  // hide widgets for this dashboard session, for better user experience
  const [hiddenWidgets, setHiddenWidgets] = useState([])

  const refreshWidgets = useCallback(() => {
    if (!refreshPaused) {
      setRefreshPaused(true)
      dispatch({
        type: INC_REFRESH_COUNT,
      })

      setTimeout(() => {
        setRefreshPaused(false)
      }, REFRESH_PAUSE_TIMER)
    }
  }, [refreshPaused])

  const setDatePreset = useCallback((presetId) => {
    dispatch({
      type: SET_DATE_PRESET,
      payload: {
        datePreset: presetId,
      },
    })
  }, [])

  const setType = useCallback(
    (searchType) => {
      dispatch({
        type: SET_SAVED_SEARCH,
        payload: {
          savedSearchProps: {
            ...state.savedSearchProps,
            type: searchType,
          },
        },
      })
    },
    [state]
  )

  const setSavedSearch = useCallback((savedSearchProps) => {
    dispatch({
      type: SET_SAVED_SEARCH,
      payload: {
        savedSearchProps: {
          ...savedSearchProps,
          setType,
        },
      },
    })
  }, [])

  const setSearchModalProps = useCallback((searchModalProps) => {
    dispatch({
      type: SET_SEARCH_MODAL_PROPS,
      payload: {
        searchModalProps: {
          ...searchModalProps,
        },
      },
    })
  }, [])

  const setWidgetMap = useCallback((widgets) => {
    const widgetMap = {}

    widgets.forEach((widget) => {
      widgetMap[widget.key] = {
        loaded: false,
        priority: widget.priority ?? 1,
      }
    })

    dispatch({
      type: SET_WIDGET_MAP,
      payload: {
        widgetMap,
      },
    })
  }, [])

  const setWidgetLoaded = useCallback(
    (key, loaded = true) => {
      const widgetMap = {
        ...state.widgetMap,
        [key]: {
          ...state.widgetMap[key],
          loaded: loaded,
        },
      }

      dispatch({
        type: SET_WIDGET_MAP,
        payload: {
          widgetMap,
        },
      })

      const maxPriority = Object.values(widgetMap).reduce(
        (a, b) => Math.max(a, b.priority),
        0
      )
      const increasePriorityLevel =
        state.priorityLevel < maxPriority &&
        Object.values(widgetMap)
          .filter((widget) => widget.priority === state.priorityLevel)
          .reduce((a, b) => a && b.loaded, true)

      if (increasePriorityLevel) {
        dispatch({
          type: SET_PRIORITY_LEVEL,
          payload: {
            priorityLevel: state.priorityLevel + 1,
          },
        })
      }
    },
    [state]
  )

  const hideWidget = useCallback(
    async (widgetKey) => {
      try {
        setHiddenWidgets((prevWidgets) => {
          return prevWidgets.concat([widgetKey])
        })

        const widgetId = widgets.find((widget) => widget.key === widgetKey)?.id

        if (!widgetId) throw new Error()

        await updateWidget(widgetId, { is_active: 0 })
      } catch (err) {
        setError('Unable to hide widget at this time. Try again later.', err)
      }
    },
    [updateWidget, setBasicNotification, widgets]
  )

  const showWidget = useCallback(
    async (widgetKey) => {
      try {
        setHiddenWidgets((prevState) => {
          if (prevState.includes(widgetKey)) {
            return prevState.filter((key) => key !== widgetKey)
          }

          return prevState
        })

        const widgetId = widgets.find((widget) => widget.key === widgetKey)?.id

        if (!widgetId) throw new Error()

        await updateWidget(widgetId, { is_active: 1 })
      } catch (err) {
        setError('Unable to show widget at this time. Try again later.', err)
      }
    },
    [updateWidget, setBasicNotification, widgets]
  )

  useEffect(() => {
    if (widgets.length > 0) {
      setHiddenWidgets(
        widgets
          .filter((widget) => !widget.is_active)
          .map((widget) => widget.key)
      )
    }
  }, [widgets])

  return (
    <DashboardContext.Provider
      value={{
        ...state,
        setDatePreset,
        setSavedSearch,
        setSearchModalProps,
        setWidgetMap,
        setWidgetLoaded,
        refreshWidgets,
        refreshPaused,
        hideWidget,
        showWidget,
        hiddenWidgets,
      }}
    >
      {children}
      <SavedSearchModalProvider>
        <SavedSearchModal {...state.savedSearchProps} />
      </SavedSearchModalProvider>
      <SearchModal {...state.searchModalProps} />
    </DashboardContext.Provider>
  )
}

export { DashboardContext, DashboardProvider }
