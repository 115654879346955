import format from 'date-fns/format'

const formatDateRange = ({
  dateRange,
  dateFormat = 'dd MMMM yyyy',
  delimiter = ' - ',
}) => {
  try {
    if (!(dateRange[0] instanceof Date) && !(dateRange[1] instanceof Date))
      return ''
    if (dateRange[0] instanceof Date && !(dateRange[1] instanceof Date))
      return format(dateRange[0], dateFormat)

    return `${format(dateRange[0], dateFormat)}${delimiter}${format(
      dateRange[1],
      dateFormat
    )}`
  } catch (err) {
    return ''
  }
}

export default formatDateRange
