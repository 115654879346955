const getFilenameExt = (filename) => {
  let ext = ''
  const extension = filename.match(/\.([^.]+)$/)
  if (extension) {
    ext = extension[1] || ''
  }

  return ext
}

export default getFilenameExt
