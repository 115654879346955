import React, { useMemo, useCallback, useEffect } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import DataList from 'components/common/DataList/Table'
import LoadingModal from 'components/common/LoadingModal'
import Header from './Header'
import ReportingItem from 'components/common/DataList/Item/Reporting'

import useNotification from 'hooks/context/useNotification'
import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import useReports from 'hooks/reporting/useReports'
import useModal from 'hooks/context/useModal'

import { DATALIST_DIMENSIONS } from 'utils/constants'

import splitByPascalCase from 'helpers/node/splitByPascalCase'

function ReportDataListUI({
  reportName,
  sidebar,
  activeReport,
  activeDateRange,
  sidebarOpen,
  setSidebarOpen,
  setSortList,
  downloadReport,
  total,
  items,
  loading,
  visibleColumns,
  headerProps,
  setPaging,
}) {
  // notification
  const { setError, setBasicNotification } = useNotification()

  // modal
  const {
    setModalProps,
    setComponent: setModalComponent,
    setOpen: setModalOpen,
  } = useModal()

  const {
    initialized,
    layoutType,
    pageSize,
    page,
    total: rowTotal,
    setTotal,
    totalPages,
    setTotalPages,
  } = useDataListContext()

  const { reports } = useReports({})

  const handleDownload = async (type = 'application/pdf') => {
    try {
      const reportTitle = reports.find(
        (report) => report.id === activeReport
      )?.title
      const typeDisplay = type.split('/')[1].toUpperCase()

      setModalProps({
        size: 'sm',
        hideButtonsBorder: true,
        title: `A ${typeDisplay} export is in progress`,
      })
      setModalComponent(LoadingModal)
      setModalOpen(true)

      await downloadReport(
        activeReport,
        activeDateRange,
        reportTitle,
        total,
        type
      )
      setModalOpen(false)
      setBasicNotification(
        `A ${typeDisplay} file has been automatically downloaded to your computer.`
      )
    } catch (err) {
      setError(
        err.response?.data?.display_message || 'Report could not be downloaded'
      )
      setModalOpen(false)
    }
  }

  const prepareRowData = (row) => ({
    ...row,
  })

  const getColumnDisplay = useCallback((col) => {
    const editDisplay = (display) =>
      splitByPascalCase(display)
        .replace(/_/gm, ' ')
        .replace(/.metadata./gm, '')
        .toUpperCase()

    return editDisplay(col)
  }, [])

  const getRowDisplay = useCallback((rowData, columnName) => {
    return rowData[columnName]?.toString() ?? 'N/A'
  }, [])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: items ?? [],
    presetName: 'reporting',
    setSortList,
    prepareRowData,
    getColumnDisplay,
    getRowDisplay,
    ignoreUserTimezone: true,
  })

  const dataListDisplay = useMemo(() => {
    return (
      <Card sx={{ boxShadow: 2, mb: 2 }}>
        <Box>
          <DataList
            {...dataListProps}
            dataList={dataList}
            columns={columns}
            loading={loading}
            validating={initialized}
            visibleColumns={visibleColumns}
            height={DATALIST_DIMENSIONS.HEIGHT}
            layoutType={'reporting'}
            multiSelect={false}
            RowComponent={ReportingItem}
            total={total}
            colProps={{
              sx: {
                whiteSpace: 'nowrap',
              },
            }}
          />
        </Box>
      </Card>
    )
  }, [
    dataListProps,
    dataList,
    columns,
    loading,
    initialized,
    layoutType,
    visibleColumns,
    total,
  ])

  useEffect(() => {
    if (rowTotal !== total) {
      setTotal(total)
    }

    const pageCount = Math.ceil((total || 0) / pageSize)
    if (pageCount !== totalPages) {
      setTotalPages(pageCount)
    }
  }, [total, pageSize])

  useEffect(() => {
    const offset = (page - 1) * pageSize ?? undefined

    if (setPaging) {
      setPaging({ offset, limit: pageSize })
    }
  }, [pageSize, page])

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        {Boolean(reportName) && (
          <Box
            sx={{
              height: '72px',
              background: 'white',
              display: 'flex',
              borderBottom: '1px solid lightgray',
            }}
          >
            <Typography variant="h1" sx={{ marginLeft: 8, marginY: '28px' }}>
              {reportName}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
          }}
        >
          <Box sx={{ px: 8, pt: 6, width: '100%' }}>
            <Header
              {...headerProps}
              handleDownload={handleDownload}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
            {dataListDisplay}
          </Box>
        </Box>
      </Box>
      {sidebar}
    </Box>
  )
}

export default ReportDataListUI
