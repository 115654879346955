import React, { useState } from 'react'

import Autocomplete from 'components/common/Autocomplete'
import MultiSelectDropdown from 'components/common/MultiSelectDropdown'

import usePrograms from 'hooks/programs/usePrograms'

function ProgramsAutocomplete({
  multiple,
  selectedPrograms,
  setSelectedPrograms,
  hookParams,
  ...props
}) {
  const { programs } = usePrograms(hookParams)
  const [searchValue, setSearchValue] = useState('')

  if (multiple) {
    return (
      <MultiSelectDropdown
        value={selectedPrograms}
        onChange={(e) => setSelectedPrograms(e.target.value)}
        name="Program name"
        showChips={false}
        options={programs}
        getOptionLabel={(program) => program.name}
        {...props}
      />
    )
  }

  return (
    <Autocomplete
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={programs}
      getOptionLabel={(program) => program.name}
      {...props}
    />
  )
}

export default ProgramsAutocomplete
