import React, { useState, useMemo, useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'
import styled from 'styled-components/macro'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'

import HelpIcon from '@mui/icons-material/Info'

import FullBox from 'components/styled/FullBox'
import ScrollableBox from 'components/styled/ScrollableBox'
import CollapseFromText from 'components/common/CollapseFromText'
import MultiSelectDropdown from 'components/common/MultiSelectDropdown'
import UserHistoryModal from 'components/user_maintenance/UserHistoryModal'

import useDataListContext from 'hooks/context/useDataListContext'
import useModal from 'hooks/context/useModal'
import useNotification from 'hooks/context/useNotification'
import {
  useRoles,
  useUserRoles,
  useUsers,
  useUserNotes,
  useUserTypes,
} from 'hooks/users'
import useConfiguration from 'hooks/useConfiguration'
import useDocumentGroups from 'hooks/documents/useDocumentGroups'

import { TIMEZONES, PRIVILEGES } from 'utils/constants'
import PrivilegeModal from 'components/user_maintenance/PrivilegeModal'

const [ACTIVE, INACTIVE] = [0, 1]

function Header({ updateUser, setTabIndex, deleteUser, setNewUser }) {
  const { setBasicNotification, setError } = useNotification()
  const { setOpen, setModalProps } = useModal()
  const { values } = useFormikContext()
  const { hasPrivilege } = useConfiguration()

  const hideDeactivate =
    !values.id || !values.is_active || !hasPrivilege(PRIVILEGES.DEACTIVATE_USER)

  const hideActivate =
    !values.id || values.is_active || !hasPrivilege(PRIVILEGES.ACTIVATE_USER)
  const hideDelete = !values.id || !hasPrivilege(PRIVILEGES.DELETE_USER)

  const handleToggleActiveUser = async () => {
    const isActive = values.is_active

    try {
      await updateUser({
        userId: values.id,
        userData: {
          is_active: !isActive,
        },
      })

      setOpen(false)

      setBasicNotification(
        <>
          User {values.display} has been{' '}
          {!isActive ? 'activated' : 'deactivated'}. Please check the{' '}
          <MuiLink
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setTabIndex(isActive ? INACTIVE : ACTIVE)}
          >
            {isActive ? 'Inactive' : 'Active'}
          </MuiLink>{' '}
          tab.
        </>
      )
    } catch (err) {
      setError(err.response?.data?.display_message || 'An error has occurred')
    }
  }

  const handleToggleActive = () => {
    const modalProps = {
      title: `${values.is_active ? 'Deactivate' : 'Activate'} ${
        values.display
      }?`,
      children: `Are you sure you want to ${
        values.is_active ? 'deactivate' : 'activate'
      } this user?`,
      size: 'sm',
      footerButtonProps: [
        {
          children: 'Confirm',
          variant: 'contained',
          color: 'primary',
          onClick: () => handleToggleActiveUser(),
        },
        {
          children: 'Cancel',
          variant: 'outlined',
          color: 'primary',
          onClick: () => setOpen(false),
        },
      ],
    }

    setModalProps(modalProps)
    setOpen(true)
  }

  const handleDeleteUser = async () => {
    try {
      const userDisplay = values.display

      if (values.id) {
        await deleteUser({
          userId: values.id,
        })
      } else {
        setNewUser(false)
      }

      setOpen(false)

      setBasicNotification(
        <>User {userDisplay || 'New User'} has been deleted.</>
      )
    } catch (err) {
      setError(err.response?.data?.display_message || 'Error deleting user.')
    }
  }

  const handleDelete = () => {
    const modalProps = {
      title: `Delete ${values.display || 'New User'}?`,
      children: 'Are you sure you want to delete this user?',
      size: 'sm',
      footerButtonProps: [
        {
          children: 'Confirm',
          variant: 'contained',
          color: 'primary',
          onClick: () => handleDeleteUser(),
        },
        {
          children: 'Cancel',
          variant: 'outlined',
          color: 'primary',
          onClick: () => setOpen(false),
        },
      ],
    }

    setModalProps(modalProps)
    setOpen(true)
  }

  return (
    <Box
      sx={{
        height: '72px',
        width: '100%',
        py: 4,
        px: 8,
        borderBottom: '1px solid',
        borderColor: 'lightgray.main',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h1" sx={{ my: 'auto' }}>
        User Details
      </Typography>
      <Box>
        {!hideDeactivate && hasPrivilege(PRIVILEGES.DEACTIVATE_USER) && (
          <Button
            size="action-footer"
            variant="contained"
            color="secondary"
            sx={{ mr: 6 }}
            onClick={handleToggleActive}
          >
            Deactivate
          </Button>
        )}
        {!hideActivate && hasPrivilege(PRIVILEGES.ACTIVATE_USER) && (
          <Button
            size="action-footer"
            variant="contained"
            color="secondary"
            sx={{ mr: 6 }}
            onClick={handleToggleActive}
          >
            Activate
          </Button>
        )}
        {!hideDelete && (
          <Button
            size="action-footer"
            variant="outlined"
            color="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        )}
      </Box>
    </Box>
  )
}

function Footer() {
  const { dirty, resetForm, submitForm, isSubmitting } = useFormikContext()

  return (
    <Box
      sx={{
        height: '72px',
        width: '100%',
        py: 4,
        px: 8,
        borderTop: '1px solid',
        borderColor: 'lightgray.main',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Box>
        <Button
          size="action-footer"
          variant="outlined"
          color="primary"
          sx={{ mr: 6 }}
          disabled={!dirty || isSubmitting}
          onClick={resetForm}
        >
          Cancel
        </Button>
        <Button
          size="action-footer"
          variant="contained"
          color="primary"
          disabled={!dirty || isSubmitting}
          onClick={submitForm}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

function MainInformation({ textFieldProps }) {
  const [expanded, setExpanded] = useState(true)
  const { values, errors, touched } = useFormikContext()
  const { hasPrivilege } = useConfiguration()
  const getModuleDisplay = (m) => {
    return m.replace('_', ' ')
  }

  const userModules = useMemo(() => {
    const roles = values.roles ?? []

    return roles.flatMap((role) => role._embedded?.modules ?? [])
  }, [values])

  const allModules = useMemo(() => {
    const mods = [
      ...new Set(userModules.filter((m) => m.resource).map((m) => m.name)),
    ]

    if (mods.length === 0) return ['dashboard']
    else return mods
  }, [userModules])

  return (
    <CollapseFromText
      text="MAIN INFORMATION"
      expanded={expanded}
      setExpanded={setExpanded}
      hideButton
      typographyProps={{ sx: { mb: 3 } }}
    >
      <Stack spacing={6}>
        {values.id !== undefined && (
          <TextField
            {...textFieldProps}
            name="id"
            label="ID"
            value={values.id || ''}
            disabled
          />
        )}
        <TextField
          {...textFieldProps}
          name="username"
          label="User name"
          value={values.username || ''}
          helperText={touched.username && errors.username}
          error={Boolean(touched.username && errors.username)}
          disabled={!hasPrivilege(PRIVILEGES.EDIT_USER)}
          required
        />
        <TextField
          {...textFieldProps}
          name="display"
          label="Full name"
          value={values.display || ''}
          helperText={touched.display && errors.display}
          error={Boolean(touched.display && errors.display)}
          disabled={!hasPrivilege(PRIVILEGES.EDIT_USER)}
          required
        />
        <TextField
          {...textFieldProps}
          name="email"
          label="Email"
          value={values.email || ''}
          helperText={touched.email && errors.email}
          error={Boolean(touched.email && errors.email)}
          disabled={!hasPrivilege(PRIVILEGES.EDIT_USER)}
          required
        />
        <TextField
          {...textFieldProps}
          name="phone"
          label="Mobile phone"
          value={values.phone || ''}
          helperText={errors.phone}
          error={Boolean(errors.phone)}
          disabled={!hasPrivilege(PRIVILEGES.EDIT_USER)}
        />
        <TextField
          {...textFieldProps}
          name="note"
          label="Note"
          value={values.note || ''}
          helperText={touched.note && errors.note}
          error={Boolean(touched.note && errors.note)}
          disabled={!hasPrivilege(PRIVILEGES.EDIT_USER)}
        />
        <TextField
          {...textFieldProps}
          name="timezone"
          label="Time zone"
          value={values.timezone || ''}
          helperText={errors.timezone}
          error={Boolean(errors.timezone)}
          select
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: { maxHeight: '50vh !important', boxShadow: 2 },
              },
            },
          }}
          disabled={!hasPrivilege(PRIVILEGES.EDIT_USER)}
        >
          {Object.keys(TIMEZONES).map((tz) => (
            <MenuItem key={tz} value={tz}>
              {TIMEZONES[tz]}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          {...textFieldProps}
          name="home_module"
          label="Home page"
          value={values.home_module || ''}
          helperText={errors.home_module}
          error={Boolean(errors.home_module)}
          select
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: { maxHeight: '50vh !important', boxShadow: 2 },
              },
            },
          }}
          sx={{ textTransform: 'capitalize' }}
          defaultValue="dashboard"
          disabled={!hasPrivilege(PRIVILEGES.EDIT_USER)}
        >
          {allModules.map((m) => (
            <MenuItem key={m} value={m} sx={{ textTransform: 'capitalize' }}>
              {getModuleDisplay(m)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </CollapseFromText>
  )
}

const StyledListItem = styled(Box)`
  padding: 0 !important;
  color: ${({ theme }) => theme.palette.darkgray.main};
  font-size: 1rem;
`

function Permissions({ textFieldProps }) {
  const { setOpen, setModalProps } = useModal()
  const { values, handleChange } = useFormikContext()
  const { roles } = useRoles({})
  const [expanded, setExpanded] = useState(true)
  const { resetUserPassword } = useUsers()
  const { setBasicNotification, setError } = useNotification()
  const { hasPrivilege } = useConfiguration()
  const [helpRole, setHelpRole] = useState(null)

  const hideRoles = !hasPrivilege(PRIVILEGES.MANAGE_USER_ROLES)
  const hidePasswordReset =
    !values.id || !hasPrivilege(PRIVILEGES.RESET_PASSWORD)

  const handlePasswordResetConfirm = async () => {
    try {
      await resetUserPassword({ userId: values.id })

      setBasicNotification(
        `A password reset request has been submitted for ${values.display}.`
      )
    } catch (err) {
      setError(err.response.data?.display_message || 'Error resetting password')
    } finally {
      setOpen(false)
    }
  }

  const modalProps = {
    title: 'Are you sure?',
    children: (
      <>
        <Typography mb={3}>
          Initiating a password reset will send an email to this user with a
          link to reset their password.
        </Typography>
        <Typography>
          Please ensure the email address for this user is correct.
        </Typography>
      </>
    ),
    size: 'sm',
    footerButtonProps: [
      {
        children: 'Confirm',
        variant: 'contained',
        color: 'primary',
        onClick: () => handlePasswordResetConfirm(),
      },
      {
        children: 'Cancel',
        variant: 'outlined',
        color: 'primary',
        onClick: () => setOpen(false),
      },
    ],
  }

  const handlePasswordReset = () => {
    setModalProps(modalProps)
    setOpen(true)
  }

  const handleRoleSelect = (e) => {
    const newRoles = e.target.value

    handleChange({
      target: {
        name: 'roles',
        value: newRoles
          ? roles.filter((role) => newRoles.includes(role.name))
          : [],
      },
    })

    const roleNames = newRoles?.map((role) => role.name)

    if (!roleNames.includes(values.home_module)) {
      handleChange({
        target: {
          name: 'home_module',
          value: 'dashboard',
        },
      })
    }
  }

  const getDisplayRoles = (rolesArr) => {
    return rolesArr
      .filter((role) => role.is_assignable)
      .map((role) => role.name)
  }

  const SelectRenderedOption = useCallback(
    ({ option, getOptionLabel, ...renderProps }) => {
      const handleHelpClick = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const foundRole = roles.find((role) => role.name === option)
        setHelpRole(foundRole)
      }

      return (
        <StyledListItem component="li" {...renderProps} option={option}>
          <Checkbox
            checked={renderProps['aria-selected']}
            color="green"
            sx={{ color: 'lightgray.main' }}
          />
          {getOptionLabel(option)}
          <IconButton color="primary" onClick={handleHelpClick}>
            <HelpIcon />
          </IconButton>
        </StyledListItem>
      )
    },
    [roles]
  )

  return (
    <>
      <PrivilegeModal
        open={Boolean(helpRole)}
        onClose={() => setHelpRole(null)}
        role={helpRole}
      />
      <CollapseFromText
        text="PERMISSIONS"
        expanded={expanded}
        setExpanded={setExpanded}
        hideButton
        typographyProps={{ sx: { mb: 3 } }}
      >
        <Stack spacing={6}>
          {!hideRoles && (
            <MultiSelectDropdown
              {...textFieldProps}
              options={getDisplayRoles(roles)}
              name="Roles"
              value={getDisplayRoles(values.roles)}
              onChange={handleRoleSelect}
              RenderedOption={SelectRenderedOption}
            />
          )}
          {!hidePasswordReset && (
            <Button
              sx={{ height: '32px', width: '160px' }}
              color="secondary"
              variant="contained"
              onClick={handlePasswordReset}
            >
              Reset Password
            </Button>
          )}
        </Stack>
      </CollapseFromText>
    </>
  )
}

function Summary() {
  const { values } = useFormikContext()
  const [expanded, setExpanded] = useState(true)
  const { roles } = useRoles({})
  const { documentGroups } = useDocumentGroups()
  const { userType } = useUserTypes(values.username)

  const userRoles = roles.filter((role) =>
    values.roles.map((r) => r.id).includes(role.id)
  )

  const accessedModules = useMemo(() => {
    return [
      ...new Set(
        userRoles
          .flatMap((r) => r._embedded?.modules ?? [])
          .map((m) => m.display)
      ),
    ]
  }, [userRoles])

  const restrictedDocGroups = useMemo(() => {
    const rDocGroups = [
      ...new Set(
        userRoles.flatMap((r) => r._embedded?.restrictions?.docgroup ?? [])
      ),
    ]

    if (rDocGroups.length <= 0) return ['All']

    return [
      ...new Set(
        documentGroups
          .filter((dg) => rDocGroups.includes(dg.id))
          .flatMap((dg) => dg._embedded?.metadata_descriptions ?? [])
      ),
    ]
  }, [userRoles, documentGroups])

  return (
    <CollapseFromText
      text="SUMMARY"
      expanded={expanded}
      setExpanded={setExpanded}
      hideButton
      typographyProps={{ sx: { mb: 3 } }}
    >
      <Stack spacing={6}>
        <Box>
          <Typography fontWeight="bold">User Type</Typography>
          <Typography>{userType}</Typography>
        </Box>
        <Box>
          <Typography fontWeight="bold">Modules Access</Typography>
          <Typography>{accessedModules.join(', ') || 'None'}</Typography>
        </Box>
        <Box>
          <Typography fontWeight="bold">Documents Access</Typography>
          <Typography>{restrictedDocGroups.join(', ')}</Typography>
        </Box>
      </Stack>
    </CollapseFromText>
  )
}

function Body({
  handleChange,
  values,
  setShowHistoryModal,
  setShowPasswordResetModal,
  setBlocked,
}) {
  const { handleBlur } = useFormikContext()

  const textFieldProps = {
    size: 'small',
    onChange: handleChange,
    onBlur: (e) => {
      handleBlur(e)
      setBlocked(false)
    },
    onFocus: () => setBlocked(true),
  }

  return (
    <FullBox sx={{ px: 8 }}>
      <Grid container columnSpacing={16} sx={{ height: '100%', width: '100%' }}>
        <Grid item xs={6}>
          <MainInformation textFieldProps={textFieldProps} />
          {values.id && (
            <Button
              color="primary"
              sx={{ textDecoration: 'underline' }}
              onClick={() => setShowHistoryModal(true)}
            >
              User's History
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <FullBox sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ height: '40%' }}>
              <Permissions
                textFieldProps={textFieldProps}
                setShowPasswordResetModal={setShowPasswordResetModal}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Summary textFieldProps={textFieldProps} />
            </Box>
          </FullBox>
        </Grid>
      </Grid>
    </FullBox>
  )
}

function UserMaintenancePreviewContent({
  dataList,
  updateUser,
  setTabIndex,
  deleteUser,
  addUser,
  setNewUser,
  setBlocked,
}) {
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const { setBasicNotification, setError } = useNotification()
  const { activeIndex } = useDataListContext()
  const user = dataList[activeIndex]
  const { roles, addRole, removeRole } = useUserRoles(user.id)
  const { notes, addNote, deleteNote, editNote } = useUserNotes(user.id)

  const initialValues = {
    id: user.id,
    username: user.username,
    display: user.display,
    email: user.email,
    phone: user.phone,
    note: notes[0]?.note || '',
    timezone: user.timezone,
    home_module: user.home_module,
    is_active: user.is_active,
    roles,
  }

  const updateRoles = async (values, userId) => {
    const userRoles = [...roles]

    // only add roles the user doesn't already have
    const toAdd = values.roles.filter(
      (role) => !userRoles.find((ur) => ur.id === role.id)
    )

    // only remove roles the user already has
    const toDelete = userRoles.filter(
      (role) => !values.roles.find((ur) => ur.id === role.id)
    )

    await Promise.all(
      toAdd.map(async (role) => addRole({ roleId: role.id, id: userId }))
    )
    await Promise.all(
      toDelete.map(async (role) => removeRole({ roleId: role.id, id: userId }))
    )
  }

  const onSubmit = async (values, { resetForm }) => {
    try {
      const userData = {
        username: values.username,
        email: values.email,
        phone: values.phone,
        display: values.display,
        timezone: values.timezone,
        home_module: values.home_module,
      }

      // new user case
      if (values.id === undefined) {
        const { id } = await addUser({
          userData,
        })

        let postAddErrors = []
        try {
          await updateRoles(values, id)
        } catch (err) {
          postAddErrors.push(
            `Issue with role assignment: ${
              err.response?.data?.display_message || 'Unknown'
            }`
          )
        }

        let newNotes = []
        let noteContent = (values.note || '').trim()
        if (noteContent !== '') {
          try {
            const newNote = await addNote(id, values.note)
            newNotes.push(newNote)
          } catch (err) {
            postAddErrors.push(
              `Issue with leaving note: ${
                err.response?.data?.display_message || 'Unknown'
              }`
            )
          }
        }

        // reset new user state
        setNewUser({
          _embedded: {
            roles: [],
            notes: newNotes,
          },
        })

        resetForm()

        setBasicNotification(
          <>
            User {values.display} has been successfully created. An email has
            been sent to the user at the address on their account.
            {postAddErrors.length > 0
              ? ` However, errors after creating the user occured: ${postAddErrors.join(
                  ', '
                )}`
              : ''}
          </>
        )
      } else {
        await updateUser({
          userId: values.id,
          userData,
        })

        await updateRoles(values, values.id)

        // only support 1 note presently
        let noteError = null
        try {
          let currentNoteExists = Array.isArray(notes) && notes[0]?.id > 0
          if ((values.note || '').trim()) {
            if (currentNoteExists) {
              await editNote(values.id, notes[0].id, values.note)
            } else {
              await addNote(values.id, values.note)
            }
          } else if (currentNoteExists) {
            await deleteNote(values.id, notes[0].id)
          }
        } catch (err) {
          noteError = err.response?.data?.display_message || 'unknown'
        }

        if (noteError === null) {
          setBasicNotification(
            <>User {values.display} has been successfully updated.</>
          )
        } else {
          setError(
            `User ${values.display} has been updated successfully, but note failed: ${noteError}`
          )
        }
      }
    } catch (err) {
      setError(err.response?.data?.display_message || 'Error updating user')
    }
  }

  const validationSchema = Yup.object().shape({
    display: Yup.string().required('Full name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    username: Yup.string().required('Username is required'),
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange={false}
      >
        {({ handleSubmit, handleChange, values }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                height: '100%',
                width: '100%',
              }}
            >
              <FullBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Header
                  updateUser={updateUser}
                  setTabIndex={setTabIndex}
                  deleteUser={deleteUser}
                  setNewUser={setNewUser}
                />
                <Box sx={{ flex: 1 }}>
                  <ScrollableBox
                    sx={{
                      overflow: 'auto',
                      height: `calc(65vh - 144px)`,
                    }}
                  >
                    <Body
                      handleChange={handleChange}
                      values={values}
                      setShowHistoryModal={setShowHistoryModal}
                      setBlocked={setBlocked}
                    />
                  </ScrollableBox>
                </Box>
                <Footer />
              </FullBox>
            </Box>
          </form>
        )}
      </Formik>
      <UserHistoryModal
        userId={user.id}
        open={showHistoryModal}
        onClose={() => setShowHistoryModal(false)}
      />
    </>
  )
}

export default UserMaintenancePreviewContent
