const isObject = (item) => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

const mergeDeep = (source, target) => {
  const newSource = { ...source }

  Object.keys(target).forEach((key) => {
    if (isObject(source[key]) && isObject(target[key])) {
      // if both vals are objs, recursively merge
      newSource[key] = mergeDeep(newSource[key], target[key])
    } else if (Array.isArray(source[key]) && Array.isArray(target[key])) {
      // if both vals are arrs, concat and remove dupes
      const newArr = source[key].concat(target[key])
      newSource[key] = [...new Set(newArr)]
    } else {
      // overwite by default
      newSource[key] = target[key]
    }
  })

  return newSource
}

export default mergeDeep
