import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import useUserHomepage from 'hooks/users/useUserHomepage'

import { TIMEZONES } from 'utils/constants'
import InstanceDisplay from 'components/common/InstanceDisplay'

function CardForm({ setShowModal }) {
  const navigate = useNavigate()
  const { values, handleChange, submitForm, dirty, isSubmitting } =
    useFormikContext()
  const { userModules } = useUserHomepage()

  const allModules = useMemo(() => {
    return [...new Set(userModules.map((m) => m.name))]
  }, [userModules])

  const getModuleDisplay = (m) => {
    return m.replace('_', ' ')
  }

  return (
    <Card sx={{ px: 10, py: 8, boxShadow: 2 }}>
      <Box sx={{ mb: 8, display: 'flex', justifyContent: 'space-between' }}>
        <Typography fontWeight="bold">MAIN INFORMATION</Typography>
        <Typography sx={{ textTransform: 'uppercase' }}>
          <InstanceDisplay />
        </Typography>
      </Box>
      <Grid container columnSpacing={8} rowSpacing={6}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label={'Full name'}
            name={'display'}
            onChange={handleChange}
            value={values['display']}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label={'Mobile phone'}
            name={'phone'}
            onChange={handleChange}
            value={values['phone']}
          />
        </Grid>
        <Grid item xl={6}>
          <TextField
            size="small"
            fullWidth
            label={'Note'}
            name={'note'}
            onChange={handleChange}
            value={values['note']}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label={'Username'}
            name={'username'}
            onChange={handleChange}
            value={values['username']}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label={'Time zone'}
            name={'timezone'}
            onChange={handleChange}
            value={values['timezone']}
            select
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: { maxHeight: '50vh !important', boxShadow: 2 },
                },
              },
            }}
          >
            {Object.keys(TIMEZONES).map((tz) => (
              <MenuItem key={tz} value={tz}>
                {TIMEZONES[tz]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label={'Email'}
            name={'email'}
            onChange={handleChange}
            value={values['email']}
          />
        </Grid>
        <Grid item xs={6} sx={{ textTransform: 'capitalize' }}>
          <TextField
            size="small"
            fullWidth
            defaultValue={'dashboard'}
            label={'Home page'}
            name={'home_module'}
            onChange={handleChange}
            value={values['home_module']}
            select
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: { maxHeight: '50vh !important', boxShadow: 2 },
                },
              },
            }}
          >
            {allModules.map((m) => (
              <MenuItem key={m} value={m} sx={{ textTransform: 'capitalize' }}>
                {getModuleDisplay(m)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowModal(true)}
          >
            Change Password
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
          >
            <Button
              variant="outlined"
              color="primary"
              size="action-header"
              sx={{ mr: 4 }}
              disabled={isSubmitting}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="action-header"
              disabled={!dirty || isSubmitting}
              onClick={submitForm}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CardForm
