import { createContext, useReducer, useCallback } from 'react'

const initialState = {
  layoutType: 'normal',
  initialized: false,
  refresh: false,
  requestParams: {},
  tabIndex: 0,
  sortedCols: {},
  dataListRef: null,
  dimensions: { height: 0, width: 0 },
  maxWidths: {},
}

const SET_LAYOUT_TYPE = 'SET_LAYOUT_TYPE'
const SET_INITIALIZED = 'SET_INITIALIZED'
const REFRESH_DATA = 'REFRESH_DATA'
const SET_PARAMS = 'SET_PARAMS'
const SET_TAB_INDEX = 'SET_TAB_INDEX'
const SET_SORTED_COLS = 'SET_SORTED_COLS'
const SET_DATALIST_REF = 'SET_DATALIST_REF'
const SET_DIMENSIONS = 'SET_DIMENSIONS'
const SET_MAX_WIDTHS = 'SET_MAX_WIDTHS'

const DataListReducer = (state, action) => {
  switch (action.type) {
    case SET_LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.payload.layoutType,
      }
    case SET_INITIALIZED:
      return {
        ...state,
        initialized: action.payload.initialized,
      }
    case REFRESH_DATA:
      return {
        ...state,
        refreshData: action.payload.refreshData,
      }
    case SET_PARAMS:
      return {
        ...state,
        requestParams: action.payload.params,
      }
    case SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.payload.tabIndex,
      }
    case SET_SORTED_COLS:
      return {
        ...state,
        sortedCols: action.payload.sortedCols,
      }
    case SET_DATALIST_REF:
      return {
        ...state,
        dataListRef: action.payload.dataListRef,
      }
    case SET_DIMENSIONS: {
      return {
        ...state,
        dimensions: action.payload.dimensions,
      }
    }
    case SET_MAX_WIDTHS: {
      const { value, index } = action.payload.maxWidth
      let indexValue = state.maxWidths[index]

      if (!state.maxWidths[index] || state.maxWidths[index] < value) {
        indexValue = value
      }

      return {
        ...state,
        maxWidths: {
          ...state.maxWidths,
          [index]: indexValue,
        },
      }
    }
    default:
      return state
  }
}

const DataListContext = createContext(null)

function DataListProvider({ children }) {
  const [state, dispatch] = useReducer(DataListReducer, initialState)

  const setLayoutType = useCallback((layoutType) => {
    dispatch({
      type: SET_LAYOUT_TYPE,
      payload: {
        layoutType,
      },
    })
  }, [])

  const setInitialized = useCallback((initialized) => {
    dispatch({
      type: SET_INITIALIZED,
      payload: {
        initialized,
      },
    })
  }, [])

  const refreshData = useCallback((refresh) => {
    dispatch({
      type: REFRESH_DATA,
      payload: {
        refresh: refresh,
      },
    })
  }, [])

  const setParams = useCallback((params) => {
    dispatch({
      type: SET_PARAMS,
      payload: {
        params,
      },
    })
  }, [])

  const setTabIndex = useCallback((tabIndex) => {
    dispatch({
      type: SET_TAB_INDEX,
      payload: {
        tabIndex,
      },
    })
  }, [])

  const setSortedCols = useCallback((sortedCols) => {
    dispatch({
      type: SET_SORTED_COLS,
      payload: {
        sortedCols,
      },
    })
  }, [])

  const setDataListRef = useCallback((dataListRef) => {
    dispatch({
      type: SET_DATALIST_REF,
      payload: {
        dataListRef,
      },
    })
  })

  const setDimensions = useCallback((dimensions) => {
    dispatch({
      type: SET_DIMENSIONS,
      payload: {
        dimensions,
      },
    })
  }, [])

  const setMaxWidth = useCallback((maxWidth, index) => {
    dispatch({
      type: SET_MAX_WIDTHS,
      payload: {
        maxWidth: {
          value: maxWidth,
          index,
        },
      },
    })
  }, [])

  return (
    <DataListContext.Provider
      value={{
        ...state,
        setLayoutType,
        setInitialized,
        refreshData,
        setParams,
        setTabIndex,
        setSortedCols,
        setDataListRef,
        setDimensions,
        setMaxWidth,
      }}
    >
      {children}
    </DataListContext.Provider>
  )
}

export { DataListContext, DataListProvider }
