import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Box from '@mui/material/Box'

function DraggableCards({ children, handle, id = 'droppable', handleDragEnd }) {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (handleDragEnd) {
      handleDragEnd(result)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id}>
        {(provided, _snapshot) => (
          <Box component="div" ref={provided.innerRef}>
            {children?.length > 0 &&
              children.map((child, index) => (
                <Draggable
                  key={`${id}-child-${index}`}
                  draggableId={`${id}-child-${index}`}
                  index={index}
                >
                  {(provided, _snapshot) => (
                    <Box
                      component="div"
                      sx={{ display: 'flex' }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...(!handle && provided.dragHandleProps)}
                    >
                      {handle && handle(provided.dragHandleProps)}
                      {child}
                    </Box>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableCards
