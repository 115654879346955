import React, { useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import Close from '@mui/icons-material/Close'

import DateRangePicker from 'components/common/DateRangePicker'
import AddableList from 'components/common/AddableList'
import {
  FILTER_OPTION_BY_TYPE,
  FILTER_OPTION_DISPLAYS,
  COLUMN_TYPES,
  getVarcharMaxLength,
  getColumnType,
} from 'utils/report'

function FilterActionSelect({ value, column, columnType, readonly }) {
  const { setFieldValue } = useFormikContext()

  const options = useMemo(() => {
    return FILTER_OPTION_BY_TYPE[columnType] || []
  }, [columnType])

  return (
    <Select
      readOnly={readonly}
      fullWidth
      value={value.action}
      onChange={(e) => {
        setFieldValue(`report_filters.${value.index}.action`, e.target.value)
      }}
      size="small"
      sx={{ textAlign: 'center' }}
    >
      <MenuItem value="eq">{'='}</MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {FILTER_OPTION_DISPLAYS[option] || option}
        </MenuItem>
      ))}
    </Select>
  )
}

function FilterTargetSelect({ value, column, columnType, action }) {
  const { setFieldValue, values } = useFormikContext()

  const filterValue = values.report_filters[value.index].target

  const handleValueChange = (newValue) => {
    setFieldValue(`report_filters.${value.index}.target`, newValue)
  }

  useEffect(() => {
    if (
      columnType === COLUMN_TYPES.DATETIME &&
      Array.isArray(filterValue) &&
      filterValue.length &&
      action !== 'between'
    ) {
      handleValueChange([])
    }

    if (
      columnType === COLUMN_TYPES.DATETIME &&
      typeof filterValue === 'string'
    ) {
      const newDate = new Date(filterValue)
      if (newDate.toString() === 'Invalid Date') {
        handleValueChange(new Date())
      } else {
        handleValueChange(new Date(filterValue))
      }
    }
  }, [columnType, filterValue, action])

  if (columnType === COLUMN_TYPES.BOOLEAN) {
    return (
      <Select
        fullWidth
        size="small"
        variant="outlined"
        value={filterValue}
        onChange={(e) => {
          handleValueChange(e.target.value)
        }}
      >
        <MenuItem value={true}>TRUE</MenuItem>
        <MenuItem value={false}>FALSE</MenuItem>
      </Select>
    )
  }

  if (columnType === COLUMN_TYPES.DATETIME) {
    try {
      return (
        <Box>
          <DateRangePicker
            single={action !== 'between'}
            value={Array.isArray(filterValue) ? filterValue : [filterValue]}
            setValue={(val) => {
              if (action === 'between') {
                handleValueChange(val)
              } else {
                handleValueChange(val?.[0])
              }
            }}
            size="small"
            dateFormat={{
              dateFormat: 'MM/dd/yyyy',
            }}
          />
        </Box>
      )
    } catch (err) {
      return <></>
    }
  }

  if (action === 'in') {
    return (
      <AddableList
        options={
          (Array.isArray(values.report_filters[value.index].target)
            ? values.report_filters[value.index].target
            : values.report_filters[value.index].target?.split(',')
          )?.filter((v) => v) || []
        }
        setOptions={(options) => {
          setFieldValue(
            `report_filters.${value.index}.target`,
            options.join(',')
          )
        }}
      />
    )
  }

  return (
    <TextField
      size="small"
      variant="outlined"
      value={filterValue}
      type={columnType === COLUMN_TYPES.NUMBER ? 'number' : 'text'}
      inputProps={{
        maxLength: getVarcharMaxLength(column),
      }}
      onChange={(e) => {
        handleValueChange(e.target.value)
      }}
    />
  )
}

export default function ValueDisplay({ value, type, onClose, readonly }) {
  const { setFieldValue, values, status } = useFormikContext()

  const getValueDisplay = () => {
    if (type === 'sort') {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography my="auto" fontWeight="600">
            {`${value}`}
          </Typography>
          <Select
            value={values.report_sorts[value].dir}
            onChange={(e) => {
              setFieldValue(`report_sorts.${value}.dir`, e.target.value)
            }}
            size="small"
          >
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </Box>
      )
    }

    if (type === 'filter') {
      const column = status.columns.find((col) => col.col === value.col)
      const columnType = getColumnType(column)

      return (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '200px 1fr 2fr',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '200px',
              fontWeight: 600,
              py: 2,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            title={`${value.col} (${columnType})`}
          >
            {`${value.col}`}
          </Box>
          <Box>
            <Box sx={{ px: 1 }}>
              <FilterActionSelect
                value={value}
                column={column}
                columnType={columnType}
                readonly={readonly}
              />
            </Box>
          </Box>
          <Box>
            <FilterTargetSelect
              value={value}
              column={column}
              columnType={columnType}
              action={values.report_filters[value.index].action}
            />
          </Box>
        </Box>
      )
    }

    return (
      <Typography my="auto" fontWeight="600">
        {`${value}`}
      </Typography>
    )
  }

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 2,
        boxShadow: 3,
        width: '100%',
        mb: 3,
      }}
    >
      {getValueDisplay()}
      {!readonly && (
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </Card>
  )
}
