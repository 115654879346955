import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'

import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'

import ExpandedList from 'components/common/ExpandedList'
import CorrespondenceExpandedList from 'components/common/ExpandedList/Correspondence'

// local components
import ItemCheckBox from './components/ItemCheckbox'
import HoverButtons from './components/HoverButtons'
import FixedButtons from './components/FixedButtons'
import DataListItemWrapper from './components/DataListItemWrapper'

import useDataListContext from 'hooks/context/useDataListContext'

import { COLORS } from 'utils/constants'

function SFTPHistoryItem(props) {
  const {
    index,
    rowData,
    columns,
    fixedButtonProps = [],
    getDataDisplay,
    multiSelect,
    statusColName,
    expandedListProps,
    visibleColumns,
    sharedSx,
  } = props

  const [hovered, setHovered] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [checked, setChecked] = useState(false)
  const [status, setStatus] = useState({ color: COLORS.GREEN })

  const getStatusMessage = () => {
    if (rowData?.record_type === 'notification') {
      const { datetime_sent } = rowData?._embedded?.notification ?? {}
      let sentDatetimeDisplay = datetime_sent

      try {
        sentDatetimeDisplay = format(
          new Date(datetime_sent),
          'MM/dd/yyyy hh:mm a'
        )
      } catch (err) {
        sentDatetimeDisplay = datetime_sent
      }

      return `Sent at ${sentDatetimeDisplay}, ${status.display}`
    }

    return undefined
  }

  const statusTitle = status.success ? 'DELIVERY SUCCESSFUL' : 'DELIVERY FAILED'
  const statusMessage = getStatusMessage()

  const darken = expanded || checked

  const shownColumns = visibleColumns
    .map((col) => columns.find((c) => c.id === col))
    .filter((c) => c)

  const { color, success } = status ?? { color: 'green.main' }
  const checkboxColor = success ? 'success' : 'warning'
  const showButtons = hovered && !expanded

  const getBorderColor = () => {
    if (darken) return color

    return 'background.paper'
  }

  useEffect(() => {
    if (rowData?.[statusColName]) {
      setStatus(rowData[statusColName])
    }
  }, [rowData])

  function CheckBox() {
    const { allChecked, checkedCount } = useDataListContext()

    useEffect(() => {
      if (allChecked === true) setChecked(true)
      if (checkedCount === 0) setChecked(false)
    }, [allChecked, checkedCount])

    return (
      <>
        {multiSelect && (
          <ItemCheckBox
            index={index}
            handleItemCheck={setChecked}
            color={checkboxColor}
          />
        )}
      </>
    )
  }

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        borderBottomColor: 'lightgray.main',
        bgcolor: darken && 'hover.main',
        color: 'darkgray.main',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          borderLeftColor: !darken && 'hover.main',
          bgcolor: !darken && 'hover.main',
        },
      }}
    >
      <ListItem>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {<CheckBox />}
          {shownColumns.map((col, colIndex) => (
            <DataListItemWrapper key={col.id} index={colIndex}>
              <Box
                sx={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  flexDirection: 'column',
                  color: col.id === statusColName && color,
                }}
              >
                <Box
                  sx={{
                    my: 'auto',
                    fontSize: 14,
                  }}
                >
                  {getDataDisplay(rowData, col.id, statusColName)}
                </Box>
              </Box>
            </DataListItemWrapper>
          ))}
        </Box>
        {showButtons && <HoverButtons {...props} />}
        {fixedButtonProps.length > 0 && <FixedButtons {...props} />}
      </ListItem>
      <ExpandedList
        {...expandedListProps}
        expanded={expanded}
        setExpanded={setExpanded}
        columns={columns}
        rowData={rowData}
        visibleColumns={visibleColumns}
        getDataDisplay={getDataDisplay}
        statusTitle={statusTitle}
        statusMessage={statusMessage}
        ExpandedListComponent={CorrespondenceExpandedList}
        hoverButtons={<HoverButtons sx={{ right: 0 }} {...props} />}
      />
    </Box>
  )
}

export default SFTPHistoryItem
