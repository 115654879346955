import React from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

function PasswordCheckItem({ value, check }) {
  const { regex, display, cond } = check
  const isValid = regex?.test(value) || cond?.(value)

  return (
    <Box
      sx={{ display: 'flex', color: isValid ? 'success.main' : 'error.main' }}
    >
      {isValid ? (
        <CheckCircleOutlineIcon fontSize="small" sx={{ my: 'auto' }} />
      ) : (
        <ErrorOutlineIcon fontSize="small" sx={{ my: 'auto' }} />
      )}
      <Typography sx={{ ml: 1, my: 'auto' }}>{display}</Typography>
    </Box>
  )
}

function PasswordCheck({ value, checks = [] }) {
  return (
    <Stack spacing={1}>
      {checks.map((check) => (
        <PasswordCheckItem key={check.name} value={value} check={check} />
      ))}
    </Stack>
  )
}

export default PasswordCheck
