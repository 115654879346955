function getDollarDisplay(value = 0) {
  let num = parseFloat(value)

  const isNeg = num < 0

  num = Math.abs(num)

  let prefix = isNeg ? '-$' : '$'
  let suffix = num.toFixed(2).split('.')[1]
  return `${prefix}${Math.floor(num).toLocaleString()}.${suffix}`
}

export default getDollarDisplay
