export function mergeAggregateData(target, source) {
  const result = { ...target }

  Object.entries(source).forEach(([key, value]) => {
    const existing = result[key]

    if (typeof existing === 'undefined') {
      result[key] = value
      return
    }

    if (typeof existing === 'number' || typeof value === 'number') {
      result[key] = parseFloat(existing) + parseFloat(value)
      return
    }

    if (typeof existing === 'object' && typeof value === 'object') {
      result[key] = mergeAggregateData(existing, value)
      return
    }

    // else override
    result[key] = value
  })

  return result
}

export function mergeAggregateDataArray({ data }) {
  let aggregatedDataTotal = {}

  data.forEach((aggData) => {
    aggregatedDataTotal = mergeAggregateData(aggregatedDataTotal, aggData)
  })

  return aggregatedDataTotal
}

export function groupData({ data = {}, count = 0, excludeEmpty = true }) {
  let total = count

  if (!total) {
    total = Object.keys(data).length
  }

  const groupedData = {}

  const groups = Object.entries(data || {}).sort((a, b) => b[1] - a[1])
  groups.slice(0, total - 1).forEach((entry) => {
    if (entry[1]) groupedData[entry[0]] = entry[1]
  })

  if (count < Object.keys(data).length) {
    const otherCount = groups.slice(total).reduce((a, b) => a + b[1], 0)

    if (otherCount)
      groupedData['Other'] = groups.slice(total).reduce((a, b) => a + b[1], 0)
  }

  return groupedData
}

export function groupByKey({ data = [], key = '' }) {
  const groups = {}

  data.forEach((item) => {
    const groupKey = item[key]
    if (!groups[groupKey]) {
      groups[groupKey] = [item]
    } else {
      groups[groupKey].push(item)
    }
  })

  return groups
}
