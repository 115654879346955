import React, { useEffect, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import DateRangeDropdown from 'components/common/DateRangeDropdown'
import ItemContainer from './containers/ItemContainer'
import PieChart from 'components/charts/PieChart'
import Legend from './components/Legend'

import useWidget from 'hooks/dashboard/useWidget'
import useDashboardContext from 'hooks/context/useDashboardContext'

import { COLOR_SHADES, DASHBOARD_WIDGETS, WIDGET_KEYS } from 'utils/constants'
import { mergeAggregateDataArray } from 'utils/aggregate'
import { DATE_RANGE_PRESETS } from 'components/common/DateRangePicker/dateRangePresets'

const labels = ['Documents', 'Notifications']

function PieSection({ totals, colors, label }) {
  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          data: totals,
          backgroundColor: colors,
          borderWidth: Array.from({ length: totals.length }).map(() => 0),
        },
      ],
    }
  }, [totals])

  return (
    <Stack spacing={4}>
      <Box>
        <Typography fontWeight={700} fontSize="24px" lineHeight="24px">
          {totals.reduce((a, b) => a + b, 0)}
        </Typography>
        <Typography fontSize="12px">{label}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <PieChart chartData={chartData} sx={{ width: '125px' }} />
      </Box>
      <Legend
        labels={labels}
        data={totals}
        colors={colors}
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          '& > *': { mr: 4 },
        }}
      />
    </Stack>
  )
}

function CorrespondenceItemCounts({ id }) {
  const { refreshCount } = useDashboardContext()
  const [datePreset, setDatePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const {
    data: liveData,
    loading: liveLoading,
    mutate: mutateLive,
  } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.CORRESPONDENCE,
    widgetName: id,
    presetId: DATE_RANGE_PRESETS.CURRENT_DAY,
  })
  const { data, loading, mutate } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.CORRESPONDENCE,
    presetId: datePreset !== DATE_RANGE_PRESETS.CURRENT_DAY && datePreset,
  })

  const aggregateTotal = useMemo(() => {
    return (
      mergeAggregateDataArray({ data: liveData.concat(data) })
        ?.aggregated_data || {}
    )
  }, [data, liveData])

  const failures = useMemo(() => {
    const notifFailures = Object.entries(
      aggregateTotal?.counts?.failures || {}
    ).reduce((a, [k, v]) => a + (k !== 'mail' ? v : 0), 0)
    const mailFailures = Object.entries(
      aggregateTotal?.counts?.failures || {}
    ).reduce((a, [k, v]) => a + (k === 'mail' ? v : 0), 0)
    return [mailFailures, notifFailures]
  }, [aggregateTotal])

  const news = useMemo(() => {
    const docCount = parseInt(aggregateTotal?.counts?.['new-documents'] || '0')
    const notifCount = parseInt(
      aggregateTotal?.counts?.['new-notifications'] || '0'
    )
    return [docCount, notifCount]
  }, [aggregateTotal])

  useEffect(() => {
    mutateLive()
    mutate()
  }, [refreshCount])

  return (
    <ItemContainer
      disablePadding
      widgetKey={WIDGET_KEYS.CORRESPONDENCE_DUAL_DONUT}
      title="Correspondence"
      href="/correspondence-hub/correspondence"
      labels={labels}
      loading={liveLoading || loading}
      titleAction={
        <Box>
          <DateRangeDropdown
            value={datePreset}
            onChange={(e) => setDatePreset(e.target.value)}
          />
        </Box>
      }
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1 }}>
          <PieSection
            totals={news}
            colors={[COLOR_SHADES.GREEN[0], COLOR_SHADES.GREEN[1]]}
            label="new documents"
          />
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mx: 8 }} />
        <Box sx={{ flex: 1 }}>
          <PieSection
            totals={failures}
            colors={[COLOR_SHADES.YELLOW[0], COLOR_SHADES.YELLOW[1]]}
            label="failed deliveries"
          />
        </Box>
      </Box>
    </ItemContainer>
  )
}

export default CorrespondenceItemCounts
