import React, { useEffect, useState } from 'react'

import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// local components
import ItemCheckBox from './components/ItemCheckbox'

import useDataListContext from 'hooks/context/useDataListContext'

function PreviewItem({
  index,
  rowData,
  columns,
  getDataDisplay,
  multiSelect,
  statusColName,
  sharedSx,
}) {
  const { activeIndex, setActiveIndex } = useDataListContext()
  const [status, setStatus] = useState({ color: 'success' })
  const [checked, setChecked] = useState(false)

  const active = activeIndex === index
  const { color, success } = status ?? { color: 'success' }
  const checkboxColor = success ? 'success' : 'warning'
  const darken = active || checked

  const getBorderColor = () => {
    if (active || checked) return color

    return 'background.paper'
  }

  const setActive = () => {
    setActiveIndex(index)
  }

  function CheckBox() {
    const { allChecked, checkedCount } = useDataListContext()

    useEffect(() => {
      if (allChecked === true) setChecked(true)
      if (checkedCount === 0) setChecked(false)
    }, [allChecked, checkedCount])

    return (
      <>
        {multiSelect && (
          <ItemCheckBox
            index={index}
            handleItemCheck={setChecked}
            color={checkboxColor}
          />
        )}
      </>
    )
  }

  useEffect(() => {
    if (rowData) {
      setStatus(rowData[statusColName])
    }
  }, [rowData])

  return (
    <Box
      onClick={setActive}
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderBottomColor: 'lightgray.main',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        bgcolor: darken && 'hover.main',
        color: 'darkgray.main',
        cursor: 'pointer',
        display: 'flex',
        minWidth: '232px',
        flexDirection: 'column',
        '&:hover': {
          borderLeftColor: !active && !checked && 'hover.main',
          bgcolor: !active && !checked && 'hover.main',
        },
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          overflow: 'hidden',
          justifyContent: 'space-around',
          my: 'auto',
          mx: 0,
          width: '100%',
        }}
      >
        {multiSelect && (
          <CheckBox
            index={index}
            handleItemCheck={setChecked}
            color={checkboxColor}
          />
        )}
        <Box
          key={columns[0].id}
          sx={{
            wordBreak: 'break-word',
            display: 'flex',
            color: columns[0].id === statusColName && color,
          }}
        >
          <Box sx={{ width: '48px', p: '12px' }}>
            <Box
              title={rowData.type.display}
              sx={{
                m: 'auto',
              }}
            >
              {getDataDisplay(rowData, columns[0].id, statusColName)}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Stack sx={{ mx: 'auto' }}>
            <Typography sx={{ textDecoration: 'underline' }}>
              ID {getDataDisplay(rowData, columns[1].id, statusColName)}
            </Typography>
            <Typography
              sx={{
                maxWidth: '100px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {getDataDisplay(rowData, '_.metadata.Description') ?? 'N/A'}
            </Typography>
          </Stack>
        </Box>
      </ListItem>
    </Box>
  )
}

export default PreviewItem
