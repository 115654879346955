import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import LogInComponent from 'components/auth/LogIn'
import SSOComponent from 'components/auth/SSO'
import Logo from 'components/common/Logo'

import useConfiguration from 'hooks/useConfiguration'
import useNotification from 'hooks/context/useNotification'
import useAuth from 'hooks/useAuth'
import useTheme from 'hooks/useTheme'

function LogIn() {
  const navigate = useNavigate()
  const { setBasicNotification } = useNotification()
  const [mounted, setMounted] = useState(false)
  const { user, setInstance, isInitialized, hasLoggedIn, logoutReason } =
    useAuth()
  const { shortName: instanceId } = useParams()
  const { instanceConfigurations, isSSO, is2FA } = useConfiguration()
  const {
    texts: { client_name: clientName },
  } = useTheme()

  useEffect(() => {
    if (!instanceId) {
      navigate(`/auth/login/demo`)
    }
  }, [instanceId])

  useEffect(() => {
    if (logoutReason) setBasicNotification(logoutReason)
  }, [logoutReason])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (isInitialized && user?.instanceId !== instanceId) {
      setInstance(instanceId)
    }
  }, [user, isInitialized])

  if (!mounted) return <Box></Box>

  if (
    !hasLoggedIn &&
    instanceId &&
    !Object.keys(instanceConfigurations || {}).length
  ) {
    return <Box></Box>
  }

  return (
    <>
      <Container maxWidth="md">
        <Card
          sx={{
            boxShadow: 2,
            height: '75vh',
            px: 10,
            py: 14,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <Box
              sx={{
                width: '50%',
                marginX: 'auto',
                textAlign: 'center',
              }}
            >
              <Typography variant="h1">Welcome to</Typography>
              <Logo />
            </Box>
          </Box>
          <Helmet title="Log In" />

          <Typography
            variant="h2"
            textAlign="center"
            fontWeight="medium"
            my={6}
          >
            {clientName}
          </Typography>

          {isSSO ? (
            <SSOComponent shortName={instanceId} />
          ) : (
            <LogInComponent is2FA={is2FA} shortName={instanceId} />
          )}
        </Card>
      </Container>
    </>
  )
}

export default LogIn
