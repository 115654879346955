import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedListHR from 'components/styled/ExpandedListHR'
import InvoiceExpandedListItem from 'components/common/ExpandedList/components/InvoiceExpandedListItem'

// local components
import ShowMoreContent from './components/ShowMoreContent'

const gridWidth = 6

function PostageInvoicesExpandedList({
  children,
  expanded,
  rowData,
  columns,
  getDataDisplay,
  visibleColumns,
  collapseDetails,
}) {
  const [open, setOpen] = useState(true)

  return (
    <Collapse
      in={expanded}
      sx={{
        textAlign: 'left',
        mt: 0,
        '& .MuiCollapse-entered': {
          mt: 4,
        },
      }}
    >
      <Grid
        sx={{ p: 6, bgcolor: 'background.paper' }}
        container
        columns={gridWidth}
        spacing={30}
      >
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight="medium" fontSize={14}>
              Details
            </Typography>
            {collapseDetails && (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </Box>
          <ExpandedListHR />
          <ShowMoreContent
            grid
            rowData={rowData}
            columns={columns}
            open={open}
            getDataDisplay={getDataDisplay}
            visibleColumns={visibleColumns}
            ExpandedListItemComponent={InvoiceExpandedListItem}
          />
        </Grid>
      </Grid>
      {children}
    </Collapse>
  )
}

export default PostageInvoicesExpandedList
