import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import useTheme from '@mui/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import XIcon from '@mui/icons-material/Close'

import useSidebar from 'hooks/useSidebar'

import withDataList from 'hocs/withDataList'

function DataListSidebar({ title, sidebarContent, isMobileView }) {
  const location = useLocation()
  const { open, setOpen } = useSidebar('datalist')
  const { open: navOpen, setOpen: setNavOpen } = useSidebar('navigation')
  const showSidebar = !isMobileView || (isMobileView && open)

  // close sidebar on search on mobile
  useEffect(() => {
    if (location.state && isMobileView && open) {
      setOpen(false)
    }
  }, [location.state])

  // close nav sidebar when this sidebar is open
  useEffect(() => {
    if (open && navOpen) {
      setNavOpen(false)
    }
  }, [open])

  return (
    <Box>
      <Collapse in={open} orientation="horizontal">
        <Box sx={{ width: showSidebar ? '424px' : '0px' }}>
          <Card
            sx={{
              px: 8,
              py: 7,
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              position: 'fixed',
              height: '100vh',
            }}
          >
            <IconButton onClick={() => setOpen(false)} sx={{ float: 'right' }}>
              <XIcon />
            </IconButton>
            <Typography variant="h1">{title}</Typography>
            <Fade in={open} timeout={{ appear: 2000, enter: 800 }}>
              <Box>{sidebarContent}</Box>
            </Fade>
          </Card>
        </Box>
      </Collapse>
    </Box>
  )
}

function DataListLayoutContent({ children, title, sidebarContent }) {
  const { open } = useSidebar('datalist')

  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('xl')) || undefined

  const showTable = !isMobileView || (isMobileView && !open)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mb: 4,
        }}
      >
        <Box
          sx={{ pt: 6, mx: 8, width: '100%', display: !showTable && 'none' }}
        >
          {children}
        </Box>
        <DataListSidebar
          title={title}
          sidebarContent={sidebarContent}
          isMobileView={isMobileView}
        />
      </Box>
    </>
  )
}

export default withDataList(DataListLayoutContent)
