import { formatInTimeZone } from 'date-fns-tz'
import enUS from 'date-fns/locale/en-US'

const formatTimeZone = (
  date,
  timeZone = 'America/New_York',
  formatString = 'M/dd/yyyy h:mm a zzz'
) => {
  try {
    return formatInTimeZone(new Date(date), timeZone, formatString, {
      locale: enUS,
    })
  } catch (err) {
    return 'N/A'
  }
}

export default formatTimeZone
