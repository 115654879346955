import React, { useMemo } from 'react'

import Box from '@mui/material/Box'

function PillChart({ colors = [], data = [] }) {
  const sum = useMemo(() => data.reduce((acc, value) => acc + value, 0), [data])

  return (
    <Box sx={{ width: '100%', height: '32px', display: 'flex' }}>
      {data.map((value, index) => (
        <Box
          sx={{
            bgcolor: colors[index],
            borderTopLeftRadius: index === 0 && '8px',
            borderBottomLeftRadius: index === 0 && '8px',
            borderTopRightRadius: index === data.length - 1 && '8px',
            borderBottomRightRadius: index === data.length - 1 && '8px',
            width: `${(value / sum) * 100}%`,
          }}
        />
      ))}
    </Box>
  )
}

export default PillChart
