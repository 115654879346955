import React, { useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import MuiAutocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'

import ListItem from './ListItem'
import DraggableCards from 'components/common/DraggableCards'
import ScrollableBox from 'components/styled/ScrollableBox'

import reorder from 'helpers/node/reorder'

import { ReactComponent as DragHandleIcon } from 'svgs/components/Draggable.svg'

const StyledDragHandleIcon = styled(DragHandleIcon)`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  cursor: pointer;
`

const Autocomplete = styled(MuiAutocomplete)`
  & .MuiAutocomplete-inputRoot {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const ListContainer = styled(Box)`
  display: grid;
  grid-template-rows: 16px 114px auto;
`

function List({
  title,
  name,
  optionKey,
  selectedOptions,
  availableOptions,
  getOptionDisplay = (option) => option[optionKey],
  itemProps,
  readonly,
}) {
  const { values, handleChange } = useFormikContext()
  const [searchValue, setSearchValue] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)

  const addOptionValue = (optionArray, option, fieldName = name) => {
    const valueExists = optionArray.find(
      (val) => val[optionKey] === option[optionKey]
    )

    if (!valueExists) {
      handleChange({
        target: { name: fieldName, value: [...optionArray, option] },
      })
    }
  }

  const handleAutocompleteChange = (e, value) => {
    setSelectedOption(value)
  }

  const handleInputChange = (_e, value) => {
    setSearchValue(value)
  }

  const handleAdd = () => {
    let foundOption = availableOptions
      .filter(
        (option) =>
          !selectedOptions.map((o) => o[optionKey]).includes(option[optionKey])
      )
      .find(
        (option) =>
          option[optionKey] === selectedOption?.[optionKey] ||
          option[optionKey] === searchValue
      )

    if (foundOption) {
      addOptionValue(selectedOptions, foundOption)

      if (name === 'searchInputs') {
        addOptionValue(
          values.resultTableColumns,
          foundOption,
          'resultTableColumns'
        )
      }

      // clear search field
      setSelectedOption(null)
    }
  }

  const handleRemove = (index) => {
    const newOptions = [...selectedOptions]
    newOptions.splice(index, 1)

    handleChange({
      target: { name, value: newOptions },
    })
  }

  const handleDragEnd = (result) => {
    const reorderedSelectedOptions = reorder(
      selectedOptions,
      result.source.index,
      result.destination.index
    )

    handleChange({ target: { name, value: reorderedSelectedOptions } })
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAdd()
    }
  }

  const handleFilter = (options) => {
    const filtered = options.filter(
      (option) =>
        !selectedOptions.find(
          (opt) =>
            opt[optionKey].toLowerCase() === option[optionKey].toLowerCase()
        ) && option[optionKey].toLowerCase().includes(searchValue.toLowerCase())
    )
    return filtered
  }

  const draggableCards = useMemo(
    () => (
      <DraggableCards
        id={`${title}-droppable`}
        handle={(props) => (
          <Box
            component="div"
            sx={{ display: 'flex', marginY: 'auto' }}
            {...props}
          >
            <StyledDragHandleIcon />
          </Box>
        )}
        handleDragEnd={handleDragEnd}
      >
        {(selectedOptions || []).map((item, index) => (
          <ListItem
            {...itemProps}
            key={`input-list-item-${index}`}
            display={getOptionDisplay(item)}
            handleRemove={() => handleRemove(index)}
          />
        ))}
      </DraggableCards>
    ),
    [selectedOptions]
  )

  return (
    <ListContainer sx={{ height: { md: '55vh' } }}>
      <Typography variant="h2" fontWeight="medium">
        {title}
      </Typography>
      {!readonly ? (
        <>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              pt: 1,
            }}
          >
            <Box sx={{ width: '100%', my: 'auto' }}>
              <Autocomplete
                autoComplete
                autoHighlight
                disablePortal
                fullWidth
                freeSolo
                openOnFocus
                options={availableOptions}
                filterOptions={handleFilter}
                getOptionLabel={(option) => getOptionDisplay(option) ?? option}
                value={selectedOption}
                onChange={handleAutocompleteChange}
                inputValue={searchValue}
                onInputChange={handleInputChange}
                onKeyPress={handleKeyPress}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select new input"
                    color="green"
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                width: '22%',
                my: 'auto',
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '100%',
                  height: '56px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Box>
          </Box>
          <ScrollableBox
            sx={{
              overflowY: { sm: 'visible', md: 'scroll' },
            }}
          >
            {selectedOptions?.length > 0 && <>{draggableCards}</>}
          </ScrollableBox>
        </>
      ) : (
        <ScrollableBox sx={{ height: '55vh' }}>
          <Stack spacing={4} sx={{ p: 4 }}>
            {(selectedOptions || []).map((option) => (
              <Card
                sx={{
                  border: '1px solid',
                  borderColor: 'lightgray.main',
                  p: 2,
                  width: '100%',
                }}
              >
                <Typography>{option.display}</Typography>
              </Card>
            ))}
          </Stack>
        </ScrollableBox>
      )}
    </ListContainer>
  )
}

export default List
