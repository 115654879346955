import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { useDropzone } from 'react-dropzone'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'

const UnderlinedText = styled.span`
  text-decoration: underline;
`

const ONE_MB = 1000000

function FileAttachDropzone({
  file,
  onChange,
  error,
  disabled,
  display,
  setFieldError,
}) {
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        const rejectedFile = rejectedFiles[0]

        if (rejectedFile)
          setFieldError('file', rejectedFile.errors?.[0]?.message)
      } else {
        onChange(acceptedFiles[0])
      }
    },
    [onChange]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    maxFiles: 1,
    maxSize: ONE_MB,
  })

  return (
    <>
      <Box component="label">{display}*</Box>
      <Box
        component="div"
        {...getRootProps()}
        sx={{
          minHeight: '150px',
          width: '100%',
          border: '1px solid',
          borderColor: error ? 'error.main' : 'lightgray.main',
          bgcolor: 'lightgray.light',
          borderRadius: '4px',
          display: 'flex',
          userSelect: 'none',
          cursor: 'pointer',
        }}
      >
        {!disabled && <Box component="input" {...getInputProps()} />}
        <Box
          sx={{ width: '50%', height: '80%', m: 'auto', textAlign: 'center' }}
        >
          {file ? (
            <Box>
              <CloudUploadIcon sx={{ color: 'darkgray.main' }} />
              <Typography color="darkgray.main">
                Selected file: <UnderlinedText>{file.name}</UnderlinedText>
              </Typography>
            </Box>
          ) : (
            <Box>
              <CloudUploadIcon sx={{ color: 'darkgray.main' }} />
              <Typography color="darkgray.main">
                Drag and drop a pdf here or{' '}
                <UnderlinedText>browse files</UnderlinedText>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {error && (
        <Typography color="error.main" sx={{ mt: '1px' }}>
          {error}
        </Typography>
      )}
    </>
  )
}

export default FileAttachDropzone
