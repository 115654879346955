import React from 'react'

function ProcessingWhite({ width = '24', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
    >
      <circle
        cx="24"
        cy="24"
        r="24"
        fill="#fff"
        stroke="#76BC21"
        stroke-width="2"
      />
      <path
        d="M36.5118 12.9418L34.2421 15.2228C31.6336 12.4788 27.9975 10.9092 24.2146 10.9092C18.1282 10.9092 12.6854 14.9066 10.9351 20.8011C10.8109 21.2302 11.1496 21.6593 11.6013 21.6593L14.1759 21.648C14.4582 21.648 14.7067 21.4673 14.797 21.1963C16.1182 17.5828 19.7542 14.692 24.2146 14.692C27.0038 14.692 29.68 15.8664 31.5771 17.899L29.0025 20.4849C28.8105 20.6769 28.946 21.0156 29.2171 21.0156H36.5795C36.8392 21.0156 37.0425 20.8124 37.0425 20.5527V13.1563C37.0312 12.8853 36.7037 12.7498 36.5118 12.9418Z"
        fill="#76BC21"
      />
      <path
        d="M11.4885 35.7512L13.7582 33.4702C16.3667 36.2142 20.0028 37.7838 23.7856 37.7838C29.8721 37.7838 35.3149 33.7864 37.0652 27.8919C37.1894 27.4628 36.8506 27.0337 36.3989 27.0337L33.8243 27.045C33.542 27.045 33.2936 27.2257 33.2033 27.4967C31.8821 31.1102 28.246 34.0009 23.7856 34.0009C20.9965 34.0009 18.3202 32.8266 16.4232 30.794L18.9978 28.2081C19.1897 28.0161 19.0542 27.6773 18.7832 27.6773H11.4207C11.161 27.6773 10.9578 27.8806 10.9578 28.1403V35.5367C10.9691 35.8077 11.2965 35.9432 11.4885 35.7512Z"
        fill="#76BC21"
      />
    </svg>
  )
}

export default ProcessingWhite
