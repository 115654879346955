import React from 'react'

function Completed({ width = '24', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="currentColor" />
      <path
        d="M23.6314 37.0913C16.8041 36.895 11.111 31.2004 10.9147 24.3713C10.7075 17.1713 16.346 11.215 23.4242 10.9095C23.7405 10.8986 24.0022 11.1495 24.0022 11.4768V13.615C24.0022 14.1277 23.7623 14.3568 23.4678 14.3677C18.3528 14.6513 14.2957 18.9277 14.372 24.1313C14.4483 29.2477 18.7454 33.5568 23.8605 33.6331C29.0628 33.7095 33.3489 29.6513 33.6325 24.535C33.6543 24.2404 33.8833 24.0004 34.1778 24.0004H36.5226C36.8389 24.0004 37.1116 24.2732 37.0898 24.5895C36.7735 31.6586 30.8187 37.2877 23.6314 37.0913Z"
        fill="white"
      />
      <path
        d="M22.0287 29.0519L17.0882 24.1101C16.87 23.8919 16.87 23.5319 17.0882 23.3137L18.7459 21.6555C18.964 21.4373 19.313 21.4373 19.5421 21.6555L22.1814 24.2955C22.3123 24.4264 22.5304 24.4264 22.6613 24.2955L32.2043 14.7501C32.4224 14.5319 32.7714 14.5319 32.9895 14.7501L34.6473 16.4082C34.8654 16.6264 34.8654 16.9755 34.6473 17.1937L22.803 29.0519C22.5958 29.2701 22.2468 29.2701 22.0287 29.0519Z"
        fill="white"
      />
    </svg>
  )
}

export default Completed
