import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import CircularProgress from '@mui/material/CircularProgress'
import Fade from '@mui/material/Fade'

import Item from './Item'
import ScrollableBox from 'components/styled/ScrollableBox'

import { DATALIST_DIMENSIONS } from 'utils/constants'

import useDataListContext from 'hooks/context/useDataListContext'

import getDataListItemWidth from 'helpers/css/getDataListItemWidth'
import useDimensions from 'hooks/useDimensions'
import FullBox from 'components/styled/FullBox'

function RowItem({
  row,
  index,
  sharedItemProps,
  currentItemRef,
  layoutType,
  statusColName,
  minWidth,
}) {
  const { activeIndex } = useDataListContext()

  return (
    <Box
      component="div"
      key={row.id}
      ref={index === activeIndex ? currentItemRef : undefined}
    >
      <Item
        {...sharedItemProps}
        variant={layoutType}
        index={index}
        active={index === activeIndex}
        rowData={row}
        statusColName={statusColName}
        minWidth={minWidth}
      />
    </Box>
  )
}
function DataList({
  dataList,
  columns,
  loading,
  columnCount = 7,
  multiSelect = false,
  getDataDisplay,
  actionButtonProps = [],
  fixedButtonProps = [],
  statusColName,
  setSortingList,
  layoutType,
  visibleColumns,
  height = DATALIST_DIMENSIONS.HEIGHT,
  flipSort = [],
  previewName = 'preview',
  itemProps = {},
  showColumns = true,
  scrollableBoxSx = {},
  expandable = true,
}) {
  const {
    setDimensions,
    dimensions: dataListDimensions,
    maxWidths,
  } = useDataListContext()
  const preview = useMemo(
    () => layoutType === previewName,
    [layoutType, previewName]
  )

  const [scrollDimensions, setScrollDimensions] = useState({
    scrollLeft: 0,
    scrollTop: 0,
  })

  const dataListRef = useRef(null)
  const [columnsRef, setColumnsRef] = useState(null)
  const onColumnsRefChange = useCallback((node) => {
    setColumnsRef({ current: node })
  }, [])
  const dataListRootRef = useRef(null)
  const scrollRef = useRef(null)
  const dimensions = useDimensions({ ref: dataListRootRef })
  const currentItemRef = useRef(null)
  const { activeIndex, setActiveIndex, sortedCols, setSortedCols } =
    useDataListContext()

  const [modalProps, setModalProps] = useState({ open: false })

  const columnData = useMemo(() => columns, [columns])
  const rowData = useMemo(() => dataList, [dataList])

  const allShownColumns =
    visibleColumns ?? columns.map((col) => col.id).slice(0, columnCount)

  const getDataDisplayCallback = useCallback(getDataDisplay, [getDataDisplay])

  // props shared between headers and rows
  const sharedItemProps = useMemo(
    () => ({
      columns: columnData ?? [],
      setActiveIndex,
      getDataDisplay: getDataDisplayCallback,
      multiSelect,
      visibleColumns: preview ? [] : allShownColumns,
      actionButtonProps,
      fixedButtonProps,
      modalProps,
      setModalProps,
      preview,
      sharedSx: {
        minWidth: undefined,
        width: !preview ? getDataListItemWidth(maxWidths) : undefined,
      },
      ...itemProps,
    }),
    [
      columns,
      preview,
      maxWidths,
      fixedButtonProps,
      actionButtonProps,
      itemProps,
    ]
  )

  const handleColumnClick = useCallback(
    (col) => {
      const newSortedCols = {}
      newSortedCols[col.id] = sortedCols[col.id]

      // alternate between not sorted, asc, and desc
      if (!newSortedCols[col.id]) {
        newSortedCols[col.id] = { id: col.id, desc: false }
      } else if (newSortedCols[col.id].desc === false) {
        newSortedCols[col.id] = { id: col.id, desc: true }
      } else {
        if (flipSort.includes(col.id)) {
          newSortedCols[col.id] = { id: col.id, desc: false }
        } else {
          delete newSortedCols[col.id]
        }
      }

      setSortedCols(newSortedCols)
      setSortingList(Object.values(newSortedCols))
    },
    [sortedCols, setSortedCols, setSortingList, flipSort]
  )

  const handleScrollX = useCallback(
    (target) => {
      setScrollDimensions({
        ...scrollDimensions,
        scrollLeft: target.scrollLeft,
      })
    },
    [setScrollDimensions]
  )

  const updateDimensions = useCallback(() => {
    if (
      dimensions &&
      (dataListDimensions.width !== dimensions.width ||
        dataListDimensions.height !== dimensions.height ||
        dataListDimensions.scrollLeft !== scrollDimensions?.scrollLeft)
    )
      setDimensions({
        width: dimensions?.width,
        height: dimensions?.height,
        scrollLeft: scrollDimensions?.scrollLeft,
      })
  }, [dimensions, scrollDimensions])

  useEffect(() => {
    if (scrollRef.current && currentItemRef.current) {
      const maxDiff =
        scrollRef.current.clientHeight - currentItemRef.current.clientHeight
      const minDiff = currentItemRef.current.clientHeight
      const diff =
        currentItemRef.current.offsetTop - scrollRef.current.scrollTop

      if (diff > maxDiff || diff < minDiff) {
        scrollRef.current.scrollTop =
          currentItemRef.current.offsetTop - maxDiff / 2
      }
    }
  }, [activeIndex, rowData])

  useEffect(updateDimensions, [updateDimensions])

  const rowDatas = useMemo(() => {
    return rowData.map((row, index) => (
      <RowItem
        key={row.id}
        row={row}
        index={index}
        sharedItemProps={sharedItemProps}
        layoutType={layoutType}
        statusColName={statusColName}
        currentItemRef={currentItemRef}
      />
    ))
  }, [layoutType, sharedItemProps])

  return (
    <>
      <List
        ref={dataListRootRef}
        component="div"
        disablePadding
        sx={{
          width: '100%',
          height,
          bgcolor: rowData.length > 0 && 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          overflow: loading && 'hidden',
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        {!loading ? (
          <>
            <Fade in={!loading}>
              <FullBox>
                {showColumns && rowData.length > 0 && (
                  <Item
                    {...sharedItemProps}
                    key={`data-list-column-names`}
                    variant="columns"
                    sortedCols={sortedCols}
                    handleColumnClick={handleColumnClick}
                    sharedSx={{
                      ...sharedItemProps.sharedSx,
                      transform: `translateX(-${scrollDimensions.scrollLeft}px)`,
                    }}
                    innerRef={onColumnsRefChange}
                    expandable={expandable}
                  />
                )}
                <ScrollableBox
                  ref={dataListRef}
                  psRef={scrollRef}
                  sx={{
                    overflow: 'auto',
                    height: `calc(${height} - ${
                      columnsRef?.current?.clientHeight || 0
                    }px)`,
                    width: '100%',
                    ...scrollableBoxSx,
                  }}
                  perfectScrollbarProps={{
                    onScrollX: handleScrollX,
                  }}
                >
                  {rowDatas}
                </ScrollableBox>
              </FullBox>
            </Fade>
          </>
        ) : (
          <CircularProgress thickness={2} sx={{ m: 'auto' }} />
        )}
      </List>
    </>
  )
}

export default DataList
