import { createContext, useReducer } from 'react'

const initialState = {
  sidebars: {
    navigation: { open: true, mounted: false },
    datalist: { open: false },
  },
}

const SET_OPEN = 'SET_OPEN'
const SET_MOUNTED = 'SET_MOUNTED'

const SidebarReducer = (state, action) => {
  switch (action.type) {
    case SET_OPEN: {
      const newSidebars = { ...state.sidebars }
      newSidebars[action.payload.id] = {
        ...newSidebars[action.payload.id],
        open: action.payload.open,
      }

      return {
        ...state,
        sidebars: {
          ...newSidebars,
        },
      }
    }
    case SET_MOUNTED: {
      const newSidebars = { ...state.sidebars }
      newSidebars[action.payload.id] = {
        ...newSidebars[action.payload.id],
        mounted: action.payload.mounted,
      }

      return {
        ...state,
        sidebars: {
          ...newSidebars,
        },
      }
    }
    default:
      return state
  }
}

const SidebarContext = createContext(null)

function SidebarProvider({ children }) {
  const [state, dispatch] = useReducer(SidebarReducer, {
    ...initialState,
    open: initialState.open,
  })

  const toggleOpen = (id) => {
    dispatch({
      type: SET_OPEN,
      payload: {
        id,
        open: !state.sidebars[id]?.open,
      },
    })
  }

  const setOpen = (id, open) => {
    dispatch({
      type: SET_OPEN,
      payload: {
        id,
        open,
      },
    })
  }

  const setMounted = (id, mounted) => {
    dispatch({
      type: SET_MOUNTED,
      payload: {
        id,
        mounted,
      },
    })
  }

  return (
    <SidebarContext.Provider
      value={{
        ...state,
        setOpen,
        toggleOpen,
        setMounted,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export { SidebarContext, SidebarProvider }
