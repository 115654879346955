const saveData = () => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'

  return (data, fileName, type) => {
    const blob = new Blob([data], { type })
    const url = window.URL.createObjectURL(blob)

    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }
}

export default saveData()
