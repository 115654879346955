import React, { useRef, useEffect } from 'react'
import axios from 'utils/axios'
import { APP_KEY } from 'utils/constants'
import { useFormikContext } from 'formik'

import Modal from 'components/common/Modal'
import Form from './Form'
import Content from './Content'

import useTheme from '@mui/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import useSavedSearchModal from 'hooks/useSavedSearchModal'
import rh from 'helpers/css/rh'

function SavedSearchModalContent({ type, setType, readonly }) {
  const { submitForm, dirty } = useFormikContext()
  const { saveDisabled, setSubmitAction } = useSavedSearchModal()

  const cancelButtonRef = useRef(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleFormSubmit = (submitAction = 'save') => {
    setSubmitAction(submitAction)
    submitForm()
  }

  const handleModalClose = () => {
    cancelButtonRef?.current?.click()
  }

  const footerButtonProps = [
    !readonly && {
      children: 'Save',
      color: 'primary',
      variant: 'contained',
      disabled: saveDisabled,
      onClick: () => handleFormSubmit('save'),
    },
    !readonly && {
      children: 'Delete',
      color: 'primary',
      variant: 'outlined',
      hidden: type !== 'edit',
      popover: true,
      popoverMessage: (
        <>
          Are you sure you want to delete this saved search?
          <br /> This action is irreversible.
        </>
      ),
      popoverProps: {
        anchorOrigin: {
          vertical: -100,
          horizontal: -64,
        },
      },
      onClick: () => handleFormSubmit('delete'),
    },
    {
      children: 'Cancel',
      color: 'secondary',
      variant: 'contained',
      ref: cancelButtonRef,
      popover: dirty,
      popoverMessage: (
        <>
          You have unsaved changes.
          <br /> Are you sure you want to return?
        </>
      ),
      popoverProps: {
        anchorOrigin: {
          vertical: -100,
          horizontal: -64,
        },
      },
      onClick: () => setType('closed'),
    },
  ].filter((b) => b)

  return (
    <Modal
      onClose={handleModalClose}
      open={type !== 'closed'}
      height={rh(888)}
      width={'1144px'}
      footerButtonProps={footerButtonProps}
      scrollable={isMobile}
    >
      <Content readonly={readonly} />
    </Modal>
  )
}

function SavedSearchModal({
  type,
  setType,
  defaultSfc,
  activeSfc,
  title,
  fetchSearchConfig,
  setActiveId,
  moduleId = '5',
  readonly = false,
}) {
  const {
    setSfcEtag,
    sfcEtag,
    type: typeContext,
    setType: setTypeContext,
    setSubmitAction,
    setActiveSfcId,
  } = useSavedSearchModal()

  // load etag on modal open (edit mode only)
  useEffect(() => {
    const asyncSetEtag = async () => {
      const res = await axios.get(
        `/modules/${moduleId}/search-field-configurations/${activeSfc.id}`,
        {
          params: {
            app_key: APP_KEY,
          },
        }
      )

      if (res.status !== 200) return null

      const { etag } = res.headers

      if (etag !== sfcEtag) setSfcEtag(etag)
    }

    if (type === 'edit' && activeSfc) {
      asyncSetEtag()
    } else if (sfcEtag) {
      setSfcEtag(undefined)
    }
  }, [type])

  const initialSfc = type === 'add' ? defaultSfc : activeSfc
  const initialValues = {
    title: type !== 'edit' ? '' : title,
    searchInputs: initialSfc?.fields,
    resultTableColumns: initialSfc?.resultColumns,
  }
  const initialStatus = {
    type,
    submitAction: 'save',
    sfcId: activeSfc?.id,
  }

  useEffect(() => {
    setTypeContext(type)
    setSubmitAction('save')
    setActiveSfcId(activeSfc?.id)
  }, [type, activeSfc])

  return (
    <Form
      type={typeContext}
      setType={setType || setTypeContext}
      initialValues={initialValues}
      initialStatus={initialStatus}
      fetchSearchConfig={fetchSearchConfig}
      setActiveId={setActiveId}
      moduleId={moduleId}
    >
      <SavedSearchModalContent
        type={typeContext}
        setType={setType || setTypeContext}
        readonly={readonly}
      />
    </Form>
  )
}

export default SavedSearchModal
