import React, { useRef, useMemo, useCallback, useState } from 'react'
import * as Yup from 'yup'

import FormRenderer from 'components/common/FormRenderer'
import Modal from 'components/common/Modal'

import useNotification from 'hooks/context/useNotification'
import { PRINT_ASSET_MIME_TYPES } from 'utils/constants'

const validationSchema = Yup.object().shape({
  key_id: Yup.string('Key must be a string').required('Key is required'),
})

const generateRenderingData = (collateralAvailable = false) => {
  const fieldDataMap = {
    name: {
      field: 'name',
      display: 'Name',
      maxLength: 40,
    },
    custom_data: {
      field: 'custom_data',
      display: 'Tags',
      data_type: 'tags',
      default_value: [],
    },
    collateral_available: {
      field: 'collateral_available',
      display: 'Available For Collateral',
      default_value: false,
      data_type: 'checkbox',
    },
    assetfile: {
      field: 'assetfile',
      data_type: 'fileupload',
      placeholder: 'Drag and drop files here',
      includePreview: false,
      multiple: true,
      accept: collateralAvailable
        ? 'image/png, image/jpeg, image/tiff'
        : ['application/postscript', '.psd']
            .concat(Object.values(PRINT_ASSET_MIME_TYPES))
            .join(', '),
      acceptLabel: collateralAvailable
        ? 'PNG, JPEG, TIFF'
        : Object.keys(PRINT_ASSET_MIME_TYPES).join(', '),
    },
  }

  const renderingData = [...Object.values(fieldDataMap)]

  return renderingData
}

function PrintAssetsAddModal({ open, onClose, addPrintAsset, tabIndex }) {
  const [collateralAvailable, setCollateralAvailable] = useState(false)
  const [loading, setLoading] = useState(false)
  const formikRef = useRef()
  const { setBasicNotification, setError } = useNotification()

  const modalProps = {
    title: `Create New Asset`,
    subTitle: undefined,
    scrollable: true,
    size: undefined,
    width: '732px',
    height: '805px',
    hideButtonsBorder: true,
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const formDataValues = {}

      Object.entries(values).forEach(([key, value]) => {
        if (key.includes('custom_data')) {
          if (!formDataValues['custom_field_data']) {
            formDataValues['custom_field_data'] = {}
          }

          if (Array.isArray(value))
            value.forEach(({ name, value }) => {
              formDataValues['custom_field_data'][name] = value
            })
        } else {
          if (key === 'name' && !values.name?.trim?.() && values.assetfile) {
            formDataValues[key] = values.assetfile.name
          } else {
            formDataValues[key] = value
          }
        }
      })

      formDataValues['custom_field_data'] = JSON.stringify(
        formDataValues['custom_field_data']
      )

      delete formDataValues['custom_data']

      if (tabIndex === 1) {
        formDataValues['internal'] = 1
      }

      await addPrintAsset({ updateData: formDataValues })
      setBasicNotification('Print asset has been successfully created')
      onClose()
    } catch (err) {
      setError(err?.response?.data?.display_message || err?.message)
    } finally {
      setLoading(false)
    }
  }

  const renderingData = useMemo(
    () => generateRenderingData(collateralAvailable),
    [collateralAvailable]
  )

  const handleChange = useCallback(
    (e) => {
      if (formikRef.current && e.target.name === 'collateral_available') {
        if (
          e.target.value &&
          formikRef.current.values.assetfile &&
          !formikRef.current.values.assetfile?.type?.includes('image')
        ) {
          setError('Only image files are supported for Collateral')
          formikRef.current.handleChange({
            target: { name: 'collateral_available', value: false },
          })
        } else {
          setCollateralAvailable(e.target.value)
        }
      }
    },
    [formikRef]
  )

  return (
    <Modal {...modalProps} open={open} onClose={onClose}>
      <FormRenderer
        validateOnChange
        renderingData={renderingData}
        innerRef={formikRef}
        handleSubmit={handleSubmit}
        onChange={handleChange}
        validationSchema={validationSchema}
        footerActionProps={[
          {
            children: 'Upload',
            color: 'primary',
            variant: 'contained',
            size: 'action-header',
            onClick: () => {
              handleSubmit(formikRef.current?.values, formikRef.current)
            },
            loading: loading,
          },
          {
            children: 'Cancel',
            color: 'primary',
            variant: 'outlined',
            size: 'action-header',
            onClick: () => onClose(),
            disabled: loading,
          },
        ]}
        validate={(values) => {
          if (!values.assetfile) {
            return {
              assetfile: 'File is required',
            }
          } else {
            return {}
          }
        }}
      />
    </Modal>
  )
}

export default PrintAssetsAddModal
