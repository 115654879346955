const fragmentPath = (path) => {
  const splitPath = path.split(/\//gm).slice(1)
  let pathHistory = ''

  const fragmentedPath = splitPath.map((pathname) => {
    const href = `${pathHistory}/${pathname}`
    pathHistory = href

    return {
      pathname,
      href,
    }
  })

  return fragmentedPath
}

export default fragmentPath
