import { useState, useEffect } from 'react'
import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import axios from 'utils/axios'
import { etagFetcher } from 'utils/fetcher'

import useLetterStatusHistory from './useLetterStatusHistory'
import findErrorMessage from 'helpers/api/findErrorMessage'

const useLetters = (params) => {
  const [loading, setLoading] = useState(false)
  const { getLastUpdatedUser } = useLetterStatusHistory()
  const [updatedLetters, setUpdatedLetters] = useState([])

  const { data, isValidating, mutate } = useSWR(
    params
      ? `/letters?${urlSerialize({
          ...params,
        })}`
      : null,
    etagFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const updateLetter = async (id, data, shouldMutate = true) => {
    const {
      headers: { etag },
    } = await axios.get(`/letters/${id}`)

    try {
      const res = await axios.patch(`/letters/${id}`, data, {
        headers: {
          'If-Match': etag,
        },
      })

      if (res.status !== 200) {
        throw new Error('Error updating letter')
      }

      if (shouldMutate) mutate()

      return res.data
    } catch (err) {
      mutate()
      throw new Error(findErrorMessage(err, 'An error has occurred', 'letters'))
    }
  }

  const updateLetters = async (letters, shouldMutate = true) => {
    try {
      const getEtag = async (letterId) => {
        const {
          headers: { etag },
        } = await axios.head(`/letters/${letterId}`)
        return etag
      }

      // fetch all etags at the same time
      const etags = await Promise.all(
        letters.map(async (letter) => getEtag(letter.id))
      )

      const res = await axios.patch(
        `/letters`,
        { letters },
        {
          headers: {
            'If-Match': etags.join(','),
          },
        }
      )

      if (res.status !== 200) {
        throw new Error('Error updating letters')
      }

      if (shouldMutate) mutate()

      return res.data
    } catch (err) {
      mutate()
      throw new Error(findErrorMessage(err, 'An error has occurred', 'letters'))
    }
  }

  const attachLetters = async (letters, file, shouldMutate = true) => {
    try {
      const getEtag = async (letterId) => {
        const {
          headers: { etag },
        } = await axios.head(`/letters/${letterId}`)
        return etag
      }

      // fetch all etags at the same time
      const etags = await Promise.all(
        letters.map(async (letter) => getEtag(letter.id))
      )

      const formData = new FormData()

      formData.append('letters', JSON.stringify(letters))
      formData.append('filedata', file)

      const res = await axios.post(`/letter-attachments `, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'If-Match': etags.join(','),
        },
      })

      if (res.status !== 200) {
        throw new Error('Error updating letters')
      }

      if (shouldMutate) mutate()

      return res.data
    } catch (err) {
      mutate()
      throw new Error(
        err.response?.data?.display_message || 'An error has occurred'
      )
    }
  }

  const updateSearchedLetters = async ({
    updateData,
    checkAllPreset = 'all-matching-query',
    shouldMutate = true,
  }) => {
    try {
      const updatedParams = {
        ...params,
      }

      delete updatedParams['paging[page_size]']
      delete updatedParams['paging[page]']

      const getEtag = async () => {
        const {
          headers: { etag },
        } = await axios.head(`/letters`, { params: updatedParams })
        return etag
      }

      // if etag not provided, fetch it
      let etag = data.etag
      if (!etag) {
        etag = await getEtag()
      }

      const res = await axios.patch(
        `/searched-letters`,
        { ...updateData },
        {
          params: updatedParams,
          headers: {
            'If-Match': etag,
          },
        }
      )

      if (res.status !== 204) {
        throw new Error('Error updating letters')
      }

      if (shouldMutate) mutate()

      return res.data
    } catch (err) {
      mutate()
      throw new Error(findErrorMessage(err, 'An error has occurred', 'letters'))
    }
  }

  useEffect(() => {
    const asyncUpdateLetter = async (letter) => {
      const newLetter = { ...letter }

      if (params?.status_filter === 'On Hold') {
        newLetter.held_by = getLastUpdatedUser(letter.id, 'On Hold')
      } else if (params?.status_filter === 'Rejected') {
        newLetter.rejected_by = getLastUpdatedUser(letter.id, 'Rejected')
      } else if (params?.status_filter === 'Approved') {
        newLetter.approved_by = getLastUpdatedUser(letter.id, 'Approved')
      }

      return newLetter
    }

    const asyncUpdateLetters = async () => {
      const letters = data?._embedded?.letters ?? []
      const updatedLetters = await Promise.all(letters.map(asyncUpdateLetter))
      setUpdatedLetters(updatedLetters)
      setLoading(false)
    }

    if (data) {
      setLoading(true)
      asyncUpdateLetters()
    }
  }, [data])

  return {
    letters: updatedLetters,
    embedded: data?._embedded ?? {},
    loading:
      (!data?._embedded?.letters && !data?.error) || isValidating || loading,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    updateLetter,
    updateLetters,
    updateSearchedLetters,
    attachLetters,
  }
}

export default useLetters
