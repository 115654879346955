import useOrderApprovalTotal from 'hooks/useOrderApprovalTotal'

const useOrderApprovalAggregate = () => {
  const {
    total: processingTotal,
    mutate: processingMutate,
    loading: processingLoading,
  } = useOrderApprovalTotal({
    state: 'OrderApproval',
    status_filter: 'Processing',
    'paging[page_size]': 1,
  })
  const {
    total: onHoldTotal,
    mutate: onHoldMutate,
    loading: onHoldLoading,
  } = useOrderApprovalTotal({
    state: 'OrderApproval',
    status_filter: 'On Hold',
    'paging[page_size]': 1,
  })
  const {
    total: pendingApprovalTotal,
    mutate: pendingApprovalMutate,
    loading: pendingApprovalLoading,
  } = useOrderApprovalTotal({
    state: 'OrderApproval',
    status_filter: 'Pending Approval',
    'paging[page_size]': 1,
  })
  const {
    total: productionTotal,
    mutate: productionMutate,
    loading: productionLoading,
  } = useOrderApprovalTotal({
    state: 'OrderApproval',
    status_filter: 'Production',
    'paging[page_size]': 1,
  })

  const mutate = () => {
    processingMutate()
    onHoldMutate()
    pendingApprovalMutate()
    productionMutate()
  }

  const loading =
    processingLoading ||
    onHoldLoading ||
    pendingApprovalLoading ||
    productionLoading

  return {
    mutate,
    aggregate: {
      counts: {
        status_group_filters: {
          Processing: processingTotal || 0,
          'On Hold': onHoldTotal || 0,
          'Pending Approval': pendingApprovalTotal || 0,
          Production: productionTotal || 0,
        },
      },
    },
    loading,
  }
}

export default useOrderApprovalAggregate
