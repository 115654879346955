import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import PDFViewer from 'components/common/PDFViewer'
import Modal from 'components/common/Modal'
import { usePrintAssetPreview } from 'hooks/print_assets'
import Async from 'components/common/Async'

function ContentPreview({ content, contentType = '', fileName }) {
  let contentComponent = content?.toString()

  if (typeof content === 'object') {
    contentComponent = ''
  }

  if (contentType?.toLowerCase().includes('pdf'))
    contentComponent = <PDFViewer url={content} fileName={fileName} />

  if (contentType.includes('tiff') || contentType.includes('adobe')) {
    return <Box>No preview available.</Box>
  }

  if (contentType.includes('text') && content instanceof Blob) {
    contentComponent = <Async observable={content.text()} />
  }

  if (contentType.includes('image')) {
    contentComponent = (
      <Box
        component="img"
        sx={{
          maxWidth: '100%',
          height: 'auto',
          width: 'auto',
          maxHeight: '100%',
        }}
        src={URL.createObjectURL(content)}
        alt=""
      />
    )
  }

  if (!content || !contentComponent) return <Box>No preview available.</Box>

  return (
    <Box
      sx={{
        overflow: 'auto',
        width: '100%',
        height: '70vh',
      }}
    >
      {contentComponent}
    </Box>
  )
}

function PreviewModal({ open, setOpen, asset }) {
  const { preview, refetch, loading } = usePrintAssetPreview({
    asset,
    version: asset?.version,
  })

  const handleClose = () => {
    setOpen(null)
  }

  useEffect(() => {
    if (asset && open) refetch()
  }, [asset, open])

  return (
    <Modal
      title="Preview"
      open={open}
      onClose={handleClose}
      width="60vw"
      footerButtonProps={[
        {
          children: 'Close',
          variant: 'contained',
          color: 'primary',
          onClick: handleClose,
        },
      ]}
    >
      <Box sx={{ display: 'flex' }}>
        {loading && <CircularProgress sx={{ mx: 'auto' }} />}
        {!loading && preview && (
          <ContentPreview
            content={preview}
            fileName={asset?._embedded?.document?.filename}
            contentType={asset?.mime_type}
          />
        )}
      </Box>
    </Modal>
  )
}

export default PreviewModal
