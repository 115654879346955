import React from 'react'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'

function AgencyPreferencesCell({ row, colId, getDataDisplay }) {
  return <TableCell>{getDataDisplay(row, colId)}</TableCell>
}

function AgencyPreferencesRow({
  row,
  rowKey,
  visibleColumns = [],
  getDataDisplay,
  setActiveAgency,
  columns,
}) {
  return (
    <>
      <TableRow key={row[rowKey]}>
        {visibleColumns.map((col) => (
          <AgencyPreferencesCell
            key={`${row[rowKey]}-${col}`}
            row={row}
            getDataDisplay={getDataDisplay}
            colId={col}
          />
        ))}
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActiveAgency(row)}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
    </>
  )
}

export default AgencyPreferencesRow
