const removeMatchingValues = (objToCompare, objToEdit) => {
  const outputObj = { ...objToEdit }

  for (let key in objToCompare) {
    if (objToCompare[key] === objToEdit[key]) {
      delete outputObj[key]
    }
  }

  return outputObj
}

export default removeMatchingValues
