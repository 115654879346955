import React, { useEffect, useState } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'

import SubTitleSubLayout from 'layouts/Landing/sublayouts/SubHeader'

import Search from 'components/correspondence/Search'

import decodeBase64 from 'helpers/node/decodeBase64'

import useConfiguration from 'hooks/useConfiguration'

function CorrespondenceSearchPage() {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [moduleId, setModuleId] = useState(undefined)
  const [intialData, setInitialData] = useState(undefined)
  const [baseActiveId, setBaseActiveId] = useState(undefined)
  const {
    navigation: { currentPath, modules },
  } = useConfiguration()

  useEffect(() => {
    try {
      const allModules = modules.flatMap((m) => m?.modules || [])
      const foundModule = allModules.find((m) => m.id === moduleId)
      const configuration = foundModule?.configurations?.find?.(
        (config) => config.configuration_type === 'field_definitions'
      )

      const fieldDefinitions = configuration.fieldsets.reduce(
        (acc, fieldset) => {
          return {
            ...acc,
            ...fieldset.fields,
          }
        },
        {}
      )

      const q = searchParams.get('q')

      if (q) {
        const values = JSON.parse(decodeBase64(q))

        setBaseActiveId(values.activeId)

        delete values.activeId

        const newValues = {}

        Object.keys(values).forEach((key) => {
          const fieldDefinition = fieldDefinitions[key]
          const valueKey = key.replace('_.metadata.', '')

          switch (fieldDefinition?.data_type) {
            case 'date':
            case 'datetime':
              newValues[valueKey] =
                Array.isArray(values[key]) &&
                values[key].filter((d) => d).length > 0
                  ? [new Date(values[key][0]), new Date(values[key][1])]
                  : [undefined, undefined]
              break
            default:
              newValues[valueKey] = values[key]
          }
        })

        setInitialData(newValues)
      }
    } catch (err) {}
  }, [moduleId])

  useEffect(() => {
    const newModuleId = currentPath.slice(-1)[0]?.id

    if (newModuleId !== undefined && moduleId !== newModuleId) {
      setModuleId(newModuleId)
    }
  }, [currentPath, modules, location.state])

  return (
    <SubTitleSubLayout>
      <CssBaseline />
      <Container maxWidth="md" sx={{ pb: 16 }}>
        <Search initialData={intialData} baseActiveId={baseActiveId} isPage />
      </Container>
    </SubTitleSubLayout>
  )
}

export default CorrespondenceSearchPage
