import React, { useEffect, useState } from 'react'

import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'

// local components
import DataListItemWrapper from './components/DataListItemWrapper'
import FixedButtons from './components/FixedButtons'

import { COLORS } from 'utils/constants'

function PrintAssetsItem(props) {
  const {
    rowData,
    columns,
    getDataDisplay,
    statusColName,
    visibleColumns,
    sharedSx,
    fixedButtonProps,
  } = props
  const [status, setStatus] = useState({ color: COLORS.GREEN })

  const shownColumns = visibleColumns
    .map((col) => columns.find((c) => c.id === col))
    .filter((c) => c)

  const { color } = status ?? { color: 'green.main' }

  const getBorderColor = () => {
    return 'background.paper'
  }

  useEffect(() => {
    if (rowData?.[statusColName]) {
      setStatus(rowData[statusColName])
    }
  }, [rowData])

  return (
    <Box
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        borderBottomColor: 'lightgray.main',
        color: 'darkgray.main',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          overflow: 'hidden',
          my: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {shownColumns.map((col, colIndex) => (
            <DataListItemWrapper key={col.id} index={colIndex}>
              <Box
                sx={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  flexDirection: 'column',
                  color: col.id === statusColName && color,
                  pr: colIndex === shownColumns.length - 1 ? '128px' : 0,
                }}
              >
                <Box
                  sx={{
                    my: 'auto',
                    fontSize: 14,
                  }}
                >
                  {getDataDisplay(rowData, col.id, statusColName)}
                </Box>
              </Box>
            </DataListItemWrapper>
          ))}
        </Box>
        {fixedButtonProps.length > 0 && (
          <DataListItemWrapper index={shownColumns.length + 1}>
            <Box>
              <FixedButtons {...props} fixedButtonProps={fixedButtonProps} />
            </Box>
          </DataListItemWrapper>
        )}
      </ListItem>
    </Box>
  )
}

export default PrintAssetsItem
