import { useMemo } from 'react'
import useSWR from 'swr'

import fetcher from 'utils/fetcher'
import axios from 'utils/axios'
import urlSerialize from 'helpers/browser/urlSerialize'
import saveData from 'helpers/browser/saveData'

import useModal from 'hooks/context/useModal'
import useNotification from 'hooks/context/useNotification'

import LoadingModal from 'components/common/LoadingModal'

const useAgencyPreferences = ({ params, clientPaginated = false }) => {
  const {
    setModalProps,
    setComponent: setModalComponent,
    setOpen: setModalOpen,
  } = useModal()
  const { setBasicNotification, setError } = useNotification()

  const endpoint = !params
    ? '/agency-mailing'
    : `/agency-mailing?${urlSerialize(params)}`

  const { data, isValidating, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  })

  const addAgency = async ({ values }) => {
    const res = await axios.post('/agency-mailing', { ...values })

    mutate()

    return res
  }

  const updateAgencyPreferences = async ({ agencyId, values }) => {
    // get etag
    const {
      headers: { etag },
    } = await axios.head(`/agency-mailing/${agencyId}`)

    // update item
    await axios.patch(
      `/agency-mailing/${agencyId}`,
      { ...values },
      { headers: { 'If-Match': etag } }
    )

    mutate()
  }

  const agencyPreferences = useMemo(() => {
    const preferences = data?._embedded?.agency_preferences || []
    const page = params['paging[page]']
    const pageSize = params['paging[page_size]']

    if (clientPaginated) {
      return preferences.slice((page - 1) * pageSize, page * pageSize)
    } else {
      return preferences
    }
  }, [data, params])

  const downloadCsv = async () => {
    try {
      setModalProps({
        size: 'sm',
        hideButtonsBorder: true,
        title: 'A CSV export is in progress',
      })
      setModalComponent(LoadingModal)
      setModalOpen(true)

      const res = await axios.get(endpoint, {
        headers: {
          Accept: 'text/csv',
        },
      })

      setModalOpen(false)

      saveData(
        res.data,
        `Agency.Mail.Preference.Export${Date.now()}.csv`,
        'text/csv'
      )
      setBasicNotification(
        'A CSV file has been automatically downloaded to your computer.'
      )
    } catch (err) {
      setError('An error has occurred.')
      setModalOpen(false)
    }
  }

  return {
    agencyPreferences,
    loading:
      (!data?._embedded?.agency_preferences && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    addAgency,
    updateAgencyPreferences,
    downloadCsv,
  }
}

export default useAgencyPreferences
