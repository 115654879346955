export default function isValidUSPhone(number) {
  if (!!!number) {
    return false
  } else if (Number.isInteger(number)) {
    number = number.toString()
  } else if (typeof number != 'string') {
    return false
  }

  // clean up number; remove anything non-numeric
  number = number.replace(/[^0-9]/gm, '')

  // number should be 10 digits, or 11 if it starts with a 1
  if (number.charAt(0) === '1' && number.length === 11) {
    // ok
    return true
  } else if (number.length === 10) {
    // ok
    return true
  } else {
    // not okay
    return false
  }
}
