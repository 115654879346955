import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import DoughnutChart from 'components/charts/DoughnutChart'
import Legend from '../components/Legend'
import ItemContainer from './ItemContainer'

import { COLOR_SHADES } from 'utils/constants'

function DoughnutItemContainer({
  children,
  totals = [],
  labels = ['Rejected', 'On Hold', 'To Review', 'Approved'],
  tooltips = [],
  colors = [
    COLOR_SHADES.CHART_BLUE[0],
    COLOR_SHADES.YELLOW[0],
    COLOR_SHADES.RED[0],
    COLOR_SHADES.GREEN[0],
  ],
  title,
  subTitle,
  centerText,
  donutChartProps,
  loading,
  href,
  disablePadding,
  wrapperSx,
  legendSx,
  emptyState,
  widgetKey,
}) {
  const totalSum = useMemo(() => totals.reduce((a, b) => a + b, 0), [totals])

  const chartData = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          data: totals,
          backgroundColor: colors,
          borderWidth: Array.from({ length: totals.length }).map(() => 0),
        },
      ],
    }
  }, [totals])

  return (
    <ItemContainer
      title={title}
      subTitle={subTitle}
      titleAction={<>{children}</>}
      loading={loading}
      href={href}
      disablePadding={disablePadding}
      emptyState={emptyState}
      widgetKey={widgetKey}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', ...wrapperSx }}
      >
        <Box>
          <DoughnutChart {...donutChartProps} chartData={chartData}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography fontWeight="bold" fontSize={32}>
                {totalSum}
              </Typography>
              <Typography fontSize={10}>{centerText}</Typography>
            </Box>
          </DoughnutChart>
        </Box>
        <Legend
          labels={chartData.labels}
          colors={chartData.datasets[0].backgroundColor}
          data={totals}
          tooltips={tooltips}
          sx={legendSx}
        />
      </Box>
    </ItemContainer>
  )
}

export default DoughnutItemContainer
