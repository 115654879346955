import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'

import getFollowingMonth from 'helpers/datetime/getNextMonth'

import CalendarItemGrid from './ItemGrid'

function Calendar({
  dateRange,
  setDateRange,
  months = 1,
  forcedView = [],
  single,
}) {
  const [calendarConfigs, setCalendarConfigs] = useState(forcedView)

  useEffect(() => {
    const newCalendarConfigs = []

    for (let inc = 0; inc < months; inc += 1) {
      const { month, year } = getFollowingMonth({ inc })
      newCalendarConfigs.push({
        month,
        year,
      })
    }

    setCalendarConfigs(newCalendarConfigs)
  }, [months])

  useEffect(() => {
    if (forcedView?.length > 0) setCalendarConfigs(forcedView)
  }, [forcedView])

  const handleMonthChange = (inc) => {
    const newCalendarConfigs = []

    calendarConfigs.forEach(({ month, year }) => {
      const baseDate = new Date(year, month)
      const newConfig = getFollowingMonth({ baseDate, inc })
      newCalendarConfigs.push(newConfig)
    })

    setCalendarConfigs(newCalendarConfigs)
  }

  return (
    <Box
      sx={{
        width: '92.8%',
        marginX: 'auto',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          paddingY: 0,
          marginBottom: 0,
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', marginY: 'auto' }}>
          <IconButton size="small" onClick={() => handleMonthChange(-months)}>
            <KeyboardArrowLeft fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            marginY: 'auto',
            textAlign: 'center',
            width: '100%',
          }}
        >
          {calendarConfigs.map(({ month, year }, index) => (
            <Box sx={{ width: '100%' }} key={`calendar-title-${index}`}>
              <Box
                sx={{
                  fontWeight: 'medium',
                  fontSize: '12px',
                  fontFamily: 'Open Sans',
                  mr: index < months - 1 ? 'calc(1.5rem + 15px)' : undefined,
                  ml: index < months - 1 ? undefined : 'calc(1.5rem + 15px)',
                }}
                color="text.main"
              >
                {format(new Date(year, month), 'MMMM')} {year}
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', marginY: 'auto' }}>
          <IconButton size="small" onClick={() => handleMonthChange(months)}>
            <KeyboardArrowRight fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          height: '100%',
          width: '100%',
          gridTemplateColumns: '1fr 1fr',
          columnGap: '1.5rem',
        }}
      >
        {calendarConfigs.map(({ month, year }, index) => (
          <CalendarItemGrid
            key={`calendar-${index}`}
            dateRange={dateRange}
            setDateRange={setDateRange}
            month={month}
            year={year}
            single={single}
          />
        ))}
      </Box>
    </Box>
  )
}

Calendar.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  setDateRange: PropTypes.func,
  months: PropTypes.number,
}

export default Calendar
