import React, { useEffect, useState, useMemo } from 'react'

import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import Modal from 'components/common/Modal'
import PDFViewer from 'components/common/PDFViewer'

import useManualBill from 'hooks/manual_bill/useManualBill'
import useManualBillPDF from 'hooks/manual_bill/useManualBillPDF'
import useNotification from 'hooks/context/useNotification'

import {
  MANUAL_BILL_STATUSES,
  MANUAL_BILL_READONLY_STATUSES,
} from 'utils/constants'

function BillPreviewModal({ bill, setBill, open, setOpen, mutate }) {
  const {
    manualBill,
    updateManualBill,
    mutate: mutateManual,
  } = useManualBill({
    id: open && bill?.id,
  })
  const [activeStatus, setActiveStatus] = useState(bill?.status)
  const { pdf } = useManualBillPDF({ id: open && bill?.id })
  const { setBasicNotification, setError } = useNotification()
  const [buttonLoading, setButtonLoading] = useState(false)

  const isReadOnly = useMemo(() => {
    return MANUAL_BILL_READONLY_STATUSES.includes(bill?.status)
  }, [bill])

  const handleClose = () => {
    setOpen(false)
    setBill(null)
  }

  const handleStatusUpdate = async () => {
    setButtonLoading(true)
    try {
      await updateManualBill({
        billId: manualBill?.id,
        updateData: {
          status: activeStatus,
        },
      })
      mutate()
      mutateManual()
      setBasicNotification(
        `Bill #${manualBill?.id} has been updated to status ${activeStatus}!`
      )
    } catch (err) {
      setError('Unable to update bill status.', err)
    } finally {
      setButtonLoading(false)
    }
  }

  useEffect(() => {
    if (manualBill?.status) setActiveStatus(manualBill?.status)
  }, [manualBill])

  return (
    <Modal
      title="Bill Preview"
      open={open}
      onClose={handleClose}
      height="90vh"
      width="max(60vw, 400px)"
      footerButtonProps={[
        {
          children: 'Close',
          variant: 'contained',
          color: 'primary',
          onClick: handleClose,
          sx: {
            justifySelf: 'center',
          },
        },
      ]}
    >
      <Box
        sx={{
          pt: 2,
          height: '69vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!isReadOnly && (
          <Box sx={{ display: 'flex', mb: 2 }}>
            <TextField
              fullWidth
              select
              name="status"
              label="Status"
              size="small"
              value={activeStatus}
              onChange={(e) => setActiveStatus(e.target.value)}
            >
              {Object.values(MANUAL_BILL_STATUSES)
                .filter(
                  (status) => !MANUAL_BILL_READONLY_STATUSES.includes(status)
                )
                .map((status) => (
                  <MenuItem
                    value={status}
                    selected={activeStatus?.trim() === status?.trim()}
                  >
                    {status}
                  </MenuItem>
                ))}
            </TextField>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                whiteSpace: 'nowrap',
                px: 4,
              }}
              size="small"
              onClick={handleStatusUpdate}
              disabled={activeStatus === manualBill?.status}
              loading={buttonLoading}
            >
              Submit Update
            </LoadingButton>
          </Box>
        )}
        <Box sx={{ flex: 1 }}>
          {pdf && <PDFViewer url={pdf} fileName={`${Date.now()}.pdf`} />}
        </Box>
      </Box>
    </Modal>
  )
}

export default BillPreviewModal
