import React, { useMemo } from 'react'

import useDataList from 'hooks/useDataList'
import useAuth from 'hooks/useAuth'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const requiresMPXAdmin = ['lot_file_name', 'lot_report']

function LotsTable({ lots = [] }) {
  const sortedLots = useMemo(() => {
    try {
      if (!lots) return lots

      if (Array.isArray(lots)) return lots

      const lotsWithId = Object.entries(lots).map(([id, lot]) => ({
        lot_id: id,
        ...lot,
      }))

      return lotsWithId.sort((a, b) => parseInt(a.lot_id) - parseInt(b.lot_id))
    } catch (err) {
      return lots || []
    }
  }, [lots])

  const { user } = useAuth()
  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: sortedLots,
    presetName: 'orderHistoryLots',
  })

  const filteredColumns = useMemo(() => {
    return (
      columns?.filter(
        (col) => !requiresMPXAdmin.includes(col.id) || user.isMPXAdmin
      ) || []
    )
  }, [columns])

  return (
    <Box sx={{ pt: 8, pb: 4 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {filteredColumns.map((col) => (
                <TableCell>{col.display}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {filteredColumns.map((col) => (
                  <TableCell>
                    {dataListProps.getDataDisplay(row, col.id)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default LotsTable
