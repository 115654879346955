const dataToArrayBuffer = async (data, encoding) => {
  return new Promise((resolve, reject) => {
    try {
      const blob = new Blob([data], {
        type: encoding,
      })
      const reader = new FileReader()

      reader.onload = (evt) => {
        resolve(evt.target.result)
      }

      reader.readAsArrayBuffer(blob)
    } catch (error) {
      reject(error)
    }
  })
}

export default dataToArrayBuffer
