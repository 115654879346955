import React from 'react'
import { Outlet } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'

import NavigationSideBar from 'components/sidebar/NavigationSideBar'
import NavBar from 'components/navbar/landing/NavBar'

import useConfiguration from 'hooks/useConfiguration'
import { NAVBAR_DIMENSIONS } from 'utils/constants'

function LandingPageContent({ children }) {
  const {
    navigation: { modules },
  } = useConfiguration()

  if (!modules) return <div />

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar />
      <NavigationSideBar modules={modules} />
      <Box
        id="main"
        component="main"
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: NAVBAR_DIMENSIONS.HEIGHT,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

function Landing({ children }) {
  return (
    <LandingPageContent>
      {children}
      <Outlet />
    </LandingPageContent>
  )
}

export default Landing
