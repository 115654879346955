import React from 'react'

import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

// local components
import ItemCheckBox from './components/ItemCheckbox'
import FixedButtons from './components/FixedButtons'
import SortingArrow from './components/SortingArrow'
import DataListItemWrapper from './components/DataListItemWrapper'

function ColumnsItem({
  checked,
  handleCheck,
  columns,
  fixedButtonProps = [],
  multiSelect,
  sortedCols,
  handleColumnClick,
  visibleColumns,
  sharedSx,
  innerRef,
  expandable = true,
}) {
  const shownColumns = visibleColumns
    .map((col) => columns.find((c) => c.id === col))
    .filter((c) => c)

  return (
    <Box
      ref={innerRef}
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderLeft: '4px solid',
        borderBottomColor: 'lightgray.main',
        borderLeftColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ListItem
        secondaryAction={
          expandable && <IconButton edge="end" aria-label="expand" disabled />
        }
        sx={{
          display: 'flex',
          overflow: 'hidden',
          my: 'auto',
          width: '100%',
          mr: 5,
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {multiSelect && (
            <DataListItemWrapper index={0}>
              <Box>
                <ItemCheckBox
                  displayColumns
                  checked={checked}
                  handleCheck={handleCheck}
                />
              </Box>
            </DataListItemWrapper>
          )}
          {shownColumns.map((col, colIndex) => (
            <DataListItemWrapper
              index={colIndex + (multiSelect ? 1 : 0)}
              key={col.id}
              allowUpdates={false}
            >
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      my: 'auto',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      ...col.sx,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        cursor: col.sortable && 'pointer',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={() => col.sortable && handleColumnClick(col)}
                    >
                      {col.display}
                      <SortingArrow col={col} sortedCols={sortedCols} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DataListItemWrapper>
          ))}
          {fixedButtonProps.length > 0 && (
            <DataListItemWrapper index={shownColumns.length + 1}>
              <Box>
                <FixedButtons
                  displayColumns
                  fixedButtonProps={fixedButtonProps}
                />
              </Box>
            </DataListItemWrapper>
          )}
        </Box>
      </ListItem>
    </Box>
  )
}

export default ColumnsItem
