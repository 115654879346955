import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import { etagFetcher } from 'utils/fetcher'

const useLetter = ({ letterId, params }) => {
  const { data, isValidating, mutate } = useSWR(
    letterId
      ? `/letters/${letterId}?${urlSerialize({
          ...params,
        })}`
      : null,
    etagFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    letter: data || [],
    embedded: data?._embedded ?? {},
    loading: (!data && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default useLetter
