import React from 'react'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

function PaperlessSection() {
  const { values, handleChange } = useFormikContext()

  return (
    <Box>
      <Typography
        fontWeight={600}
        color="primary.main"
        variant="h2"
        sx={{ mb: 2 }}
      >
        Paperless Preferences
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', height: '100%' }}>
            <Typography my="auto">Commercial Policies</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Checkbox
            color="green"
            name="paperless.Commercial"
            checked={Boolean(values?.paperless?.Commercial)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', height: '100%' }}>
            <Typography my="auto">Personal Policies</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Checkbox
            color="green"
            name="paperless.Personal"
            checked={Boolean(values?.paperless?.Personal)}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default PaperlessSection
