import useSWR from 'swr'

import fetcher from 'utils/fetcher'
import axios from 'utils/axios'
import urlSerialize from 'helpers/browser/urlSerialize'
import saveData from 'helpers/browser/saveData'

import useNotification from 'hooks/context/useNotification'

const useManualBills = (params) => {
  const { setError, setBasicNotification } = useNotification()
  const { data, isValidating, mutate } = useSWR(
    `/manual-bill${params ? `?${urlSerialize(params)}` : ''}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const downloadManualBill = async ({ billId }) => {
    try {
      const res = await axios.get(
        `/manual-bill/${billId?.toString().trim()}/render`,
        {
          responseType: 'blob',
          headers: { accept: 'application/pdf' },
        }
      )
      saveData(res.data, `${Date.now()}.pdf`, 'application/pdf')
      setBasicNotification(
        `Bill #${billId} has been successfully downloaded to your computer!`
      )
    } catch (err) {
      setError('Unable to download bill', err)
    }
  }

  return {
    manualBills: data?._embedded?.manual_bill || [],
    loading: (!data?._embedded?.manual_bill && !data?.error) || isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    validating: isValidating,
    error: data?.error,
    mutate,
    downloadManualBill,
  }
}

export default useManualBills
