import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

import stringAvatar from 'helpers/css/stringAvatar'
import getUserColor from 'helpers/css/getUserColor'

import { useUserData } from 'hooks/users'

function NotesItem({ note }) {
  const [color] = useState(getUserColor())
  const { formatUserTimeZoneRelative } = useUserData()
  const { user_display: name, note: message, datetime_created: date } = note

  const getDateDisplay = () => {
    try {
      return formatUserTimeZoneRelative(date)
    } catch (err) {
      return 'N/A'
    }
  }

  return (
    <Grid item>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ mr: 2 }}>
          <Avatar {...stringAvatar({ display: name, color })} />
        </Box>
        <Box>
          <Typography fontSize={12} fontWeight="medium" marginBottom={1}>
            {name || 'System User'}, {getDateDisplay()}
          </Typography>
          <Typography fontSize={14} sx={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default NotesItem
