import React, { useState, useCallback, useEffect } from 'react'

import useDataListContext from 'hooks/context/useDataListContext'

import Box from '@mui/material/Box'

function DataListItemWrapper({ children, index, allowUpdates = true }) {
  const { maxWidths, setMaxWidth } = useDataListContext()
  const [ref, setRef] = useState({ current: null })
  const onRefChange = useCallback((node) => {
    setRef({ current: node })
  }, [])

  useEffect(() => {
    if (ref && true) {
      if (maxWidths[index] !== ref.current?.clientWidth) {
        setMaxWidth(ref.current?.clientWidth || 0, index)
      }
    }
  }, [ref])

  return (
    <Box
      sx={{
        minWidth: maxWidths[index] ? `${maxWidths[index]}px` : undefined,
        maxWidth: maxWidths[index] ? `${maxWidths[index]}px` : undefined,
        ml: 6,
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ my: 'auto' }} ref={onRefChange}>
        {children}
      </Box>
    </Box>
  )
}

export default DataListItemWrapper
