import useSWR from 'swr'

import { etagFetcher } from 'utils/fetcher'
import { cloneDeep } from 'lodash'
import axios from 'utils/axios'

import useAuth from 'hooks/useAuth'
import { useCallback } from 'react'

const useOrderProgramAlerts = ({ userId = 0 }) => {
  const { user } = useAuth()

  const apiUserId = userId || user?.userId

  const { data, isValidating, mutate } = useSWR(
    apiUserId ? `/users/${apiUserId}/alerts/programs` : null,
    etagFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const handleProgramAlertsUpdate = useCallback(
    async (values) => {
      const updatedValues = cloneDeep(values.programs)

      Object.keys(updatedValues).forEach((key) => {
        delete updatedValues[key].name
        delete updatedValues[key].id
      })

      const {
        headers: { etag },
      } = await axios.get(`/users/${apiUserId}/alerts/programs`)

      await axios.put(
        `/users/${apiUserId}/alerts/programs`,
        { programs: updatedValues },
        {
          headers: {
            'If-Match': etag,
          },
        }
      )
      mutate()
    },
    [apiUserId, data]
  )

  return {
    programAlerts: data?._embedded?.programs ?? [],
    loading: (!data?._embedded?.programs && !data?.error) || isValidating,
    error: data?.error,
    mutate,
    handleProgramAlertsUpdate,
  }
}

export default useOrderProgramAlerts
