const sortByObjectKey = (objArray, key) => {
  // setup a dictionary for fast lookup
  const keyObjDict = {}
  objArray.forEach((obj) => {
    keyObjDict[obj[key]] = obj
  })

  // sort based on object key
  const objKeys = objArray.map((obj) => obj[key])
  objKeys.sort((a, b) => (a.toUpperCase() > b.toUpperCase() ? 1 : -1))

  // convert back to object array using dictionary
  const sortedObjArray = objKeys.map((objKey) => keyObjDict[objKey])

  return sortedObjArray
}

export default sortByObjectKey
