import React from 'react'

import Box from '@mui/material/Box'

import ResultsList from 'components/library/ResultsList'

import DataListLayout from 'layouts/PureDataList'

function PrintAssets() {
  return (
    <DataListLayout>
      <Box
        sx={{
          backgroundColor: 'white',
          pt: 6,
          px: 8,
          width: '100%',
          overflow: 'hidden',
          flex: 1,
          minHeight: '85vh',
        }}
      >
        <ResultsList />
      </Box>
    </DataListLayout>
  )
}

export default PrintAssets
