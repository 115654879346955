import React, { useMemo, useState } from 'react'

import Menu from '@mui/material/Menu'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'

import PlusIcon from '@mui/icons-material/Add'

import useUserWidgets from 'hooks/users/useUserWidgets'
import useWidgets from 'hooks/dashboard/useWidgets'
import useNotification from 'hooks/context/useNotification'
import useDashboardContext from 'hooks/context/useDashboardContext'

function AddWidgetMenu() {
  const { showWidget, hiddenWidgets } = useDashboardContext()
  const [anchorEl, setAnchorEl] = useState(null)
  const { widgets: dashboardWidgets } = useWidgets()
  const { widgets } = useUserWidgets()
  const { setError } = useNotification()

  const addableWidgets = useMemo(() => {
    return dashboardWidgets.filter((widget) =>
      hiddenWidgets.includes(widget.key)
    )
  }, [dashboardWidgets, widgets, hiddenWidgets])

  const disabled = !Boolean(addableWidgets.length)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleWidgetClick = async (widget) => {
    handleClose()

    try {
      await showWidget(widget.key)
    } catch (err) {
      setError('Unable to add widget. Try again later.', err)
    }
  }

  return (
    <>
      <IconButton
        sx={{ my: 'auto' }}
        title="Add Widgets"
        onClick={handleClick}
        disabled={disabled}
      >
        <PlusIcon color={disabled ? 'secondary' : 'primary'} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            boxShadow: 2,
          },
        }}
      >
        <MenuList disablePadding>
          {addableWidgets.map((widget) => (
            <MenuItem
              key={widget.key}
              onClick={() => handleWidgetClick(widget)}
            >
              <ListItemIcon>
                <PlusIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{widget.display}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  )
}

export default AddWidgetMenu
