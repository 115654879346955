import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import useAuth from 'hooks/useAuth'
import useTheme from 'hooks/useTheme'

function LandingContent() {
  const { user } = useAuth()
  const {
    images: { logo_footer },
  } = useTheme()

  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
        height: '100%',
        backgroundImage: `url("${logo_footer}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column',
        pt: 10,
      }}
    >
      <Box
        sx={{
          width: '80%',
          bgcolor: '#012f6d',
          color: 'white',
          mx: 'auto',
        }}
      >
        <Typography fontSize="2rem" fontWeight="bold">
          {user.display}, Welcome to Our NEW Console!
        </Typography>
      </Box>
    </Box>
  )
}

export default LandingContent
