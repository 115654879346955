import React, { useState, useMemo, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

import EditIcon from '@mui/icons-material/Edit'

import useModal from 'hooks/context/useModal'
import useConfiguration from 'hooks/useConfiguration'
import useNotification from 'hooks/context/useNotification'
import { useLibrary } from 'hooks/library'

import VersionsTable from 'components/library/VersionsTable'
import EditFieldModal from 'components/library/EditFieldModal'

import { PRIVILEGES } from 'utils/constants'
import formatTimeZone from 'helpers/datetime/formatTimeZone'
import UploadNewVersionModal from 'components/library/UploadNewVersionModal'
import PreviewSquare from 'components/library/PreviewSquare'

function HeaderLayoutButtons({ onDeleteModalSubmit }) {
  const { hasPrivilege } = useConfiguration()
  const navigate = useNavigate()
  const { setBasicNotification, setError } = useNotification()
  const { setOpen: setModalOpen, setModalProps } = useModal()

  const handleDeleteClick = () => {
    const modalProps = {
      title: `Delete Document`,
      children: 'Are you sure you want to permanently delete this file?',
      subTitle: undefined,
      scrollable: false,
      width: '414px',
      height: '224px',
      hideButtonsBorder: true,
      footerButtonProps: [
        {
          children: 'Delete',
          color: 'primary',
          variant: 'contained',
          size: 'action-header',
          onClick: async () => {
            try {
              await onDeleteModalSubmit()
              setModalOpen(false)
              setBasicNotification('Document has been successfully deleted')
              navigate('/correspondence-hub/library')
            } catch (err) {
              setModalOpen(false)
              setError(err?.response?.data?.display_message || err?.message)
            }
          },
        },
        {
          children: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          onClick: () => setModalOpen(false),
          size: 'action-header',
        },
      ],
    }

    setModalProps(modalProps)
    setModalOpen(true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        '& > .MuiButton-root': { my: 'auto' },
      }}
    >
      {hasPrivilege(PRIVILEGES.MODIFY_LIBRARY) && (
        <Button
          size="action-header"
          color="primary"
          variant="outlined"
          onClick={handleDeleteClick}
        >
          Delete
        </Button>
      )}
    </Box>
  )
}

function DetailsItem({ label, value, setEditableData, sx }) {
  const { hasPrivilege, instanceConfigurations } = useConfiguration()

  const renderValue = useMemo(() => {
    let textValue = value?.toString()

    return (
      <Typography lineHeight="24px" whiteSpace="pre" sx={sx}>
        {textValue || 'N/A'}
      </Typography>
    )
  }, [value])

  const fieldRenderingData = useMemo(() => {
    const categories = instanceConfigurations?.library_categories || []
    const categoryMap = categories.reduce((a, b) => {
      a[`${b}`.toLowerCase()] = b
      return a
    }, {})

    return {
      name: {
        field: 'name',
        display: 'Name',
      },
      category: {
        field: 'category',
        display: 'Category',
        data_type: 'select',
        options: categoryMap,
      },
      notes: {
        field: 'note',
        display: 'Notes',
        inputProps: {
          multiline: true,
          rows: 4,
        },
      },
    }
  }, [])

  const handleEditClick = () => {
    setEditableData(fieldRenderingData[label.toLowerCase()])
  }

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Typography fontWeight={700} my="auto" lineHeight="24px">
          {label}
        </Typography>
        {Boolean(setEditableData) && hasPrivilege(PRIVILEGES.MODIFY_LIBRARY) && (
          <IconButton onClick={handleEditClick} color="primary" size="small">
            <EditIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        )}
      </Box>
      {renderValue}
    </Box>
  )
}

function LibraryPreview() {
  const location = useLocation()
  const navigate = useNavigate()
  const [editableData, setEditableData] = useState(null)
  const [pageTitle, setPageTitle] = useState(
    location.state?.breadcrumbs?.[1]?.display
  )
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { hasPrivilege, updatePath } = useConfiguration()
  const { libId } = useParams()
  const {
    libraryItem,
    updateLibraryItem,
    updateLibraryItemVersion,
    deleteLibraryItem,
    addNewLibraryItemVersion,
    addLibraryItemNote,
    dataLoading,
  } = useLibrary({
    libId,
  })

  useEffect(() => {
    if (libraryItem?.name && pageTitle !== libraryItem.name) {
      setPageTitle(libraryItem.name)
      updatePath([
        {
          display: 'Library',
          href: '/correspondence-hub/library',
        },
        {
          display: libraryItem?.name || `Document ${libId}`,
          href: `/correspondence-hub/library/${libId}`,
        },
      ])
      navigate(location.pathname, {
        shallow: true,
        state: {
          breadcrumbs: [
            {
              display: 'Library',
              href: '/correspondence-hub/library',
            },
            {
              display: libraryItem?.name || `Document ${libId}`,
              href: `/correspondence-hub/library/${libId}`,
            },
          ],
        },
      })
    }
  }, [libraryItem])

  useEffect(() => {
    if (!location.state?.breadcrumbs && libraryItem?.name) {
      navigate(location.pathname, {
        shallow: true,
        state: {
          breadcrumbs: [
            {
              display: 'Library',
              href: '/correspondence-hub/library',
            },
            {
              display: libraryItem?.name || `Document ${libId}`,
              href: `/correspondence-hub/library/${libId}`,
            },
          ],
        },
      })
    }
  }, [location, libraryItem])

  return (
    <Box sx={{ backgroundColor: 'white', px: 8, minHeight: '912px' }}>
      <EditFieldModal
        open={Boolean(editableData)}
        setOpen={() => setEditableData(null)}
        fieldData={editableData}
        libraryItem={libraryItem}
        updateLibraryItem={updateLibraryItem}
        updateLibraryItemVersion={updateLibraryItemVersion}
        addLibraryItemNote={addLibraryItemNote}
      />
      <UploadNewVersionModal
        open={showUploadModal}
        setOpen={setShowUploadModal}
        libraryItem={libraryItem}
        addNewLibraryItemVersion={addNewLibraryItemVersion}
      />
      <Box
        sx={{
          height: '102px',
          background: 'white',
          display: 'flex',
          borderColor: 'darkblue.light',
          justifyContent: 'space-between',
          overflowY: 'hidden',
          overflowX: 'auto',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography
          fontSize="32px"
          fontWeight={600}
          lineHeight="43.58px"
          sx={{ my: 'auto' }}
        >
          {libraryItem && `${libraryItem?.name ?? ''} Details`}
        </Typography>
        <HeaderLayoutButtons
          onDeleteModalSubmit={async () => deleteLibraryItem({ id: libId })}
          libraryItem={libraryItem}
        />
      </Box>
      <Card
        sx={{
          py: 8,
          pl: 8,
          pr: { xl: '380px' },
          border: '1px solid',
          borderColor: 'lightgray.main',
        }}
      >
        <Grid container columns={10}>
          <Grid item xs={3} md={2} lg={1} xl={2}>
            <PreviewSquare libraryItem={libraryItem} />
          </Grid>
          <Grid item xs={7} md={4} lg={3} xl={2}>
            <Stack spacing={6}>
              <DetailsItem
                label="Name"
                value={libraryItem?.name || 'N/A'}
                setEditableData={setEditableData}
              />
              <DetailsItem
                label="Category"
                value={libraryItem?.category || 'N/A'}
                sx={{ textTransform: 'capitalize' }}
                setEditableData={setEditableData}
              />
            </Stack>
          </Grid>
          <Grid item xs={10} sm={5} md={4} lg={3} xl={2}>
            <Stack spacing={6}>
              <DetailsItem
                label="Document ID"
                value={libraryItem?.document_id || 'N/A'}
              />
              <DetailsItem
                label="Created"
                value={formatTimeZone(libraryItem?.datetime_created)}
              />
            </Stack>
          </Grid>
          <Grid item xs={10} sm={5} lg={3} xl={2}>
            <Stack spacing={6}>
              <DetailsItem
                label="Modified"
                value={formatTimeZone(libraryItem?.datetime_modified)}
              />
            </Stack>
          </Grid>
          <Grid item xs={10} sm={5} lg={3} xl={2} sx={{ pl: 16 }}>
            <DetailsItem
              label="Notes"
              variant="notes"
              value={libraryItem?._embedded?.notes?.[0]?.note || ''}
              setEditableData={setEditableData}
            />
          </Grid>
        </Grid>
      </Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 8,
          pb: 4,
          borderBottom: '1px solid',
          borderColor: 'lightgray.main',
        }}
      >
        <Typography my="auto" fontWeight={600} fontSize="24px">
          File versions
        </Typography>
        {hasPrivilege(PRIVILEGES.MODIFY_LIBRARY) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowUploadModal(true)}
          >
            Upload new version
          </Button>
        )}
      </Box>
      <VersionsTable
        versions={libraryItem?._embedded?.versions?.versions || []}
        activeVersion={libraryItem?.default_version}
        updateLibraryItemVersion={updateLibraryItemVersion}
        updateLibraryItem={updateLibraryItem}
        dataLoading={dataLoading}
      />
    </Box>
  )
}

export default LibraryPreview
