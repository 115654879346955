import React, { useEffect, useState, useMemo } from 'react'

import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { Chip } from '@mui/material'
import debounce from 'helpers/node/debounce'

const CHECKED_DEPTHS = {
  documents: 3,
  forms: 1,
}

const getLeafNodes = (option) => {
  if (!option?.items?.length) return []

  return option.items
    .flatMap((item) => [item, ...getLeafNodes(item)])
    .filter((item) => item.value)
}

const getChildIds = (option) => {
  if (!option?.items?.length) return []

  return option.items.flatMap((item) => [item.id, ...getChildIds(item)])
}

function TreeNodeContent({ option, checked, handleCheck, checkDepth = 3 }) {
  const showCheckbox = option?.depth > checkDepth
  return (
    <Box
      onClick={(e) => {
        if (showCheckbox) {
          handleCheck(!checked[option.id])
          e.stopPropagation()
        }
      }}
      sx={{ p: !showCheckbox && 2 }}
    >
      {showCheckbox && (
        <Checkbox
          color={checked[option.id] === true ? 'green' : undefined}
          checked={checked[option.id] === true || false}
          indeterminate={checked[option.id] === 'indeterminate'}
        />
      )}
      {option.label}
    </Box>
  )
}

const updateChecked = ({ option, checked }) => {
  if (!option) return

  const leafNodes = getLeafNodes(option)
  const checkedCount = leafNodes.filter((node) => checked[node.id]).length

  if (checkedCount === leafNodes.length) {
    checked[option.id] = true
  } else if (checkedCount > 0) {
    checked[option.id] = 'indeterminate'
  } else {
    checked[option.id] = false
  }

  if (option.parent) {
    updateChecked({ option: option.parent, checked })
  }
}

function TreeNode({ option, checked, setChecked, checkDepth }) {
  const childIds = useMemo(() => {
    return getChildIds(option)
  }, [option])

  const handleCheck = (val) => {
    const newChecked = { ...checked, [option.id]: val }

    childIds.forEach((id) => {
      newChecked[id] = val
    })

    setChecked(newChecked)
  }

  return (
    <TreeItem
      label={
        <TreeNodeContent
          option={option}
          checked={checked}
          handleCheck={handleCheck}
          checkDepth={checkDepth}
        />
      }
      nodeId={`${option.id}`}
    >
      {option.items.map((item) => (
        <TreeNode
          key={item.id}
          option={item}
          checked={checked}
          setChecked={setChecked}
          checkDepth={checkDepth}
        />
      ))}
    </TreeItem>
  )
}

function TagGroup({ option, label, values, handleDelete }) {
  if (
    getLeafNodes(option).filter((node) => values.includes(node.value))
      .length === 0
  ) {
    return <></>
  }

  return (
    <Box sx={{ p: 2, mb: 2 }}>
      <Typography>{label ?? option.label}</Typography>
      {option.items.map((item) =>
        item.items.length ? (
          <TagGroup
            key={`tag-group-${item.id}`}
            option={item}
            values={values}
            handleDelete={handleDelete}
          />
        ) : (
          values.includes(item.value) && (
            <Chip
              key={item.id}
              label={item.label}
              sx={{ m: 1 }}
              onDelete={() => handleDelete(item)}
            />
          )
        )
      )}
    </Box>
  )
}

function NestedMultiselectField({
  options,
  values,
  onChange,
  name,
  error,
  scrollRef,
}) {
  const [tree, setTree] = useState({
    root: { items: [], label: name, id: '0' },
  })
  const [checked, setChecked] = useState({})
  const handleChecked = (checkedOptions) => {
    setChecked(checkedOptions)
    const newValues = getLeafNodes(tree.root)
      .filter((node) => checkedOptions[node.id])
      .map((node) => node.value)
    onChange(newValues)
  }

  const handleNodeToggle = (e) => {
    const li = e.target.closest('li')

    debounce(() => {
      if (scrollRef?.current && li) {
        const scrollTop = scrollRef.current.scrollTop
        const scrollHeight = scrollRef.current.clientHeight
        const dropdownHeight = li.clientHeight
        const clientY = li.offsetTop
        const dropdownPos = clientY + dropdownHeight
        const diff = scrollTop + scrollHeight - dropdownPos

        if (diff < 0) {
          scrollRef.current.scrollTop += Math.abs(diff)
        }
      }
    })()
  }

  useEffect(() => {
    let counter = 1
    const root = { items: options, label: name, id: '0', depth: 0 }

    const abstractOptions = (items, parent, depth = 1) => {
      items.forEach((item) => {
        item.id = counter
        item.parent = parent
        item.depth = depth
        counter++

        if (!Array.isArray(item.items)) {
          item.items = Object.values(item.items || {})
        }

        abstractOptions(item.items, item, depth + 1)
      })
    }

    abstractOptions(root.items, root)

    setTree({ root })
  }, [options])

  useEffect(() => {
    const newChecked = {}

    getLeafNodes(tree.root)
      .filter((node) => values.includes(node.value))
      .forEach((node) => {
        newChecked[node.id] = true
        updateChecked({ option: node.parent, checked: newChecked })
      })

    setChecked(newChecked)
  }, [values])

  return (
    <>
      <Typography color={error ? 'error.main' : 'primary.main'} variant="h2">
        {name}
      </Typography>{' '}
      <Box>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeToggle={handleNodeToggle}
          sx={{ flexGrow: 1, overflowY: 'auto' }}
        >
          <TreeNode
            nodeId="0"
            option={tree.root}
            checked={checked}
            setChecked={handleChecked}
            checkDepth={CHECKED_DEPTHS[`${name}`.toLowerCase()] || 0}
          />
        </TreeView>
        <Box>
          {tree.root.items.map((item) => (
            <TagGroup
              key={item.id}
              option={item}
              values={values}
              handleDelete={(itemToDelete) => {
                const newChecked = { ...checked, [itemToDelete.id]: false }
                updateChecked({
                  option: itemToDelete.parent,
                  checked: newChecked,
                })
                handleChecked(newChecked)
              }}
            />
          ))}
        </Box>
        {error && <Typography color="error.main">{error}</Typography>}
      </Box>
    </>
  )
}

export default NestedMultiselectField
