import React from 'react'
import styled from 'styled-components/macro'

import Box from '@mui/material/Box'
import TimelineDot from '@mui/lab/TimelineDot'

const Connector = styled.hr`
  margin-top: auto;
  margin-bottom: auto;
  width: 100px;
`

function HorizontalTimeline({ content = [] }) {
  return (
    <Box sx={{ display: 'flex' }}>
      {content.map(({ children, sx, icon, connectorProps }, index) => (
        <Box key={`timeline-element-${index}`} sx={{ display: 'flex' }}>
          <Box sx={{ position: 'relative' }}>
            {icon ? icon : <TimelineDot />}
            <Box sx={{ position: 'absolute', width: '96px' }}>
              <Box sx={{ transform: 'translateX(-24px)', textAlign: 'center' }}>
                {children}
              </Box>
            </Box>
          </Box>
          {index < content.length - 1 && <Connector />}
        </Box>
      ))}
    </Box>
  )
}

export default HorizontalTimeline
