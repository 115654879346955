import React, { useEffect, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import DateRangeDropdown from 'components/common/DateRangeDropdown'

import { DASHBOARD_WIDGETS, WIDGET_KEYS } from 'utils/constants'

import useWidget from 'hooks/dashboard/useWidget'
import useDashboardContext from 'hooks/context/useDashboardContext'

import { mergeAggregateDataArray } from 'utils/aggregate'
import DoughnutItemContainer from './containers/DoughnutItem'
import { DATE_RANGE_PRESETS } from 'components/common/DateRangePicker/dateRangePresets'

function CollateralCategoryItem({ id }) {
  const [datePreset, setDatePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const { refreshCount } = useDashboardContext()
  const {
    data: liveData,
    loading: liveLoading,
    mutate,
  } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.COLLATERAL,
    widgetName: id,
    presetId: DATE_RANGE_PRESETS.CURRENT_DAY,
  })
  const { data, loading } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.COLLATERAL,
    presetId: datePreset !== DATE_RANGE_PRESETS.CURRENT_DAY && datePreset,
  })

  const categoriesAggregate = useMemo(() => {
    const aggregateData =
      mergeAggregateDataArray({ data: liveData.concat(data) })
        ?.aggregated_data || {}

    const categories = Object.values(aggregateData?.counts || {}).map(
      (mailMethod) => {
        return mailMethod['by-category']
      }
    )

    const categoryAggregate =
      mergeAggregateDataArray({ data: categories }) || {}

    return categoryAggregate
  }, [data, liveData])

  const templatesAggregate = useMemo(() => {
    const aggregateData =
      mergeAggregateDataArray({ data: liveData.concat(data) })
        ?.aggregated_data || {}

    const templates = Object.values(aggregateData?.counts || {}).map(
      (mailMethod) => {
        return mailMethod['by-template']
      }
    )

    const templateAggregate = mergeAggregateDataArray({ data: templates }) || {}

    return templateAggregate
  }, [data, liveData])

  const labels = useMemo(() => {
    return Object.keys(categoriesAggregate || {})
  }, [categoriesAggregate])

  const totals = useMemo(() => {
    return Object.values(categoriesAggregate || {})
  }, [categoriesAggregate])

  useEffect(() => {
    mutate()
  }, [refreshCount])

  return (
    <DoughnutItemContainer
      disablePadding
      widgetKey={WIDGET_KEYS.COLLATERAL_DONUT}
      title="Collateral"
      centerText="Created Documents"
      href="/correspondence-hub/collateral"
      loading={loading || liveLoading}
      labels={labels}
      totals={totals}
      tooltips={[
        <Stack>
          <Typography fontWeight="bold">Breakdown By Template: </Typography>
          <Divider />
          {Object.entries(templatesAggregate)
            .filter(([key, value]) => Boolean(value) && !labels.includes(key))
            .map(([key, value]) => (
              <Box>
                {key || 'No Template'}: ({value})
              </Box>
            ))}
        </Stack>,
        ...labels.slice(1),
      ]}
    >
      <Box>
        <DateRangeDropdown
          value={datePreset}
          onChange={(e) => setDatePreset(e.target.value)}
        />
      </Box>
    </DoughnutItemContainer>
  )
}

export default CollateralCategoryItem
