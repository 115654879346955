import React, { useEffect, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import DateRangeDropdown from 'components/common/DateRangeDropdown'
import DoughnutItemContainer from './containers/DoughnutItem'

import { DASHBOARD_WIDGETS, WIDGET_KEYS } from 'utils/constants'

import useWidget from 'hooks/dashboard/useWidget'
import useDashboardContext from 'hooks/context/useDashboardContext'

import { mergeAggregateDataArray, groupData } from 'utils/aggregate'
import { DATE_RANGE_PRESETS } from 'components/common/DateRangePicker/dateRangePresets'

function CorrespondenceItem({ id }) {
  const { refreshCount } = useDashboardContext()
  const [datePreset, setDatePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const {
    data: liveData,
    loading: liveLoading,
    mutate: mutateLive,
  } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.CORRESPONDENCE,
    widgetName: id,
    presetId: DATE_RANGE_PRESETS.CURRENT_DAY,
  })
  const { data, loading, mutate } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.CORRESPONDENCE,
    presetId: datePreset !== DATE_RANGE_PRESETS.CURRENT_DAY && datePreset,
    params: {
      correspondence_item: 1,
    },
  })

  const descriptionCounts = useMemo(() => {
    return (
      (
        mergeAggregateDataArray({ data: liveData.concat(data) })
          ?.aggregated_data || {}
      )?.counts?.['new-by-description'] || {}
    )
  }, [data, liveData])

  const aggregateTotal = useMemo(() => {
    return groupData({ data: descriptionCounts, count: 5 })
  }, [descriptionCounts])

  const totals = useMemo(() => {
    return Object.values(aggregateTotal || {})
  }, [aggregateTotal])

  const [tooltips, labels] = useMemo(() => {
    const t = Object.keys(aggregateTotal || {})
    const l = t

    return [t, l]
  }, [aggregateTotal])

  useEffect(() => {
    mutateLive()
    mutate()
  }, [refreshCount])

  return (
    <DoughnutItemContainer
      disablePadding
      widgetKey={WIDGET_KEYS.CORRESPONDENCE_DONUT}
      title="Correspondence"
      href="/correspondence-hub/correspondence"
      centerText="Documents"
      totals={totals}
      labels={labels}
      loading={loading || liveLoading}
      tooltips={[
        // NOTE: could cause issues if total categories less than 5
        ...tooltips.slice(0, 4),
        <Stack>
          <Typography fontWeight="bold">Breakdown By Category: </Typography>
          <Divider />
          {Object.entries(descriptionCounts)
            .filter(([key, value]) => Boolean(value) && !labels.includes(key))
            .map(([key, value]) => (
              <Box>
                {key || 'No Category'}: ({value})
              </Box>
            ))}
        </Stack>,
      ]}
      wrapperSx={{
        '& > *:first-child': {
          mr: 4,
        },
      }}
    >
      <Box>
        <DateRangeDropdown
          value={datePreset}
          onChange={(e) => setDatePreset(e.target.value)}
        />
      </Box>
    </DoughnutItemContainer>
  )
}

export default CorrespondenceItem
