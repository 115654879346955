import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

function ContactCard({ mpxContact }) {
  const gridLabelProps = {
    sm: 12,
    md: 6,
    sx: {
      fontWeight: 'bold',
      textAlign: { sm: 'left', md: 'right' },
      paddingRight: { sm: 0, md: 8 },
    },
  }

  const gridValueProps = {
    sm: 12,
    md: 6,
  }

  const tier = mpxContact?.FlowClientTier
  const showServiceDesk = !tier || tier === 'Tier 3' || tier === 'Empty'

  const renderContact = ({ name, email }) => {
    let nameComponent = name
    let emailComponent = (
      <Box component="a" href={`mailto:${email}`}>
        {email}
      </Box>
    )

    if (name && !email) return nameComponent
    if (!name && email) return emailComponent

    return (
      <>
        {nameComponent} ({emailComponent})
      </>
    )
  }

  return (
    <Card sx={{ px: 10, py: 8, boxShadow: 2, width: '100%' }}>
      <Box
        sx={{
          mb: 8,
          width: '100%',
        }}
      >
        <Typography fontWeight="bold" fontSize={20} textAlign="center">
          {mpxContact.FlowClientName || 'Client #' + mpxContact.FlowClientID}
        </Typography>
      </Box>
      <Grid container columns={12} rowGap={4}>
        {showServiceDesk ? (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="a"
              target="_blank"
              href={
                'https://mpxonline.atlassian.net/servicedesk/customer/portal/14'
              }
            >
              MPX Client Service Support
            </Box>
          </Grid>
        ) : (
          <>
            {Boolean(mpxContact.FlowCAMName || mpxContact.FlowCAMEmail) && (
              <>
                <Grid item {...gridLabelProps}>
                  Audit & Billing Strategic Account Manager
                </Grid>
                <Grid item {...gridValueProps}>
                  {renderContact({
                    name: mpxContact.FlowCAMName,
                    email: mpxContact.FlowCAMEmail,
                  })}
                </Grid>
              </>
            )}

            {Boolean(mpxContact.FlowCRMName || mpxContact.FlowCRMEmail) && (
              <>
                <Grid item {...gridLabelProps}>
                  Strategic Account Manager
                </Grid>
                <Grid item {...gridValueProps}>
                  {renderContact({
                    name: mpxContact.FlowCRMName,
                    email: mpxContact.FlowCRMEmail,
                  })}
                </Grid>
              </>
            )}

            {Boolean(mpxContact.FlowTPMName || mpxContact.FlowTPMEmail) && (
              <>
                <Grid item {...gridLabelProps}>
                  Technical Lead
                </Grid>
                <Grid item {...gridValueProps}>
                  {renderContact({
                    name: mpxContact.FlowTPMName,
                    email: mpxContact.FlowTPMEmail,
                  })}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Card>
  )
}

export default ContactCard
