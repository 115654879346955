import React, { useEffect, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import DateRangeDropdown from 'components/common/DateRangeDropdown'
import BarChart from 'components/charts/BarChart'
import CreatedIcon from 'svgs/statuses/Created'

import { COLOR_SHADES, DASHBOARD_WIDGETS, WIDGET_KEYS } from 'utils/constants'

import useWidget from 'hooks/dashboard/useWidget'
import useDashboardContext from 'hooks/context/useDashboardContext'

import { mergeAggregateDataArray } from 'utils/aggregate'
import ItemContainer from './containers/ItemContainer'
import { DATE_RANGE_PRESETS } from 'components/common/DateRangePicker/dateRangePresets'
import Legend from './components/Legend'

function CollateralItem({ id }) {
  const colors = [
    COLOR_SHADES.GREEN[0],
    COLOR_SHADES.YELLOW[0],
    COLOR_SHADES.CHART_BLUE[0],
  ]
  const { refreshCount } = useDashboardContext()
  const [datePreset, setDatePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const { data: liveData, loading: liveLoading } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.COLLATERAL,
    widgetName: id,
    presetId: DATE_RANGE_PRESETS.CURRENT_DAY,
  })
  const { data, loading, mutate } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.COLLATERAL,
    presetId: datePreset !== DATE_RANGE_PRESETS.CURRENT_DAY && datePreset,
    params: {
      collateral_item: 1,
    },
  })

  const aggregateTotal = useMemo(() => {
    return (
      mergeAggregateDataArray({ data: liveData.concat(data) })
        ?.aggregated_data || {}
    )
  }, [data, liveData])

  const labels = useMemo(() => {
    let _labels = ['Mailed', 'Emails', 'FAX']

    if (!aggregateTotal?.counts?.fax?.['by-category']) {
      _labels = _labels.filter((label) => label !== 'FAX')
    }

    return _labels
  }, [aggregateTotal])

  const totals = useMemo(() => {
    const emailTotal = Object.values(
      aggregateTotal?.counts?.email?.['by-category'] || {}
    ).reduce((a, b) => a + b, 0)
    const mailTotal = Object.values(
      aggregateTotal?.counts?.mail?.['by-category'] || {}
    ).reduce((a, b) => a + b, 0)
    const faxTotal = Object.values(
      aggregateTotal?.counts?.fax?.['by-category'] || {}
    ).reduce((a, b) => a + b, 0)
    return [mailTotal, emailTotal, faxTotal]
  }, [aggregateTotal])

  useEffect(() => {
    mutate()
  }, [refreshCount])

  return (
    <ItemContainer
      title="Collateral"
      href="/correspondence-hub/collateral"
      loading={loading || liveLoading}
      titleAction={
        <DateRangeDropdown
          value={datePreset}
          onChange={(e) => setDatePreset(e.target.value)}
        />
      }
      widgetKey={WIDGET_KEYS.COLLATERAL_BARS}
    >
      <Stack spacing={3}>
        <Box sx={{ display: 'flex', color: 'primary.main' }}>
          <CreatedIcon width={56} height={56} />
          <Box sx={{ ml: 4 }}>
            <Typography
              color="primary.main"
              fontSize={44}
              lineHeight="44px"
              fontWeight="bold"
            >
              {(totals || []).reduce((a, b) => a + b, 0)}
            </Typography>
            <Typography
              color="primary.main"
              fontWeight="medium"
              fontSize="12px"
              lineHeight="12px"
            >
              created documents
            </Typography>
          </Box>
        </Box>
        <Box>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ flex: 1, pt: 2 }}>
              <BarChart
                height="150px"
                chartData={{
                  labels: labels,
                  datasets: [
                    {
                      data: totals,
                      backgroundColor: colors,
                      borderWidth: Array.from({ length: totals.length }).map(
                        () => 0
                      ),
                    },
                  ],
                }}
              />
            </Box>
            <Legend
              labels={labels}
              colors={['transparent', 'transparent', 'transparent']}
              data={totals}
              sx={{ justifyContent: 'space-evenly' }}
            />
          </Box>
        </Box>
      </Stack>
    </ItemContainer>
  )
}

export default CollateralItem
