import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'

import DocumentDataList from 'components/digital_mailbox/DocumentDataList'

import TabbedDataListLayout from 'layouts/TabbedDataList'

import useConfiguration from 'hooks/useConfiguration'

function DocumentList() {
  const csvFilename = `documents-${format(new Date(), 'MM-dd-yyyy-hhmmss')}.csv`
  const [mounted, setMounted] = useState(false)
  const { currentModule } = useConfiguration()

  useEffect(() => {
    setMounted(Boolean(currentModule))
  }, [currentModule])

  if (!mounted) return <div></div>

  return (
    <TabbedDataListLayout csvFilename={csvFilename}>
      <DocumentDataList />
    </TabbedDataListLayout>
  )
}

export default DocumentList
