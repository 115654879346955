import React, { useMemo } from 'react'
import Typography from '@mui/material/Typography'

import useTheme from 'hooks/useTheme'

export default function Copyright(props) {
  const {
    texts: { copyright },
  } = useTheme()

  const copyRightDisplay = useMemo(() => {
    return copyright?.replace('{year}', new Date().getFullYear().toString())
  }, [copyright])

  return (
    <Typography variant="body2" color="textSecondary" align="center" {...props}>
      {copyRightDisplay}
    </Typography>
  )
}
