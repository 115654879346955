import routes from 'routes'

const getModuleRoute = async (homepage, userRoutes = routes, basePath = '') => {
  let moduleRoute = ''

  // for each route, check if it is the homepage
  await Promise.all(
    userRoutes.map(async (userRoute) => {
      if (userRoute.path === homepage) {
        moduleRoute = `${basePath}/${userRoute.path}`
      } else if (userRoute?.children?.length > 0) {
        // if the route has children, recursively check if any of the children
        // are the homepage
        const result = await getModuleRoute(
          homepage,
          userRoute.children || [],
          `${basePath}/${userRoute.path}`
        )

        if (result) {
          moduleRoute = result
        }
      }
    })
  )

  return moduleRoute
}

export default getModuleRoute
