const findErrorMessage = (
  err,
  defaultMessage = 'An error has occurred',
  linksKey = ''
) => {
  if (!err) return defaultMessage

  const response = err?.response

  // only show error if response was an error (207 included)
  if (!response?.status || (response.status < 400 && response.status !== 207))
    return defaultMessage

  // handle standard API errors
  if (response?.data?.display_message) return response?.data?.display_message

  /* nested error handling */
  const responseData = response.data

  if (typeof responseData !== 'object') return defaultMessage

  if (responseData?._links && responseData?._links?.error?.display_message) {
    return responseData._links.error.display_message
  }

  const linksData = responseData?.[linksKey]
  const linksParent = Array.isArray(linksData)
    ? linksData.find((data) => data?._links?.error?.display_message)
    : linksData

  if (linksParent?._links?.error?.display_message)
    return linksParent._links.error.display_message

  return defaultMessage
}

export default findErrorMessage
