import axios from 'utils/axios'
import saveData from 'helpers/browser/saveData'

export const downloadProcessingReport = async (orderId, reportId, filename) => {
  const res = await axios.get(`/orders/${orderId}/reports/${reportId}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/pdf',
    },
  })
  saveData(res.data, filename, 'application/pdf')
}
