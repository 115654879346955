import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const useAgencyPreferencesActivity = ({ agencyCode }) => {
  const { data, isValidating, mutate } = useSWR(
    agencyCode ? `/agency-mailing/${agencyCode}/audits` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    audits: data?._embedded?.audits || [],
    mutate,
    loading: (!data?._embedded?.audits && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
  }
}

export default useAgencyPreferencesActivity
