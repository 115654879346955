import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedListHR from 'components/styled/ExpandedListHR'
import { useOrderNotes } from 'hooks/useOrders'
import useModal from 'hooks/context/useModal'

import FixedButtons from 'components/common/DataList/Item/components/FixedButtons'
import OrderHistoryModal from 'components/order_approval/OrderHistoryModal'
import { Link } from 'react-router-dom'

// local components
import Notes from './components/Notes'
import ShowMoreContent from './components/ShowMoreContent'

function OrderApprovalExpandedList({
  children,
  expanded,
  rowData,
  columns,
  statusTitle = '',
  getDataDisplay,
  visibleColumns,
  collapseDetails,
  hoverButtons,
}) {
  const { setOpen: setModalOpen, setComponent, setModalProps } = useModal()

  const reports = {}

  const procReportParams = { orderId: null, reportId: null }

  if (rowData?._embedded?.reports.length > 0) {
    Object.keys(rowData._embedded.reports).forEach((key) => {
      if (rowData._embedded.reports[key].type === 'processing') {
        reports['processing'] = rowData._embedded.reports[key]
        procReportParams.orderId = reports['processing'].order_id
        procReportParams.reportId = reports['processing'].id
      }
    })
  }

  const { notes } = useOrderNotes(rowData.order_id)

  const [open, setOpen] = useState(!collapseDetails)

  const fixedButtonProps =
    rowData.order_status !== 'Processing'
      ? [
          {
            children: 'Preview Order',
            color: 'primary',
            variant: 'outlined',
            size: 'string',
            onClick: () => {
              window.location.href = `/command-center/order-details/${rowData.order_id}`
            },
          },
        ]
      : []

  const showHistoryModal = () => {
    setModalProps({
      orderId: rowData.order_id,
    })
    setComponent(OrderHistoryModal)
    setModalOpen(true)
  }

  return (
    <Collapse
      in={expanded}
      sx={{
        backgroundColor: '#fff',
        textAlign: 'left',
        px: 11,
        pt: 2,
        mt: 0,
        '& .MuiCollapse-entered': {
          mt: 4,
        },
      }}
    >
      {statusTitle && (
        <Typography fontWeight="bold" fontSize={14}>
          {statusTitle}
        </Typography>
      )}

      <Grid container columns={12} rowSpacing={10} columnSpacing={30}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight="medium" fontSize={14}>
              Details
            </Typography>
            {collapseDetails && (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
            {expanded && hoverButtons && (
              <Box
                sx={{
                  justifySelf: 'flex-end',
                  justifyContent: 'flex-end',
                  float: 'right',
                  width: '100%',
                  position: 'relative',
                  pb: 9,
                }}
              >
                {hoverButtons}
              </Box>
            )}
          </Box>
          <ExpandedListHR />
          <ShowMoreContent
            rowData={rowData}
            columns={columns}
            open={open}
            getDataDisplay={getDataDisplay}
            visibleColumns={visibleColumns}
          />
        </Grid>

        <Grid item xs={6}>
          {notes?.length > 0 && <Notes notes={notes} />}
        </Grid>
        <Grid item xs={3} sx={{ padding: 0, textAlign: 'center' }}>
          {rowData.order_status !== 'Processing' && (
            <Box>
              <Box
                component="span"
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: 'primary.main',
                }}
                onClick={showHistoryModal}
              >
                Order Status History
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={7} sx={{ padding: 0 }}>
          {rowData.order_status !== 'Processing' &&
            (procReportParams.orderId ? (
              <>
                <Link
                  to={`/command-center/order-details/${procReportParams.orderId}/order-processing-report`}
                  state={procReportParams}
                  sx={{ fontSize: 14 }}
                >
                  Download Processing Report
                </Link>
              </>
            ) : (
              'Processing Report unavailable'
            ))}
        </Grid>
        <Grid item xs={2} sx={{ padding: 0, marginBottom: 2 }}>
          {fixedButtonProps?.length > 0 && (
            <FixedButtons fixedButtonProps={fixedButtonProps} />
          )}
        </Grid>
      </Grid>
      {children}
    </Collapse>
  )
}

export default OrderApprovalExpandedList
