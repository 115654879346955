import React, { useMemo, useState, useEffect } from 'react'
import format from 'date-fns/format'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import DateRangeDropdown from 'components/common/DateRangeDropdown'
import DoughnutItemContainer from './containers/DoughnutItem'

import useDashboardContext from 'hooks/context/useDashboardContext'
import useConfiguration from 'hooks/useConfiguration'

import useLettersAggregate from 'hooks/document_approval/useLettersAggregate'
import useWidget from 'hooks/dashboard/useWidget'
import {
  DATE_RANGE_PRESETS,
  dateRangePresets,
} from 'components/common/DateRangePicker/dateRangePresets'
import {
  DASHBOARD_WIDGETS,
  LETTERS_ENABLED,
  WIDGET_KEYS,
} from 'utils/constants'
import getUTCDateRange from 'helpers/datetime/getUTCDateRange'
import { mergeAggregateDataArray } from 'utils/aggregate'
import ItemContainer from './containers/ItemContainer'

function DocumentApprovalItem({ id }) {
  const { refreshCount } = useDashboardContext()
  const { instanceConfigurations } = useConfiguration()
  const [datePreset, setDatePreset] = useState(DATE_RANGE_PRESETS.CURRENT_DAY)
  const isCurrentDay = datePreset === DATE_RANGE_PRESETS.CURRENT_DAY
  const enabled = instanceConfigurations?.[LETTERS_ENABLED] === '1'

  const {
    data,
    loading: widgetLoading,
    mutate: mutateLive,
  } = useWidget({
    widgetKey: DASHBOARD_WIDGETS.DOCUMENT_APPROVAL,
    widgetName: id,
    presetId: isCurrentDay && DATE_RANGE_PRESETS.CURRENT_DAY,
    isDisabled: !enabled,
  })

  const hookParams = useMemo(() => {
    const params = {
      status_filter: 'To Review',
      'paging[page_size]': 1,
      'paging[page]': 1,
      'sort[0]': 'datetime_created:asc',
    }

    const dateRange = dateRangePresets[datePreset]?.preset || []

    if (dateRange && !isCurrentDay) {
      const utcDateRange = getUTCDateRange(
        dateRange[0],
        dateRange[1] || dateRange[0]
      )
      params['datetime_created[start]'] =
        utcDateRange[0]?.toISOString() || undefined
      params['datetime_created[end]'] =
        utcDateRange[1]?.toISOString() || undefined
    }

    return params
  }, [datePreset])

  const {
    aggregate: lettersAggregate,
    mutate,
    loading: aggregateLoading,
  } = useLettersAggregate({
    baseParams: hookParams,
    disabled: isCurrentDay || !enabled,
  })

  const aggregateTotal = useMemo(() => {
    if (isCurrentDay) {
      return mergeAggregateDataArray({ data })?.aggregated_data || {}
    }

    return lettersAggregate
  }, [lettersAggregate, isCurrentDay, data])

  const oldestLetterDisplay = useMemo(() => {
    const documentId = aggregateTotal?.oldest_doc_to_review
    const datetimeCreated = aggregateTotal?.oldest_date_to_review

    if (!documentId || !datetimeCreated) {
      return ''
    }

    return `Oldest Doc ${documentId} - ${format(
      new Date(datetimeCreated),
      'MM/dd/yyyy'
    )}`
  }, [aggregateTotal])

  const isLoading =
    (!isCurrentDay && aggregateLoading) || (isCurrentDay && widgetLoading)

  const totals = useMemo(() => {
    if (datePreset === DATE_RANGE_PRESETS.CURRENT_DAY) {
      return [
        aggregateTotal?.counts?.['Rejected'] || 0,
        aggregateTotal?.counts?.['On Hold'] || 0,
        aggregateTotal?.counts?.['To Review'] || 0,
        aggregateTotal?.counts?.['Approved'] || 0,
      ]
    } else {
      return [
        aggregateTotal?.counts?.['Rejected'] || 0,
        aggregateTotal?.counts?.['Mailed'] || 0,
        aggregateTotal?.counts?.['Production'] || 0,
        aggregateTotal?.counts?.['Other'] || 0,
      ]
    }
  }, [datePreset, aggregateTotal])

  const labels = useMemo(() => {
    if (datePreset === DATE_RANGE_PRESETS.CURRENT_DAY) {
      return ['Rejected', 'On Hold', 'To Review', 'Approved']
    } else {
      return ['Rejected', 'Mailed', 'Production', 'Other (See Current Day)']
    }
  }, [datePreset])

  useEffect(() => {
    mutate()
    mutateLive()
  }, [refreshCount])

  if (!enabled) {
    return (
      <ItemContainer
        title="Document Approval"
        href="/command-center/document-approval"
        widgetKey={WIDGET_KEYS.DOCUMENT_APPROVAL_DONUT}
        loading={false}
      >
        <Typography textAlign="center">
          Enable letters to access this widget.
        </Typography>
      </ItemContainer>
    )
  }

  return (
    <DoughnutItemContainer
      title="Document Approval"
      subTitle={oldestLetterDisplay}
      centerText="All documents"
      totals={totals}
      labels={labels}
      donutChartProps={{
        height: '200px',
        width: '200px',
      }}
      loading={enabled && isLoading}
      href="/command-center/document-approval"
      widgetKey={WIDGET_KEYS.DOCUMENT_APPROVAL_DONUT}
    >
      <Box>
        <DateRangeDropdown
          value={datePreset}
          onChange={(e) => setDatePreset(e.target.value)}
        />
      </Box>
    </DoughnutItemContainer>
  )
}

export default DocumentApprovalItem
