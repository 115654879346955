import React from 'react'
import useTheme from 'hooks/useTheme'

import Box from '@mui/material/Box'

import FullBox from 'components/styled/FullBox'

function Logo({ variant }) {
  const {
    images: { logo_login, logo_header },
  } = useTheme()
  if (variant === 'white')
    return (
      <FullBox sx={{ display: 'flex' }}>
        <Box
          component="img"
          src={logo_header}
          alt=""
          sx={{ width: '100%', height: 'auto', my: 'auto' }}
        />
      </FullBox>
    )

  return (
    <FullBox sx={{ display: 'flex' }}>
      <Box
        component="img"
        src={logo_login}
        alt=""
        sx={{ width: '100%', height: 'auto', my: 'auto' }}
      />
    </FullBox>
  )
}

export default Logo
