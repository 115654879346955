import { useState } from 'react'
import useSWR from 'swr'

import axios from 'utils/axios'
import { BASE_PAGE_SIZE } from 'utils/constants'
import { etagFetcher } from 'utils/fetcher'

import saveData from 'helpers/browser/saveData'
import { convertFormForAPI } from 'utils/report'
import isEmptyObject from 'helpers/node/isEmptyObject'
import useDataDomains from './useDataDomains'

const useDDReport = ({ ddReportId, ...params }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { columnToTypeMap } = useDataDomains({ embed: 'columns' })

  const {
    data: reportData,
    isValidating,
    mutate,
  } = useSWR(ddReportId ? `/ddreports/${ddReportId}` : null, etagFetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  })

  const runReport = async (reportId, extraParams) => {
    setLoading(true)

    try {
      const res = await axios.post(`/ddreports/${reportId}/runs`, {
        ...params,
        ...extraParams,
      })

      setData(res.data)
    } finally {
      setLoading(false)
    }
  }

  const previewReport = async (values, body = {}) => {
    setLoading(true)

    try {
      if (isEmptyObject(values.report_columns)) {
        return
      }

      const res = await axios.post('/ddreports/previews', {
        ...body,
        report_data: convertFormForAPI(values, columnToTypeMap),
      })
      setData(res.data)
    } finally {
      setLoading(false)
    }
  }

  const downloadReport = async (
    reportId,
    dateRange,
    reportTitle,
    total = BASE_PAGE_SIZE,
    type = 'application/pdf'
  ) => {
    const res = await axios.post(
      `/ddreports/${reportId}/runs`,
      {
        ...params,
      },
      {
        responseType: 'blob',
        headers: {
          Accept: type,
        },
      }
    )

    const ext = type === 'application/pdf' ? 'pdf' : 'csv'

    saveData(res.data, `${reportTitle || `Report.${Date.now()}.`}.${ext}`, type)
  }

  const updateReport = async (values) => {
    const body = convertFormForAPI(values, columnToTypeMap)

    const {
      headers: { etag },
    } = await axios.get(`/ddreports/${ddReportId}`)
    const res = await axios.patch(`/ddreports/${ddReportId}`, body, {
      headers: { 'If-Match': etag },
    })
    await mutate()
    return res.status === 200
  }

  const deleteReport = async () => {
    const res = await axios.delete(`/ddreports/${ddReportId}`)
    return res.status === 201
  }

  return {
    reportData: reportData || {},
    report: data || {},
    items: data?.results || [],
    columns: Object.keys(data?.results?.[0] || {}),
    loading: loading || isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    runReport,
    downloadReport,
    previewReport,
    updateReport,
    deleteReport,
  }
}

export default useDDReport
