import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'

import CssBaseline from '@mui/material/CssBaseline'

import GlobalStyle from 'components/GlobalStyle'
import MPXCopyright from 'components/layout/MPXCopyright'

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

function Auth({ children }) {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
      <MPXCopyright />
    </Root>
  )
}

export default Auth
