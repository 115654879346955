import React, { useMemo } from 'react'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import ChartLegend from 'components/common/ChartLegend'

import getDollarDisplay from 'helpers/css/getDollarDisplay'

import usePostageBalance from 'hooks/postage/usePostageBalance'
import useOrders from 'hooks/useOrders'
import useModal from 'hooks/context/useModal'

function MinimumBalance({
  clientMeta = {},
  currentBalance,
  currentBalanceDisplay,
}) {
  const { setOpen: setModalOpen, setModalProps } = useModal()

  const minimumBalance = useMemo(() => {
    try {
      const minimum = Object.values(clientMeta).reduce(
        (a, b) =>
          a > parseFloat(b?.minimum_balance || 0)
            ? a
            : parseFloat(b?.minimum_balance || 0),
        0
      )
      return minimum
    } catch (err) {
      return 0
    }
  }, [clientMeta])

  let showWarning = currentBalance < minimumBalance

  const renderMinBalanceDisplay = useMemo(() => {
    let content = 'Unable to find minimum balance'
    try {
      const clientCount = Object.keys(clientMeta || {}).length

      if (clientCount === 0) return content

      content = `${getDollarDisplay(minimumBalance)} min. balance`
    } catch (err) {
      return content
    }

    return content
  }, [clientMeta, minimumBalance])

  const handleClick = () => {
    setModalProps({
      title: 'Minimum Postage Balance',
      size: 'md',
      children: (
        <Stack spacing={1}>
          {Object.entries(clientMeta).map(([id, meta]) => {
            const isError =
              currentBalance < parseFloat(meta?.minimum_balance || 0)

            return (
              <Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    fontWeight="bold"
                    color={isError ? 'error' : undefined}
                  >
                    {meta?.client_name || 'CLIENT'} ({id})
                  </Typography>
                  {isError && (
                    <ErrorOutlineIcon
                      sx={{
                        height: 16,
                        width: 16,
                        my: 'auto',
                        color: 'error.main',
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography color={isError ? 'error' : undefined} mr={1}>
                    {getDollarDisplay(parseFloat(meta?.minimum_balance || 0))}
                  </Typography>
                  {isError && (
                    <Typography fontSize="12px" my="auto" color="error">
                      {` - Current postage balance (${currentBalanceDisplay}) is below the minimum amount required!`}
                    </Typography>
                  )}
                </Box>
              </Box>
            )
          })}
        </Stack>
      ),
      footerButtonProps: [
        {
          children: 'Close',
          color: 'primary',
          variant: 'contained',
          onClick: () => setModalOpen(false),
        },
      ],
    })
    setModalOpen(true)
  }

  let multipleClients = Object.keys(clientMeta || {}).length > 1

  return (
    <Box sx={{ display: 'flex' }}>
      <Typography
        display="flex"
        color={showWarning ? 'error.main' : 'primary'}
        my="auto"
        fontSize={12}
        sx={
          Object.keys(clientMeta || {}).length > 1 && {
            cursor: 'pointer',
            textDecoration: 'underline',
          }
        }
        onClick={multipleClients ? handleClick : undefined}
      >
        {renderMinBalanceDisplay}
        {multipleClients && ' (See All)'}
        {showWarning && (
          <Box
            sx={{ display: 'flex', cursor: 'pointer', ml: 1 }}
            title="Your current postage balance is below the minimum amount required!"
          >
            <ErrorOutlineIcon
              sx={{ height: 16, width: 16, my: 'auto', color: 'error.main' }}
            />
          </Box>
        )}
      </Typography>
    </Box>
  )
}

function PendingApprovalDisplay({ total }) {
  let Wrapper = Link
  let wrapperProps = {
    to: '/command-center/order-approval',
  }

  if (!total || total < 0) {
    Wrapper = Box
    wrapperProps = {}
  }

  return (
    <Wrapper {...wrapperProps}>
      <Typography color="primary" my="auto" fontSize={12} sx={{ mr: 2 }}>
        {total} order{total !== 1 && 's'} pending approval
      </Typography>
    </Wrapper>
  )
}

function CurrentBalance({ clientId }) {
  const { total } = useOrders({
    state: 'OrderApproval',
    status_filter: 'To Review',
    'paging[page]': 1,
    'paging[page_size]': 1,
  })
  const {
    data: {
      current_balance,
      pending_balance,
      last_updated,
      expected_usage,
      client_meta,
    },
    loading,
  } = usePostageBalance(clientId)

  const renderLastUpdated = useMemo(() => {
    try {
      return format(new Date(last_updated), 'MMMM dd, yyyy')
    } catch (err) {
      // pass
    }

    return 'N/A'
  }, [last_updated])

  const renderDollarDisplay = useMemo(() => {
    return getDollarDisplay(parseFloat(current_balance || 0))
  }, [current_balance])

  const currentBalanceProgress = useMemo(() => {
    return Math.floor(
      ((parseFloat(current_balance || 0) - parseFloat(pending_balance || 0)) /
        parseFloat(current_balance || 0)) *
        100
    )
  }, [current_balance, pending_balance])

  const expectedExceedsCurrent = useMemo(() => {
    return parseFloat(expected_usage || 0) > parseFloat(current_balance || 0)
  }, [expected_usage, current_balance])

  return (
    <Card
      sx={{
        p: 8,
        width: { xs: '100%', sm: '100%', md: '576px' },
        height: '385px',
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        margin: '0px',
      }}
    >
      {loading ? (
        <>
          <CircularProgress sx={{ mx: 'auto' }} />
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography color="primary" fontWeight={600} my="auto">
              Current balance:
            </Typography>
            <Typography
              fontWeight={500}
              color="primary"
              my="auto"
              fontSize={12}
            >
              Last updated {renderLastUpdated}
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography
              color="primary"
              fontSize="60px"
              lineHeight="60px"
              fontWeight={600}
            >
              {renderDollarDisplay}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 6 }}>
            <PendingApprovalDisplay total={total} />
            <MinimumBalance
              clientMeta={client_meta}
              currentBalance={current_balance}
              currentBalanceDisplay={renderDollarDisplay}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              flex: 1,
            }}
          >
            <Box sx={{ mb: 4 }}>
              <LinearProgress
                variant="determinate"
                value={currentBalanceProgress}
                color="success"
                sx={{
                  height: '32px',
                  borderRadius: 1.5,
                  backgroundColor: 'yellow.light',
                }}
              />
            </Box>
            <Box>
              <ChartLegend
                labels={[
                  'Available',
                  'Reserved for orders in production',
                  expectedExceedsCurrent ? (
                    <Box
                      title="This is an estimate and could be impacted by one-off orders and spikes in volume."
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        cursor: 'help',
                      }}
                    >
                      <Typography fontSize="10px" fontWeight={600}>
                        Expected 30-Day Usage -{' '}
                      </Typography>
                      <ErrorOutlineIcon
                        sx={{
                          height: '14px',
                          width: '14px',
                          my: 'auto',
                          color: 'error.main',
                          mx: '2px',
                        }}
                      />
                      <Typography
                        fontSize="10px"
                        fontWeight={600}
                        color="error.main"
                      >
                        Your 30-day expected postage usage exceeds your current
                        balance!
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      title="This is an estimate and could be impacted by one-off orders and spikes in volume."
                      sx={{ cursor: 'help' }}
                    >
                      Expected 30-Day Usage
                    </Box>
                  ),
                ]}
                data={[
                  getDollarDisplay(
                    parseFloat(current_balance || 0) -
                      parseFloat(pending_balance || 0)
                  ),
                  getDollarDisplay(parseFloat(pending_balance || 0)),
                  getDollarDisplay(parseFloat(expected_usage || 0)),
                ]}
                colors={[
                  'green.main',
                  'yellow.light',
                  expectedExceedsCurrent ? 'error.main' : 'primary.main',
                ]}
                labelProps={{
                  sx: {
                    fontWeight: 600,
                  },
                }}
                sx={{
                  justifyContent: 'flex-start',
                  '& > *': {
                    mb: 4,
                  },
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </Card>
  )
}

export default CurrentBalance
