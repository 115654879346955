import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedListHR from 'components/styled/ExpandedListHR'
import LotsTable from 'components/order_history/LotsTable'

// local components
import StatusHistoryDisplay from './components/StatusHistoryDisplay'
import Notes from './components/Notes'
import ShowMoreContent from './components/ShowMoreContent'
import OrderProgress from 'components/order_history/OrderProgress'

function OrderHistoryExpandedList({
  children,
  expanded,
  rowData,
  columns,
  statusTitle = '',
  statusMessage = '',
  getDataDisplay,
  visibleColumns,
  collapseDetails,
}) {
  const { notes, workflow_statuses: statusHistory } = rowData?._embedded ?? {}
  const gridWidth = notes?.length > 0 ? 12 : 6

  const [open, setOpen] = useState(!collapseDetails)

  Object.keys(statusHistory).forEach((k) => {
    if (rowData['status'].status === statusHistory[k].status) {
      statusHistory[k]['current'] = true
      statusHistory[k].display = rowData['status'].display
    } else statusHistory[k]['current'] = false
  })

  return (
    <Collapse
      in={expanded}
      sx={{
        textAlign: 'left',
        px: 11,
        pt: 2,
        mt: 0,
        '& .MuiCollapse-entered': {
          mt: 4,
        },
      }}
    >
      {statusTitle && (
        <Typography fontWeight="bold" fontSize={14}>
          {statusTitle}
        </Typography>
      )}

      {rowData?.status?.status?.toLowerCase() === 'processing' && (
        <OrderProgress order={rowData} label="Processing Progress" />
      )}

      {statusHistory && expanded && (
        <StatusHistoryDisplay
          statusHistory={statusHistory}
          currentStatus={rowData?.status}
        />
      )}

      {Boolean(rowData?.lots && expanded) && <LotsTable lots={rowData?.lots} />}

      <Grid container columns={gridWidth} spacing={30}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight="medium" fontSize={14}>
              Details
            </Typography>
            {collapseDetails && (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </Box>
          <ExpandedListHR />
          <ShowMoreContent
            rowData={rowData}
            columns={columns}
            open={open}
            getDataDisplay={getDataDisplay}
            visibleColumns={visibleColumns}
          />
        </Grid>
        {notes?.length > 0 && (
          <Grid item xs={6}>
            <Notes notes={notes} />
          </Grid>
        )}
      </Grid>
      {children}
    </Collapse>
  )
}

export default OrderHistoryExpandedList
