import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'

import useTheme from '@mui/styles/useTheme'

import CloseIcon from '@mui/icons-material/Close'

function BasicDrawer({
  width = '428px',
  mobileWidth = '100vw',
  open,
  setOpen,
  children,
  fadeProps,
  collapseProps,
  sx,
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const handleClose = () => setOpen(false)
  const drawerWidth = isMobile ? mobileWidth : width

  return (
    <Box sx={{ position: 'fixed', right: 0, zIndex: 100, ...sx }}>
      <Collapse in={open} orientation="horizontal" {...collapseProps}>
        <Box>
          <Card sx={{ minWidth: drawerWidth, maxWidth: drawerWidth }}>
            <Box sx={{ height: '16px' }}>
              <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Fade
              in={open}
              timeout={{ appear: 2000, enter: 800 }}
              {...fadeProps}
            >
              <Box>{children}</Box>
            </Fade>
          </Card>
        </Box>
      </Collapse>
    </Box>
  )
}

export default BasicDrawer
