import { useState, useEffect } from 'react'
import sub from 'date-fns/sub'
import deepEqual from 'deep-equal'

import axios from 'utils/axios'
import { WIP_PAGE_DAYS } from 'utils/constants'

import urlSerialize from 'helpers/browser/urlSerialize'
import formatDateToUTC from 'helpers/datetime/formatDateToUTC'

const useOrdersInfinite = ({ params, manualStartDate, manualEndDate }) => {
  const [cachedParams, setCachedParams] = useState(params)
  const [infiniteLoading, setInfiniteLoading] = useState(false)
  const [data, setData] = useState(null)
  const [infiniteData, setInfiniteData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  const handleGet = async (initial = false) => {
    const notInfinite = Boolean(manualStartDate)
    const sorted = Boolean(cachedParams['sort[0]'])

    const startDate =
      formatDateToUTC(manualStartDate) ||
      formatDateToUTC(sub(new Date(), { days: WIP_PAGE_DAYS * (page + 1) }))

    let endDate = !manualStartDate
      ? formatDateToUTC(sub(new Date(), { days: 1 + WIP_PAGE_DAYS * page }))
      : formatDateToUTC(manualEndDate)

    if (initial && !notInfinite) {
      endDate = formatDateToUTC(new Date())
    }

    const urlParams = { ...cachedParams }

    if (!manualStartDate) {
      urlParams['date_created[start]'] = startDate
      urlParams['date_created[end]'] = endDate
    }

    const endpoint =
      Boolean(cachedParams) && `/orders?${urlSerialize(urlParams)}`

    if (endpoint) {
      if (page === 1 || notInfinite) {
        setLoading(true)
      } else {
        setInfiniteLoading(true)
      }
      try {
        const res = await axios.get(endpoint)
        const orders = res.data._embedded?.orders || []

        if (!sorted && orders.length > 0) {
          orders.sort(
            (a, b) =>
              new Date(b.datetime_created) - new Date(a.datetime_created)
          )
        }

        if (notInfinite) {
          setData([res.data])
        } else {
          setInfiniteData(infiniteData.concat([res.data]))
          setData(null)
        }
      } catch (err) {
        setData(null)
      } finally {
        // without a delay, window freezes shortly instead of showing a loader
        setTimeout(() => {
          setLoading(false)
          setInfiniteLoading(false)
        }, 100)
      }
    }
  }

  useEffect(() => {
    if (page === 1) {
      handleGet(true)
    } else {
      handleGet()
    }
  }, [page, cachedParams])

  useEffect(() => {
    if (!deepEqual(cachedParams, params)) {
      setInfiniteData([])
      setData(null)
      setCachedParams(params)
      setPage(1)
    }
  }, [params])

  const orders = data || infiniteData || []

  return {
    orders,
    allOrders: orders?.flatMap((data) => data?._embedded?.orders) ?? [],
    total: orders?.length
      ? orders.reduce((sum, orders) => sum + orders.total, 0)
      : 1,
    loading: loading || !orders?.length,
    additionalLoading: infiniteLoading,
    page,
    setPage: (page) => {
      setInfiniteLoading(true)
      setPage(page)
    },
    mutate: handleGet,
  }
}

export default useOrdersInfinite
