import React, { useCallback, useMemo, useRef } from 'react'
import { Formik } from 'formik'
import deepEqual from 'deep-equal'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'

import Modal from 'components/common/Modal'
import MultiselectTree from 'components/common/MultiselectTree'

import isEmptyObject from 'helpers/node/isEmptyObject'
import recursiveSort from 'helpers/node/recursiveSort'

import useNotification from 'hooks/context/useNotification'
import PaperlessSection from './PaperlessSection'

const POLICY_STATES = {
  Maine: 'ME',
  Massachusetts: 'MA',
  'New Hampshire': 'NH',
  Pennsylvania: 'PA',
  Vermont: 'VT',
  Virginia: 'VA',
}

const TRANSACTION_TYPES = {
  New: 'New Business',
  Endorsement: 'Endorsement',
  Renewal: 'Renewal',
}

const LINE_OF_BUSINESS = {
  Personal: {
    'Dwelling Fire': 'Dwelling Fire',
    Homeowners: 'Homeowners',
    'Personal Auto': 'Personal Auto',
    'Personal Umbrella': 'Personal Umbrella',
    Snowmobile: 'Snowmobile',
    Watercraft: 'Watercraft',
  },
  Commercial: {
    'Business Owners / Special Trade Contractors':
      'Business Owners / Special Trade Contractors',
    'Commercial Auto': 'Commercial Auto',
    'Commercial Fire': 'Commercial Fire',
    'Commercial Inland Marine': 'Commercial Inland Marine',
    'Commercial Package': 'Commercial Package',
    'Commercial Umbrella': 'Commercial Umbrella',
    'Farm Owners': 'Farm Owners',
  },
}

const extractItems = (map, parentKey = '') => {
  return Object.entries(map).map(([key, value]) => {
    let items = undefined
    let itemValue = undefined
    let defaultExpanded = false

    if (typeof value === 'object') {
      defaultExpanded = true
      items = extractItems(value, parentKey)
    } else {
      itemValue = value
    }

    return {
      label: key,
      defaultExpanded,
      parentKey,
      value: itemValue,
      items,
    }
  })
}

const constructTree = (rootKey = '') => {
  const policyStates = {
    label: 'Policy State',
    defaultExpanded: true,
    items: extractItems(POLICY_STATES, rootKey).map((state) => {
      const key = `${rootKey}.${POLICY_STATES[state.label]}`
      return {
        key,
        label: state.label,
        items: [
          {
            label: 'Transaction Type',
            defaultExpanded: true,
            items: extractItems(TRANSACTION_TYPES, key).map((tt) => {
              const ttKey = `${key}.${TRANSACTION_TYPES[tt.label]}`
              return {
                key: ttKey,
                label: tt.label,
                items: [
                  {
                    label: 'Line of Business',
                    defaultExpanded: true,
                    items: extractItems(LINE_OF_BUSINESS, ttKey),
                  },
                ],
              }
            }),
          },
        ],
      }
    }),
  }

  return [policyStates]
}

const DEFAULT_DOC_TYPE = 'NP'

function EditPreferencesModal({
  open,
  setOpen,
  agency,
  updateAgencyPreferences,
}) {
  const { setBasicNotification, setError } = useNotification()
  const formikRef = useRef()

  const initialDocumentType = useMemo(() => {
    if (!agency) return DEFAULT_DOC_TYPE

    const mailingPreferences = agency.mailing_preferences || {}

    if (!isEmptyObject(mailingPreferences)) {
      const keys = Object.keys(mailingPreferences)

      // only support one document type
      return keys[0]
    }

    return DEFAULT_DOC_TYPE
  }, [agency])

  const paperless = useMemo(() => {
    return agency?.mailing_preferences?.[initialDocumentType]?.Paperless || {}
  }, [initialDocumentType, agency])

  const preferencesTree = useMemo(() => {
    return constructTree(initialDocumentType)
  }, [initialDocumentType])

  const handleSubmit = useCallback(
    async (values) => {
      try {
        // sort all arrays in each obj for deepEqual
        recursiveSort(agency?.mailing_preferences)
        recursiveSort(values?.mailingPreferences)

        if (
          initialDocumentType !== values.documentType ||
          !deepEqual(agency?.mailing_preferences, values.mailingPreferences) ||
          !deepEqual(paperless, values.paperless)
        ) {
          await updateAgencyPreferences({
            agencyId: agency?.agency_code,
            values: {
              mailing_preferences: {
                [values.documentType]: {
                  ...values.mailingPreferences?.[initialDocumentType],
                  Paperless: values.paperless,
                },
              },
            },
          })

          setBasicNotification(
            `${agency?.name} Mailing Preferences has been successfully updated!`
          )
          setOpen(false)
          return
        }

        throw new Error('No changes made to Mailing Preferences')
      } catch (err) {
        setError(
          err.response?.data?.display_message ||
            err?.message ||
            'Unable to update agency.'
        )
      }
    },
    [agency, initialDocumentType, paperless]
  )

  return (
    <Modal
      scrollable
      height="90vh"
      width="732px"
      open={open}
      onClose={() => setOpen(false)}
      title={`${agency?.name} (${agency?.agency_code})`}
      footerButtonProps={[
        {
          children: <Box>Save Preferences</Box>,
          variant: 'contained',
          color: 'primary',
          onClick: () => formikRef.current.submitForm(),
        },
        {
          children: 'Cancel Changes',
          variant: 'outlined',
          color: 'primary',
          onClick: () => setOpen(false),
        },
      ]}
    >
      <Alert severity="info" sx={{ mb: 3, color: 'primary.main' }}>
        Only add preferences intended to be mailed to the agency office.
      </Alert>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        onSubmit={handleSubmit}
        initialValues={{
          documentType: initialDocumentType,
          mailingPreferences: agency?.mailing_preferences,
          paperless: paperless,
        }}
      >
        {({ values, handleChange }) => (
          <Stack spacing={4} sx={{ pt: 2 }}>
            <TextField
              label="Document Type"
              variant="outlined"
              size="small"
              name="documentType"
              value={values.documentType}
              onChange={handleChange}
              disabled
            />
            <PaperlessSection />
            <MultiselectTree
              defaultExpanded
              options={preferencesTree}
              values={values.mailingPreferences}
              name="All"
              title="Mailing Preferences"
              onChange={(newPrefs) =>
                handleChange({
                  target: { name: 'mailingPreferences', value: newPrefs },
                })
              }
            />
          </Stack>
        )}
      </Formik>
    </Modal>
  )
}

export default EditPreferencesModal
