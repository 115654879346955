import React from 'react'

import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import EditIcon from '@mui/icons-material/Edit'

function BulkMailCell({ row, colId, getDataDisplay }) {
  return <TableCell>{getDataDisplay(row, colId)}</TableCell>
}

function BulkMailRow({
  row,
  rowKey,
  visibleColumns = [],
  getDataDisplay,
  onDetailsClick,
  columns,
}) {
  return (
    <>
      <TableRow key={row[rowKey]} sx={{ border: 0 }}>
        {visibleColumns.map((col) => (
          <BulkMailCell
            key={`${row[rowKey]}-${col}`}
            row={row}
            getDataDisplay={getDataDisplay}
            colId={col}
          />
        ))}
        <TableCell>
          <Button
            color="primary"
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => onDetailsClick(row)}
          >
            Details
          </Button>
        </TableCell>
      </TableRow>
    </>
  )
}

export default BulkMailRow
