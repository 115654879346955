export const parsePrintAssetRules = (rules = {}, collateralEnabled = false) => {
  const acceptArr = []

  Object.entries(rules).forEach(([k1, v1]) => {
    if (typeof v1 === 'object') {
      Object.entries(v1).forEach(([k2, v2]) => {
        if (v2?.allowed) {
          if (collateralEnabled && k1.toLowerCase() !== 'image') {
            return
          }

          if (k2 === 'vnd.adobe.photoshop') {
            acceptArr.push('.psd')
          }

          acceptArr.push(`${k1}/${k2}`)
        }
      })
    }
  })

  return acceptArr
}
