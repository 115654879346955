import React from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import usePrograms from 'hooks/programs/usePrograms'
import useReports from 'hooks/reporting/useReports'
import useDocumentGroups from 'hooks/documents/useDocumentGroups'

function ModuleRestrictions({ restrictions }) {
  const { programs } = usePrograms()
  const { allReports } = useReports({})
  const { documentGroups } = useDocumentGroups()

  const updateRestriction = ([key, vals]) => {
    if (key === 'program') {
      const displays = vals.map((val) => {
        const foundProgram = programs.find(
          (program) => parseInt(program.id) === parseInt(val)
        )

        if (foundProgram) {
          return foundProgram.name
        }

        return `Program #${val}`
      })

      return [key, displays]
    }

    if (key === 'report') {
      const displays = vals.map((val) => {
        const foundReport = allReports.find(
          (report) => parseInt(report.id) === parseInt(val)
        )

        if (foundReport) {
          return foundReport.title
        }

        return `Report #${val}`
      })

      return [key, displays]
    }

    if (key === 'docgroup') {
      const displays = vals.map((val) => {
        const foundGroup = documentGroups.find(
          (group) => parseInt(group.id) === parseInt(val)
        )

        if (foundGroup) {
          return foundGroup.name
        }

        return `Document Group #${val}`
      })

      return [key, displays]
    }

    return [key, vals]
  }

  if (Object.values(restrictions).length === 0) {
    return <></>
  }

  return (
    <Box>
      <Typography variant="h1" fontWeight="bold">
        Restrictions
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box>
        {Object.entries(restrictions)
          .map(updateRestriction)
          .map(([key, restrs]) => (
            <Box>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  display: 'inline',
                  fontWeight: 'bold',
                }}
              >
                {key.replace(/-/gm, ' ')}
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Box component="ul">
                {restrs.map((restr) => (
                  <Box component="li">{restr}</Box>
                ))}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

function ModulePrivileges({ mod, privileges, restrictions }) {
  const modulePrivileges = privileges
    .filter((priv) => `${priv?._embedded?.module?.id}` === `${mod.id}`)
    .sort((a, b) => (a.name || '').localeCompare(b.name || ''))

  return (
    <Box>
      <Typography variant="h2" fontWeight="bold">
        {mod.display}
      </Typography>
      <Divider sx={{ my: 1 }} />
      {Boolean(modulePrivileges?.length) ? (
        <Box>
          {modulePrivileges.map((priv) => (
            <Box component="ul">
              {Boolean(priv.name) && (
                <>
                  <Typography fontWeight="bold">{priv.name}</Typography>
                  <Divider sx={{ my: 1 }} />
                </>
              )}
              {priv._embedded.privileges
                .filter((p) => p?.name)
                .map((p) => (
                  <Box component="li" sx={{ lineHeight: '16px' }}>
                    {p.name}
                    <Typography
                      component="span"
                      fontSize={9}
                      color="darkgray.main"
                    >
                      {p.restricted_by && restrictions[p.restricted_by]
                        ? ` - Restricted by ${p.restricted_by}`
                        : ''}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ))}
        </Box>
      ) : (
        <Box component="ul">
          <Box component="li">Full Access</Box>
        </Box>
      )}
    </Box>
  )
}

function PrivilegeModal({ open, onClose, role }) {
  const privileges = role?._embedded?.privilege_sets || []
  const modules = role?._embedded?.modules || []
  const restrictions = role?._embedded?.restrictions || []

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${role?.name || ''} Role`}
      size=""
      height="90vh"
      width="400px"
      footerButtonProps={[
        {
          children: 'Close',
          variant: 'contained',
          color: 'primary',
          onClick: () => onClose(),
        },
      ]}
    >
      <Stack spacing={3}>
        <Typography variant="h1" fontWeight="bold">
          Privileges
        </Typography>
        <Divider sx={{ my: 1 }} />
        {modules.length ? (
          modules.map((mod) => (
            <ModulePrivileges
              key={mod.id}
              mod={mod}
              privileges={privileges}
              restrictions={restrictions}
            />
          ))
        ) : (
          <Typography textAlign="center" pb={3}>
            No privileges set.
          </Typography>
        )}
        <ModuleRestrictions restrictions={restrictions} />
      </Stack>
    </Modal>
  )
}

export default PrivilegeModal
