import { useState, useEffect } from 'react'
import urlSerialize from 'helpers/browser/urlSerialize'
import useSWR from 'swr'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

const useLibraries = (params = null) => {
  const [latest, setLatest] = useState([])
  const [loading, setLoading] = useState(false)

  const { data, isValidating, mutate } = useSWR(
    `/libraries?${urlSerialize({
      versionactive: 1,
      versiondefault: 1,
      embed: 'versions',
      ...params,
    })}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const addLibraryItem = async ({ updateData }) => {
    const errorMsg = 'Error adding library item'

    if (!updateData) throw new Error()

    const formData = new FormData()

    Object.entries(updateData).forEach(([key, value]) => {
      if (key === 'libraryfile' && !value) return

      // value can be falsey
      if (value !== undefined || value !== null) {
        // check for redundancies
        if (value !== data[key]) {
          formData.append(key, value)
        }
      }
    })

    const res = await axios.post(`/libraries`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      mutate()
    }
  }

  const fetchByVersion = async (libId, versionNum) => {
    const res = await axios.get(`/libraries/${libId}/versions/${versionNum}`)
    return res.data
  }

  const parseCurrent = async (libraries) => {
    const updated = await Promise.all(
      libraries.map(async (libItem) => {
        const currentVersion = libItem.default_version?.toString()
        const allVersions = libItem?._embedded?.versions?.versions || []
        const version = allVersions.find(
          (v) => v.version.toString() === currentVersion
        )

        return {
          ...libItem,
          ...version,
        }
      })
    )

    setLatest(updated)
  }

  useEffect(() => {
    const asyncHandler = async () => {
      setLoading(true)

      try {
        const libraries = data?._embedded?.libraries
        if (Array.isArray(libraries)) {
          return parseCurrent(libraries)
        }

        throw new Error('Invalid data')
      } catch (err) {
        setLatest([])
      } finally {
        setLoading(false)
      }
    }

    asyncHandler()
  }, [data])

  return {
    libraries: latest,
    loading:
      (!data?._embedded?.libraries && !data?.error) || isValidating || loading,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    addLibraryItem,
    fetchByVersion,
  }
}

export default useLibraries
