import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import uniqBy from 'lodash/uniqBy'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import Drawer from 'components/common/Drawer/Basic'
import Section from './Section'
import ValueDisplay from './ValueDisplay'

import useConfiguration from 'hooks/useConfiguration'
import ScrollableBoxWrapper from 'components/styled/ScrollableBox'
import useDataDomains from 'hooks/reporting/useDataDomains'
import { convertApiToForm, convertFormForAPI } from 'utils/report'

function DDSidebar({
  open,
  setOpen,
  reports = [],
  activeReport,
  activeReportData,
  setReportingView,
}) {
  const { updatePath } = useConfiguration()
  const navigate = useNavigate()
  const [localActiveReport, setLocalActiveReport] = useState(activeReport)
  const { dataDomains, columnToTypeMap } = useDataDomains({ embed: 'columns' })

  const foundReport = useMemo(() => {
    return reports?.find((report) => report.id === localActiveReport)
  }, [reports, localActiveReport])

  const handleSubmit = (values) => {
    const userFilters = {}

    const apiData = convertFormForAPI(values, columnToTypeMap)
    Object.entries(apiData?.where || {}).forEach(([name, filter]) => {
      userFilters[name] = filter.default
    })

    setReportingView(localActiveReport, [], userFilters)

    const reportName = foundReport?.title

    const breadcrumbs = [
      {
        display: 'Reporting',
        href: '/command-center/reporting',
      },
      reportName && {
        display: reportName,
        href: `/command-center/reporting/${reportName}`,
      },
    ].filter((b) => b)

    updatePath(breadcrumbs)

    navigate(`/command-center/reporting`, {
      shallow: true,
      state: {
        activeReport: localActiveReport,
        reportName: reportName,
        breadcrumbs,
        isDD: true,
      },
    })
  }

  const initialValues = useMemo(() => {
    if (!foundReport) return { report_filters: [] }

    return convertApiToForm({ reportData: foundReport, dataDomains })
  }, [foundReport, dataDomains])

  const initialStatus = useMemo(() => {
    let newColumns = []

    dataDomains.forEach((domain) => {
      const columns = domain._embedded?.columns || {}
      newColumns = newColumns.concat(
        Object.entries(columns).flatMap(([view, dd]) =>
          Object.entries(dd).map(([k, v]) => ({
            dataType: v,
            col: k,
            view: view,
          }))
        )
      )
    })

    newColumns = [...uniqBy(newColumns, (col) => col.col)]

    return { columns: newColumns }
  }, [dataDomains])

  if (!initialStatus?.columns?.length) return <></>

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      initialStatus={initialStatus}
    >
      {({ submitForm, values }) => (
        <Drawer
          open={open}
          setOpen={setOpen}
          sx={{ border: '1px solid', borderColor: 'lightgray.main' }}
          width="605px"
        >
          <Box
            sx={{
              px: 4,
              height: '100vh',
            }}
          >
            <Box sx={{ py: 6, textAlign: 'center' }}>
              <Typography fontWeight={'bold'} variant="h2">
                Search / Filter
              </Typography>
            </Box>
            <Stack spacing={4}>
              <TextField
                fullWidth
                size="small"
                label="Report type"
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { maxHeight: '50vh !important', boxShadow: 2 },
                    },
                  },
                }}
                value={localActiveReport}
                onChange={(e) => setLocalActiveReport(e.target.value)}
              >
                {reports.map((report) => (
                  <MenuItem key={report.id} value={report.id}>
                    {report.title}
                  </MenuItem>
                ))}
              </TextField>
              {Boolean(values.report_filters.length) && (
                <Stack spacing={4}>
                  <Section
                    title={`Report Inputs (${values.report_filters.length})`}
                    selected={[]}
                  >
                    <ScrollableBoxWrapper sx={{ height: '60vh' }}>
                      <Stack mr={4}>
                        {values.report_filters.map((filter) => (
                          <ValueDisplay
                            readonly
                            key={filter.col}
                            value={filter}
                            type="filter"
                          />
                        ))}
                      </Stack>
                    </ScrollableBoxWrapper>
                  </Section>
                </Stack>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  Execute search
                </Button>
              </Box>
            </Stack>
          </Box>
        </Drawer>
      )}
    </Formik>
  )
}

export default DDSidebar
