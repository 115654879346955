import React from 'react'
import 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2'

import Box from '@mui/material/Box'

function DoughnutChart({
  chartData,
  title,
  legend,
  children,
  height = '222px',
  width = '222px',
}) {
  return (
    <Box sx={{ position: 'relative', height, width }}>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
        }}
      >
        <Box sx={{ mx: 'auto', my: 'auto' }}>{children}</Box>
      </Box>
      <Doughnut
        data={chartData}
        options={{
          plugins: {
            title: {
              display: Boolean(title),
              text: title,
            },
            legend: {
              display: false,
              position: 'right',
              align: 'start',
              labels: {
                boxHeight: 32,
                boxWidth: 4,
                padding: 24,
              },
              ...legend,
            },
          },
        }}
      />
    </Box>
  )
}

export default DoughnutChart
