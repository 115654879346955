import urlSerialize from 'helpers/browser/urlSerialize'
import { useMemo } from 'react'
import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const useDataDomains = (params) => {
  const { data, isValidating, mutate } = useSWR(
    params ? `/datadomains?${urlSerialize(params)}` : '/datadomains',
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  )

  const columnToTypeMap = useMemo(() => {
    if (!params?.embed?.includes('columns')) return {}

    let map = {}

    const dataDomains = data?._embedded?.datadomains || []

    dataDomains.forEach((dd) => {
      const columns = dd?._embedded?.columns || {}

      Object.values(columns).forEach((colMap) => {
        map = { ...map, ...colMap }
      })
    })

    return map
  }, [data])

  return {
    dataDomains: data?._embedded?.datadomains || [],
    embedded: data?._embedded ?? {},
    loading: (!data?._embedded?.reports && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    columnToTypeMap,
    mutate,
  }
}

export default useDataDomains
