import urlSerialize from 'helpers/browser/urlSerialize'
import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const usePrograms = (params) => {
  const { data, isValidating, mutate } = useSWR(
    `/programs${params ? `?${urlSerialize(params)}` : ''}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    programs: data?._embedded?.programs ?? [],
    loading: (!data?._embedded?.programs && !data?.error) || isValidating,
    error: data?.error,
    mutate,
  }
}

export default usePrograms
