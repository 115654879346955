import React, { useEffect, useRef } from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useParams, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import FullBox from 'components/styled/FullBox'

import useNotification from 'hooks/context/useNotification'

function PasswordReset() {
  const formikRef = useRef(null)
  const navigate = useNavigate()
  const { shortName } = useParams()
  const { setBasicNotification, setError } = useNotification()

  const initialValues = {
    email: '',
    instanceId: shortName,
  }

  const handleSubmit = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/${shortName}/password-reset-codes`,
        {
          email: values.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
          },
        }
      )

      if (res.data.success) {
        setBasicNotification(
          'Your request has been received. Please check your email inbox for further instructions.'
        )
        navigate('/')
      } else {
        throw new Error()
      }
    } catch (err) {
      setError(
        err.response?.data?.display_message ||
          'Error resetting password. Try again later.'
      )
    }
  }

  useEffect(() => {
    const { setValues, values } = formikRef.current
    setValues({
      ...values,
      instanceId: shortName,
    })
  }, [shortName])

  return (
    <FullBox sx={{ display: 'flex' }}>
      <Container maxWidth="sm" sx={{ mt: 40 }}>
        <Card
          sx={{
            boxShadow: 2,
            px: 12,
            py: 14,
          }}
        >
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Please enter a valid email')
                .required('Email is required'),
              instanceId: Yup.string().required(
                'Instance shortname is required in URL'
              ),
            })}
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              isSubmitting,
              handleBlur,
              touched,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Stack spacing={4} mb={4}>
                  <Typography variant="h1" textAlign="center">
                    Request password reset
                  </Typography>
                  <Typography
                    variant="h2"
                    textAlign="center"
                    fontWeight={'medium'}
                  >
                    MPXLinq
                  </Typography>
                  <Box>
                    <Typography mb={2}>
                      Please enter your email address
                    </Typography>
                    <TextField
                      fullWidth
                      label="Email"
                      onChange={handleChange}
                      name="email"
                      disabled={!shortName}
                      error={Boolean(touched.email) && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                    />
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'center',
                      color: 'error.main',
                    }}
                  >
                    {errors.instanceId}
                  </Box>
                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ width: '50%', mx: 'auto' }}
                      onClick={handleSubmit}
                      disabled={!shortName || isSubmitting}
                    >
                      Send
                    </Button>
                  </Box>
                </Stack>
              </form>
            )}
          </Formik>
          <Box sx={{ width: '100%', display: 'flex', textAlign: 'center' }}>
            <Typography
              fontSize={12}
              color="darkgray.main"
              sx={{ width: '50%', mx: 'auto' }}
            >
              Please contact MPX if you are having trouble logging in.
            </Typography>
          </Box>
        </Card>
      </Container>
    </FullBox>
  )
}

export default PasswordReset
