import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedListHR from 'components/styled/ExpandedListHR'

import FixedButtons from 'components/common/DataList/Item/components/FixedButtons'
import { Link } from 'react-router-dom'

import ShowMoreContent from './components/ShowMoreContent'

import useConfiguration from 'hooks/useConfiguration'

function OrderDetailExpandedList({
  children,
  expanded,
  rowData,
  columns,
  statusTitle = '',
  statusMessage = '',
  getDataDisplay,
  visibleColumns,
  collapseDetails,
}) {
  const {
    navigation: { currentPath },
  } = useConfiguration()
  const reports = {}

  const procReportParams = { orderId: null, reportId: null, currentPath }

  if (rowData?._embedded?.reports?.length > 0) {
    Object.keys(rowData._embedded.reports).forEach((key) => {
      if (rowData._embedded.reports[key].type === 'processing') {
        reports['processing'] = rowData._embedded.reports[key]
        procReportParams.orderId = reports['processing'].order_id
        procReportParams.reportId = reports['processing'].id
      }
    })
  }

  const [open, setOpen] = useState(true)

  const fixedButtonProps = []

  return (
    <Collapse
      in={expanded}
      sx={{
        backgroundColor: '#fff',
        textAlign: 'left',
        px: 11,
        pt: 2,
        mt: 0,
        '& .MuiCollapse-entered': {
          mt: 4,
        },
      }}
    >
      {statusTitle && (
        <Typography fontWeight="bold" fontSize={14}>
          {statusTitle}
        </Typography>
      )}

      <Grid container columns={12} rowSpacing={10} columnSpacing={30}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight="medium" fontSize={14}>
              Details
            </Typography>
            {collapseDetails && (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </Box>
          <ExpandedListHR />
          <ShowMoreContent
            rowData={rowData}
            columns={columns}
            open={open}
            getDataDisplay={getDataDisplay}
            visibleColumns={visibleColumns}
          />
        </Grid>

        <Grid item xs={10} sx={{ padding: 10 }}>
          {procReportParams.orderId ? (
            <>
              <Link
                to={`/command-center/order-details/${procReportParams.orderId}/order-processing-report`}
                state={procReportParams}
                sx={{ fontSize: 14 }}
              >
                Download Processing Report
              </Link>
            </>
          ) : (
            'Processing Report unavailable'
          )}
        </Grid>
        <Grid item xs={2} sx={{ padding: 0, marginBottom: 2 }}>
          {fixedButtonProps?.length > 0 && (
            <FixedButtons fixedButtonProps={fixedButtonProps} />
          )}
        </Grid>
      </Grid>
      {children}
    </Collapse>
  )
}

export default OrderDetailExpandedList
