import useSWR from 'swr'

import fetcher from 'utils/fetcher'

const usePostageBalance = (clientId = undefined) => {
  const endpoint = '/postage-balance'
  const { data, isValidating, mutate } = useSWR(
    clientId ? `${endpoint}?client_id=${clientId}` : endpoint,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data: data ?? [],
    loading: (!data && !data?.error) || isValidating,
    error: data?.error,
    mutate,
  }
}

export default usePostageBalance
