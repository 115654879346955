import React from 'react'

import Box from '@mui/material/Box'

import Modal from 'components/common/Modal'
import PDFViewer from 'components/common/PDFViewer'

function SplitPreviewModal(props) {
  const splitPdf = props.splitPdf
  return (
    <Modal {...props}>
      {splitPdf && (
        <Box
          sx={{
            pt: 2,
            height: '69vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ flex: 1 }}>
            {splitPdf && (
              <PDFViewer
                id="split-pdf"
                url={splitPdf}
                fileName={`${Date.now()}.pdf`}
              />
            )}
          </Box>
        </Box>
      )}
    </Modal>
  )
}

export default SplitPreviewModal
