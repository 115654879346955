import { PDFDocument, rgb } from 'pdf-lib'

/**
 * Render a redacted pdf using pdf lib
 * @param {Blob} pdfBlob - blob of pdf
 * @param {Array<Array<{ origin: { x: number, y: number }, end: { x: number, y: number } }>>} rects - redacted rectangles to render, per page
 */
async function renderRedactedPDF(pdfBlob, rects = []) {
  const pdfBytes = await pdfBlob.arrayBuffer()
  const pdfDoc = await PDFDocument.load(pdfBytes)
  const pages = pdfDoc.getPages()

  pages.forEach((page, pageIndex) => {
    const pageRects = rects[pageIndex]
    if (Array.isArray(pageRects) && pageRects.length > 0) {
      pageRects.forEach((pageRect) => {
        const x = pageRect.origin.x
        const height = pageRect.end.y - pageRect.origin.y
        const y = page.getHeight() - pageRect.origin.y - height
        const width = pageRect.end.x - pageRect.origin.x
        page.drawRectangle({
          x,
          y,
          width,
          height,
          color: rgb(0, 0, 0),
        })
      })
    }
  })

  const arrayBuffer = await pdfDoc.save()
  const base64 = await pdfDoc.saveAsBase64()

  return { arrayBuffer, base64 }
}

export default renderRedactedPDF
