import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import useConfiguration from 'hooks/useConfiguration'

function ExpandedListItem({ id, display, data, getDataDisplay }) {
  const { getMetadataDisplay } = useConfiguration()

  return (
    <Box
      key={`extra-row-${data.id}-${id}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        mt: 0,
        mb: 6,
        ml: 0,
        mr: 4,
        wordBreak: 'break-word',
      }}
    >
      <Typography fontWeight="bold" fontSize={14}>
        {getMetadataDisplay(`_.metadata.${display}`)}
      </Typography>
      <Box>{getDataDisplay(data, id)}</Box>
    </Box>
  )
}

export default ExpandedListItem
