import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import fetcher from 'utils/fetcher'
import { APP_KEY } from 'utils/constants'

const useRoles = (params) => {
  const { data, isValidating, mutate } = useSWR(
    params
      ? `/roles?${urlSerialize({
          embed: 'privilege_sets|modules',
          app_key: APP_KEY,
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    roles: data?._embedded?.roles ?? [],
    loading: (!data?._embedded?.roles && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default useRoles
