import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import getMimeTypeDisplay from 'helpers/css/getMimeTypeDisplay'

function LibraryTable({
  dataList,
  columns,
  getDataDisplay,
  sortList,
  setSortList,
  loading,
  ...props
}) {
  const handleColumnClick = useCallback(
    (col) => {
      // alternate between not sorted, asc, and desc
      if (!sortList['sort[0]']) {
        setSortList({ 'sort[0]': `${col}:asc` })
      } else if (sortList['sort[0]'] === `${col}:asc`) {
        setSortList({ 'sort[0]': `${col}:desc` })
      } else {
        setSortList({})
      }
    },
    [sortList, setSortList]
  )

  const getExtension = useCallback((mimeType) => {
    return getMimeTypeDisplay(mimeType)
  }, [])

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Box
          sx={{
            height: '64px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="assets-table">
          <TableHead>
            <TableRow>
              <TableCell>File type</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortList && sortList['sort[0]']?.match(/^name:/)}
                  direction={sortList?.['sort[0]']?.split(':')?.[1]}
                  onClick={() => handleColumnClick('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Category</TableCell>
              <TableCell>
                <TableSortLabel
                  active={
                    sortList && sortList['sort[0]']?.match(/^datetime_created:/)
                  }
                  direction={sortList?.['sort[0]']?.split(':')?.[1]}
                  onClick={() => handleColumnClick('datetime_created')}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell>Modified</TableCell>
              <TableCell>Versions</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: 'lightgray.light',
                      display: 'flex',
                      width: '60px',
                      height: '32px',
                      borderRadius: '6px',
                    }}
                  >
                    <Typography
                      fontSize="12px"
                      fontWeight={600}
                      lineHeight="12px"
                      m="auto"
                      color="darkgray.main"
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {getExtension(row.mime_type) || 'N/A'}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{getDataDisplay(row, 'name')}</TableCell>
                <TableCell>{getDataDisplay(row, 'category')}</TableCell>
                <TableCell>{getDataDisplay(row, 'datetime_created')}</TableCell>
                <TableCell>
                  {getDataDisplay(row, 'datetime_modified')}
                </TableCell>
                <TableCell>{getDataDisplay(row, 'versions')}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                      LinkComponent={Link}
                      to={`/correspondence-hub/library/${row.library_id}`}
                      state={{
                        breadcrumbs: [
                          {
                            display: 'Library',
                            href: '/correspondence-hub/library',
                          },
                          {
                            display: row.name,
                            href: `/correspondence-hub/library/${row.library_id}`,
                          },
                        ],
                      }}
                    >
                      Details
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  )
}

export default LibraryTable
