import React, { useState, useEffect, useMemo, useCallback } from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import DataList from 'components/common/DataList'
import DataListLayout from 'layouts/TabbedDataList'
import SearchField from 'components/common/SearchField'
import CCListModal from 'components/sftp_history/CCListModal'

import useDataList from 'hooks/useDataList'
import useSFTPHistory, { useSFTPUsers } from 'hooks/useSFTPHistory'
import useDataListContext from 'hooks/context/useDataListContext'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import trimString from 'helpers/node/trimString'
import { DATALIST_DIMENSIONS } from 'utils/constants'
import SchedulesModal from 'components/sftp_history/SchedulesModal'

const SFTP_TABS = {
  INCOMING: 0,
  OUTGOING: 1,
}

function SFTPHistory() {
  const { page, pageSize, setTotal, setTotalPages, setPageSize } =
    useDataListContext()

  const [filters, setFilters] = useState({})
  const [sortList, setSortList] = useState([])
  const [tabIndex, setTabIndex] = useState(SFTP_TABS.INCOMING)
  const [ccModalOpen, setCCModalOpen] = useState(false)
  const [schedulesModalOpen, setSchedulesModalOpen] = useState(false)

  const { sftpUsers } = useSFTPUsers()

  const sftpHookParams = useMemo(() => {
    const searchValue = trimString(filters.search)

    return {
      'filter[field]':
        searchValue && searchValue !== '' && filters.searchfields
          ? filters.searchfields
          : undefined,
      'filter[term]':
        searchValue && searchValue !== '' && filters.searchfields
          ? searchValue
          : undefined,
      'paging[page]': !searchValue ? page : undefined,
      'paging[page_size]': pageSize,
      directory: tabIndex === SFTP_TABS.INCOMING ? '/to_mpx/' : '/from_mpx/',
      ...sortList,
    }
  }, [filters, page, pageSize, sortList, tabIndex])

  const sftpHookUsername = useMemo(
    () => filters?.sftpuser ?? sftpUsers[0]?.username,
    [filters, sftpUsers]
  )

  const sftpUser = useMemo(() => {
    return sftpUsers.find((user) => user.username === sftpHookUsername)
  }, [sftpHookUsername, sftpUsers])

  const { records, total, totalPages, loading } = useSFTPHistory(
    sftpHookParams,
    sftpHookUsername
  )

  useEffect(() => {
    setTotal(total)
    setTotalPages(totalPages)
    setPageSize(pageSize, false)
  }, [total, totalPages, pageSize])

  const prepareRowData = (row) => {
    const rowData = {
      filename: row.filename,
      filepath: row.filepath,
      datetime_uploaded: row.datetime_uploaded,
      size_kb: row.size_kb.replace(',', '').replace('KiB', ' (kb)'),
      order_id: row.order_id,
    }

    if (tabIndex === SFTP_TABS.OUTGOING) delete rowData.order_id

    return rowData
  }

  const getColumnDisplay = useCallback((col) => {
    const editDisplay = (display) => {
      switch (display) {
        case 'filename':
          return 'File Name'

        case 'filepath':
          return 'File Path'

        case 'datetime_uploaded':
          return 'Date/Time Uploaded'

        case 'size_kb':
          return 'File Size (kb)'

        case 'order_id':
          return 'Order ID'

        default:
          return display
      }
    }

    return editDisplay(col)
  }, [])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: records,
    presetName: 'SFTPHistory',
    setSortList,
    prepareRowData,
    getColumnDisplay,
  })

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    const newFilters = { ...filters }

    if (name === 'search' && value) {
      newFilters['searchfields'] = 'filename|directory'
    }
    newFilters[name] = value

    setFilters(newFilters)
  }

  return (
    <>
      <CCListModal
        open={ccModalOpen}
        setOpen={setCCModalOpen}
        sftpUser={sftpUser}
      />

      <SchedulesModal
        open={schedulesModalOpen}
        setOpen={setSchedulesModalOpen}
        sftpUser={sftpUser}
      />

      <Box sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
        <Box
          sx={{
            bgcolor: 'white',
            height: '72px',
            position: 'absolute',
            overflow: 'hidden',
            width: '100%',
          }}
        />
        <Grid
          container
          sx={{
            height: '72px',
            width: '100%',
            minWidth: '1024px',
          }}
        >
          <Grid item md={6} lg={5}>
            <Tabs
              value={tabIndex}
              onChange={(_e, value) => setTabIndex(value)}
              aria-label="sftp tabs"
              sx={{ ml: 8, my: 6, minWidth: 160 * 2 }}
            >
              <Tab fullWidth label="INCOMING" disabled={loading} />
              <Tab fullWidth label="OUTGOING" disabled={loading} />
            </Tabs>
          </Grid>
        </Grid>
        <Box
          fullWidth
          sx={{
            height: '1px',
            bgcolor: 'darkblue.main',
            opacity: 0.12,
          }}
        />
      </Box>

      <Box sx={{ p: 6 }}>
        <Grid
          container
          columns={12}
          spacing={4}
          sx={{
            '& > *': { display: 'flex' },
            '& > * > *': { my: 'auto' },
          }}
        >
          <Grid item xs={2}>
            <TextField
              fullWidth
              select
              size="small"
              label="SFTP User"
              name="sftpuser"
              onChange={handleFilterChange}
              value={sftpHookUsername || ''}
            >
              {sftpUsers.map((user) => (
                <MenuItem key={user.username} value={user.username}>
                  {user.username}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <SearchField
              fullWidth
              size="small"
              label="Search"
              name="search"
              value={filters.search}
              onChange={handleFilterChange}
            />
          </Grid>
        </Grid>

        <Box sx={{ mb: 6, display: 'flex' }}>
          <Button
            onClick={() => setCCModalOpen(true)}
            sx={{ textDecoration: 'underline' }}
          >
            Email Recipients
          </Button>
          <Button
            sx={{ textDecoration: 'underline' }}
            onClick={() => setSchedulesModalOpen(true)}
          >
            Schedules
          </Button>
        </Box>

        <Card
          sx={{
            boxShadow: 2,
            mx: 0,
            width: {
              sm: '600px',
              md: '880px',
              lg: 'auto',
            },
          }}
        >
          {!total && !loading && (
            <Box sx={{ px: 4, pt: 2 }}>
              <Typography>Filter results empty</Typography>
            </Box>
          )}
          <DataList
            {...dataListProps}
            dataList={dataList}
            columns={columns}
            loading={loading}
            filters={filters}
            layoutType="sftphistory"
            expandable={false}
            height={DATALIST_DIMENSIONS.TABBED_HEIGHT}
          />
        </Card>
      </Box>
    </>
  )
}

function SFTPHistoryWrapper() {
  return (
    <DataListLayout>
      <Box sx={{ flex: 1 }}>
        <SFTPHistory />
      </Box>
    </DataListLayout>
  )
}

export default SFTPHistoryWrapper
