import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import MuiModal from '@mui/material/Popover'

import formatDateRange from 'helpers/datetime/formatDateRange'

import Calendar from 'components/common/Calendar'
import DateRangePickerButton from './Button'

import getFollowingMonth from 'helpers/datetime/getNextMonth'

function DateRangePickerModal({
  open,
  setOpen,
  setValue,
  months = 2,
  buttonConfig,
  anchor,
  value = [undefined, undefined],
  single = false,
}) {
  const [forcedView, setForcedView] = useState([])
  const [dateRange, setDateRange] = useState(value)
  const presetConfig = buttonConfig || [0, 1, 2, 3, 4]
  const footerContent = formatDateRange({ dateRange })

  useEffect(() => {
    if (Array.isArray(value)) setPresetRange(value)
    else setDateRange([undefined, undefined])
  }, [value])

  const setPresetRange = (preset) => {
    setDateRange(preset)

    if (preset[0]) {
      const firstMonth = {
        month: preset[0].getMonth(0),
        year: preset[0].getFullYear(),
      }

      const secondMonth = getFollowingMonth({ baseDate: preset[0], inc: 1 })

      setForcedView([firstMonth, secondMonth])
    }
  }

  return (
    <MuiModal
      id="date-range-picker-modal"
      open={open}
      anchorEl={anchor}
      BackdropProps={{ invisible: false }}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        },
      }}
    >
      <Card
        sx={{
          height: '302px',
          width: '552px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '80%',
          }}
        >
          <Stack
            sx={{
              height: '100%',
              width: '20%',
              borderRight: '1px solid',
              borderColor: 'background.main',
            }}
          >
            {presetConfig.map((presetIndex) => (
              <Box sx={{ flex: 1 }} key={`preset-${presetIndex}`}>
                <DateRangePickerButton
                  setDateRange={setPresetRange}
                  dateRange={dateRange}
                  presetIndex={presetIndex}
                />
              </Box>
            ))}
          </Stack>
          <Box
            sx={{
              width: '80%',
              height: '100%',
              borderBottom: '1px solid',
              borderColor: 'background.main',
            }}
          >
            <Calendar
              dateRange={dateRange}
              setDateRange={setDateRange}
              months={months}
              forcedView={forcedView}
              single={single}
            />
          </Box>
        </Box>
        <Box
          sx={{
            height: '20%',
            width: '100%',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              borderRight: '1px solid',
              borderColor: 'background.main',
              width: '20%',
              height: '100%',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              sx={{ margin: 'auto' }}
              size="large"
              onClick={() => {
                setValue(dateRange)
                setOpen(false)
              }}
            >
              Apply
            </Button>
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '60%',
              display: 'flex',
            }}
          >
            <Typography
              variant="h2"
              color="darkgray.main"
              sx={{ margin: 'auto', fontWeight: 'medium', fontSize: '1rem' }}
            >
              {footerContent}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              borderLeft: '1px solid',
              borderColor: 'background.main',
              width: '20%',
              height: '100%',
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              sx={{ margin: 'auto' }}
              size="large"
              onClick={() => {
                setDateRange([undefined, undefined])
              }}
            >
              Clear
            </Button>
          </Box>
        </Box>
      </Card>
    </MuiModal>
  )
}

DateRangePickerModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setValue: PropTypes.func,
  months: PropTypes.number,
  buttonConfig: PropTypes.arrayOf(PropTypes.number),
}

export default DateRangePickerModal
