import getDateRangePreset from 'helpers/datetime/getDateRangePreset'
import getLastDayOfMonth from 'helpers/datetime/getLastDayOfMonth'

export const DATE_RANGE_PRESETS = {
  TODAY: 0,
  YESTERDAY: 1,
  LAST_7_DAYS: 2,
  LAST_30_DAYS: 3,
  LAST_MONTH: 4,
  CURRENT_DAY: 5,
  LAST_90_DAYS: 6,
}

export const dateRangePresets = {
  [DATE_RANGE_PRESETS.TODAY]: {
    label: 'Today',
    preset: getDateRangePreset({}),
  },
  [DATE_RANGE_PRESETS.YESTERDAY]: {
    label: 'Yesterday',
    preset: getDateRangePreset({ daysBefore: 1 }),
  },
  [DATE_RANGE_PRESETS.LAST_7_DAYS]: {
    label: 'Last 7 Days',
    preset: getDateRangePreset({ daysBefore: 7, daysAfter: 0 }),
  },
  [DATE_RANGE_PRESETS.LAST_30_DAYS]: {
    label: 'Last 30 Days',
    preset: getDateRangePreset({ daysBefore: 30, daysAfter: 0 }),
  },
  [DATE_RANGE_PRESETS.LAST_MONTH]: {
    label: 'Last Month',
    preset: getDateRangePreset({
      daysBefore: getLastDayOfMonth(new Date().getMonth() - 1).getDate() - 1,
      daysAfter: 0,
      baseDate: getLastDayOfMonth(new Date().getMonth() - 1),
    }),
  },
  [DATE_RANGE_PRESETS.CURRENT_DAY]: {
    label: 'Current Day',
    preset: getDateRangePreset({}),
  },
  [DATE_RANGE_PRESETS.LAST_90_DAYS]: {
    label: 'Last 90 Days',
    preset: getDateRangePreset({ daysBefore: 90, daysAfter: 0 }),
  },
}

export default Object.values(dateRangePresets)
