import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import isSameDay from 'date-fns/isSameDay'

const isDateBetween = ({ date, start, end }) => {
  if (!start || !end || !date) return false

  if (isSameDay(date, start) || isSameDay(date, end)) return false

  return isBefore(date, end) && isAfter(date, start)
}

export default isDateBetween
