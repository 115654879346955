import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import fetcher from 'utils/fetcher'

const usePostageInvoiceHistory = (params) => {
  const path = [
    '/postage-invoices',
    params &&
      urlSerialize({
        ...params,
      }),
  ].filter((p) => p)

  const { data, isValidating, mutate } = useSWR(path.join('?'), fetcher, {
    revalidateOnFocus: false,
  })

  return {
    invoices: data?._embedded?.invoices || [],
    loading: (!data?._embedded?.invoices && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
  }
}

export default usePostageInvoiceHistory
