import React, { useState, useEffect, useMemo, useCallback } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'

import PlusIcon from '@mui/icons-material/Add'

import SearchField from 'components/common/SearchField'
import DataListLayout from 'layouts/DataList'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'

import DataListTable from 'components/common/DataList/Table'
import AgencyPreferencesRow from 'components/common/DataList/Item/AgencyPreferences/MMG'
import useAgencyPreferences from 'hooks/agency_preferences/useAgencyPreferences'
import MMGEditAgencyModal from 'components/agency_preferences/MMG/EditAgencyModal'
import MMGEditPreferencesModal from 'components/agency_preferences/MMG/EditPreferencesModal'
import MMGActivityModal from 'components/agency_preferences/MMG/ActivityModal'

function SearchTextField({ setFilters, lastSearch }) {
  const [localValue, setLocalValue] = useState(lastSearch)

  const handleChange = (val) => {
    const searchValue = val ?? localValue

    setFilters({ search: searchValue })
  }

  return (
    <SearchField
      fullWidth
      size="small"
      label="Search"
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleChange()
        }
      }}
      onSearch={handleChange}
    />
  )
}

function MMGAgencyPreferecesPage() {
  const { setTotal, setTotalPages, page, setPage, pageSize } =
    useDataListContext()

  const [filters, setFilters] = useState({})
  const [sortList, setSortList] = useState([])

  const hookParams = useMemo(() => {
    let params = {
      'paging[page]': page,
      'paging[page_size]': pageSize,
    }

    if (filters.search) {
      params['filter[field]'] = 'name|agency_code'
      params['filter[term]'] = filters.search
    }

    return params
  }, [filters, page, pageSize])

  const {
    agencyPreferences,
    total,
    totalPages,
    loading,
    addAgency,
    updateAgencyPreferences,
  } = useAgencyPreferences({
    params: hookParams,
    clientPaginated: false,
  })

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages)
    }
  }, [total])

  const {
    dataList,
    columns,
    dataListProps,
    loading: dataLoading,
  } = useDataList({
    baseRowData: agencyPreferences,
    presetName: 'agencyMailing',
    setSortList,
  })

  const [activeAgency, setActiveAgency] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showPreferencesModal, setShowPreferencesModal] = useState(false)
  const [showActivityModal, setShowActivityModal] = useState(false)

  const openAgencyModal = useCallback((agency, action = 'edit') => {
    setActiveAgency(agency)

    if (action === 'edit') {
      setShowEditModal(true)
    } else if (action === 'preferences') {
      setShowPreferencesModal(true)
    } else if (action === 'activity') {
      setShowActivityModal(true)
    }
  }, [])

  const handleAddAgency = useCallback(
    async ({ values }) => {
      const res = await addAgency({ values })
      setActiveAgency(res.data)
      setShowPreferencesModal(true)
    },
    [addAgency]
  )

  useEffect(() => {
    if (page !== 1) {
      setPage(1)
    }
  }, [filters])

  const renderTable = useMemo(() => {
    return (
      <DataListTable
        {...dataListProps}
        rowKey="agency_code"
        dataList={dataList}
        columns={columns}
        loading={loading || dataLoading}
        filters={filters}
        sortList={sortList}
        setSortList={setSortList}
        RowComponent={AgencyPreferencesRow}
        rowProps={{
          openAgencyModal: openAgencyModal,
        }}
        total={total}
        visibleColumns={[
          'agency_code',
          'name',
          'city',
          'state',
          'updated_by',
          'datetime_modified',
        ]}
        extraColumns={
          <>
            <TableCell sx={{ backgroundColor: 'white' }}></TableCell>
            <TableCell sx={{ backgroundColor: 'white' }}></TableCell>
          </>
        }
      />
    )
  }, [
    dataListProps,
    dataList,
    columns,
    loading,
    dataLoading,
    filters,
    sortList,
    openAgencyModal,
    total,
  ])

  return (
    <>
      <MMGEditAgencyModal
        open={showEditModal}
        setOpen={setShowEditModal}
        agency={activeAgency}
        updateAgencyPreferences={updateAgencyPreferences}
        addAgency={handleAddAgency}
      />
      <MMGEditPreferencesModal
        open={showPreferencesModal}
        setOpen={setShowPreferencesModal}
        agency={activeAgency}
        updateAgencyPreferences={updateAgencyPreferences}
      />
      <MMGActivityModal
        open={showActivityModal}
        setOpen={setShowActivityModal}
        agency={activeAgency}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 6, display: 'flex', height: '40px' }}>
          <Box>
            <Button
              startIcon={<PlusIcon />}
              variant="contained"
              color="primary"
              sx={{ mr: 4, whiteSpace: 'nowrap', height: '40px' }}
              onClick={() => openAgencyModal(null)}
            >
              Create New Agency
            </Button>
          </Box>
          <SearchTextField
            setFilters={setFilters}
            lastSearch={filters.search || ''}
          />
        </Box>

        <Card sx={{ boxShadow: 2, width: '100%' }}>{renderTable}</Card>
      </Box>
    </>
  )
}

function MMGAgencyPreferecesPageWrapper() {
  return (
    <DataListLayout>
      <MMGAgencyPreferecesPage />
    </DataListLayout>
  )
}

export default MMGAgencyPreferecesPageWrapper
