import { useState, useCallback, useEffect } from 'react'

import axios from 'utils/axios'
import { STANDALONE_DELIVERY_TYPE_NAMES } from 'utils/constants'

const useFixedTemplateCategories = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchCategories = useCallback(async () => {
    setLoading(true)

    try {
      const res = await axios.get(
        '/collateral-template-categories?embed=templates|order_data_defaults'
      )
      setData(res.data)
    } finally {
      setLoading(false)
    }
  }, [])

  const filterCategories = useCallback((categories = []) => {
    if (Array.isArray(categories) && categories.length > 0) {
      const filteredCategories = []

      categories.forEach((category) => {
        const templates = category?._embedded?.templates || []
        const filteredTemplates = templates.filter((template) => {
          // filter out standalone collateral
          return !Object.values(STANDALONE_DELIVERY_TYPE_NAMES).includes(
            template.id
          )
        })

        if (filteredTemplates.length !== 0) {
          filteredCategories.push({
            ...category,
            _embedded: {
              ...category._embedded,
              templaes: filteredTemplates,
            },
          })
        }
      })

      return filteredCategories
    }

    return []
  }, [])

  useEffect(() => {
    if (!data) {
      fetchCategories()
    }
  }, [])

  return {
    categories: filterCategories(data?._embedded?.categories ?? []),
    loading,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    fetchCategories,
  }
}

export default useFixedTemplateCategories
