import React, { useState, useEffect } from 'react'

import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import RemovableItems from 'components/common/RemovableItems'

function DeactivateModal({
  open,
  onClose,
  selectedItems,
  itemName,
  handleDeactivate,
}) {
  const [checkedItems, setCheckedItems] = useState(selectedItems || [])
  const title = 'Selected documents'

  const itemDisplay =
    checkedItems.length === 1 ? itemName.singular : itemName.plural

  const handleRemove = (index) => {
    const newCheckedItems = [...checkedItems]

    newCheckedItems.splice(index, 1)

    if (newCheckedItems.length === 0) {
      onClose()
      setCheckedItems(selectedItems)
      return
    }

    setCheckedItems(newCheckedItems)
  }

  const footerButtonProps = [
    {
      children: 'Deactivate',
      color: 'primary',
      variant: 'contained',
      onClick: () => handleDeactivate(checkedItems),
    },
    {
      children: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      onClick: () => onClose(),
    },
  ]

  useEffect(() => {
    setCheckedItems(
      selectedItems?.map((item) => ({
        ...item,
        display: 'DOCUMENT',
      })) || []
    )
  }, [selectedItems])

  return (
    <Modal
      title="Deactivate Documents"
      size="lg"
      open={open}
      onClose={onClose}
      footerButtonProps={footerButtonProps}
    >
      <RemovableItems
        items={checkedItems}
        handleRemove={handleRemove}
        title={
          checkedItems.length > 5 ? `${checkedItems.length} ${title}` : title
        }
        max={5}
      />
      <Typography color="darkgray.main" mt={6}>
        You are about to deactivate {checkedItems.length} {itemDisplay}.
      </Typography>
      <Typography color="darkgray.main">
        When you deactivate a document, users will no longer be able to find or
        view the document in a search. Please confirm this action and click
        Deactivate below, or click Cancel to go back to the previous screen.
      </Typography>
    </Modal>
  )
}

export default DeactivateModal
