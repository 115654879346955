import saveData from 'helpers/browser/saveData'
import useSWR from 'swr'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

const usePostageInvoice = ({ invoiceNumber }) => {
  const { data, isValidating, mutate } = useSWR(
    invoiceNumber ? `/postage-invoices/${invoiceNumber}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const getInvoicePdf = async (invoiceNum = invoiceNumber) => {
    const res = await axios.get(`/postage-invoices/${invoiceNum}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    })
    return res.data
  }

  const downloadInvoice = async (invoiceNum = invoiceNumber) => {
    const pdf = await getInvoicePdf(invoiceNum)
    saveData(
      pdf,
      `Postage_Invoice_${invoiceNum}-${Date.now()}.pdf`,
      'application/pdf'
    )
  }

  return {
    invoice: data,
    loading: (!data && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
    getInvoicePdf,
    downloadInvoice,
  }
}

export default usePostageInvoice
