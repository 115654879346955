import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedList from 'components/common/ExpandedList'
import OrderApprovalExpandedList from 'components/common/ExpandedList/OrderApproval'

// local components
import ItemCheckBox from './components/ItemCheckbox'
import HoverButtons from './components/HoverButtons'

import useDataListContext from 'hooks/context/useDataListContext'
import useConfiguration from 'hooks/useConfiguration'

import { COLORS, PRIVILEGES } from 'utils/constants'
import DataListItemWrapper from './components/DataListItemWrapper'

const StyledDataListItem = styled(Box)`
  .hover-buttons {
    display: none;
  }

  &:hover {
    .hover-buttons {
      ${({ showButtons }) => showButtons && 'display: block;'}
    }
  }
`

function OrderApprovalItem(props) {
  const {
    index,
    rowData,
    columns,
    getDataDisplay,
    multiSelect,
    statusColName,
    expandedListProps,
    visibleColumns,
    sharedSx,
    actionButtonProps,
  } = props

  const { hasPrivilege } = useConfiguration()
  const [expanded, setExpanded] = useState(false)
  const [checked, setChecked] = useState(false)
  const [status, setStatus] = useState({ color: COLORS.GREEN })

  const filteredHoverButtonProps = useMemo(() => {
    return actionButtonProps.filter((buttonProps) => {
      if (buttonProps.privId === 'hold') {
        return hasPrivilege(PRIVILEGES.HOLD_ORDER, null, {
          program: rowData?.program_id,
        })
      } else if (buttonProps.privId === 'approve') {
        return hasPrivilege(PRIVILEGES.APPROVE_ORDER, null, {
          program: rowData?.program_id,
        })
      }

      return false
    })
  }, [actionButtonProps, hasPrivilege])

  const { allChecked, checkedCount } = useDataListContext()

  const darken = expanded || checked || allChecked

  const shownColumns = visibleColumns
    .map((col) => columns.find((c) => c.id === col))
    .filter((c) => c)

  const { color, success } = status ?? { color: 'green.main' }
  const checkboxColor = success ? 'success' : 'warning'

  const getBorderColor = () => {
    if (darken) return color

    return 'background.paper'
  }

  useEffect(() => {
    if (rowData?.[statusColName]) {
      setStatus(rowData[statusColName])
    }
  }, [rowData])

  useEffect(() => {
    if (allChecked === true) setChecked(true)
    if (checkedCount === 0) setChecked(false)
  }, [allChecked, checkedCount])

  return (
    <StyledDataListItem
      showButtons={!expanded}
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        borderBottomColor: 'lightgray.main',
        bgcolor: darken && 'hover.main',
        color: 'darkgray.main',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          borderLeftColor: !darken && 'hover.main',
          bgcolor: !darken && 'hover.main',
        },
      }}
    >
      <ListItem
        secondaryAction={
          <>
            <IconButton
              edge="end"
              aria-label="expand"
              onClick={(e) => {
                e.stopPropagation()
                setExpanded(!expanded)
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </>
        }
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: 'flex',
          my: 'auto',
          width: '100%',
          '&:hover': {
            borderColor: expanded && 'hover.main',
            bgcolor: expanded && 'hover.main',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {multiSelect && (
            <ItemCheckBox
              index={index}
              handleItemCheck={setChecked}
              color={checkboxColor}
            />
          )}
          {shownColumns.map((col, colIndex) => (
            <DataListItemWrapper key={col.id} index={colIndex}>
              <Box
                sx={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  flexDirection: 'column',
                  color: col.id === statusColName && color,
                }}
              >
                <Box
                  sx={{
                    my: 'auto',
                    fontSize: 14,
                  }}
                >
                  {getDataDisplay(rowData, col.id, statusColName)}
                </Box>
              </Box>
            </DataListItemWrapper>
          ))}
          <HoverButtons
            {...props}
            actionButtonProps={filteredHoverButtonProps}
          />
        </Box>
      </ListItem>
      <ExpandedList
        {...expandedListProps}
        expanded={expanded}
        columns={columns}
        rowData={rowData}
        visibleColumns={visibleColumns}
        getDataDisplay={getDataDisplay}
        ExpandedListComponent={OrderApprovalExpandedList}
        hoverButtons={
          <HoverButtons
            expanded
            {...props}
            actionButtonProps={filteredHoverButtonProps}
          />
        }
      />
    </StyledDataListItem>
  )
}

export default OrderApprovalItem
