import removeNullKeys from 'helpers/node/removeNullKeys'

const urlSerialize = (paramObj, allowNull = false) => {
  let obj = paramObj

  if (!allowNull) {
    obj = removeNullKeys(paramObj)
  }

  return new URLSearchParams(obj).toString()
}

export default urlSerialize
