import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ItemContainer from './containers/ItemContainer'
import SearchField from 'components/common/SearchField'

import useOrders from 'hooks/useOrders'
import { WIDGET_KEYS } from 'utils/constants'

function OrderHistoryItemSearch() {
  const { getOrder } = useOrders()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleSearch = async () => {
    if (!search || error) return
    setLoading(true)

    // validate before redirect
    try {
      const order = await getOrder(search)

      if (!order) {
        setError('Invalid order id')
      }
    } catch (err) {
      setError('Invalid order id')
      return
    } finally {
      setLoading(false)
    }

    navigate(`/command-center/order-details/${search}`, {
      state: {
        from: {
          display: 'Dashboard',
          href: '/main/dashboard',
        },
      },
    })
  }

  return (
    <ItemContainer
      title="Order History"
      href="/command-center/wip"
      widgetKey={WIDGET_KEYS.ORDER_HISTORY_QUICKSEARCH}
      loading={false}
    >
      <Box
        sx={{
          height: '216px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack spacing={3}>
          <Typography fontWeight={700} fontSize="10px">
            Quick Search
          </Typography>
          <SearchField
            hideClear
            fullWidth
            label="Order ID"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)

              if (error) {
                setError('')
              }
            }}
            onSearch={() => handleSearch()}
            helperText={error}
            error={Boolean(error)}
            disabled={loading}
          />
          <Box>
            <LoadingButton
              variant="contained"
              color="primary"
              size="action-header"
              sx={{ float: 'right' }}
              onClick={handleSearch}
              disabled={Boolean(error)}
              loading={loading}
            >
              Search
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
    </ItemContainer>
  )
}

export default OrderHistoryItemSearch
