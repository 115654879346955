import React from 'react'

import UserDataList from 'components/user_maintenance/UserDataList'

import TabbedDataListLayout from 'layouts/TabbedDataList'

function UserMaintenancePage() {
  return (
    <TabbedDataListLayout>
      <UserDataList />
    </TabbedDataListLayout>
  )
}

export default UserMaintenancePage
