import { useMemo } from 'react'

import {
  WIDGET_KEYS,
  WIDGET_KEY_DISPLAYS,
  MODULE_NAMES,
  INSTANCE_CONFIGIRATIONS,
} from 'utils/constants'

import useConfiguration from 'hooks/useConfiguration'

const useWidgets = () => {
  const { getModuleFromName, instanceConfigurations } = useConfiguration()

  const widgets = useMemo(() => {
    const activeWidgets =
      instanceConfigurations?.[INSTANCE_CONFIGIRATIONS.DASHBOARD_ACTIVE_WIDGETS]

    if (!activeWidgets) return []

    const showDocApproval =
      getModuleFromName(MODULE_NAMES.DOCUMENT_APPROVAL)?.hidden === false
    const showOrderApproval =
      getModuleFromName(MODULE_NAMES.ORDER_APPROVAL)?.hidden === false
    const showOrderHistory =
      getModuleFromName(MODULE_NAMES.ORDER_HISTORY)?.hidden === false
    const showCollateral =
      getModuleFromName(MODULE_NAMES.COLLATERAL)?.hidden === false
    const showDigitalMailbox =
      getModuleFromName(MODULE_NAMES.DIGITAL_MAILBOX)?.hidden === false
    const showCorrespondence =
      getModuleFromName(MODULE_NAMES.CORRESPONDENCE)?.hidden === false
    const showSFTP =
      getModuleFromName(MODULE_NAMES.SFTP_HISTORY)?.hidden === false

    return [
      showDocApproval && {
        key: WIDGET_KEYS.DOCUMENT_APPROVAL_DONUT,
        priority: 2,
      },
      showOrderApproval && {
        key: WIDGET_KEYS.ORDER_APPROVAL_DONUT,
        priority: 1,
      },
      showOrderApproval && {
        key: WIDGET_KEYS.ORDER_APPROVAL_LIST,
        priority: 1,
      },
      showOrderHistory && {
        key: WIDGET_KEYS.ORDER_HISTORY_CHART,
        priority: 1,
      },
      showOrderHistory && {
        key: WIDGET_KEYS.ORDER_HISTORY_QUICKSEARCH,
        priority: 0,
      },
      showCollateral && {
        key: WIDGET_KEYS.COLLATERAL_BARS,
        priority: 1,
      },
      showCollateral && {
        key: WIDGET_KEYS.COLLATERAL_DONUT,
        priority: 1,
      },
      showDigitalMailbox && {
        key: WIDGET_KEYS.DIGITAL_MAILBOX_DONUT,
        priority: 1,
      },
      showCorrespondence && {
        key: WIDGET_KEYS.CORRESPONDENCE_DONUT,
        priority: 2,
      },
      showCorrespondence && {
        key: WIDGET_KEYS.CORRESPONDENCE_DUAL_DONUT,
        priority: 2,
      },
      showCorrespondence && {
        key: WIDGET_KEYS.CORRESPONDENCE_QUICKSEARCH,
        priority: 0,
      },
      showCorrespondence && {
        key: WIDGET_KEYS.CORRESPONDENCE_SAVED_SEARCH,
        priority: 0,
      },
      showDigitalMailbox && {
        key: WIDGET_KEYS.DIGITAL_MAILBOX_ASSIGNMENTS,
        priority: 1,
      },
      showSFTP && {
        key: WIDGET_KEYS.SFTP_STATS_CHART,
        priority: 2,
      },
    ]
      .filter((w) => w)
      .filter((w) => activeWidgets[w.key])
      .map((widget) => ({
        ...widget,
        display: WIDGET_KEY_DISPLAYS[widget.key],
      }))
  }, [getModuleFromName, instanceConfigurations])

  return {
    widgets,
  }
}

export default useWidgets
