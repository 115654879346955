import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import deepEqual from 'deep-equal'
import get from 'lodash.get'
import cloneDeep from 'lodash/cloneDeep'

import axios from 'utils/axios'
import {
  SSO_KEY,
  APP_KEY,
  MODULE_NAMES,
  INSTANCE_CONFIGIRATIONS,
} from 'utils/constants'

import useAuth from 'hooks/useAuth'
import useInstanceConfiguration from 'hooks/useInstanceConfigurations'
import useDocumentMimeTypes from 'hooks/documents/useDocumentMimeTypes'

import fragmentPath from 'helpers/browser/fragmentPath'
import getUrlName from 'helpers/browser/getUrlName'
import getBreadcrumbDisplay from 'helpers/browser/getBreadcrumbDisplay'
import mergeDeep from 'helpers/node/mergeDeep'
import isEmptyObject from 'helpers/node/isEmptyObject'

const INITIALIZE = 'INITIALIZE'
const UPDATE_PATH = 'UPDATE_PATH'
const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS'
const SET_PRIVILEGES = 'SET_PRIVILEGES'
const CLEAR = 'CLEAR'
const SET_INSTANCE_CONFIG = 'SET_INSTANCE_CONFIG'
const UPDATE_AUTOSET_BREADCRUMBS = 'UPDATE_AUTOSET_BREADCRUMBS'

const initialState = {
  isInitialized: false,
  isSSO: false,
  is2FA: false,
  navigation: {
    modules: [],
    currentPath: [],
  },
  searchParams: null,
  permissions: {},
  privileges: {},
  instanceConfigurations: {},
  restrictions: {},
  autosetBreadcrumbs: true,
}

const ConfigurationReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        isInitialized: true,
        navigation: {
          ...state.navigation,
          modules: action.payload.modules,
        },
      }
    case UPDATE_PATH:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          currentPath: [...action.payload.path],
        },
      }
    case UPDATE_AUTOSET_BREADCRUMBS:
      return {
        ...state,
        autosetBreadcrumbs: Boolean(action.payload.autoset),
      }
    case UPDATE_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload.searchParams,
        },
      }
    case SET_PRIVILEGES:
      return {
        ...state,
        permissions: action.payload.permissions,
        privileges: action.payload.privileges,
        restrictions: action.payload.restrictions,
      }
    case CLEAR:
      return {
        ...initialState,
      }
    case SET_INSTANCE_CONFIG: {
      return {
        ...state,
        instanceConfigurations: action.payload.instanceConfigurations,
        isSSO: action.payload.instanceConfigurations?.[SSO_KEY] === 'SSO',
        is2FA: action.payload.instanceConfigurations?.[SSO_KEY] === '2FA',
      }
    }
    default:
      return state
  }
}
const ConfigurationContext = createContext(null)

function ConfigurationProvider({ children }) {
  const [state, dispatch] = useReducer(ConfigurationReducer, initialState)
  const location = useLocation()
  const navigate = useNavigate()
  const { user, isAuthenticated, logOut } = useAuth()
  const { instanceConfigurations, isFetched } = useInstanceConfiguration({
    instanceName: user.instanceId,
  })
  const { mimeTypes: documentMimeTypes, loading: mimeTypesLoading } =
    useDocumentMimeTypes({
      disabled: !isAuthenticated,
      params: {
        is_library: '1',
      },
    })

  const currentModule = useMemo(() => {
    const path = location.pathname
    const parentModule = state.navigation.modules.find((mod) =>
      path.includes(mod.href)
    )

    if (parentModule) {
      const foundModule = parentModule.modules.find((mod) =>
        path.includes(mod.href)
      )

      return foundModule
    }

    return null
  }, [location, state.navigation])

  const currentModuleId = useMemo(() => currentModule?.id || 0, [currentModule])

  const getUserAuthorizedModules = async () => {
    const excludedModules = []

    const response = await axios.get('/modules', {
      params: {
        app_key: APP_KEY,
        embed: '*',
      },
    })

    const userResponse = await axios.get(`/users/${user.userId}`, {
      params: {
        embed: '*',
        app_key: APP_KEY,
      },
    })

    const userModules = userResponse.data._embedded.accessible_modules.map(
      (m) => m.id
    )

    const canAccessModule = (m) => {
      // if user has access to module, return true
      if (userModules.includes(m.id)) {
        return true
      }

      if (m.id === 'bookmark') return true

      // check if any of the module's children are accessible
      const children = m._embedded.modules
      if (children.length > 0) {
        return children.some((child) => canAccessModule(child))
      }
    }

    const abstractModules = (modules, rootPath = '') => {
      return modules
        ?.sort((a, b) => a.priority - b.priority)
        .map((module) => {
          const urlName = getUrlName(module.name)
          const href = module.href ?? `${rootPath}/${urlName}`

          if (excludedModules.includes(module.name)) return null

          return {
            id: module.id,
            name: module.name,
            urlName,
            display: module.display,
            href,
            modules: abstractModules(module._embedded.modules, href),
            configurations: module._embedded.configurations,
            hidden: !canAccessModule(module),
          }
        })
        .filter((m) => m)
    }

    if (response.status === 200) {
      const modules = abstractModules(response.data._embedded.modules)

      return { modules }
    } else {
      throw new Error('Unauthorized user')
    }
  }

  const updatePath = useCallback((newPath) => {
    dispatch({
      type: UPDATE_PATH,
      payload: { path: newPath },
    })
    dispatch({
      type: UPDATE_AUTOSET_BREADCRUMBS,
      payload: { autoset: false },
    })
  }, [])

  const setAutosetBreadcrumbs = useCallback((autoset) => {
    dispatch({
      type: UPDATE_AUTOSET_BREADCRUMBS,
      payload: { autoset },
    })
  })

  const setUserPrivileges = useCallback(async (userId) => {
    // get user and their privileges
    const newPrivileges = {}
    const newRestrictions = {}

    const res = await axios.get(`/users/${userId}`, {
      params: {
        embed: '*',
        app_key: APP_KEY,
      },
    })

    const userRoles = res.data._embedded?.user_roles

    // each role has a set of privileges
    // set privileges to the union of all privileges
    try {
      userRoles.forEach((userRole) => {
        const setNewPrivilege = (p) => {
          const key = `${p._embedded.module.id}|${p.action}|${p.target}`
          const newPriv = userRole._embedded.role_restrictions ?? {}

          if (newPrivileges[key]) {
            newPrivileges[key] = mergeDeep(newPrivileges[key], newPriv)
          } else {
            newPrivileges[key] = newPriv
          }
        }

        userRole._embedded.privilege_sets.forEach((privSet) => {
          if (privSet.id === null) {
            privSet._embedded.privileges.forEach((priv) => {
              setNewPrivilege(priv)
            })
          } else {
            privSet._embedded.privileges.forEach(setNewPrivilege)
          }
        })

        // handle restrictions
        Object.keys(userRole._embedded.role_restrictions || {}).forEach(
          (rr) => {
            if (!newRestrictions[rr]) {
              newRestrictions[rr] = userRole._embedded.role_restrictions[rr]
            } else {
              // concat new restrictions, remove duplicates
              newRestrictions[rr] = [
                ...new Set([
                  ...(newRestrictions[rr] || []),
                  ...(userRole._embedded.role_restrictions[rr] || []),
                ]),
              ]
            }
          }
        )
      })
    } catch (err) {}

    dispatch({
      type: SET_PRIVILEGES,
      payload: {
        privileges: newPrivileges,
        restrictions: newRestrictions,
        permissions: res.data._embedded?.permissions,
      },
    })
  }, [])

  const getModuleFromName = useCallback(
    (moduleName) => {
      const modules = state.navigation.modules.flatMap((m) => m.modules)
      return modules.find((module) => `${module.name}` === `${moduleName}`)
    },
    [state.navigation]
  )

  const getModule = useCallback(
    (moduleId) => {
      const modules = state.navigation.modules.flatMap((m) => m.modules)
      return modules.find((module) => `${module.id}` === `${moduleId}`)
    },
    [state.navigation]
  )

  const hasPrivilege = useCallback(
    (privilegeId, moduleId = null, data = {}) => {
      if (!state.permissions?.matrix) return false

      // need to know which module is being referenced
      let privilegeModuleId = moduleId
      let moduleName = ''

      if (!privilegeModuleId) {
        privilegeModuleId = currentModuleId
        moduleName = getModule(privilegeModuleId)?.name
      } else if (isNaN(+privilegeModuleId)) {
        moduleName = privilegeModuleId
      }

      if (!moduleName) {
        return false
      }

      // construct property path for lodash get to access privilege obj
      const propertyPath = `${moduleName}.${privilegeId}`
      const privilege = get(state.permissions.matrix, propertyPath)

      if (!privilege) {
        return false
      }

      let hasAccess = Boolean(privilege.has_access)

      // no need to check restrictions if this is false
      if (!hasAccess) return false

      // determine access based on restrictions
      const restrictedBy = privilege.restricted_by
      const restrictedTo = privilege.restricted_to || {}

      if (
        restrictedBy &&
        Object.values(restrictedTo).length > 0 &&
        !isEmptyObject(data)
      ) {
        const key = data[restrictedBy]

        if (key) {
          return Boolean(restrictedTo[key])
        } else {
          return false
        }
      }

      return hasAccess
    },
    [getModule, currentModuleId, state.permissions]
  )

  const canAccessModule = useCallback(
    (value, key = 'href') => {
      const moduleValues = state.navigation.modules
        .flatMap((m) => m.modules)
        .filter((m) => !m.hidden)
        .map((m) => m[key])

      return moduleValues.reduce(
        (acc, curr) => acc || value.includes(curr),
        false
      )
    },
    [state.navigation]
  )

  const getFieldDefinitions = useCallback(
    (moduleName = MODULE_NAMES.CORRESPONDENCE) => {
      const m = getModuleFromName(moduleName)

      if (m?.configurations) {
        const configurations = m.configurations
        const fieldDefinitions = cloneDeep(
          configurations.find(
            (config) => config.configuration_type === 'field_definitions'
          )?.fieldsets?.[0]?.fields || {}
        )

        return fieldDefinitions
      }

      return {}
    },
    [getModuleFromName]
  )

  const fieldDefinitions = useMemo(getFieldDefinitions, [getModuleFromName])

  const getMetadataDisplay = useCallback(
    (metaKey, nullable = false, moduleName = MODULE_NAMES.CORRESPONDENCE) => {
      const metadataColumnNames =
        instanceConfigurations?.[INSTANCE_CONFIGIRATIONS.METADATA_COLUMN_NAMES]

      if (metadataColumnNames && metadataColumnNames[metaKey]) {
        return metadataColumnNames[metaKey].toUpperCase()
      }

      const _fieldDefinitions = getFieldDefinitions(moduleName)

      let metaDisplay = _fieldDefinitions[metaKey]?.display

      // also check for key with _.metadata. prefix removed
      if (!metaDisplay) {
        metaDisplay = _fieldDefinitions[`_.metadata.${metaKey}`]?.display
      }

      if (metaDisplay) return metaDisplay?.toUpperCase()
      else if (nullable) return null

      return metaKey?.replace('_.metadata.', '')
    },
    [getFieldDefinitions, instanceConfigurations]
  )

  const getEnvInfo = useCallback(() => {
    // pulls env info from first subdomain. don't care for difference of mismatch vs prod
    return {
      apiUrl: process.env.REACT_APP_BASE_URL,
      env:
        (process.env.REACT_APP_BASE_URL ?? '').match(
          /^(?:http[s]?:\/\/)?(?:www\.)?(?:([^.]+(?:\.[^.]+)*)\.)?(?:mpxpp\.com)/
        )?.[1] ?? '',
    }
  }, [])

  // set modules on user login
  useEffect(() => {
    const asyncSetModules = async () => {
      // only update modules only if they are not set
      if (!user.instanceId || state.navigation.modules.length > 0) {
        return
      }

      try {
        const { modules } = await getUserAuthorizedModules()

        dispatch({
          type: INITIALIZE,
          payload: {
            modules,
          },
        })
      } catch (error) {
        logOut(false)
      }
    }

    const asyncSetPrivileges = async () => {
      if (!user.instanceId || Object.keys(state.privileges).length > 0) {
        return
      }

      const { UserID } = jwtDecode(user.accessToken)

      await setUserPrivileges(UserID)
    }

    if (isAuthenticated) {
      asyncSetModules()
      asyncSetPrivileges()
    } else dispatch({ type: CLEAR })
  }, [user])

  // update path for breadcrumbs whenever url changes
  useEffect(() => {
    const {
      navigation: { modules },
    } = state

    const asyncFindCurrentPath = async () => {
      let found = false

      if (location.state?.breadcrumbs) {
        updatePath(location.state.breadcrumbs)
        return
      }

      // set breadcrumbs based on nav modules
      await Promise.all(
        modules.map(async (module) => {
          if (location.pathname === module.href) {
            navigate(module.modules[0].href, { replace: true })
          }

          const foundModule = module.modules.find(
            (m) => location.pathname === m.href
          )

          if (foundModule) {
            found = true

            updatePath([foundModule])
          }
        })
      )

      // set breadcrumbs based on url
      if (!found) {
        const modulePaths = modules.map((module) => module.href)
        const splitPath = fragmentPath(location.pathname)

        updatePath(
          splitPath
            .map((sp) => ({
              display: getBreadcrumbDisplay(sp),
              href: sp.href,
            }))
            .filter((sp) => !modulePaths.includes(sp.href))
        )
      }
    }

    if (isAuthenticated && state.isInitialized) asyncFindCurrentPath()
  }, [location.pathname, state.navigation.modules])

  useEffect(() => {
    if (!deepEqual(instanceConfigurations, state.instanceConfigurations)) {
      dispatch({
        type: SET_INSTANCE_CONFIG,
        payload: {
          instanceConfigurations,
        },
      })
    }
  }, [instanceConfigurations])

  return (
    <ConfigurationContext.Provider
      value={{
        ...state,
        fieldDefinitions,
        updatePath,
        hasPrivilege,
        canAccessModule,
        getEnvInfo,
        getModule,
        instanceConfigFetched: isFetched,
        currentModule,
        currentModuleId,
        getMetadataDisplay,
        getModuleFromName,
        setAutosetBreadcrumbs,
        documentMimeTypes,
        mimeTypesLoading,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  )
}

export { ConfigurationContext, ConfigurationProvider }
