import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'

import ItemsOrderList from 'components/collateral/ItemsOrderList'
import { CollateralProvider } from 'contexts/collateral/CollateralContext'

function ItemsOrderListPage() {
  const location = useLocation()

  if (!location.state) {
    return <Navigate to="/correspondence-hub/collateral"></Navigate>
  } else {
    return (
      <CollateralProvider>
        <ItemsOrderList />
      </CollateralProvider>
    )
  }
}

export default ItemsOrderListPage
