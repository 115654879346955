import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

import saveData from 'helpers/browser/saveData'

const useDocumentExtracts = (params) => {
  const { data, isValidating, mutate } = useSWR(
    params
      ? `/document-extracts?${urlSerialize({
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const downloadZip = async (docExtract) => {
    const res = await axios.get(`/document-extracts/${docExtract.id}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/zip',
      },
    })

    const file = res.data

    if (file) saveData(file, docExtract.filename, 'application/zip')
    else throw new Error('ZIP could not be downloaded.')
  }

  return {
    downloads: data?._embedded?.document_extracts || [],
    embedded: data?._embedded ?? {},
    loading:
      (!data?._embedded?.document_extracts && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    downloadZip,
  }
}

export default useDocumentExtracts
