import React from 'react'

import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import getDollarDisplay from 'helpers/css/getDollarDisplay'

function PostTransactionActivityRowCell({
  row,
  colId,
  getDataDisplay,
  isPayment,
}) {
  return (
    <TableCell
      sx={{
        py: 5,
        backgroundColor: isPayment && 'hover.main',
        color: 'darkgray.main',
        fontSize: '16px',
      }}
    >
      {getDataDisplay(row, colId)}
    </TableCell>
  )
}

function PostTransactionActivityRow({
  row,
  rowKey,
  visibleColumns = [],
  getDataDisplay,
  columns,
}) {
  const isPayment = `${row.transaction_type}`.toLowerCase() === 'payment'
  const isAdjustment = `${row.transaction_type}`.toLowerCase() === 'adjustment'
  const includesRunningBalance = columns.find(
    (col) => col.id === 'ending_balance'
  )

  if (isPayment || isAdjustment) {
    return (
      <TableRow key={row[rowKey]}>
        <PostTransactionActivityRowCell
          isPayment
          row={row}
          getDataDisplay={getDataDisplay}
          colId={'date_transaction'}
        />
        <TableCell
          sx={{
            py: 5,
            backgroundColor: 'hover.main',
            color: 'darkgray.main',
            fontWeight: 600,
            fontSize: '16px',
          }}
        >
          {isPayment ? 'Payment' : 'Adjustment'}
        </TableCell>
        <TableCell
          colSpan={visibleColumns.length - (includesRunningBalance ? 4 : 3)}
          sx={{
            py: 5,
            backgroundColor: 'hover.main',
            color: 'darkgray.main',
            fontSize: '16px',
          }}
        >
          {row.notes?.split('\n').join('\t') || 'N/A'}
        </TableCell>
        <TableCell
          sx={{
            py: 5,
            backgroundColor: 'hover.main',
            color: 'darkgray.main',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography my="auto" fontWeight={600}>
              {isPayment && '+'} {getDollarDisplay(row.amount)}
            </Typography>
            {isPayment && !includesRunningBalance && (
              <CheckCircleIcon
                color="success"
                sx={{ fontSize: '32px', my: 'auto' }}
              />
            )}
          </Box>
        </TableCell>
        {includesRunningBalance && (
          <TableCell
            sx={{
              py: 5,
              backgroundColor: 'hover.main',
              color: 'darkgray.main',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography my="auto" fontWeight={600}>
                {getDollarDisplay(row.ending_balance)}
              </Typography>
              {isPayment && (
                <CheckCircleIcon
                  color="success"
                  sx={{ fontSize: '32px', my: 'auto' }}
                />
              )}
            </Box>
          </TableCell>
        )}
      </TableRow>
    )
  }

  return (
    <>
      <TableRow key={row[rowKey]}>
        {visibleColumns.map((col) => (
          <PostTransactionActivityRowCell
            isPayment={isPayment}
            key={`${row[rowKey]}-${col}`}
            row={row}
            getDataDisplay={getDataDisplay}
            colId={col}
          />
        ))}
      </TableRow>
    </>
  )
}

export default PostTransactionActivityRow
