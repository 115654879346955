import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedListHR from 'components/styled/ExpandedListHR'

// local components
import ShowMoreContent from './components/ShowMoreContent'

import useLetter from 'hooks/document_approval/useLetter'

function DocumentApprovalExpandedList({
  children,
  expanded,
  setExpanded,
  rowData,
  getDataDisplay,
  visibleColumns,
  collapseDetails,
  hoverButtons,
}) {
  const gridWidth = 12
  const { letter } = useLetter({
    letterId: expanded ? rowData?.id : null,
    params: { embed: 'metadata' },
  })

  return (
    <Collapse
      in={expanded}
      sx={{
        textAlign: 'left',
        px: 11,
        pt: 2,
        mt: 0,
        '& .MuiCollapse-entered': {
          mt: 4,
        },
      }}
    >
      <Grid container columns={gridWidth} spacing={30}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography fontWeight="medium" fontSize={14}>
              Details
            </Typography>
            {collapseDetails && (
              <IconButton onClick={() => setExpanded(!expanded)}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
            {expanded && hoverButtons && (
              <Box
                sx={{
                  justifySelf: 'flex-end',
                  justifyContent: 'flex-end',
                  float: 'right',
                  width: '100%',
                  position: 'relative',
                  pb: 9,
                }}
              >
                {hoverButtons}
              </Box>
            )}
          </Box>
          <ExpandedListHR />
          <ShowMoreContent
            rowData={letter?._embedded?.metadata || {}}
            columns={Object.keys(letter?._embedded?.metadata ?? {}).map(
              (key) => ({
                id: key,
                display: key,
              })
            )}
            open={true}
            getDataDisplay={getDataDisplay}
            visibleColumns={visibleColumns}
          />
        </Grid>
      </Grid>
      {children}
    </Collapse>
  )
}

export default DocumentApprovalExpandedList
