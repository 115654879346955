import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Toolbar from '@mui/material/Toolbar'
import MuiIconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import styled from '@mui/styles/styled'
import Hidden from '@mui/material/Hidden'
import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

import Logo from 'components/common/Logo'
import BreadCrumbs from 'components/common/BreadCrumbs'
import NavAppBar from './NavAppBar'

import useSidebar from 'hooks/useSidebar'
import useAuth from 'hooks/useAuth'
import useUser from 'hooks/users/useUser'
import useClientDataList from 'hooks/flow/useClientDataList'

import stringAvatar from 'helpers/css/stringAvatar'
import { NAVBAR_DIMENSIONS, ADMIN_USERNAME } from 'utils/constants'

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(6),
  marginY: 'auto',
}))

function NavBar() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const { toggleOpen } = useSidebar('navigation')
  const { user, logOut } = useAuth()
  const {
    user: { username },
  } = useUser(user?.userId)
  const { contactsExist } = useClientDataList()

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleUserSettingsClick = () => {
    setAnchorEl(null)
    navigate('/user-settings')
  }

  const handleMPXContactClick = () => {
    setAnchorEl(null)
    navigate('/contact-mpx', {
      state: {
        breadcrumbs: [
          {
            display: 'Contact MPX',
            href: '/contact-mpx',
          },
        ],
      },
    })
  }

  return (
    <NavAppBar position="absolute">
      <Toolbar>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: NAVBAR_DIMENSIONS.HEIGHT,
          }}
        >
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleOpen}
            >
              <img src="/static/img/icons/topbar/menu.svg" alt="" />
            </IconButton>
            <Box
              sx={{
                color: 'white',
                marginLeft: { xs: 4, md: 20 },
                width: '109px',
                '& > *': {
                  height: '100%',
                  width: '100%',
                },
              }}
              component={Link}
              to={user.homepage || '/'}
            >
              <Logo variant="white" />
            </Box>
            <Hidden smDown>
              <Box
                sx={{
                  color: 'white',
                  marginLeft: 20,
                  marginY: 'auto',
                }}
              >
                <BreadCrumbs />
              </Box>
            </Hidden>
          </Box>
          <Box
            sx={{
              marginY: 'auto',
              display: 'flex',
            }}
          >
            <Hidden smDown>
              <Box sx={{ marginY: 'auto', marginRight: 2 }}>
                <Typography>{user.display}</Typography>
              </Box>
            </Hidden>
            <Avatar {...stringAvatar(user)} />
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleClick}
            >
              <img src="/static/img/icons/topbar/cog.svg" alt="" />
            </IconButton>
            <IconButton
              color="inherit"
              LinkComponent={Link}
              to="/help"
              target="_blank"
              size="small"
              sx={{ ml: '0px !important', mb: 'auto' }}
              title="Need help? Click here"
            >
              <HelpOutlineOutlinedIcon fontSize="12px" />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {username && username !== ADMIN_USERNAME && (
            <ListItemButton
              sx={{ color: 'primary.main' }}
              onClick={handleUserSettingsClick}
            >
              User Settings
            </ListItemButton>
          )}
          {contactsExist && (
            <ListItemButton
              sx={{ color: 'primary.main' }}
              onClick={handleMPXContactClick}
            >
              Contact MPX
            </ListItemButton>
          )}
          <ListItemButton sx={{ color: 'error.main' }} onClick={logOut}>
            Logout
          </ListItemButton>
        </List>
      </Popover>
    </NavAppBar>
  )
}

export default NavBar
