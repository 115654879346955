import React from 'react'
import styled from 'styled-components/macro'
import get from 'lodash.get'
import set from 'lodash.set'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import axios from 'axios'

const ModalFormLabel = styled.span`
  font-weight: bold;
`

/**
 * @param {Object} lookupData - data to be sent to SmartyStreets
 * format: inputId, addressee, street, street2, secondary, urbanization, city, state, zipcode, maxCandidates, match
 * see: https://github.com/smartystreets/smartystreets-javascript-sdk/blob/master/examples/us_street.js
 */
export const checkAddress = async (lookupData) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/address-validations`,
      lookupData,
      { headers: { Accept: '*/*' } }
    )
    return data
  } catch (err) {
    return err
  }
}

export const generateAddressModalProps = async ({
  values = {},
  addressKeys = {},
  onContinue,
  onCancel,
}) => {
  const generateModalContent = (addressValues) => {
    return (
      <Stack>
        <Typography>
          <ModalFormLabel>Street: </ModalFormLabel>
          {get(addressValues, addressKeys.address)}
          {` ${get(addressValues, addressKeys.addressalt) ?? ''}`}
        </Typography>
        <Typography>
          <ModalFormLabel>City: </ModalFormLabel>
          {get(addressValues, addressKeys.city)}
        </Typography>
        <Typography>
          <ModalFormLabel>State / Province: </ModalFormLabel>
          {get(addressValues, addressKeys.state)}
        </Typography>
        <Typography>
          <ModalFormLabel>Zip / Postal Code: </ModalFormLabel>
          {get(addressValues, addressKeys.zip)}
        </Typography>
        <Typography>
          <ModalFormLabel>Country: </ModalFormLabel>
          {get(addressValues, addressKeys.country)}
        </Typography>
      </Stack>
    )
  }

  const response = await checkAddress({
    line1: get(values, addressKeys.address),
    line2: get(values, addressKeys.addressalt),
    city: get(values, addressKeys.city),
    state: get(values, addressKeys.state),
    zip: get(values, addressKeys.zip),
    country: get(values, addressKeys.country),
  })

  if (response instanceof Error) {
    const modalProps = {
      title: 'The selected address could not be validated, please check your input and try again',
      size: 'sm',
      footerButtonProps: [
        {
          children: 'Repair Address',
          color: 'primary',
          variant: 'outlined',
          onClick: () => onCancel(),
        },
      ]
    };

    if (response?.response?.status === 400) {
      // safe error
      if (response?.response?.data?.display_message) {
        modalProps.children = (
          <Box>
            <Typography mb={6}>
              {response?.response?.data?.display_message}
            </Typography>
          </Box>
        );
      }
    }

    return { modalProps };
  }

  const { address, is_valid: isValid } = response
  const modalProps = {
    title: 'The selected address could not be found.',
    size: 'sm',
    children: generateModalContent(values),
    footerButtonProps: [
      {
        children: <Box sx={{ fontSize: 12 }}>Continue anyways</Box>,
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          const newValues = {
            ...values,
          }

          if (isValid) {
            set(newValues, addressKeys.address, address.line1)
            set(newValues, addressKeys.addressalt, address.line2)
            set(newValues, addressKeys.city, address.city)
            set(newValues, addressKeys.state, address.state)
            set(newValues, addressKeys.zip, address.zip)
            set(newValues, addressKeys.country, address.country)
          }

          onContinue(newValues)
        },
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: () => onCancel(),
      },
    ],
  }
  if (isValid) {
    modalProps.title = 'Is the following address correct?'
    modalProps.footerButtonProps[0].children = 'Continue'
    modalProps.children = generateModalContent({
      [addressKeys.address]: address.line1,
      [addressKeys.addressalt]: address.line2,
      [addressKeys.city]: address.city,
      [addressKeys.state]: address.state,
      [addressKeys.zip]: address.zip,
      [addressKeys.country]: address.country,
    })
  }

  return { modalProps }
}
