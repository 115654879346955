import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import isSameDay from 'date-fns/isSameDay'

import Box from '@mui/material/Box'

import getLastDayOfMonth from 'helpers/datetime/getLastDayOfMonth'
import getFirstDayOfMonth from 'helpers/datetime/getFirstDayOfMonth'
import isDateBetween from 'helpers/datetime/isDateBetween'

import CalendarItem from './Item'

function CalendarItemGrid({ dateRange, setDateRange, month, year, single }) {
  const labels = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  const [startDate, endDate] = dateRange

  const isActive = (date) => {
    return isSameDay(date, startDate) || isSameDay(date, endDate)
  }

  const setActive = (pressedDate) => {
    if (single || !startDate) setDateRange([pressedDate, undefined])
    else if (!endDate) {
      let newDateRange = [startDate, pressedDate]
      const dateDiff = pressedDate - startDate

      if (dateDiff === 0) newDateRange = []
      // handle edge case where later date is chosen first
      else if (dateDiff < 0) newDateRange.reverse()

      setDateRange(newDateRange)
    } else {
      const startDiff = Math.abs(startDate - pressedDate)
      const endDiff = Math.abs(endDate - pressedDate)

      if (startDiff <= 0) setDateRange([endDate, undefined])
      else if (endDiff <= 0) setDateRange([startDate, undefined])
      else if (startDiff > endDiff) setDateRange([startDate, pressedDate])
      else if (endDiff > startDiff) setDateRange([pressedDate, endDate])
    }
  }

  const getDays = useCallback(() => {
    const days = []
    const totalDays = 42

    const lastDay = getLastDayOfMonth(month, year)
    const firstDay = getFirstDayOfMonth(month, year)
    const daysFromLastMonth = firstDay.getDay()
    const daysFromNextMonth =
      totalDays - (daysFromLastMonth + lastDay.getDate())

    for (
      let i = -daysFromLastMonth;
      i < lastDay.getDate() + daysFromNextMonth;
      i++
    ) {
      const newDate = new Date(firstDay)
      newDate.setDate(newDate.getDate() + i)

      // props that only change when month or year changes
      const dateProps = {
        date: newDate,
        display: newDate.getDate().toString(),
        outsideCurrentMonth: i >= lastDay.getDate() || i < 0,
      }

      days.push(dateProps)
    }

    return days
  }, [month, year])

  const days = getDays()

  return (
    <Box
      spacing={0}
      sx={{
        height: 'calc(95% - 69px)',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 27px)',
      }}
    >
      {labels.map((label, labelIndex) => (
        <Box key={`${label}-${labelIndex}`}>
          <CalendarItem display={label} />
        </Box>
      ))}
      {days.map((dateProps, dateIndex) => (
        <Box key={`date-${dateIndex}`}>
          <CalendarItem
            isActive={isActive(dateProps.date)}
            setActive={setActive}
            isEnd={isSameDay(dateProps.date, endDate)}
            isBetween={isDateBetween({
              start: startDate,
              end: endDate,
              date: dateProps.date,
            })}
            {...dateProps}
          />
        </Box>
      ))}
    </Box>
  )
}

CalendarItemGrid.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  setDateRange: PropTypes.func,
  month: PropTypes.number,
  year: PropTypes.number,
}

export default CalendarItemGrid
