import { useState, useContext, useEffect } from 'react'

import axios from 'utils/axios'
import { APP_KEY } from 'utils/constants'

import { ConfigurationContext } from 'contexts/ConfigurationContext'

const useConfiguration = () => {
  const context = useContext(ConfigurationContext)

  if (!context)
    throw new Error(
      'ConfigurationContext must be placed within ConfigurationProvider'
    )

  return context
}

export const useSearchFieldConfiguration = ({
  moduleId,
  index,
  moduleIndex,
}) => {
  const {
    navigation: { modules },
    fieldDefinitions,
  } = useConfiguration()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [searchConfig, setSearchConfig] = useState({})

  const abstractFieldData = (field) => {
    if (fieldDefinitions[field.path]) {
      return {
        ...field,
        ...fieldDefinitions[field.path],
      }
    } else {
      return {
        ...field,
        display: field.path.replace(/_/gm, ' '),
      }
    }
  }

  const getConfigurations = () => {
    let configurations = null

    if (index !== undefined && moduleIndex !== undefined) {
      configurations = modules[moduleIndex][index]?.configurations
    } else {
      const allModules = [
        ...modules,
        ...modules.flatMap((module) => module.modules),
      ]
      const foundModule = allModules.find(
        (module) => module.id.toString() === moduleId?.toString()
      )
      if (foundModule) configurations = foundModule.configurations
    }

    return configurations
  }

  const getSearchConfig = async (configurations) => {
    const appendEtag = async (fieldset) => {
      const res = await axios.get(
        `/modules/${moduleId}/search-field-configurations/${fieldset.id}`,
        {
          params: {
            app_key: APP_KEY,
          },
        }
      )

      if (!res.status === 200) return null

      const { etag } = res.headers

      return {
        ...fieldset,
        fields: fieldset.fields.map(abstractFieldData),
        etag,
      }
    }

    setLoading(true)

    const searchFieldConfigurations = await Promise.all(
      configurations
        .find((config) => config.configuration_type === 'search_fieldset')
        ?.fieldsets.map(appendEtag)
    )

    setSearchConfig({
      searchFieldConfigurations,
    })

    setLoading(false)
  }

  const fetchSearchConfig = async () => {
    setLoading(true)

    try {
      const res = await axios.get(`/modules/${moduleId}`, {
        params: {
          app_key: 'mpxpp_healthlinq',
          embed: 'modules|configurations',
        },
      })

      if (res.status === 200) {
        const configurations = res.data._embedded?.configurations
        await getSearchConfig(configurations)
      } else {
        throw new Error('Failed to fetch search config')
      }
    } catch (error) {
      setError({ message: error.message })
    }

    setLoading(false)
  }

  useEffect(() => {
    const asyncSetConfig = async () => {
      if (modules.length > 0) {
        const configurations = getConfigurations()

        if (configurations) {
          await getSearchConfig(configurations)
        }
      }
    }

    asyncSetConfig()
  }, [moduleId, index, moduleIndex, modules])

  return {
    searchConfig,
    loading,
    error,
    fetchSearchConfig,
  }
}

export default useConfiguration
