import React, { useState, useMemo } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedListHR from 'components/styled/ExpandedListHR'

function MMGAgencyPreferencesExpandedList({
  children,
  expanded,
  rowData,
  columns,
  statusTitle = '',
  getDataDisplay,
  visibleColumns,
  collapseDetails,
}) {
  const gridWidth = 6
  const [open, setOpen] = useState(!collapseDetails)

  const mailingPreferences = useMemo(() => {
    const preferences = Object.entries(rowData?.mailing_preferences || {}) || []

    return preferences.flatMap(([documentType, statePreferences]) =>
      Object.entries(statePreferences).flatMap(
        ([state, transactionTypePreferences]) =>
          Object.entries(transactionTypePreferences).flatMap(
            ([transactionType, prefs]) =>
              Object.values(prefs).map((lineofBusiness) => ({
                documentType,
                state,
                transactionType,
                lineofBusiness,
              }))
          )
      )
    )
  }, [rowData])

  return (
    <Collapse
      in={expanded}
      sx={{
        textAlign: 'left',
        px: 11,
        py: expanded && 2,
        mt: 0,
        '& .MuiCollapse-entered': {
          mt: 4,
        },
      }}
    >
      {statusTitle && (
        <Typography fontWeight="bold" fontSize={14}>
          {statusTitle}
        </Typography>
      )}

      <Grid container columns={gridWidth} spacing={30}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight="medium" fontSize={14}>
              Preferences
            </Typography>
            {collapseDetails && (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </Box>
          <ExpandedListHR />
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Document Type</TableCell>
                  <TableCell>Policy State</TableCell>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Line of Business</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mailingPreferences.map((mp) => (
                  <TableRow key={JSON.stringify(mp)}>
                    <TableCell>{mp.documentType}</TableCell>
                    <TableCell>{mp.state}</TableCell>
                    <TableCell>{mp.transactionType}</TableCell>
                    <TableCell>{mp.lineofBusiness}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Collapse>
  )
}

export default MMGAgencyPreferencesExpandedList
