import { useMemo, useEffect } from 'react'
import useSWR from 'swr'

import fetcher from 'utils/fetcher'

import urlSerialize from 'helpers/browser/urlSerialize'
import {
  DATE_RANGE_PRESETS,
  dateRangePresets,
} from 'components/common/DateRangePicker/dateRangePresets'
import getUTCDateRange from 'helpers/datetime/getUTCDateRange'

import useDashboardContext from 'hooks/context/useDashboardContext'

const useWidget = ({ widgetKey, widgetName, params, presetId, isDisabled }) => {
  const { priorityLevel, setWidgetLoaded, widgetMap } = useDashboardContext()
  const priority = widgetMap[widgetName]?.priority ?? 1
  const disabled = priority > priorityLevel || isDisabled

  const apiParams = useMemo(() => {
    const newParams = { ...params }

    if (
      dateRangePresets[presetId] &&
      presetId !== DATE_RANGE_PRESETS.CURRENT_DAY
    ) {
      const [start, end] = dateRangePresets[presetId].preset
      const [utcStart, utcEnd] = getUTCDateRange(start, end)
      newParams['datetime_ran[start]'] = utcStart.toISOString()
      newParams['datetime_ran[end]'] = utcEnd.toISOString()
    }

    return newParams
  }, [params, presetId])

  const { data, isValidating, mutate } = useSWR(
    widgetKey && presetId && !disabled
      ? `/widgets/${widgetKey}${
          params || presetId ? `?${urlSerialize(apiParams)}` : ''
        }`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    if (isDisabled || (data?.aggregated_data && !isValidating && widgetName)) {
      setWidgetLoaded(widgetName)
    }
  }, [data, isValidating, isDisabled])

  return {
    data: data?.aggregated_data ?? [],
    loading: isValidating || disabled,
    validating: isValidating,
    mutate,
  }
}

export default useWidget
