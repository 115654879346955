import React from 'react'
import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'

import GlobalStyle from 'components/GlobalStyle'
import Logo from 'components/common/Logo'

function CornerLinq({ children }) {
  return (
    <Box sx={{ px: 14, py: 12, height: '100vh', width: '100vw' }}>
      <CssBaseline />
      <GlobalStyle />
      <Box sx={{ width: '226px', height: '46px' }}>
        <Logo />
      </Box>
      {children}
      <Outlet />
    </Box>
  )
}

export default CornerLinq
