import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

import axios from 'utils/axios'
import fetcher, { etagFetcher } from 'utils/fetcher'

import urlSerialize from 'helpers/browser/urlSerialize'
import saveData from 'helpers/browser/saveData'
import useNotification from './context/useNotification'
import useConfiguration from './useConfiguration'

import DownloadsNotification from 'components/common/Notification/Downloads'

const useDocuments = (params) => {
  const [docData, setDocData] = useState({})

  const { data, isValidating, mutate } = useSWR(
    params
      ? `/documents?${urlSerialize({
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const deactivateDocument = async (documentId) => {
    // get document etag
    let res = await axios.get(`/documents/${documentId}`)
    const { etag } = res.headers

    if (!etag) throw new Error('Document etag not found')

    // deactivate document
    res = await axios.delete(`/documents/${documentId}`, {
      headers: { 'If-Match': etag },
    })

    if (res.status !== 204) throw new Error('Error deactivating document')
  }

  const deactivateDocuments = async (documentIds) => {
    for (let i = 0; i < documentIds.length; i++) {
      await deactivateDocument(documentIds[i])
    }
    mutate()
  }

  const reprintDocument = async (documentId, reprintData) => {
    const res = await axios.post(
      `/documents/${documentId}/reprints`,
      reprintData
    )

    if (res.status !== 201) throw new Error('Error reprinting document')
  }

  const reprintDocuments = async (documentIds, reprintData) => {
    for (let i = 0; i < documentIds.length; i++) {
      await reprintDocument(documentIds[i], reprintData)
    }
  }

  const resendNotification = async (notificationId, resendData) => {
    const res = await axios.post(
      `/notifications/${notificationId}/resends`,
      resendData
    )

    if (res.status !== 201) throw new Error('Error resending notification')
  }

  const resendNotifications = async (notificationIds, resendData) => {
    for (let i = 0; i < notificationIds.length; i++) {
      await resendNotification(notificationIds[i], resendData)
    }
  }

  const downloadDocumentsHelper = async (extractSearchMap, urlPathOverride) => {
    const res = await axios.post(
      urlPathOverride || '/document-extracts',
      extractSearchMap
    )

    if (res.status !== 200) {
      throw new Error('Error downloading documents')
    }

    return res.data
  }

  const downloadDocuments = async (documentIds) => {
    if (!Array.isArray(documentIds) || documentIds.length === 0) {
      throw new Error('Error downloading documents')
    }

    return downloadDocumentsHelper({
      document_ids: documentIds.map((docId) => parseInt(docId)),
    })
  }

  const downloadDocumentsBySearch = async (search, urlPathOverride) => {
    if (search !== undefined && typeof search !== 'object') {
      throw new Error(
        'Error creating document download, invalid search parameters'
      )
    }

    return downloadDocumentsHelper(
      {
        ...(search || []),
      },
      urlPathOverride
    )
  }

  // wrap data to prevent it from clearing on error
  useEffect(() => {
    if (data?.error) {
      setDocData({ ...docData, error: data.error })
    } else {
      setDocData(data)
    }
  }, [data])

  return {
    documents: docData?._embedded?.documents ?? [],
    embedded: docData?._embedded ?? {},
    loading:
      (!docData?._embedded?.documents && !docData?.error) || isValidating,
    validating: isValidating,
    count: docData?.count,
    total: docData?.total,
    totalPages: docData?.total_pages,
    pageLimit: docData?.resource_limit,
    pageSize: docData?.page_size,
    paginates: docData?.paginates,
    error: docData?.error,
    deactivateDocuments,
    reprintDocuments,
    resendNotifications,
    downloadDocuments,
    downloadDocumentsBySearch,
    mutate,
  }
}

export const useDocumentPdf = ({ documentId }) => {
  const [missingPdfs, setMissingPdfs] = useState({})
  const [foundPdfs, setFoundPdfs] = useState({})
  const [pdf, setPdf] = useState(null)

  const getPdfByDocumentId = async (docId) => {
    if (!docId) return null

    try {
      const res = await axios.get(`/documents/${docId}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })

      if (res.status === 202) {
        return { downloadId: docId }
      } else if (res.status !== 200) {
        throw new Error('Document not found')
      }

      return res.data
    } catch (err) {
      return null
    }
  }

  const fetchPdf = async () => {
    if (missingPdfs[documentId]) return false
    if (foundPdfs[documentId]) {
      setPdf(foundPdfs[documentId])
      return true
    }

    try {
      const pdfData = await getPdfByDocumentId(documentId)

      if (pdfData) {
        setFoundPdfs({ ...foundPdfs, [documentId]: pdfData })
        setPdf(pdfData)

        return true
      } else {
        throw new Error('A PDF could not be found for this document.')
      }
    } catch (err) {
      setPdf(null)

      missingPdfs[documentId] = true
      setMissingPdfs(missingPdfs)

      return false
    }
  }

  return {
    fetchPdf,
    getPdfByDocumentId,
    pdf,
  }
}

export const useDocument = (documentId, params) => {
  const { setComponent, setOpen } = useNotification()
  const { downloadDocuments } = useDocuments()
  const { getPdfByDocumentId } = useDocumentPdf({ documentId })

  const { data, isValidating, mutate } = useSWR(
    documentId
      ? `/documents/${documentId}?${urlSerialize({
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const getDocument = async (docId, params) => {
    const res = await axios.get(
      `/documents/${docId}?${urlSerialize({
        embed: 'notification|notes|metadata|linked_notification_documents',
        ...params,
      })}`
    )
    return res.data
  }

  const downloadDocument = async (document) => {
    const { id } = document
    let contentType = document?.content_type
    let file = null

    switch (contentType) {
      case 'application/pdf':
        file = await getPdfByDocumentId(id)
        break
      default:
        file = (
          await axios.get(`/documents/${id}`, {
            responseType: 'blob',
            headers: { accept: contentType },
          })
        ).data
    }

    if (file?.downloadId) {
      await downloadDocuments([file.downloadId])
      setComponent(DownloadsNotification)
      setOpen(true)
    } else if (file) saveData(file, document.filename, contentType)
    else throw new Error('Document could not be downloaded.')

    return file
  }

  return {
    getDocument,
    downloadDocument,
    document: data,
    loading: !data || isValidating,
    mutate,
  }
}

export const useAllDocuments = (
  params,
  total,
  getDataDisplay,
  searchFields,
  getColumnDisplay,
  pageLimit
) => {
  const { setBasicNotification, setError } = useNotification()
  const { canAccessModule } = useConfiguration()
  const canAccessDownloads = canAccessModule('/main/downloads')

  const getAllDocuments = async (csv = false) => {
    let documents = []

    if (total && params) {
      try {
        const newParams = { ...params }
        newParams.fields = undefined // get all fields regardless of search

        if (csv && typeof pageLimit === 'number' && total > pageLimit) {
          if (!canAccessDownloads) {
            setError(
              'Access to Downloads is required to download large csv files. Ask an administrator for access.'
            )
            return
          }

          await axios.post(
            '/documents/extract',
            {
              extractType: 'csv',
            },
            {
              params: {
                ...newParams,
              },
            }
          )

          setBasicNotification(
            <>
              We started preparing your file. This may take some time. Please
              check <Link to="/main/downloads">Downloads</Link> later.
            </>
          )
        } else {
          const res = await axios.get('/documents', {
            params: {
              ...newParams,
              'paging[page_size]': total,
              'paging[page]': 1,
            },
            headers: csv
              ? {
                  Accept: 'text/csv',
                }
              : undefined,
          })

          if (res.status === 200 && csv) return res.data

          documents = res.data._embedded.documents
        }
      } catch (err) {
        setError('Unable to get CSV export', err)
      }
    }

    return documents
  }

  const getCsvExport = async () => {
    return getAllDocuments(true)
  }

  return {
    getAllDocuments,
    getCsvExport,
  }
}

export const useDocumentNotes = (documentId, params = { type: 'User' }) => {
  const { data, isValidating, mutate } = useSWR(
    documentId
      ? `/documents/${documentId}/notes?${urlSerialize(params)}`
      : null,
    etagFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const makeNote = async (note) => {
    const res = await axios.post(`/documents/${documentId}/notes`, {
      note,
    })

    mutate()

    return res
  }

  return {
    notes: data?._embedded?.notes ?? [],
    loading: (!data?._embedded?.notes && !data?.error) || isValidating,
    error: data?.error,
    mutate,
    makeNote,
  }
}

export default useDocuments
