import React, { useCallback, useMemo, useState } from 'react'
import { Formik, useFormikContext } from 'formik'
import get from 'lodash.get'

import Fab from '@mui/material/Fab'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'

import BellIcon from '@mui/icons-material/Notifications'
import SaveIcon from '@mui/icons-material/Save'
import XIcon from '@mui/icons-material/Close'

import useOrderProgramAlerts from 'hooks/orders/useOrderProgramAlerts'
import usePrograms from 'hooks/programs/usePrograms'
import useConfiguration from 'hooks/useConfiguration'
import useNotification from 'hooks/context/useNotification'
import { ORDER_HISTORY_USER_ALERT_PROGRAMIDS } from 'utils/constants'
import deepEqual from 'deep-equal'

import useAuth from 'hooks/useAuth'
import useUser from 'hooks/users/useUser'

const NOTIFICATION_OPTIONS = ['sms', 'email']

function ProgramOption({ program }) {
  const { values, handleChange } = useFormikContext()
  const { user } = useAuth()
  const { user: authUser } = useUser(user?.userId)

  return (
    <>
      <Divider sx={{ my: 1 }} />
      <Stack>
        <Typography fontWeight="bold">{program.name}</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > *': { mr: 2 } }}>
          {NOTIFICATION_OPTIONS.filter(
            (opt) => opt !== 'sms' || (opt === 'sms' && authUser?.phone)
          ).map((opt) => (
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ textTransform: 'uppercase', my: 'auto' }}>
                {opt}:
              </Typography>
              <Checkbox
                id={`programs.${program.id}.${opt}`}
                name={`programs.${program.id}.${opt}`}
                size="small"
                sx={{ my: 'auto' }}
                color="green"
                checked={get(values.programs, `${program.id}.${opt}`) === 1}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked ? 1 : 0,
                    },
                  })
                }}
              />
            </Box>
          ))}
        </Box>
      </Stack>
    </>
  )
}

function EditNotifications() {
  const [anchorEl, setAnchorEl] = useState(null)
  const { programAlerts, handleProgramAlertsUpdate } = useOrderProgramAlerts({})
  const { programs } = usePrograms()
  const { instanceConfigurations } = useConfiguration()
  const { setBasicNotification, setError } = useNotification()

  const validPrograms = useMemo(() => {
    return programs.filter(
      (program) =>
        Array.isArray(
          instanceConfigurations[ORDER_HISTORY_USER_ALERT_PROGRAMIDS]
        ) &&
        instanceConfigurations[ORDER_HISTORY_USER_ALERT_PROGRAMIDS].includes(
          program.id.toString()
        )
    )
  }, [programs, instanceConfigurations])

  const initialValues = useMemo(() => {
    const values = {
      programs: {},
    }

    validPrograms.forEach((program) => {
      values.programs[program.id] = {
        name: program.name,
        id: program.id,
      }

      NOTIFICATION_OPTIONS.forEach((opt) => {
        values.programs[program.id][opt] = parseInt(
          programAlerts?.[program.id]?.[opt] || 0
        )
      })
    })

    return values
  }, [validPrograms, programAlerts])

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await handleProgramAlertsUpdate(values)
        setBasicNotification('Notifications preferences successfully updated!')
      } catch (err) {
        setError(
          'Unable to update notification preferences. Try again later.',
          err
        )
      }
    },
    [handleProgramAlertsUpdate, setBasicNotification, setError]
  )

  return (
    <Box sx={{ my: 'auto' }}>
      <IconButton
        sx={{ my: 'auto' }}
        title="Edit Notifications Preferences"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <BellIcon color="primary" />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Card
          sx={{
            padding: 2,
            border: '1px solid',
            borderColor: 'lightgray.main',
          }}
        >
          <Typography variant="h1">Program Notification Preferences</Typography>
          {!Boolean(validPrograms?.length) && (
            <Box p={4}>
              <Typography textAlign="center">No programs available.</Typography>
            </Box>
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, submitForm, resetForm }) => (
              <Stack sx={{ position: 'relative' }}>
                {validPrograms.map((program) => (
                  <ProgramOption program={program} />
                ))}
                {!deepEqual(values, initialValues) && (
                  <>
                    <Fab
                      title="Cancel Changes"
                      color="secondary"
                      sx={{ position: 'fixed', right: 116, bottom: 24 }}
                      onClick={() => resetForm()}
                    >
                      <XIcon />
                    </Fab>
                    <Fab
                      title="Save Changes"
                      color="primary"
                      sx={{ position: 'fixed', right: 48, bottom: 24 }}
                      onClick={() => submitForm()}
                    >
                      <SaveIcon />
                    </Fab>
                  </>
                )}
              </Stack>
            )}
          </Formik>
        </Card>
      </Popover>
    </Box>
  )
}

export default EditNotifications
