import axios from 'utils/axios'

const useAllDocumentInboundItems = (params, total) => {
  const getAllDocumentInboundItems = async (csv = false) => {
    let documents = []

    if (total && params) {
      try {
        const newParams = { ...params }
        newParams.fields = undefined // get all fields regardless of search

        const res = await axios.get('/document-inbound-items', {
          params: {
            ...newParams,
            'paging[page_size]': total,
            'paging[page]': 1,
          },
          headers: csv
            ? {
                Accept: 'text/csv',
              }
            : undefined,
        })

        if (res.status === 200 && csv) return res.data

        documents = res.data._embedded.document_inbound_items
      } catch (err) {}
    }

    return documents
  }

  const getCsvExport = async () => {
    return getAllDocumentInboundItems(true)
  }

  return {
    getAllDocumentInboundItems,
    getCsvExport,
  }
}

export default useAllDocumentInboundItems
