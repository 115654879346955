import mimeJson from 'json/mime.json'

const getMimeTypeDisplay = (mimeType) => {
  if (typeof mimeType !== 'string') return 'N/A'

  const foundEntry = Object.entries(mimeJson).find(
    ([_, value]) => value === mimeType
  )

  const display = foundEntry?.[0] || mimeType.split('/').slice(-1)[0]

  return display
}

export default getMimeTypeDisplay
