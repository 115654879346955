import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'

import useTheme from '@mui/styles/useTheme'
import CloseIcon from '@mui/icons-material/Close'

import useSidebar from 'hooks/useSidebar'

function ResponsiveDrawer({
  width = '428px',
  open,
  setOpen,
  children,
  fadeProps,
  collapseProps,
  sx,
}) {
  const theme = useTheme()
  const showNavSidebar = useMediaQuery(theme.breakpoints.up('lg'))
  const { setOpen: setSidebarOpen, open: sidebarOpen } =
    useSidebar('navigation')

  const handleClose = () => setOpen(false)
  const drawerWidth = width

  useEffect(() => {
    if (!showNavSidebar && sidebarOpen && open) setSidebarOpen(false)
  }, [showNavSidebar, open])

  return (
    <Box sx={{ right: 0, zIndex: 100, ...sx }}>
      <Collapse in={Boolean(open)} orientation="horizontal" {...collapseProps}>
        <Box sx={{ minWidth: drawerWidth, height: sx?.height ?? '75vh' }}>
          <Card sx={{ height: sx?.height, boxShadow: 2 }}>
            <Box sx={{ height: '16px' }}>
              <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Fade
              in={Boolean(open)}
              timeout={{ appear: 2000, enter: 800 }}
              {...fadeProps}
            >
              <Box>{children}</Box>
            </Fade>
          </Card>
        </Box>
      </Collapse>
    </Box>
  )
}

export default ResponsiveDrawer
