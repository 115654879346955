import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import TextField from '@mui/material/TextField'

import ScrollableBox from 'components/styled/ScrollableBox'

function DropdownFromButton({
  children,
  options = [],
  selectedOption,
  setSelectedOption,
  buttonProps,
  onScrollDone,
  loading,
  keepOpen,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  const handleScroll = (e) => {
    const target = e.target

    const top = target.scrollTop
    const pos = target.scrollHeight - target.clientHeight
    const percentDiff = top / pos

    if (percentDiff === 1 && onScrollDone) {
      onScrollDone()
    }
  }

  return (
    <>
      <Button {...buttonProps} onClick={(e) => setAnchorEl(e.currentTarget)}>
        {children}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Card
          sx={{
            p: 4,
            border: '1px solid',
            borderColor: 'lightgray.main',
          }}
        >
          <TextField
            variant="standard"
            color="green"
            placeholder="Enter text to filter"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <ScrollableBox
            component="div"
            sx={{ overflow: 'auto', maxHeight: '25vh' }}
            onScroll={handleScroll}
          >
            <List>
              {options
                .filter((option) =>
                  option.display
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                )
                .map((option) => (
                  <ListItemButton
                    key={JSON.stringify(option || {})}
                    onClick={() => {
                      setSelectedOption(option)

                      if (!keepOpen) {
                        setAnchorEl(null)
                      }
                    }}
                  >
                    {option.display}
                  </ListItemButton>
                ))}
              {loading && (
                <ListItem sx={{ display: 'flex' }}>
                  <CircularProgress color="primary" sx={{ mx: 'auto' }} />
                </ListItem>
              )}
            </List>
          </ScrollableBox>
        </Card>
      </Popover>
    </>
  )
}

export default DropdownFromButton
