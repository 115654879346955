import add from 'date-fns/add'
import format from 'date-fns/format'
import localDateToUTC from 'helpers/datetime/localDateToUTC'
import utcToLocalDate from 'helpers/datetime/utcToLocalDate'

export const FILTER_OPTION_DISPLAYS = {
  le: '<=',
  ge: '>=',
  l: '<',
  ne: '!=',
  g: '>',
  in: 'IN',
  between: 'BETWEEN',
}

export const FILTER_OPTION_BY_TYPE = {
  TEXT: ['ne', 'in'],
  NUMBER: ['l', 'g', 'le', 'ge', 'ne', 'in'],
  BOOLEAN: ['ne'],
  DATETIME: ['l', 'g', 'le', 'ge', 'ne', 'between'],
}

export const COLUMN_TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATETIME: 'DATETIME',
}

export const getColumnType = (column) => {
  if (column.dataType === 'tinyint(1)') {
    return COLUMN_TYPES.BOOLEAN
  }

  if (column.dataType.includes('int')) {
    return COLUMN_TYPES.NUMBER
  }

  if (column.dataType.includes('date')) {
    return COLUMN_TYPES.DATETIME
  }

  return COLUMN_TYPES.TEXT
}

export const getVarcharMaxLength = (column) => {
  if (!column.dataType.includes('varchar')) return undefined

  return column.dataType.match(/(?<=varchar\()\d+(?=\))/)?.[0]
}

export const generateWhereName = (filter) => {
  const op = filter.action
  const prefix = `where${op.toUpperCase()}`
  return `${prefix}_${filter.col}`
}

export function convertFormForAPI(values, columnToTypeMap) {
  const body = {}

  body.datadomains = values.data_domains.map((dd) => dd.view)
  body.columns = {}
  body.public = values.public ? 1 : 0
  body.name = values.name
  body.where = {}
  body.order = []
  body.user_input = {}

  Object.values(values.report_columns)
    .sort((a, b) => a.position - b.position)
    .forEach((col) => {
      if (!body.columns[col.view]) {
        body.columns[col.view] = { [col.col]: null }
      } else {
        body.columns[col.view][col.col] = null
      }
    })

  values.report_filters.forEach((filter) => {
    let target = filter.target
    let op = filter.action
    const whereName = generateWhereName(filter)

    if (target instanceof Date) {
      if (op === 'eq') {
        op = 'between'

        target = [new Date(target), new Date(target)]
      } else if (op === 'le') {
        target.setHours(23, 59, 59, 999)
      } else if (op === 'ge') {
        target.setHours(0, 0, 0, 0)
      } else if (op === 'g') {
        target.setHours(23, 59, 59, 999)
      } else if (op === 'ne') {
        let [dateStart, dateEnd] = [new Date(target), new Date(target)]

        dateStart.setHours(0, 0, 0, 0)
        dateEnd.setHours(24, 0, 0, 0)
        dateStart = localDateToUTC(dateStart)
        dateEnd = localDateToUTC(dateEnd)

        body.where[`${whereName}_start`] = {
          view: filter.view,
          col: filter.col,
          default: `${format(dateStart, 'yyyy-MM-dd hh:mm:ss')}`,
          user_definable: true,
          op: 'l',
        }

        body.where[`${whereName}_end`] = {
          view: filter.view,
          col: filter.col,
          default: `${format(dateEnd, 'yyyy-MM-dd hh:mm:ss')}`,
          user_definable: true,
          op: 'g',
        }

        return
      }
    }

    if (op === 'between') {
      try {
        // target is tuple of dates
        let [dateStart, dateEnd] = target

        dateStart = new Date(dateStart)
        dateEnd = new Date(dateEnd)

        dateStart.setHours(0, 0, 0, 0)
        dateEnd.setHours(24, 0, 0, 0)
        dateStart = localDateToUTC(dateStart)
        dateEnd = localDateToUTC(dateEnd)

        body.where[`${whereName}_start`] = {
          view: filter.view,
          col: filter.col,
          default: `${format(dateStart, 'yyyy-MM-dd hh:mm:ss')}`,
          user_definable: true,
          op: 'ge',
        }

        body.where[`${whereName}_end`] = {
          view: filter.view,
          col: filter.col,
          default: `${format(dateEnd, 'yyyy-MM-dd hh:mm:ss')}`,
          user_definable: true,
          op: 'le',
        }

        return
      } catch (err) {}
    }

    if (op === 'in') {
      if (!Array.isArray(target)) {
        target = target.split(',')
      }
    }

    const dataType = columnToTypeMap[filter.col] || ''

    if (dataType.toLowerCase().includes('date') && target instanceof Date) {
      target = format(target, 'yyyy-MM-dd hh:mm:ss')
    }

    if (dataType.toLowerCase() === 'tinyint(1)') {
      if (target === '0') {
        target = false
      } else {
        target = Boolean(target)
      }
    }

    body.where[whereName] = {
      view: filter.view,
      col: filter.col,
      default: target,
      user_definable: true,
      op: op,
    }
  })

  Object.entries(values.report_sorts).forEach(([col, val]) => {
    body.order.push({
      view: val.view,
      col: col,
      asc: val.dir === 'asc',
    })
  })

  return body
}

export function convertApiToForm({ reportData, dataDomains }) {
  const values = {
    data_domains: [],
    report_columns: {},
    report_sorts: {},
    report_filters: [],
    name: reportData?.name,
    public: reportData?.public,
  }

  let i = 0

  Object.entries(reportData.columns).forEach(([view, cols]) => {
    Object.keys(cols).forEach((col) => {
      values.report_columns[col] = {
        col,
        position: i++,
        view: view,
      }
    })
  })

  reportData.order.forEach((sort) => {
    values.report_sorts[sort.col] = {
      dir: sort.asc ? 'asc' : 'desc',
      view: sort.view,
    }
  })

  i = 0

  const betweens = {}

  Object.entries(reportData.where).forEach(([name, filter]) => {
    if (name.slice(0, 13) === 'whereBETWEEN_') {
      if (name.slice(-4) === '_end') {
        betweens[filter.col] = {
          ...betweens[filter.col],
          end: filter,
          name,
        }
      } else if (name.slice(-6) === '_start') {
        betweens[filter.col] = {
          ...betweens[filter.col],
          start: filter,
          name,
        }
      }

      // handle betweens later
      return
    }

    let target = filter.default
    if (typeof target === 'string' && target.match(/\d{4}-\d{2}-\d{2}/gm)) {
      target = new Date(target)
    }

    values.report_filters.push({
      col: filter.col,
      action: filter.op,
      target: target,
      index: i++,
      view: filter.view,
      name,
    })
  })

  Object.entries(betweens).forEach(([_, filter]) => {
    const { start, end } = filter

    const startDate = utcToLocalDate(start.default)
    let endDate = utcToLocalDate(end.default)

    endDate = add(endDate, { seconds: -1 })

    values.report_filters.push({
      col: start.col,
      action: 'between',
      target: [startDate, endDate],
      index: i++,
      view: start.view,
      name: filter.name,
    })
  })

  const views = reportData.domains.map((d) => d.view)

  values.data_domains = dataDomains.filter((dd) => views.includes(dd.view))

  return values
}

export const getJoinableDomainIds = (domains) => {
  let validDomainIds = []

  domains.forEach((domain) => {
    validDomainIds.push(domain.id.toString())

    const joins = domain._embedded?.joins || {}
    validDomainIds = validDomainIds.concat(Object.keys(joins))
  })

  return validDomainIds
}

export const validateJoins = (domains) => {
  if (!Array.isArray(domains) || domains.length < 2) return []

  const invalidJoins = []

  // even if the domain is removed, it should still appear in joinable ids
  for (let i = 0; i < domains.length; i += 1) {
    const targetDomain = domains[i]
    const _domains = domains.filter((domain) => domain.id !== targetDomain.id)
    const joinableIds = getJoinableDomainIds(_domains)
    if (!joinableIds.includes(targetDomain.id)) {
      invalidJoins.push(targetDomain)
    }
  }

  return invalidJoins
}

export const filtersToRenderingData = (filters = {}, columnToTypeMap = {}) => {
  const renderingData = []

  Object.entries(filters).forEach(([name, filter]) => {
    const type = columnToTypeMap[filter.col] || ''
    let dataType = 'text'
    let defaultValue = filter.default

    if (type.includes('varchar')) {
      dataType = 'text'
    } else if (type === 'tinyint(1)') {
      dataType = 'checkbox'
      defaultValue = Boolean(defaultValue)
    } else if (type.includes('date')) {
      dataType = 'date'
    } else if (type.includes('int')) {
      dataType = 'number'
    }

    renderingData.push({
      display: filter.col,
      default_value: defaultValue,
      data_type: dataType,
      field: name,
    })
  })

  return renderingData
}
