import { COLOR_SHADES } from 'utils/constants'

const components = {
  MuiBox: {
    variants: [
      {
        props: {
          fullHeight: true,
        },
        style: {
          height: '100%',
        },
      },
    ],
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        boxShadow: 'none',
        '&.Mui-disabled': {
          color: COLOR_SHADES.DARK_GRAY[1],
        },
        '&:focus': {
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
        },
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: COLOR_SHADES.LIGHT_GRAY[0],
        },
      },
      outlined: {
        border: '2px solid',
        '&.Mui-disabled': {
          border: '2px solid',
          borderColor: COLOR_SHADES.LIGHT_GRAY[0],
        },
        '&:hover': {
          border: '2px solid',
        },
      },
    },
    variants: [
      {
        props: {
          size: 'action-header',
        },
        style: {
          height: '32px',
          width: '120px',
        },
      },
      {
        props: {
          size: 'action-footer',
        },
        style: {
          height: '40px',
          width: '132px',
        },
      },
      {
        props: {
          size: 'dashboard-list-action',
        },
        style: {
          height: '24px',
          width: '80px',
        },
      },
      {
        props: {
          bgcolor: 'white',
        },
        style: {
          backgroundColor: COLOR_SHADES.WHITE[0],
        },
      },
    ],
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        border: '2px solid',
        borderColor: COLOR_SHADES.DARK_GRAY[1],
        '&.Mui-selected': {
          backgroundColor: 'inherit',
          borderColor: 'inherit',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: COLOR_SHADES.WHITE[0],
        '&.Mui-disabled': {
          backgroundColor: COLOR_SHADES.MEDIUM_GRAY[1],
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: COLOR_SHADES.DARK_GRAY[1],
        textTransform: 'capitalize',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: COLOR_SHADES.LIGHT_GRAY[0],
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiCardActions: {
    defaultProps: {
      sx: {
        justifyContent: 'flex-end',
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h1',
        sx: { marginBottom: '32px' },
      },
      subheaderTypographyProps: {
        variant: 'h2',
      },
    },
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        boxShadow:
          'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
        backgroundImage: 'none',
        margin: 'auto',
        position: 'relative',
        zIndex: 10,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: '300',
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: '64px',
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: '6px',
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: 'transparent',
        '& > *': {
          backgroundColor: 'transparent',
        },
      },
      switchHeader: {
        marginTop: '2px',
        marginBottom: '4px',
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: '32px',
        height: '32px',
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        '& h4': {
          fontSize: '2.125rem',
          fontWeight: 400,
        },
      },
      timeHeader: {
        '& h3': {
          fontSize: '3rem',
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        '& h2': {
          fontSize: '3.75rem',
          fontWeight: 300,
        },
      },
    },
  },
  MuiDateRangePickerDay: {
    variants: [
      {
        props: { outsideCurrentMonth: true, isHighlighting: true },
        style: {
          backgroundColor: '#76BC2133',
        },
      },
      {
        props: { isHighlighting: true, beginningOfMonth: true },
        style: {
          borderRadius: 0,
          margin: 0,
        },
      },
      {
        props: { isHighlighting: true, endOfMonth: true },
        style: {
          borderRadius: 0,
          margin: 0,
        },
      },
    ],
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        '& h4': {
          fontSize: '2.125rem',
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
      },
    },
  },
  MuiTabs: {
    defaultProps: {
      TabIndicatorProps: { style: { background: COLOR_SHADES.GREEN[0] } },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: COLOR_SHADES.DARK_BLUE[0],
        '&.Mui-selected': {
          color: COLOR_SHADES.GREEN[0],
        },
      },
    },
  },
}

export default components
