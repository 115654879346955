import React from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'

import SubTitleSubLayout from 'layouts/Landing/sublayouts/SubHeader'

import DeliveryAddress from 'components/collateral/DeliveryAddress'

function DeliveryAddressPage() {
  return (
    <SubTitleSubLayout title="Set Recipient">
      <CssBaseline />
      <Container maxWidth="md" sx={{ pb: 16 }}>
        <DeliveryAddress />
      </Container>
    </SubTitleSubLayout>
  )
}

export default DeliveryAddressPage
