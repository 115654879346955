import { useContext } from 'react'
import { ModalContext } from 'contexts/component/ModalContext'

const useModal = () => {
  const context = useContext(ModalContext)

  if (!context)
    throw new Error(
      'SavedSearModalContext must be placed within SavedSearModalProvider'
    )

  return context
}

export default useModal
