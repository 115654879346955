import { useEffect, useMemo, useState } from 'react'

import debounce from 'helpers/node/debounce'

const useDimensions = ({ ref }) => {
  const [dimensions, setDimensions] = useState({})

  const elementObserver = useMemo(() => {
    return new ResizeObserver(() => {
      debounce(() => {
        if (!ref?.current) return
        setDimensions({
          height: ref.current.clientHeight,
          width: ref.current.clientWidth,
        })
      }, 1000)()
    })
  }, [ref])

  useEffect(() => {
    if (!ref?.current) return
    const element = ref.current

    setDimensions({
      height: element.clientHeight,
      width: element.clientWidth,
    })

    elementObserver.observe(element)
    return () => {
      elementObserver.unobserve(element)
    }
  }, [ref, elementObserver])

  return {
    ...dimensions,
  }
}

export default useDimensions
