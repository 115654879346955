import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import deepEqual from 'deep-equal'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import ToggleButton from '@mui/material/ToggleButton'
import NotesDrawer from 'components/notes/NotesDrawer'
import { useOrderNotes } from 'hooks/useOrders'
import OrderDetailDrawer from 'components/notes/NotesDrawer/OrderDetail'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'

import ResultsList from 'components/order_detail/ResultsList'
import AsyncTextField from 'components/common/AsyncTextField'

import DataListLayout from 'layouts/PureDataList'

import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'

import SearchIcon from '@mui/icons-material/Search'

import ExpandedList from 'components/common/ExpandedList'
import OrderDetailExpandedList from 'components/common/ExpandedList/OrderDetail'
import OrderApproveModal from 'components/order_approval/OrderApproveModal'
import OrderHoldModal from 'components/order_approval/OrderHoldModal'

import { useOrderDetail, useOrderStatus } from 'hooks/useOrders'
import useDataList from 'hooks/useDataList'
import useConfiguration from 'hooks/useConfiguration'
import useModal from 'hooks/context/useModal'
import useUserData from 'hooks/users/useUserData'

import { PRIVILEGES, MODULE_NAMES } from 'utils/constants'
import { CircularProgress } from '@mui/material'
import OrderProgress from 'components/order_history/OrderProgress'

function OrderDetail() {
  const location = useLocation()
  const navigate = useNavigate()
  const { formatUserTimeZone } = useUserData()

  const formatResponseDate = (dateString) => {
    if (dateString && dateString != null) {
      return formatUserTimeZone(new Date(dateString))
    }
  }

  const { getModule } = useConfiguration()

  const metaSearchKeys = useMemo(() => {
    const docApproval = getModule(10)

    const config = docApproval?.configurations.find(
      (c) => c.configuration_type === 'module_configurations'
    )

    if (config)
      return config.settings.available_metadata_fields.map(
        (field) => `_.metadata.${field}`
      )
    return []
  }, [getModule])

  const { setComponent, setOpen: setModalOpen, setModalProps } = useModal()
  const {
    updatePath,
    navigation: { currentPath },
    hasPrivilege,
  } = useConfiguration()

  const [filters, setFilters] = useState({})
  const [open, setOpen] = useState(false)

  const { orderId } = useParams()
  const { order, loading } = useOrderDetail(orderId)
  const { order: heldOrder } = useOrderStatus(orderId, 'On Hold')

  const hideHold =
    heldOrder ||
    order.status !== 'Pending Approval' ||
    !hasPrivilege(PRIVILEGES.HOLD_ORDER, MODULE_NAMES.ORDER_APPROVAL, {
      program: order?.program_id,
    })
  const hideApprove =
    !['Pending Approval', 'On Hold'].includes(order.status) ||
    !hasPrivilege(PRIVILEGES.APPROVE_ORDER, MODULE_NAMES.ORDER_APPROVAL, {
      program: order?.program_id,
    })

  const { notes, notesLoading, makeNote } = useOrderNotes(orderId)

  const prepareRowData = useCallback((row) => {
    const rowData = {
      ...row,
      date_created: formatResponseDate(row.datetime_created),
      status: !row.is_on_hold
        ? { status: row.status, display: row.status }
        : { status: row.status, display: 'On Hold' },
    }

    delete rowData.datetime_created

    return rowData
  }, [])

  const {
    dataList,
    columns,
    dataListProps: { getDataDisplay },
  } = useDataList({
    baseRowData: [order],
    presetName: 'orderHistory',
    prepareRowData,
  })

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    const newFilters = { ...filters }

    if (name === 'search' && value) {
      newFilters['searchfields'] = [
        'id',
        'account_id',
        'alternate_id',
        ...metaSearchKeys,
      ].join('|')

      newFilters[name] = value
    } else if (name === 'search' && !value) {
      delete newFilters['search']
      delete newFilters['searchfields']
    }

    setFilters(newFilters)
  }

  const handleShowModal = (variant) => {
    if (variant === 'hold') {
      setComponent(OrderHoldModal)
      setModalProps({
        size: 'sm',
        scrollable: false,
        title: undefined,
        subTitle: undefined,
        footerButtonProps: undefined,
        orderId,
        mutate: () => navigate(`/command-center/order-approval`),
      })
      setModalOpen(true)
    } else {
      setComponent(OrderApproveModal)
      setModalProps({
        size: 'sm',
        scrollable: false,
        title: undefined,
        subTitle: undefined,
        footerButtonProps: undefined,
        orderId,
        mutate: () => navigate(`/command-center/order-approval`),
      })
      setModalOpen(true)
    }
  }

  function HeaderLayoutButtons() {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          '& > .MuiButton-root': { my: 'auto' },
        }}
      >
        {!hideHold && (
          <Button
            size="action-header"
            color="secondary"
            variant="contained"
            onClick={() => handleShowModal('hold')}
            sx={{ mr: 4 }}
          >
            Hold
          </Button>
        )}
        {!hideApprove && (
          <Button
            size="action-header"
            color="primary"
            variant="contained"
            onClick={() => handleShowModal('approve')}
            sx={{ mr: 4 }}
          >
            Approve
          </Button>
        )}
      </Box>
    )
  }

  const handleSubmitNotes = async (
    values,
    { setSubmitting, setFieldValue }
  ) => {
    await makeNote(values.note)
    setFieldValue('note', '')
    setSubmitting(false)
  }

  // update breadcrumbs based on where the user is from
  useEffect(() => {
    const newPath = [
      location.state?.from || {
        display: 'Order Approval',
        href: '/command-center/order-approval',
      },
      {
        display: `Order Details`,
        href: `/command-center/order-details/${orderId}`,
      },
    ]

    if (!deepEqual(newPath, currentPath)) updatePath(newPath)
  }, [currentPath, location, orderId])

  useEffect(() => {
    try {
      document.querySelector('main').scrollTop = 0
    } catch (err) {
      //
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          height: '72px',
          background: 'white',
          display: 'flex',
          borderBottom: '1px solid',
          borderColor: 'darkblue.light',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1" sx={{ ml: 8, my: '28px' }}>
          {order && `Order ID ${orderId}`}
        </Typography>
        {!order.is_approved && <HeaderLayoutButtons />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Box sx={{ px: 8, flex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              py: 5,
              width: '100%',
            }}
          >
            <Box sx={{ width: '50%' }}>
              <AsyncTextField
                fullWidth
                size="small"
                label="Search"
                name="search"
                onChange={handleFilterChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box sx={{ display: 'flex' }}>
              <ToggleButton
                sx={{ height: '40px', width: '64px', m: 'auto' }}
                selected={!open}
                onClick={() => {
                  setOpen(!open)
                }}
              >
                <ChatBubbleIcon />
              </ToggleButton>
            </Box>
          </Box>

          <OrderProgress order={order} />

          <Box sx={{ py: 5 }}>
            <Card sx={{ width: '100%' }}>
              {!order?.error && loading ? (
                <Box sx={{ display: 'flex', width: '100%', height: '350px' }}>
                  <Box component="span" sx={{ m: 'auto' }}>
                    <CircularProgress />
                  </Box>
                </Box>
              ) : (
                <>
                  {!order?.error ? (
                    <>
                      <ExpandedList
                        expanded="true"
                        columns={columns}
                        rowData={dataList?.[0]}
                        getDataDisplay={getDataDisplay}
                        visibleColumns={['error']}
                        ExpandedListComponent={OrderDetailExpandedList}
                        collapseDetails
                      />
                    </>
                  ) : (
                    <Typography sx={{ p: 8 }}>
                      {order.error.response?.data.display_message ||
                        'Order not found.'}
                    </Typography>
                  )}
                </>
              )}
            </Card>

            <DataListLayout>
              <ResultsList
                orderId={orderId}
                filters={filters}
                sidebarOpen={open}
                setSidebarOpen={setOpen}
              />
            </DataListLayout>
          </Box>
        </Box>

        <NotesDrawer
          notes={notes}
          loading={notesLoading}
          open={open}
          setOpen={setOpen}
          label="Order ID"
          id={order?.id}
          handleSubmit={handleSubmitNotes}
          NotesDrawerComponent={OrderDetailDrawer}
        />
      </Box>
    </>
  )
}

function OrderDetailWrapper() {
  const { currentModule } = useConfiguration()

  // currentModule is null by default
  if (currentModule === null) return <div></div>

  return <OrderDetail />
}

export default OrderDetailWrapper
