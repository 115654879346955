import React, { useState, useCallback, useEffect } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'

import DeleteOutlined from '@mui/icons-material/DeleteOutlineOutlined'

import useModal from 'hooks/context/useModal'
import { useUserData } from 'hooks/users'
import useNotification from 'hooks/context/useNotification'
import { useLibraryPreview } from 'hooks/library'
import saveData from 'helpers/browser/saveData'
import useConfiguration from 'hooks/useConfiguration'

import { PRIVILEGES, PRINT_ASSET_MIME_TYPES } from 'utils/constants'

import PreviewModal from './PreviewModal'

function VersionsTable({
  versions,
  activeVersion,
  updateLibraryItemVersion,
  updateLibraryItem,
  dataLoading,
}) {
  const [activeValue, setActiveValue] = useState(activeVersion)
  const { setOpen: setModalOpen, setModalProps } = useModal()
  const { formatUserTimeZone } = useUserData()
  const { setError, setBasicNotification } = useNotification()
  const { getLibraryItemPreview } = useLibraryPreview({})
  const { hasPrivilege } = useConfiguration()

  // document version to render in modal
  const [previewModalVersion, setPreviewModalVersion] = useState(null)
  const [showPreviewModal, setShowPreviewModal] = useState(false)

  const handleActiveVersionChange = useCallback(
    (newVersion) => {
      const newActiveVersion = versions.find(
        (version) => `${version.version}` === `${newVersion}`
      )

      if (!newActiveVersion)
        return setError('Active version could not be changed.')

      const modalProps = {
        title: 'Change Active Version',
        size: 'sm',
        scrollable: false,
        hideButtonsBorder: true,
        children: (
          <Box>
            <Typography mb={6}>
              Are you sure you want to change the active version of this
              document?
            </Typography>
            <Box>
              <Typography fontWeight={600} mr={1}>
                New active version:{' '}
              </Typography>
              <Typography sx={{ wordBreak: 'break-word' }}>
                {newActiveVersion?._embedded?.document?.filename}
              </Typography>
            </Box>
          </Box>
        ),
        footerButtonProps: [
          {
            children: 'Change',
            color: 'primary',
            variant: 'contained',
            size: 'action-header',
            onClick: async () => {
              try {
                await updateLibraryItem({
                  updateData: { default_version: parseInt(newVersion, 10) },
                  isForm: false,
                })
                setActiveValue(newVersion)
                setBasicNotification(
                  `${
                    newActiveVersion.name || 'Document'
                  } has been set to Version #${newVersion}!`
                )
              } catch (err) {
                setError(
                  err?.response?.data?.display_message ||
                    err?.message ||
                    'Unable to update document version.'
                )
              } finally {
                setModalOpen(false)
              }
            },
          },
          {
            children: 'Cancel',
            color: 'primary',
            variant: 'outlined',
            size: 'action-header',
            onClick: () => setModalOpen(false),
          },
        ],
      }

      setModalProps(modalProps)
      setModalOpen(true)
    },
    [
      setModalOpen,
      setModalProps,
      versions,
      activeValue,
      updateLibraryItemVersion,
    ]
  )

  const handleVersionDelete = useCallback(
    (version) => {
      const foundVersion = versions.find((v) => `${v.version}` === `${version}`)

      if (!foundVersion) return setError('Version could not be deleted.')

      const modalProps = {
        title: 'Delete Version?',
        size: 'sm',
        scrollable: false,
        hideButtonsBorder: true,
        children: (
          <Box>
            <Typography mb={6}>
              Are you sure you want to delete this version of this document?
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography fontWeight={600} mr={1}>
                The following version will be removed:{' '}
              </Typography>
              <Typography>{version}</Typography>
            </Box>
          </Box>
        ),
        footerButtonProps: [
          {
            children: 'Delete',
            color: 'primary',
            variant: 'contained',
            size: 'action-header',
            onClick: async () => {
              try {
                await updateLibraryItemVersion({
                  version: version,
                  updateData: { active: 0 },
                })
                setBasicNotification(
                  `${
                    foundVersion.name || 'Document'
                  } has been successfully deleted!`
                )
              } catch (err) {
                setError(
                  err?.response?.data?.display_message ||
                    err?.message ||
                    'Unable to delete document version.'
                )
              } finally {
                setModalOpen(false)
              }
            },
          },
          {
            children: 'Cancel',
            color: 'primary',
            variant: 'outlined',
            size: 'action-header',
            onClick: () => setModalOpen(false),
          },
        ],
      }

      setModalProps(modalProps)
      setModalOpen(true)
    },
    [
      setModalOpen,
      setModalProps,
      versions,
      activeValue,
      updateLibraryItemVersion,
    ]
  )

  const handleVersionDownload = useCallback(
    async (version) => {
      try {
        const foundVersion = versions.find(
          (v) => `${v.version}` === `${version}`
        )
        const filename = foundVersion?._embedded?.document?.filename
        if (!filename) throw new Error('Unable to download document.')

        const data = await getLibraryItemPreview({
          libItem: foundVersion,
          versionNum: version,
        })

        saveData(data, filename, foundVersion.mime_type)
        setBasicNotification(
          `${filename} has been successfully download to your computer!`
        )
      } catch (err) {
        setError(
          err?.response?.data?.display_message ||
            err?.message ||
            'Unable to download document.'
        )
      }
    },
    [activeVersion, versions]
  )

  const handlePreview = (version) => {
    try {
      const foundVersion = versions.find((v) => `${v.version}` === `${version}`)

      setPreviewModalVersion(foundVersion)
      setShowPreviewModal(true)
    } catch (err) {
      setError('Unable to preview document.')
    }
  }

  useEffect(() => {
    setActiveValue(activeVersion)
  }, [activeVersion])

  return (
    <>
      <PreviewModal
        open={showPreviewModal}
        setOpen={() => {
          setShowPreviewModal(false)
          setPreviewModalVersion(null)
        }}
        libraryItem={previewModalVersion}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="versions table">
          <TableHead>
            <TableRow>
              <TableCell>Active</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>File</TableCell>
              <TableCell>Created</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versions
              .slice()
              .reverse()
              .map((version) => (
                <TableRow
                  key={version.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Radio
                      checked={`${activeValue}` === `${version.version}`}
                      onChange={() =>
                        handleActiveVersionChange(version.version)
                      }
                      value={version.version}
                      name="radio-buttons"
                      disabled={dataLoading}
                    />
                  </TableCell>
                  <TableCell>{version.version}</TableCell>
                  <TableCell>
                    {version._embedded?.document?.filename || 'N/A'}
                  </TableCell>
                  <TableCell>
                    {formatUserTimeZone(new Date(version.datetime_created))}
                  </TableCell>
                  <TableCell
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        sx={{ mr: 2 }}
                        onClick={() => handlePreview(version.version)}
                        disabled={
                          !Object.values(PRINT_ASSET_MIME_TYPES).includes(
                            version.mime_type
                          ) && !version.mime_type.includes('text')
                        }
                      >
                        Preview
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        sx={{ mr: 2 }}
                        onClick={() => handleVersionDownload(version.version)}
                      >
                        Download
                      </Button>
                      {hasPrivilege(PRIVILEGES.MODIFY_LIBRARY) && (
                        <IconButton
                          onClick={() => handleVersionDelete(version.version)}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default VersionsTable
