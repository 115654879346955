import React, { useEffect, useState, useMemo, useCallback } from 'react'
import format from 'date-fns/format'
import styled from 'styled-components/macro'

import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandedList from 'components/common/ExpandedList'
import CorrespondenceExpandedList from 'components/common/ExpandedList/Correspondence'
import MailTrackingModal from 'components/correspondence/MailTrackingModal'

// local components
import ItemCheckBox from './components/ItemCheckbox'
import HoverButtons from './components/HoverButtons'
import FixedButtons from './components/FixedButtons'

import useDataListContext from 'hooks/context/useDataListContext'
import useConfiguration from 'hooks/useConfiguration'
import useModal from 'hooks/context/useModal'

import { COLORS, PRIVILEGES } from 'utils/constants'
import DataListItemWrapper from './components/DataListItemWrapper'

const StyledDataListItem = styled(Box)`
  .hover-buttons {
    display: none;
  }

  &:hover {
    .hover-buttons {
      ${({ showButtons }) => showButtons && 'display: block;'}
    }
  }
`

function NormalItem(props) {
  const {
    index,
    rowData,
    columns,
    fixedButtonProps = [],
    getDataDisplay,
    multiSelect,
    statusColName,
    expandedListProps,
    visibleColumns,
    sharedSx,
  } = props
  const [expanded, setExpanded] = useState(false)
  const [checked, setChecked] = useState(false)
  const [status, setStatus] = useState({ color: COLORS.GREEN })
  const { hasPrivilege } = useConfiguration()
  const { setOpen: setModalOpen, setModalProps, setComponent } = useModal()

  const getStatusMessage = () => {
    if (rowData?.record_type === 'notification') {
      const { datetime_sent } = rowData?._embedded?.notification ?? {}
      let sentDatetimeDisplay = datetime_sent

      try {
        sentDatetimeDisplay = format(
          new Date(datetime_sent),
          'MM/dd/yyyy hh:mm a'
        )
      } catch (err) {
        sentDatetimeDisplay = datetime_sent
      }

      return `Sent at ${sentDatetimeDisplay}, ${status.display}`
    }

    return undefined
  }

  const statusTitle = status.success ? 'DELIVERY SUCCESSFUL' : 'DELIVERY FAILED'
  const statusMessage = getStatusMessage()

  const darken = expanded || checked

  const shownColumns = visibleColumns
    .map((col) => columns.find((c) => c.id === col))
    .filter((c) => c)

  const { color, success } = status ?? { color: 'green.main' }
  const checkboxColor = success ? 'success' : 'warning'
  const showButtons = !expanded

  const getBorderColor = () => {
    if (darken) return color

    return 'background.paper'
  }

  useEffect(() => {
    if (rowData?.[statusColName]) {
      setStatus(rowData[statusColName])
    }
  }, [rowData])

  function CheckBox() {
    const { allChecked, checkedCount } = useDataListContext()

    useEffect(() => {
      if (allChecked === true) setChecked(true)
      if (checkedCount === 0) setChecked(false)
    }, [allChecked, checkedCount])

    return (
      <>
        {multiSelect && (
          <ItemCheckBox
            index={index}
            handleItemCheck={setChecked}
            color={checkboxColor}
          />
        )}
      </>
    )
  }

  const showTrackingModal = useCallback(
    ({ docImb }) => {
      setComponent(MailTrackingModal)
      setModalProps({
        docImb,
      })
      setModalOpen(true)
    },
    [setComponent, setModalProps, setModalOpen]
  )

  const expandedButtonProps = useMemo(() => {
    const showTrack =
      hasPrivilege(PRIVILEGES.VIEW_MAIL_TRACKING) && rowData?.tracking_imb

    return props.actionButtonProps.concat(
      showTrack
        ? {
            children: 'Track',
            variant: 'outlined',
            color: 'primary',
            size: 'action-header',
            sx: {
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'hover.opaque',
              },
            },
            onClick: () => showTrackingModal({ docImb: rowData?.tracking_imb }),
          }
        : []
    )
  }, [props.actionButtonProps, hasPrivilege, showTrackingModal, rowData])

  return (
    <StyledDataListItem
      showButtons={showButtons}
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        borderBottomColor: 'lightgray.main',
        bgcolor: darken && 'hover.main',
        color: 'darkgray.main',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          borderLeftColor: !darken && 'hover.main',
          bgcolor: !darken && 'hover.main',
        },
      }}
    >
      <ListItem
        secondaryAction={
          <>
            <IconButton
              edge="end"
              aria-label="expand"
              onClick={(e) => {
                e.stopPropagation()
                setExpanded(!expanded)
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </>
        }
        onClick={() => setExpanded(!expanded)}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          overflow: 'hidden',
          my: 'auto',
          width: '100%',
          '&:hover': {
            borderColor: expanded && 'hover.main',
            bgcolor: expanded && 'hover.main',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {multiSelect && (
            <DataListItemWrapper index={0}>
              <Box>{<CheckBox />}</Box>
            </DataListItemWrapper>
          )}
          {shownColumns.map((col, index) => (
            <DataListItemWrapper
              index={index + (multiSelect ? 1 : 0)}
              key={col.id}
            >
              <Box
                sx={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  flexDirection: 'column',
                  color: col.id === statusColName && color,
                  width: '100%',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    my: 'auto',
                    fontSize: 14,
                  }}
                >
                  {getDataDisplay(rowData, col.id, statusColName)}
                </Box>
              </Box>
            </DataListItemWrapper>
          ))}
          {fixedButtonProps.length > 0 && (
            <DataListItemWrapper index={shownColumns.length + 1}>
              <Box>
                <FixedButtons {...props} />
              </Box>
            </DataListItemWrapper>
          )}
        </Box>
        <HoverButtons {...props} actionButtonProps={expandedButtonProps} />
      </ListItem>
      <ExpandedList
        {...expandedListProps}
        expanded={expanded}
        setExpanded={setExpanded}
        columns={columns}
        rowData={rowData}
        visibleColumns={visibleColumns}
        getDataDisplay={getDataDisplay}
        statusTitle={statusTitle}
        statusMessage={statusMessage}
        ExpandedListComponent={CorrespondenceExpandedList}
        hoverButtons={
          <HoverButtons
            expanded
            {...props}
            actionButtonProps={expandedButtonProps}
          />
        }
      />
    </StyledDataListItem>
  )
}

export default NormalItem
