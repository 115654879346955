import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import styled from 'styled-components/macro'

import MuiAlert from '@mui/material/Alert'
import spacing from '@mui/system/spacing'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import ScrollableBox from 'components/styled/ScrollableBox'
import ActionButtons from 'components/common/ActionButtons'
import Field from './Field'

const Alert = styled(MuiAlert)(spacing)

const SubActionButton = styled(Typography)`
  float: right;
  margin-top: ${({ theme }) => `-${theme.rh(16)}`};
  margin-bottom: ${({ theme }) => theme.rh(28)};
  height: ${({ theme }) => theme.rh(16)};
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`

function FormRenderer({
  renderingData,
  headerActionProps,
  subActionProps,
  footerActionProps,
  innerRef,
  validate,
  handleSubmit,
  initialData,
  scrollHeight,
  validationSchema,
  loading,
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
  isCorrespondence,
  scrollRef,
  validateOnChange = false,
  disableFooterPadding = false,
  labelMaxLength,
}) {
  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    const newInitialValues = {}

    renderingData.forEach((field) => {
      let initialValue =
        initialData?.[field.field] ?? field?.default_value ?? ''

      if (!initialValue && field.field_type === 'date')
        initialValue = [undefined, undefined]

      newInitialValues[field.field] = initialValue
    })

    setInitialValues(newInitialValues)
  }, [renderingData, initialData])

  const Component = scrollHeight ? ScrollableBox : Box

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={innerRef}
      enableReinitialize
      validate={validate}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({ errors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {headerActionProps && (
            <Box sx={{ pt: 3, pb: !subActionProps && 3 }}>
              <ActionButtons actionProps={headerActionProps} />
            </Box>
          )}
          {subActionProps && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 3 }}>
              {subActionProps && <SubActionButton {...subActionProps} />}
            </Box>
          )}
          <Component
            sx={{
              overflowY: scrollHeight && 'auto',
              height: scrollHeight ?? 'auto',
              width: '100%',
              py: scrollHeight && 4,
            }}
          >
            <Grid container columns={12}>
              {renderingData.map((fieldData, fieldIndex) => (
                <Grid
                  item
                  xs={fieldData.colSpan || 12}
                  key={fieldData.field}
                  sx={{ py: 3 }}
                >
                  <Field
                    key={`${fieldData.path}-${fieldIndex}`}
                    fieldData={fieldData}
                    gutterBottom={fieldIndex !== renderingData.length - 1}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onChange}
                    loading={loading}
                    isCorrespondence={isCorrespondence}
                    scrollRef={scrollRef}
                    labelMaxLength={labelMaxLength}
                  />
                </Grid>
              ))}
            </Grid>
          </Component>
          {footerActionProps && (
            <Box sx={{ py: disableFooterPadding ? 0 : 8 }}>
              <ActionButtons footer actionProps={footerActionProps} />
            </Box>
          )}
        </form>
      )}
    </Formik>
  )
}

export default FormRenderer
