import React, { useState, useEffect, useMemo, useCallback } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import DataList from 'components/common/DataList'
import Header from './Header'
import Tabs from './Tabs'
import BulkPreview from 'components/common/BulkPreview'
import UserMaintenancePreview from 'components/common/BulkPreview/components/PreviewContent/UserMaintenance'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import { useUsers } from 'hooks/users'

import Typography from '@mui/material/Typography'

import getUserColor from 'helpers/css/getUserColor'

function UserDataList() {
  // datalist
  const [sortList, setSortList] = useState({})
  const [filterList, setFilterList] = useState({})
  const [newUser, setNewUser] = useState({})

  // tabs
  const [tabIndex, setTabIndex] = useState(0)

  const {
    page,
    pageSize,
    setTotal,
    setTotalPages,
    setPageSize,
    initialized,
    layoutType,
    setLayoutType,
    activeIndex,
    setActiveIndex,
  } = useDataListContext()

  const documentsHookParams = {
    'paging[page]': page,
    'paging[page_size]': pageSize,
    is_active: tabIndex === 0,
    embed: 'roles|notes',
    ...filterList,
    ...sortList,
  }

  const {
    users,
    total,
    loading,
    totalPages,
    pageSize: docPageSize,
    updateUser,
    deleteUser,
    addUser,
  } = useUsers(documentsHookParams)

  const updatedUsers = useMemo(() => {
    return [newUser, ...users].filter((user) => user)
  }, [users, newUser])

  const prepareRowData = (row) => ({
    ...row,
    color: tabIndex === 0 && getUserColor(),
  })

  const getColumnDisplay = useCallback((col) => {
    const editDisplay = (display) =>
      display
        .replace(/_/gm, ' ')
        .replace(/.metadata./gm, '')
        .toUpperCase()

    return editDisplay(col)
  }, [])

  const getRowDisplay = useCallback((rowData, columnName) => {
    return rowData[columnName]?.toString() ?? 'N/A'
  }, [])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: updatedUsers ?? [],
    presetName: 'userMaintenance',
    setSortList,
    prepareRowData,
    getColumnDisplay,
    getRowDisplay,
  })

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages) // assume total pages updates alongside total
      setPageSize(docPageSize, false)
    }
  }, [total, totalPages])

  useEffect(() => {
    setActiveIndex(0, true)
    setLayoutType('usermaintenance')
  }, [])

  const dataListDisplay = useMemo(() => {
    const showDataList = loading || dataList?.length > 0

    return showDataList ? (
      <Card sx={{ boxShadow: 2, mb: 2 }}>
        <BulkPreview
          dataList={dataList}
          dataListProps={dataListProps}
          loading={loading}
          columns={columns}
          PreviewComponent={UserMaintenancePreview}
          excludeFromBlur
          tabIndex={tabIndex}
          updateUser={updateUser}
          deleteUser={deleteUser}
          addUser={addUser}
          setNewUser={setNewUser}
          setTabIndex={setTabIndex}
          height="65vh"
          defaultText="Select a user to continue"
          forceIndexChange={(activeIndex, dl) => {
            return activeIndex + 2 === dl.length
          }}
          dataListComponent={
            <DataList
              {...dataListProps}
              dataList={dataList}
              columns={columns}
              loading={loading}
              validating={initialized}
              visibleColumns={[]}
              height="65vh"
              layoutType={layoutType}
              multiSelect={false}
              showColumns={false}
              scrollableBoxSx={{
                overflowX: 'hidden',
              }}
            />
          }
        />
      </Card>
    ) : (
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Typography color="darkgray.light" sx={{ m: 'auto' }}>
          No users found.
        </Typography>
      </Box>
    )
  }, [
    dataListProps,
    dataList,
    columns,
    loading,
    initialized,
    layoutType,
    tabIndex,
    updateUser,
    setTabIndex,
    deleteUser,
  ])

  return (
    <>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Tabs
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
          layoutType={layoutType}
          setLayoutType={setLayoutType}
          items={users}
        />
        <Box sx={{ px: 8, pt: 6, width: '100%' }}>
          <Header
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            items={users}
            documentsLoading={loading}
            filterList={filterList}
            setFilterList={setFilterList}
            layoutType={layoutType}
            activeIndex={activeIndex}
            setNewUser={setNewUser}
            newUser={newUser}
          />
          {dataListDisplay}
        </Box>
      </Box>
    </>
  )
}

export default UserDataList
