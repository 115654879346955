import { useMemo } from 'react'
import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import fetcher from 'utils/fetcher'
import axios from 'utils/axios'
import { REPORT_USAGE } from 'utils/constants'
import { convertFormForAPI } from 'utils/report'

import useDataDomains from './useDataDomains'
import useAuth from 'hooks/useAuth'

const useReports = ({ tabIndex, ddOnly, ddParams, ...params }) => {
  const { user } = useAuth()
  const { data, isValidating, mutate } = useSWR(
    params
      ? `/reports?${urlSerialize({
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )
  const { columnToTypeMap } = useDataDomains({ embed: 'columns' })

  const {
    data: ddData,
    isValidating: ddLoading,
    mutate: ddMutate,
  } = useSWR(
    ddParams
      ? `/ddreports?${urlSerialize({
          ...ddParams,
        })}`
      : '/ddreports',
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const reports = useMemo(() => {
    if (tabIndex === 1 || tabIndex === 2 || ddOnly) {
      let ddReports =
        ddData?._embedded?.ddreport?.map((report) => ({
          ...report,
          title: report.name,
          isDD: true,
        })) || []

      if (!ddOnly) {
        ddReports = ddReports.filter(
          (report) => report.public === (tabIndex === 1 ? false : true)
        )
      }

      return ddReports
    }

    let excludedUsages = []

    if (!user.isMPXAdmin) {
      excludedUsages.push(REPORT_USAGE.INTERNAL)
    }

    let apiReports = data?._embedded?.reports || []
    apiReports = Array.isArray(apiReports) ? apiReports : []

    return apiReports.filter(
      (report) => report && !excludedUsages.includes(report.usage)
    )
  }, [data, ddData, tabIndex])

  const saveReport = async (values) => {
    const body = convertFormForAPI(values, columnToTypeMap)
    const res = await axios.post('/ddreports', body)
    await ddMutate()
    if (res.status === 200) {
      return res.data
    }

    return null
  }

  return {
    reports: reports,
    allReports: data?._embedded?.reports || [],
    embedded: data?._embedded ?? {},
    loading:
      (!data?._embedded?.reports && !data?.error) || isValidating || ddLoading,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    saveReport,
  }
}

export default useReports
