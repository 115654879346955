import React from 'react'
import styled from 'styled-components/macro'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

import { PRIVILEGES } from 'utils/constants'

import useConfiguration from 'hooks/useConfiguration'

import FilterToggleButton from 'components/common/FilterToggleButton'

const Tab = styled(MuiTab)`
  min-width: 160px;
`

function DigitalMailboxTabs({
  open,
  toggleOpen,
  tabIndex,
  setTabIndex,
  filterCount,
}) {
  const { hasPrivilege } = useConfiguration()
  const canViewAllItems = hasPrivilege(PRIVILEGES.VIEW_ALL_ITEMS)
  const canViewArchivedItems = hasPrivilege(PRIVILEGES.VIEW_ARCHIVED_ITEMS)

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
      <Box
        sx={{
          bgcolor: 'white',
          height: '72px',
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
        }}
      />
      <Box
        sx={{
          height: '72px',
          width: '100%',
          minWidth: '1024px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Tabs
            value={tabIndex}
            onChange={(_e, value) => setTabIndex(value)}
            aria-label="digital mailbox tabs"
            sx={{ ml: 8, mt: 6, minWidth: '420px' }}
          >
            {canViewAllItems && <Tab fullWidth label="ALL ITEMS" />}
            <Tab fullWidth label="MY ITEMS" />
            {canViewArchivedItems && <Tab fullWidth label="ARCHIVE" />}
          </Tabs>
        </Box>
        <Box sx={{ height: '100%', display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              height: '50%',
              width: '100%',
              my: 'auto',
              '& > *': { my: 'auto' },
              justifyContent: 'flex-end',
              justifySelf: 'flex-end',
            }}
          >
            <FilterToggleButton
              opne={open}
              toggleOpen={toggleOpen}
              filterCount={filterCount}
            />
          </Box>
        </Box>
      </Box>
      <Box
        fullWidth
        sx={{
          height: '1px',
          bgcolor: 'darkblue.main',
          opacity: 0.12,
        }}
      />
    </Box>
  )
}

export default DigitalMailboxTabs
