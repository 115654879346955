import axios from 'utils/axios'
import { useState, useEffect } from 'react'

const useUserTypes = (username) => {
  const [userType, setUserType] = useState('N/A')

  useEffect(() => {
    axios
      .post('/sso-email-validations', { username })
      .then((data) => {
        setUserType(data?.requires_sso_login ? 'SSO' : 'Portal')
      })
      .catch(() => {})
  }, [username])

  return {
    userType,
  }
}

export default useUserTypes
