import { useState, useEffect } from 'react'

import axios from 'utils/axios'

import urlSerialize from 'helpers/browser/urlSerialize'

import useConfiguration from 'hooks/useConfiguration'

import { PRIVILEGES } from 'utils/constants'

const useAllUsers = (params, forceFetch = false) => {
  const { hasPrivilege } = useConfiguration()
  const [users, setUsers] = useState([])

  const fetchUsers = async () => {
    const {
      data: { total },
    } = await axios.get('/users', {
      params: {
        'paging[page_size]': 1,
      },
    })

    const res = await axios.get(
      `/users?${urlSerialize({ ...params, 'paging[page_size]': total })}`
    )

    const sortedUsers = res.data._embedded?.users?.sort((a, b) =>
      ('' + a.display?.toUpperCase?.()).localeCompare(
        b.display?.toUpperCase?.()
      )
    )

    setUsers(sortedUsers)
  }

  useEffect(() => {
    if (forceFetch || hasPrivilege(PRIVILEGES.VIEW_USERS, 13)) fetchUsers()
  }, [hasPrivilege])

  return {
    users,
  }
}

export default useAllUsers
