import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Box from '@mui/material/Box'

import ResultsList from 'components/print_assets/ResultsList'
import PrintAssetsTabs from 'components/print_assets/Tabs'

import DataListLayout from 'layouts/PureDataList'

import useDataListContext from 'hooks/context/useDataListContext'
import useConfiguration from 'hooks/useConfiguration'

import { PRIVILEGES } from 'utils/constants'

function PrintAssetsContent() {
  const { hasPrivilege } = useConfiguration()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setPage } = useDataListContext()
  const [tabIndex, _setTabIndex] = useState(0)
  const setTabIndex = (newTabIndex) => {
    if (newTabIndex !== tabIndex) {
      setPage(1) // reset page on tab change

      if (newTabIndex > 0) {
        setSearchParams({ tab: newTabIndex })
      } else {
        searchParams.delete('tab')
        setSearchParams(searchParams)
      }

      _setTabIndex(newTabIndex)
    }
  }

  useEffect(() => {
    if (searchParams.get('tab')) {
      const newTab = parseInt(searchParams.get('tab'))
      if (newTab) {
        _setTabIndex(newTab)
      }
    }
  }, [])

  return (
    <Box sx={{ flex: 1 }}>
      {hasPrivilege(PRIVILEGES.ACCESS_INTERNAL_PRINT_ASSET) && (
        <PrintAssetsTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
      )}
      <Box
        sx={{
          backgroundColor: 'white',
          pt: 6,
          px: 8,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <ResultsList tabIndex={tabIndex} />
      </Box>
    </Box>
  )
}

function PrintAssets() {
  return (
    <>
      <DataListLayout>
        <PrintAssetsContent />
      </DataListLayout>
    </>
  )
}

export default PrintAssets
