import { createContext, useReducer } from 'react'

const initialState = {
  saveDisabled: false,
  type: 'closed',
  sfcEtag: undefined,
  submitAction: '',
  activeSfcId: undefined,
}

const SavedSearchModalContext = createContext(null)

const SET_TYPE = 'SET_TYPE'
const SET_SAVE_DISABLED = 'SET_SAVE_DISABLED'
const SET_ETAG = 'SET_ETAG'
const SET_SUBMIT_ACTION = 'SET_SUBMIT_ACTION'
const SET_ACTIVE_SFC_ID = 'SET_ACTIVE_SFC_ID'

const SavedSearchReducer = (state, action) => {
  switch (action.type) {
    case SET_TYPE:
      return {
        ...state,
        type: action.payload.type,
      }
    case SET_SAVE_DISABLED:
      return {
        ...state,
        saveDisabled: action.payload.saveDisabled,
      }
    case SET_ETAG:
      return {
        ...state,
        sfcEtag: action.payload.sfcEtag,
      }
    case SET_SUBMIT_ACTION:
      return {
        ...state,
        submitAction: action.payload.submitAction,
      }
    case SET_ACTIVE_SFC_ID:
      return {
        ...state,
        activeSfcId: action.payload.activeSfcId,
      }
    default:
      return state
  }
}

function SavedSearchModalProvider({ children }) {
  const [state, dispatch] = useReducer(SavedSearchReducer, initialState)

  const disableSave = (saveDisabled) => {
    dispatch({
      type: SET_SAVE_DISABLED,
      payload: {
        saveDisabled,
      },
    })
  }

  const setType = (type) => {
    dispatch({
      type: SET_TYPE,
      payload: {
        type,
      },
    })
  }

  const setSfcEtag = (sfcEtag) => {
    dispatch({
      type: SET_ETAG,
      payload: {
        sfcEtag,
      },
    })
  }

  const setSubmitAction = (submitAction) => {
    dispatch({
      type: SET_SUBMIT_ACTION,
      payload: {
        submitAction,
      },
    })
  }

  const setActiveSfcId = (activeSfcId) => {
    dispatch({
      type: SET_ACTIVE_SFC_ID,
      payload: {
        activeSfcId,
      },
    })
  }

  return (
    <SavedSearchModalContext.Provider
      value={{
        ...state,
        disableSave,
        setType,
        setSfcEtag,
        setSubmitAction,
        setActiveSfcId,
      }}
    >
      {children}
    </SavedSearchModalContext.Provider>
  )
}

export { SavedSearchModalContext, SavedSearchModalProvider }
