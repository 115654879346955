import React, { useState, useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import get from 'lodash.get'

import Autocomplete from 'components/common/Autocomplete'

import useSmartyStreetsAutocomplete from 'hooks/address_validation/useSmartyStreetsAutocomplete'

import isCountryUSA from 'helpers/api/isCountryUSA'

function AddressAutocomplete({ addressKeys, textFieldProps }) {
  const { values, handleChange, errors, touched, handleBlur } =
    useFormikContext()
  const address = get(values, addressKeys.address)
  const country = get(values, addressKeys.country)
  const [selectedOption, setSelectedOption] = useState(undefined)
  const [localTimeout, setLocalTimeout] = useState(null)
  const [fullAddress, setFullAddress] = useState(address)

  const search = useMemo(() => {
    if (selectedOption || localTimeout || !isCountryUSA(country)) return null
    return fullAddress
  }, [selectedOption, fullAddress, localTimeout, country])

  const { suggestions, loading } = useSmartyStreetsAutocomplete(search)

  const getSuggestionDisplay = ({ entries, ...suggestion }) => {
    return Object.values(suggestion)
      .filter((value) => value)
      .join(', ')
  }

  const handleOptionSelect = (selectedAddress) => {
    if (selectedAddress) {
      handleChange({
        target: {
          name: addressKeys['addressalt'],
          value: selectedAddress.secondary,
        },
      })
      handleChange({
        target: { name: addressKeys['city'], value: selectedAddress.city },
      })
      handleChange({
        target: { name: addressKeys['state'], value: selectedAddress.state },
      })
      handleChange({
        target: { name: addressKeys['zip'], value: selectedAddress.zipcode },
      })
      handleChange({
        target: {
          name: addressKeys['country'],
          value: 'USA',
        },
      })
    }

    setSelectedOption(selectedAddress)
  }

  useEffect(() => {
    const generateTimeout = () =>
      setTimeout(() => {
        setLocalTimeout(null)
        setFullAddress(address)
      }, 500)

    const timeout = generateTimeout()

    if (localTimeout) {
      clearTimeout(localTimeout)
    }

    setLocalTimeout(timeout)
    if (!address || selectedOption?.street_line !== address)
      setSelectedOption(undefined)
  }, [address])

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const setSearchValue = (value) => {
    if (mounted) handleChange({ target: { name: addressKeys.address, value } })
  }

  return (
    <Autocomplete
      searchValue={address ?? ''}
      setSearchValue={setSearchValue}
      selectedOption={selectedOption}
      setSelectedOption={handleOptionSelect}
      options={suggestions}
      renderOption={(props, option) => (
        <li {...props} key={`${option.street_line} ${option.state}`}>
          {getSuggestionDisplay(option)}
        </li>
      )}
      getOptionLabel={(option) => option.street_line}
      filterOptions={undefined}
      placeholder="Address *"
      textFieldProps={{
        delay: 200,
        color: 'primary',
        ...textFieldProps,
        error:
          touched[addressKeys.address] && Boolean(errors[addressKeys.address]),
        helperText: touched[addressKeys.address] && errors[addressKeys.address],
        onBlur: handleBlur,
      }}
      loading={Boolean(localTimeout) || loading}
    />
  )
}

export default AddressAutocomplete
