import React from 'react'
import 'chart.js/auto'
import { Pie } from 'react-chartjs-2'

import Box from '@mui/material/Box'

function PieChart({ chartData, sx }) {
  if (!chartData) return <></>

  return (
    <Box sx={sx}>
      <Pie
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Box>
  )
}

export default PieChart
