import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components/macro'
import { useDropzone } from 'react-dropzone'
import get from 'lodash.get'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import XIcon from '@mui/icons-material/Close'

import ScrollableBox from 'components/styled/ScrollableBox'
import FileLoader from '../FileLoader'

const UnderlinedText = styled.span`
  text-decoration: underline;
`

function ContentPreview({ content, contentType = '', fileName = 'preview' }) {
  if (!content) return <Box />

  let contentComponent = null

  // svgs not support for rendering at the moment
  if (
    typeof contentType === 'string' &&
    contentType.includes('image') &&
    !contentType.includes('svg')
  ) {
    try {
      contentComponent = (
        <Box component="img" src={content} alt="" sx={{ height: '150px' }} />
      )
    } catch (err) {}
  }

  if (!contentComponent) return <Box />

  return (
    <ScrollableBox
      sx={{
        border: '1px solid',
        borderColor: 'lightgray.main',
        p: 2,
        overflow: 'auto',
      }}
    >
      {contentComponent}
    </ScrollableBox>
  )
}

function getRuleFromMimeType(mimeType, rules) {
  const path = mimeType?.replace('/', '.')
  const rule = get(rules, path) || {}
  return rule
}

function contentSizeValidator(file, rules) {
  const rule = getRuleFromMimeType(file.type, rules)

  const maxsize = rule.rules?.maxsize

  if (!maxsize?.min) {
    return null
  }

  if (file.size && maxsize.min > file.size) {
    return {
      code: 'file-too-small',
      message: `File is smaller than ${maxsize.min} bytes`,
    }
  }

  if (file.size && maxsize.max < file.size) {
    return {
      code: 'file-too-large',
      message: `File is larger than ${maxsize.max} bytes`,
    }
  }

  return null
}

function FileDropzone({
  file,
  onChange,
  error,
  disabled,
  fieldData,
  onFocus,
  onFileDeattach,
}) {
  const fileReaderRef = useRef()
  const {
    display,
    required,
    placeholder,
    accept,
    acceptLabel,
    includePreview,
    maxSize,
    preview,
    fileType,
    multiple,
    rules,
    field,
  } = fieldData
  const [basePreviewData, setBasePreviewData] = useState(preview)
  const [previewData, setPreviewData] = useState(null)
  const { setErrors } = useFormikContext()

  const onDrop = useCallback(
    (acceptedFiles) => {
      const droppedFile = acceptedFiles[0]

      onChange(droppedFile)
    },
    [onChange, rules]
  )

  const acceptType = useMemo(() => {
    if (accept === '*') return undefined
    if (accept) return accept

    return 'application/pdf'
  }, [accept])

  const acceptDisplay = useMemo(() => {
    if (acceptLabel) return acceptLabel

    try {
      const exts = acceptType
        .split(', ')
        .map((type) => (type.includes('*') ? type : type.split('/')[1]))
      return exts.join(', ')
    } catch (err) {}

    return ''
  }, [acceptType])

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: acceptType,
      maxFiles: 1,
      maxSize: maxSize || null,
      validator: (file) => contentSizeValidator(file, rules),
    })

  // update onload func on filereader when preview data changes
  useEffect(() => {
    if (!includePreview) return

    if (!fileReaderRef.current) {
      fileReaderRef.current = new FileReader()
    }

    if (fileReaderRef.current) {
      fileReaderRef.current.onload = (ext) => {
        if (previewData !== ext.target.result) setPreviewData(ext.target.result)
      }
    }
  }, [previewData])

  // read file to display preview data when it changes
  useEffect(() => {
    if (file && fileReaderRef.current && includePreview) {
      fileReaderRef.current.readAsDataURL(file)
    } else if (previewData) {
      setPreviewData(null)
    }
  }, [file, includePreview])

  useEffect(() => {
    if (preview && basePreviewData !== preview) {
      setBasePreviewData(preview)
    }
  }, [preview])

  useEffect(() => {
    const rejection = fileRejections?.[0]

    if (rejection) {
      setErrors({
        [field]: rejection?.errors?.[0]?.message,
      })
    }
  }, [fileRejections])

  return (
    <Box>
      <Box component="label">
        {display}
        {required && '*'}
      </Box>
      {includePreview && (
        <ContentPreview
          content={previewData || basePreviewData}
          contentType={file?.type || fileType}
        />
      )}
      <Box
        component="div"
        {...getRootProps()}
        sx={{
          minHeight: '150px',
          width: '100%',
          border: '1px solid',
          borderColor: error ? 'error.main' : 'lightgray.main',
          bgcolor: 'lightgray.light',
          borderRadius: '4px',
          display: 'flex',
          userSelect: 'none',
          cursor: 'pointer',
        }}
      >
        {!disabled && (
          <Box
            component="input"
            {...getInputProps()}
            onClick={(e) => {
              getInputProps().onClick(e)
              onFocus?.()
            }}
          />
        )}
        <Box
          sx={{ width: '50%', height: '80%', m: 'auto', textAlign: 'center' }}
        >
          {file && !multiple ? (
            <Box>
              <CloudUploadIcon sx={{ color: 'darkgray.main' }} />
              {isDragActive ? (
                <Typography color="darkgray.main">
                  Drop the files here ...
                </Typography>
              ) : (
                <Typography color="darkgray.main">
                  Selected file: <UnderlinedText>{file.name}</UnderlinedText>
                </Typography>
              )}
            </Box>
          ) : (
            <Box>
              <CloudUploadIcon sx={{ color: 'darkgray.main' }} />
              {isDragActive ? (
                <Typography color="darkgray.main">
                  Drop the files here ...
                </Typography>
              ) : (
                <Typography color="darkgray.main">
                  {placeholder ?? (
                    <>
                      Drag and drop a pdf here or{' '}
                      <UnderlinedText>browse files</UnderlinedText>
                    </>
                  )}
                </Typography>
              )}
            </Box>
          )}
          <Box sx={{ pt: 4 }}>
            <Typography
              color="darkgray.main"
              fontWeight={600}
              fontSize="14px"
              sx={{ textTransform: 'uppercase' }}
            >
              {acceptDisplay}
            </Typography>
          </Box>
        </Box>
      </Box>
      {Boolean(file) && !multiple && (
        <Button
          sx={{ width: '100%', textAlign: 'center' }}
          startIcon={<XIcon />}
          color="error"
          onClick={() => {
            onChange(null)

            if (onFileDeattach) {
              onFileDeattach()
            }
          }}
        >
          Remove attachment
        </Button>
      )}
      {Boolean(multiple) && file && (
        <Box mt={6}>
          <FileLoader
            filename={file?.name}
            fileType={file?.type}
            onClose={() => onChange(null)}
          />
        </Box>
      )}
      {error && (
        <Typography color="error.main" sx={{ mt: '1px' }}>
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default FileDropzone
