import React, { createContext, useEffect, useMemo, useReducer } from 'react'

import axios from 'axios'
import { APP_KEY } from 'utils/constants'
import useAuth from 'hooks/useAuth'

const initialState = {
  images: {
    logo_footer: '',
    logo_header: '',
    logo_login: '',
  },
  texts: {
    copyright: '',
    client_name: '',
    login_help: '',
  },
}
const ThemeContext = createContext(initialState)

const ThemeReducer = (state, action) => {
  switch (action.type) {
    case 'SET_THEME': {
      const images = {}

      Object.entries(action.payload.images).forEach(([key, value]) => {
        images[key] = value._embedded.self
      })

      return {
        ...state,
        images,
        texts: action.payload.texts,
      }
    }
    default:
      return state
  }
}

function ThemeProvider({ children }) {
  const [state, dispatch] = useReducer(ThemeReducer, initialState)
  const {
    user: { instanceId },
  } = useAuth()

  const instanceName = useMemo(() => {
    return instanceId || localStorage.getItem('instanceId')
  }, [instanceId])

  useEffect(() => {
    if (!instanceName) return

    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/${instanceName}/app-themes/${APP_KEY}`,
          { headers: { Accept: '*/*' } }
        )
        .then(({ data }) => {
          dispatch({ type: 'SET_THEME', payload: data })
        })
    } catch (err) {}
  }, [instanceName])

  return (
    <ThemeContext.Provider value={{ ...state }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
