import React from 'react'
import styled from 'styled-components/macro'
import 'chart.js/auto'
import { Line } from 'react-chartjs-2'

import Box from '@mui/material/Box'
import { COLOR_SHADES } from 'utils/constants'

const StyledBox = styled(Box)`
  canvas {
    height: 125px !important;
  }
`

function LineChart({ chartData, options }) {
  return (
    <StyledBox sx={{ height: '125px' }}>
      <Line
        data={chartData}
        options={{
          plugins: { legend: { display: false } },
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            x: {
              grid: {
                color: 'white',
              },
              ticks: {
                color: COLOR_SHADES.LIGHT_GRAY[0],
                font: {
                  size: 10,
                  family: 'Open Sans',
                },
              },
            },
            y: {
              grid: {
                borderDash: [4, 4],
                color: COLOR_SHADES.LIGHT_GRAY[0],
              },
              ticks: {
                color: COLOR_SHADES.LIGHT_GRAY[0],
              },
            },
          },
          ...options,
        }}
      />
    </StyledBox>
  )
}

export default LineChart
