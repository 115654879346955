import React from 'react'

import Box from '@mui/material/Box'

import components from 'svgs/statuses/components'

import HorizontalTimeline from 'components/common/HorizontalTimeline'

import { useUserData } from 'hooks/users'

const COLOR_MAPPING = {
  'on hold': 'error.main',
  'partially mailed': 'yellow.main',
}

function StatusTimeline({ statusHistory }) {
  const { formatUserTimeZone } = useUserData()

  const futureMap = Object.entries(statusHistory).map((status) => {
    if (status[1].current === true) {
      return status[0]
    }

    return null
  })

  const futureIndex = Number(futureMap.join(''))

  const createTimelineContent = (status, index) => {
    const display = status.display || status.status
    const color = COLOR_MAPPING[display.toLowerCase()] || 'green.main'
    const Icon =
      status.current !== true || index === Object.keys(statusHistory).length - 1
        ? components[display.toLowerCase()]
        : components[display.toLowerCase() + '_current']

    const timelineContent = {
      icon: (
        <Box
          sx={{
            color: index <= futureIndex ? color : 'lightgray.main',
          }}
        >
          {Icon && <Icon height="48" width="48" sx={{ fontSize: '56px' }} />}
        </Box>
      ),
      children: (
        <>
          <strong>{display}</strong>
          <br />
          {status.datetime_changed &&
            formatUserTimeZone(new Date(status.datetime_changed))}
        </>
      ),
    }

    return timelineContent
  }

  return (
    <HorizontalTimeline content={statusHistory.map(createTimelineContent)} />
  )
}

export default StatusTimeline
