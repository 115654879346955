import React from 'react'

import Memic from './instance/Memic'
import MMG from './instance/MMG'

import useAuth from 'hooks/useAuth'

function AgencyPreferencesPage() {
  const { user } = useAuth()
  const instanceId = user?.instanceId?.toLowerCase()

  if (instanceId === 'memic') {
    return <Memic />
  } else if (instanceId === 'mmg') {
    return <MMG />
  }

  return <></>
}

export default AgencyPreferencesPage
