import { generatePath, matchPath } from 'react-router-dom'

import { PATH_DISPLAYS } from 'utils/constants'

const getBreadcrumbDisplay = ({ pathname, href }) => {
  const getPathDisplay = () => {
    for (let i = 0; i < PATH_DISPLAYS.length; i++) {
      const pathDisplay = PATH_DISPLAYS[i]
      const match = matchPath(pathDisplay.path, href)

      if (match) {
        return { pathDisplay, match }
      }
    }

    return {}
  }

  const { pathDisplay, match } = getPathDisplay()

  if (!pathDisplay) return pathname.split(/-/gm).join(' ')

  return generatePath(pathDisplay.display, { ...match.params })
}

export default getBreadcrumbDisplay
