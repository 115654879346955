import React, { useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import ItemContainer from './containers/ItemContainer'
import FormRenderer from 'components/common/FormRenderer'

import useSavedSearch from 'hooks/search/useSavedSearch'
import useConfiguration from 'hooks/useConfiguration'
import { INSTANCE_CONFIGIRATIONS, WIDGET_KEYS } from 'utils/constants'

function CorrespondenceItemSearch() {
  const { instanceConfigurations } = useConfiguration()
  const navigate = useNavigate()
  const { defaultSearch, fieldDefinitions } = useSavedSearch({})
  const formikRef = useRef()

  const fieldDefinition = useMemo(() => {
    const field =
      instanceConfigurations?.[
        INSTANCE_CONFIGIRATIONS.DASHBOARD_QUICK_SEARCH_FIELD
      ] || ''

    const fieldData =
      fieldDefinitions?.[field] || fieldDefinitions?.[`_.metadata.${field}`]
    return {
      ...fieldData,
    }
  }, [instanceConfigurations, fieldDefinitions])

  const handleSearch = (values) => {
    navigate(`/correspondence-hub/correspondence/results`, {
      state: {
        fields:
          defaultSearch?.resultColumns
            .map((rc) => rc.path)
            .filter((rc) => rc) || [],
        displays:
          defaultSearch?.resultColumns
            .map((rc) => rc.display)
            .filter((rc) => rc) || [],
        values: values,
        activeId: defaultSearch.id,
      },
    })
  }

  return (
    <ItemContainer
      title="Correspondence"
      href="/correspondence-hub/correspondence"
      loading={!fieldDefinition}
      widgetKey={WIDGET_KEYS.CORRESPONDENCE_QUICKSEARCH}
    >
      <Box
        sx={{
          height: '216px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography fontWeight={700} fontSize="10px">
          Quick Search
        </Typography>
        <FormRenderer
          disableFooterPadding
          innerRef={formikRef}
          renderingData={[fieldDefinition]}
          handleSubmit={handleSearch}
          footerActionProps={[
            {
              children: 'Search',
              variant: 'contained',
              color: 'primary',
              size: 'action-header',
              onClick: () => formikRef.current?.submitForm(),
            },
          ]}
        />
      </Box>
    </ItemContainer>
  )
}

export default CorrespondenceItemSearch
