import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'

import ExpandedListItem from './ExpandedListItem'

function ShowMoreContent({
  rowData,
  visibleColumns = [],
  columns,
  initialShowing = 12,
  getDataDisplay,
  open,
  ExpandedListItemComponent = ExpandedListItem,
}) {
  const [showAll, setShowAll] = useState(false)

  const hiddenColumns = columns.filter(
    (col) => !visibleColumns.includes(col.id)
  )

  const visibleCount = showAll ? columns.length : initialShowing
  const showMoreVisible = visibleCount < hiddenColumns.length

  return (
    <Collapse in={open} sx={{ m: '0 !important' }}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'start',
            pt: 2,
          }}
        >
          {hiddenColumns.slice(0, initialShowing).map((col) => {
            return (
              <Box key={col.id}>
                <ExpandedListItemComponent
                  id={col.id}
                  display={col.display}
                  data={rowData}
                  getDataDisplay={getDataDisplay}
                />
              </Box>
            )
          })}
          {showAll &&
            hiddenColumns.slice(initialShowing).map((col) => {
              return (
                <ExpandedListItemComponent
                  key={col.id}
                  id={col.id}
                  display={col.display}
                  data={rowData}
                  getDataDisplay={getDataDisplay}
                />
              )
            })}
        </Box>
        {showMoreVisible && !showAll && (
          <Box
            onClick={() => setShowAll(true)}
            sx={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              fontSize: 14,
              mb: 4,
              textAlign: 'left',
              color: 'primary.main',
              cursor: 'pointer',
            }}
          >
            Show more
          </Box>
        )}
      </Box>
    </Collapse>
  )
}

export default ShowMoreContent
