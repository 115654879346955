import React from 'react'
import { Formik, useFormikContext } from 'formik'
import { cloneDeep } from 'lodash'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import Modal from 'components/common/Modal'
import { useCallback } from 'react'
import { useRef } from 'react'
import useNotification from 'hooks/context/useNotification'
import getObjDiff from 'helpers/node/getObjDiff'
import isEmptyObject from 'helpers/node/isEmptyObject'

const COMPANY_NAMES = {
  310: 'MEMIC Indemnity Company (310)',
  510: 'MEMIC (181 and 510)',
  610: 'MEMIC Casualty Company (610)',
}

const DOCUMENT_TYPE_DISPLAYS = {
  NB: 'New Business',
  RB: 'Renewal Business',
  EN: 'Endorsement',
}

const OPTIONS = ['Mail to Agent', 'Mail to Insured']

function PreferenceSection({ dtKey: key, display }) {
  const { values, handleChange } = useFormikContext()

  if (!values.mailing_preferences) return <></>

  return (
    <Box>
      <Typography fontWeight={600} variant="h2" sx={{ mb: 4 }}>
        {display}
      </Typography>
      <Stack spacing={5}>
        {Object.keys(values.mailing_preferences).map((companyCode) => (
          <Box>
            <Typography>{COMPANY_NAMES[companyCode]}</Typography>
            <TextField
              fullWidth
              name={`mailing_preferences.${companyCode}.${key}`}
              size="small"
              placeholder={COMPANY_NAMES[companyCode]}
              select
              value={values.mailing_preferences[companyCode][key] || ''}
              onChange={handleChange}
            >
              {OPTIONS.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </TextField>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}

function MemicEditPreferencesModal({
  open,
  setOpen,
  agency,
  updateAgencyPreferences,
}) {
  const formikRef = useRef()
  const { setError, setBasicNotification } = useNotification()

  const readonlyFields = [
    {
      field: 'agency_code',
      display: 'Agency Code',
    },
    {
      field: 'name',
      display: 'Name',
    },
    {
      field: 'address1',
      display: 'Address 1',
    },
    {
      field: 'address2',
      display: 'Address 2',
    },
    {
      field: 'city',
      display: 'City',
    },
    {
      field: 'state',
      display: 'State',
    },
    {
      field: 'zip_code',
      display: 'Zip-Code',
    },
  ]

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const updatedValues = cloneDeep(values)

        getObjDiff(agency, updatedValues)

        if (updatedValues && !isEmptyObject(updatedValues)) {
          await updateAgencyPreferences({
            agencyId: agency?.agency_code,
            values: { ...updatedValues },
          })
        }

        setOpen(false)
        setBasicNotification(
          'Agency Preferences has been successfully updated!'
        )
      } catch (err) {
        setError(
          err?.response?.data?.display_message ||
            err?.message ||
            'Unable to update agency preferences'
        )
      }
    },
    [agency, updateAgencyPreferences]
  )

  return (
    <Modal
      open={open}
      onClose={() => setOpen(null)}
      height="90vh"
      width="732px"
      title={`Edit ${agency?.name || ''} (${agency?.agency_code || ''})`}
      scrollable
      footerButtonProps={[
        {
          children: 'Save',
          variant: 'contained',
          color: 'primary',
          onClick: () => formikRef.current.submitForm(),
        },
        {
          children: 'Cancel Changes',
          variant: 'outlined',
          color: 'primary',
          onClick: () => setOpen(false),
        },
      ]}
    >
      <Formik
        enableReinitialize
        innerRef={formikRef}
        onSubmit={handleSubmit}
        initialValues={{
          notes: agency?.notes || '',
          mailing_preferences: agency?.mailing_preferences,
        }}
      >
        {({ handleChange, values }) => (
          <Box sx={{ pb: 12, pt: 2 }}>
            <Stack spacing={4}>
              {readonlyFields.map((field) => (
                <Grid container columns={7}>
                  <Grid item xs={1}>
                    <Box>
                      <Typography>{field.display}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <TextField
                        fullWidth
                        size="small"
                        value={agency?.[field.field] || ''}
                        disabled
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))}
              <Grid container columns={7}>
                <Grid item xs={1}>
                  <Box>
                    <Typography>Notes</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      fullWidth
                      multiline
                      name="notes"
                      rows={8}
                      value={values.notes || ''}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Box sx={{ pb: 4, mt: 12 }}>
              <Typography variant="h1" sx={{ mb: 4 }}>
                Preferences
              </Typography>
              <Stack spacing={6}>
                {Object.entries(DOCUMENT_TYPE_DISPLAYS).map(
                  ([key, display]) => (
                    <>
                      <Divider />
                      <PreferenceSection
                        key={key}
                        dtKey={key}
                        display={display}
                      />
                    </>
                  )
                )}
              </Stack>
            </Box>
          </Box>
        )}
      </Formik>
    </Modal>
  )
}

export default MemicEditPreferencesModal
