import { useState, useCallback } from 'react'

import useSWR from 'swr'

import fetcher from 'utils/fetcher'

import urlSerialize from 'helpers/browser/urlSerialize'

import axios from 'utils/axios'

const useOrders = (params) => {
  const { data, isValidating, mutate } = useSWR(
    params
      ? `/orders?${urlSerialize({
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const getOrder = useCallback(async (orderId) => {
    return (await axios.get(`/orders/${orderId}`))?.data
  }, [])

  return {
    orders: data?._embedded?.orders ?? [],
    embedded: data?._embedded ?? {},
    loading: (!data?._embedded?.orders && !data?.error) || isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    getOrder,
  }
}

export const useAllOrders = (params, total, prepareRowData) => {
  const getAllOrders = async (csv = false) => {
    let orders = []

    if (total && params) {
      try {
        const newParams = { ...params }
        newParams.fields = undefined // get all fields regardless of search

        const res = await axios.get('/orders', {
          params: {
            ...newParams,
            'paging[page_size]': total,
            'paging[page]': 1,
          },
          headers: csv
            ? {
                Accept: 'text/csv',
              }
            : undefined,
        })

        if (res.status === 200 && csv) return res.data

        orders = res.data._embedded.orders
      } catch (err) {}
    }

    return orders
  }

  const getCsvExport = async () => {
    return getAllOrders(true)
  }

  return {
    getAllOrders,
    getCsvExport,
  }
}

export const useOrderNotes = (orderId) => {
  const [error, setError] = useState({})
  const { data, mutate, isValidating } = useSWR(
    orderId ? `/orders/${orderId}/notes` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const makeNote = async (note) => {
    try {
      const res = await axios.post(`/orders/${orderId}/notes`, { note })

      if (res.status !== 201) {
        throw new Error()
      } else {
        mutate()
      }
    } catch (err) {
      setError(err)
    }
  }

  return {
    notes: data?._embedded?.notes ?? [],
    loading: !isValidating,
    error,
    makeNote,
  }
}

export const useOrderDetail = (orderId) => {
  const { data, isValidating } = useSWR(
    orderId
      ? `/orders/${orderId}?${urlSerialize({
          embed: 'reports|workflow_statuses|processing_statistics',
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    order: data ?? [],
    loading: !data || isValidating,
    error: data?.error,
  }
}

export const useOrderStatus = (
  orderId,
  statusFilter,
  statusGroup = 'OrderApproval'
) => {
  const { orders } = useOrders({
    state: statusGroup,
    status_filter: statusFilter,
    'filter[field]': 'id',
    'filter[term]': orderId,
  })

  return {
    order: orders[0],
  }
}

export default useOrders
