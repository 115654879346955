import React from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

import AsyncTextField from 'components/common/AsyncTextField'

function SearchField({
  onChange,
  name,
  value,
  onSearch,
  isAsync = false,
  hideClear = false,
  ...props
}) {
  const handleClear = () => {
    onChange({ target: { name, value: '' } })
    if (onSearch) onSearch('')
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      if (onSearch) onSearch(value)
    }
  }

  const handleBlur = () => {
    const trimmedValue = value?.trim()

    if (trimmedValue && trimmedValue !== value) {
      onChange({ target: { name, value: trimmedValue } })
    }
  }

  const Component = isAsync ? AsyncTextField : TextField

  return (
    <Component
      name={name}
      value={value}
      onChange={onChange}
      {...props}
      InputProps={{
        onKeyDown: handleEnter,
        onBlur: handleBlur,
        endAdornment: (
          <InputAdornment position="end">
            {!hideClear && (
              <IconButton onClick={handleClear}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
            {onSearch ? (
              <IconButton onClick={() => onSearch()}>
                <SearchIcon fontSize="small" />
              </IconButton>
            ) : (
              <SearchIcon fontSize="small" />
            )}
          </InputAdornment>
        ),
      }}
    />
  )
}

export default SearchField
