import { useEffect, useState } from 'react'
import useSWR from 'swr'
import deepEqual from 'deep-equal'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

import useInstance from 'hooks/instance/useInstance'
import isEmptyObject from 'helpers/node/isEmptyObject'

import useConfiguration from 'hooks/useConfiguration'
import useDocumentInboundNotes from './useDocumentInboundNotes'

const useDocumentInboundItem = (inboundId) => {
  const [data, setData] = useState({})
  const { currentModule } = useConfiguration()
  const { mutate: mutateNotes } = useDocumentInboundNotes(inboundId, {
    type: 'System',
  })

  const { alterDocumentInboundItem } = useInstance()
  const {
    data: apiData,
    isValidating,
    mutate,
  } = useSWR(
    inboundId !== undefined
      ? `/document-inbound-items/${inboundId}?embed=document|pull_request|document_metadata`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const getEtag = async () => {
    const {
      headers: { etag },
    } = await axios.get(`/document-inbound-items/${inboundId}`)
    return etag
  }

  const updateInboundItem = async (updatedData, shouldMutate = true) => {
    const etag = await getEtag()
    const res = await axios.patch(
      `/document-inbound-items/${inboundId}`,
      updatedData,
      {
        headers: {
          'If-Match': etag,
        },
      }
    )

    if (res.status !== 200) {
      throw new Error('Error updating inbound item')
    }

    if (shouldMutate) {
      mutate()
      mutateNotes()
    }

    return res.data
  }

  const mailOriginalItem = async (mailData) => {
    const formData = new FormData()

    formData.append('to', JSON.stringify(mailData.to))
    formData.append('saveAddress', mailData.saveAddress ? '1' : '0')
    formData.append('instructions', mailData.instructions ?? '')
    formData.append('addressid', mailData.addressid ?? '0')
    formData.append('filedata', mailData.attachment ?? '')

    const res = await axios.post(
      `/document-inbound-items/${inboundId}/pulls`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    mutate()
    return res
  }

  const redactItem = async (redactionData) => {
    const etag = await getEtag()
    const res = await axios.post(
      `/document-inbound-items/${inboundId}/redactions`,
      redactionData,
      {
        headers: {
          'If-Match': etag,
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      }
    )

    return res.data
  }

  const duplicateItem = async (inboundId, body) => {
    const etag = await getEtag()
    const res = await axios.post(
      `/document-inbound-items/${inboundId}/escalations`,
      body,
      {
        headers: {
          'If-Match': etag,
          'Content-Type': 'application/json',
        },
      }
    )

    return res.data
  }

  const splitItem = async (splitData) => {
    const etag = await getEtag()
    const res = await axios.post(
      `/document-inbound-items/${inboundId}/splits`,
      splitData,
      {
        headers: {
          'If-Match': etag,
          'Content-Type': 'application/json',
        },
      }
    )

    return res
  }

  const redactItemBySection = async (redactionData) => {
    const etag = await getEtag()
    const res = await axios.post(
      `/document-inbound-items/${inboundId}/document-overrides`,
      redactionData,
      {
        headers: {
          'If-Match': etag,
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      }
    )

    return res
  }

  const getInboundId = async (documentId) => {
    const res = await axios.get(
      `/document-inbound-items?search=document_id|${documentId}`
    )
    return res.data?._embedded?.document_inbound_items?.[0]?.id
  }

  useEffect(() => {
    if (!apiData || isEmptyObject(apiData)) {
      setData(apiData)
      return
    }

    alterDocumentInboundItem(apiData).then((docInboundData) => {
      if (!deepEqual(data, docInboundData)) {
        setData(docInboundData)
      }
    })
  }, [apiData, currentModule])

  return {
    documentInboundItem: data ?? {},
    loading: (!data && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
    updateInboundItem,
    mailOriginalItem,
    redactItem,
    getInboundId,
    duplicateItem,
    redactItemBySection,
    splitItem,
  }
}

export default useDocumentInboundItem
