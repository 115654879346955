import { useContext } from 'react'
import { DataListContext } from 'contexts/component/dataList/DataListContext'
import { PaginationContext } from 'contexts/component/dataList/PaginationContext'

const useDataListContext = () => {
  const dataListContext = useContext(DataListContext)
  const paginationContext = useContext(PaginationContext)

  if (!dataListContext)
    throw new Error('DataListContext must be placed within DataListProvider')

  if (!paginationContext)
    throw new Error(
      'PaginationContext must be placed within PaginationProvider'
    )

  return {
    ...dataListContext,
    ...paginationContext,
  }
}

export default useDataListContext
