import useSWR from 'swr'

import fetcher from 'utils/fetcher'

import urlSerialize from 'helpers/browser/urlSerialize'

import useConfiguration from 'hooks/useConfiguration'
import useConsumerAccountIdTypes from './useConsumerAccountIdTypes'
import { COLLATERAL_ENABLE_ACCOUNT_NUMBER } from 'utils/constants'

const useConsumerAccounts = ({ any_id, embed = null }) => {
  const { instanceConfigurations } = useConfiguration()
  const { defaultIdType } = useConsumerAccountIdTypes()
  const allowFetching =
    Boolean(any_id) &&
    instanceConfigurations?.[COLLATERAL_ENABLE_ACCOUNT_NUMBER] === '1'

  const params = {
    any_id_type: defaultIdType,
  }

  if (embed !== null) {
    if (Array.isArray(embed)) {
      params.embed = embed.join('|')
    } else if (typeof embed === 'string') {
      params.embed = embed
    }
  }

  let {
    data,
    isValidating,
    mutate: fetchConsumerAccounts,
  } = useSWR(
    allowFetching
      ? `/consumer-accounts/${any_id}?${urlSerialize(params)}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateIfStale: false,
    }
  )

  return {
    addresses: data?._embedded?.addresses ?? [],
    loading: (!data?._embedded?.addresses && !data?.error) || isValidating,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    fetchConsumerAccounts,
  }
}

export default useConsumerAccounts
