import React, { useEffect } from 'react'

import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import CheckboxWithDropdown from 'components/common/CheckboxWithDropdown'

import useDataListContext from 'hooks/context/useDataListContext'

const selectionOptions = [
  {
    value: 'all-on-page',
    display: 'All On Page',
  },
  {
    value: 'all-matching-query',
    display: 'All Matching Query',
  },
]

function ItemCheckBox({
  displayColumns,
  index,
  handleItemCheck,
  color,
  checkedState,
}) {
  const { allChecked, setChecked, toggleCheckAll, checked, setCheckAllPreset } =
    useDataListContext()

  const handleItemSelect = (e) => {
    setCheckAllPreset(e.target.value)
    e.stopPropagation()
  }

  const handleCheck = (e) => {
    if (handleItemCheck) handleItemCheck(e.target.checked)

    setChecked({
      index,
      value: e.target.checked,
    })

    e.stopPropagation()
  }

  useEffect(() => {
    if (checkedState !== checked[index]) {
      handleItemCheck(checked[index])
    }
  }, [checked])

  return (
    <Grid item xs={1} sx={{ display: 'flex' }}>
      <ListItemIcon sx={{ marginY: 'auto' }}>
        {displayColumns ? (
          <CheckboxWithDropdown
            checked={allChecked ?? false}
            handleCheck={toggleCheckAll}
            options={selectionOptions}
            handleItemSelect={handleItemSelect}
          />
        ) : (
          <Checkbox
            edge="start"
            color={color}
            checked={checkedState ?? checked[index] ?? allChecked ?? false}
            tabIndex={-1}
            disableRipple
            onClick={handleCheck}
          />
        )}
      </ListItemIcon>
    </Grid>
  )
}

export default ItemCheckBox
