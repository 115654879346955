import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/styles/useTheme'

import FileTypeSquare from './FileTypeSquare'

function FileTypeButton({ type, count, label, onClick }) {
  const theme = useTheme()
  const hideSquare = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Card
      sx={{
        border: '1px solid',
        borderColor: 'lightgray.main',
        p: {
          xs: 4,
          xl: 8,
        },
        cursor: 'pointer',
        width: {
          xl: '100%',
        },
        ':hover': {
          backgroundColor: 'lightgray.light',
        },
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex' }}>
        {!hideSquare && <FileTypeSquare>{type}</FileTypeSquare>}
        <Box>
          <Typography fontWeight={600} lineHeight="24px" whiteSpace="nowrap">
            {label}
          </Typography>
          <Typography fontSize="48px" lineHeight="50px" fontWeight={600}>
            {count}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default FileTypeButton
