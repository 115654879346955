import getFilenameExt from 'helpers/node/getFilenameExt'
import { useState } from 'react'

import axios from 'utils/axios'

const usePrintAssetPreview = ({ asset, version }) => {
  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(false)

  const getAssetPreview = async ({
    printAsset = asset,
    versionNum = version,
  }) => {
    if (printAsset && printAsset.key_id && printAsset.mime_type) {
      let mimeType = printAsset.mime_type
      const ext = getFilenameExt(printAsset.filename)

      if (ext === 'eps') {
        mimeType = 'application/postscript'
      }

      setLoading(true)
      try {
        const res = await axios.get(
          `/print-assets/${printAsset.key_id}/versions/${versionNum}`,
          {
            responseType: 'blob',
            headers: { Accept: mimeType },
          }
        )
        setLoading(false)
        return res.data
      } catch (err) {
        setLoading(false)
        return null
      }
    } else {
      setLoading(false)
      return null
    }
  }

  const refetch = async () => {
    setPreview(
      await getAssetPreview({ printAsset: asset, versionNum: version })
    )
  }

  return {
    preview,
    refetch,
    getAssetPreview,
    loading,
  }
}

export default usePrintAssetPreview
