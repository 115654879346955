import React, { useRef, useCallback, useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import FormRenderer from 'components/common/FormRenderer'
import ScrollableBox from 'components/styled/ScrollableBox'

import useNotification from 'hooks/context/useNotification'
import useCollateralContext from 'hooks/collateral/useCollateralContext'
import useOrderSummary from 'hooks/collateral/useOrderSummary'
import useAuth from 'hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { COLLATERAL_MODES } from 'utils/constants'
import { addressKeys } from 'components/collateral/AddressForm'

function ItemForm({
  activeTemplate,
  addToCart,
  handleBlur,
  handleFocus,
  handleChange,
  loading,
  cart,
  generateFormData,
  parseCart,
}) {
  const { setBasicNotification } = useNotification()
  const { mode } = useCollateralContext()
  const formikRef = useRef(null)
  const scrollRef = useRef(null)
  const location = useLocation()
  const [renderingData, setRenderingData] = useState([])
  const {
    user: { instanceId },
  } = useAuth()

  const shouldRenderPreview = activeTemplate?.should_render_preview
  const isUpdate = cart.find((item) => item.template.id === activeTemplate.id)
  const isStandalone = mode === COLLATERAL_MODES.STANDALONE

  const { handleSubmit: handleOrderSubmit } = useOrderSummary({
    generateFormData,
  })

  const handleSubmit = useCallback(
    (values) => {
      if (addToCart && !isStandalone) {
        addToCart(values)

        const message = isUpdate ? (
          `${activeTemplate.name} has been successfully updated.`
        ) : (
          <>
            {activeTemplate.name} has been added to the order. Check the Order
            section.
          </>
        )

        setBasicNotification(message)
      } else {
        handleOrderSubmit(parseCart(values), {})
      }
    },
    [addToCart, cart, isUpdate, parseCart]
  )

  const validate = useCallback(
    (values) => {
      const errors = {}

      renderingData.forEach((field) => {
        const value = values[field.field]

        if (
          (field.required || field?.showIf?.(values) === true) &&
          (value === '' ||
            value === undefined ||
            value === null ||
            (!(value instanceof Date) && isEmpty(value)) ||
            (Array.isArray(value) &&
              value.filter((v) => v !== undefined).length === 0))
        )
          errors[field.field] = `Required`
      })

      return errors
    },
    [renderingData]
  )

  useEffect(() => {
    let newRenderingData = []

    if (!isUpdate && formikRef.current) {
      formikRef.current.resetForm()
    }

    if (activeTemplate?.fields) {
      newRenderingData = activeTemplate.fields.map((field) => {
        let defaultValue = field.initialDefaultValue ?? field.default_value
        if (Boolean(defaultValue) === false && defaultValue !== 0) {
          if (
            activeTemplate.alt_id_source_field !== undefined &&
            field.path === activeTemplate.alt_id_source_field &&
            location?.state?.values?.account_number
          ) {
            field.default_value = location.state.values.account_number
            field.initialDefaultValue = location.state.values.account_number
            defaultValue = location.state.values.account_number
          } else if (
            activeTemplate?._embedded?.order_defaults !== undefined &&
            activeTemplate?._embedded?.order_defaults[field.path] !== undefined
          ) {
            let pathway = activeTemplate._embedded.order_defaults[field.path]
            if (
              location?.state?.values[pathway] === undefined &&
              addressKeys[pathway] !== undefined
            ) {
              // set to fill address fields IFF it's an address key & the location state is undefined.
              pathway = addressKeys[pathway]
            }

            if (location?.state?.values[pathway] !== undefined) {
              field.default_value = location.state.values[pathway]
              field.initialDefaultValue = field.default_value
              defaultValue = field.default_value
            }
          }

          if (instanceId === 'ustgateway') {
            if (field.path === 'language') {
              const defaultLanguage = location?.state?.values?.language || 'ENG'
              field.default_value = defaultLanguage
              field.initialDefaultValue = defaultLanguage
              defaultValue = defaultLanguage
            } else if (field.path === 'lob' && location?.state?.values?.lob) {
              const defaultLineOfBusiness = location.state.values.lob
              field.default_value = defaultLineOfBusiness
              field.initialDefaultValue = defaultLineOfBusiness
              defaultValue = defaultLineOfBusiness
            } else if (field.path === 'material') {
              field.default_value = []
              field.initialDefaultValue = []
              defaultValue = []
            }
          }
        }

        let inputProps = {
          InputProps: {
            readOnly:
              Boolean(field.default_value_enforced ?? false) &&
              (defaultValue || defaultValue === 0),
          },
          isCollateral: true,
        }

        if (field.field_type === 'date') {
          inputProps.single = true
          inputProps.dateFormat = { dateFormat: field.date_format }

          if (defaultValue) {
            defaultValue = [new Date(defaultValue)]
            field.default_value = defaultValue
          }
        }

        if (field.field_type === 'time' && defaultValue) {
          // assume default value is string in the form HH:MM (A|P)M
          try {
            defaultValue = defaultValue.trim()
            const [hour, min] = defaultValue.split(':')
            const isPM = defaultValue.split(' ')[1].toLowerCase() === 'pm'

            // time input takes a date value. The date doesn't matter
            const date = new Date()

            date.setHours(parseInt(hour) + isPM ? 12 : 0)
            date.setMinutes(parseInt(min))

            defaultValue = date
            field.default_value = date
          } catch (err) {
            // if malformed, ignore
          }
        }

        if (field.depends_on) {
          field.showIf = (values) => {
            const dependsOn = field.depends_on || {}

            return Object.entries(dependsOn).reduce((acc, [field, value]) => {
              return acc && values[field] === value
            }, true)
          }
        }

        return {
          required: true,
          ...field,
          field: field.path,
          data_type: field.field_type,
          initialDefaultValue: defaultValue,
          inputProps,
        }
      })
    }

    setRenderingData(newRenderingData)
  }, [activeTemplate])

  return (
    <Box
      sx={{
        minWidth: '324px',
        width: shouldRenderPreview ? '324px' : '100%',
        pl: 6,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ScrollableBox
        sx={{ height: 'calc(100% - 100px)', overflow: 'auto', pt: 2, px: 3 }}
        psRef={scrollRef}
      >
        <FormRenderer
          renderingData={renderingData}
          innerRef={formikRef}
          handleSubmit={handleSubmit}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          loading={loading}
          validate={validate}
          scrollRef={scrollRef}
          labelMaxLength={28}
        />
      </ScrollableBox>
      {renderingData.length > 0 && (
        <Box>
          <Button
            sx={{ textDecoration: 'underline' }}
            onClick={() => {
              setRenderingData(
                renderingData.map((field) => {
                  return {
                    ...field,
                    default_value: field.initialDefaultValue,
                  }
                })
              )
              formikRef.current.resetForm()
              handleBlur()
            }}
          >
            Clear
          </Button>
        </Box>
      )}
      <Box sx={{ height: '72px', pt: 4, px: 4 }}>
        <Button
          color="secondary"
          variant="contained"
          sx={{ float: 'right' }}
          size="action-header"
          onClick={() => {
            formikRef.current.submitForm()
            scrollRef.current.scrollTop = 0
          }}
        >
          {isStandalone ? 'Submit' : 'Save'}
        </Button>
      </Box>
    </Box>
  )
}

export default ItemForm
