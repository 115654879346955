import React, { useMemo, useEffect } from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import usePostageBalance from 'hooks/postage/usePostageBalance'

function ClientSelect({ value, setValue, sx, setHasMultiple }) {
  const {
    data: { client_meta },
  } = usePostageBalance()
  const clients = useMemo(() => {
    return Object.entries(client_meta || {}).map(([client_id, meta]) => ({
      ...meta,
      client_id,
    }))
  }, [client_meta])

  useEffect(() => {
    if (Object.keys(client_meta || {}).length > 1 && setHasMultiple) {
      setHasMultiple(true)
    }
  }, [client_meta])

  useEffect(() => {
    if (clients.length === 1) {
      setValue(clients[0].client_id)
    }
  }, [clients])

  return (
    clients.length > 1 && (
      <Box
        sx={{
          flex: 1,
          mr: { md: 3 },
          mb: 6,
          width: '100%',
          ...sx,
        }}
      >
        <TextField
          select
          fullWidth
          size="small"
          label="Client ID"
          value={value || 0}
          onChange={(e) => setValue(e.target.value)}
        >
          <MenuItem value={0} selected={!value}>
            All
          </MenuItem>
          {clients
            .slice()
            .sort((a, b) => a.client_name.localeCompare(b.client_name))
            .map((client) => (
              <MenuItem value={client.client_id}>{client.client_name}</MenuItem>
            ))}
        </TextField>
      </Box>
    )
  )
}

export default ClientSelect
