import React from 'react'
import styled from 'styled-components/macro'

import Button from '@mui/material/Button'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import usePostageInvoice from 'hooks/postage/usePostageInvoice'
import useNotification from 'hooks/context/useNotification'

const StyledRowTableCell = styled(TableCell)`
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 0px;
  white-space: nowrap;
`

function PostageInvoiceWidgetRow({
  row,
  rowKey,
  visibleColumns = [],
  getDataDisplay,
  columns,
}) {
  const { setBasicNotification, setError } = useNotification()
  const { downloadInvoice } = usePostageInvoice({})
  const handleDownload = async (invoiceNumber) => {
    try {
      await downloadInvoice(invoiceNumber)
      setBasicNotification(
        'Invoice has been automatically downloaded to your computer.'
      )
    } catch (err) {
      setError(
        err?.response?.data?.display_message ||
          err?.message ||
          'Unable to download invoice.'
      )
    }
  }

  return (
    <>
      <TableRow key={row.invoice_number}>
        {visibleColumns.map((col) => (
          <StyledRowTableCell key={col}>
            {getDataDisplay(row, col)}
          </StyledRowTableCell>
        ))}
        <StyledRowTableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownload(row.invoice_number)}
          >
            Download
          </Button>
        </StyledRowTableCell>
      </TableRow>
    </>
  )
}

export default PostageInvoiceWidgetRow
