import React, { useEffect, useMemo, useState } from 'react'
import { swap } from 'react-grid-dnd'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import RefreshIcon from '@mui/icons-material/Refresh'

import DocumentApprovalItem from 'components/dashboard/DocumentApprovaItem'
import DraggableCardGrid from 'components/common/DraggableCardGrid'

import { DashboardProvider } from 'contexts/dashboard/DashboardContext'
import useDashboardContext from 'hooks/context/useDashboardContext'
import OrderApprovalItem from 'components/dashboard/OrderApprovalItem'
import DigitalMailboxItem from 'components/dashboard/DigitalMailboxItem'
import CollateralItem from 'components/dashboard/CollateralItem'
import CorrespondenceItem from 'components/dashboard/CorrespondenceItem'
import OrderHistoryItem from 'components/dashboard/OrderHistoryItem'
import OrderHistoryItemSearch from 'components/dashboard/OrderHistoryItemSearch'
import CorrespondenceItemCounts from 'components/dashboard/CorrespondenceItemCounts'
import CorrespondenceItemSearch from 'components/dashboard/CorrespondenceItemSearch'
import OrderApprovalList from 'components/dashboard/OrderApprovalList'
import DigitalMailboxCounts from 'components/dashboard/DigitalMailboxCounts'

import LandingContent from 'components/landing/LandingContent'

import useConfiguration from 'hooks/useConfiguration'
import { WIDGET_KEYS } from 'utils/constants'
import CollateralCategoryItem from 'components/dashboard/CollateralCategoryItem'
import CorrespondenceSavedSearchesItem from 'components/dashboard/CorrespondenceSavedSearchesItem'
import SftpHistoryItem from 'components/dashboard/SftpHistoryItem'
import useUserWidgets from 'hooks/users/useUserWidgets'
import useAuth from 'hooks/useAuth'
import useWidgets from 'hooks/dashboard/useWidgets'
import AddWidgetMenu from 'components/dashboard/components/AddWidgetMenu'
import deepEqual from 'deep-equal'

const COMPONENT_MAP = {
  [WIDGET_KEYS.DOCUMENT_APPROVAL_DONUT]: DocumentApprovalItem,
  [WIDGET_KEYS.ORDER_APPROVAL_DONUT]: OrderApprovalItem,
  [WIDGET_KEYS.ORDER_APPROVAL_LIST]: OrderApprovalList,
  [WIDGET_KEYS.ORDER_HISTORY_CHART]: OrderHistoryItem,
  [WIDGET_KEYS.ORDER_HISTORY_QUICKSEARCH]: OrderHistoryItemSearch,
  [WIDGET_KEYS.COLLATERAL_BARS]: CollateralItem,
  [WIDGET_KEYS.COLLATERAL_DONUT]: CollateralCategoryItem,
  [WIDGET_KEYS.DIGITAL_MAILBOX_DONUT]: DigitalMailboxItem,
  [WIDGET_KEYS.CORRESPONDENCE_DONUT]: CorrespondenceItem,
  [WIDGET_KEYS.CORRESPONDENCE_DUAL_DONUT]: CorrespondenceItemCounts,
  [WIDGET_KEYS.CORRESPONDENCE_QUICKSEARCH]: CorrespondenceItemSearch,
  [WIDGET_KEYS.CORRESPONDENCE_SAVED_SEARCH]: CorrespondenceSavedSearchesItem,
  [WIDGET_KEYS.DIGITAL_MAILBOX_ASSIGNMENTS]: DigitalMailboxCounts,
  [WIDGET_KEYS.SFTP_STATS_CHART]: SftpHistoryItem,
}

function DashboardContent({ defaultItems = [], updateWidgets }) {
  const { refreshPaused, refreshWidgets, setWidgetMap } = useDashboardContext()
  const isTwoCols = useMediaQuery('(max-width: 1919px) and (min-width: 1440px)')
  const isThreeCols = useMediaQuery('(min-width: 1920px)')

  const boxesPerRow = useMemo(() => {
    if (isTwoCols) return 2
    if (isThreeCols) return 3
    return 1
  }, [isTwoCols, isThreeCols])

  const [items, setItems] = useState(defaultItems)

  const handleChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    try {
      const results = swap(items, sourceIndex, targetIndex)
      updateWidgets(
        results.map((widget, index) => ({
          key: widget.key,
          priority: results.length - index,
        }))
      )
      return setItems(results)
    } catch (err) {}
  }

  useEffect(() => {
    if (!deepEqual(items, defaultItems)) {
      setItems(defaultItems)
      setWidgetMap(defaultItems)
    }
  }, [defaultItems])

  return (
    <Box>
      <Box
        sx={{
          height: '72px',
          background: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          px: 6,
        }}
      >
        <Typography variant="h1" sx={{ mt: 7 }}>
          Dashboard
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ my: 'auto' }}>
            <AddWidgetMenu />
          </Box>
          <Box sx={{ my: 'auto' }}>
            <IconButton
              sx={{ my: 'auto' }}
              title="Refresh All Widgets"
              disabled={refreshPaused}
              onClick={() => refreshWidgets()}
            >
              <RefreshIcon color={!refreshPaused ? 'primary' : 'secondary'} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        fullWidth
        sx={{
          height: '1px',
          bgcolor: 'darkblue.main',
          opacity: 0.12,
        }}
      />
      <Box sx={{ px: 10, py: 5, height: '100%', width: '100%' }}>
        {items.length ? (
          <DraggableCardGrid
            handleChange={handleChange}
            items={items}
            dropzoneProps={{ boxesPerRow: boxesPerRow }}
          />
        ) : (
          <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
            <Typography m="auto" color="darkgray.main">
              No widgets enabled. Use the + icon in the top right of the page to
              add a widget.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

function Dashboard() {
  const { user } = useAuth()
  const { widgets: dashboardWidgets } = useWidgets()
  const { widgets, loading, updateWidgets, mutate } = useUserWidgets(
    user?.userId
  )
  const { getModuleFromName, isInitialized, canAccessModule } =
    useConfiguration()
  const [loaded, setLoaded] = useState(false)
  const { hiddenWidgets } = useDashboardContext()

  const defaultItems = useMemo(() => {
    const widgetMap = {}

    widgets.forEach((widget) => {
      widgetMap[widget.key] = widget
    })

    return dashboardWidgets
      .map((dw) => ({ ...dw, Component: COMPONENT_MAP[dw.key] }))
      .filter((w) => widgetMap[w.key] && !hiddenWidgets.includes(w.key))
      .sort((a, b) => widgetMap[b.key].priority - widgetMap[a.key].priority)
  }, [getModuleFromName, widgets, hiddenWidgets, dashboardWidgets])

  // set user widgets if they have none
  useEffect(() => {
    if (widgets.length > 0) {
      if (widgets?.[0]?.id === 0) {
        updateWidgets(
          dashboardWidgets.map((widget, index) => ({
            key: widget.key,
            priority: dashboardWidgets.length - index,
          }))
        ).then(() => {
          mutate()
        })
      }
    }
  }, [widgets, dashboardWidgets])

  if (!isInitialized || (loading && !loaded)) {
    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <CircularProgress sx={{ m: 'auto' }} />
      </Box>
    )
  }

  if (!loaded) {
    setLoaded(true)
  }

  if (!canAccessModule('/main/dashboard')) {
    return <LandingContent />
  }

  return (
    <>
      <DashboardContent
        defaultItems={defaultItems}
        updateWidgets={updateWidgets}
      />
    </>
  )
}

function DashboardWrapper() {
  return (
    <DashboardProvider>
      <Dashboard />
    </DashboardProvider>
  )
}

export default DashboardWrapper
