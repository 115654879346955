import React, { useMemo, useEffect, useState } from 'react'
import { PRINT_ASSET_MIME_TYPES } from 'utils/constants'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import FileTypeSquare from './FileTypeSquare'

import getMimeTypeDisplay from 'helpers/css/getMimeTypeDisplay'
import { useLibraryPreview } from 'hooks/library'

function PreviewSquare({ libraryItem }) {
  const [cachedAsset, setCachedAsset] = useState({})
  const { preview, refetch, loading } = useLibraryPreview({
    libraryItem,
    version: libraryItem?.version,
  })

  const fileTypeDisplay = useMemo(() => {
    let display = 'N/A'

    if (!libraryItem?.mime_type) {
      return display
    }

    if (libraryItem.mime_type.includes('text')) {
      return 'TXT'
    }

    Object.entries(PRINT_ASSET_MIME_TYPES).forEach(([key, value]) => {
      if (value === libraryItem?.mime_type) {
        display = key
      }
    })

    if (libraryItem?.mime_type && display === 'N/A') {
      return getMimeTypeDisplay(libraryItem.mime_type).toUpperCase()
    }

    return display
  }, [libraryItem])

  useEffect(() => {
    if (
      cachedAsset.id !== libraryItem?.id ||
      cachedAsset.version !== libraryItem?.version
    ) {
      refetch()
      setCachedAsset(libraryItem)
    }
  }, [libraryItem])

  if (loading) {
    return (
      <Box sx={{ height: '76px', width: '76px', display: 'flex' }}>
        <CircularProgress sx={{ m: 'auto' }} />
      </Box>
    )
  }

  if (
    `${libraryItem?.mime_type}`.includes('png') ||
    `${libraryItem?.mime_type}`.includes('jpg') ||
    `${libraryItem?.mime_type}`.includes('jpeg')
  ) {
    let src = ''

    try {
      src = URL.createObjectURL(preview)
    } catch (err) {
      // pass
    }

    return (
      <Box
        component="img"
        sx={{ height: '76px', width: '76px' }}
        src={src}
        alt=""
      />
    )
  }

  return <FileTypeSquare>{fileTypeDisplay}</FileTypeSquare>
}

export default PreviewSquare
