import React, { useState } from 'react'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MMGAgencyPreferencesExpandedList from 'components/common/ExpandedList/AgencyPreferences/MMG'

function AgencyPreferencesCell({ row, colId, getDataDisplay }) {
  return (
    <TableCell sx={{ borderBottom: 0 }}>{getDataDisplay(row, colId)}</TableCell>
  )
}

function AgencyPreferencesRow({
  row,
  rowKey,
  visibleColumns = [],
  getDataDisplay,
  openAgencyModal,
  columns,
}) {
  const [expanded, setExpanded] = useState(false)

  const hasMailingPreferences = Boolean(
    row?.mailing_preferences &&
      Object.keys(row?.mailing_preferences || {}).length > 0
  )

  return (
    <>
      <TableRow key={row[rowKey]} sx={{ border: 0 }}>
        {visibleColumns.map((col) => (
          <>
            <AgencyPreferencesCell
              key={`${row[rowKey]}-${col}`}
              row={row}
              getDataDisplay={getDataDisplay}
              colId={col}
            />
          </>
        ))}
        <TableCell sx={{ borderBottom: 0 }}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => openAgencyModal(row, 'edit')}
            sx={{ mr: 2, my: 'auto' }}
          >
            Edit
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => openAgencyModal(row, 'preferences')}
            sx={{ mr: 2, my: 'auto' }}
          >
            Preferences
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => openAgencyModal(row, 'activity')}
            sx={{ my: 'auto' }}
          >
            Changelog
          </Button>
        </TableCell>
        <TableCell sx={{ borderBottom: 0 }}>
          <IconButton
            sx={{
              float: 'right',
              visibility: !hasMailingPreferences && 'hidden',
            }}
            edge="end"
            aria-label="expand"
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: expanded && 'hover.main' }}>
        <TableCell sx={{ pb: 0, pt: 0 }} colSpan={12}>
          {hasMailingPreferences && (
            <MMGAgencyPreferencesExpandedList
              expanded={expanded}
              visibleColumns={visibleColumns}
              getDataDisplay={getDataDisplay}
              rowData={row}
              columns={columns}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

export default AgencyPreferencesRow
