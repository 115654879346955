import React, { useState } from 'react'

import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'

import DateRangePicker from 'components/common/DateRangePicker'
import Modal from 'components/common/Modal'

import DateRangeIcon from '@mui/icons-material/DateRange'

import useNotification from 'hooks/context/useNotification'

function DatePickerModal({ open, setOpen, activeReport, handleSave }) {
  const { setError } = useNotification()
  const [dateRange, setDateRange] = useState([undefined, undefined])

  const validateDateRange = (dateRangeToValidate) => {
    if (
      dateRangeToValidate?.[0] === undefined ||
      dateRangeToValidate?.[1] === undefined
    ) {
      setError('Please select a valid date range')
      return false
    }

    return true
  }

  const handleClick = () => {
    let [startDate, endDate] = dateRange || []

    if (startDate && !endDate) {
      endDate = startDate
    }

    if (validateDateRange([startDate, endDate])) {
      handleSave(activeReport, [startDate, endDate])
    }
  }

  const footerButtonProps = [
    {
      children: 'Save',
      color: 'primary',
      variant: 'contained',
      onClick: () => handleClick(),
    },
    {
      children: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      onClick: () => setOpen(false),
    },
  ]

  return (
    <Modal
      size="md"
      title="Select date range"
      open={open}
      onClose={() => setOpen(false)}
      footerButtonProps={footerButtonProps}
    >
      <Box sx={{ mt: 3 }}>
        <DateRangePicker
          value={dateRange}
          setValue={setDateRange}
          fullWidth
          label="Date"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <DateRangeIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Modal>
  )
}

export default DatePickerModal
