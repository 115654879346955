import React from 'react'

import ActionButtons from './ActionButtons'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Popover from '@mui/material/Popover'

function ParanoidPopover({
  cancelAction,
  acceptAction,
  children,
  sx,
  ...props
}) {
  const actionProps = [
    {
      children: 'Proceed',
      color: 'primary',
      variant: 'contained',
      onClick: acceptAction,
    },
    {
      children: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      onClick: cancelAction,
    },
  ]

  return (
    <Popover {...props}>
      <Card sx={{ p: 4, ...sx }}>
        {children}
        <Box sx={{ p: 4 }}>
          <ActionButtons footer actionProps={actionProps} />
        </Box>
      </Card>
    </Popover>
  )
}

export default ParanoidPopover
