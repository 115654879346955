import React, { useState } from 'react'

import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import OrderHistoryExpandedList from 'components/common/ExpandedList/OrderHistory'

function OrderHistoryCell({ row, colId, getDataDisplay }) {
  return (
    <TableCell sx={{ borderBottom: 0 }}>{getDataDisplay(row, colId)}</TableCell>
  )
}

function OrderHistoryRow({
  row,
  rowKey,
  visibleColumns = [],
  getDataDisplay,
  columns,
}) {
  const [expanded, setExpanded] = useState()

  return (
    <>
      <TableRow
        key={row[rowKey]}
        sx={{ border: 0, backgroundColor: expanded && 'hover.main' }}
      >
        {visibleColumns.map((col) => (
          <OrderHistoryCell
            key={`${row[rowKey]}-${col}`}
            row={row}
            getDataDisplay={getDataDisplay}
            colId={col}
          />
        ))}
        <TableCell sx={{ borderBottom: 0 }}>
          <IconButton
            sx={{ float: 'right' }}
            edge="end"
            aria-label="expand"
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: expanded && 'hover.main' }}>
        <TableCell sx={{ pb: 0, pt: 0 }} colSpan={12}>
          <OrderHistoryExpandedList
            expanded={expanded}
            visibleColumns={visibleColumns}
            getDataDisplay={getDataDisplay}
            rowData={row}
            columns={columns}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

export default OrderHistoryRow
