import { useState, useEffect, useMemo } from 'react'
import useSWR from 'swr'

import fetcher from 'utils/fetcher'
import { APP_KEY, LETTER_TYPES, MEDICARE_OPTIONS } from 'utils/constants'

import useAuth from 'hooks/useAuth'
import useConfiguration from 'hooks/useConfiguration'

const useSavedSearch = ({ moduleId = 5 }) => {
  const [savedSearches, setSavedSearches] = useState([])
  const {
    user: { instanceId },
  } = useAuth()
  const { fieldDefinitions } = useConfiguration()

  const abstractFieldData = (field) => {
    if (fieldDefinitions[field.path]) {
      const fieldData = {
        ...field,
        ...fieldDefinitions[field.path],
      }

      if (field.path === '_.metadata.LetterType' && LETTER_TYPES[instanceId]) {
        fieldData.options = LETTER_TYPES[instanceId]
        fieldData.data_type = 'select'
      }

      if (
        field.path === '_.metadata.Medicare' &&
        MEDICARE_OPTIONS[instanceId]
      ) {
        fieldData.options = MEDICARE_OPTIONS[instanceId]
        fieldData.data_type = 'select'
      }

      return fieldData
    } else {
      return {
        ...field,
        display: field.path.replace(/_/gm, ' '),
      }
    }
  }

  const abstractSfc = (fieldset) => {
    return {
      ...fieldset,
      fields: fieldset.fields.search_fields.map(abstractFieldData),
      resultColumns: fieldset.fields.result_columns.map(abstractFieldData),
    }
  }

  const setSearchConfig = async (searchFieldConfigurations) => {
    const savedSearchFieldConfigurations =
      searchFieldConfigurations.map(abstractSfc)

    setSavedSearches(savedSearchFieldConfigurations)
  }

  const { data, error, mutate } = useSWR(
    moduleId === undefined
      ? null
      : `/modules/${moduleId}/search-field-configurations?app_key=${APP_KEY}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const defaultSearch = useMemo(() => {
    if (Array.isArray(savedSearches)) {
      return savedSearches.find((ss) => ss.is_default)
    }

    return null
  }, [savedSearches])

  useEffect(() => {
    const sfcs = data?._embedded?.search_field_configurations || []
    setSearchConfig(sfcs)
  }, [data, fieldDefinitions])

  return {
    loading: savedSearches.length <= 0,
    defaultSearch,
    mutate,
    savedSearches,
    error,
    fieldDefinitions,
  }
}

export default useSavedSearch
