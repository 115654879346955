import isEmptyObject from './isEmptyObject'

/**
 * sorts all arrays in an object
 * @param obj - object to perform sort on
 * @return sorted object
 */
function recursiveSort(obj, compareFn = undefined) {
  if (!obj || isEmptyObject(obj)) return obj

  const newObj = { ...obj }

  Object.values(newObj).forEach((val) => {
    if (Array.isArray(val)) {
      return val.sort(compareFn)
    }

    if (typeof val === 'object') {
      return recursiveSort(val)
    }
  })

  return newObj
}

export default recursiveSort
