import React from 'react'
import { Navigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import useAuth from 'hooks/useAuth'

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth()

  if (!isAuthenticated && isInitialized) {
    return (
      <Navigate
        to={`/auth/login/${user.instanceId || localStorage.instanceId || ''}`}
      />
    )
  } else if (!isInitialized) return <Box />

  return <>{children}</>
}

export default AuthGuard
