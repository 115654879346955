import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/styles/useTheme'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

import usePostageInvoiceHistory from 'hooks/postage/usePostageInvoiceHistory'
import useDataList from 'hooks/useDataList'

import Table from 'components/common/DataList/Table'
import PostageInvoiceWidgetRow from 'components/common/DataList/Item/PostageInvoiceWidgetRow'

const ScrollableCard = styled(Card)`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.darkgray.light};
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
`

function PostageInvoices({ clientId, showClientId }) {
  const [sortList, setSortList] = useState({
    'sort[0]': 'datetime_updated:desc',
  })
  const { invoices, loading, total } = usePostageInvoiceHistory({
    'paging[page]': 1,
    'paging[page_size]': 5,
    client_id: clientId,
    ...sortList,
  })
  const {
    dataList,
    dataListProps: { getDataDisplay, visibleColumns },
    columns,
  } = useDataList({
    baseRowData: invoices,
    presetName: 'postageInvoices',
    setSortList,
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const renderViewAll = useMemo(() => {
    const href = '/command-center/postage/invoices'
    const ButtonComponent = isMobile ? IconButton : Button

    let buttonProps = {
      sx: {
        whiteSpace: 'nowrap',
        minWidth: '120px',
        overflow: 'hidden',
        textOverflow: 'truncate',
      },
      endIcon: <ArrowRightAltIcon />,
      color: 'secondary',
      variant: 'contained',
    }

    if (isMobile) {
      buttonProps = {
        color: 'primary',
      }
    }

    return (
      <ButtonComponent
        title="View All"
        LinkComponent={Link}
        to={clientId ? `${href}?clientId=${clientId}` : href}
        {...buttonProps}
      >
        {isMobile ? <ArrowRightAltIcon /> : 'View All'}
      </ButtonComponent>
    )
  }, [isMobile, clientId])

  return (
    <ScrollableCard
      sx={{
        pt: 7,
        boxShadow: 3,
        overflowX: 'auto',
        position: 'relative',
        height: !showClientId ? '385px' : '449px',
      }}
    >
      <Box sx={{ position: 'absolute', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: 4,
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={isMobile ? '16px' : '24px'}
            sx={{ whiteSpace: 'nowrap', my: 'auto' }}
          >
            Postage Invoices
          </Typography>
          {!loading && renderViewAll}
        </Box>
        <Box sx={{ width: '100%', height: '100%' }}>
          {loading ? (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mx: 'auto' }}>
                <CircularProgress />
              </Box>
            </Box>
          ) : (
            <Table
              dataList={dataList}
              getDataDisplay={getDataDisplay}
              RowComponent={PostageInvoiceWidgetRow}
              rowKey="invoice_number"
              columns={columns}
              visibleColumns={visibleColumns}
              sortList={sortList}
              setSortList={setSortList}
              height="auto"
              extraColumns={
                <>
                  <TableCell
                    sx={{
                      backgroundColor: 'white',
                      borderBottom: '1px solid',
                      borderColor: 'lightgray.main',
                    }}
                  ></TableCell>
                </>
              }
              total={total ?? 5}
              colProps={{
                sx: {
                  fontWeight: 700,
                  whiteSpace: 'nowrap',
                },
              }}
            />
          )}
        </Box>
      </Box>
    </ScrollableCard>
  )
}

export default PostageInvoices
