import React from 'react'

import Modal from 'components/common/Modal'
import Search from '../Search'
import useDashboardContext from 'hooks/context/useDashboardContext'

export default function SearchModal({ open, name, ...props }) {
  const { setSearchModalProps } = useDashboardContext()

  return (
    <Modal
      title={`Correspondence Search ${name ? `(${name})` : ''}`}
      size=""
      height="90vh"
      width="60vw"
      open={open}
      hideButtonsBorder
      onClose={() => setSearchModalProps({ open: false })}
    >
      <Search
        isPage={false}
        hideHeaderActions
        extraFooterActionProps={[
          {
            children: 'Close',
            color: 'primary',
            variant: 'outlined',
            onClick: () => setSearchModalProps({ open: false }),
          },
        ]}
        {...props}
      />
    </Modal>
  )
}
