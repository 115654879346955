import useSWR from 'swr'

import axios from 'utils/axios'
import { etagFetcher } from 'utils/fetcher'

import urlSerialize from 'helpers/browser/urlSerialize'

const useDocumentInboundNotes = (inboundId, params = { type: 'User' }) => {
  const { data, isValidating, mutate } = useSWR(
    inboundId !== undefined
      ? `/document-inbound-items/${inboundId}/notes?${urlSerialize(params)}`
      : null,
    etagFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const makeNote = async (
    note,
    docInboundId = inboundId,
    activity_type = undefined
  ) => {
    const res = await axios.post(
      `/document-inbound-items/${docInboundId}/notes`,
      {
        note,
        activity_type,
      }
    )

    mutate()

    return res
  }

  return {
    notes: data?._embedded?.notes ?? [],
    loading: (!data?._embedded?.notes && !data?.error) || isValidating,
    error: data?.error,
    mutate,
    makeNote,
  }
}

export default useDocumentInboundNotes
