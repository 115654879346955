import React from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'

import GlobalStyle from 'components/GlobalStyle'

import { ReactComponent as Logo } from 'svgs/logos/MPXLinq_Color.svg'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdfRounded'
import MPXCopyright from 'components/layout/MPXCopyright'

const PDF_PATH = '/static/files/pdfs'

const PDFS = [
  'AdminUserSettings.pdf',
  'Bookmarks.pdf',
  'Collateral.pdf',
  'Correspondence.pdf',
  'DigitalMailbox.pdf',
  'DocumentApproval.pdf',
  'Library.pdf',
  'OrderApproval.pdf',
  'OrderHistory.pdf',
  'PasswordChange.pdf',
  'Postage.pdf',
]

function HelpPage() {
  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <Container maxWidth="md">
        <Box sx={{ width: '25%', mx: 'auto' }}>
          <Logo />
        </Box>
        <Typography variant="h1" textAlign="center">
          User Guides
        </Typography>
        <Typography mb={2}>
          Welcome to MPXLinq - a redesigned, modern, faster, and easier way to
          access all of your client communications and manage your MPX order and
          processing activity in real-time. Click a document below to read more
          about a specific module, what's changed, and how to get started with
          MPXLinq today.
        </Typography>
        <List>
          {PDFS.map((pdf) => (
            <ListItem
              component="a"
              disablePadding
              href={`${PDF_PATH}/${pdf}`}
              target="_blank"
              sx={{ color: 'primary.main' }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <PictureAsPdfIcon sx={{ color: 'error.light' }} />
                </ListItemIcon>
                <ListItemText primary={pdf} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Typography>
          We hope you enjoy using MPXLinq and find its new features helpful.
          Please don't hesitate to provide feedback on your experience.
        </Typography>
      </Container>
      <MPXCopyright />
    </>
  )
}

export default HelpPage
