import { useState, useMemo } from 'react'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import DropdownFromButton from 'components/common/DropdownFromButton'

function AddButton({ type, onAdd, selected = [] }) {
  const { status } = useFormikContext()

  const options = useMemo(() => {
    return status.columns
      .filter((col) => !selected.includes(col.col))
      .map((col) => ({ display: col.col, value: col.col, view: col.view }))
  }, [status, selected])

  return (
    <>
      <DropdownFromButton
        keepOpen
        options={options}
        buttonProps={{
          size: 'small',
          variant: 'contained',
          color: 'secondary',
        }}
        setSelectedOption={onAdd}
      >
        Add
      </DropdownFromButton>
    </>
  )
}

export default function Section({ children, title, onAdd, selected }) {
  const [expanded, setExpanded] = useState(true)

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <IconButton onClick={() => setExpanded(!expanded)} size="small">
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography fontWeight="bold" fontSize="16px" my="auto">
            {title}
          </Typography>
        </Box>
        {Boolean(onAdd) && <AddButton onAdd={onAdd} selected={selected} />}
      </Box>
      <Collapse in={expanded}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  )
}
