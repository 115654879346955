import React, { useCallback } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'

import Box from '@mui/material/Box'

import LoadingModal from 'components/common/LoadingModal'

import axios from 'utils/axios'

import useNotification from 'hooks/context/useNotification'
import useConfiguration from 'hooks/useConfiguration'
import useModal from 'hooks/context/useModal'

const useOrderSummary = ({ generateFormData }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    setModalProps,
    setComponent: setModalComponent,
    setOpen: setModalOpen,
  } = useModal()

  const { canAccessModule, instanceConfigurations } = useConfiguration()

  const canAccessDocApproval = canAccessModule(
    '/command-center/document-approval'
  )

  // value coming from api is string as stored in mpx, false is '0'
  const collateralReviewEnabled =
    instanceConfigurations?.collateral_enable_review !== '0'

  const { setError, setBasicNotification } = useNotification()

  const handleFormDataSubmit = async (formData) => {
    try {
      setModalProps({
        size: 'sm',
        hideButtonsBorder: true,
        title: 'Your order is processing...',
      })
      setModalComponent(LoadingModal)
      setModalOpen(true)

      await axios.post('/collateral-order-drafts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      const res = await axios.post('/collateral-orders', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      const { letters } = res.data._embedded
      const plural = letters?.length > 1

      setBasicNotification(
        <>
          Thank you for submitting the order.
          {formData.get('is_email_delivery') && (
            <> The email will be queued and sent shortly.</>
          )}
          {formData.get('is_mail_delivery') && letters?.length > 0 && (
            <>
              {collateralReviewEnabled ? (
                <>
                  <Box>
                    The letter{plural && 's'} that will be sent{' '}
                    {plural ? 'have' : 'has'} the id
                    {plural && 's'} of{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {letters.join(', ')}
                    </Box>
                    .
                  </Box>
                  {canAccessDocApproval && (
                    <Box>
                      Check{' '}
                      <Link to="/command-center/document-approval">
                        Doc Approval
                      </Link>
                      .
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {letters.length} document{plural ? 's have' : ' has'} been
                  successfully queued for mailing.
                </>
              )}
            </>
          )}
        </>
      )

      setModalOpen(false)

      navigate('/correspondence-hub/collateral')
    } catch (err) {
      setModalOpen(false)
      setError(err.response?.data?.display_message || 'Error submitting order')
    }
  }

  const handleSubmit = useCallback(
    (cart, values) => {
      const formData = generateFormData(
        { ...location.state.values, ...values },
        cart,
        {},
        false
      )

      handleFormDataSubmit(formData)
    },
    [handleFormDataSubmit, location, generateFormData]
  )

  return {
    handleSubmit,
  }
}

export default useOrderSummary
