import { THEMES, COLOR_SHADES } from 'utils/constants'

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: 'light',
    primary: {
      main: COLOR_SHADES.NAVY_BLUE[1],
      light: COLOR_SHADES.NAVY_BLUE[3],
      opaque: COLOR_SHADES.NAVY_BLUE[0],
      contrastText: COLOR_SHADES.WHITE[0],
    },
    secondary: {
      main: COLOR_SHADES.NAVY_BLUE[4],
      contrastText: COLOR_SHADES.NAVY_BLUE[0],
    },
    disabled: {
      main: COLOR_SHADES.DARK_GRAY[1],
      contrastText: COLOR_SHADES.WHITE[0],
    },
    green: {
      main: COLOR_SHADES.GREEN[0],
      light: COLOR_SHADES.GREEN[1],
      lightest: COLOR_SHADES.GREEN[2],
      contrastText: COLOR_SHADES.WHITE[0],
    },
    darkblue: {
      main: COLOR_SHADES.DARK_BLUE[0],
      light: COLOR_SHADES.DARK_BLUE[1],
      contrastText: COLOR_SHADES.WHITE[0],
    },
    darkgray: {
      main: COLOR_SHADES.DARK_GRAY[0],
      light: COLOR_SHADES.DARK_GRAY[1],
      contrastText: COLOR_SHADES.WHITE[0],
    },
    medgray: {
      main: COLOR_SHADES.MEDIUM_GRAY[0],
      light: COLOR_SHADES.MEDIUM_GRAY[1],
      contrastText: COLOR_SHADES.WHITE[0],
    },
    lightgray: {
      main: COLOR_SHADES.LIGHT_GRAY[0],
      light: COLOR_SHADES.LIGHT_GRAY[1],
    },
    lightblue: {
      main: COLOR_SHADES.LIGHT_BLUE[0],
      light: COLOR_SHADES.LIGHT_BLUE[1],
    },
    yellow: {
      main: COLOR_SHADES.YELLOW[0],
      light: COLOR_SHADES.YELLOW[1],
    },
    background: {
      main: COLOR_SHADES.LIGHT_GRAY[0],
      default: COLOR_SHADES.LIGHT_GRAY[1],
      paper: COLOR_SHADES.WHITE[0],
    },
    text: {
      primary: COLOR_SHADES.DARK_BLUE[0],
      secondary: COLOR_SHADES.NAVY_BLUE[0],
      main: COLOR_SHADES.DARK_GRAY[0],
      disabled: COLOR_SHADES.DARK_GRAY[1],
      error: COLOR_SHADES.YELLOW[0],
    },
    error: {
      main: COLOR_SHADES.RED[0],
    },
    warning: {
      main: COLOR_SHADES.YELLOW[0],
    },
    info: {
      main: COLOR_SHADES.BLUE[0],
    },
    success: {
      main: COLOR_SHADES.GREEN[0],
    },
    hover: {
      main: COLOR_SHADES.LIGHT_GRAY[1],
      opaque: COLOR_SHADES.LIGHT_GRAY[2],
    },
    purple: {
      main: COLOR_SHADES.PURPLE[0],
      light: COLOR_SHADES.PURPLE[1],
    },
    teal: {
      main: COLOR_SHADES.TEAL[0],
      light: COLOR_SHADES.TEAL[1],
    },
    lightyellow: {
      main: COLOR_SHADES.LIGHT_YELLOW[0],
      hover: COLOR_SHADES.LIGHT_YELLOW[1],
      light: COLOR_SHADES.LIGHT_YELLOW[2],
    },
  },
  header: {
    color: COLOR_SHADES.DARK_BLUE[0],
    background: COLOR_SHADES.WHITE[0],
  },
  sidebar: {
    color: COLOR_SHADES.DARK_BLUE[0],
    background: COLOR_SHADES.WHITE[0],
  },
}

const variants = [defaultVariant]

export default variants
