import React, { useState, useCallback, useEffect, useMemo } from 'react'
import add from 'date-fns/add'

import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import ExpandedListHR from 'components/styled/ExpandedListHR'

import { useUserData } from 'hooks/users'

function OrderProgress({ order, label }) {
  const [percentComplete, setPercentComplete] = useState()
  const { formatUserTimeZone } = useUserData()

  const processingDate = useMemo(() => {
    if (!order) return null

    const workflowStatuses = order?._embedded?.workflow_statuses || []
    const processingStatuses = workflowStatuses
      .filter((status) => status?.status?.toLowerCase() === 'processing')
      .sort(
        (a, b) => new Date(b.datetime_changed) - new Date(a.datetime_changed)
      )
    const maxProcessStatus = processingStatuses[0]

    if (maxProcessStatus) {
      return new Date(maxProcessStatus.datetime_changed)
    }

    return new Date(order.datetime_created)
  }, [order])

  const estimatedCompleteDate = useMemo(() => {
    let totalTime =
      order?._embedded?.processing_statistics?.totals_estimated?.processing_time

    if (!processingDate || !totalTime) {
      return null
    }

    return add(processingDate, { seconds: totalTime })
  }, [order, processingDate])

  const refreshPercentComplete = useCallback(() => {
    if (!estimatedCompleteDate || !order || !processingDate) return null

    const currentTime = new Date().getTime()
    const creationTime = processingDate.getTime()
    const completionTime = estimatedCompleteDate.getTime()

    setPercentComplete(
      Math.max(
        Math.min(
          Math.abs(
            Math.round(
              ((currentTime - creationTime) / (completionTime - creationTime)) *
                100
            )
          ),
          100
        )
      ),
      0
    )
  }, [processingDate, estimatedCompleteDate, setPercentComplete])

  useEffect(() => {
    if (!order) return

    const handleTimeout = () => {
      refreshPercentComplete()
    }

    const interval = setInterval(handleTimeout, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [refreshPercentComplete])

  if (!order || !percentComplete) return <></>

  return (
    <Box>
      {label && (
        <Box>
          <Typography fontWeight="medium" fontSize={14}>
            {label}
          </Typography>
          <ExpandedListHR />
        </Box>
      )}
      <Box sx={{ width: '50%' }}>
        <LinearProgress variant="determinate" value={percentComplete} />
        <Typography component="span" fontSize={10}>
          {percentComplete}% Complete
        </Typography>
        {estimatedCompleteDate && (
          <Typography component="span" fontSize={10}>
            {' '}
            | {percentComplete >= 100
              ? 'Completed'
              : 'Estimated completion'}:{' '}
            {formatUserTimeZone(estimatedCompleteDate)}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default OrderProgress
