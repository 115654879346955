import { useState } from 'react'

import axios from 'utils/axios'

const useDocumentContent = ({ document, params = {} }) => {
  const [missingContent, setMissingContent] = useState({})
  const [foundContent, setFoundContent] = useState({})
  const [content, setContent] = useState(null)
  const documentId = document?.id

  const getContentByDocumentId = async (docId) => {
    if (!docId || !document) return null

    let contentType = document.content_type?.toLowerCase()

    if (contentType.includes('pdf')) contentType = 'application/pdf'

    const responseType =
      contentType.includes('pdf') || contentType.includes('image')
        ? 'blob'
        : undefined

    try {
      const res = await axios.get(`/documents/${docId}`, {
        responseType,
        params,
        headers: {
          Accept: contentType,
        },
      })

      if (res.status === 202) {
        return {
          downloadId: document.id,
        }
      }

      if (res.status !== 200) {
        throw new Error('Document not found')
      }

      return res.data
    } catch (err) {
      return null
    }
  }

  const fetchContent = async () => {
    if (missingContent[documentId]) return false
    if (foundContent[documentId]) {
      setContent(foundContent[documentId])
      return true
    }

    try {
      const contentData = await getContentByDocumentId(documentId)

      if (contentData) {
        setFoundContent({ ...foundContent, [documentId]: contentData })
        setContent(contentData)

        return true
      } else {
        throw new Error('No content could not be found for this document.')
      }
    } catch (err) {
      setContent(null)

      missingContent[documentId] = true
      setMissingContent(missingContent)

      return false
    }
  }

  return {
    fetchContent,
    getContentByDocumentId,
    content,
  }
}

export default useDocumentContent
