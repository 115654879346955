function arrayToObject(arr) {
  if (!Array.isArray(arr) || arr.length === 0) return {}

  const obj = {}

  arr.forEach((val) => {
    obj[val] = val
  })

  return obj
}

export default arrayToObject
