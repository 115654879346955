import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'
import { useSearchParams } from 'react-router-dom'

import DocumentDataList from 'components/document_approval/DocumentDataList'

import TabbedDataListLayout from 'layouts/TabbedDataList'

import useConfiguration from 'hooks/useConfiguration'
import decodeBase64 from 'helpers/node/decodeBase64'

function DocumentList() {
  const [mounted, setMounted] = useState(false)
  const [filterList, setFilterList] = useState({})
  const { currentModule } = useConfiguration()
  const [searchParams] = useSearchParams()
  const csvFilename = `letters-${format(new Date(), 'MM-dd-yyyy-hhmmss')}.csv`

  useEffect(() => {
    const q = searchParams.get('q')

    if (q) {
      try {
        setFilterList(JSON.parse(decodeBase64(q)))
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line
          console.error(err.message)
        }
      }
    }

    setMounted(Boolean(currentModule))
  }, [currentModule, searchParams])

  if (!mounted) return <div></div>

  return (
    <TabbedDataListLayout csvFilename={csvFilename}>
      <DocumentDataList baseFilterList={filterList} />
    </TabbedDataListLayout>
  )
}

export default DocumentList
