import React from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'

import { useUserData } from 'hooks/users'

function HistoryRow({ date, docId, index, reprints }) {
  return (
    <Grid
      container
      sx={{
        minHeight: '32px',
        py: 1,
        borderBottom: index < reprints.length - 1 && '1px solid',
        borderColor: 'lightgray.main',
      }}
      columnSpacing={4}
    >
      <Grid item xs={4}>
        <Typography fontWeight={index === -1 && 'bold'}>{docId}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography fontWeight={index === -1 && 'bold'}>{date}</Typography>
      </Grid>
    </Grid>
  )
}

function ReprintHistoryModal({ reprints, loading, docId, ...props }) {
  const { formatUserTimeZone } = useUserData()

  return (
    <Modal {...props} scrollable>
      <Box>
        {loading ? (
          <CircularProgress />
        ) : reprints.length > 0 ? (
          <>
            <HistoryRow
              date={'DATE REPRINTED'}
              docId={'DOCUMENT ID'}
              index={-1}
              reprints={reprints}
            />
            {reprints.map((item, index) => (
              <HistoryRow
                date={formatUserTimeZone(
                  new Date(item.datetime_created),
                  `M/d/yyyy hh:mm a zzzz`
                )}
                docId={docId}
                index={index}
                reprints={reprints}
              />
            ))}
          </>
        ) : (
          <Typography>No reprints found.</Typography>
        )}
      </Box>
    </Modal>
  )
}

export default ReprintHistoryModal
