import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'

import ItemContainer from './containers/ItemContainer'
import DashboardListItem from './containers/DashboardListItem'

import useOrders from 'hooks/useOrders'
import useDataList from 'hooks/useDataList'
import useDashboardContext from 'hooks/context/useDashboardContext'

import isEmptyObject from 'helpers/node/isEmptyObject'
import { WIDGET_KEYS } from 'utils/constants'

function OrderApprovalListItem({ order, divider, getDataDisplay }) {
  const navigate = useNavigate()
  return (
    <DashboardListItem
      divider={divider}
      buttonProps={{
        children: 'Details',
        color: 'primary',
        onClick: (e) => {
          e.stopPropagation()
          e.preventDefault()
          navigate(`/command-center/order-details/${order.order_id}`)
        },
      }}
    >
      <Box
        sx={{
          pr: 4,
          display: 'flex',
        }}
      >
        {getDataDisplay(order, 'status')}
      </Box>
      <ListItemText
        primary={`ORDER ID ${order.order_id}`}
        secondary={`${order.program_name}`}
        primaryTypographyProps={{
          fontWeight: 'bold',
          fontSize: '12px',
        }}
        secondaryTypographyProps={{
          fontSize: '8px',
          color: 'darkgray.light',
        }}
      ></ListItemText>
    </DashboardListItem>
  )
}

function OrderApprovalList({ id }) {
  const { refreshCount, setWidgetLoaded } = useDashboardContext()
  const { orders, loading, mutate } = useOrders({
    state: 'OrderApproval',
  })

  const prepareRowData = (row) => {
    const updatedLots = row.lots

    if (updatedLots && !isEmptyObject(updatedLots)) {
      Object.keys(row.lots).forEach((key) => {
        delete row.lots[key].lot_date_expected_contractsla
      })
    }

    return {
      order_id: row.id,
      program_name: row.program_name,
      datetime_created: row.datetime_created,
      datetime_approved: row.datetime_approved,
      date_mailed: row.date_mailed,
      pieces: row.counts ? row.counts.pieces : null,
      status: !row.is_on_hold
        ? { status: row.status, display: row.status }
        : { status: row.status, display: 'On Hold' },
      program_id: row.program_id,
      file_name: row.file_name,
      envelopes: row.counts.envelopes,
      images: row.counts.images_clicks,
      lots: updatedLots,
      _embedded: row._embedded,
    }
  }

  const {
    dataList,
    dataListProps: { getDataDisplay },
    loading: dataLoading,
  } = useDataList({
    baseRowData: orders,
    presetName: 'dashboardOrderApproval',
    prepareRowData,
  })

  const dataListLength = dataList?.length || 0

  useEffect(() => {
    mutate()
  }, [refreshCount])

  useEffect(() => {
    if (!loading) {
      setWidgetLoaded(id)
    }
  }, [loading])

  return (
    <ItemContainer
      title="Order Approval"
      loading={loading || dataLoading}
      href="/command-center/order-approval"
      emptyState={dataListLength === 0 && 'No Orders Pending Approval'}
      widgetKey={WIDGET_KEYS.ORDER_APPROVAL_LIST}
    >
      <List disablePadding>
        {dataList.map((order, orderIndex) => (
          <OrderApprovalListItem
            key={order.id}
            divider={orderIndex < order.length - 1}
            getDataDisplay={getDataDisplay}
            order={order}
          />
        ))}
      </List>
    </ItemContainer>
  )
}

export default OrderApprovalList
