import React, { useState, useMemo, useCallback, useEffect } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import TableCell from '@mui/material/TableCell'

import BillEditModal from './BillEditModal'
import BillPreviewModal from './BillPreviewModal'
import ManualBillRow from 'components/common/DataList/Item/ManualBillRow'
import StatusMultiselect from './StatusMultiselect'
import Table from 'components/common/DataList/Table'

import useManualBills from 'hooks/manual_bill/useManualBills'
import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'

import { MANUAL_BILL_DEFAULT_STATUSES } from 'utils/constants'

function ManualBillResultsList() {
  const [activeBill, setActiveBill] = useState(null)
  const [statuses, setStatuses] = useState(MANUAL_BILL_DEFAULT_STATUSES)
  const [showBillPreviewModal, setShowBillPreviewModal] = useState(false)
  const [showBillEditModal, setShowBillEditModal] = useState(false)

  const { setTotal, setTotalPages, page, setPage, pageSize } =
    useDataListContext()

  const hookParams = useMemo(() => {
    const params = {
      'paging[page_size]': pageSize,
      'paging[page]': page,
    }

    if (statuses.length > 0) {
      params['status'] = statuses.join('|')
    }

    return params
  }, [statuses, page, pageSize])

  const {
    manualBills,
    total,
    totalPages,
    loading,
    downloadManualBill,
    mutate,
  } = useManualBills(hookParams)
  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: manualBills,
    presetName: 'manualBill',
  })

  const handleStatusChange = useCallback((newStatuses) => {
    if (newStatuses.length === 0) {
      setStatuses(MANUAL_BILL_DEFAULT_STATUSES)
      return
    }

    setStatuses(newStatuses)
  }, [])

  const handleViewClick = useCallback((manualBill) => {
    setActiveBill(manualBill)
    setShowBillPreviewModal(true)
  }, [])

  const handleDownloadClick = useCallback(
    (manualBill) => {
      downloadManualBill({ billId: manualBill?.id })
    },
    [downloadManualBill]
  )

  const handleEditClick = useCallback((manualBill) => {
    setActiveBill(manualBill)
    setShowBillEditModal(true)
  }, [])

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages)
    }
  }, [total, totalPages])

  useEffect(() => {
    if (page > 1) {
      setPage(1)
    }
  }, [statuses, pageSize])

  return (
    <>
      <BillPreviewModal
        open={showBillPreviewModal}
        setOpen={setShowBillPreviewModal}
        bill={activeBill}
        setBill={setActiveBill}
        mutate={mutate}
      />
      <BillEditModal
        open={showBillEditModal}
        setOpen={setShowBillEditModal}
        bill={activeBill}
        setBill={setActiveBill}
        mutate={mutate}
      />
      <Box sx={{ mb: 4, width: { sm: '100%', md: '50%', lg: '25%' } }}>
        <StatusMultiselect
          placeholder="Status"
          textFieldProps={{ label: 'status', size: 'small' }}
          showChips={false}
          value={statuses}
          onChange={(e) => handleStatusChange(e.target.value)}
        />
      </Box>
      <Card sx={{ boxShadow: 3 }}>
        <Table
          {...dataListProps}
          height="75vh"
          dataList={dataList}
          total={total}
          loading={loading}
          columns={columns}
          RowComponent={ManualBillRow}
          rowProps={{
            onViewClick: handleViewClick,
            onDownloadClick: handleDownloadClick,
            onEditClick: handleEditClick,
          }}
          extraColumns={
            <>
              <TableCell
                sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid',
                  borderColor: 'lightgray.main',
                }}
              ></TableCell>
            </>
          }
        />
      </Card>
    </>
  )
}

export default ManualBillResultsList
