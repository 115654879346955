import React, { useEffect, useState, useCallback } from 'react'
import stateJson from 'json/states.json'

import Autocomplete from './Autocomplete'

function StatesAutocomplete({
  value,
  selectedOption,
  setSelectedOption,
  searchValue,
  setSearchValue,
  ...props
}) {
  const [options] = useState(stateJson)

  const getOptionLabel = useCallback((option) => {
    return option.name || ''
  }, [])

  const handleAutocompleteFilter = useCallback(
    (options) => {
      const filtered = options.filter((option) => {
        return (
          option.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          option?.abbreviation.toLowerCase().includes(searchValue.toLowerCase())
        )
      })
      return filtered
    },
    [searchValue]
  )

  useEffect(() => {
    if (value && selectedOption?.abbreviation !== value) {
      const foundOption = options.find(
        (option) => option.abbreviation.toLowerCase() === value.toLowerCase()
      )

      if (foundOption) {
        setSelectedOption(foundOption)
        setSearchValue(foundOption.name || '')
      }
    } else if (selectedOption) {
      setSearchValue(selectedOption.name || '')
    } else {
      setSearchValue('')
    }
  }, [value, selectedOption])

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      handleFilter={handleAutocompleteFilter}
      {...props}
    />
  )
}

export default StatesAutocomplete
