import React, { useEffect, useState } from 'react'

import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DownloadIcon from '@mui/icons-material/Download'

// local components
import DataListItemWrapper from './components/DataListItemWrapper'

import DownloadsExpandedList from 'components/common/ExpandedList/Downloads'
import ExpandedList from 'components/common/ExpandedList'

import { COLORS } from 'utils/constants'

import useDocumentExtracts from 'hooks/documents/useDocumentExtracts'
import useNotification from 'hooks/context/useNotification'
import { CircularProgress } from '@mui/material'

function DownloadButton({ docExtract }) {
  const [loading, setLoading] = useState(false)
  const { downloadZip } = useDocumentExtracts(null)
  const { setBasicNotification, setError } = useNotification()

  const handleDownload = async () => {
    try {
      setLoading(true)
      await downloadZip(docExtract)

      setBasicNotification(
        `${docExtract.filename} has been automatically downloaded to your computer.`
      )
    } catch (err) {
      setError(err.response?.data?.display_message || err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        '& > .MuiButton-root': {
          mr: 2,
        },
      }}
    >
      <Button
        color="primary"
        variant="outlined"
        size="action-header"
        disabled={loading || docExtract?.status === 'Pending'}
        sx={{
          bgcolor: 'background.paper',
          '&:hover': {
            bgcolor: 'hover.main',
          },
        }}
        onClick={(e) => {
          handleDownload()
          e.stopPropagation()
        }}
        startIcon={
          loading ? (
            <CircularProgress size={12} sx={{ ml: 1 }} />
          ) : (
            <DownloadIcon />
          )
        }
      >
        {loading ? 'Downloading' : 'Download'}
      </Button>
    </Box>
  )
}

function DownloadsItem(props) {
  const {
    rowData,
    columns,
    getDataDisplay,
    statusColName,
    visibleColumns,
    sharedSx,
    expandedListProps,
  } = props
  const [expanded, setExpanded] = useState(false)
  const [status, setStatus] = useState({ color: COLORS.GREEN })

  const shownColumns = visibleColumns
    .map((col) => columns.find((c) => c.id === col))
    .filter((c) => c)

  const { color } = status ?? { color: 'green.main' }

  const getBorderColor = () => {
    return 'background.paper'
  }

  useEffect(() => {
    if (rowData?.[statusColName]) {
      setStatus(rowData[statusColName])
    }
  }, [rowData])

  return (
    <Box
      sx={{
        ...sharedSx,
        borderBottom: '1px solid',
        borderLeft: '4px solid',
        borderLeftColor: getBorderColor(),
        borderBottomColor: 'lightgray.main',
        color: 'darkgray.main',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          overflow: 'hidden',
          my: 'auto',
          width: '100%',
        }}
        secondaryAction={
          <>
            <IconButton
              edge="end"
              aria-label="expand"
              onClick={(e) => {
                e.stopPropagation()
                setExpanded(!expanded)
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </>
        }
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {shownColumns.map((col, colIndex) => (
            <DataListItemWrapper key={col.id} index={colIndex}>
              <Box
                sx={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  flexDirection: 'column',
                  color: col.id === statusColName && color,
                  pr: colIndex === shownColumns.length - 1 ? '128px' : 0,
                }}
              >
                <Box
                  sx={{
                    my: 'auto',
                    fontSize: 14,
                  }}
                >
                  {getDataDisplay(rowData, col.id, statusColName)}
                </Box>
              </Box>
            </DataListItemWrapper>
          ))}
          <DataListItemWrapper index={shownColumns.length + 1}>
            <Box>
              <DownloadButton docExtract={rowData} />
            </Box>
          </DataListItemWrapper>
        </Box>
      </ListItem>
      <ExpandedList
        {...expandedListProps}
        expanded={expanded}
        setExpanded={setExpanded}
        columns={columns}
        rowData={rowData}
        visibleColumns={visibleColumns}
        getDataDisplay={getDataDisplay}
        ExpandedListComponent={DownloadsExpandedList}
      />
    </Box>
  )
}

export default DownloadsItem
