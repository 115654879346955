import { useState, useEffect } from 'react'
import deepEqual from 'deep-equal'

import axios from 'utils/axios'

const useAllLetters = (params, total) => {
  const [prevParams, setPrevParams] = useState({})

  const updatedParams = {
    ...params,
    'paging[page_size]': total,
    'paging[page]': 1,
  }

  const getAllLetters = async () => {
    let letters = []

    if (total && params) {
      try {
        const newParams = { ...updatedParams }
        newParams.fields = undefined // get all fields regardless of search

        const res = await axios.get('/letters', {
          params: {
            ...newParams,
          },
          headers: {
            Accept: 'text/csv',
          },
        })

        if (res.status === 200) return res.data

        letters = res.data._embedded.letters
      } catch (err) {}
    }

    return letters
  }

  const getCsvExport = async () => {
    return getAllLetters()
  }

  useEffect(() => {
    if (!total) return
    if (deepEqual(updatedParams, prevParams)) return
    setPrevParams(updatedParams)
  }, [updatedParams])

  return {
    getAllLetters,
    getCsvExport,
  }
}

export default useAllLetters
