import React from 'react'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import SearchIcon from '@mui/icons-material/Search'

import usePostageInvoice from 'hooks/postage/usePostageInvoice'
import useNotification from 'hooks/context/useNotification'

function PostageInvoiceRowCell({ row, colId, getDataDisplay, isPayment }) {
  return (
    <TableCell
      sx={{
        py: 5,
        backgroundColor: isPayment && 'hover.main',
        color: 'darkgray.main',
      }}
    >
      {getDataDisplay(row, colId)}
    </TableCell>
  )
}

function PostageInvoiceRow({
  row,
  rowKey,
  visibleColumns = [],
  getDataDisplay,
  columns,
}) {
  const { setError, setBasicNotification } = useNotification()
  const { downloadInvoice } = usePostageInvoice({})
  const isPayment = `${row.program_name}`.toLowerCase() === 'payment'
  const handleDownload = async () => {
    try {
      await downloadInvoice(row.invoice_number)
      setBasicNotification(
        'Invoice has been automatically downloaded to your computer.'
      )
    } catch (err) {
      setError(
        err?.response?.data?.display_message ||
          err?.message ||
          'Unable to download invoice.'
      )
    }
  }

  return (
    <>
      <TableRow key={row[rowKey]}>
        {visibleColumns.map((col) => (
          <PostageInvoiceRowCell
            isPayment={isPayment}
            key={`${row[rowKey]}-${col}`}
            row={row}
            getDataDisplay={getDataDisplay}
            colId={col}
          />
        ))}
        <TableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: '100%', mr: 6 }}
              startIcon={<SearchIcon />}
              LinkComponent={Link}
              to={`/command-center/postage/invoices/${row[rowKey]}`}
            >
              View
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '100%' }}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

export default PostageInvoiceRow
