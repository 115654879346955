import React, { useState, useEffect } from 'react'
import deepEqual from 'deep-equal'
import { useLocation, useParams } from 'react-router-dom'

import PDFViewer from 'components/common/PDFViewer'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ScrollableBox from 'components/styled/ScrollableBox'

import useOrderProcessingReport from 'hooks/useOrderProcessingReport'
import useConfiguration from 'hooks/useConfiguration'

function OrderProcessingPdf() {
  const location = useLocation()
  const {
    updatePath,
    navigation: { currentPath },
  } = useConfiguration()
  const { orderId } = useParams()

  const [procReportParams] = useState(location.state)

  const procReport = useOrderProcessingReport(procReportParams)

  // update breadcrumbs based on where the user is from
  useEffect(() => {
    const newPath = [
      ...(location.state?.currentPath || []),
      !location.state?.currentPath && {
        display: `Order Details`,
        href: `/command-center/order-details/${orderId}`,
      },
      {
        display: 'Order Processing Report',
        href: `/command-center/order-details/${orderId}/order-processing-report`,
      },
    ].filter((p) => p)

    if (!deepEqual(newPath, currentPath)) updatePath(newPath)
  }, [currentPath, location, orderId])

  return (
    <>
      {procReport.loading ? (
        <CircularProgress />
      ) : (
        <>
          {procReport.response &&
            procReport.response.type === 'application/pdf' && (
              <PDFViewer
                url={procReport.response}
                fileName={`OrderProcessingReport-${procReportParams.orderId}.pdf`}
                hideLoader
              />
            )}
          {!procReport.response && (
            <Grid item xs={12}>
              <h3> PDF error: not found</h3>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

function OrderProcessingReport() {
  return (
    <ScrollableBox
      sx={{
        px: 10,
        py: 6,
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ height: `100%` }}>
        <OrderProcessingPdf />
      </Box>
    </ScrollableBox>
  )
}

export default OrderProcessingReport
