import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'

function ManualBillCell({ row, colId, getDataDisplay }) {
  return (
    <TableCell sx={{ borderBottom: 0 }}>{getDataDisplay(row, colId)}</TableCell>
  )
}

function ManualBillRow({
  row,
  rowKey,
  visibleColumns = [],
  getDataDisplay,
  onEditClick,
  onDownloadClick,
  onViewClick,
  columns,
}) {
  return (
    <>
      <TableRow key={row[rowKey]} sx={{ border: 0 }}>
        {visibleColumns.map((col) => (
          <ManualBillCell
            key={`${row[rowKey]}-${col}`}
            row={row}
            getDataDisplay={getDataDisplay}
            colId={col}
          />
        ))}
        <TableCell sx={{ borderBottom: 0 }}>
          <Box sx={{ display: 'flex' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: '100%', my: 'auto', mr: 2 }}
              size="action-header"
              startIcon={<SearchIcon />}
              onClick={() => {
                onViewClick(row)
              }}
            >
              View
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: '100%', my: 'auto', mr: 2 }}
              size="action-header"
              startIcon={<DownloadIcon />}
              onClick={() => {
                onDownloadClick(row)
              }}
            >
              Download
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: '100%', my: 'auto' }}
              size="action-header"
              startIcon={<EditIcon />}
              onClick={() => {
                onEditClick(row)
              }}
            >
              Edit
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ManualBillRow
