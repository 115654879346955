import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'

import Modal from 'components/common/Modal'
import MultiSelectDropdown from 'components/common/MultiSelectDropdown'
import SplitPreviewModal from './Preview'

import useNotification from 'hooks/context/useNotification'
import useModal from 'hooks/context/useModal'

import renderSplitPDF from 'helpers/pdf/renderSplitPDF'

function SplitModal({
  document,
  open,
  onClose,
  splitItem,
  pageCount,
  fetchPdf,
  pdf,
}) {
  const { setComponent, setModalProps, setOpen: setModalOpen } = useModal()
  const { setError, setBasicNotification, handleClose } = useNotification()
  const [pageError, setPageError] = useState(false)
  const [splitIndexes, setSplitIndexes] = useState([])

  const handleSubmit = async () => {
    try {
      if (splitIndexes.length === 0) {
        setPageError(true)
        return
      }

      const { arrayBuffer } = await renderSplitPDF(pdf, splitIndexes)

      const handleConfirm = async () => {
        try {
          const res = await splitItem({ page_numbers: splitIndexes })

          let newDoc
          const docItems = res.data?._embedded?.document_inbound_items || []
          if (docItems.length > 1) {
            newDoc = docItems[1]
          }

          fetchPdf()

          let content = `Document has been successfully split on the following pages: ${splitIndexes.join(
            ','
          )}.`

          if (newDoc?.document_id) {
            content = (
              <Box>
                {content} See #
                <a
                  href={`/correspondence-hub/digital-mailbox/${newDoc.document_id}`}
                  onClick={handleClose}
                >
                  {newDoc.document_id}
                </a>
                .
              </Box>
            )
          }

          setBasicNotification(content)
        } catch (err) {
          setError('Document could not be split.', err)
        } finally {
          onClose()
          setModalOpen(false)
        }
      }

      const modalProps = {
        title: 'Confirm Split Document',
        subTitle:
          'The following is a preview of the new document that will be created:',
        scrollable: true,
        size: undefined,
        height: '90vh',
        width: '60vw',
        hideButtonsBorder: true,
        footerButtonProps: [
          {
            children: 'Confirm',
            color: 'primary',
            variant: 'contained',
            size: 'action-header',
            onClick: () => {
              handleConfirm()
            },
          },
          {
            children: 'Cancel',
            color: 'primary',
            variant: 'outlined',
            size: 'action-header',
            onClick: () => setModalOpen(false),
          },
        ],
        splitPdf: arrayBuffer,
      }

      setModalProps(modalProps)
      setComponent(SplitPreviewModal)
      setModalOpen(true)
    } catch (err) {
      setError('Document could not be split.', err)
    }
  }

  const modalProps = {
    title: `Split Document ID ${document?.document_id}`,
    subTitle: undefined,
    scrollable: true,
    size: undefined,
    height: '400px',
    width: '732px',
    hideButtonsBorder: true,
    footerButtonProps: [
      {
        children: 'Continue',
        color: 'primary',
        variant: 'contained',
        size: 'action-header',
        onClick: () => handleSubmit(),
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        size: 'action-header',
        onClick: () => onClose(),
      },
    ],
  }

  useEffect(() => {
    if (pageError) setPageError(false)
  }, [splitIndexes])

  useEffect(() => {
    if (!open) {
      setSplitIndexes([])
    }
  }, [open])

  return (
    <Modal {...modalProps} open={open} onClose={onClose}>
      <Box sx={{ pt: 2 }}>
        <MultiSelectDropdown
          options={Array.from({ length: pageCount }).map(
            (_, index) => `${index + 1}`
          )}
          value={splitIndexes}
          name="Pages to include"
          onChange={(e) => setSplitIndexes(e.target.value)}
          getOptionLabel={(option) => `Page ${option}`}
          showChips
          rearrange
          textFieldProps={{
            error: pageError,
            helperText: pageError && 'At least one page is required',
          }}
        />
      </Box>
    </Modal>
  )
}

export default SplitModal
