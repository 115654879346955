const appendHttp = (href) => {
  let newHref = href

  if (typeof href === 'string') {
    newHref = newHref.trim()

    // allow relative paths
    if (newHref.slice(0, 1) === '/') {
      return newHref
    }

    if (!newHref.match(/^http(s|):\/\/.+$/gm)) {
      newHref = `http://${newHref}`
    }
  }

  return newHref
}

export default appendHttp
