import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import useConfiguration from 'hooks/useConfiguration'
import useAuth from 'hooks/useAuth'
import TwoFactorCode from 'components/auth/TwoFactorCode'

function TwoFactor() {
  const [mounted, setMounted] = useState(false)
  const { user, setInstance, isInitialized, hasLoggedIn } = useAuth()
  const { shortName } = useParams()
  const { instanceConfigurations, isSSO } = useConfiguration()

  useEffect(() => {
    if (shortName && user?.instanceId !== shortName) {
      setInstance(shortName)
    }
  }, [isInitialized])

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return <Box></Box>

  if (
    !hasLoggedIn &&
    shortName &&
    (!Object.keys(instanceConfigurations || {}).length || isSSO)
  ) {
    if (isSSO) {
      window.location.replace(
        `${process.env.REACT_APP_BASE_URL}/${shortName}/sso-sessions`
      )
    }
    return <Box></Box>
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
      <Container maxWidth="sm" sx={{ m: 'auto', pb: '226px' }}>
        <Card
          sx={{
            boxShadow: 2,
            height: '50vh',
            px: 10,
            py: 14,
          }}
        >
          <Helmet title="2FA" />
          <TwoFactorCode />
        </Card>
      </Container>
    </Box>
  )
}

export default TwoFactor
