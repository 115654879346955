import React, { useState, useRef, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import { axiosInstance } from 'utils/axios'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useNavigate, useSearchParams, Navigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import CornerLinq from 'layouts/CornerLinq'
import FullBox from 'components/styled/FullBox'
import PasswordCheck from 'components/common/PasswordCheck'
import LoginLoadingScreen from 'components/loaders/LoginLoadingScreen'

import useNotification from 'hooks/context/useNotification'
import useAuth from 'hooks/useAuth'

import { PASSWORD_CHECKS } from 'utils/constants'
import checkPassword from 'helpers/node/checkPassword'

function OneTimePasswordReset() {
  const { logIn } = useAuth()
  const axios = axiosInstance()
  const formikRef = useRef(null)
  const navigate = useNavigate()
  const { setBasicNotification, setError } = useNotification()
  const [searchParams] = useSearchParams()
  const [shortName] = useState(searchParams.get('i'))
  const [username] = useState(searchParams.get('u'))
  const [code] = useState(searchParams.get('c'))
  const [tmpPassword] = useState(searchParams.get('t'))
  const [initialReset] = useState(searchParams.get('x'))
  const [sso] = useState(searchParams.get('s'))
  const [userToken, setUserToken] = useState('')
  const disabled = !shortName || !username || (!code && !tmpPassword && !sso)

  localStorage.removeItem('accessToken')

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const getTitleDisplay = () => {
    return initialReset === '1' ? 'Set new password' : 'Request password reset'
  }

  const getSuccessNotificationDisplay = () => {
    return initialReset === '1'
      ? 'Your password has been successfully set. You may now login.'
      : 'Your password has been successfully reset. You may now login.'
  }

  const handleSubmit = async (values) => {
    try {
      let token = userToken

      // signin user with otp and get bearer token, if not already set
      if (!token) {
        const res = await axios.post(`${shortName}/user-sessions`, {
          username,
          one_time_code: code || sso,
        })

        token = res.data.token
        setUserToken(token)
      }

      // get user id from bearer token
      const { UserID } = jwtDecode(token)

      // set authorization header for the following requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem('accessToken', token)

      // get user etag
      const {
        headers: { etag },
      } = await axios.get(`${shortName}/users/${UserID}`)

      // update user password
      await axios.patch(
        `${shortName}/users/${UserID}`,
        {
          password: values.password,
        },
        {
          headers: {
            'If-Match': etag,
          },
        }
      )

      // remove authorization header
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('accessToken', token)

      // notify user of successful password reset
      setBasicNotification(getSuccessNotificationDisplay())

      // navigate user to login page
      navigate(`/auth/login/${shortName}`)
    } catch (err) {
      setError(
        err.response?.data?.display_message ||
          'Error resetting password. Try again later.'
      )
    }
  }

  const validate = (values) => {
    const errors = {}

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords must match'
    }

    if (!checkPassword(values.password)) {
      errors.password = 'Password must match the criteria below'
    }

    return errors
  }

  useEffect(() => {
    const asyncHandler = async () => {
      if (tmpPassword) {
        try {
          await logIn({
            username,
            password: tmpPassword,
            instanceId: shortName,
            impersonate: true,
          })
        } catch (err) {
          navigate('/')
        }
      }
    }

    asyncHandler()
  }, [tmpPassword])

  useEffect(() => {
    const asyncHandler = async () => {
      if (sso) {
        try {
          await logIn({
            username,
            password: sso,
            instanceId: shortName,
            sso: true,
          })
        } catch (err) {
          navigate('/')
        }
      }
    }

    asyncHandler()
  }, [sso])

  if (disabled) return <Navigate to="/" />
  if (tmpPassword || sso) return <LoginLoadingScreen></LoginLoadingScreen>

  return (
    <CornerLinq>
      <FullBox sx={{ display: 'flex' }}>
        <Container maxWidth="sm" sx={{ mt: '10vh' }}>
          <Card
            sx={{
              boxShadow: 2,
              px: 12,
              py: 14,
            }}
          >
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validate={validate}
              validationSchema={Yup.object().shape({
                password: Yup.string().required('Password is required'),
                confirmPassword: Yup.string().required(
                  'Confirm password is required'
                ),
              })}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                errors,
                isSubmitting,
                handleBlur,
                touched,
                dirty,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Stack spacing={4} mb={4}>
                    <Typography variant="h1" textAlign="center">
                      {getTitleDisplay()}
                    </Typography>
                    <Typography
                      variant="h2"
                      textAlign="center"
                      fontWeight={'medium'}
                    >
                      MPXLinq
                    </Typography>
                    <Box>
                      <TextField
                        fullWidth
                        type="password"
                        label="New Password"
                        onChange={handleChange}
                        name="password"
                        disabled={disabled}
                        error={
                          Boolean(touched.password) && Boolean(errors.password)
                        }
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box>
                      <TextField
                        fullWidth
                        type="password"
                        label="Confirm Password"
                        onChange={handleChange}
                        name="confirmPassword"
                        disabled={disabled}
                        error={
                          Boolean(touched.confirmPassword) &&
                          Boolean(errors.confirmPassword)
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box>
                      <PasswordCheck
                        value={values.password}
                        checks={[
                          ...PASSWORD_CHECKS,
                          {
                            display:
                              'Password and confirmation password must match',
                            cond: (value) =>
                              value && value === values.confirmPassword,
                          },
                        ]}
                      />
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ width: '50%', mx: 'auto' }}
                        onClick={handleSubmit}
                        disabled={
                          !dirty ||
                          isSubmitting ||
                          Boolean(Object.keys(errors).length)
                        }
                      >
                        Set new password
                      </Button>
                    </Box>
                  </Stack>
                </form>
              )}
            </Formik>
            <Box sx={{ width: '100%', display: 'flex', textAlign: 'center' }}>
              <Typography
                fontSize={12}
                color="darkgray.main"
                sx={{ width: '50%', mx: 'auto' }}
              >
                Please contact MPX if you are having trouble logging in.
              </Typography>
            </Box>
          </Card>
        </Container>
      </FullBox>
    </CornerLinq>
  )
}

export default OneTimePasswordReset
