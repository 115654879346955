import { useState, useEffect, useCallback, useMemo } from 'react'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import addMinutes from 'date-fns/addMinutes'
import deepEqual from 'deep-equal'

import Box from '@mui/material/Box'

import dataListPresets, {
  defaultPreset,
} from 'components/common/DataList/dataListPresets'

import useModal from 'hooks/context/useModal'
import { useUserData } from 'hooks/users'
import useConfiguration from './useConfiguration'
import utcToLocalDate from 'helpers/datetime/utcToLocalDate'
import { MODULE_NAMES } from 'utils/constants'

const defaultFilterFormat = ({ id, desc }) => `${id}:${desc ? 'desc' : 'asc'}`

const useDataList = ({
  baseRowData,
  baseColData,
  presetName, // see src/components/common/DataList/dataListPresets.js
  prepareColData,
  prepareRowData,
  setSortList,
  setFilterList,
  getFilterFormat,
  getColumnDisplay,
  getRowDisplay = (rowData, columnName) =>
    rowData[columnName]?.toString() ?? 'N/A',
  moduleName = MODULE_NAMES.CORRESPONDENCE,
  ignoreUserTimezone = false,
}) => {
  const [loading, setLoading] = useState(false)

  const { getMetadataDisplay } = useConfiguration()
  const { formatUserTimeZone } = useUserData()
  const { setOpen, setModalProps } = useModal()

  const [data, setData] = useState({
    dataList: [],
    props: dataListPresets[presetName] ?? defaultPreset,
    filters: {},
  })

  const [columns, setColumns] = useState([])

  const formatFilter = useCallback(getFilterFormat ?? defaultFilterFormat, [
    getFilterFormat,
  ])

  const getRowData = () => {
    const { props } = data
    const rowProps = { ...props }

    if (prepareRowData) rowProps.prepareRowData = prepareRowData

    if (!rowProps.prepareRowData) {
      rowProps.prepareRowData = (row) => row
    }

    return {
      dataList: baseRowData.map(rowProps.prepareRowData),
      rowProps,
    }
  }

  const getColData = (rowProps, colData) => {
    const colProps = { ...rowProps }
    const {
      doSort,
      exactMatch,
      excludedColumns,
      dataListProps: { statusColName },
      colSx,
    } = colProps

    if (prepareColData) colProps.prepareColData = prepareColData
    if (getColumnDisplay) colProps.getColumnDisplay = getColumnDisplay

    if (!colProps.prepareColData) {
      colProps.prepareColData = (columnData) => {
        return Object.keys(columnData)
          .filter((col) => !excludedColumns.includes(col))
          .map((col) => ({
            display:
              getMetadataDisplay(col, true, moduleName) ||
              colProps.getColumnDisplay(col),
            id: col,
            sortable:
              doSort === true ||
              doSort.includes(col) ||
              col.includes('_.metadata'),
            exactMatch: exactMatch.includes(col),
            filter: col === statusColName ? 'status' : undefined,
            sx: colSx?.[col],
          }))
      }
    }

    return {
      columns: colProps.prepareColData(colData),
      props: colProps,
    }
  }

  const filterToString = (filter) => {
    return `${filter.id}|${filter.value}*`
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    const newFilters = { ...data.filters }

    newFilters[name] = {
      id: name,
      value,
    }

    setData({
      ...data,
      filters: newFilters,
    })

    if (setFilterList) {
      let filterList = Object.values(newFilters)
        .filter((f) => f.value) // remove empty strings
        .map(filterToString) // convert items to string
        .join('|') // convert array to string

      setFilterList(filterList)
    }
  }

  const getDataDisplay = useCallback(
    (rowData, columnName) => {
      const { displayOptions } = data.props
      const rowValue = rowData[columnName]

      // null values return N/A
      if (!rowValue?.toString()) return 'N/A'

      // if displayOptions is defined, use it
      if (displayOptions[columnName])
        return displayOptions[columnName](rowValue)

      /*
        handle row values that are arrays
      */
      let tmpSymbol = '|||---|||'

      // if the row value has this symbol for whatever reason, add a pipe until it doesn't
      while (rowValue.toString().includes(tmpSymbol))
        tmpSymbol = `${tmpSymbol}|`

      // exclude comma with space values from split
      const rv = rowValue.toString().replace(/,\s/gm, tmpSymbol)

      // perform split on comma
      const rowValueSplit = rv.split(',')

      // only render See All link if this is a comma-separated list
      // replace spaced comma after split has occurred
      if (rowValueSplit.length > 1) {
        const handleClick = (e) => {
          const modalProps = {
            title: data.props.getColumnDisplay(columnName),
            subTitle: undefined,
            scrollable: true,
            size: 'sm',
            footerButtonProps: [
              {
                children: 'Close',
                color: 'primary',
                variant: 'contained',
                onClick: () => setOpen(false),
                size: 'action-header',
              },
            ],
            children: (
              <>
                {rowValueSplit.map((value, index) => (
                  <Box key={`${value}-${index}`} sx={{ fontSize: '14px' }}>
                    {value.replace(tmpSymbol, ', ')}
                  </Box>
                ))}
              </>
            ),
          }

          setModalProps(modalProps)
          setOpen(true)

          e.stopPropagation()
        }

        return (
          <>
            <Box>{rowValueSplit[0].replace(tmpSymbol, ', ')}</Box>
            <Box
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleClick}
            >
              See All
            </Box>
          </>
        )
      }

      const display = getRowDisplay(rowData, columnName)

      if (display !== rowValue?.toString()) {
        return display
      }

      try {
        columnName = columnName.toLowerCase()
        if (
          columnName.includes('date') &&
          rowValue.match(/^\d{4}-\d{2}-\d{2}$/gm)
        ) {
          let date = new Date(rowValue)
          date = addMinutes(date, date.getTimezoneOffset())
          return format(date, 'MM/dd/yyyy')
        } else if (
          columnName.includes('datetime') ||
          (columnName.includes('date') && rowValue.match(/\d{4}-\d{2}-\d{2}/gm))
        ) {
          let date = new Date(rowValue)

          if (rowValue.match(/\d{1,2}\/\d{2}\/\d{4} \d{1,2}:\d{2}:(am|pm)/)) {
            date = parse(rowValue, 'MM/dd/yyyy h:mm:a', new Date())
          }

          if (ignoreUserTimezone) {
            return format(date, 'M/dd/yyyy h:mm a')
          }

          return formatUserTimeZone(date)
        } else if (
          typeof rowValue === 'string' &&
          rowValue.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/gm) &&
          new Date(rowValue).toString() !== 'Invalid Date'
        ) {
          let date = new Date(rowValue)

          if (ignoreUserTimezone) {
            return format(date, 'M/dd/yyyy h:mm a')
          }

          date = utcToLocalDate(date)

          return formatUserTimeZone(date)
        }
      } catch (err) {
        return 'N/A'
      }

      return display
    },
    [data.props, data.filters, setOpen, setModalProps]
  )

  const setSortingList = useCallback(
    (sortingData) => {
      if (setSortList) {
        const sortList = {}

        sortingData.map(formatFilter).forEach((sd, index) => {
          sortList[`sort[${index}]`] = sd
        })

        setSortList(sortList)
      }
    },
    [setSortList, formatFilter]
  )

  useEffect(() => {
    setLoading(true)
    const { dataList, rowProps } = getRowData()
    const { columns: localColumns, props } = getColData(
      rowProps,
      baseColData ?? dataList[0] ?? []
    )

    if (localColumns?.length && !deepEqual(columns, localColumns)) {
      setColumns(localColumns)
    }

    if (!deepEqual(dataList, data.dataList))
      setData({
        dataList,
        props,
      })

    setLoading(false)
  }, [baseRowData, baseColData, presetName])

  const dataListProps = useMemo(
    () => ({
      ...data.props.dataListProps,
      getDataDisplay,
      setSortingList,
    }),
    [data, getDataDisplay, setSortingList]
  )

  return {
    dataList: data.dataList,
    columns: columns,
    filters: Object.values(data.filters ?? {}),
    handleFilterChange,
    ...data.props,
    dataListProps,
    loading,
  }
}

export default useDataList
