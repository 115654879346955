import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ToggleButton from '@mui/material/ToggleButton'

import TuneIcon from '@mui/icons-material/Tune'

function ReportingHeader({
  handleDownload,
  sidebarOpen,
  setSidebarOpen,
  SidebarIcon = TuneIcon,
  buttons = [],
}) {
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', mb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            height: '100%',
            minWidth: '444px',
            '& > .MuiButton-root': {
              my: 'auto',
            },
          }}
        >
          {buttons?.length ? (
            buttons
          ) : (
            <>
              <Button
                color="secondary"
                variant="contained"
                size="action-footer"
                onClick={() => handleDownload('text/csv')}
                sx={{ mr: 4 }}
              >
                Export to CSV
              </Button>
            </>
          )}
        </Box>
        {setSidebarOpen && (
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              height: '100%',
              minWidth: '440px',
              '& > .MuiButton-root': {
                my: 'auto',
              },
            }}
          >
            <ToggleButton
              color="primary"
              value="list"
              selected={!sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <SidebarIcon />
            </ToggleButton>
          </Box>
        )}
      </Box>
    </>
  )
}

export default ReportingHeader
