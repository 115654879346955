import useSWR from 'swr'

import fetcher from 'utils/fetcher'
import axios from 'utils/axios'

const useManualBill = ({ id }) => {
  const { data, isValidating, mutate } = useSWR(
    id ? `/manual-bill/${id?.toString().trim()}` : null,
    fetcher,
    { revalidateOnFocus: false }
  )

  const updateManualBill = async ({ billId = id, updateData }) => {
    const endpoint = `/manual-bill/${billId}`
    const {
      headers: { etag },
    } = await axios.head(endpoint)
    const res = await axios.patch(endpoint, updateData, {
      headers: {
        'If-Match': etag,
      },
    })
    return res.data
  }

  return {
    manualBill: data || {},
    updateManualBill,
    loading: (!data && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
  }
}

export default useManualBill
