import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'

import Grid from '@mui/material/Grid'

import { Formik, useFormikContext } from 'formik'
import RemovableItems from 'components/common/RemovableItems'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import LoadingModal from 'components/common/LoadingModal'
import FileAttachDropzone from './FileAttachDropzone'

import useModal from 'hooks/context/useModal'
import useNotification from 'hooks/context/useNotification'

export const attachValidationSchema = Yup.object().shape({
  file: Yup.string().typeError('File is required').required('File is required'),
})

const addDisplay = (items) => {
  return items.map((item) => ({ ...item, display: 'LETTER' }))
}

const DOCUMENTS_LIMIT = 100

function AttachFormModal({ items, handleConfirm, open, setOpen }) {
  const [selectedItems, setSelectedItems] = useState(addDisplay(items))
  const {
    setOpen: setModalOpen,
    setModalProps,
    setComponent: setModalComponent,
  } = useModal()
  const { setError } = useNotification()

  const plural = selectedItems.length !== 1 ? 1 : 0
  const itemsDisplay = ['document', 'documents']
  const {
    values,
    errors,
    validateForm,
    handleChange,
    resetForm,
    setFieldError,
  } = useFormikContext()

  const handleRemove = (index) => {
    const newSelectedItems = [...selectedItems]

    newSelectedItems.splice(index, 1)

    setSelectedItems(newSelectedItems)
  }

  const handleCompleteConfirm = async () => {
    const executeConfirmAction = async () => {
      await validateForm()

      if (Object.keys(errors).length > 0) {
        return false
      }

      if (selectedItems.length > DOCUMENTS_LIMIT) {
        setError(`Max ${DOCUMENTS_LIMIT} documents can be attached at one time`)
        return false
      }

      await handleConfirm(selectedItems, { file: values.file })
      return true
    }
    setModalProps({
      size: 'lg',
      hideButtonsBorder: true,
      title: 'Attach File',
    })
    setModalComponent(LoadingModal)
    setModalOpen(true)
    if (await executeConfirmAction()) {
      setOpen(false)
    }
    setModalOpen(false)
  }

  const footerButtonProps = [
    {
      children: 'Attach',
      color: 'primary',
      variant: 'contained',
      onClick: () => handleCompleteConfirm(),
    },
    {
      children: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      onClick: () => setOpen(false),
    },
  ]

  useEffect(() => {
    setSelectedItems(addDisplay(items))
  }, [items])

  useEffect(() => {
    if (selectedItems.length === 0 && open) {
      setOpen(false)
    }
  }, [selectedItems])

  // reset form on modal close
  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open])

  const content = (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      footerButtonProps={footerButtonProps}
      title="Attach File"
      height={'600px'}
      width={'1144px'}
    >
      <Box sx={{ pb: 8, pr: 8 }}>
        <RemovableItems
          items={selectedItems}
          handleRemove={handleRemove}
          idKey="id"
          title="Documents"
          max={5}
        />

        <Box sx={{ mt: 2 }}>
          <Typography color="darkgray.main" mb={6}>
            You are about to attach a file to{' '}
            {`${selectedItems.length} ${itemsDisplay[plural]}`}.
          </Typography>
          <Grid container spacing={6} xs={{ mb: 12 }}>
            <Grid item xs={12}>
              <FileAttachDropzone
                file={values.file}
                onChange={(newFile) =>
                  handleChange({ target: { name: 'file', value: newFile } })
                }
                error={errors.file}
                display="Attach File"
                setFieldError={setFieldError}
              />
            </Grid>
          </Grid>
          <Typography color="darkgray.main">
            Please confirm this action and click Confirm below, or click Cancel
            to go back to the previous screen.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
  return content
}
function AttachModal({ open, setOpen, items, handleConfirm }) {
  return (
    <Formik
      initialValues={{
        file: null,
      }}
      validationSchema={attachValidationSchema}
    >
      <AttachFormModal
        items={items}
        open={open}
        setOpen={setOpen}
        handleConfirm={handleConfirm}
      />
    </Formik>
  )
}

export default AttachModal
