import React, { useState, useEffect, useMemo } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import DropdownFromButton from 'components/common/DropdownFromButton'
import ConfirmationModal from './ConfirmationModal'

import useDocuments from 'hooks/useDocuments'
import useDataListContext from 'hooks/context/useDataListContext'
import useNotification from 'hooks/context/useNotification'
import urlSerialize from 'helpers/browser/urlSerialize'
import {
  useDocumentInboundCategories,
  useDocumentInboundStatuses,
} from 'hooks/digital_mail'
import useAllUsers from 'hooks/users/useAllUsers'
import useConfiguration from 'hooks/useConfiguration'

import { MODULE_NAMES, PRIVILEGES } from 'utils/constants'

function UserButton({ checkedCount, handlePatch, setConfirmModalProps }) {
  const { users: allUsers, loading } = useAllUsers(
    { embed: 'permissions' },
    true
  )

  const users = useMemo(() => {
    // only show users that have access to digital mail
    return allUsers.filter((user) => {
      return user._embedded?.permissions?.modules?.[
        MODULE_NAMES.DIGITAL_MAILBOX
      ]
    })
  }, [allUsers])

  const buttonProps = {
    color: 'primary',
    variant: 'outlined',
    size: 'action-footer',
    disabled: checkedCount <= 0,
  }

  const changeUserOfSelected = async (user, selected) => {
    return handlePatch(selected.slice(), {
      assigned_to_user_id: user.id,
    })
  }

  const handleUserSelect = (user) => {
    const customContent = (selectedCount) => (
      <>
        <Typography color="darkgray.main" pb={1}>
          You are about to assign {selectedCount} document
          {selectedCount !== 1 && 's'} to {user.display}.
        </Typography>
        <Typography color="darkgray.main">
          Please confirm this action and click Confirm below, or click Cancel to
          go back to the previous screen.
        </Typography>
      </>
    )

    const customNotification = (selectedCount) => (
      <>
        {selectedCount} document
        {selectedCount !== 1 && 's'} have been assigned to {user.display}.
      </>
    )

    setConfirmModalProps({
      open: true,
      variant: 'user',
      handleConfirm: async (selected) => {
        const selectedUser = user
        return changeUserOfSelected(selectedUser, selected)
      },
      customTitle: `Assign Documents to ${user.display}`,
      customContent,
      customNotification,
    })
  }

  return (
    <Box>
      <DropdownFromButton
        options={[...users, { id: null, display: 'Unassigned' }]}
        buttonProps={buttonProps}
        setSelectedOption={handleUserSelect}
        loading={loading}
      >
        User
      </DropdownFromButton>
    </Box>
  )
}

function CategoryButton({ checkedCount, handlePatch, setConfirmModalProps }) {
  const { categories } = useDocumentInboundCategories()

  const buttonProps = {
    color: 'primary',
    variant: 'outlined',
    size: 'action-footer',
    disabled: checkedCount <= 0,
  }

  const displayCategories = categories
    .map((category) => ({
      ...category,
      display: category.name,
    }))
    .sort((a, b) => a.display.localeCompare(b.display))

  const changeCategoryOfSelected = async (category, selected) => {
    return handlePatch(selected, {
      category: category.name,
    })
  }

  const handleCategorySelect = (category) => {
    const customContent = (selectedCount) => (
      <>
        <Typography color="darkgray.main" pb={1}>
          You are about to change the category of {selectedCount} document
          {selectedCount !== 1 && 's'} to {category.name}.
        </Typography>
        <Typography color="darkgray.main">
          Please confirm this action and click Confirm below, or click Cancel to
          go back to the previous screen.
        </Typography>
      </>
    )

    const customNotification = (selectedCount) => (
      <>
        Categor{selectedCount !== 1 ? 'ies' : 'y'} of {selectedCount} document
        {selectedCount !== 1 && 's'} have been changed to {category.name}.
      </>
    )

    setConfirmModalProps({
      open: true,
      variant: 'category',
      handleConfirm: async (selected) => {
        const selectedCategory = category
        return changeCategoryOfSelected(selectedCategory, selected)
      },
      customTitle: `Document Category Change to ${category.name}`,
      customContent,
      customNotification,
    })
  }

  return (
    <Box>
      <DropdownFromButton
        options={displayCategories}
        buttonProps={buttonProps}
        setSelectedOption={handleCategorySelect}
      >
        Category
      </DropdownFromButton>
    </Box>
  )
}

function StatusButton({ checkedCount, handlePatch, setConfirmModalProps }) {
  const { statuses } = useDocumentInboundStatuses()

  const buttonProps = {
    color: 'primary',
    variant: 'outlined',
    size: 'action-footer',
    disabled: checkedCount <= 0,
  }

  const displayStatuses = statuses
    .map((status) => ({
      ...status,
      display: status.status,
    }))
    .sort((a, b) => a.display.localeCompare(b.display))

  const changeStatusOfSelected = async (status, selected) => {
    return handlePatch(selected, {
      status: status.status,
    })
  }

  const handleStatusSelect = (status) => {
    const customContent = (selectedCount) => (
      <>
        <Typography color="darkgray.main" pb={1}>
          You are about to change the status of {selectedCount} document
          {selectedCount !== 1 && 's'} to {status.status}.
        </Typography>
        <Typography color="darkgray.main">
          Please confirm this action and click Confirm below, or click Cancel to
          go back to the previous screen.
        </Typography>
      </>
    )

    const customNotification = (selectedCount) => (
      <>
        Status{selectedCount !== 1 && 'es'} of {selectedCount} document
        {selectedCount !== 1 && 's'} have been changed to {status.status}.
      </>
    )

    setConfirmModalProps({
      open: true,
      variant: 'status',
      handleConfirm: async (selected) => {
        const selectedStatus = status
        return changeStatusOfSelected(selectedStatus, selected)
      },
      customTitle: `Document Status Change to ${status.status}`,
      customContent,
      customNotification,
    })
  }

  return (
    <Box>
      <DropdownFromButton
        options={displayStatuses}
        buttonProps={buttonProps}
        setSelectedOption={handleStatusSelect}
      >
        Status
      </DropdownFromButton>
    </Box>
  )
}

function DigitalMailboxHeader({
  getAllDocumentInboundItems,
  tabIndex,
  items,
  setTabIndex,
  updateInboundItem,
  handleSingleDownload,
  documentsLoading,
  total,
  searchParams,
}) {
  const { hasPrivilege, canAccessModule } = useConfiguration()
  const canViewUsers = true
  const canArchive = hasPrivilege(PRIVILEGES.ARCHIVE_ITEM)

  const { checkedCount, checked, allChecked, checkAllPreset, pageSize } =
    useDataListContext()
  const { downloadDocuments, downloadDocumentsBySearch } = useDocuments()
  const { setError } = useNotification()

  // states
  const [confirmModalProps, setConfirmModalProps] = useState(false)
  const [checkedItems, setCheckedItems] = useState(items)
  const [bulkUseSearchParams, setBulkUseSearchParams] = useState(false)
  const [searchedParams, setSearchedParams] = useState([])

  useEffect(() => {
    if (checkedCount === total && total > pageSize) {
      setBulkUseSearchParams(true)
      setCheckedItems([])
      const parsedParams = {}

      if (searchParams && typeof searchParams === 'object') {
        Object.keys(searchParams).forEach((key) => {
          if (
            typeof key !== 'string' ||
            key === 'embed' ||
            key.startsWith('paging') ||
            key.startsWith('sort')
          ) {
            return
          }

          parsedParams[key] = searchParams[key]
        })
      }

      setSearchedParams(parsedParams)
      return
    } else if (allChecked) {
      setCheckedItems(items)
    } else {
      setCheckedItems(
        items
          .map((item, index) => ({ ...item, checkedIndex: index }))
          .filter((_, index) => checked[index])
      )
    }

    setBulkUseSearchParams(false)
  }, [checkedCount, allChecked, checkAllPreset, searchParams])

  const itemName = {
    singular: 'document',
    plural: 'documents',
  }

  const handleDownload = () => {
    const downloadSelected = async (selected) => {
      let success = false

      try {
        if (bulkUseSearchParams) {
          success = await downloadDocumentsBySearch(
            searchedParams,
            '/document-inbound-items/extract' +
              (searchedParams ? `?${urlSerialize({ ...searchedParams })}` : '')
          )
        } else if (selected.length === 1) {
          // success = false also means do not show notification
          success = await handleSingleDownload(
            selected[0]._embedded.document,
            false
          )
        } else if (selected.length > 1) {
          const documentIds = selected.map((doc) => doc.document_id)
          await downloadDocuments(documentIds)
          success = true
        }

        return success
      } catch (err) {
        setError(err.message)

        return false
      }
    }

    const customHeaderContent = bulkUseSearchParams
      ? (selectedCount) => (
          <>
            <Typography color="darkgray.main" pb={1}>
              Search Parameters:
            </Typography>
            {searchedParams &&
            typeof searchedParams === 'object' &&
            Object.keys(searchedParams).length > 0 ? (
              Object.keys(searchedParams).map((key) => {
                let valueDisplay = searchedParams[key] ?? 'NULL'
                if (typeof valueDisplay === 'boolean') {
                  valueDisplay = valueDisplay.toString()
                } else if (typeof valueDisplay === 'object') {
                  if (Array.isArray(valueDisplay)) {
                    valueDisplay = valueDisplay.join(', ')
                  } else {
                    valueDisplay = JSON.stringify(valueDisplay)
                  }
                } else if (!valueDisplay && Number.isNaN(valueDisplay)) {
                  return null
                }
                return (
                  <Typography color="darkgray.main" pb={1}>
                    {key
                      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
                      .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())}
                    : {valueDisplay}
                  </Typography>
                )
              })
            ) : (
              <Typography color="darkgray.main">All Available</Typography>
            )}
          </>
        )
      : null

    const customContent = (selectedCount) => null

    // open confirmation modal
    setConfirmModalProps({
      open: true,
      variant: 'download',
      handleConfirm: downloadSelected,
      customContent,
      customHeaderContent,
    })
  }

  const handlePatch = async (selected, data) => {
    const successfulIndexes = []
    let selectedItems = [...selected]

    // assume a selected length of 0 to be an all-matching query1
    if (selectedItems.length === 0) {
      selectedItems = await getAllDocumentInboundItems()
    }

    try {
      for (let i = 0; i < selectedItems.length; i += 1) {
        await updateInboundItem(
          selectedItems[i].id,
          data,
          i === selectedItems.length - 1
        )

        successfulIndexes.push(i)
      }
    } catch (err) {
      setError(err.message)
    }

    return successfulIndexes.length === selectedItems.length
  }

  const handleArchive = async () => {
    const archiveSelected = async (selected) => {
      const isArchived = tabIndex !== 2

      return handlePatch(selected, {
        is_archived: isArchived,
        status: isArchived ? 'CLOSED' : undefined,
      })
    }

    // open confirmation modal
    setConfirmModalProps({
      open: true,
      variant: tabIndex === 2 ? 'unarchive' : 'archive',
      handleConfirm: archiveSelected,
    })
  }

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', mb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            height: '100%',
            minWidth: '284px',
            '& > .MuiButton-root': {
              my: 'auto',
            },
          }}
        >
          <Button
            color="primary"
            variant="contained"
            size="action-footer"
            disabled={
              canAccessModule('/main/downloads')
                ? checkedCount <= 0
                : checkedCount !== 1
            }
            onClick={() => handleDownload()}
          >
            Download
          </Button>
          {canArchive && (
            <Button
              color="secondary"
              variant="contained"
              size="action-footer"
              disabled={checkedCount <= 0}
              onClick={() => handleArchive()}
            >
              {tabIndex === 2 ? 'Unarchive' : 'Archive'}
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {checkedCount > 0 && (
            <Box sx={{ px: 4, pt: 2 }}>
              <Typography>
                {`${checkedCount} ${
                  checkedCount === 1 ? itemName.singular : itemName.plural
                } selected`}
              </Typography>
            </Box>
          )}
          {items.length === 0 && !documentsLoading && (
            <Box sx={{ px: 4, pt: 2 }}>
              <Typography>No {itemName.plural} found</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            height: '100%',
            '& > .MuiButton-root': {
              my: 'auto',
            },
            '& > *': {
              ml: 4,
            },
          }}
        >
          {canViewUsers && (
            <UserButton
              checkedCount={checkedCount}
              setConfirmModalProps={setConfirmModalProps}
              handlePatch={handlePatch}
            />
          )}
          <CategoryButton
            checkedCount={checkedCount}
            setConfirmModalProps={setConfirmModalProps}
            handlePatch={handlePatch}
          />
          <StatusButton
            checkedCount={checkedCount}
            setConfirmModalProps={setConfirmModalProps}
            handlePatch={handlePatch}
          />
        </Box>
      </Box>
      <ConfirmationModal
        items={checkedItems}
        setTabIndex={setTabIndex}
        setOpen={() =>
          setConfirmModalProps({ ...confirmModalProps, open: false })
        }
        {...confirmModalProps}
      />
    </>
  )
}

export default DigitalMailboxHeader
