import { useMemo } from 'react'
import useSWR from 'swr'
import * as Yup from 'yup'

import fetcher from 'utils/fetcher'
import isEmptyObject from 'helpers/node/isEmptyObject'

const useWebForm = () => {
  const { data, isValidating, mutate } = useSWR(
    '/manual-bill/webform',
    fetcher,
    { revalidateOnFocus: false }
  )

  const validationSchema = useMemo(() => {
    const shape = {}

    if (!isEmptyObject(data)) {
      Object.values(data).forEach((field) => {
        if (
          typeof field.restrictions === 'object' &&
          !isEmptyObject(field.restrictions)
        ) {
          const maxLength = field.restrictions.max_length
          shape[field.field] = Yup.string().max(
            maxLength,
            `${field.label} must be ${maxLength} characters or less`
          )
        }
      })
    }

    return Yup.object().shape(shape)
  }, [data])

  return {
    fields: Object.values(data || {}).filter((field) => field.field) || [],
    validationSchema,
    loading: (!data && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
  }
}

export default useWebForm
