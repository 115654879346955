import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import MuiLink from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import RemovableItems from 'components/common/RemovableItems'
import DownloadsTemplate from 'components/common/Notification/templates/DownloadsTemplate'
import LoadingModal from 'components/common/LoadingModal'

import useModal from 'hooks/context/useModal'
import useNotification from 'hooks/context/useNotification'
import useConfiguration from 'hooks/useConfiguration'
import useDataListContext from 'hooks/context/useDataListContext'

import { PRIVILEGES } from 'utils/constants'

function ConfirmationModal({
  open,
  setOpen,
  items,
  handleConfirm,
  variant,
  setTabIndex,
  customTitle,
  customNotification = () => {},
  customContent = () => {},
  customHeaderContent = null,
}) {
  const { canAccessModule, hasPrivilege } = useConfiguration()
  const { checkedCount } = useDataListContext()
  const canAccessDownloads = canAccessModule('/main/downloads')
  const canAccessArchive = hasPrivilege(PRIVILEGES.VIEW_ARCHIVED_ITEMS)

  const addDisplay = (add_display) =>
    (add_display ?? []).map((item) => ({ ...item, display: 'DOCUMENT' }))

  const {
    setOpen: setModalOpen,
    setModalProps,
    setComponent: setModalComponent,
  } = useModal()
  const { setComponent, setOpen: setNotifOpen } = useNotification()
  const [selectedItems, setSelectedItems] = useState(addDisplay(items))
  const selectedCount = selectedItems?.length || checkedCount

  const handleRemove = (index) => {
    const newSelectedItems = [...selectedItems]

    newSelectedItems.splice(index, 1)

    setSelectedItems(newSelectedItems)
  }

  const titles = {
    download: 'Download Digital Mail',
    archive: 'Archive Digital Mail',
    unarchive: 'Unarchive Digital Mail',
  }

  const content = {
    download: (
      <>
        <Typography color="darkgray.main" mt={6}>
          You are about to download {selectedCount} document
          {selectedCount !== 1 && 's'}.
        </Typography>
        <Typography color="darkgray.main">
          Documents can be very large and take some time to download. Please
          confirm this action and click Confirm below, or click Cancel to go
          back to the previous screen.
        </Typography>
      </>
    ),
    archive: (
      <>
        <Typography color="darkgray.main" pb={1}>
          You are about to archive {selectedCount} document
          {selectedCount !== 1 && 's'}. When you archive a document, users will
          no longer be able to find or view the document in a search.
        </Typography>
        <Typography color="darkgray.main">
          Please confirm this action and click Confirm below, or click Cancel to
          go back to the previous screen.
        </Typography>
      </>
    ),
    unarchive: (
      <>
        <Typography color="darkgray.main" pb={1}>
          You are about to unarchive {selectedCount} document
          {selectedCount !== 1 && 's'}. When you unarchive a document, users
          will be able to find or view the document in a search.
        </Typography>
        <Typography color="darkgray.main">
          Please confirm this action and click Confirm below, or click Cancel to
          go back to the previous screen.
        </Typography>
      </>
    ),
  }

  const notificationContent = {
    download: (
      <>
        Document ID {selectedItems[0]?.document_id} has been automatically
        downloaded to your computer.
      </>
    ),
    downloads: (
      <>
        We started downloading your files. This may take some time.{' '}
        {canAccessDownloads && (
          <>
            Please check <Link to="/main/downloads">Downloads</Link>.
          </>
        )}
      </>
    ),
    archive: (
      <>
        {selectedCount} document{selectedCount !== 1 && 's'} have been archived.{' '}
        {canAccessArchive && (
          <>
            You can find them in the{' '}
            <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setTabIndex(2)}>
              Archive Tab
            </MuiLink>
          </>
        )}
      </>
    ),
    unarchive: (
      <>
        {selectedCount} document{selectedCount !== 1 && 's'} have been
        unarchived. You can find them in the{' '}
        <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setTabIndex(0)}>
          All Items Tab
        </MuiLink>
      </>
    ),
  }

  const handleCompleteConfirm = async () => {
    const executeConfirmAction = async () => {
      const success = await handleConfirm(selectedItems)
      const notifVariant =
        variant === 'download' && selectedCount > 1 ? 'downloads' : variant

      const NotificationComponent = (props) => (
        <DownloadsTemplate {...props}>
          {notificationContent[notifVariant] ??
            customNotification(selectedCount)}
        </DownloadsTemplate>
      )

      if (success) {
        setComponent(NotificationComponent)
        setNotifOpen(true)
      }
    }

    setModalProps({
      size: 'sm',
      hideButtonsBorder: true,
      title: customTitle ?? titles[variant],
    })
    setModalComponent(LoadingModal)
    setModalOpen(true)
    await executeConfirmAction()
    setOpen(false)
    setModalOpen(false)
  }

  const handleModalConfirm = () => {
    const footerButtonProps = [
      {
        children: 'Confirm',
        color: 'primary',
        variant: 'contained',
        onClick: () => handleCompleteConfirm(),
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: () => setModalOpen(false),
      },
    ]

    setModalProps({
      children: customContent(selectedCount) ?? content[variant],
      title: customTitle ?? titles[variant],
      size: 'sm',
      footerButtonProps,
    })

    setModalOpen(true)
  }

  const footerButtonProps = [
    {
      children: 'Confirm',
      color: 'primary',
      variant: 'contained',
      onClick: () => handleCompleteConfirm(),
    },
    {
      children: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      onClick: () => setOpen(false),
    },
  ]

  useEffect(() => {
    setSelectedItems(addDisplay(items))
  }, [items])

  useEffect(() => {
    if (items.length === 1 && open) {
      handleModalConfirm()
      setOpen(false)
    }
  }, [items, open])

  useEffect(() => {
    if (selectedItems.length === 0 && open) {
      setOpen(false)
      setSelectedItems(addDisplay(items))
    }
  }, [selectedItems])

  return (
    <Modal
      open={open ?? false}
      onClose={() => setOpen(false)}
      size="md"
      footerButtonProps={footerButtonProps}
    >
      {selectedItems?.length ? (
        <RemovableItems
          items={selectedItems}
          handleRemove={handleRemove}
          title={titles[variant] ?? customTitle}
          idKey="document_id"
          max={5}
        />
      ) : null}
      {customHeaderContent && typeof customHeaderContent === 'function' ? (
        <Box sx={{ mb: 2 }}>{customHeaderContent(selectedCount) ?? null}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        {customContent(selectedCount) ?? content[variant]}
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
