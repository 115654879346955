import React, { useState } from 'react'

import Autocomplete from 'components/common/Autocomplete'

import useReports from 'hooks/reporting/useReports'

function ReportTypeSelect({ activeReport, setActiveReport, tabIndex }) {
  const [searchValue, setSearchValue] = useState('')
  const { reports } = useReports({ tabIndex })

  return (
    <Autocomplete
      options={reports}
      getOptionLabel={(option) => option.title || ''}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedOption={activeReport}
      setSelectedOption={setActiveReport}
      handleFilter={(options) =>
        options.filter((option) =>
          option.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      }
      textFieldProps={{
        label: 'Report Type',
      }}
    />
  )
}

export default ReportTypeSelect
