import { useCallback } from 'react'
import useSWR from 'swr'

import urlSerialize from 'helpers/browser/urlSerialize'

import fetcher from 'utils/fetcher'
import axios from 'utils/axios'

import useAuth from 'hooks/useAuth'

const useUserWidgets = (userId, params, disabled = false) => {
  const { user } = useAuth()

  const userWidgetId = userId || user?.userId

  const { data, isValidating, mutate } = useSWR(
    userWidgetId && !disabled
      ? `/users/${userWidgetId}/widgets?${urlSerialize({
          ...params,
        })}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const updateWidgets = useCallback(
    (widgets) => {
      return axios.patch(`/users/${userWidgetId}/widgets`, {
        widgets,
      })
    },
    [userWidgetId]
  )

  const updateWidget = useCallback(
    (widgetId, widgetData) => {
      return axios.patch(
        `/users/${userWidgetId}/widgets/${widgetId}`,
        widgetData
      )
    },
    [userWidgetId]
  )

  return {
    widgets: data?._embedded?.widgets ?? [],
    loading: (!data?._embedded?.widgets && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
    updateWidgets,
    updateWidget,
  }
}

export default useUserWidgets
