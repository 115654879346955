import React from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'

import useClientDataList from 'hooks/flow/useClientDataList'
import ContactCard from 'components/mpx_contact/ContactCard'

function MPXContactContent({ loading, mpxContacts }) {
  if (loading) return <CircularProgress sx={{ m: 'auto' }} />

  if (!mpxContacts?.length)
    return <Box textAlign="center">No contacts available.</Box>

  return mpxContacts.map((mpxContact) => (
    <ContactCard mpxContact={mpxContact} />
  ))
}

function MPXContact() {
  const { mpxContacts, loading } = useClientDataList()

  return (
    <Stack spacing={8} sx={{ p: 8, display: 'flex' }}>
      <MPXContactContent loading={loading} mpxContacts={mpxContacts} />
    </Stack>
  )
}

export default MPXContact
