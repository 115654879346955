import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'

import AlertTitle from '@mui/material/AlertTitle'
import MuiAlert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import MuiTextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import spacing from '@mui/system/spacing'

import useAuth from 'hooks/useAuth'
import useNotification from 'hooks/context/useNotification'
import useTheme from 'hooks/useTheme'

const Alert = styled(MuiAlert)(spacing)

const TextField = styled(MuiTextField)(spacing)

function TwoFactorCodeContent() {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue,
    submitForm,
  } = useFormikContext()
  const location = useLocation()
  const { setBasicNotification, setError } = useNotification()
  const { resendPin, isInitialized } = useAuth()
  const {
    texts: { client_name: clientName },
  } = useTheme()

  const { shortName, userPhone } = location.state || {}

  const handlePinResend = async () => {
    const { pin, error } = await resendPin()

    if (error) {
      setError(error)
      return
    }

    setFieldValue('twoFactorCode', pin)
    setBasicNotification(
      `A verification code has been sent to your phone${
        userPhone ? `: ${userPhone}` : ''
      }`
    )
  }

  useEffect(() => {
    if (isInitialized) handlePinResend()
  }, [isInitialized])

  return (
    <Stack spacing={8}>
      <Typography variant="h1" textAlign="center">
        Get your identification code
      </Typography>
      <Typography
        variant="h2"
        textAlign="center"
        sx={{ textTransform: 'capitalize' }}
      >
        {clientName}
      </Typography>
      <Box>
        {errors.submit && (
          <Alert mt={2} mb={3} severity="warning">
            <AlertTitle>Unable to log in</AlertTitle>
            {errors.submit}
          </Alert>
        )}
        <TextField
          type="text"
          name="twoFactorCode"
          label="Enter Two Factor Code"
          value={values.twoFactorCode}
          error={Boolean(touched.twoFactorCode && errors.twoFactorCode)}
          fullWidth
          helperText={touched.twoFactorCode && errors.twoFactorCode}
          onBlur={handleBlur}
          onChange={handleChange}
          my={2}
          disabled={!shortName}
        />
        <Box sx={{ textAlign: 'right', textDecoration: 'underline' }}>
          <Button onClick={handlePinResend} disabled={!shortName}>
            Re-send PIN
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          sx={{ width: '50%', mx: 'auto' }}
          disabled={!shortName || isSubmitting}
          onClick={submitForm}
        >
          Log in
        </Button>
      </Box>
    </Stack>
  )
}

function TwoFactorCode() {
  const location = useLocation()
  const navigate = useNavigate()
  const { logIn, validate2FA, setInstance } = useAuth()
  const {
    texts: { login_help },
  } = useTheme()

  const { username, shortName, responseData } = location.state || {}

  const [mounted, setMounted] = useState(false)

  const onSubmit = async (values, { setErrors }) => {
    try {
      const success = await validate2FA(values.twoFactorCode)

      if (success) {
        await logIn({ username, instanceId: shortName, resData: responseData })
      } else {
        throw new Error()
      }
    } catch (err) {
      setErrors({
        submit: err.response?.data?.display_message || 'Invalid code',
      })
    }
  }

  useEffect(() => {
    if (!username || !shortName || !responseData) {
      navigate('/auth/login')
    } else {
      setInstance(shortName, responseData.token)
    }
  }, [location])

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return <Box></Box>

  return (
    <Formik
      enableReinitialize
      initialValues={{
        twoFactorCode: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        twoFactorCode: Yup.string().required('2FA code is required'),
      })}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TwoFactorCodeContent />
          <Box sx={{ width: '100%', display: 'flex', textAlign: 'center' }}>
            <Typography
              fontSize={12}
              color="darkgray.main"
              sx={{ width: '50%', mx: 'auto' }}
            >
              {login_help}
            </Typography>
          </Box>
        </Box>
      )}
    </Formik>
  )
}

export default TwoFactorCode
