import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Snackbar from '@mui/material/Snackbar'

import withNotification from 'hocs/withNotification'

function DownloadsTemplate({ children, open, setOpen, timeout }) {
  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={timeout}
      onClose={handleClose}
    >
      <Card
        sx={{
          boxShadow: 2,
          height: 'auto',
          width: '344px',
          p: 4,
          textAlign: 'left',
        }}
      >
        <Box sx={{ height: 'auto', pb: 4, color: 'darkgray.main' }}>
          {children}
        </Box>
        <Button
          color="primary"
          sx={{ float: 'right', textDecoration: 'underline' }}
          onClick={handleClose}
        >
          Close
        </Button>
      </Card>
    </Snackbar>
  )
}

export default withNotification(DownloadsTemplate)
