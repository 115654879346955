import React, { useState, useMemo, useRef, useEffect } from 'react'
import debounce from 'lodash.debounce'

import TextField from '@mui/material/TextField'

function AsyncTextField({ delay = 200, value, ...textFieldProps }) {
  const inputValue = useRef()
  const [localValue, setLocalValue] = useState(value)

  const changeHandler = (e) => {
    inputValue.current = e.target.value

    setTimeout(() => {
      if (inputValue.current === e.target.value) {
        textFieldProps.onChange(e)
      }
    }, delay / 2)
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, delay / 2),
    []
  )

  useEffect(() => {
    if (value !== localValue) setLocalValue(value)
  }, [value])

  return (
    <TextField
      {...textFieldProps}
      value={localValue}
      onChange={(e) => {
        debouncedChangeHandler(e)
        setLocalValue(e.target.value)
      }}
    />
  )
}

export default AsyncTextField
