import React from 'react'

function PendingApproval({ width = '24', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="currentColor" />
      <path
        d="M33.1547 19.0602C33.2333 19.2287 33.4019 19.3411 33.5928 19.3411H36.5813C36.9183 19.3411 37.1767 18.9928 37.0643 18.6783C34.941 12.8138 29.099 9.12884 22.6616 9.92649C16.5836 10.6792 11.6966 15.6337 11 21.7228C10.0676 29.8567 16.4376 36.7885 24.3917 36.7885C28.1216 36.7885 31.6493 35.2381 34.1883 32.5418L34.2108 32.5642L36.424 34.7887C36.615 34.9797 36.9408 34.8449 36.9408 34.5752V27.3402C36.9408 27.093 36.7385 26.8908 36.4914 26.8908H29.29C29.0204 26.8908 28.8855 27.2166 29.0765 27.4076L31.5481 29.8792C29.7057 31.8789 27.1329 33.0361 24.4029 33.0361C18.5272 33.0361 13.8536 27.8008 14.7973 21.7453C15.5725 16.8245 19.8192 13.398 24.7961 13.589C28.762 13.735 31.7728 16.128 33.1547 19.0602Z"
        fill="white"
      />
      <path
        d="M23.7045 16.3749C23.4102 16.3749 23.1772 16.5863 23.1772 16.8533V23.617L19.7928 26.6874C19.5844 26.8765 19.5844 27.1769 19.7928 27.366L21.9387 29.3128C22.1472 29.502 22.4783 29.502 22.6867 29.3128L27.2728 25.1522V16.8421C27.2728 16.5751 26.7456 16.3638 26.7456 16.3638H23.7045V16.3749Z"
        fill="white"
      />
    </svg>
  )
}

export default PendingApproval
