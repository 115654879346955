import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useLocation } from 'react-router-dom'

import MuiListSubheader from '@mui/material/ListSubheader'

import NavListItem from './NavLisItem'

import decimalToAlpha from 'helpers/css/decimalToAlpha'
import appendHttp from 'helpers/browser/appendHttp'

import useConfiguration from 'hooks/useConfiguration'
import useSidebar from 'hooks/useSidebar'

const ListSubheader = styled(MuiListSubheader)`
  text-align: left;
  padding-left: 38px;
  font-weight: bold;
  height: ${({ theme }) => theme.rh(40)};
  margin-top: ${({ theme }) => theme.rh(39.67)};
  align-items: center;
  display: flex;
  ${({ theme, active }) =>
    `color: ${
      active
        ? theme.palette.text.primary
        : `${theme.palette.text.secondary}${decimalToAlpha(0.4)}`
    };`}
`

const ActiveBar = styled.div`
  position: absolute;
  ${({ theme, open }) =>
    open
      ? `
    left: 0%;
    right: 97.86%;
    top: 0%;
    bottom: 0%;
    background: ${theme.palette.green.main};
    border-radius: 0px;
  `
      : `
    left: 36.36%;
    right: 36.36%;
    top: 45%;
    bottom: 45%;
    border-radius: 2px;
  `}
  ${({ active, theme }) =>
    `background-color: ${
      active
        ? theme.palette.green.main
        : `${theme.palette.text.secondary}${decimalToAlpha(0.4)}`
    }`}
`

function NavListGroup({ items, display, open, moduleIndex }) {
  const {
    navigation: { currentPath },
  } = useConfiguration()
  const location = useLocation()
  const [active, setActive] = useState(undefined)
  const { setMounted } = useSidebar('navigation')
  const showActiveBar = active || !open

  const matchesPath = (item) => {
    return location.pathname.includes(item.href)
  }

  // update active state whenever url path changes
  useEffect(() => {
    const foundItem = items.find(matchesPath)
    setActive(foundItem)
  }, [currentPath])

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <>
      {display && (
        <ListSubheader active={active}>
          {showActiveBar && <ActiveBar active={active} open={open} />}
          {open && display.toUpperCase()}
        </ListSubheader>
      )}
      {items.map((item, index) => {
        const isBookmark = item.name === 'bookmark'
        return (
          <NavListItem
            key={`${item.name}-${item.id}`}
            icon={`/static/img/icons/navigation/${item.name}.svg`}
            display={item.display}
            href={appendHttp(item.href)}
            selected={matchesPath(item)}
            open={open}
            moduleIndex={moduleIndex}
            index={index}
            bookmark={isBookmark}
          />
        )
      })}
    </>
  )
}

export default NavListGroup
