import React from 'react'
import 'chart.js/auto'
import { Bar } from 'react-chartjs-2'

import Box from '@mui/material/Box'

function BarChart({ chartData, width, height }) {
  return (
    <Box sx={{ position: 'relative', width: width, height }}>
      <Bar
        data={chartData}
        options={{
          maintainAspectRatio: false,
          indexAxis: 'y',
          scales: {
            y: {
              grid: {
                display: false,
                borderColor: 'rgba(0,0,0,0)',
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                display: false,
                borderColor: 'rgba(0,0,0,0)',
              },
              ticks: {
                display: false,
              },
              reverse: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Box>
  )
}

export default BarChart
