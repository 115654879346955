import React from 'react'

import Box from '@mui/material/Box'

import withDataList from 'hocs/withDataList'

function DataListLayoutContent({ children }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          pb: 4,
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </>
  )
}

export default withDataList(DataListLayoutContent)
