import React, { useMemo, useState, useEffect, useRef } from 'react'

import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import XIcon from '@mui/icons-material/Close'

import getMimeTypeDisplay from 'helpers/css/getMimeTypeDisplay'
import { MIME_TYPES } from 'utils/constants'
import flipObject from 'helpers/node/flipObject'

const PPS = 0.2 // percent per second
const BASE_LOAD = 2 // seconds

function FileLoader({ filename, fileType, progress, onClose }) {
  const progressRef = useRef({ progress: 0 })
  const [loaderProgress, setLoaderProgress] = useState(progress)
  const [loaderInterval, setLoaderInterval] = useState(null)

  const ext = useMemo(() => {
    try {
      if (!fileType) throw new Error()

      const reversedMimeTypes = flipObject(MIME_TYPES)

      if (reversedMimeTypes[fileType]) {
        return reversedMimeTypes[fileType]
      }

      return getMimeTypeDisplay(fileType)
    } catch (err) {
      return 'FILE'
    }
  }, [fileType])

  useEffect(() => {
    if (
      loaderProgress === undefined &&
      !loaderInterval &&
      progressRef?.current
    ) {
      setLoaderInterval(
        setInterval(() => {
          progressRef.current.progress += PPS * Math.floor(100 / 3)
          setLoaderProgress(progressRef.current.progress)
        }, BASE_LOAD * PPS * (999 / 3))
      )
    }

    if (loaderProgress >= 100) {
      clearInterval(loaderInterval)
      setLoaderInterval(null)
    }

    return () => {
      if (loaderInterval) clearInterval(loaderInterval)

      setLoaderProgress(null)
    }
  }, [loaderInterval, progressRef])

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'lightgray.light',
        border: '1px solid',
        borderColor: 'lightgray.main',
        borderRadius: '8px',
        display: 'flex',
        height: '92px',
        p: 4,
      }}
    >
      <XIcon
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          cursor: 'pointer',
          width: 16,
          height: 16,
        }}
        color="primary"
        onClick={onClose}
      />
      <Box
        sx={{
          height: '60px',
          width: '60px',
          display: 'flex',
          backgroundColor: 'lightblue.light',
          borderRadius: '6px',
          my: 'auto',
          mr: 4,
        }}
      >
        <Typography
          color="primary.main"
          fontWeight={600}
          fontSize="12px"
          sx={{ textTransform: 'uppercase', m: 'auto' }}
        >
          {ext}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, py: 2.5, display: 'flex', flexDirection: 'column' }}>
        <Typography
          color="primary.main"
          fontSize="14px"
          fontWeight={600}
          lineHeight="16px"
          my="auto"
        >
          {filename || 'Unknown file'}
        </Typography>
        {loaderProgress < 100 && (
          <LinearProgress
            sx={{ borderRadius: '7px', height: '7px', mt: 4 }}
            color="primary"
            value={loaderProgress || 0}
            variant="determinate"
          />
        )}
      </Box>
    </Box>
  )
}

export default FileLoader
