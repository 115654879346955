import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import AuthGuard from 'components/guards/AuthGuard'

import useDocumentContent from 'hooks/documents/useDocumentContent'
import { useDocument } from 'hooks/useDocuments'

function DocumentHTML() {
  const { documentId } = useParams()
  const { document } = useDocument(documentId)
  const { content, fetchContent } = useDocumentContent({ document })

  useEffect(() => {
    fetchContent()
  }, [document])

  if (document?.content_type !== 'text/html' || !content) return <div></div>

  return <div dangerouslySetInnerHTML={{ __html: content }}></div>
}

function DocumentHTMLWrapper() {
  return (
    <AuthGuard>
      <DocumentHTML />
    </AuthGuard>
  )
}

export default DocumentHTMLWrapper
