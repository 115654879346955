import styled from 'styled-components/macro'

import Box from '@mui/material/Box'

const FullBox = styled(Box)`
  width: 100%;
  height: 100%;
`

export default FullBox
