import React, { useEffect } from 'react'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

import AddCircleOutline from '@mui/icons-material/AddCircleOutline'

import ItemContainer from './containers/ItemContainer'
import ScrollableBox from 'components/styled/ScrollableBox'

import useDashboardContext from 'hooks/context/useDashboardContext'
import useSavedSearch from 'hooks/search/useSavedSearch'
import { WIDGET_KEYS } from 'utils/constants'

function CorrespondenceSavedSearchesItem() {
  const { setSavedSearch, refreshCount, setSearchModalProps } =
    useDashboardContext()
  const { savedSearches, loading, mutate, defaultSearch } = useSavedSearch({})

  useEffect(() => {
    mutate()
  }, [refreshCount])

  return (
    <ItemContainer
      loading={loading}
      href="/correspondence-hub/correspondence"
      title="Correspondence"
      subTitle="Saved Searches"
      titleAction={
        <IconButton
          title="Add saved search"
          onClick={() =>
            setSavedSearch({
              type: 'add',
              title: 'Default',
              fetchSearchConfig: mutate,
              defaultSfc: defaultSearch,
            })
          }
        >
          <AddCircleOutline color="primary" />
        </IconButton>
      }
      widgetKey={WIDGET_KEYS.CORRESPONDENCE_SAVED_SEARCH}
    >
      <ScrollableBox sx={{ overflowY: 'auto', height: '200px', px: 4 }}>
        <Stack spacing={3}>
          {savedSearches
            .slice()
            .reverse()
            .map((ss) => (
              <>
                <Button
                  variant={ss.is_default ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() =>
                    setSearchModalProps({
                      open: true,
                      baseActiveId: ss?.id,
                      name: ss?.name,
                    })
                  }
                >
                  {ss.name}
                </Button>
              </>
            ))}
        </Stack>
      </ScrollableBox>
    </ItemContainer>
  )
}

export default CorrespondenceSavedSearchesItem
