import React, { Fragment } from 'react'

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

function LegendItem({ color, children, tooltip }) {
  let Wrapper = Fragment
  const wrapperProps = {}

  if (tooltip) {
    Wrapper = Tooltip
    wrapperProps.title = tooltip
  }

  return (
    <Wrapper {...wrapperProps}>
      <Box
        sx={{ pl: 2, borderLeft: '4px solid', borderColor: color, height: 32 }}
      >
        {children}
      </Box>
    </Wrapper>
  )
}

function Legend({ labels, colors, tooltips = [], data, sx }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        overflow: 'hidden',
        ...sx,
      }}
    >
      {labels.map((label, index) => (
        <Tooltip>
          <LegendItem
            key={label}
            color={colors[index]}
            tooltip={tooltips[index]}
          >
            <Typography fontWeight="bold" lineHeight="16px">
              {data[index]}
            </Typography>
            <Typography
              fontSize="10px"
              lineHeight="16px"
              sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {label}
            </Typography>
          </LegendItem>
        </Tooltip>
      ))}
    </Box>
  )
}

export default Legend
