const getTextWidth = (text, font = 'Open Sans') => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  context.font = font || getComputedStyle(document.body).font

  return context.measureText(text).width
}

export default getTextWidth
