import { useState, useEffect } from 'react'
import urlSerialize from 'helpers/browser/urlSerialize'
import useSWR from 'swr'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

const usePrintAssets = (params = null) => {
  const [latestAssets, setLatestAssets] = useState([])
  const [loading, setLoading] = useState(false)

  const { data, isValidating, mutate } = useSWR(
    `/print-assets?${urlSerialize({
      versionactive: 1,
      versiondefault: 1,
      embed: 'versions|rules',
      ...params,
    })}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const addPrintAsset = async ({ updateData }) => {
    const errorMsg = 'Error adding asset'

    if (!updateData) throw new Error()

    const formData = new FormData()

    Object.entries(updateData).forEach(([key, value]) => {
      if (key === 'assetfile' && !value) return

      // value can be falsey
      if (value !== undefined || value !== null) {
        // check for redundancies
        if (value !== data[key]) {
          formData.append(key, value)
        }
      }
    })

    const res = await axios.post(`/print-assets`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      mutate()
    }
  }

  const fetchAssetByVersion = async (assetId, versionNum) => {
    const res = await axios.get(
      `/print-assets/${assetId}/versions/${versionNum}`
    )
    return res.data
  }

  const parseCurrentAssets = async (assets) => {
    const updatedAssets = await Promise.all(
      assets.map(async (asset) => {
        const currentVersion = asset.default_version?.toString()
        const allVersions = asset?._embedded?.versions?.versions || []
        const versionAsset = allVersions.find(
          (version) => version.version.toString() === currentVersion
        )

        return {
          ...asset,
          ...versionAsset,
        }
      })
    )

    setLatestAssets(updatedAssets)
  }

  useEffect(() => {
    const asyncHandler = async () => {
      setLoading(true)

      try {
        const assets = data?._embedded?.print_assets
        if (Array.isArray(assets)) {
          return parseCurrentAssets(assets)
        }

        throw new Error('Invalid data')
      } catch (err) {
        setLatestAssets([])
      } finally {
        setLoading(false)
      }
    }

    asyncHandler()
  }, [data])

  return {
    assets: latestAssets,
    loading:
      (!data?._embedded?.print_assets && !data?.error) ||
      isValidating ||
      loading,
    validating: isValidating,
    count: data?.count,
    total: data?.total,
    totalPages: data?.total_pages,
    pageSize: data?.page_size,
    paginates: data?.paginates,
    error: data?.error,
    mutate,
    addPrintAsset,
    fetchAssetByVersion,
  }
}

export default usePrintAssets
