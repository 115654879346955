import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { DATALIST_DIMENSIONS } from 'utils/constants'

import Box from '@mui/material/Box'

import ExpandedList from 'components/common/ExpandedList'
import ScrollableBox from 'components/styled/ScrollableBox'
import PDFViewer from 'components/common/PDFViewer'
import CorrespondencePreviewExpandedList from 'components/common/ExpandedList/CorrespondencePreview'

import useDataListContext from 'hooks/context/useDataListContext'
import useDocumentContent from 'hooks/documents/useDocumentContent'

const StyledIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`

function ContentPreview({ document, content, contentType = '', fileName }) {
  if (contentType?.toLowerCase().includes('pdf'))
    return <PDFViewer url={content} fileName={fileName} />

  let contentComponent = content

  if (contentType.includes('html'))
    return (
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'lightgray.main',
          height: `calc(${DATALIST_DIMENSIONS.HEIGHT} - 108px)`,
        }}
      >
        <StyledIframe
          title="Notification Preview"
          src={`/html/document/${document?.id}`}
        ></StyledIframe>
      </Box>
    )

  if (contentType.includes('image'))
    contentComponent = <img src={URL.createObjectURL(content)} alt="" />

  if (typeof contentComponent === 'object') return <Box />

  return (
    <ScrollableBox
      sx={{
        border: '1px solid',
        borderColor: 'lightgray.main',
        height: `calc(${DATALIST_DIMENSIONS.HEIGHT} - 108px)`,
        p: 2,
        overflow: 'auto',
      }}
    >
      {contentComponent}
    </ScrollableBox>
  )
}

function CorrespondencePreviewContent({ dataList, dataListProps, columns }) {
  const { activeIndex } = useDataListContext()

  const activeDocument = dataList?.[activeIndex]

  const { content, fetchContent } = useDocumentContent({
    document: activeDocument,
  })

  const showPreview =
    Boolean(content) || activeDocument?.content_type === 'text/html'

  useEffect(() => {
    if (activeDocument?.content_type !== 'text/html') fetchContent()
  }, [activeIndex])

  return (
    <ScrollableBox
      sx={{
        px: 10,
        py: 6,
        height: DATALIST_DIMENSIONS.HEIGHT,
        overflow: 'auto',
      }}
    >
      <ExpandedList
        expanded={true}
        columns={columns}
        getDataDisplay={dataListProps.getDataDisplay}
        rowData={dataList[activeIndex] ?? {}}
        visibleColumns={['type']}
        collapseDetails
        ExpandedListComponent={CorrespondencePreviewExpandedList}
      />
      {showPreview && (
        <Box
          sx={{
            height: `calc(${DATALIST_DIMENSIONS.HEIGHT} - 108px)`,
          }}
        >
          <ContentPreview
            document={activeDocument}
            content={content}
            contentType={activeDocument?.content_type}
            fileName={dataList[activeIndex].filename}
          />
        </Box>
      )}
    </ScrollableBox>
  )
}

export default CorrespondencePreviewContent
