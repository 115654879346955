import React from 'react'
import styled from 'styled-components/macro'
import { Link as RouterLink } from 'react-router-dom'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import useConfiguration from 'hooks/useConfiguration'

const Link = styled(RouterLink)`
  color: white;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const LinkName = styled(Typography)`
  color: white;
  font-weight: bold;
  text-transform: capitalize;
`

function BreadCrumbs() {
  const {
    navigation: { currentPath },
  } = useConfiguration()

  return (
    <Breadcrumbs separator={<NavigateNextIcon />} sx={{ color: 'white' }}>
      {currentPath.slice(0, -1).map((pathNode, pathIndex) => (
        <Link key={`${pathNode.display}-${pathIndex}`} to={pathNode.href}>
          <LinkName variant="h2">{pathNode.display}</LinkName>
        </Link>
      ))}
      <LinkName variant="h2">{currentPath.slice(-1)[0]?.display}</LinkName>
    </Breadcrumbs>
  )
}

export default BreadCrumbs
