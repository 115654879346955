import React from 'react'

import { GridContextProvider, GridDropZone, GridItem } from 'react-grid-dnd'

function DraggableCardGrid({ items = [], handleChange, dropzoneProps }) {
  return (
    <GridContextProvider onChange={handleChange}>
      <GridDropZone
        id="items"
        boxesPerRow={3}
        rowHeight={320}
        style={{ height: `${(Math.floor(items.length / 3) + 1) * 320}px` }}
        {...dropzoneProps}
      >
        {items.map((item) => (
          <GridItem key={item.key}>
            <div
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <item.Component id={item.key} priority={item.priority ?? 1} />
            </div>
          </GridItem>
        ))}
      </GridDropZone>
    </GridContextProvider>
  )
}

export default DraggableCardGrid
