import React from 'react'
import styled from 'styled-components/macro'

import MuiAutocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const StyledMuiAutocomplete = styled(MuiAutocomplete)`
  .MuiAutocomplete-clearIndicator {
    background-color: white;
  }

  .MuiAutocomplete-clearIndicator:hover {
    color: ${({ theme }) => theme.palette.error.main};
  }

  .MuiAutocomplete-inputRoot {
    min-width: ${({ minwidth }) => minwidth};
  }
`

function Autocomplete({
  searchValue,
  setSearchValue,
  selectedOption,
  setSelectedOption,
  options,
  onEnter,
  handleFilter,
  placeholder,
  showInputValue = true,
  textFieldProps,
  maxHeight,
  minWidth,
  handleAutocompleteChange,
  ...props
}) {
  const _handleAutocompleteChange = (e, value) => {
    setSelectedOption(value)
  }

  const handleInputChange = (_e, value) => {
    setSearchValue(value)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (onEnter) onEnter(e)
    }
  }

  const handleAutocompleteFilter = (options) => {
    const filtered = options.filter((option) => {
      let label = option

      if (props.getOptionLabel) {
        label = props.getOptionLabel(option)
      }

      return label.toLowerCase().includes(searchValue.toLowerCase())
    })
    return filtered
  }

  return (
    <StyledMuiAutocomplete
      autoComplete
      autoHighlight
      fullWidth
      freeSolo
      openOnFocus
      options={options}
      minwidth={minWidth}
      filterOptions={handleFilter ?? handleAutocompleteFilter}
      getOptionLabel={(option) => option}
      value={selectedOption}
      onChange={handleAutocompleteChange || _handleAutocompleteChange}
      inputValue={searchValue}
      onInputChange={handleInputChange}
      onKeyPress={handleKeyPress}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          color="green"
          {...params}
          {...textFieldProps}
        />
      )}
      renderOption={(optionProps, option, data) => {
        let key = option

        if (typeof option === 'object') {
          key = JSON.stringify(option)
        }

        return (
          <li {...optionProps} key={key}>
            {props?.getOptionLabel
              ? props.getOptionLabel(option, data)
              : option}
          </li>
        )
      }}
      ListboxProps={{ style: { overflowX: 'visible', maxHeight, minWidth } }}
      {...props}
    />
  )
}

export default Autocomplete
