import React, { useState, useEffect, useMemo } from 'react'

import useDataList from 'hooks/useDataList'
import useOrders, { useAllOrders } from 'hooks/useOrders'
import useOrderApprovalTotal from 'hooks/useOrderApprovalTotal'
import useDataListContext from 'hooks/context/useDataListContext'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import OrderApprovalTabs from 'components/order_approval/OrderApprovalTabs'
import OrderApproveModal from 'components/order_approval/OrderApproveModal'
import OrderHoldModal from 'components/order_approval/OrderHoldModal'
import DataList from 'components/common/DataList'
import DataListLayout from 'layouts/TabbedDataList'
import SearchField from 'components/common/SearchField'

import { DATALIST_DIMENSIONS } from 'utils/constants'
import ExportToCSV from 'components/common/ExportToCSV'

import trimString from 'helpers/node/trimString'

function OrderApproval() {
  const { page, pageSize, setTotal, setTotalPages } = useDataListContext()

  const [filters, setFilters] = useState({})
  const [sortList, setSortList] = useState([])
  const [tabIndex, setTabIndex] = useState(0)

  const toReviewTotal = useOrderApprovalTotal({
    state: 'OrderApproval',
    status_filter: 'To Review',
    'paging[page_size]': 1,
  })
  const processingTotal = useOrderApprovalTotal({
    state: 'OrderApproval',
    status_filter: 'Processing',
    'paging[page_size]': 1,
  })
  const onHoldTotal = useOrderApprovalTotal({
    state: 'OrderApproval',
    status_filter: 'On Hold',
    'paging[page_size]': 1,
  })

  const tabsObj = useMemo(
    () => [
      { status_filter: 'To Review', total: toReviewTotal.total ?? 0 },
      { status_filter: 'On Hold', total: onHoldTotal.total ?? 0 },
      { status_filter: 'Processing', total: processingTotal.total ?? 0 },
    ],
    [toReviewTotal, onHoldTotal, processingTotal]
  )

  const ordersHookParams = useMemo(() => {
    const searchTerm = trimString(filters.search)

    return {
      status_filter: tabsObj[tabIndex].status_filter,
      state: 'OrderApproval',

      'filter[field]':
        searchTerm && searchTerm !== '' && filters.searchfields
          ? filters.searchfields
          : undefined,
      'filter[term]':
        searchTerm && searchTerm !== '' && filters.searchfields
          ? searchTerm
          : undefined,
      'paging[page]': page,
      'paging[page_size]': pageSize,
      embed: 'reports',
      ...sortList,
    }
  }, [tabsObj, tabIndex, filters, page, pageSize, sortList])

  const { orders, total, totalPages, loading, mutate } =
    useOrders(ordersHookParams)

  useEffect(() => {
    if (total !== undefined) {
      toReviewTotal.mutate()
      processingTotal.mutate()
      onHoldTotal.mutate()
      setTotal(total)
      setTotalPages(totalPages)
    }
  }, [total])

  const prepareRowData = (row) => ({
    order_id: row.id,
    program_name: row.program_name,
    date_created: row.datetime_created,
    file_name: row.file_name,
    pieces: row.counts ? row.counts.pieces : null,
    program_id: row.program_id,
    envelopes: row.counts.envelopes,
    images: row.counts.images_clicks,
    order_status: row.status,
    _embedded: row._embedded,
  })

  const hoverButtonProps =
    tabIndex === 0
      ? [
          {
            privId: 'hold',
            children: 'Hold',
            color: 'primary',
            variant: 'outlined',
            size: 'action-header',
            onClick: (props) => handleClick('hold', props),
            sx: {
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'hover.opaque',
              },
            },
          },
          {
            privId: 'approve',
            children: 'Approve',
            color: 'primary',
            variant: 'contained',
            size: 'action-header',
            onClick: (props) => handleClick('approve', props),
          },
        ].filter((b) => b)
      : [
          {
            privId: 'approve',
            children: 'Approve',
            color: 'primary',
            variant: 'contained',
            size: 'action-header',
            onClick: (props) => handleClick('approve', props),
          },
        ].filter((b) => b)

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: orders,
    presetName: 'orderApproval',
    setSortList,
    prepareRowData,
  })
  const { getCsvExport } = useAllOrders(ordersHookParams, total, prepareRowData)

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    const newFilters = { ...filters }

    if (name === 'search' && value) {
      newFilters['searchfields'] = 'program_name|order_id|file_name'
    }

    newFilters[name] = value

    setFilters(newFilters)
  }

  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(0)
  const [modalProps, setModalProps] = useState({})
  const modalButtonProps = [
    {
      children: 'Button1',
      color: 'primary',
      variant: 'contained',
    },
    {
      children: 'Button2',
      color: 'primary',
      variant: 'outlined',
      onClick: () => setOpen(false),
    },
  ]

  const handleClick = (modalType, props) => {
    setModal(modalType)
    switch (modalType) {
      case 'approve':
        setModalProps({
          size: 'sm',
          scrollable: false,
          title: undefined,
          subTitle: undefined,
          footerButtonProps: undefined,
          orderId: props.rowData.order_id,
          mutate,
        })
        break
      case 'hold':
        setModalProps({
          size: 'sm',
          scrollable: false,
          title: undefined,
          subTitle: undefined,
          footerButtonProps: undefined,
          orderId: props.rowData.order_id,
          mutate,
        })
        break
      default:
        setModalProps({})
    }

    setOpen(true)
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <OrderApprovalTabs
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          tabsObj={tabsObj}
        />

        <Box sx={{ px: 8, pt: 6, width: '100%' }}>
          <Grid
            container
            columns={12}
            spacing={4}
            sx={{
              '& > *': { display: 'flex' },
              '& > * > *': { my: 'auto' },
              mb: 6,
            }}
          >
            <Grid item xs={6}>
              <SearchField
                fullWidth
                size="small"
                label="Search"
                name="search"
                onChange={handleFilterChange}
                value={filters.search}
              />
            </Grid>
          </Grid>
          <Card sx={{ boxShadow: 2, mb: 3 }}>
            {!total && !loading && (
              <Box sx={{ px: 4, pt: 2 }}>
                <Typography>Filter results empty</Typography>
              </Box>
            )}
            <Box>
              <DataList
                {...dataListProps}
                dataList={dataList}
                columns={columns}
                loading={loading}
                filters={filters}
                layoutType="orderapproval"
                actionButtonProps={
                  tabIndex === 2 ? undefined : hoverButtonProps
                }
                height={DATALIST_DIMENSIONS.TABBED_HEIGHT}
              />
            </Box>
          </Card>
        </Box>
        <ExportToCSV
          filename={`Order.Approval.${Date.now()}.csv`}
          getCsvExport={getCsvExport}
          sx={{ ml: 8 }}
          hide={loading || dataList.length <= 0}
        />
      </Box>
      {modal === 'approve' && (
        <OrderApproveModal
          open={open}
          onClose={() => setOpen(false)}
          footerButtonProps={modalButtonProps}
          {...modalProps}
        />
      )}
      {modal === 'hold' && (
        <OrderHoldModal
          open={open}
          onClose={() => setOpen(false)}
          footerButtonProps={modalButtonProps}
          {...modalProps}
        />
      )}
    </>
  )
}

function OrderApprovalWrapper() {
  return (
    <DataListLayout>
      <OrderApproval />
    </DataListLayout>
  )
}

export default OrderApprovalWrapper
