import format from 'date-fns-tz/format'
import addMinutes from 'date-fns/addMinutes'

function formatDateToUTC(date) {
  if (!date) return undefined

  return format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd')
}

export default formatDateToUTC
