import { useMemo } from 'react'

import useConfiguration from 'hooks/useConfiguration'

const useFieldDefinitions = () => {
  const { currentModule } = useConfiguration()

  const fieldDefinitions = useMemo(() => {
    const fd = currentModule?.configurations?.find(
      (config) => config.configuration_type === 'field_definitions'
    )
    const fieldSets = fd?.fieldsets || []
    let extraFields = {}

    fieldSets.forEach((fieldSet) => {
      extraFields = {
        ...extraFields,
        ...fieldSet.fields,
      }
    })

    return extraFields
  }, [currentModule])

  return {
    fieldDefinitions,
  }
}

export default useFieldDefinitions
