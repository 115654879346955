import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import useNotification from 'hooks/context/useNotification'

function ConfirmCreateReportModal({ open, onClose, saveReport, ddReportId }) {
  const { values, setFieldValue } = useFormikContext()
  const navigate = useNavigate()
  const { setBasicNotification, setError } = useNotification()
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    if (loading) return

    setLoading(true)
    try {
      const report = await saveReport({
        ...values,
      })

      if (report) {
        setBasicNotification(
          `Report ${values.name} has been ${
            ddReportId ? 'updated' : 'created'
          }!`
        )
        if (!ddReportId) {
          navigate(`/command-center/reporting`)
        }

        onClose()
        return
      }

      throw new Error('Invalid report')
    } catch (err) {
      setError(
        'There was a problem creating your report. Try again later.',
        err
      )
    } finally {
      setLoading(false)
    }
  }

  const modalProps = {
    title: 'Save Report',
    subTitle: undefined,
    scrollable: true,
    size: undefined,
    height: '360px',
    width: '732px',
    hideButtonsBorder: false,
    footerButtonProps: [
      {
        children: 'Save',
        color: 'primary',
        variant: 'contained',
        onClick: () => handleSave(),
        size: 'action-header',
        disabled: !values.name || loading,
      },
      {
        children: 'Close',
        color: 'primary',
        variant: 'outlined',
        onClick: () => onClose(),
        size: 'action-header',
        disabled: loading,
      },
    ],
  }

  return (
    <Modal {...modalProps} open={open} onClose={onClose}>
      <Box pt={2}>
        <Stack spacing={4}>
          <TextField
            label="Report name"
            value={values.name}
            onChange={(e) => setFieldValue('name', e.target.value)}
            disabled={loading}
          />
          <Box sx={{ display: 'flex' }}>
            <Checkbox
              checked={values.public}
              onChange={(e) => setFieldValue('public', e.target.checked)}
              color="success"
              disabled={loading}
            />
            <Typography my="auto">Share with others?</Typography>
          </Box>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ConfirmCreateReportModal
