import React from 'react'

import Box from '@mui/material/Box'

import useOrderUpdate from 'hooks/useOrderUpdate'

import useNotification from 'hooks/context/useNotification'

import Modal from 'components/common/Modal'

import rh from 'helpers/css/rh'

function OrderApproveModal(props) {
  const { updateOrderStatus } = useOrderUpdate(props.orderId)

  const { setBasicNotification, setError } = useNotification()

  const overrideProps = {
    title: `Approve Order #${props.orderId}`,
    subTitle: undefined,
    scrollable: false,
    size: 'sm',
    hideButtonsBorder: true,
    width: '1144px',
    height: rh(432),
    footerButtonProps: [
      {
        children: 'Approve',
        color: 'primary',
        variant: 'contained',
        onClick: async () => {
          try {
            await updateOrderStatus({ status: 'Approved for Production' })
            props.mutate()
            setBasicNotification(`Order #${props.orderId} was Approved.`)
          } catch (err) {
            setError(err.response?.data?.display_message || 'Unauthorized')
          } finally {
            props.onClose()
          }
        },
        size: 'action-header',
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: props.onClose,
        size: 'action-header',
      },
    ],
  }

  return (
    <Modal {...props} {...overrideProps}>
      <Box>
        <Box sx={{ mb: 10 }}>Are you sure??</Box>
      </Box>
    </Modal>
  )
}

export default OrderApproveModal
