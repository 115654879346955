export default function isCountryUSA(country) {
  if (typeof country !== 'string') {
    return false
  }

  switch (country.trim().toLowerCase()) {
    case 'us':
    case 'usa':
    case 'u.s.a.':
    case 'u.s.':
    case 'united states':
    case 'united states of america':
      return true
    default:
      return false
  }
}
