import React from 'react'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'

function ImagePreviewModal({ imageSrc, ...props }) {
  return (
    <Dialog
      {...props}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          height: { sm: props.height },
          width: props.width,
          maxWidth: props.width,
        },
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          height: '100%',
        }}
        onClick={() => props.onClose()}
      >
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              position: 'relative',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                backgroundColor: 'white',
              }}
            >
              <CloseIcon color="primary" />
            </IconButton>
            <Box
              component="img"
              src={imageSrc}
              sx={{
                height: 'auto',
                width: '100%',
                maxWidth: props.width,
                maxHeight: props.height,
                objectFit: 'contain',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ImagePreviewModal
