import React, { useState, useEffect, useMemo, useCallback } from 'react'
import jwtDecode from 'jwt-decode'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import DataList from 'components/common/DataList'
import Header from './Header'
import Tabs from './Tabs'
import Sidebar from './Sidebar'

import { useDocument } from 'hooks/useDocuments'
import useNotification from 'hooks/context/useNotification'
import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import {
  useAllDocumentInboundItems,
  useDocumentInboundItems,
} from 'hooks/digital_mail'
import useAuth from 'hooks/useAuth'
import useConfiguration from 'hooks/useConfiguration'

import {
  DATALIST_DIMENSIONS,
  BASE_PAGE_SIZE,
  PRIVILEGES,
  DOCUMENT_INBOUND_NOTE_ACTIVITY_TYPES,
  MODULE_NAMES,
} from 'utils/constants'

import encodeBase64 from 'helpers/node/encodeBase64'
import decodeBase64 from 'helpers/node/decodeBase64'
import ExportToCSV from 'components/common/ExportToCSV'
import useDocumentInboundNotes from 'hooks/digital_mail/useDocumentInboundNotes'
import useInstance from 'hooks/instance/useInstance'

function DocumentDataList() {
  const defaultSort = {
    id: 'date_doc',
    desc: false,
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const { hasPrivilege } = useConfiguration()
  const canDownload = hasPrivilege(PRIVILEGES.DOWNLOAD_ITEM)
  const { makeNote } = useDocumentInboundNotes()
  const { getDigitalMailVisibleColumns: getVisibleColumns } = useInstance()

  // auth
  const { user } = useAuth()
  const { UserID } = jwtDecode(user.accessToken)

  // datalist
  const [sortList, setSortList] = useState({
    'sort[0]': `${defaultSort.id}:${defaultSort.desc ? 'desc' : 'asc'}`,
  })
  const [filterList, setFilterList] = useState({})

  const filterCount = useMemo(() => {
    try {
      let dateApplied = false

      return Object.entries(filterList).reduce((acc, [k, v]) => {
        if (!v) return acc

        if (k.includes('date_doc')) {
          const returnValue = !dateApplied ? 1 : 0
          dateApplied = true
          return acc + returnValue
        } else if (k.includes('search')) {
          return acc + v.split(/\|/gm).length / 2
        } else {
          return acc + 1
        }
      }, 0)
    } catch (err) {
      return 0
    }
  }, [filterList])

  // sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false)

  // tabs
  const [tabIndex, _setTabIndex] = useState(0)
  const setTabIndex = (newTabIndex) => {
    if (newTabIndex !== tabIndex) {
      setPage(1) // reset page on tab change
      _setTabIndex(newTabIndex)
    }
  }

  //navigation
  const navigate = useNavigate()

  // documents
  const { downloadDocument } = useDocument()

  // notification
  const { setBasicNotification, setError } = useNotification()

  const handleDownload = async (document, showNotif = true) => {
    try {
      const file = await downloadDocument(document)

      if (showNotif && !file?.downloadId)
        setBasicNotification(
          `Document ID ${document.id} has been automatically downloaded to your computer.`
        )

      return !Boolean(file?.downloadId)
    } catch (err) {
      setError(err.response?.data?.display_message || err.message)
    }
  }

  const {
    page,
    pageSize,
    total: dataListTotal,
    setTotal,
    setPage,
    setTotalPages,
    totalPages: dataListTotalPages,
    setPageSize,
    clearChecked,
    checkedCount,
    initialized,
    setSortedCols,
  } = useDataListContext()

  const [documentsHookParams, setDocumentsHookParams] = useState({
    'paging[page]': page,
    'paging[page_size]': pageSize,
    assigned_to_user_id: tabIndex === 1 ? UserID : undefined,
    is_archived: tabIndex === 2 ? true : undefined,
    embed: 'document|document_metadata',
    ...filterList,
    ...sortList,
  })

  useEffect(() => {
    if (searchParams && searchParams.get('q')) {
      const q = searchParams.get('q')
      const qDecoded = JSON.parse(decodeBase64(q))

      const localPageSize = qDecoded['paging[page_size]']
      if (localPageSize) {
        setPageSize(parseInt(localPageSize || BASE_PAGE_SIZE))
      }

      const localPage = qDecoded['paging[page]']
      if (localPage) {
        setPage(parseInt(localPage || 1))
      }

      const localSort = qDecoded['sort[0]']
      if (localSort) {
        const [id, value] = localSort.split(':')

        setSortList({ 'sort[0]': localSort })
        setSortedCols({ [id]: { id, desc: value === 'desc' } })
      } else {
        setSortedCols({ [defaultSort.id]: defaultSort })
      }

      const assignedToUserId = qDecoded['assigned_to_user_id']
      const isArchived = qDecoded['is_archived']
      /*
        check if this is equal to the current user
      */
      if (assignedToUserId && assignedToUserId === UserID) {
        setTabIndex(1)
      } else if (isArchived) {
        setTabIndex(2)
      } else {
        setTabIndex(0)
      }

      const search = qDecoded['search']
      const status = qDecoded['status']
      const category = qDecoded['category']
      const startDate = qDecoded['date_doc[start]']
      const endDate = qDecoded['date_doc[end]']
      setFilterList({
        search,
        status,
        category,
        assigned_to_user_id: assignedToUserId,
        'date_doc[start]': startDate,
        'date_doc[end]': endDate,
      })
    } else {
      setSortedCols({ [defaultSort.id]: defaultSort })
    }
  }, [])

  useEffect(() => {
    let isArchived = false

    if (tabIndex === 2) {
      isArchived = true
    }

    const searchParamsObj = {
      'paging[page]': page,
      'paging[page_size]': pageSize,
      is_archived: isArchived,
      embed: 'document|document_metadata',
      ...filterList,
      ...sortList,
    }

    if (tabIndex === 1) {
      searchParamsObj.assigned_to_user_id = UserID
    } else {
      searchParamsObj.assigned_to_user_id = filterList?.assigned_to_user_id
    }

    Object.keys(searchParamsObj).forEach((key) => {
      if (searchParamsObj[key] === undefined) delete searchParamsObj[key]
    })

    setDocumentsHookParams(searchParamsObj)
    setSearchParams(
      { q: encodeBase64(JSON.stringify(searchParamsObj)) },
      { replace: true }
    )
  }, [page, pageSize, sortList, filterList, tabIndex, UserID])

  const {
    documentInboundItems,
    total,
    loading,
    totalPages,
    updateInboundItem,
  } = useDocumentInboundItems(documentsHookParams)

  const prepareRowData = (row) => ({
    ...row,
    date_doc: row.doc_datetime || row.date_doc,
  })

  const getRowDisplay = useCallback(
    (rowData, columnName) => {
      if (columnName === 'document_id') {
        return (
          <Box
            component={Link}
            to={`/correspondence-hub/digital-mailbox/${rowData.document_id}`}
            sx={{ textDecoration: 'underline', color: 'text.primary' }}
            onClick={(e) => {
              makeNote(
                '',
                rowData.id,
                DOCUMENT_INBOUND_NOTE_ACTIVITY_TYPES.VIEW
              )
              e.stopPropagation()
            }}
          >
            {rowData.document_id}
          </Box>
        )
      } else {
        return rowData[columnName]?.toString() ?? 'N/A'
      }
    },
    [makeNote]
  )

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: documentInboundItems ?? [],
    presetName: 'digitalMailbox',
    setSortList,
    prepareRowData,
    getRowDisplay,
    moduleName: MODULE_NAMES.DIGITAL_MAILBOX,
  })

  const { getCsvExport, getAllDocumentInboundItems } =
    useAllDocumentInboundItems(
      {
        ...documentsHookParams,
      },
      !loading && total,
      dataListProps.getDataDisplay
    )

  useEffect(() => {
    if (total !== undefined && total !== dataListTotal) {
      setTotal(total)
      setTotalPages(totalPages)
      setPage(1)
    }
  }, [total, totalPages])

  useEffect(() => {
    if (totalPages && totalPages !== dataListTotalPages) {
      setTotalPages(totalPages)
    }
  }, [totalPages])

  // resets checked whenever documents changes
  useEffect(() => {
    if (checkedCount > 0) clearChecked()
  }, [page, total, documentInboundItems])

  const dataListDisplay = useMemo(() => {
    const actionButtonProps = [
      {
        children: 'View',
        color: 'primary',
        variant: 'outlined',
        size: 'action-header',
        sx: {
          bgcolor: 'background.paper',
          '&:hover': {
            bgcolor: 'hover.opaque',
          },
        },
        onClick: ({ rowData }) => {
          makeNote(
            '',
            rowData.id,
            DOCUMENT_INBOUND_NOTE_ACTIVITY_TYPES.VIEW
          ).finally(() => {
            navigate(
              `/correspondence-hub/digital-mailbox/${rowData.document_id}`
            )
          })
        },
      },
      canDownload && {
        children: 'Download',
        color: 'primary',
        variant: 'outlined',
        size: 'action-header',
        sx: {
          bgcolor: 'background.paper',
          '&:hover': {
            bgcolor: 'hover.opaque',
          },
        },
        onClick: (props) => handleDownload(props?.rowData?._embedded?.document),
      },
    ].filter((b) => b)

    return (
      <Card sx={{ boxShadow: 2, mb: 2 }}>
        <Box>
          <DataList
            {...dataListProps}
            dataList={dataList}
            columns={columns}
            loading={loading}
            validating={initialized}
            visibleColumns={getVisibleColumns()}
            height={DATALIST_DIMENSIONS.TABBED_HEIGHT}
            layoutType="digitalmailbox"
            actionButtonProps={actionButtonProps}
          />
        </Box>
      </Card>
    )
  }, [
    dataListProps,
    dataList,
    columns,
    loading,
    initialized,
    canDownload,
    getVisibleColumns,
  ])

  return (
    <>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Tabs
          open={sidebarOpen}
          toggleOpen={() => setSidebarOpen(!sidebarOpen)}
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
          filterCount={filterCount}
        />
        <Box sx={{ px: 8, pt: 6, width: '100%' }}>
          <Header
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            items={documentInboundItems}
            searchParams={documentsHookParams}
            updateInboundItem={updateInboundItem}
            handleSingleDownload={handleDownload}
            getAllDocumentInboundItems={getAllDocumentInboundItems}
            documentsLoading={loading}
            total={total}
          />
          {dataListDisplay}
        </Box>
        <ExportToCSV
          getCsvExport={getCsvExport}
          filename={`Digital.Mail.${Date.now()}.csv`}
          sx={{ ml: 8 }}
          hide={loading || dataList.length <= 0}
        />
      </Box>
      <Sidebar
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        filterList={filterList}
        setFilterList={setFilterList}
      />
    </>
  )
}

export default DocumentDataList
