import { createContext, useReducer, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import useStandaloneTemplate from 'hooks/collateral/useStandaloneTemplate'
import axios from 'utils/axios'
import {
  COLLATERAL_MODES,
  DELIVERY_TYPES,
  STANDALONE_DELIVERY_TYPE_NAMES,
} from 'utils/constants'
import useNotification from 'hooks/context/useNotification'

const initialState = {
  activeTemplate: null,
  previewPdf: null,
  pdfLoading: false,
  mode: COLLATERAL_MODES.DEFAULT,
  deliveryType: DELIVERY_TYPES.MAIL,
  favorited: {},
}

const SET_ACTIVE_TEMPLATE = 'SET_ACTIVE_TEMPLATE'
const SET_PREVIEW_PDF = 'SET_PREVIEW_PDF'
const SET_PDF_LOADING = 'SET_PDF_LOADING'
const SET_MODE = 'SET_MODE'
const SET_FAVORITED = 'SET_FAVORITED'

const CollateralReducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_TEMPLATE: {
      return {
        ...state,
        activeTemplate: action.payload.activeTemplate,
        previewPdf: null,
      }
    }
    case SET_PREVIEW_PDF: {
      return {
        ...state,
        previewPdf:
          action.payload.override ||
          action.payload.activeTemplate.id === state.activeTemplate.id
            ? action.payload.pdf
            : null,
        pdfLoading: false,
      }
    }
    case SET_PDF_LOADING: {
      return {
        ...state,
        pdfLoading: action.payload.pdfLoading,
      }
    }
    case SET_MODE: {
      return {
        ...state,
        mode: action.payload.mode,
        deliveryType: action.payload.deliveryType,
      }
    }
    case SET_FAVORITED: {
      return {
        ...state,
        favorited: {
          ...state.favorited,
          ...action.payload.favorited,
        },
      }
    }
    default:
      return state
  }
}

const CollateralContext = createContext(null)

function CollateralProvider({ children }) {
  const location = useLocation()
  const { setError } = useNotification()
  const activeIndex = location.state?.activeIndex || 0
  const [state, dispatch] = useReducer(CollateralReducer, {
    ...initialState,
    deliveryType: activeIndex,
    mode: Boolean(STANDALONE_DELIVERY_TYPE_NAMES[activeIndex])
      ? COLLATERAL_MODES.STANDALONE
      : COLLATERAL_MODES.DEFAULT,
  })

  const setActiveTemplate = useCallback(
    (activeTemplate) => {
      if (
        !state.activeTemplate ||
        state.activeTemplate.id !== activeTemplate?.id
      )
        dispatch({
          type: SET_ACTIVE_TEMPLATE,
          payload: {
            activeTemplate,
          },
        })
    },
    [state]
  )

  const setPdfLoading = useCallback((pdfLoading) => {
    dispatch({
      type: SET_PDF_LOADING,
      payload: {
        pdfLoading,
      },
    })
  }, [])

  const setFavorited = useCallback((favorited) => {
    dispatch({
      type: SET_FAVORITED,
      payload: {
        favorited,
      },
    })
  })

  const fetchPreviewPdf = useCallback(
    (formData, activeTemplate) => {
      const getPdf = async () => {
        const res = await axios.post(
          `/collateral-templates/${activeTemplate.id}/previews`,
          formData,
          {
            responseType: 'blob',
            headers: {
              'Content-Type': 'multipart/form-data',
              Accept: 'application/pdf',
            },
          }
        )

        return res.data
      }

      if (
        activeTemplate?.should_render_preview &&
        state.activeTemplate.id === activeTemplate.id
      ) {
        setPdfLoading(true)

        getPdf()
          .then((pdf) => {
            dispatch({
              type: SET_PREVIEW_PDF,
              payload: {
                activeTemplate,
                pdf,
              },
            })
          })
          .catch((e) => {
            setPdfLoading(false)

            const responseData = e?.response?.data

            if (
              responseData instanceof Blob &&
              responseData.type === 'application/json'
            ) {
              responseData
                .text()
                .then((val) => {
                  const data = JSON.parse(val)
                  setError(data?.display_message)
                })
                .catch(() => {
                  setError(
                    'There was an issue trying display this item. Please try again.'
                  )
                })
            } else {
              setError(
                'There was an issue trying display this item. Please try again.'
              )
            }
          })
      }
    },
    [state]
  )

  // hook to populate activeTemplate when standalone
  useStandaloneTemplate({
    templateId: STANDALONE_DELIVERY_TYPE_NAMES[state.deliveryType],
    activeTemplate: state.activeTemplate,
    setActiveTemplate,
  })

  return (
    <CollateralContext.Provider
      value={{
        ...state,
        setActiveTemplate,
        fetchPreviewPdf,
        setPdfLoading,
        setFavorited,
      }}
    >
      {children}
    </CollateralContext.Provider>
  )
}

export { CollateralContext, CollateralProvider }
